import {executeWithIntercept, getRandomInteger, pageData} from "@/helpers/CypressHelper";

context("(Desktop) CatalogManagement", (): void => {

    beforeEach(() => {
        cy.init("Desktop");
    });

    it("Should work correctly for Admin", (): void => {
        cy.loginAsAdmin();
        test();
    });

    function test(): void {
        describe("Catalog management. E2E test.", (): void => {
            cy.navigateToAdminCatalogManagementPage();

            cy.log("Enable edit mode");
            pageData().catalogManagement.editButton().click();

            // Add a category
            cy.log("Add a new category");
            const parentCategoryName: string = `Test Category ${getRandomInteger(null, 99999)}`;

            addCategory(parentCategoryName, null, "motorcycle");

            assertCategory(parentCategoryName, "motorcycle");

            // Add a sub-category
            cy.log("Create a sub-category");
            let subCategoryName: string = `Test SubCategory ${getRandomInteger(null, 99999)}`;

            addCategory(subCategoryName, parentCategoryName, "motorcycle");

            cy.log(`Go to the sub-category folder of category \"${parentCategoryName}\"`);
            goIntoCategory(parentCategoryName);

            assertCategory(subCategoryName, "motorcycle");

            // Modify the sub-category
            cy.log("Modify the sub-category");
            subCategoryName = modifyCategory(subCategoryName, null, "clarinet");

            assertCategory(subCategoryName, "clarinet");

            cy.log(`Go into the sub-category \"${subCategoryName}\"`);
            pageData().catalogManagement.category.goIntoCategoryButton().click();

            // Add a product
            cy.log("Add a product");
            const productName: string = `Test Product ${getRandomInteger(null, 99999)}`;
            const externalId: number = getRandomInteger(null, 10000);

            addProduct(productName, externalId, 1000, null, "guitar", "Guitar, nature, fireplace");

            assertProduct(productName, externalId, 1000, "guitar", "Guitar, nature, fireplace");

            // Modify the product
            cy.log(`Modify the product \"${productName}\"`);
            const newExternalId: number = getRandomInteger(null, 10000);

            const newProductName: string = modifyProduct(productName, newExternalId, 999, null, "car", "Guitar");

            assertProduct(newProductName, newExternalId, 999, "car", "Guitar");

            cy.log("Navigate to the root folder");
            pageData().catalogManagement.breadCrumbsHomeIcon().click();

            cy.log("Disable edit mode");
            pageData().catalogManagement.editButton().click();

            // Search the product
            cy.log(`Find product \"${newProductName}\"`);
            pageData().catalogManagement.searchInput().type(newProductName);
            pageData().catalogManagement.searchButton().click();
            pageData().catalogManagement.categoriesAndProductsList().within(() => {
                cy.get(`#productItem_${newProductName.replace(/\s/g, '_')}`).should("be.visible")
            });

            cy.log("Enable edit mode");
            pageData().catalogManagement.editButton().click();

            // Delete product
            cy.log(`Delete product \"${newProductName}\"`);
            deleteProduct(newProductName);

            cy.log("Navigate to the root folder");
            pageData().catalogManagement.breadCrumbsHomeIcon().click();

            // Delete the sub-category
            cy.log(`Go to the sub-category \"${subCategoryName}\"`);
            goIntoCategory(parentCategoryName);

            cy.log(`Delete sub-category\"${subCategoryName}\"`);
            deleteCategory(subCategoryName);

            cy.log("Navigate to the root folder");
            pageData().catalogManagement.breadCrumbsHomeIcon().click();

            // Delete the category
            cy.log(`Delete category\"${parentCategoryName}\"`);
            deleteCategory(parentCategoryName);

            cy.log("Disable edit mode");
            pageData().catalogManagement.editButton().click();
        });
    }

    function goIntoCategory(categoryName: string): void {
        pageData().catalogManagement.categoriesAndProductsList().within(() => {
            cy.get(`#categoryItem_${categoryName.replace(/\s/g, '_')}`).within(() => {
                pageData().catalogManagement.category.goIntoCategoryButton().click();
            });
        });
    }

    function addCategory(categoryName: string, parentCategoryName: string | null, categoryIcon: string | null): void {
        pageData().catalogManagement.category.addCategoryButton().click();

        pageData().catalogManagement.category.categoryModal().within(() => {
            pageData().catalogManagement.category.categoryNameInput().type(categoryName);

            if (parentCategoryName) {
                pageData().catalogManagement.category.parentCategoryDropdown().selectDropdownValue(parentCategoryName);
            }

            if (categoryIcon) {
                pageData().catalogManagement.category.categoryIconInput().type(categoryIcon);
            }

            executeWithIntercept(
                () => pageData().catalogManagement.category.saveCategoryButton().click(),
                [pageData().catalogManagement.routes.addCategory]
            );
        });
    }

    function modifyCategory(categoryName: string, _: string | null, categoryIcon: string | null): string {
        const newCategoryName: string = `${categoryName} is modified`;

        pageData().catalogManagement.categoriesAndProductsList().within(() => {
            pageData().catalogManagement.category.categoryNameInput().type('{selectall}').type(newCategoryName);
            pageData().catalogManagement.category.cancelCategoryChangesButton().click();

            pageData().catalogManagement.category.categoryNameInput().should("have.value", categoryName);

            pageData().catalogManagement.category.categoryNameInput().type('{selectall}').type(newCategoryName);

            if (categoryIcon) {
                pageData().catalogManagement.category.categoryIconInput().type('{selectall}').type(categoryIcon);
            }

            executeWithIntercept(
                () => pageData().catalogManagement.category.saveCategoryButton().click(),
                [pageData().catalogManagement.routes.saveCategory]
            );
        });

        return newCategoryName;
    }

    function deleteCategory(categoryName: string): void {
        cy.get(`#categoryItem_${categoryName.replace(/\s/g, '_')}`).within(() => {
            pageData().catalogManagement.category.deleteCategoryButton().trigger('click');
        });

        pageData().common.confirmationDialog.dialog();
        pageData().common.confirmationDialog.confirmButton().click();

        cy.get(`#categoryItem_${categoryName.replace(/\s/g, '_')}`).should("not.exist");
    }

    function addProduct(productName: string, externalId: number, price: number, categoryName: string | null, productIcon: string | null, keywords: string | null): void {
        pageData().catalogManagement.product.addProductButton().click();

        pageData().catalogManagement.product.productModal().within(() => {
            pageData().catalogManagement.product.productNameInput().type(productName);
            pageData().catalogManagement.product.cancelProductChangesButton().click();
            pageData().catalogManagement.product.productNameInput().should("be.empty");

            pageData().catalogManagement.product.productNameInput().type(productName);
            pageData().catalogManagement.product.productExternalIdInput().type(`${externalId}`);
            pageData().catalogManagement.product.productPriceInput().type(`${price}`);
            pageData().catalogManagement.product.productUnitDropdown().selectDropdownValue("Meter");

            if (productIcon) {
                pageData().catalogManagement.product.productIconInput().type(productIcon);
            }

            if (keywords) {
                pageData().catalogManagement.product.productKeywordsInput().type(keywords);
            }

            if (categoryName) {
                pageData().catalogManagement.product.productCategoryDropdown().selectDropdownValue(categoryName);
            }

            executeWithIntercept(
                () => pageData().catalogManagement.product.saveProductButton().click(),
                [pageData().catalogManagement.routes.addProduct]
            );
        });
    }

    function modifyProduct(productName: string, externalId: number, price: number, categoryName: string | null, productIcon: string | null, keywords: string | null): string {
        const newProductName: string = `${productName} is modified`;

        pageData().catalogManagement.categoriesAndProductsList().within(() => {
            pageData().catalogManagement.product.productNameInput().type('{selectall}').type(newProductName);
            pageData().catalogManagement.product.cancelProductChangesButton().click();
            pageData().catalogManagement.product.productNameInput().should("have.value", productName);

            pageData().catalogManagement.product.productNameInput().type('{selectall}').type(newProductName);
            pageData().catalogManagement.product.productExternalIdInput().type('{selectall}').type(`${externalId}`);
            pageData().catalogManagement.product.productPriceInput().type('{selectall}').type(`${price}`);
            pageData().catalogManagement.product.productUnitDropdown().selectDropdownValue("Bottle");

            if (productIcon) {
                pageData().catalogManagement.product.productIconInput().type('{selectall}').type(productIcon);
            }

            if (keywords) {
                pageData().catalogManagement.product.productKeywordsInput().type('{selectall}').type(keywords);
            }

            if (categoryName) {
                pageData().catalogManagement.product.productCategoryDropdown().selectDropdownValue(categoryName);
            }

            executeWithIntercept(
                () => pageData().catalogManagement.product.saveProductButton().click(),
                [pageData().catalogManagement.routes.saveProduct]
            );
        });

        return newProductName;
    }

    function deleteProduct(productName: string): void {
        cy.get(`#productItem_${productName.replace(/\s/g, '_')}`).within(() => {
            pageData().catalogManagement.product.deleteProductButton().trigger('click');
        });

        pageData().common.confirmationDialog.dialog();
        pageData().common.confirmationDialog.confirmButton().click();

        cy.get(`#productItem_${productName.replace(/\s/g, '_')}`).should("not.exist");
    }

    function assertCategory(categoryName: string, categoryIcon: string | null): void {
        pageData().catalogManagement.categoriesAndProductsList().within(() => {
            cy.get(`#categoryItem_${categoryName.replace(/\s/g, '_')}`).within(() => {
                pageData().catalogManagement.category.categoryNameInput().should("have.value", categoryName);

                if (categoryIcon) {
                    pageData().catalogManagement.category.categoryIconInput().should("have.value", categoryIcon);
                }
            });
        });
    }

    function assertProduct(newProductName: string, externalId: number, price: number, productIcon: string | null, keywords: string | null): void {
        pageData().catalogManagement.product.productNameInput().should("have.value", newProductName);
        pageData().catalogManagement.product.productExternalIdInput().should("have.value", externalId);
        pageData().catalogManagement.product.productPriceInput().should("have.value", price);

        if (productIcon) {
            pageData().catalogManagement.product.productIconInput().should("have.value", productIcon);
        }

        if (keywords) {
            pageData().catalogManagement.product.productKeywordsInput().should("have.value", keywords);
        }
    }
});