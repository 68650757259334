import React from "react";
import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import {BaseComponent, ch, IBaseComponent, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {AddressInput, Button, ButtonContainer, ButtonType, Checkbox, Dropdown, DropdownRequiredType, Form, Modal, OneColumn, TextInput} from "@renta-apps/athenaeum-react-components";
import AddConstructionSiteRequest from "../../../models/server/requests/AddConstructionSiteRequest";
import OrganizationContract from "../../../models/server/OrganizationContract";
import AddConstructionSiteResponse from "@/models/server/responses/AddConstructionSiteResponse";
import ConstructionSite from "@/models/server/ConstructionSite";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "../../../localization/Localizer";

import "./BootstrapOverride.scss";
import styles from "./AddConstructionSiteModal.module.scss";

interface IAddConstructionSiteModalProps {
    modalId: string;
    fetchCustomers(sender: IBaseComponent): Promise<OrganizationContract[]>;
    addConstructionSite(request: AddConstructionSiteRequest): Promise<AddConstructionSiteResponse>;
}

interface IAddConstructionSiteModalState {
    organizationContracts: OrganizationContract[] | null,
    organizationContract: OrganizationContract | null,
    location: GeoLocation | null,
    name: string,
    invoiceReference: string,
    redirect: boolean;
}

export default class AddConstructionSiteModal extends BaseComponent<IAddConstructionSiteModalProps, IAddConstructionSiteModalState> {

    state: IAddConstructionSiteModalState = {
        organizationContracts: null,
        organizationContract: null,
        location: null,
        name: "",
        invoiceReference: "",
        redirect: true
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private async onPlaceSelected(location: GeoLocation) {
        await this.setState({location});
    }

    private async onChangeCustomerAsync(customer: OrganizationContract): Promise<void> {
        await this.setState({organizationContract: customer});
    }
 
    private async onNameChangeAsync(name: string) {
        await this.setState({name});
    }

    private async onCustomerInvoiceReferenceChangeAsync(invoiceReference: string) {
        await this.setState({invoiceReference: invoiceReference});
    }

    private async onRedirectChangeAsync(redirect: boolean) {
        await this.setState({redirect});
    }

    private async onSubmitAsync(): Promise<void> {

        const request: AddConstructionSiteRequest = {
            name: this.state.name,
            invoiceReference: this.state.invoiceReference,
            formattedAddress: this.state.location!.formattedAddress,
            organizationContractId: this.state.organizationContract!.id,
        };

        const response: AddConstructionSiteResponse = await this.props.addConstructionSite(request);
        const constructionSite: ConstructionSite | null = response.constructionSite;

        await this._modalRef.current!.closeAsync();

        if (this.state.redirect && constructionSite != null) {
            await PageRouteProvider.redirectAsync(PageDefinitions.constructionSiteManagement(constructionSite.id));
        }

        await ch.flyoutMessageAsync(Localizer.addConstructionsiteFlyout);
    }
 
    public hasSpinner(): boolean {
        return true;
    }

    public async openAsync(sender: Modal): Promise<void> {
        const organizationContracts: OrganizationContract[] = await this.props.fetchCustomers(sender);
        await this.setState({organizationContracts: organizationContracts});
        await this._modalRef.current!.openAsync();
    }

    public render(): React.ReactNode {

        return (
            <Modal id={this.props.modalId} 
                   ref={this._modalRef}
                   className={styles.addConstructionSiteModal}
                   title={Localizer.addConstructionsiteTitle}
                   onOpen={async(sender) => await this.openAsync(sender)}
            >
                <div className="row">
                    <div className="col">

                        <Form ref={this._formRef} id="addConstructionSiteForm" onSubmit={async () => await this.onSubmitAsync()} submitOnEnter={false}>

                            <OneColumn>
                                <Dropdown id="addConstructionSiteFormOrganizationContract"
                                          required
                                          noSubtext
                                          autoGroupSelected
                                          requiredType={DropdownRequiredType.Manual}
                                          label={Localizer.addConstructionsiteOrganization}
                                          items={this.state.organizationContracts || []}
                                          selectedItem={this.state.organizationContract || undefined}
                                          onChange={async (_, item) => await this.onChangeCustomerAsync(item!)}
                                />
                                
                                <TextInput id="addConstructionSiteFormName" required
                                           label={Localizer.tasksPanelName}
                                           value={this.state.name!}
                                           onChange={async (_, item) => await this.onNameChangeAsync(item!)}
                                />

                                <AddressInput id="addConstructionSiteFormAddress" required
                                              label={Localizer.formInputAddress}
                                              value={this.state!.location != null ? this.state.location.formattedAddress : ""}
                                              locationPicker
                                              append
                                              onChange={async (location) => await this.onPlaceSelected(location)}
                                />
                                
                                <TextInput id="addConstructionSiteFormInvoiceReference"
                                           label={Localizer.addConstructionSiteInvoiceReference}
                                           value={this.state.invoiceReference}
                                           onChange={async (_, item) => await this.onCustomerInvoiceReferenceChangeAsync(item!)}
                                />
                            </OneColumn>

                            <ButtonContainer>

                                <div className={styles.redirect}>
                                    <Checkbox id="addConstructionSiteFormRedirectToConstructionSite"
                                              value={this.state.redirect}
                                              onChange={async (_, item) => await this.onRedirectChangeAsync(item!)}/>

                                    <span>{Localizer.addConstructionsiteRedirect}</span>
                                </div>

                                <Button submit
                                        id="addConstructionSiteFormAddButton"
                                        label={Localizer.addConstructionsiteAddSite}
                                        type={ButtonType.Orange}
                                />
                            </ButtonContainer>

                        </Form>

                    </div>
                </div>
            </Modal>
        );
    }
};