import {ProductUnit, RentalItemActionType} from "@/models/Enums";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";

/**
 * A display-only class for rental items (mass or individual).
 */
export default class RentalItemDetailView {
    public id: string = "";
    public externalId: string | null = null;
    public actionType: RentalItemActionType = RentalItemActionType.Leave;
    public name: string = "";
    public unit: ProductUnit | null = null;
    public amount: number = 0;
    public rentDate: Date = new Date();
    public comment: string | null = null;
    
    public constructor(item: any) {
        if (item instanceof WorkOrderRentalItemModel || item.isWorkOrderRentalItemModel) {
            this.id = item.id;
            this.externalId = item.rentalItemExternalId;
            this.name = item.rentalItemName ?? "-";
            this.comment = item.comment;
            this.rentDate = item.rentDate;
            this.actionType = item.actionType;

            // Not valid for individual rental equipment
            this.amount = 1;
            this.unit = null;
        } else if (item instanceof WorkOrderEquipment || item.isWorkOrderEquipment) {
            this.id = item.id;
            this.name = item.name ?? "-";
            this.comment = item.description;
            this.rentDate = item.rentDate;
            this.actionType = item.actionType;
            this.amount = item.amount;
            this.unit = item.unit;

            // Not valid for mass rental equipment
            this.externalId = null;
        } else {
            throw new Error(`Not supported type ${typeof item}`);
        }
    }
}