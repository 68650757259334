//Autogenerated

import {BaseEnumProvider, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {WebApplicationType, PasswordValidationError} from "@renta-apps/athenaeum-react-common";
import {SelectListItem} from "@renta-apps/athenaeum-react-components";
import {LoginResultStatus, OrganizationContractLevel, OrganizationContractType, WorkDayState, UserSalaryAggregateType, CustomerApprovalType, ConstructionSiteOrWarehouseType, TaskStatusFilter, ConstructionSiteStatus, CommunicationLanguage, WorkOrderStatus, ConstructionSiteWorkOrderStatus, UserRoleGroup, ProductUnit, EquipmentType, SavePasswordResultStatus, InvitationType, AuditTimestamp, AuthType, FiltersBehavior, CalendarUnitTime, WorkOrderContactPersonType, FormHeaderType, FormTimeslotType, CustomFormType, FormItemType, FormMeasuringOperator, WorkOrderFormStatusFilter, ExtraChargeTypeUnit, AttachmentType, NotificationType, NotificationInterval, NotificationMediaType, NotificationStatus, RentalItemActionType, MounterPermission, WorkOrderContractType, CatalogType, WorkType, SsoProvider, DayOfWeek} from "@/models/Enums";

class EnumProvider extends BaseEnumProvider<SelectListItem> {

    // #region Private
    
    private readonly _types: string[] = ["WebApplicationType", "LoginResultStatus", "OrganizationContractLevel", "OrganizationContractType", "WorkDayState", "UserSalaryAggregateType", "CustomerApprovalType", "ConstructionSiteOrWarehouseType", "TaskStatusFilter", "ConstructionSiteStatus", "CommunicationLanguage", "WorkOrderStatus", "ConstructionSiteWorkOrderStatus", "UserRoleGroup", "ProductUnit", "EquipmentType", "PasswordValidationError", "SavePasswordResultStatus", "InvitationType", "AuditTimestamp", "AuthType", "SortDirection", "FiltersBehavior", "CalendarUnitTime", "WorkOrderContactPersonType", "FormHeaderType", "FormTimeslotType", "CustomFormType", "FormItemType", "FormMeasuringOperator", "WorkOrderFormStatusFilter", "ExtraChargeTypeUnit", "AttachmentType", "NotificationType", "NotificationInterval", "NotificationMediaType", "NotificationStatus", "RentalItemActionType", "MounterPermission", "WorkOrderContractType", "CatalogType", "WorkType", "SsoProvider", "DayOfWeek"];

    protected get types(): readonly string[] {
        return this._types;
    }

    protected createSelectListItem(value: string, text: string, subtext: string): SelectListItem {
        return new SelectListItem(value, text, subtext);
    }
    
    // #endregion
    
    constructor() {
        super();
    }

    // #region WebApplicationType

    public getWebApplicationTypeItem(value: WebApplicationType): SelectListItem {
        return this.transform(WebApplicationType, "WebApplicationType", value);
    }

    public getWebApplicationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(WebApplicationType, "WebApplicationType", reverse);
    }

    public getWebApplicationTypeName(value: WebApplicationType): string {
        return this.getWebApplicationTypeItem(value).text;
    }

    public getWebApplicationTypeText(value: WebApplicationType): string {
        return this.localizer.get(this.getWebApplicationTypeName(value));
    }

    public getWebApplicationTypeDescription(value: WebApplicationType): string {
        return this.getWebApplicationTypeItem(value).subtext;
    }

    // #endregion

    // #region LoginResultStatus

    public getLoginResultStatusItem(value: LoginResultStatus): SelectListItem {
        return this.transform(LoginResultStatus, "LoginResultStatus", value);
    }

    public getLoginResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(LoginResultStatus, "LoginResultStatus", reverse);
    }

    public getLoginResultStatusName(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).text;
    }

    public getLoginResultStatusText(value: LoginResultStatus): string {
        return this.localizer.get(this.getLoginResultStatusName(value));
    }

    public getLoginResultStatusDescription(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).subtext;
    }

    // #endregion

    // #region OrganizationContractLevel

    public getOrganizationContractLevelItem(value: OrganizationContractLevel): SelectListItem {
        return this.transform(OrganizationContractLevel, "OrganizationContractLevel", value);
    }

    public getOrganizationContractLevelItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrganizationContractLevel, "OrganizationContractLevel", reverse);
    }

    public getOrganizationContractLevelName(value: OrganizationContractLevel): string {
        return this.getOrganizationContractLevelItem(value).text;
    }

    public getOrganizationContractLevelText(value: OrganizationContractLevel): string {
        return this.localizer.get(this.getOrganizationContractLevelName(value));
    }

    public getOrganizationContractLevelDescription(value: OrganizationContractLevel): string {
        return this.getOrganizationContractLevelItem(value).subtext;
    }

    // #endregion

    // #region OrganizationContractType

    public getOrganizationContractTypeItem(value: OrganizationContractType): SelectListItem {
        return this.transform(OrganizationContractType, "OrganizationContractType", value);
    }

    public getOrganizationContractTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrganizationContractType, "OrganizationContractType", reverse);
    }

    public getOrganizationContractTypeName(value: OrganizationContractType): string {
        return this.getOrganizationContractTypeItem(value).text;
    }

    public getOrganizationContractTypeText(value: OrganizationContractType): string {
        return this.localizer.get(this.getOrganizationContractTypeName(value));
    }

    public getOrganizationContractTypeDescription(value: OrganizationContractType): string {
        return this.getOrganizationContractTypeItem(value).subtext;
    }

    // #endregion

    // #region WorkDayState

    public getWorkDayStateItem(value: WorkDayState): SelectListItem {
        return this.transform(WorkDayState, "WorkDayState", value);
    }

    public getWorkDayStateItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(WorkDayState, "WorkDayState", reverse);
    }

    public getWorkDayStateName(value: WorkDayState): string {
        return this.getWorkDayStateItem(value).text;
    }

    public getWorkDayStateText(value: WorkDayState): string {
        return this.localizer.get(this.getWorkDayStateName(value));
    }

    public getWorkDayStateDescription(value: WorkDayState): string {
        return this.getWorkDayStateItem(value).subtext;
    }

    // #endregion

    // #region UserSalaryAggregateType

    public getUserSalaryAggregateTypeItem(value: UserSalaryAggregateType): SelectListItem {
        return this.transform(UserSalaryAggregateType, "UserSalaryAggregateType", value);
    }

    public getUserSalaryAggregateTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(UserSalaryAggregateType, "UserSalaryAggregateType", reverse);
    }

    public getUserSalaryAggregateTypeName(value: UserSalaryAggregateType): string {
        return this.getUserSalaryAggregateTypeItem(value).text;
    }

    public getUserSalaryAggregateTypeText(value: UserSalaryAggregateType): string {
        return this.localizer.get(this.getUserSalaryAggregateTypeName(value));
    }

    public getUserSalaryAggregateTypeDescription(value: UserSalaryAggregateType): string {
        return this.getUserSalaryAggregateTypeItem(value).subtext;
    }

    // #endregion

    // #region CustomerApprovalType

    public getCustomerApprovalTypeItem(value: CustomerApprovalType): SelectListItem {
        return this.transform(CustomerApprovalType, "CustomerApprovalType", value);
    }

    public getCustomerApprovalTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(CustomerApprovalType, "CustomerApprovalType", reverse);
    }

    public getCustomerApprovalTypeName(value: CustomerApprovalType): string {
        return this.getCustomerApprovalTypeItem(value).text;
    }

    public getCustomerApprovalTypeText(value: CustomerApprovalType): string {
        return this.localizer.get(this.getCustomerApprovalTypeName(value));
    }

    public getCustomerApprovalTypeDescription(value: CustomerApprovalType): string {
        return this.getCustomerApprovalTypeItem(value).subtext;
    }

    // #endregion

    // #region ConstructionSiteOrWarehouseType

    public getConstructionSiteOrWarehouseTypeItem(value: ConstructionSiteOrWarehouseType): SelectListItem {
        return this.transform(ConstructionSiteOrWarehouseType, "ConstructionSiteOrWarehouseType", value);
    }

    public getConstructionSiteOrWarehouseTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ConstructionSiteOrWarehouseType, "ConstructionSiteOrWarehouseType", reverse);
    }

    public getConstructionSiteOrWarehouseTypeName(value: ConstructionSiteOrWarehouseType): string {
        return this.getConstructionSiteOrWarehouseTypeItem(value).text;
    }

    public getConstructionSiteOrWarehouseTypeText(value: ConstructionSiteOrWarehouseType): string {
        return this.localizer.get(this.getConstructionSiteOrWarehouseTypeName(value));
    }

    public getConstructionSiteOrWarehouseTypeDescription(value: ConstructionSiteOrWarehouseType): string {
        return this.getConstructionSiteOrWarehouseTypeItem(value).subtext;
    }

    // #endregion

    // #region TaskStatusFilter

    public getTaskStatusFilterItem(value: TaskStatusFilter): SelectListItem {
        return this.transform(TaskStatusFilter, "TaskStatusFilter", value);
    }

    public getTaskStatusFilterItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(TaskStatusFilter, "TaskStatusFilter", reverse);
    }

    public getTaskStatusFilterName(value: TaskStatusFilter): string {
        return this.getTaskStatusFilterItem(value).text;
    }

    public getTaskStatusFilterText(value: TaskStatusFilter): string {
        return this.localizer.get(this.getTaskStatusFilterName(value));
    }

    public getTaskStatusFilterDescription(value: TaskStatusFilter): string {
        return this.getTaskStatusFilterItem(value).subtext;
    }

    // #endregion

    // #region ConstructionSiteStatus

    public getConstructionSiteStatusItem(value: ConstructionSiteStatus): SelectListItem {
        return this.transform(ConstructionSiteStatus, "ConstructionSiteStatus", value);
    }

    public getConstructionSiteStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ConstructionSiteStatus, "ConstructionSiteStatus", reverse);
    }

    public getConstructionSiteStatusName(value: ConstructionSiteStatus): string {
        return this.getConstructionSiteStatusItem(value).text;
    }

    public getConstructionSiteStatusText(value: ConstructionSiteStatus): string {
        return this.localizer.get(this.getConstructionSiteStatusName(value));
    }

    public getConstructionSiteStatusDescription(value: ConstructionSiteStatus): string {
        return this.getConstructionSiteStatusItem(value).subtext;
    }

    // #endregion

    // #region CommunicationLanguage

    public getCommunicationLanguageItem(value: CommunicationLanguage): SelectListItem {
        return this.transform(CommunicationLanguage, "CommunicationLanguage", value);
    }

    public getCommunicationLanguageItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(CommunicationLanguage, "CommunicationLanguage", reverse);
    }

    public getCommunicationLanguageName(value: CommunicationLanguage): string {
        return this.getCommunicationLanguageItem(value).text;
    }

    public getCommunicationLanguageText(value: CommunicationLanguage): string {
        return this.localizer.get(this.getCommunicationLanguageName(value));
    }

    public getCommunicationLanguageDescription(value: CommunicationLanguage): string {
        return this.getCommunicationLanguageItem(value).subtext;
    }

    // #endregion

    // #region WorkOrderStatus

    public getWorkOrderStatusItem(value: WorkOrderStatus): SelectListItem {
        return this.transform(WorkOrderStatus, "WorkOrderStatus", value);
    }

    public getWorkOrderStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(WorkOrderStatus, "WorkOrderStatus", reverse);
    }

    public getWorkOrderStatusName(value: WorkOrderStatus): string {
        return this.getWorkOrderStatusItem(value).text;
    }

    public getWorkOrderStatusText(value: WorkOrderStatus): string {
        return this.localizer.get(this.getWorkOrderStatusName(value));
    }

    public getWorkOrderStatusDescription(value: WorkOrderStatus): string {
        return this.getWorkOrderStatusItem(value).subtext;
    }

    // #endregion

    // #region ConstructionSiteWorkOrderStatus

    public getConstructionSiteWorkOrderStatusItem(value: ConstructionSiteWorkOrderStatus): SelectListItem {
        return this.transform(ConstructionSiteWorkOrderStatus, "ConstructionSiteWorkOrderStatus", value);
    }

    public getConstructionSiteWorkOrderStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ConstructionSiteWorkOrderStatus, "ConstructionSiteWorkOrderStatus", reverse);
    }

    public getConstructionSiteWorkOrderStatusName(value: ConstructionSiteWorkOrderStatus): string {
        return this.getConstructionSiteWorkOrderStatusItem(value).text;
    }

    public getConstructionSiteWorkOrderStatusText(value: ConstructionSiteWorkOrderStatus): string {
        return this.localizer.get(this.getConstructionSiteWorkOrderStatusName(value));
    }

    public getConstructionSiteWorkOrderStatusDescription(value: ConstructionSiteWorkOrderStatus): string {
        return this.getConstructionSiteWorkOrderStatusItem(value).subtext;
    }

    // #endregion

    // #region UserRoleGroup

    public getUserRoleGroupItem(value: UserRoleGroup): SelectListItem {
        return this.transform(UserRoleGroup, "UserRoleGroup", value);
    }

    public getUserRoleGroupItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(UserRoleGroup, "UserRoleGroup", reverse);
    }

    public getUserRoleGroupName(value: UserRoleGroup): string {
        return this.getUserRoleGroupItem(value).text;
    }

    public getUserRoleGroupText(value: UserRoleGroup): string {
        return this.localizer.get(this.getUserRoleGroupName(value));
    }

    public getUserRoleGroupDescription(value: UserRoleGroup): string {
        return this.getUserRoleGroupItem(value).subtext;
    }

    // #endregion

    // #region ProductUnit

    public getProductUnitItem(value: ProductUnit): SelectListItem {
        return this.transform(ProductUnit, "ProductUnit", value);
    }

    public getProductUnitItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductUnit, "ProductUnit", reverse);
    }

    public getProductUnitName(value: ProductUnit): string {
        return this.getProductUnitItem(value).text;
    }

    public getProductUnitText(value: ProductUnit): string {
        return this.localizer.get(this.getProductUnitName(value));
    }

    public getProductUnitDescription(value: ProductUnit): string {
        return this.getProductUnitItem(value).subtext;
    }

    // #endregion

    // #region EquipmentType

    public getEquipmentTypeItem(value: EquipmentType): SelectListItem {
        return this.transform(EquipmentType, "EquipmentType", value);
    }

    public getEquipmentTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(EquipmentType, "EquipmentType", reverse);
    }

    public getEquipmentTypeName(value: EquipmentType): string {
        return this.getEquipmentTypeItem(value).text;
    }

    public getEquipmentTypeText(value: EquipmentType): string {
        return this.localizer.get(this.getEquipmentTypeName(value));
    }

    public getEquipmentTypeDescription(value: EquipmentType): string {
        return this.getEquipmentTypeItem(value).subtext;
    }

    // #endregion

    // #region PasswordValidationError

    public getPasswordValidationErrorItem(value: PasswordValidationError): SelectListItem {
        return this.transform(PasswordValidationError, "PasswordValidationError", value);
    }

    public getPasswordValidationErrorItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PasswordValidationError, "PasswordValidationError", reverse);
    }

    public getPasswordValidationErrorName(value: PasswordValidationError): string {
        return this.getPasswordValidationErrorItem(value).text;
    }

    public getPasswordValidationErrorText(value: PasswordValidationError): string {
        return this.localizer.get(this.getPasswordValidationErrorName(value));
    }

    public getPasswordValidationErrorDescription(value: PasswordValidationError): string {
        return this.getPasswordValidationErrorItem(value).subtext;
    }

    // #endregion

    // #region SavePasswordResultStatus

    public getSavePasswordResultStatusItem(value: SavePasswordResultStatus): SelectListItem {
        return this.transform(SavePasswordResultStatus, "SavePasswordResultStatus", value);
    }

    public getSavePasswordResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SavePasswordResultStatus, "SavePasswordResultStatus", reverse);
    }

    public getSavePasswordResultStatusName(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).text;
    }

    public getSavePasswordResultStatusText(value: SavePasswordResultStatus): string {
        return this.localizer.get(this.getSavePasswordResultStatusName(value));
    }

    public getSavePasswordResultStatusDescription(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).subtext;
    }

    // #endregion

    // #region InvitationType

    public getInvitationTypeItem(value: InvitationType): SelectListItem {
        return this.transform(InvitationType, "InvitationType", value);
    }

    public getInvitationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InvitationType, "InvitationType", reverse);
    }

    public getInvitationTypeName(value: InvitationType): string {
        return this.getInvitationTypeItem(value).text;
    }

    public getInvitationTypeText(value: InvitationType): string {
        return this.localizer.get(this.getInvitationTypeName(value));
    }

    public getInvitationTypeDescription(value: InvitationType): string {
        return this.getInvitationTypeItem(value).subtext;
    }

    // #endregion

    // #region AuditTimestamp

    public getAuditTimestampItem(value: AuditTimestamp): SelectListItem {
        return this.transform(AuditTimestamp, "AuditTimestamp", value);
    }

    public getAuditTimestampItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AuditTimestamp, "AuditTimestamp", reverse);
    }

    public getAuditTimestampName(value: AuditTimestamp): string {
        return this.getAuditTimestampItem(value).text;
    }

    public getAuditTimestampText(value: AuditTimestamp): string {
        return this.localizer.get(this.getAuditTimestampName(value));
    }

    public getAuditTimestampDescription(value: AuditTimestamp): string {
        return this.getAuditTimestampItem(value).subtext;
    }

    // #endregion

    // #region AuthType

    public getAuthTypeItem(value: AuthType): SelectListItem {
        return this.transform(AuthType, "AuthType", value);
    }

    public getAuthTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AuthType, "AuthType", reverse);
    }

    public getAuthTypeName(value: AuthType): string {
        return this.getAuthTypeItem(value).text;
    }

    public getAuthTypeText(value: AuthType): string {
        return this.localizer.get(this.getAuthTypeName(value));
    }

    public getAuthTypeDescription(value: AuthType): string {
        return this.getAuthTypeItem(value).subtext;
    }

    // #endregion

    // #region SortDirection

    public getSortDirectionItem(value: SortDirection): SelectListItem {
        return this.transform(SortDirection, "SortDirection", value);
    }

    public getSortDirectionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SortDirection, "SortDirection", reverse);
    }

    public getSortDirectionName(value: SortDirection): string {
        return this.getSortDirectionItem(value).text;
    }

    public getSortDirectionText(value: SortDirection): string {
        return this.localizer.get(this.getSortDirectionName(value));
    }

    public getSortDirectionDescription(value: SortDirection): string {
        return this.getSortDirectionItem(value).subtext;
    }

    // #endregion

    // #region FiltersBehavior

    public getFiltersBehaviorItem(value: FiltersBehavior): SelectListItem {
        return this.transform(FiltersBehavior, "FiltersBehavior", value);
    }

    public getFiltersBehaviorItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(FiltersBehavior, "FiltersBehavior", reverse);
    }

    public getFiltersBehaviorName(value: FiltersBehavior): string {
        return this.getFiltersBehaviorItem(value).text;
    }

    public getFiltersBehaviorText(value: FiltersBehavior): string {
        return this.localizer.get(this.getFiltersBehaviorName(value));
    }

    public getFiltersBehaviorDescription(value: FiltersBehavior): string {
        return this.getFiltersBehaviorItem(value).subtext;
    }

    // #endregion

    // #region CalendarUnitTime

    public getCalendarUnitTimeItem(value: CalendarUnitTime): SelectListItem {
        return this.transform(CalendarUnitTime, "CalendarUnitTime", value);
    }

    public getCalendarUnitTimeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(CalendarUnitTime, "CalendarUnitTime", reverse);
    }

    public getCalendarUnitTimeName(value: CalendarUnitTime): string {
        return this.getCalendarUnitTimeItem(value).text;
    }

    public getCalendarUnitTimeText(value: CalendarUnitTime): string {
        return this.localizer.get(this.getCalendarUnitTimeName(value));
    }

    public getCalendarUnitTimeDescription(value: CalendarUnitTime): string {
        return this.getCalendarUnitTimeItem(value).subtext;
    }

    // #endregion

    // #region WorkOrderContactPersonType

    public getWorkOrderContactPersonTypeItem(value: WorkOrderContactPersonType): SelectListItem {
        return this.transform(WorkOrderContactPersonType, "WorkOrderContactPersonType", value);
    }

    public getWorkOrderContactPersonTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(WorkOrderContactPersonType, "WorkOrderContactPersonType", reverse);
    }

    public getWorkOrderContactPersonTypeName(value: WorkOrderContactPersonType): string {
        return this.getWorkOrderContactPersonTypeItem(value).text;
    }

    public getWorkOrderContactPersonTypeText(value: WorkOrderContactPersonType): string {
        return this.localizer.get(this.getWorkOrderContactPersonTypeName(value));
    }

    public getWorkOrderContactPersonTypeDescription(value: WorkOrderContactPersonType): string {
        return this.getWorkOrderContactPersonTypeItem(value).subtext;
    }

    // #endregion

    // #region FormHeaderType

    public getFormHeaderTypeItem(value: FormHeaderType): SelectListItem {
        return this.transform(FormHeaderType, "FormHeaderType", value);
    }

    public getFormHeaderTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(FormHeaderType, "FormHeaderType", reverse);
    }

    public getFormHeaderTypeName(value: FormHeaderType): string {
        return this.getFormHeaderTypeItem(value).text;
    }

    public getFormHeaderTypeText(value: FormHeaderType): string {
        return this.localizer.get(this.getFormHeaderTypeName(value));
    }

    public getFormHeaderTypeDescription(value: FormHeaderType): string {
        return this.getFormHeaderTypeItem(value).subtext;
    }

    // #endregion

    // #region FormTimeslotType

    public getFormTimeslotTypeItem(value: FormTimeslotType): SelectListItem {
        return this.transform(FormTimeslotType, "FormTimeslotType", value);
    }

    public getFormTimeslotTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(FormTimeslotType, "FormTimeslotType", reverse);
    }

    public getFormTimeslotTypeName(value: FormTimeslotType): string {
        return this.getFormTimeslotTypeItem(value).text;
    }

    public getFormTimeslotTypeText(value: FormTimeslotType): string {
        return this.localizer.get(this.getFormTimeslotTypeName(value));
    }

    public getFormTimeslotTypeDescription(value: FormTimeslotType): string {
        return this.getFormTimeslotTypeItem(value).subtext;
    }

    // #endregion

    // #region CustomFormType

    public getCustomFormTypeItem(value: CustomFormType): SelectListItem {
        return this.transform(CustomFormType, "CustomFormType", value);
    }

    public getCustomFormTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(CustomFormType, "CustomFormType", reverse);
    }

    public getCustomFormTypeName(value: CustomFormType): string {
        return this.getCustomFormTypeItem(value).text;
    }

    public getCustomFormTypeText(value: CustomFormType): string {
        return this.localizer.get(this.getCustomFormTypeName(value));
    }

    public getCustomFormTypeDescription(value: CustomFormType): string {
        return this.getCustomFormTypeItem(value).subtext;
    }

    // #endregion

    // #region FormItemType

    public getFormItemTypeItem(value: FormItemType): SelectListItem {
        return this.transform(FormItemType, "FormItemType", value);
    }

    public getFormItemTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(FormItemType, "FormItemType", reverse);
    }

    public getFormItemTypeName(value: FormItemType): string {
        return this.getFormItemTypeItem(value).text;
    }

    public getFormItemTypeText(value: FormItemType): string {
        return this.localizer.get(this.getFormItemTypeName(value));
    }

    public getFormItemTypeDescription(value: FormItemType): string {
        return this.getFormItemTypeItem(value).subtext;
    }

    // #endregion

    // #region FormMeasuringOperator

    public getFormMeasuringOperatorItem(value: FormMeasuringOperator): SelectListItem {
        return this.transform(FormMeasuringOperator, "FormMeasuringOperator", value);
    }

    public getFormMeasuringOperatorItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(FormMeasuringOperator, "FormMeasuringOperator", reverse);
    }

    public getFormMeasuringOperatorName(value: FormMeasuringOperator): string {
        return this.getFormMeasuringOperatorItem(value).text;
    }

    public getFormMeasuringOperatorText(value: FormMeasuringOperator): string {
        return this.localizer.get(this.getFormMeasuringOperatorName(value));
    }

    public getFormMeasuringOperatorDescription(value: FormMeasuringOperator): string {
        return this.getFormMeasuringOperatorItem(value).subtext;
    }

    // #endregion

    // #region WorkOrderFormStatusFilter

    public getWorkOrderFormStatusFilterItem(value: WorkOrderFormStatusFilter): SelectListItem {
        return this.transform(WorkOrderFormStatusFilter, "WorkOrderFormStatusFilter", value);
    }

    public getWorkOrderFormStatusFilterItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(WorkOrderFormStatusFilter, "WorkOrderFormStatusFilter", reverse);
    }

    public getWorkOrderFormStatusFilterName(value: WorkOrderFormStatusFilter): string {
        return this.getWorkOrderFormStatusFilterItem(value).text;
    }

    public getWorkOrderFormStatusFilterText(value: WorkOrderFormStatusFilter): string {
        return this.localizer.get(this.getWorkOrderFormStatusFilterName(value));
    }

    public getWorkOrderFormStatusFilterDescription(value: WorkOrderFormStatusFilter): string {
        return this.getWorkOrderFormStatusFilterItem(value).subtext;
    }

    // #endregion

    // #region ExtraChargeTypeUnit

    public getExtraChargeTypeUnitItem(value: ExtraChargeTypeUnit): SelectListItem {
        return this.transform(ExtraChargeTypeUnit, "ExtraChargeTypeUnit", value);
    }

    public getExtraChargeTypeUnitItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ExtraChargeTypeUnit, "ExtraChargeTypeUnit", reverse);
    }

    public getExtraChargeTypeUnitName(value: ExtraChargeTypeUnit): string {
        return this.getExtraChargeTypeUnitItem(value).text;
    }

    public getExtraChargeTypeUnitText(value: ExtraChargeTypeUnit): string {
        return this.localizer.get(this.getExtraChargeTypeUnitName(value));
    }

    public getExtraChargeTypeUnitDescription(value: ExtraChargeTypeUnit): string {
        return this.getExtraChargeTypeUnitItem(value).subtext;
    }

    // #endregion

    // #region AttachmentType

    public getAttachmentTypeItem(value: AttachmentType): SelectListItem {
        return this.transform(AttachmentType, "AttachmentType", value);
    }

    public getAttachmentTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AttachmentType, "AttachmentType", reverse);
    }

    public getAttachmentTypeName(value: AttachmentType): string {
        return this.getAttachmentTypeItem(value).text;
    }

    public getAttachmentTypeText(value: AttachmentType): string {
        return this.localizer.get(this.getAttachmentTypeName(value));
    }

    public getAttachmentTypeDescription(value: AttachmentType): string {
        return this.getAttachmentTypeItem(value).subtext;
    }

    // #endregion

    // #region NotificationType

    public getNotificationTypeItem(value: NotificationType): SelectListItem {
        return this.transform(NotificationType, "NotificationType", value);
    }

    public getNotificationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(NotificationType, "NotificationType", reverse);
    }

    public getNotificationTypeName(value: NotificationType): string {
        return this.getNotificationTypeItem(value).text;
    }

    public getNotificationTypeText(value: NotificationType): string {
        return this.localizer.get(this.getNotificationTypeName(value));
    }

    public getNotificationTypeDescription(value: NotificationType): string {
        return this.getNotificationTypeItem(value).subtext;
    }

    // #endregion

    // #region NotificationInterval

    public getNotificationIntervalItem(value: NotificationInterval): SelectListItem {
        return this.transform(NotificationInterval, "NotificationInterval", value);
    }

    public getNotificationIntervalItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(NotificationInterval, "NotificationInterval", reverse);
    }

    public getNotificationIntervalName(value: NotificationInterval): string {
        return this.getNotificationIntervalItem(value).text;
    }

    public getNotificationIntervalText(value: NotificationInterval): string {
        return this.localizer.get(this.getNotificationIntervalName(value));
    }

    public getNotificationIntervalDescription(value: NotificationInterval): string {
        return this.getNotificationIntervalItem(value).subtext;
    }

    // #endregion

    // #region NotificationMediaType

    public getNotificationMediaTypeItem(value: NotificationMediaType): SelectListItem {
        return this.transform(NotificationMediaType, "NotificationMediaType", value);
    }

    public getNotificationMediaTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(NotificationMediaType, "NotificationMediaType", reverse);
    }

    public getNotificationMediaTypeName(value: NotificationMediaType): string {
        return this.getNotificationMediaTypeItem(value).text;
    }

    public getNotificationMediaTypeText(value: NotificationMediaType): string {
        return this.localizer.get(this.getNotificationMediaTypeName(value));
    }

    public getNotificationMediaTypeDescription(value: NotificationMediaType): string {
        return this.getNotificationMediaTypeItem(value).subtext;
    }

    // #endregion

    // #region NotificationStatus

    public getNotificationStatusItem(value: NotificationStatus): SelectListItem {
        return this.transform(NotificationStatus, "NotificationStatus", value);
    }

    public getNotificationStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(NotificationStatus, "NotificationStatus", reverse);
    }

    public getNotificationStatusName(value: NotificationStatus): string {
        return this.getNotificationStatusItem(value).text;
    }

    public getNotificationStatusText(value: NotificationStatus): string {
        return this.localizer.get(this.getNotificationStatusName(value));
    }

    public getNotificationStatusDescription(value: NotificationStatus): string {
        return this.getNotificationStatusItem(value).subtext;
    }

    // #endregion

    // #region RentalItemActionType

    public getRentalItemActionTypeItem(value: RentalItemActionType): SelectListItem {
        return this.transform(RentalItemActionType, "RentalItemActionType", value);
    }

    public getRentalItemActionTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(RentalItemActionType, "RentalItemActionType", reverse);
    }

    public getRentalItemActionTypeName(value: RentalItemActionType): string {
        return this.getRentalItemActionTypeItem(value).text;
    }

    public getRentalItemActionTypeText(value: RentalItemActionType): string {
        return this.localizer.get(this.getRentalItemActionTypeName(value));
    }

    public getRentalItemActionTypeDescription(value: RentalItemActionType): string {
        return this.getRentalItemActionTypeItem(value).subtext;
    }

    // #endregion

    // #region MounterPermission

    public getMounterPermissionItem(value: MounterPermission): SelectListItem {
        return this.transform(MounterPermission, "MounterPermission", value);
    }

    public getMounterPermissionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(MounterPermission, "MounterPermission", reverse);
    }

    public getMounterPermissionName(value: MounterPermission): string {
        return this.getMounterPermissionItem(value).text;
    }

    public getMounterPermissionText(value: MounterPermission): string {
        return this.localizer.get(this.getMounterPermissionName(value));
    }

    public getMounterPermissionDescription(value: MounterPermission): string {
        return this.getMounterPermissionItem(value).subtext;
    }

    // #endregion

    // #region WorkOrderContractType

    public getWorkOrderContractTypeItem(value: WorkOrderContractType): SelectListItem {
        return this.transform(WorkOrderContractType, "WorkOrderContractType", value);
    }

    public getWorkOrderContractTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(WorkOrderContractType, "WorkOrderContractType", reverse);
    }

    public getWorkOrderContractTypeName(value: WorkOrderContractType): string {
        return this.getWorkOrderContractTypeItem(value).text;
    }

    public getWorkOrderContractTypeText(value: WorkOrderContractType): string {
        return this.localizer.get(this.getWorkOrderContractTypeName(value));
    }

    public getWorkOrderContractTypeDescription(value: WorkOrderContractType): string {
        return this.getWorkOrderContractTypeItem(value).subtext;
    }

    // #endregion

    // #region CatalogType

    public getCatalogTypeItem(value: CatalogType): SelectListItem {
        return this.transform(CatalogType, "CatalogType", value);
    }

    public getCatalogTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(CatalogType, "CatalogType", reverse);
    }

    public getCatalogTypeName(value: CatalogType): string {
        return this.getCatalogTypeItem(value).text;
    }

    public getCatalogTypeText(value: CatalogType): string {
        return this.localizer.get(this.getCatalogTypeName(value));
    }

    public getCatalogTypeDescription(value: CatalogType): string {
        return this.getCatalogTypeItem(value).subtext;
    }

    // #endregion

    // #region WorkType

    public getWorkTypeItem(value: WorkType): SelectListItem {
        return this.transform(WorkType, "WorkType", value);
    }

    public getWorkTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(WorkType, "WorkType", reverse);
    }

    public getWorkTypeName(value: WorkType): string {
        return this.getWorkTypeItem(value).text;
    }

    public getWorkTypeText(value: WorkType): string {
        return this.localizer.get(this.getWorkTypeName(value));
    }

    public getWorkTypeDescription(value: WorkType): string {
        return this.getWorkTypeItem(value).subtext;
    }

    // #endregion

    // #region SsoProvider

    public getSsoProviderItem(value: SsoProvider): SelectListItem {
        return this.transform(SsoProvider, "SsoProvider", value);
    }

    public getSsoProviderItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SsoProvider, "SsoProvider", reverse);
    }

    public getSsoProviderName(value: SsoProvider): string {
        return this.getSsoProviderItem(value).text;
    }

    public getSsoProviderText(value: SsoProvider): string {
        return this.localizer.get(this.getSsoProviderName(value));
    }

    public getSsoProviderDescription(value: SsoProvider): string {
        return this.getSsoProviderItem(value).subtext;
    }

    // #endregion

    // #region DayOfWeek

    public getDayOfWeekItem(value: DayOfWeek): SelectListItem {
        return this.transform(DayOfWeek, "DayOfWeek", value);
    }

    public getDayOfWeekItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DayOfWeek, "DayOfWeek", reverse);
    }

    public getDayOfWeekName(value: DayOfWeek): string {
        return this.getDayOfWeekItem(value).text;
    }

    public getDayOfWeekText(value: DayOfWeek): string {
        return this.localizer.get(this.getDayOfWeekName(value));
    }

    public getDayOfWeekDescription(value: DayOfWeek): string {
        return this.getDayOfWeekItem(value).subtext;
    }

    // #endregion
}

//Singleton
export default new EnumProvider();