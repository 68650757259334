import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import {ICatalogDataProvider} from "@/providers/CatalogDataProvider";
import CatalogManagementDataProvider from "@/pages/CatalogManagement/CatalogManagementDataProvider";
import Catalog, {CatalogMode} from "@/components/Catalog/Catalog";
import Localizer from "@/localization/Localizer";
import {CatalogType} from "@/models/Enums";

import styles from "./CatalogManagement.module.scss";


export default class CatalogManagement extends AuthorizedPage {

    // Inherited / extended

    public getTitle(): string {
        return Localizer.catalogManagementPageTitle;
    }
    
    public getType(): CatalogType {
        return CatalogType.SalesProduct;
    }

    // Fields

    private readonly _dataProvider: ICatalogDataProvider = new CatalogManagementDataProvider();

    // Render(s)

    public render(): React.ReactNode {
        return (
            <PageContainer scale className={styles.catalogManagement}>

                <PageHeader title={this.getTitle}/>

                
                <Catalog type={this.getType()}
                         className={styles.categoriesProducts}
                         dataProvider={this._dataProvider}
                         mode={CatalogMode.Editor}
                />

            </PageContainer>
        );
    }
}