import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {BaseComponent, ch, IBaseComponent, TextAlign, PageCacheProvider} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, CellAction, CellModel, ColumnDefinition, ColumnType, Dropdown, DropdownAlign, Grid, GridHoveringType, GridModel, GridOddType, IconSize, Panel, RowModel} from "@renta-apps/athenaeum-react-components";
import WorkOrderModel from "../../../models/server/WorkOrderModel";
import {ActionType} from "@/models/Enums";
import TaskMounter from "../../../models/server/TaskMounter";
import SaveWorkOrderRequest from "../../../models/server/requests/SaveWorkOrderRequest";
import WarehouseTaskHoursPanel from "./WarehouseTaskHoursPanel/WarehouseTaskHoursPanel";
import WarehouseAddTaskModal from "./WarehouseAddTaskModal/WarehouseAddTaskModal";
import Warehouse from "../../../models/server/Warehouse";
import User from "@/models/server/User";
import GetSiteOrWarehouseWorkOrdersRequest from "@/models/server/requests/GetSiteOrWarehouseWorkOrdersRequest";
import SaveDescriptionRequest from "../../../models/server/requests/SaveDescriptionRequest";
import CreateWorkOrderRequest from "../../../models/server/requests/CreateWorkOrderRequest";
import GetEmployeesRequest from "@/models/server/requests/GetEmployeesRequest";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import Localizer from "../../../localization/Localizer";

import styles from "./WarehouseTasksPanel.module.scss";

interface IWarehouseTasksPanelProps {
    warehouse: Warehouse;
    readonly: boolean;
}

interface IWarehouseTasksPanelState {
    selectedTabIndex: number,
    taskDescriptionPopover: CellModel<WorkOrderModel> | null,
}

export default class WarehouseTasksPanel extends BaseComponent<IWarehouseTasksPanelProps, IWarehouseTasksPanelState> {

    state: IWarehouseTasksPanelState = {
        selectedTabIndex: 0,
        taskDescriptionPopover: null,
    };

    private readonly _workOrdersGridRef: React.RefObject<Grid<WorkOrderModel>> = React.createRef();
    private readonly _addTaskModalRef: React.RefObject<WarehouseAddTaskModal> = React.createRef();

    private readonly _workOrdersColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "code",
            minWidth: 65,
            noWrap: true,
            className: "grey"
        },
        {
            header: "fas sync-alt",
            minWidth: 40,
            type: ColumnType.Icon,
            accessor: (model) => WorkOrderModel.getStateWithBlockingIcon(model)
        },
        {
            header: Localizer.tasksPanelNameLanguageItemName,
            accessor: "name",
            editable: true,
            reRenderRow: true,
            minWidth: 280,
            maxWidth: 280,
            init: (cell) => this.initNameColumn(cell),
            settings: {
                descriptionTitle: "Description of the task",
                descriptionAccessor: nameof<WorkOrderModel>(model => model.description),
                descriptionMaxLength: RentaTaskConstants.bigStringLength,
                descriptionCallback: (cell) => this.updateTaskDescriptionAsync(cell)
            },
            callback: (cell: CellModel<WorkOrderModel>) => this.openAddTaskModalAsync(cell.row.model, cell),
        },
        {
            header: Localizer.addTaskModalMountersLanguageItemName,
            accessor: "mounters",
            type: ColumnType.Dropdown,
            settings: {
                fetchItems: async () => this.getWorkOrderMountersAsync(),
                align: DropdownAlign.Left,
                multiple: true,
                groupSelected: true,
                autoCollapse: true,
                nothingSelectedText: "0",
                selectedTextTransform: (dropdown: Dropdown<WorkOrderModel>) => dropdown.selectedListItems.length.toString()
            },
            editable: true,
            minWidth: 50,
            init: (cell) => this.initTaskColumn(cell),
            callback: async (cell: CellModel<WorkOrderModel>) => await this.onTaskMounterChangeAsync(cell)
        },
        {
            header: Localizer.tasksPanelStartLanguageItemName,
            group: Localizer.genericDateLanguageItemName,
            accessor: "activationDate",
            type: ColumnType.Date,
            format: "D",
            editable: true,
            reRenderRow: true,
            textAlign: TextAlign.Center,
            minWidth: 67,
            title: Localizer.tasksPanelStartInfoLanguageItemName,
            init: (cell) => this.initDateColumn(cell),
        },
        {
            header: Localizer.tasksPanelDoneLanguageItemName,
            group: Localizer.genericDateLanguageItemName,
            accessor: "completionDate",
            type: ColumnType.Date,
            format: "D",
            editable: true,
            reRenderRow: true,
            textAlign: TextAlign.Center,
            minWidth: 67,
            title: Localizer.tasksPanelComplitionDateLanguageItemName,
            init: (cell) => this.initCompletedAtColumn(cell),
        },
        {
            header: Localizer.tasksPanelPlannedDoneLanguageItemName,
            group: Localizer.genericDateLanguageItemName,
            accessor: "plannedCompletionDate",
            type: ColumnType.Date,
            format: "D",
            editable: true,
            reRenderRow: true,
            textAlign: TextAlign.Center,
            minWidth: 67,
            title: Localizer.tasksPanelPlannedCompletionDateLanguageItemName,
            init: (cell) => this.initPlannedCompletionDateColumn(cell),
        },
        {
            header: Localizer.tasksPanelActionsLanguageItemName,
            minWidth: 100,
            init: (cell) => this.initTaskOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.tasksPanelCommitChangesLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processTaskOperationAsync(cell, action.action.name!)
                },
                {
                    name: "cancel",
                    title: Localizer.tasksPanelCancelChangesLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processTaskOperationAsync(cell, action.action.name!)
                },
                {
                    name: "complete",
                    title: Localizer.tasksPanelCompleteTaskLanguageItemName,
                    icon: "far check-circle",
                    type: ActionType.Default,
                    callback: async (cell, action) => await this.processTaskOperationAsync(cell, action.action.name!)
                },
                {
                    name: "activate",
                    title: Localizer.tasksPanelActivateTaskLanguageItemName,
                    icon: "far play-circle",
                    type: ActionType.Default,
                    callback: async (cell, action) => await this.processTaskOperationAsync(cell, action.action.name!)
                },
                {
                    name: "approve",
                    title: Localizer.tasksPanelApproveTaskCreateLanguageItemName,
                    icon: "far thumbs-up",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processTaskOperationAsync(cell, action.action.name!)
                },
                {
                    name: "unlock",
                    icon: "fas unlock-alt",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processTaskOperationAsync(cell, action.action.name!)
                },
                {
                    name: "delete",
                    title: Localizer.tasksPanelDeleteTaskLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processTaskOperationAsync(cell, action.action.name!)
                },
                {
                    name: "restore",
                    title: Localizer.tasksPanelRestoreTaskLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processTaskOperationAsync(cell, action.action.name!)
                }
            ]
        },
    ];

    private async updateTaskDescriptionAsync(cell: CellModel<WorkOrderModel>): Promise<void> {
        const model: WorkOrderModel = cell.model;

        const request = new SaveDescriptionRequest();
        request.id = model.id;
        request.description = model.description;

        await this.workOrdersGrid.postAsync("api/constructionSiteManagement/saveTaskDescription", request);
    }

    private async getTasksAsync(): Promise<WorkOrderModel[]> {
        const request = new GetSiteOrWarehouseWorkOrdersRequest();
        request.constructionSiteOrWarehouseId = this.warehouseId;

        return await PageCacheProvider.getAsync("getTasksAsync", () => this.workOrdersGrid.postAsync("api/warehouse/getWarehouseTasks", request));
    }

    private async getMountersAsync(sender: IBaseComponent): Promise<TaskMounter[]> {
        const request = new GetEmployeesRequest();
        request.warehouseId = this.warehouseId;
        // Warehouses does not support subcontractors yet.
        request.excludeSubcontractorEmployees = true;
        
        return await PageCacheProvider.getAsync("getMountersAsync", () => sender.postAsync("api/warehouse/getEmployees", request));
    }

    private async getWorkOrderMountersAsync(): Promise<TaskMounter[]> {
        return await this.getMountersAsync(this.workOrdersGrid.instance);
    }

    private async addWorkOrderAsync(request: CreateWorkOrderRequest): Promise<void> {
        request.constructionSiteOrWarehouseId = this.warehouseId;

        await this.workOrdersGrid.postAsync("/api/RentaTasks/createWorkOrder", request);

        PageCacheProvider.clear();

        await this.workOrdersGrid.reloadAsync();
    }

    private isTaskValid(task: WorkOrderModel): boolean {
        let isValid = (task.name != null && task.name.length > 0);
        isValid = isValid && ((task.completionDate == null) || ((task.activationDate != null) && (task.completionDate.valueOf() >= task.activationDate.valueOf())));
        isValid = isValid && this.plannedCompletionDateValid(task);
        return isValid;
    }

    private initTasksRow(row: RowModel<WorkOrderModel>): void {

        const model: WorkOrderModel = row.model;

        const isInvoiced: boolean = model.invoiced;
        const isApproved: boolean = (model.approved);
        const isReadonly: boolean = (isApproved || model.deleted);

        row.className = (isInvoiced)
            ? "bg-processed"
            : (isApproved)
                ? "bg-approved"
                : (model.completed)
                    ? "bg-completed"
                    : "";

        row.readonly = (isReadonly);
    }

    private initTaskColumn(cell: CellModel<WorkOrderModel>): void {
        cell.className =  "green";
    }

    private initNameColumn(cell: CellModel<WorkOrderModel>): void {
        const model: WorkOrderModel = cell.row.model;
        cell.title = model.description || "";

        const deleted: boolean = cell.row.deleted;
        const completed: boolean = model.completed;
        const active: boolean = Utility.inInterval(Utility.now(), model.activationDate, model.completionDate);
        const locked: boolean = model.locked;
        if  ((!locked) && (!deleted)  && ((!completed) || (active))) {
            cell.className = styles.linkColumn;
        }
    }

    private initDateColumn(cell: CellModel<WorkOrderModel>): void {
        const model: WorkOrderModel = cell.row.model;
        cell.valid = ((model.completionDate == null) || ((model.activationDate != null) && (model.completionDate.valueOf() >= model.activationDate.valueOf())));
    }

    private initCompletedAtColumn(cell: CellModel<WorkOrderModel>): void {
        const model: WorkOrderModel = cell.row.model;
        cell.valid = ((model.completionDate == null) || ((model.activationDate != null) && (model.completionDate.valueOf() >= model.activationDate.valueOf())));
    }

    private initPlannedCompletionDateColumn(cell: CellModel<WorkOrderModel>): void {
        const model: WorkOrderModel = cell.row.model;
        cell.valid = this.plannedCompletionDateValid(model);
    }

    private plannedCompletionDateValid(model: WorkOrderModel): boolean {
        if (model.activationDate !== null) {
            if (model.plannedCompletionDate !== null) {
                return model.activationDate <= model.plannedCompletionDate;
            }
        }

        return true;
    }

    private initTaskOperations(cell: CellModel<WorkOrderModel>): void {
        const model: WorkOrderModel = cell.row.model;
        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const completed: boolean = model.completed;
        const active: boolean = Utility.inInterval(Utility.now(), model.activationDate, model.completionDate);
        const futureActivate: boolean = (Utility.inFuture(model.activationDate));
        const isValid: boolean = this.isTaskValid(model);
        const approvable = true;
        const approved: boolean = (model.approved);
        const locked: boolean = model.locked;
        const isSubcontractorManager: boolean = this.isSubcontractorManager;

        const saveAction: CellAction<WorkOrderModel> = cell.actions[0];
        const cancelAction: CellAction<WorkOrderModel> = cell.actions[1];
        const completeAction: CellAction<WorkOrderModel> = cell.actions[2];
        const activateAction: CellAction<WorkOrderModel> = cell.actions[3];
        const approveAction: CellAction<WorkOrderModel> = cell.actions[4];
        const unlockAction: CellAction<WorkOrderModel> = cell.actions[5];
        const deleteAction: CellAction<WorkOrderModel> = cell.actions[6];
        const restoreAction: CellAction<WorkOrderModel> = cell.actions[7];

        saveAction.visible = (!locked) && (modified) && (isValid) && (!deleted) && (!approved) && (!isSubcontractorManager);
        cancelAction.visible = (!locked) && (modified) && (!deleted) && (!approved) && (!isSubcontractorManager);
        completeAction.visible = (!locked) && (!modified) && (!deleted) && (!completed) && (!approved) && (active);
        approveAction.visible = (!locked) && (approvable) && (!deleted) && (!modified) && (completed) && (!approved) && (!isSubcontractorManager);
        unlockAction.visible = (!locked) && (approvable) && (approved) && (!isSubcontractorManager) && (!isSubcontractorManager);
        activateAction.visible = (!locked) && (!deleted) && (!modified) && (!approved) && ((completed) || (!active));
        activateAction.action.type = (futureActivate) ? ActionType.Muted : ActionType.Blue;
        deleteAction.visible = (!locked) && (!deleted) && (!approved) && (!isSubcontractorManager);
        restoreAction.visible = (!locked) && (deleted) && (!isSubcontractorManager);
    }


    private processTaskOperationAsync = async (cell: CellModel<WorkOrderModel>, actionName: string): Promise<void> => {

        const model: WorkOrderModel = cell.row.model;

        if (actionName === "save") {

            const request = new SaveWorkOrderRequest();
            request.workOrderId = model.id;
            request.mounters = model.mountersIds;
            request.equipment = null;
            request.activationDate = model.activationDate;
            request.completionDate = model.completionDate;
            request.plannedCompletionDate = model.plannedCompletionDate;
            request.name = model.name!;
            request.description = model.description;
            request.contractType = model.contractType;

            cell.row.model = await cell.grid.postAsync("api/constructionSiteManagement/saveWorkOrder", request);

            await cell.row.bindAsync();

        } else if (actionName === "cancel") {

            await cell.row.cancelAsync();

        } else if (actionName === "complete") {

            model.completed = true;
            model.completionDate = model.completionDate || new Date();
            model.completedBy = ch.getUser<User>();

            await cell.grid.postAsync("api/constructionSiteManagement/completeTask", model.id);

            await cell.row.bindAsync();

        } else if (actionName === "activate") {

            if (model.completed) {
                model.completed = false;
                model.completionDate = null;
                model.completedBy = null;
            } else {
                model.activationDate = Utility.date();
            }

            await cell.grid.postAsync("api/constructionSiteManagement/activateTask", model.id);

            await cell.row.bindAsync();

        } else if (actionName === "approve") {

            model.approved = true;
            model.approvedAt = new Date();
            model.approvedBy = ch.getUser<User>();

            await cell.grid.postAsync("api/constructionSiteManagement/approveTask", model.id);

            await cell.row.bindAsync();


        } else if (actionName === "unlock") {

            model.approved = false;
            model.approvedAt = null;
            model.approvedBy = null;

            await cell.grid.postAsync("api/constructionSiteManagement/unApproveTask", model.id);

            await cell.row.bindAsync();


        } else if (actionName === "delete") {

            const deletePermanently: boolean = (model.id == "");

            if (deletePermanently) {
                await cell.grid.deleteAsync(cell.row.index);
            } else {

                await cell.grid.postAsync("api/constructionSiteManagement/deleteTask", model.id);

                await cell.row.setDeletedAsync(true);

            }

        } else if (actionName === "restore") {

            const restoreOnServer = (model.id != "");
            if (restoreOnServer) {

                await cell.grid.postAsync("api/constructionSiteManagement/restoreTask", model.id);

            }

            await cell.row.setDeletedAsync(false);
        }
    }

    private async onTaskMounterChangeAsync(cell: CellModel<WorkOrderModel>): Promise<void> {
        const model: WorkOrderModel = cell.row.model;
        const mounters: any[] = (model.mountersIds as any[]);
        const mounterIds: string[] = mounters.map(item => (item.isTaskMounter) ? ((item as TaskMounter).user.id) : (item as string));
        cell.setValue(mounterIds);
        await cell.reloadAsync();
    }

    private async openAddTaskModalAsync(task: WorkOrderModel | null = null, cell: CellModel<WorkOrderModel> | null = null): Promise<void> {
        if (this.isSubcontractorManager) {
            return;
        }

        if (cell) {
            const model: WorkOrderModel = cell.row.model;
            const deleted: boolean = cell.row.deleted;
            const completed: boolean = model.completed;
            const active: boolean = Utility.inInterval(Utility.now(), model.activationDate, model.completionDate);
            const locked: boolean = model.locked;
            if ((!locked) && (!deleted) && ((!completed) || (active))) {
                await this._addTaskModalRef.current!.openAsync(task, cell);
            }
        } else {
            await this._addTaskModalRef.current!.openAsync(task, cell);
        }
    }

    private get warehouseId(): string {
        return this.props.warehouse.id;
    }

    private get workOrdersGrid(): GridModel<WorkOrderModel> {
        return this._workOrdersGridRef.current!.model;
    }

    private get readonly(): boolean {
        return this.props.readonly;
    }

    private get addButtonTitle(): string {
        return (this.state.selectedTabIndex === 0)
            ? Localizer.tasksPanelAddTaskTitle
            : (this.state.selectedTabIndex === 1)
                ? Localizer.tasksPanelAddWorkReportTitle : "";
    }

    private get isSubcontractorManager(): boolean {
        return ch.getUser<User>().isSubcontractorManager;
    }

    public async reloadAsync(settings: { awaitTasks?: boolean, awaitWorkReports?: boolean, awaitRents?: boolean } | null = null): Promise<void> {
        PageCacheProvider.clear();

        if (settings && settings.awaitTasks) {
            await this.workOrdersGrid.reloadAsync();
        } else {
            this.workOrdersGrid.reload();
        }
    }

    public reload(): void {
        // reload without await
        // noinspection JSIgnoredPromiseFromCall
        this.reloadAsync();
    }

    public async reloadTasksAsync(): Promise<void> {
        PageCacheProvider.clear();
        await this.workOrdersGrid.reloadAsync();
    }

    private renderDetailsContent(row: RowModel<WorkOrderModel>): React.ReactNode {
        return (
            <WarehouseTaskHoursPanel taskRow={row} readonly={this.readonly}/>
        );
    }


    public render(): React.ReactNode {

        return (
            <Panel title={Localizer.genericTasks}
                   customHeading={
                       <div className="col-6 d-flex justify-content-sm-between">
                           <h3 className="m-0">{Localizer.genericTasks}</h3>

                           {
                               !this.isSubcontractorManager &&
                               (
                                   <div>

                                       <span style={{paddingRight: "8px"}}>{this.addButtonTitle}</span>

                                       <Button icon={{name: "plus", size: IconSize.Large}}
                                               type={ButtonType.Orange}
                                               onClick={async () => await this.openAddTaskModalAsync()}
                                               disabled={this.readonly}
                                       />


                                       <Button title="Reload" className="ml-1"
                                               icon={{name: "far history", size: IconSize.Large}}
                                               type={ButtonType.Info}
                                               onClick={async () => await this.reloadAsync()}
                                       />

                                   </div>
                               )
                           }

                       </div>
                   }
            >
                <div className={styles.tasksPanel}>

                    <div>
                        <div className="col-12">

                            <div>
                                <Grid ref={this._workOrdersGridRef}
                                      id="tasksGrid"
                                      minWidth={"auto"}
                                      hovering={GridHoveringType.Row}
                                      odd={GridOddType.None}
                                      noDataText={Localizer.gridNoDataTasks}
                                      columns={this._workOrdersColumns}
                                      initRow={(row) => this.initTasksRow(row)}
                                      fetchData={async () => await this.getTasksAsync()}
                                      renderDetails={(row) => this.renderDetailsContent(row)}
                                      readonly={this.readonly}
                                />
                            </div>

                        </div>
                    </div>

                    <WarehouseAddTaskModal ref={this._addTaskModalRef}
                                           warehouseId={this.warehouseId}
                                           fetchMounters={async (sender) => await this.getMountersAsync(sender)}
                                           addWorkOrder={async (_, request) => await this.addWorkOrderAsync(request)}
                                           taskOperation={this.processTaskOperationAsync}
                    />

                </div>
            </Panel>
        );
    }
};