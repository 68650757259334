import React from "react";
import RentaTasksWizardPage from "@/pages/RentaTasks/RentaTasksWizardPage";
import {DropdownRequiredType, DropdownWidget} from "@renta-apps/athenaeum-react-components";
import {IBaseComponent} from "@renta-apps/athenaeum-react-common";
import Organization from "@/models/server/Organization";
import Localizer from "@/localization/Localizer";

interface ISelectOrganizationState {
}

interface ISelectOrganizationProps {
}

export default class SelectOrganization extends RentaTasksWizardPage<ISelectOrganizationProps, ISelectOrganizationState> {

    private readonly StpOrganizationsId = "StpOrganizations";

    private async fetchOrganizationsAsync(sender: IBaseComponent): Promise<Organization[]> {
        return await sender.postAsync("api/rentaTasks/getOrganizations", null);
    }

    private get organization(): Organization | null {
        return this.wizard.organization;
    }

    private async onSetOrganizationAsync(organization: Organization | null): Promise<void> {
        this.wizard.organization = organization;
        this.saveContext();
        await this.reRenderAsync();
    }

    protected get canNext(): boolean {
        return (!!this.organization);
    }

    protected get workOrderRequired(): boolean {
        return false;
    }

    public getManual(): string {
        return ""
    }

    renderContent(): React.ReactNode {
        return (
            <DropdownWidget<Organization> required wide autoCollapse autoGroupSelected
                                          id={this.StpOrganizationsId}
                                          requiredType={DropdownRequiredType.Manual}
                                          icon={{name: "fas users-cog"}}
                                          label={Localizer.rentaTasksControllerStepsActivateConstructionSiteTitleSelectCustomer}
                                          fetchDataAsync={async (sender) => await this.fetchOrganizationsAsync(sender)}
                                          selectedItem={this.organization || undefined}
                                          onChange={async (_, item) => await this.onSetOrganizationAsync(item)}
            />
        );
    }
}