//Autogenerated

import {BaseComponentLocalizer} from "@renta-apps/athenaeum-react-common";

class SignatureWidgetLocalizer extends BaseComponentLocalizer {

    //Constants
    public readonly clearLanguageItemName: string = `Clear`;
    public readonly doneLanguageItemName: string = `Done`;

    constructor() {

        super(
            [
                { code: "en", label: "English" },
                { code: "nb", label: "Norsk bokmål" },
                { code: "pl", label: "Polski" },
                { code: "sv", label: "Svenska" },
                { code: "fi", label: "Suomi" },
                { code: "da", label: "Dansk" },
                { code: "uk", label: "Українська" },
                { code: "ru", label: "Русский" }
            ],
            "en");
        
        //Initializer
        this.set(this.clearLanguageItemName, { language: `en`, value: `Clear` }, { language: `uk`, value: `Очистити` }, { language: `sv`, value: `Töm` }, { language: `ru`, value: `Очистить` }, { language: `pl`, value: `Wyczyść` }, { language: `nb`, value: `Tøm` }, { language: `fi`, value: `Tyhjennä` }, { language: `da`, value: `Klar` },);
        this.set(this.doneLanguageItemName, { language: `en`, value: `Done` }, { language: `uk`, value: `Завершити` }, { language: `sv`, value: `Färdig` }, { language: `ru`, value: `Завершить` }, { language: `pl`, value: `Gotowe` }, { language: `nb`, value: `Ferdig` }, { language: `fi`, value: `Valmis` }, { language: `da`, value: `Udført` },);
    }

    /**
    /* "Clear" (Clear)
    */
    public get clear() : string {
        return this.get(this.clearLanguageItemName);
    }

    /**
    /* "Done" (Done)
    */
    public get done() : string {
        return this.get(this.doneLanguageItemName);
    }
}

//Singleton
export default new SignatureWidgetLocalizer();