import React from "react";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, Checkbox, Dropdown, EmailInput, Form, Modal, OneColumn, PhoneInput, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import User from "../../models/server/User";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import {AuthType, CustomerApprovalType, WorkOrderContactPersonType} from "@/models/Enums";
import ApproveWorkOrderByEmailResponse from "@/models/server/responses/ApproveWorkOrderByEmailResponse";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import GetAndCreateIfMissingContactPersonResponse from "@/models/server/responses/GetAndCreateIfMissingContactPersonResponse";
import GetAndCreateIfMissingContactPersonRequest from "@/models/server/requests/GetAndCreateIfMissingContactPersonRequest";
import ApproveWorkOrderByEmailRequest from "../../models/server/requests/ApproveWorkOrderByEmailRequest";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "../../localization/Localizer";

import styles from "./SendWorkOrderModal.module.scss";


/** @internal */
type SendWorkOrderModalActionType = "displayInfo" | "editApprover" | "editOrderer" | "sendOrApprove";

export enum SendWorkOrderModalAction {

    /**
     * Display readonly information of the Work Order {@link WorkOrderModel.customerApprover}
     */
    DisplayWorkOrderApprover,

    /**
     * Display readonly information of the Work Order {@link WorkOrderModel.customerOrderer}
     */
    DisplayWorkOrderOrderer,

    /**
     * Create a new Contact Person and assign as the Work Orders {@link WorkOrderModel.customerApprover}
     */
    CreateWorkOrderApprover,

    /**
     * Create a new Contact Person and assign as the Work Orders {@link WorkOrderModel.customerOrderer}
     */
    CreateWorkOrderOrderer,

    /**
     * Edit the Work Orders existing {@link WorkOrderModel.customerApprover}
     */
    EditWorkOrderApprover,

    /**
     * Edit the Work Orders existing {@link WorkOrderModel.customerOrderer}
     */
    EditWorkOrderOrderer,

    /**
     * Select an existing Contact Person as the Work Orders {@link WorkOrderModel.customerApprover},
     * create a new Contact Person and assign as the Work Orders {@link WorkOrderModel.customerApprover}, or
     * edit the Work Orders existing {@link WorkOrderModel.customerApprover}
     */
    SelectCreateEditWorkOrderApprover,

    /**
     * Select an existing Contact Person as the Work Orders {@link WorkOrderModel.customerOrderer},
     * create a new Contact Person and assign as the Work Orders {@link WorkOrderModel.customerOrderer}, or
     * edit the Work Orders existing {@link WorkOrderModel.customerOrderer}
     */
    SelectCreateEditWorkOrderOrderer,

    /**
     * Select an existing Contact Person as the Work Orders {@link WorkOrderModel.customerApprover},
     * create a new Contact Person and assign as the Work Orders {@link WorkOrderModel.customerApprover}, or
     * edit the Work Orders existing {@link WorkOrderModel.customerApprover} and
     * send the Work Order to be approved by the Work Orders {@link WorkOrderModel.customerApprover}
     */
    SelectCreateEditWorkOrderApproverAndSendWorkOrder,

    /**
     * Select an existing Contact Person as the Work Orders {@link WorkOrderModel.customerApprover},
     * create a new Contact Person and assign as the Work Orders {@link WorkOrderModel.customerApprover}, or
     * edit the Work Orders existing {@link WorkOrderModel.customerApprover} and
     * approve the Work Order
     */
    SelectCreateEditWorkOrderApproverAndApproveWorkOrder,
}

/**
 * Data passed to the {@link SendWorkOrderModal} on open
 */
export interface ISendWorkOrderModalData {

    /**
     * Work Order which {@link WorkOrderModel.customerApprover} or {@link WorkOrderModel.customerOrderer} to display/create/edit, and which to possibly approve or send.
     *
     * NOTE: The Work Order is mutated by the {@link SendWorkOrderModal}
     */
    workOrder: WorkOrderModel;

    /**
     * Action to perform in the opened {@link SendWorkOrderModal}
     */
    action: SendWorkOrderModalAction;
}

/**
 * Props for {@link SendWorkOrderModal}
 */
export interface ISendWorkOrderModalProps {

    /**
     * Called when the {@link SendWorkOrderModal} closes
     *
     * @param sender The closing {@link SendWorkOrderModal}
     * @param workOrder The Work Order modified by the {@link SendWorkOrderModal}
     * @param action Action performed in the {@link SendWorkOrderModal}
     * @param success Was the {@link action} performed successfully
     */
    onClose(sender: Readonly<SendWorkOrderModal>, workOrder: WorkOrderModel, action: SendWorkOrderModalAction, success: boolean): Promise<void>;

    getContactPersons?(sender: IBaseComponent, constructionSiteOrWarehouseId: string): Promise<User[]>;

    getAndCreateIfMissingContactPerson?(sender: IBaseComponent, request: GetAndCreateIfMissingContactPersonRequest): Promise<GetAndCreateIfMissingContactPersonResponse>;

    approveWorkOrderByEmail?(sender: IBaseComponent, request: ApproveWorkOrderByEmailRequest): Promise<ApproveWorkOrderByEmailResponse>;
}

interface ISendWorkOrderModalState {
    contactPersons: User[];
    contactPerson: User | null;
    createNewContactPerson: boolean;
    initialized: boolean;

    // Contact Person data

    email?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    phone?: string;
}

export default class SendWorkOrderModal extends BaseComponent<ISendWorkOrderModalProps, ISendWorkOrderModalState> {

    //#region Fields

    public static readonly modalId = "sendWorkReportModal" as const;

    public state: ISendWorkOrderModalState = {
        contactPersons: [],
        contactPerson: null,
        createNewContactPerson: false,
        initialized: false
    };

    private readonly _modalRef: React.RefObject<Modal<ISendWorkOrderModalData>> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private _success: boolean = false;

    private get createNewContactPerson(): boolean {
        return this.state.createNewContactPerson;
    }

    private get modal(): Modal<ISendWorkOrderModalData> {
        return this._modalRef.current!;
    }

    private get readonly(): boolean {
        return (!this.state.initialized)
            || (!this.hasData)
            || (this.isDisplayContactPersonAction);
    }

    private get isDisplayContactPersonAction(): boolean {
        return (this.action === SendWorkOrderModalAction.DisplayWorkOrderApprover)
            || (this.action === SendWorkOrderModalAction.DisplayWorkOrderOrderer);
    }

    private get emailReadonly(): boolean {
        return (this.readonly) || (this.selectedContactPerson?.authType === AuthType.Email);
    }

    private get phoneReadonly(): boolean {
        return (this.readonly) || (this.selectedContactPerson?.authType === AuthType.Phone);
    }

    private get nameReadonly(): boolean {
        return (this.readonly)
            || ((!this.createNewContactPerson)
            && ((!this.selectedContactPerson) || (WorkOrderModel.isApproverOrOrdererValid(this.selectedContactPerson))));
    }

    private get sendOrApproveButtonReadonly(): boolean {
        return (!this.hasContactPersons) && (!this.createNewContactPerson);
    }

    private get hasData(): boolean {
        return (!!this.modal?.data);
    }

    private get data(): ISendWorkOrderModalData {
        return this.modal.data!;
    }

    private get contactPersons(): User[] {
        return (this.createNewContactPerson)
            ? []
            : this.state.contactPersons;
    }

    private get hasContactPersons(): boolean {
        return (this.state.contactPersons.length > 0);
    }

    private get selectedContactPerson(): User | null {
        return (this.createNewContactPerson)
            ? null
            : this.state.contactPerson;
    }

    private get type(): SendWorkOrderModalActionType {
        return (this.readonly)
            ? "displayInfo"
            : (this.action === SendWorkOrderModalAction.SelectCreateEditWorkOrderApproverAndApproveWorkOrder) || (this.action === SendWorkOrderModalAction.SelectCreateEditWorkOrderApproverAndSendWorkOrder)
                ? "sendOrApprove"
                : (this.contactPersonType === WorkOrderContactPersonType.Approver)
                    ? "editApprover"
                    : "editOrderer";
    }

    private get title(): string {
        switch (this.type) {
            case "displayInfo":     return Localizer.genericContactPersonDetails;
            case "sendOrApprove":   return Localizer.sendWorkReportTitle;
            case "editApprover":    return Localizer.sendWorkOrderModalApproverTitle;
            case "editOrderer":     return Localizer.sendWorkOrderModalOrdererTitle;
            default: throw new TypeError(`Invalid type ${this.type}`);
        }
    }

    private get subtitle(): string {
        switch (this.type) {
            case "displayInfo":     return "";
            case "sendOrApprove":   return Localizer.sendWorkReportSubtitle;
            case "editApprover":    return Localizer.sendWorkOrderModalApproverSubtitle;
            case "editOrderer":     return Localizer.sendWorkOrderModalOrdererSubtitle;
            default: throw new TypeError(`Invalid type ${this.type}`);
        }
    }

    private get workOrder(): WorkOrderModel {
        return this.data.workOrder;
    }

    private get constructionSiteOrWarehouseId(): string {
        return WorkOrderModel.constructionSiteOrWarehouseId(this.workOrder)!;
    }

    private get action(): SendWorkOrderModalAction {
        return this.data.action;
    }

    /**
     * If true, an existing Contact Person can be selected and creation of new and editing of existing Contact Persons can be toggled;
     * otherwise existing Contact Persons cannot be selected and creation of new and editing of existing Contact Persons cannot be toggled
     */
    private get canSelectExistingAndToggleCreationOfNewContactPersons(): boolean {
        return (this.action === SendWorkOrderModalAction.SelectCreateEditWorkOrderApprover)
            || (this.action === SendWorkOrderModalAction.SelectCreateEditWorkOrderOrderer)
            || (this.action === SendWorkOrderModalAction.SelectCreateEditWorkOrderApproverAndApproveWorkOrder)
            || (this.action === SendWorkOrderModalAction.SelectCreateEditWorkOrderApproverAndSendWorkOrder);
    }

    private get workOrderId(): string {
        return this.workOrder.id;
    }

    private get email(): string {
        return this.state.email ?? "";
    }

    private get phone(): string {
        return this.state.phone ?? "";
    }

    private get firstName(): string {
        return this.state.firstName ?? "";
    }

    private get middleName(): string {
        return this.state.middleName ?? "";
    }

    private get lastName(): string {
        return this.state.lastName ?? "";
    }

    /**
     * Type of the Work Order Contact Person displayed/created/edited in the {@link SendWorkOrderModal} ({@link WorkOrderModel.customerApprover} or {@link WorkOrderModel.customerOrderer})
     */
    public get contactPersonType(): WorkOrderContactPersonType {
        switch (this.action) {
            case SendWorkOrderModalAction.DisplayWorkOrderOrderer:
            case SendWorkOrderModalAction.CreateWorkOrderOrderer:
            case SendWorkOrderModalAction.EditWorkOrderOrderer:
            case SendWorkOrderModalAction.SelectCreateEditWorkOrderOrderer:
                return WorkOrderContactPersonType.Orderer;
            default:
                return WorkOrderContactPersonType.Approver;
        }
    }

    /**
     * If the {@link SendWorkOrderModal} is opened normally via {@link SendWorkOrderModal.openAsync}, does nothing.
     * If the {@link SendWorkOrderModal} is opened magically by a Grid, then sets the default Modal data (openAsync is not called in that case).
     */
    private static transformModalData(dataOrWorkOrder: WorkOrderModel | ISendWorkOrderModalData): ISendWorkOrderModalData {
        return ((dataOrWorkOrder instanceof WorkOrderModel) || ((dataOrWorkOrder as unknown as WorkOrderModel).isWorkOrderModel))
            ? {
                workOrder: dataOrWorkOrder as WorkOrderModel,
                action: SendWorkOrderModalAction.SelectCreateEditWorkOrderApproverAndApproveWorkOrder,
            }
            : dataOrWorkOrder as ISendWorkOrderModalData;
    }

    private async clearStateAsync(): Promise<void> {
        this._success = false;

        await this.setState({
            initialized: false,
            contactPersons: [],
            contactPerson: null,
            createNewContactPerson: false,
            email: undefined,
            phone: undefined,
            firstName: undefined,
            middleName: undefined,
            lastName: undefined,
        });
    }

    private async setContactPersonAsync(contactPerson: User | null): Promise<void> {
        if (contactPerson) {
            await this.initializeContractPersonFieldsAsync(contactPerson);

            await this.setState({contactPerson});   
        }
    }

    private async initializeContractPersonFieldsAsync(contactPerson: User | null): Promise<void> {
        if (contactPerson) {
            await this.setState({
                email: contactPerson.email,
                phone: contactPerson.phone,
                firstName: contactPerson.firstname,
                middleName: contactPerson.middleName,
                lastName: contactPerson.lastName,
            });
        }
    }

    private async setCreateNewContactPersonAsync(createNewContactPerson: boolean): Promise<void> {
        const contactPerson: User | null = (createNewContactPerson)
            ? new User()
            : this.state.contactPerson;

        await this.initializeContractPersonFieldsAsync(contactPerson);

        await this.setState({createNewContactPerson});
    }

    private async getContactPersonsAsync(): Promise<User[]> {
        return (this.props.getContactPersons)
            ? this.props.getContactPersons(this.modal, this.constructionSiteOrWarehouseId)
            : await this.modal.postAsync("api/workOrders/getContactPersons", this.constructionSiteOrWarehouseId);

    }

    private async getAndCreateIfMissingContactPersonAsync(request: GetAndCreateIfMissingContactPersonRequest): Promise<GetAndCreateIfMissingContactPersonResponse> {
        return (this.props.getAndCreateIfMissingContactPerson)
            ? this.props.getAndCreateIfMissingContactPerson(this.modal, request)
            : await this.modal.postAsync("api/workOrders/getAndCreateIfMissingContactPerson", request);

    }

    private async approveWorkOrderByEmailAsync(request: ApproveWorkOrderByEmailRequest): Promise<ApproveWorkOrderByEmailResponse> {
        return (this.props.approveWorkOrderByEmail)
            ? await this.props.approveWorkOrderByEmail(this.modal, request)
            : await this.modal.postAsync("api/workOrders/approveWorkOrderByEmail", request);

    }

    private async onOpenAsync(): Promise<void> {
        this._success = false;

        const contactPersons: User[] = (this.canSelectExistingAndToggleCreationOfNewContactPersons)
            ? await this.getContactPersonsAsync()
            : [];

        const workOrderApproverOrOrderer: User | null = (this.contactPersonType === WorkOrderContactPersonType.Approver)
            ? this.workOrder.customerApprover
            : this.workOrder.customerOrderer;

        const explicitlyCreatingNew: boolean =
            (this.action === SendWorkOrderModalAction.CreateWorkOrderApprover) ||
            (this.action === SendWorkOrderModalAction.CreateWorkOrderOrderer);

        const explicitlyEditingExisting: boolean =
            (this.action === SendWorkOrderModalAction.EditWorkOrderApprover) ||
            (this.action === SendWorkOrderModalAction.EditWorkOrderOrderer);

        const createNewContactPerson: boolean =
            (explicitlyCreatingNew || (!explicitlyEditingExisting && (contactPersons.length <= 0)))
            && ((this.hasData) && (!this.isDisplayContactPersonAction));

        const contactPerson: User | null = (!workOrderApproverOrOrderer) || (createNewContactPerson)
            ? null
            : (this.canSelectExistingAndToggleCreationOfNewContactPersons)
                ? contactPersons.find(contactPerson => (contactPerson.id === workOrderApproverOrOrderer.id)) || null
                : workOrderApproverOrOrderer;

        await this.initializeContractPersonFieldsAsync(contactPerson);

        await this.setState({
            contactPersons,
            contactPerson,
            createNewContactPerson,
            initialized: true,
        });
    }

    private async onCloseAsync(): Promise<void> {
        await this.props.onClose(
            this,
            this.workOrder,
            this.action,
            this._success
        );

        await this.clearStateAsync();
    }

    private async submitAsync(approveWorkOrder: boolean): Promise<void> {
        if (this.type === "displayInfo") {
            throw new Error(`Cannot submit a readonly modal`);
        }

        if (!this.nameReadonly) {
            const isValid: boolean = await this._formRef.current!.validateAsync();

            if (!isValid) {
                return;
            }
        }

        let success: boolean;
        let workOrder: WorkOrderModel | null = null;
        let contactPerson: User | null;
        let contactPersonFullName: string;

        if (this.type === "editApprover" || this.type === "editOrderer") {
            const getAndCreateIfMissingContactPersonRequest = new GetAndCreateIfMissingContactPersonRequest();

            getAndCreateIfMissingContactPersonRequest.constructionSiteId = this.constructionSiteOrWarehouseId;
            getAndCreateIfMissingContactPersonRequest.email = this.email;
            getAndCreateIfMissingContactPersonRequest.phone = this.phone;
            getAndCreateIfMissingContactPersonRequest.firstname = this.firstName;
            getAndCreateIfMissingContactPersonRequest.lastName = this.lastName;
            getAndCreateIfMissingContactPersonRequest.middleName = this.middleName;
            getAndCreateIfMissingContactPersonRequest.authType = this.selectedContactPerson?.authType ?? AuthType.Email;
            getAndCreateIfMissingContactPersonRequest.externalId = this.selectedContactPerson?.externalId ?? "";
            getAndCreateIfMissingContactPersonRequest.workOrderId = this.workOrderId;
            getAndCreateIfMissingContactPersonRequest.workOrderContactPersonType = this.contactPersonType;

            const response: GetAndCreateIfMissingContactPersonResponse = await this.getAndCreateIfMissingContactPersonAsync(getAndCreateIfMissingContactPersonRequest);

            success = response.successfully;
            contactPerson = response.user;
            contactPersonFullName = "{0} {1}".format(getAndCreateIfMissingContactPersonRequest.firstname, getAndCreateIfMissingContactPersonRequest.lastName);
        } else {
            this.data.action = (approveWorkOrder)
                ? SendWorkOrderModalAction.SelectCreateEditWorkOrderApproverAndApproveWorkOrder
                : SendWorkOrderModalAction.SelectCreateEditWorkOrderApproverAndSendWorkOrder;

            const approveWorkOrderRequest = new ApproveWorkOrderByEmailRequest();

            approveWorkOrderRequest.workOrderId = this.workOrderId;
            approveWorkOrderRequest.approved = approveWorkOrder;

            approveWorkOrderRequest.approvalType = (approveWorkOrder)
                ? CustomerApprovalType.Phone
                : CustomerApprovalType.Email;

            if (this.createNewContactPerson) {
                approveWorkOrderRequest.customerApprover = new User();
                approveWorkOrderRequest.customerApprover.id = RentaTaskConstants.defaultGuid;
                approveWorkOrderRequest.customerApprover.role.organizationContractId = RentaTaskConstants.defaultGuid;
            }
            else {
                approveWorkOrderRequest.customerApprover = this.state.contactPerson!;
            }

            approveWorkOrderRequest.customerApprover.email = this.email;
            approveWorkOrderRequest.customerApprover.firstname = this.firstName;
            approveWorkOrderRequest.customerApprover.lastName = this.lastName;
            approveWorkOrderRequest.customerApprover.middleName = this.middleName;
            approveWorkOrderRequest.customerApprover.phone = this.phone;

            const response: ApproveWorkOrderByEmailResponse = await this.approveWorkOrderByEmailAsync(approveWorkOrderRequest);

            success = response.successfully;
            workOrder = response.workOrder;
            contactPerson = response.workOrder?.customerApprover ?? null;
            contactPersonFullName = TransformProvider.toString(contactPerson);
        }

        if (!success) {
            const form: Form | null = this._formRef.current;

            if (form) {
                const validationError: string = Localizer.workOrdersValidationErrorApproverCreationRejected.format(contactPersonFullName);
                await form.setValidationErrorsAsync(validationError);
            }

            return;
        }

        if (contactPerson) {
            if (this.type === "editApprover" || this.type === "editOrderer") {
                if (this.contactPersonType === WorkOrderContactPersonType.Approver) {
                    this.workOrder.customerApprover = contactPerson;
                    this.workOrder.customerApproverId = contactPerson.id;
                } else {
                    this.workOrder.customerOrderer = contactPerson;
                    this.workOrder.customerOrdererId = contactPerson.id;

                    if (!this.workOrder.customerApprover) {
                        this.workOrder.customerApprover = contactPerson;
                        this.workOrder.customerApproverId = contactPerson.id;
                    }
                }
            }

            if (this.selectedContactPerson) {
                Utility.copyTo(contactPerson, this.selectedContactPerson);

                if (workOrder) {
                    Utility.copyTo(workOrder, this.workOrder);
                }
            }
        }

        this._success = true;
        await this.modal.closeAsync();
    }

    /**
     * Open the {@link SendWorkOrderModal}
     *
     * @param data Data to pass to the opened modal
     */
    public async openAsync(data: ISendWorkOrderModalData): Promise<void> {
        await this.modal.openAsync(data);
    }

    /**
     * Close the {@link SendWorkOrderModal}
     */
    public async closeAsync(): Promise<void> {
        await this.modal.closeAsync();
    }

    public render(): React.ReactNode {
        return (
            <Modal id={SendWorkOrderModal.modalId}
                   ref={this._modalRef}
                   className={styles.sendWorkOrderModal}
                   title={this.title}
                   subtitle={this.subtitle}
                   transform={SendWorkOrderModal.transformModalData}
                   onOpen={async () => await this.onOpenAsync()}
                   onClose={async () => await this.onCloseAsync()}
            >
                {
                    (this.hasData) &&
                    (
                        <div className="row">
                            <div className="col">
                                <Form ref={this._formRef}
                                      id={`${SendWorkOrderModal.modalId}Form`}
                                >
                                    {
                                        (this.canSelectExistingAndToggleCreationOfNewContactPersons) &&
                                        (
                                            <TwoColumns>
                                                <Dropdown required autoGroupSelected noSubtext
                                                          id={`${SendWorkOrderModal.modalId}FormContactPersons`}
                                                          label={Localizer.sendWorkReportDropdownLabel}
                                                          disabled={this.createNewContactPerson}
                                                          items={this.contactPersons}
                                                          selectedItem={this.selectedContactPerson || null}
                                                          onChange={(_, value) => this.setContactPersonAsync(value)}
                                                />

                                                <Checkbox id={`${SendWorkOrderModal.modalId}FormCreateNew`}
                                                          label={Localizer.sendWorkReportNewContactPerson}
                                                          className={styles.checkbox}
                                                          value={this.createNewContactPerson}
                                                          readonly={!this.hasContactPersons}
                                                          onChange={(_, createNewContactPerson) => this.setCreateNewContactPersonAsync(createNewContactPerson)}
                                                />
                                            </TwoColumns>
                                        )
                                    }

                                    <OneColumn className={this.css(styles.newContactPerson, styles.open)}>
                                        <TwoColumns>
                                            <EmailInput required autoFocus
                                                        id={`${SendWorkOrderModal.modalId}FormEmail`}
                                                        label={Localizer.formInputEmail}
                                                        value={this.email}
                                                        readonly={this.emailReadonly}
                                                        onChange={async (_, email) => {await this.setState({email})}}
                                            />

                                            <PhoneInput required
                                                        id={`${SendWorkOrderModal.modalId}FormPhone`}
                                                        label={Localizer.formInputPhone}
                                                        value={this.phone}
                                                        readonly={this.phoneReadonly}
                                                        onChange={async (_, phone) => {await this.setState({phone})}}
                                            />
                                        </TwoColumns>

                                        <TwoColumns>
                                            <TextInput required
                                                       id={`${SendWorkOrderModal.modalId}FormFirstName`}
                                                       label={Localizer.formInputFirstname}
                                                       value={this.firstName}
                                                       readonly={this.nameReadonly}
                                                       onChange={async (_, firstName) => {await this.setState({firstName})}}
                                            />

                                            <TextInput required
                                                       id={`${SendWorkOrderModal.modalId}FormLastName`}
                                                       label={Localizer.formInputLastname}
                                                       value={this.lastName}
                                                       readonly={this.nameReadonly}
                                                       onChange={async (_, lastName) => {await this.setState({lastName})}}
                                            />
                                        </TwoColumns>

                                        <TwoColumns>
                                            <TextInput id={`${SendWorkOrderModal.modalId}FormMiddleName`}
                                                       label={Localizer.formInputMiddlename}
                                                       value={this.middleName}
                                                       readonly={this.nameReadonly}
                                                       onChange={async (_, middleName) => {await this.setState({middleName})}}
                                            />
                                        </TwoColumns>
                                    </OneColumn>

                                    <ButtonContainer>
                                        {
                                            (this.type === "displayInfo")
                                                ?
                                                (
                                                    <Button id={`${SendWorkOrderModal.modalId}FormClose`}
                                                            className={styles.buttons}
                                                            type={ButtonType.Light}
                                                            label={Localizer.componentModalClose}
                                                            onClick={async () => await this.closeAsync()}
                                                    />
                                                )
                                                : (this.type === "editApprover" || this.type === "editOrderer")
                                                    ?
                                                    (
                                                        <Button id={`${SendWorkOrderModal.modalId}FormAssign`}
                                                                className={this.css(styles.modifyButtonsMode)}
                                                                type={ButtonType.Orange}
                                                                label={Localizer.sendWorkOrderModalButtonAssign}
                                                                title={Localizer.sendWorkOrderModalButtonTooltipAssign}
                                                                onClick={async () => await this.submitAsync(false)}
                                                        />
                                                    )
                                                    :
                                                    (
                                                        <Button id={`${SendWorkOrderModal.modalId}FormSendOrApprove`}
                                                                type={ButtonType.Orange}
                                                                label={Localizer.sendWorkOrderModalButtonSendOrApprove}
                                                                disabled={this.sendOrApproveButtonReadonly}
                                                        >
                                                            {
                                                                (WorkOrderModel.allowCustomerApproval(this.workOrder)) &&
                                                                (
                                                                    <Button.Action title={Localizer.sendWorkReportTitle} 
                                                                                   onClick={async () => await this.submitAsync(false)}
                                                                    />
                                                                )
                                                            }

                                                            <Button.Action title={Localizer.approveWorkReportTitle}
                                                                           onClick={async () => await this.submitAsync(true)}
                                                            />
                                                        </Button>
                                                    )
                                        }
                                    </ButtonContainer>
                                </Form>
                            </div>
                        </div>
                    )
                }
            </Modal>
        );
    }
}