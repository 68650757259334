import {ApiProvider} from "@renta-apps/athenaeum-react-common";
import {NewsItem} from "@/models/server/NewsItem";

export const fetchNewsAsync = async (): Promise<NewsItem[]> => {
    return ApiProvider.getAsync<NewsItem[]>("/api/NewsAdmin/NewsItems", null);
}

export const saveNewsItemAsync = async (item: NewsItem): Promise<NewsItem> => {
    return ApiProvider.postAsync<NewsItem>("/api/NewsAdmin/SaveNewsItem", item, null);
}

export const deleteNewsItemAsync = async (item: NewsItem): Promise<void> => {
    return ApiProvider.postAsync<void>("/api/NewsAdmin/DeleteNewsItem", item.id, null);
}
