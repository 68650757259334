import ExtraChargeType from "@/models/server/ExtraChargeType";
import {IIconProps} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";

/**
 * Extends ExtraChargeType by adding properties needed in admin page.
 */
export default class AdminExtraChargeType extends  ExtraChargeType {
    /**
     * True if the type is assigned to any extra charges.
     */
    public isAssigned: boolean = false;

    public isAdminExtraChargeType: boolean = true;

    /** Gets icon matching the type's usage status. */
    public static getUsageIcon(workOrder: AdminExtraChargeType): IIconProps | null {
        return workOrder.isAssigned
            ? {
                name: "far check-circle",
                className: "green assigned-icon",
                tooltip: Localizer.adminExtraChargeTypeInUseTooltip,
            }
            : null;
    }
}