import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import User from "@/models/server/User";
import WarehouseCostPool from "@/models/server/WarehouseCostPool";
import Region from "@/models/server/Region";

export default class Warehouse {
    public id: string = "";

    public name: string = "";

    public costPools: WarehouseCostPool[] = [];

    public timeTrackingDeviceId: string | null = null;

    public location: GeoLocation | null = null;
    
    public qrCode: string = "";

    public deleted: boolean = false;

    public deletedAt: Date | null = null;

    public deletedBy: User | null = null;

    public region: Region = new Region();

    public isWarehouse: boolean = true;
}