import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import RentaTasksWizardPage from "../RentaTasksWizardPage";
import TaskMounter from "../../../models/server/TaskMounter";
import {DropdownWidget, TitleWidget} from "@renta-apps/athenaeum-react-components";
import GetEmployeesRequest from "@/models/server/requests/GetEmployeesRequest";
import Localizer from "@/localization/Localizer";

export interface IAssignMountersProps {
}

interface IAssignMountersState {
    mounters: TaskMounter[]
}

export default class AssignMounters extends RentaTasksWizardPage<IAssignMountersProps, IAssignMountersState> {

    state: IAssignMountersState = {
        mounters: []
    };

    private get mounters(): string[] {
        return this.wizard.mounters || [];
    }

    private async onAssignMounter(sender: DropdownWidget<TaskMounter>): Promise<void> {

        this.wizard.mounters = sender.selectedItems.map(item => item.user.id);
        this.saveContext();

        await this.reRenderAsync();
    }

    private get description(): string {
        return (this.mounters.length > 0)
            ? Utility.format(Localizer.rentaTasksAssignMounterDropdownWidgetMountersAssigned, this.mounters.length)
            : Localizer.rentaTasksAssignMounterDropdownWidgetAssignMounters;
    }

    protected getNoToggle(): boolean {
        return true;
    }

    public getManual(): string {
        return Localizer.assignMountersManual;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const mounters: TaskMounter[] = await this.postAsync("api/rentaTasks/getMountersData", new GetEmployeesRequest(this.wizard.owner?.id));

        await this.setState({ mounters });
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <TitleWidget wide model={this.title} />

                <DropdownWidget<TaskMounter> wide multiple autoGroupSelected expanded
                                             id="Mounters"
                                             icon={{name: "fas rocket"}}
                                             label={Localizer.addTaskModalMounters}
                                             description={this.description}
                                             items={this.state.mounters}
                                             selectedItems={this.mounters}
                                             onChange={async (sender) => await this.onAssignMounter(sender)}
                />

            </React.Fragment>
        );
    }
}