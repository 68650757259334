import React from "react";
import FormItem from "@/models/server/forms/FormItem";
import {TextAreaInput, TextInput} from "@renta-apps/athenaeum-react-components";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import FormPageRow from "@/components/Form/FormPageRow/FormPageRow";

import styles from "./FormText.module.scss";

export interface IFormTextProps {

    /**
     * NOTE: Mutated!
     */
    formItem: FormItem;
    preview?: boolean;

    /**
     * Invoked when the {@link formItem} is mutated.
     */
    onChange?(): Promise<void>;
}

interface IFormTextState {
}

export default class FormText extends BaseComponent<IFormTextProps, IFormTextState> {

    state: IFormTextState = {
    };

    private get formItem(): FormItem {
        return this.props.formItem;
    }

    private async setTextAsync(formItem: FormItem, value: string): Promise<void> {
        formItem.text = value;

        if (this.props.onChange) {
            await this.props.onChange();
        }
    }

    public render(): React.ReactNode {
        const {preview} = this.props;

        return (
            <div className={styles.formText}>

                <FormPageRow>
                    <span className={styles.title}>{this.formItem.name}</span>
                </FormPageRow>

                {
                    ((this.formItem.rows != null) && (this.formItem.rows > 1))
                        ?
                        (
                            <TextAreaInput id="text"
                                           className={this.css(styles.text)}
                                           readonly={preview}
                                           value={this.formItem.text || ""}
                                           minLength={this.formItem.min || undefined}
                                           maxLength={this.formItem.max || undefined}
                                           rows={this.formItem.rows}
                                           onChange={async (_, value) => await this.setTextAsync(this.formItem, value)}
                            />
                        )
                        :
                        (
                            <TextInput id="text"
                                       className={this.css(styles.text)}
                                       readonly={preview}
                                       value={this.formItem.text || ""}
                                       minLength={this.formItem.min || undefined}
                                       maxLength={this.formItem.max || undefined}
                                       onChange={async (_, value) => await this.setTextAsync(this.formItem, value)}
                            />
                        )
                }

            </div>
        );
    }
}