import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {Button, ButtonType, DateInput, Dropdown, TextAreaInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import CatalogItem from "../CatalogItem/CatalogItem";
import EnumProvider from "@/providers/EnumProvider";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";
import Localizer from "@/localization/Localizer";
import {RentalItemActionType} from "@/models/Enums";

import styles from "./RentalEquipmentItem.module.scss";

export interface IRentalEquipmentItemProps {
    rentalEquipment: WorkOrderRentalItemModel;
    className?: string;
    editing?: boolean;
    expanded?: boolean;
    shoppingCart?: boolean;
    onChange(sender: RentalEquipmentItem, rentalEquipment: WorkOrderRentalItemModel): Promise<void>;
    onRemove(sender: RentalEquipmentItem, rentalEquipment: WorkOrderRentalItemModel): Promise<void>;
    onDescriptionToggle?(sender: RentalEquipmentItem, rentalEquipment: WorkOrderRentalItemModel, expanded: boolean): Promise<void>;
}

interface IRentalEquipmentItemState {
    expanded: boolean;
}

export default class RentalEquipmentItem extends React.Component<IRentalEquipmentItemProps, IRentalEquipmentItemState> {

    // Inherited / implemented

    public state: IRentalEquipmentItemState = {
        expanded: (this.props.expanded === true),
    }

    // Getters

    private get rentalEquipment(): WorkOrderRentalItemModel {
        return this.props.rentalEquipment;
    }

    private get shoppingCart(): boolean {
        return (this.props.shoppingCart === true);
    }

    // Async-methods

    private async onChangeDescriptionAsync(comment: string): Promise<void> {
        if (this.rentalEquipment.comment !== comment) {
            this.rentalEquipment.comment = comment;
        }

        await this.props.onChange(this, this.rentalEquipment);
    }

    private async onChangeRentDateAsync(rentDate: Date): Promise<void> {
        if (this.rentalEquipment.rentDate !== rentDate) {
            this.rentalEquipment.rentDate = rentDate;
        }

        await this.props.onChange(this, this.rentalEquipment);
    }

    private async onChangeActionTypeAsync(actionType: RentalItemActionType): Promise<void> {
        if (this.rentalEquipment.actionType !== actionType) {
            this.rentalEquipment.actionType = actionType;
        }

        await this.props.onChange(this, this.rentalEquipment);
    }

    private async toggleAsync(): Promise<void> {
        const expanded: boolean = (!this.state.expanded);

        await this.setState({expanded});

        if (this.props.onDescriptionToggle) {
            await this.props.onDescriptionToggle(this, this.rentalEquipment, expanded);
        }
    }

    private async removeAsync(): Promise<void> {
        await this.props.onRemove(this, this.rentalEquipment);
    }
    
    // Renders

    private get renderNormal(): JSX.Element {
        const rentalItemName: string = this.rentalEquipment.rentalItemName!;
        const actionTypeName: string = EnumProvider.getRentalItemActionTypeText(this.rentalEquipment.actionType);

        const descriptionButtonIconName: string = (this.rentalEquipment.comment)
            ? "far comment-alt-dots"
            : "far comment-alt"

        return (
            <React.Fragment>

                <div className={styles.title}>

                    <div className={styles.name}>
                        <span>
                             {rentalItemName} ({actionTypeName})
                        </span>
                    </div>

                </div>

                <div className={styles.content}>

                    <div className={styles.actions}>
                        <div>
                            <Button block
                                    type={ButtonType.Orange}
                                    icon={{name: "fas minus"}}
                                    onClick={() => this.removeAsync()}
                            />
                        </div>
                    </div>

                    <div className={styles.data}>

                        <div className={styles.description}>
                            <span>
                                {this.rentalEquipment.comment}
                            </span>
                        </div>

                    </div>

                    <div className={styles.actions}>

                        <div>
                            <Button block
                                    type={ButtonType.Orange}
                                    icon={{name: descriptionButtonIconName}}
                                    onClick={() => this.toggleAsync()}
                            />
                        </div>

                    </div>

                </div>

                <div className={styles.descriptionInput}>
                    {
                        (this.state.expanded) &&
                        (
                            <TextAreaInput autoFocus
                                           value={this.rentalEquipment.comment || ""}
                                           onChange={(_, value) => this.onChangeDescriptionAsync(value)}
                            />
                        )
                    }
                </div>

                <TwoColumns>
                    <DateInput required
                               readonly={false}
                               label={Localizer.genericDate}
                               value={this.rentalEquipment.rentDate}
                               onChange={async (value) => await this.onChangeRentDateAsync(value)}
                    />

                    <Dropdown required
                              label={Localizer.addExtraChargeModalExtraChargeTypeDropdownLabel}
                              minWidth="105px"
                              items={EnumProvider.getRentalItemActionTypeItems()}
                              selectedItem={this.rentalEquipment.actionType}
                              onChange={async (_, item) => await this.onChangeActionTypeAsync(parseInt(item!.value))}
                    />
                </TwoColumns>
                
            </React.Fragment>
        );
    }

    public render(): React.ReactNode {
        const className: string = Utility.css(
            styles.rentalEquipment,
            this.props.className,
            (this.shoppingCart) && (styles.active));

        return (
            <CatalogItem className={className}>
                {
                    this.renderNormal
                }
            </CatalogItem>
        );
    }
};