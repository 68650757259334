import React from "react";
import FormCheckItem from "@/models/server/forms/FormCheckItem";
import FormItem from "@/models/server/forms/FormItem";
import {SwitchNullable} from "@renta-apps/athenaeum-react-components";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import FormPageRow from "@/components/Form/FormPageRow/FormPageRow";
import Localizer from "@/localization/Localizer";

import styles from "./FormChecks.module.scss";

export interface IFormChecksProps {

    /**
     * NOTE: Mutated!
     */
    formItem: FormItem;
    preview?: boolean;

    /**
     * Invoked when the {@link formItem} is mutated.
     */
    onChange?(): Promise<void>;
}

interface IFormChecksState {
}

export default class FormChecks extends BaseComponent<IFormChecksProps, IFormChecksState> {

    state: IFormChecksState = {
    };

    private async setValueAsync(checkItem: FormCheckItem, value: boolean | null): Promise<void> {
        checkItem.ok = value;
        this.formItem.ok = FormItem.isOk(this.formItem);

        if (this.props.onChange) {
            await this.props.onChange();
        }
    }

    private get formItem(): FormItem {
        return this.props.formItem;
    }

    private get checks(): FormCheckItem[] {
        return this.formItem.checks ?? [];
    }

    private renderCheckbox(checkItem: FormCheckItem, index: number): React.ReactNode {
        const excludeNull: boolean = checkItem.isRequired;
        return (
            <FormPageRow key={index} className={"justify-content-center"}>
                <SwitchNullable excludeNull={excludeNull}
                                inline
                                className={styles.check}
                                label={`${Localizer.get(checkItem.name)} ${excludeNull ? '*' : ''}`}
                                readonly={this.props.preview}
                                value={checkItem.ok}
                                onChange={async (_, value) => await this.setValueAsync(checkItem, value)}
                />
            </FormPageRow>
        );
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.formChecks}>

                <FormPageRow>
                    <span className={styles.title}>{this.formItem.name}</span>
                </FormPageRow>

                {
                    this.checks.map((checkItem, index) => this.renderCheckbox(checkItem, index))
                }

                { this.checks.some(checkItem => checkItem.isRequired) &&
                    <FormPageRow>
                        <span>{`* ${Localizer.formDefinitionPageFormItemRequired}`}</span>
                    </FormPageRow>
                }
                
            </div>
        );
    }
}