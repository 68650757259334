import React from "react";
import {Button, ButtonType, PageContainer, PageHeader, PageRow, Spinner, Tab, TabContainer} from "@renta-apps/athenaeum-react-components";
import FormModel from "@/models/server/forms/FormModel";
import {ApiProvider, ch, DataStorageType, PageRoute, PageRouteProvider, UserInteractionDataStorage} from "@renta-apps/athenaeum-react-common";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import PageDefinitions from "@/providers/PageDefinitions";
import StartManualFormRequest from "@/models/server/requests/StartManualFormRequest";
import StartFormResponse from "@/models/server/responses/StartFormResponse";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import GetWorkOrderFormsRequest from "@/models/server/requests/GetWorkOrderFormsRequest";
import FormsList from "@/pages/RentaTasks/WorkOrderForms/FormsList/FormsList";
import GetWorkOrderRequest from "@/models/server/requests/GetWorkOrderRequest";
import UnlockFormResponse from "@/models/server/responses/UnlockFormResponse";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import WorkOrderProvider from "@/providers/WorkOrderProvider";
import RentaTasksController, {RentaTasksAction} from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "@/localization/Localizer";

import styles from "./WorkOrderForms.module.scss";
import rentaTaskStyles from "@/pages/RentaTask.module.scss";
import throwIfFalsy from "@/functions/ThrowIfFalsy";
import User from "@/models/server/User";
import {WorkOrderStatus} from "@/models/Enums";

interface IWorkOrderFormsProps {
}

interface IWorkOrderFormsState {

    /**
     * The Work Order which forms to display.
     */
    workOrder: WorkOrderModel | null;
    unprocessedForms: FormModel[];
    processedForms: FormModel[];
}

export default class WorkOrderForms extends AuthorizedPage<IWorkOrderFormsProps, IWorkOrderFormsState> {

    state: IWorkOrderFormsState = {
        workOrder: null,
        unprocessedForms: [],
        processedForms: [],
    };

    private async tryToSignIn() : Promise<boolean> {
        const user : User = this.getUser();
        if (!user.isAdmin) {
            const request: GetWorkOrderRequest = new GetWorkOrderRequest();

            request.workOrderId = this.state.workOrder!.id;
            request.excludeDeletedUserSalaryHours = true;
            request.includeMounters = true;

            let workOrder : WorkOrderModel = await WorkOrderProvider.getWorkOrderAsync(ApiProvider, request);

            const isSignedIn : boolean = RentaTasksController.isSignedIn;
            // user is already signed in to the current work order
            if (isSignedIn && RentaTasksController.mounterContext.workOrder?.id === workOrder.id) {
                return true;
            }

            if (isSignedIn) {
                // user is signed into a different work order
                await this.alertErrorAsync(Localizer.rentaTasksWorkOrderSignInAlreadySignedInAlert);
                await this.reRenderAsync();
                return false;
            }

            const canSignIn : boolean = RentaTasksController.canSignIn(workOrder);
            if (!canSignIn){
                // user cannot sign in to this work order
                await this.alertErrorAsync(Localizer.rentaTasksWorkOrderSignInFailedAlert);
                await this.reRenderAsync();
                return false;
            }

            const confirmed: boolean = (workOrder.currentStatus === WorkOrderStatus.InProgress) || (await ch.confirmAsync(Localizer.rentaTasksWorkOrderSignInConfirm));
            if (!confirmed) {
                return false;
            }

            await RentaTasksController.checkInAsync(workOrder);

            workOrder = await WorkOrderProvider.getWorkOrderAsync(ApiProvider, request);
            this.setState({workOrder});
        }
        return true;
    }

    private async onOpenFormsListItemAsync(form: FormModel): Promise<void> {

        if (!form.processed) {
            // user needs to be signed in to fill the form if the WO is created/in progress
            const workOrderStatus: WorkOrderStatus = this.workOrder!.currentStatus;
            if (workOrderStatus === WorkOrderStatus.Created || workOrderStatus === WorkOrderStatus.InProgress){
                const canContinue : boolean = await this.tryToSignIn();
                if (!canContinue) return;
            }
            
            if (form.id) {
                // Before/After Form

                const response: StartFormResponse = await this.postAsync("api/rentaTasks/startForm", form.id);

                if (response.lockedByAnotherUser) {
                    await this.alertErrorAsync(Localizer.rentaTasksControllerAlertErrorFormProcessedByAnotherUser);
                    return;
                }

                if (response.form) {
                    form = response.form;
                }
            } else {
                // Anytime ("Manual") Form

                const request: StartManualFormRequest = {
                    workOrderId: this.workOrder.id,
                    formDefinitionId: form.formDefinitionId,
                };

                const response: StartFormResponse = await this.postAsync("api/rentaTasks/startManualForm", request);

                if (response.lockedByAnotherUser) {
                    await this.alertErrorAsync(Localizer.rentaTasksControllerAlertErrorFormProcessedByAnotherUser);
                    return;
                }

                if (response.form) {
                    form = response.form;
                }
            }
        }
        // Wizard context
        RentaTasksController.wizardContext.action = RentaTasksAction.Form;
        RentaTasksController.wizardContext.workOrder = this.workOrder;
        RentaTasksController.wizardContext.actionInitialPageRoute = ch.getPage().route;

        // Mounter context
        RentaTasksController.mounterContext.form = form;
        RentaTasksController.mounterContext.formPreview = form!.processed;
        RentaTasksController.saveMounterContext();

        UserInteractionDataStorage.set(
            "initialFormHashCode",
            Utility.getHashCode(form),
            DataStorageType.Session
        );

        const redirectRoute: PageRoute = (form.processed)
            ? PageDefinitions.rentaTasksFormPreviewPageRoute
            : RentaTasksController.getFirstStep();

        await PageRouteProvider.redirectAsync(redirectRoute);
    }

    private async unlockFormAsync(formId: string): Promise<void> {
        const response: UnlockFormResponse = await this.postAsync("api/rentaTasks/unlockForm", formId);

        await this.alertMessageAsync(Localizer.workOrderFormsAlertErrorFormUnlocked);

        const form: FormModel = this.unprocessedForms.find(form => form.id === formId)!;

        if (response.form) {
            form.locked = response.form.locked;
        } else {
            const request = new GetWorkOrderFormsRequest();
            request.workOrderId = this.routeId!;
            request.processed = false;

            const unprocessedForms: FormModel[] = await this.getUnprocessedWorkOrderForms(request);

            await this.setState({unprocessedForms});
        }

        await this.reRenderAsync();
    }

    private async getUnprocessedWorkOrderForms(request: GetWorkOrderFormsRequest): Promise<FormModel[]> {
        return await this.postAsync("/api/rentaTasks/getWorkOrderForms", request);
    }

    private async getProcessedWorkOrderForms(request: GetWorkOrderFormsRequest): Promise<FormModel[]> {
        return await this.postAsync("/api/rentaTasks/getWorkOrderForms", request);
    }

    private get title(): string {
        return `${this.workOrder.name}`;
    }

    private get workOrder(): WorkOrderModel {
        return this.state.workOrder!;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const workOrderId: string | null = this.routeId;

        throwIfFalsy(workOrderId);

        const workOrderRequest: GetWorkOrderRequest = new GetWorkOrderRequest();
        workOrderRequest.workOrderId = workOrderId!;
        workOrderRequest.excludeDeletedUserSalaryHours = true;
        workOrderRequest.includeMounters = true;

        const workOrder: WorkOrderModel = await WorkOrderProvider.getWorkOrderAsync(this, workOrderRequest);

        const request = new GetWorkOrderFormsRequest();
        request.workOrderId = workOrderId!;

        request.processed = false;
        const unprocessedForms: FormModel[] = await this.getUnprocessedWorkOrderForms(request);

        request.processed = true;
        const processedForms: FormModel[] = await this.getProcessedWorkOrderForms(request);

        await this.setState({workOrder, unprocessedForms, processedForms});
    }

    private get processedForms(): FormModel[] {
        return this.state.processedForms;
    }

    private get unprocessedForms(): FormModel[] {
        return this.state.unprocessedForms;
    }

    public render(): React.ReactNode {
        const workOrder: WorkOrderModel | null = this.workOrder;

        if (!workOrder) {
            return null;
        }

        return (
            <PageContainer fullHeight
                           className={this.css(styles.workOrderForms)}
            >
                <PageHeader title={this.title}/>

                <PageRow className={styles.help}>

                    <div className="col">

                        <TabContainer>

                            <Tab id="activeForms"
                                 title={Localizer.workOrderFormsActiveFormsTab}
                            >
                                <FormsList isActiveForms
                                           forms={this.unprocessedForms}
                                           onOpenFormsListItem={async (_, form) => await this.onOpenFormsListItemAsync(form)}
                                           onUnlockForm={async (_, formId: string) => await this.unlockFormAsync(formId)}
                                />
                            </Tab>

                            <Tab id="completeForms"
                                 title={Localizer.workOrderFormsCompletedFormsTab}
                            >
                                <FormsList forms={this.processedForms}
                                           onOpenFormsListItem={async (_, form) => await this.onOpenFormsListItemAsync(form)}
                                           onUnlockForm={async (_, formId: string) => await this.unlockFormAsync(formId)}
                                />

                            </Tab>

                        </TabContainer>

                    </div>

                </PageRow>

                <Button block
                        className={this.css(rentaTaskStyles.bigButton, styles.backButton)}
                        type={ButtonType.Orange}
                        label={Localizer.genericBack}
                        route={PageDefinitions.rentaTasksWorkOrder(this.workOrder.id)}
                />

                {
                    (this.isSpinning()) && <Spinner/>
                }

            </PageContainer>
        );
    }
}