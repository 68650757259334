/**
 * {@link https://web.archive.org/web/20180324022838/http://demo.nimius.net/debounce_throttle/|A visual showcase of what debouncing is}
 */
export default class Debouncer {
    private _timeoutId: number = 0;

    /**
     * Execute a callback after a timeout.
     *
     * If {@link debounce} is called again before the timeout has passed,
     * the previous execution is cancelled and a new timeout started.
     */
    public readonly debounce = (callback: Function, timeoutMilliseconds: number): void => {
        this.cancel();

        this._timeoutId = window.setTimeout(callback, timeoutMilliseconds);
    };

    /**
     * Cancel the current execution.
     */
    public readonly cancel = (): void => {
        if (this._timeoutId) {
            clearInterval(this._timeoutId);
        }
    };
}