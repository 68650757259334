import User from "@/models/server/User";
import {FileModel} from "@renta-apps/athenaeum-toolkit";

export default class UserSignatureModel {
    public id: string = "";

    /** User who signed the Form. */
    public user: User | null = null;

    /** File metadata for user signature. */
    public file: FileModel | null = null;

    /** Name clarification of the user. */
    public nameClarification: string = "";

    public static isSignatureValid(signature: UserSignatureModel): boolean {
        return ((signature.user !== undefined)
            && (signature.file !== null)
            && (signature.nameClarification.trim().length > 0));
    }

    public static areSignaturesValid(signatures: UserSignatureModel[] | null): boolean {
        if (signatures && signatures.length > 0) {
            return signatures.every((signature) => UserSignatureModel.isSignatureValid(signature));
        }
        return false;
    }
}