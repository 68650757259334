import {AuthType, WorkOrderContactPersonType} from "@/models/Enums";

export default class GetAndCreateIfMissingContactPersonRequest {

    public organizationContractId: string | null = null;

    public constructionSiteId: string | null = null;

    /**
     * Id of the Work Order which {@link WorkOrderModel.customerApprover} or {@link WorkOrderModel.customerOrderer} to assign the created Contact Person as.
     * If set, {@link workOrderContactPersonType} is required.
     */
    public workOrderId: string | null = null;

    public authType: AuthType = AuthType.Email;

    public email: string = "";

    public phone: string = "";

    public firstname: string = "";

    public middleName: string = "";

    public lastName: string = "";

    public externalId: string = "";

    /**
     * Work Order Contact Person type to assign the created Contact Person as.
     * Required if {@link workOrderId} is set.
     */
    public workOrderContactPersonType: WorkOrderContactPersonType | null = null;
}