import React from "react";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonContainer, Button, ButtonType, Form, IStringInputModel, IconStyle, PasswordInput, TextInput, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import { LoginResultStatus } from "@/models/Enums";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import LoginRequest from "../../models/server/requests/LoginRequest";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "../../localization/Localizer";
import {FeatureSwitch} from "@/components/FeatureSwitch/FeatureSwitch";
import FeatureFlags from "@/helpers/FeatureFlags";

import styles from "./Login.module.scss";
import {getWithXSFRTokenAsync} from "@/functions/GetWithXsfrTokenAsync";

export default class Login extends AnonymousPage {

    private _usernameInput: IStringInputModel = { value: "" };
    private _passwordInput: IStringInputModel = { value: "" };
    public _formRef: React.RefObject<any> = React.createRef();


    private async loginAsync(username: string, password: string): Promise<void> {

        const login = new LoginRequest(username, password);
        const loginResult: LoginResultStatus = await this.postAsync("api/Application/Login", login);

        if (loginResult !== LoginResultStatus.Success) {
            const form: Form | null = this._formRef.current;
            if (form != null) {
                const validationError: string = EnumProvider.getLoginResultStatusName(loginResult);
                await form.setValidationErrorsAsync(validationError);
            }
        }
    }

    public getTitle(): string {
        return Localizer.topNavLogin;
    }

    private async handleSubmitAsync(): Promise<void> {
        await this.loginAsync(this._usernameInput.value, this._passwordInput.value);
    }
    private async redirectToPasswordPage() {
        await PageRouteProvider.redirectAsync(PageDefinitions.forgotPasswordRoute);
    }

    private async onAzureClickAsync(): Promise<void> {
        const httpResponse: Response = await getWithXSFRTokenAsync("/api/Application/GetAzureSsoLogin");

        if (httpResponse.headers.get("location")) {
            window.location.href = httpResponse.headers.get("location")!;
        }
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.loginPageTitle} subtitle={Localizer.loginPageSubtitle} className={styles.header}  />

                <PageRow>
                    <div className="col-lg-6">

                        <Form id="form" onSubmit={async () => await this.handleSubmitAsync()} ref={this._formRef}>

                            <TextInput autoFocus required id="username" label={Localizer.loginPageEmailInput} model={this._usernameInput} />

                            <PasswordInput required id="password" label={Localizer.loginPagePasswordInput} model={this._passwordInput} />

                            <ButtonContainer>

                                <Button submit
                                        type={ButtonType.Orange}
                                        label={Localizer.loginPageLoginButton}
                                        id="submit"/>

                                <FeatureSwitch flagName={FeatureFlags.AzureAdSso}>
                                    <Button type={ButtonType.Blue}
                                            icon={{ name: "windows", style: IconStyle.Brands }}
                                            label={Localizer.loginPageSsoButton}
                                            onClick={async () => await this.onAzureClickAsync()}
                                    />
                                </FeatureSwitch>

                                <Button label={Localizer.loginPageForgotPasswordButton} onClick={() => this.redirectToPasswordPage()}  />

                            </ButtonContainer>

                        </Form>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}