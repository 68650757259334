/**
 * Throw an error if the value is falsy (false, -0, 0, 0n, NaN, "", null, undefined).
 *
 * @param value Value to check for being falsy.
 * @param paramName Name of the value to check.
 * @throws TypeError {@link value} is falsy.
 */
export default function throwIfFalsy(value: unknown, paramName?: string): void {
    if (!value) {
        paramName = paramName ?? 'Parameter';

        const type: "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function" = typeof value;

        throw new TypeError(`${paramName} value '${value}' typeof ${type} is falsy`);
    }
}