export default class WorkOrderType {
    public id: string = "";

    /** Name of the type to be displayed on the UI. */
    public name: string = "";

    /** If true, the type cannot be added to tasks. */
    public disabled: boolean = false;

    public isWorkOrderType: boolean = true;
}