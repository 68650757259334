import React, {useState} from "react";
import {Button, ButtonType, Checkbox, FileInput, Icon, IconSize, InlineType, TextAreaInput, TextInput, Tooltip, TooltipPosition, TwoColumns} from "@renta-apps/athenaeum-react-components";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {NewsItem} from "@/models/server/NewsItem";
import Localizer from "@/localization/Localizer";

import styles from "./NewsEditor.module.scss";
import NewsEditorHelp from "@/components/NewsEditor/NewsEditorHelp";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";

interface INewsEditorFormProps {
    item: NewsItem;
    onSubmit: (item: NewsItem) => Promise<void>;
    onCancel: () => void;
}

const NewsEditorForm = (props: INewsEditorFormProps) => {    

    const [newsTitle, setNewsTitle] = useState<string>(props.item?.title || "");
    const [newsDescription, setNewsDescription] = useState<string>(props.item?.description || "");
    const [newsImage, setNewsImage] = useState<FileModel | null>(props.item?.file || null);
    const [newsIsEnabled, setNewsIsEnabled] = useState<boolean>(props.item?.enabled || false);

    const submitForm = async () => {
        if (!newsTitle || !newsDescription) {
            return;
        }
        
        const item: NewsItem = {
            id: props.item?.id || null,
            title: newsTitle,
            description: newsDescription,
            enabled: newsIsEnabled,
            file: newsImage
        };

        props.onSubmit(item);
    }
    
    return (
        <div className={styles.newsEditorForm}>
                        
            <TwoColumns>
                <div className={styles.container}>
                    <TextInput value={newsTitle}
                               required
                               maxLength={RentaTaskConstants.maxTitleDescriptionLength}
                               label={Localizer.newsEditorInputTitle}
                               onChange={async (_input, value) => setNewsTitle(value)}
                               id={"newsTitle"}
                    />
        
                    <TextAreaInput label={Localizer.newsEditorInputDescription}
                                   required
                                   value={newsDescription!}
                                   maxLength={RentaTaskConstants.newsItemDescriptionMaxLength}
                                   onChange={async (_input, value) => setNewsDescription(value)}
                                   id={"newsDescription"}
                    />

                    <Tooltip content={<NewsEditorHelp />} className={styles.helpIcon} position={TooltipPosition.LEFT} shift={TooltipPosition.BOTTOM} >
                        <Icon name="far question-circle"
                              size={IconSize.X2}
                        />
                    </Tooltip>
                </div>

                <FileInput dropZone
                           fileTypes={[...RentaTaskConstants.supportedImageFileExtensions]}
                           label={Localizer.newsEditorInputImage}
                           className={styles.imageLabel}
                           onChange={async (_file, fileModel: FileModel) => setNewsImage(fileModel)}
                           value={newsImage}
                           id={"newsImage"}
                />
            </TwoColumns>

            <TwoColumns>
                <Checkbox label={Localizer.newsEditorInputEnabled}
                          className={styles.checkbox}
                          value={newsIsEnabled}
                          onChange={async (_input, value) => setNewsIsEnabled(value)}
                          inline
                          inlineType={InlineType.Right}
                          id={"newsIsEnabled"}
                />

                <div className={styles.editFormOptions}>
                    <Button type={ButtonType.Primary}
                            icon={{name: "save", size: IconSize.Normal}}
                            label={Localizer.genericSave}
                            title="Save"
                            onClick={async () => submitForm()}
                            id={"newsItemSaveButton"}
                    />
                    <Button type={ButtonType.Default}
                            icon={{name: "cancel", size: IconSize.Normal}}
                            label={Localizer.genericActionCancel}
                            onClick={async () => props.onCancel()}
                            id={"newsItemCancelButton"}
                    />
                </div>
            </TwoColumns>
        </div>
    );
};

export default NewsEditorForm;