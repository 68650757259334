import CatalogManagement from "@/pages/CatalogManagement/CatalogManagement";
import {CatalogType} from "@/models/Enums";
import Localizer from "@/localization/Localizer";

export default class RentalMassProductCatalog extends CatalogManagement {
    public getType(): CatalogType {
        return CatalogType.RentalMassProduct;
    }

    public getTitle(): string {
        return Localizer.catalogManagementRentalMassProductsPageTitle;
    }
}