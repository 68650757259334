import {executeWithIntercept, pageData} from "@/helpers/CypressHelper";

const formNameForMountersSignature = `Mounters signature required form ${new Date()} - 2`
const workOrderNameForMountersSignature = `WorkOrderWithFormWhereMountersSignaturesAreRequired ${new Date()} - 2`;

const formNameForSubcontractorMounter = `One (Question) step form ${new Date()}`
const workOrderNameForSubcontractorMounter = `WorkOrderForSubcontractorMounter ${new Date()}`;

function addWorkOrderAndFormDefinition(): void {
    describe("Create form definition with mounter signatures required", () => {
        const addMountersSignatureFormDefinitionSettings: AddFormDefinitionSettings = generateFormDefinitionSettings(
            formNameForMountersSignature,
            true);

        cy.addFormDefinition(addMountersSignatureFormDefinitionSettings);

        const addSubcontractorMounterFormDefinitionSettings: AddFormDefinitionSettings = generateFormDefinitionSettings(
            formNameForSubcontractorMounter,
            false);

        cy.addFormDefinition(addSubcontractorMounterFormDefinitionSettings);
    });

    describe("Create work order with form which requires mounter signatures", () => {
        const addMountersSignaturesWorkOrderSettings: AddWorkOrderSettings = generateWorkOrderSettings(
            workOrderNameForMountersSignature,
            "Customer.SubCompanyContractNoExternalId.ConstructionSite");

        cy.addWorkOrder(addMountersSignaturesWorkOrderSettings);

        const addSubcontractorMounterWorkOrderSettings: AddWorkOrderSettings = generateWorkOrderSettings(
            workOrderNameForSubcontractorMounter,
            "Customer.CompanyContract.ConstructionSite");

        cy.addWorkOrder(addSubcontractorMounterWorkOrderSettings);
    });
}

function generateFormDefinitionSettings(
    name: string,
    mounterSignaturesRequired: boolean
) : AddFormDefinitionSettings {
    return {
        formName: name,
        customType: 'Undefined',
        description: `Test mounters form definition ${new Date()} description`,
        reportingHeaders: ['Customer name'],
        workOrders: ['Enabled'],
        timeslot: 'Anytime',
        required: false,
        multiple: false,
        approvable: false,
        mounterSignaturesRequired: mounterSignaturesRequired,
        steps: [
            {
                name: "Test step name",
                typeDropdown: 'Question',
                stepTypeSettings: null
            }
        ]
    };
}

function generateWorkOrderSettings(
    name: string,
    customerAndSite: ConstructionSiteName,
) : AddWorkOrderSettings {
    return {
        customerAndSite: customerAndSite,
        status: "New",
        name: name,
        workOrderType: "Enabled",
        managers: ["manager@example.com"],
        mounters: [],
        ordererFullName: "ContactPerson Tester",
        approverFullName: "ContactPerson Tester",
    };
}

// Generate data for tests
context("(Desktop) Work Order Forms", () => {
    beforeEach(() => {
        cy.init("Desktop");
    });

    it("Should add new work order with mounters signature required form", () => {
        cy.loginAsAdmin();
        addWorkOrderAndFormDefinition();
    });
});


context("(Mobile) Work Order Forms", () => {
    beforeEach(() => {
        cy.init("Mobile");
    });

    it("Should sign in Mounter and display him in mounters signatures dropdown", () => {
        cy.loginAsMounter("mounter3@example.com");
        test();
    });

    it("Subcontractor Mounter can complete forms", () => {
        cy.loginAsSubcontractorMounterCanProcessForm();
        processToMountersSummaryStep(workOrderNameForSubcontractorMounter, formNameForSubcontractorMounter);

        cy.contains('span', 'Complete').click();
    });

    it("Subcontractor Mounter cannot complete forms", () => {
        cy.loginAsSubcontractorMounterCanCompleteWorkOrder();
        cy.navigateToMobileWorkOrdersPage();

        cy.get('#workOrdersList').within(() => {
            cy.contains(workOrderNameForSubcontractorMounter).click();
        });

        cy.get('#workOrderFormsButton').should('not.exist');
    });

    function test(): void {
        processToMountersSummaryStep(workOrderNameForMountersSignature, formNameForMountersSignature);
        describe("Mounter can select himself in the signatures dropdown", () => {
            pageData().mobileFormSteps.nextButton().should('have.class', 'athenaeum-navigation-widget-disabled');
            pageData().mobileFormSteps.mountersSignaturesStep.mountersSignaturesDropdown().click();
            pageData().mobileFormSteps.mountersSignaturesStep.mountersSignaturesDropdown().within(() => {
                cy.contains('mounter3@example.com');
            })
        });
    }

    function processToMountersSummaryStep(workOrderName: string, formName: string): void {
        cy.navigateToMobileWorkOrdersPage();

        cy.get('#workOrdersList').within(() => {
            cy.contains(workOrderName).click();
        });

        cy.get('#workOrderFormsButton').click();

        cy.get('#activeForms').within(() => {
            cy.contains(formName).parent("tr").within(() => {
                cy.get('button[title=Fill]').click();
            })
        });

        executeWithIntercept(
            () => cy.contains('Are you sure you want to activate and sign in?').should('be.visible').parent().within(() => {
                cy.contains('Confirm').should('be.visible').trigger('click')
            }),
            [pageData().workOrder.routes.signIn]
        );

        pageData().mobileFormSteps.questionStep.okButton().click();
        pageData().mobileFormSteps.nextButton().click();
    }
});