import BasePasswordPage from "../ChangePassword/BasePasswordPage";
import Localizer from "../../localization/Localizer";
import {PasswordFormType} from "@renta-apps/athenaeum-react-components";

export default class ResetPassword extends BasePasswordPage {

    protected get passwordType(): PasswordFormType {
        return PasswordFormType.SetPassword;
    }

    public getTitle(): string {
        return Localizer.resetPasswordPageTitle;
    }
}