import React from "react";
import RentaTasksWizardPage from "../RentaTasksWizardPage";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import User from "@/models/server/User";
import SendWorkOrderModal, {SendWorkOrderModalAction} from "@/components/SendWorkOrderModal/SendWorkOrderModal";
import {IBaseComponent, PageCacheProvider} from "@renta-apps/athenaeum-react-common";
import ConstructionSiteOrWarehouse from "@/models/server/ConstructionSiteOrWarehouse";
import {DropdownRequiredType, DropdownWidget, TitleWidget} from "@renta-apps/athenaeum-react-components";
import {WorkOrderContactPersonType} from "@/models/Enums";
import GetAndCreateIfMissingContactPersonResponse from "@/models/server/responses/GetAndCreateIfMissingContactPersonResponse";
import GetAndCreateIfMissingContactPersonRequest from "@/models/server/requests/GetAndCreateIfMissingContactPersonRequest";
import Localizer from "@/localization/Localizer";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";

export interface IManagersProps {
}

interface IManagersState {
}

export default class Managers extends RentaTasksWizardPage<IManagersProps, IManagersState> {

    state: IManagersState = {};

    private readonly _sendWorkOrderModalRef: React.RefObject<SendWorkOrderModal> = React.createRef();
    private readonly _approversDropdownRef: React.RefObject<DropdownWidget<User>> = React.createRef();
    private readonly _orderersDropdownRef: React.RefObject<DropdownWidget<User>> = React.createRef();

    private get wizardContextWorkOrder(): WorkOrderModel {
        return RentaTasksController.wizardContextWorkOrder!;
    }

    private get owner(): ConstructionSiteOrWarehouse {
        return this.wizardContextWorkOrder.owner || this.wizard.owner!;
    }

    private get ownerId(): string {
        return this.owner.id;
    }

    private get manager(): User | null {
        return this.wizardContextWorkOrder.manager;
    }

    private get orderer(): User | null {
        return this.wizardContextWorkOrder.customerOrderer;
    }

    private get approver(): User | null {
        return this.wizardContextWorkOrder.customerApprover;
    }

    private async fetchManagersAsync(sender: IBaseComponent): Promise<User[]> {
        return await sender.postAsync("api/rentaTasks/getManagers", null);
    }

    private async fetchContactPersonsAsync(sender: IBaseComponent): Promise<User[]> {
        return await PageCacheProvider.getAsync(`fetchContactPersonsAsync`, () => sender.postAsync("api/rentaTasks/getContactPersons", this.ownerId));
    }

    private async getAndCreateIfMissingContactPersonAsync(sender: IBaseComponent, request: GetAndCreateIfMissingContactPersonRequest): Promise<GetAndCreateIfMissingContactPersonResponse> {
        PageCacheProvider.clear();

        request.constructionSiteId = this.ownerId;

        return await sender.postAsync("api/rentaTasks/getAndCreateIfMissingContactPerson", request);
    }

    private async createOrdererAsync(): Promise<void> {
        await this._sendWorkOrderModalRef.current!.openAsync({
            workOrder: this.wizardContextWorkOrder,
            action: SendWorkOrderModalAction.CreateWorkOrderOrderer,
        });
    }
    
    private async editOrdererAsync(): Promise<void> {
        await this._sendWorkOrderModalRef.current!.openAsync({
            workOrder: this.wizardContextWorkOrder,
            action: SendWorkOrderModalAction.EditWorkOrderOrderer,
        });
    }

    private async editApproverAsync(): Promise<void> {
        await this._sendWorkOrderModalRef.current!.openAsync({
            workOrder: this.wizardContextWorkOrder,
            action: SendWorkOrderModalAction.EditWorkOrderApprover,
        });
    }

    private async createApproverAsync(): Promise<void> {
        await this._sendWorkOrderModalRef.current!.openAsync({
            workOrder: this.wizardContextWorkOrder,
            action: SendWorkOrderModalAction.CreateWorkOrderApprover,
        });
    }

    private async onSendWorkOrderModalCloseAsync(sender: Readonly<SendWorkOrderModal>, success: boolean): Promise<void> {
        if (success) {
            await this._orderersDropdownRef.current!.reloadAsync();
            await this._approversDropdownRef.current!.reloadAsync();
            await this.reRenderAsync();

            const successMessage = (sender.contactPersonType === WorkOrderContactPersonType.Orderer)
                ? Localizer.managersStepOrdererSave
                : Localizer.managersStepApproverSaved;

            await this.alertMessageAsync(successMessage, true);
        }
        else {
            const errorMessage = (sender.contactPersonType === WorkOrderContactPersonType.Orderer)
                ? Localizer.managersStepOrdererNotSpecified
                : Localizer.managersStepApproverNotSpecified;

            await this.alertErrorAsync(errorMessage, true);
        }
    }

    private async onSetManagerAsync(manager: User | null): Promise<void> {

        this.wizardContextWorkOrder.manager = manager;
        this.wizardContextWorkOrder.managerId = manager ? manager.id : "";

        this.saveContext();

        await this.reRenderAsync();
    }

    private async onSetOrdererAsync(orderer: User | null): Promise<void> {

        this.wizardContextWorkOrder.customerOrderer = orderer;
        this.wizardContextWorkOrder.customerOrdererId = orderer ? orderer.id : "";

        this.saveContext();

        await this.reRenderAsync();

        await this.setOrdererAsApproverIfNotAlreadySetAsync();
    }

    private async setOrdererAsApproverIfNotAlreadySetAsync(): Promise<void> {
        if ((!this.approver) && (WorkOrderModel.isApproverOrOrdererValid(this.orderer, true))) {
            await this._approversDropdownRef.current!.selectItemAsync(this.orderer);
        }
    }

    private async checkOrdererAsync(orderer: User): Promise<void> {
        if (!WorkOrderModel.isApproverOrOrdererValid(orderer)) {
            await this.editOrdererAsync();
        }
    }

    private async onSetApproverAsync(approver: User | null): Promise<void> {

        this.wizardContextWorkOrder.customerApprover = approver;
        this.wizardContextWorkOrder.customerApproverId = approver ? approver.id : "";

        this.saveContext();

        await this.reRenderAsync();
    }

    private async checkApproverAsync(approver: User): Promise<void> {
        if (!WorkOrderModel.isApproverOrOrdererValid(approver)) {
            await this.editApproverAsync();
        }
    }

    protected getNoToggle(): boolean {
        return false;
    }

    public getManual(): string {
        return Localizer.managersStepManual;
    }

    public async nextAsync(): Promise<void> {

        const managerIsSelected: boolean = (this.manager != null);

        const approverIsSelected: boolean = (this.approver != null);
        const approverIsValid: boolean = (approverIsSelected) && (WorkOrderModel.isApproverOrOrdererValid(this.approver));

        const ordererIsSelected: boolean = (this.orderer != null);
        const ordererIsValid: boolean = (ordererIsSelected) && (WorkOrderModel.isApproverOrOrdererValid(this.orderer));

        if ((managerIsSelected) && (approverIsValid) && (ordererIsValid)) {
            await super.nextAsync();
            return;
        }

        if ((approverIsSelected) && (!approverIsValid)) {
            await this.editApproverAsync();
        } else if ((ordererIsSelected) && (!ordererIsValid)) {
            await this.editOrdererAsync();
        }
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <TitleWidget wide model={this.title} />

                <DropdownWidget<User> required wide autoCollapse autoGroupSelected
                                      id={"Managers"}
                                      requiredType={DropdownRequiredType.Restricted}
                                      icon={{name: "fas users-cog"}}
                                      label={Localizer.managersStepManagerLabel}
                                      description={Localizer.managersStepManagerDescription}
                                      fetchDataAsync={(sender) => this.fetchManagersAsync(sender)}
                                      selectedItem={this.manager || undefined}
                                      onChange={(_, item) => this.onSetManagerAsync(item)}
                />

                <DropdownWidget<User> required wide autoCollapse autoGroupSelected
                                      id={"Orderers"}
                                      requiredType={DropdownRequiredType.Restricted}
                                      ref={this._orderersDropdownRef}
                                      icon={{name: "fas user-edit"}}
                                      label={Localizer.managersStepOrdererLabel}
                                      description={Localizer.managersStepOrdererDescription}
                                      addButton={Localizer.managersStepOrdererAdd}
                                      fetchDataAsync={(sender) => this.fetchContactPersonsAsync(sender)}
                                      selectedItem={this.orderer || undefined}
                                      onChange={(_, item) => this.onSetOrdererAsync(item)}
                                      onItemClick={(_, item) => this.checkOrdererAsync(item)}
                                      onAdd={() => this.createOrdererAsync()}
                />

                <DropdownWidget<User> required wide autoCollapse autoGroupSelected
                                      id={"Approvers"}
                                      requiredType={DropdownRequiredType.Restricted}
                                      ref={this._approversDropdownRef}
                                      icon={{name: "fas user-check"}}
                                      label={Localizer.managersStepApproverLabel}
                                      description={Localizer.managersStepApproverDescription}
                                      addButton={Localizer.managersStepApproverAdd}
                                      fetchDataAsync={(sender) => this.fetchContactPersonsAsync(sender)}
                                      selectedItem={this.approver || undefined}
                                      onChange={(_, item) => this.onSetApproverAsync(item)}
                                      onItemClick={(_, item) => this.checkApproverAsync(item)}
                                      onAdd={() => this.createApproverAsync()}
                />

                <SendWorkOrderModal ref={this._sendWorkOrderModalRef}
                                    getAndCreateIfMissingContactPerson={(sender, request) => this.getAndCreateIfMissingContactPersonAsync(sender, request)}
                                    onClose={(sender, _, __, success) => this.onSendWorkOrderModalCloseAsync(sender, success)}
                />

            </React.Fragment>
        );
    }
}