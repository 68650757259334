import React, {ReactNode} from 'react';
import UnleashHelper from "@/helpers/UnleashHelper";

interface IFeatureSwitchProps {
    /**
     * Name of the flag.
     * @see FeatureFlags
     */
    flagName: string;
    children?: ReactNode;
}

/**
 * Renders component's children if the given flag is configured and enabled
 * for the current country and environment.
 * @param props
 * @constructor
 */
export const FeatureSwitch: React.FC<IFeatureSwitchProps> = props => {
    const enabled: boolean = UnleashHelper.isEnabled(props.flagName);
    return <>{(enabled) && (props.children)}</>;
}