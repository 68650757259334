import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import Localizer from "@/localization/Localizer";

export default class FileTypeTranslator {
    public static translate(type: string) {
        if (RentaTaskConstants.pdfFileTypes.includes(type)) {
            return Localizer.fileTypePdf;
        }

        if (RentaTaskConstants.imageFileTypes.includes(type)) {
            return Localizer.fileTypeImage;
        }

        if (RentaTaskConstants.microsoftWordFileTypes.includes(type)) {
            return Localizer.fileTypeWord;
        }

        if (RentaTaskConstants.microsoftExcelFileTypes.includes(type)) {
            return Localizer.fileTypeExcel;
        }

        if (RentaTaskConstants.microsoftPowerPointFileTypes.includes(type)) {
            return Localizer.fileTypePowerPoint;
        }

        if (RentaTaskConstants.openOfficeTextFileTypes.includes(type)) {
            return Localizer.fileTypeOpenOfficeText;
        }

        if (RentaTaskConstants.openOfficeSpreadsheetFileTypes.includes(type)) {
            return Localizer.fileTypeOpenOfficeSpreadsheet;
        }

        if (RentaTaskConstants.openOfficePresentationFileTypes.includes(type)) {
            return Localizer.fileTypeOpenOfficePresentation;
        }

        return type;
    }
}