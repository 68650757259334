import Product from "@/models/server/Product";
import {PostAsync} from "@/types/PostAsync";
import {PageCacheProvider} from "@renta-apps/athenaeum-react-common";
import RentalItem from "@/models/connect/RentalItem";

/**
 * {@link Product} provider.
 */
class ProductProvider {

    public async getProductsAsync(sender: PostAsync<boolean, Product[]>, showDeleted: boolean): Promise<Product[]> {
        const endpoint = "api/workOrders/getAllProducts" as const;

        return await PageCacheProvider.getAsync(
            endpoint,
            () => sender.postAsync(endpoint, showDeleted)
        );
    }

    public async getRentalProductAsync(sender: PostAsync<string, RentalItem>, productExternalId: string): Promise<RentalItem | null> {
        return await sender.postAsync("/api/rentaTasks/getRentalProduct", productExternalId);
    }
}

/**
 * {@link ProductProvider} singleton.
 */
export default new ProductProvider();