import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {ch} from "@renta-apps/athenaeum-react-common";
import TestPdfResponse from "@/models/server/responses/TestPdfResponse";
import {Button, EmailInput, Inline, OneColumn, PageContainer, PageHeader, PageRow, PhoneInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import SystemInfo from "@/models/server/SystemInfo";
import TestSmsResponse from "@/models/server/responses/TestSmsResponse";
import TestEmailResponse from "@/models/server/responses/TestEmailResponse";
import Localizer from "../../localization/Localizer";
import UnleashHelper from "@/helpers/UnleashHelper";

import styles from "./AdminConsole.module.scss"

interface IAdminConsoleState {
    systemInfo: SystemInfo | null;
    phone: string;
    email: string;
    constructionSiteAttachmentMigrationStatus: boolean;
    constructionSiteSearchMigrationStatus: boolean;
}

export default class AdminConsole extends AuthorizedPage<{}, IAdminConsoleState> {

    state: IAdminConsoleState = {
        systemInfo: null,
        phone: this.getUser().phone,
        email: this.getUser().email,
        constructionSiteAttachmentMigrationStatus: false,
        constructionSiteSearchMigrationStatus: false,
    };

    private async setPhoneAsync(phone: string): Promise<void> {
        await this.setState({phone});
    }

    private async setEmailAsync(email: string): Promise<void> {
        await this.setState({email});
    }

    private async clearRedisCacheAsync(): Promise<void> {
        await this.postAsync("api/adminConsole/clearRedisCache");
    }

    private async clearRedisSessionAsync(): Promise<void> {
        await this.postAsync("api/adminConsole/clearRedisSession");
        await ch.refresh();
    }

    private async testPdfAsyncAsync(): Promise<void> {
        const response: TestPdfResponse = await this.postAsync("api/adminConsole/testPdf");

        if (response.success) {

            await this.alertMessageAsync("Pdf version: \"{0}\".".format(response.version));

            if (response.pdf) {
                ch.download(response.pdf);
            }

        } else {

            await this.alertErrorAsync("Failed.\r\nPdf version: \"{0}\".\nError:\n{1}".format(response.version, response.error));

        }
    }
    
    private async invokeConstructionSiteAttachmentMigrationAsync(): Promise<void> {
        await this.postAsync("api/adminConsole/invokeConstructionSiteAttachmentMigration");
        await this.updateConstructionSiteAttachmentMigrationStatusAsync();
    }

    private async invokeConstructionSiteSearchMigrationAsync(): Promise<void> {
        await this.postAsync("api/adminConsole/invokeConstructionSiteSearchMigration");
        await this.updateConstructionSiteSearchMigrationStatusAsync();
    }

    private async rebuildConstructionSiteSearchAsync(): Promise<void> {
        await this.postAsync("api/adminConsole/rebuildConstructionSiteSearch");
        await this.updateConstructionSiteSearchMigrationStatusAsync();
    }

    private async testSmsAsync(): Promise<void> {
        const response: TestSmsResponse = await this.postAsync("api/adminConsole/testSms", this.state.phone);

        if (response.success) {

            await this.alertMessageAsync("Sms successfully sent.", true);

        } else {

            await this.alertErrorAsync("Sms test failed. \nError: \n{0}".format(response.error));

        }
    }

    private async testEmailAsync(): Promise<void> {
        const response: TestEmailResponse = await this.postAsync("api/adminConsole/testEmail", this.state.email);

        if (response.success) {

            await this.alertMessageAsync("Email successfully sent.", true);

        } else {

            await this.alertErrorAsync("Email test failed. \nError: \n{0}".format(response.error));

        }
    }

    private async updateSystemInfoAsync(): Promise<void> {
        const systemInfo: SystemInfo = await this.postAsync("api/adminConsole/getSystemInfo");

        await this.setState({systemInfo});
    }
    private async updateConstructionSiteAttachmentMigrationStatusAsync(): Promise<void> {
        const status: boolean = await this.postAsync("api/adminConsole/getConstructionSiteAttachmentMigrationStatus");
        await this.setState({constructionSiteAttachmentMigrationStatus: status});
    }

    private async updateConstructionSiteSearchMigrationStatusAsync(): Promise<void> {
        const status: boolean = await this.postAsync("api/adminConsole/getConstructionSiteSearchMigrationStatus");
        await this.setState({constructionSiteSearchMigrationStatus: status});
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.updateSystemInfoAsync();
        await this.updateConstructionSiteAttachmentMigrationStatusAsync();
        await this.updateConstructionSiteSearchMigrationStatusAsync();
    }

    private renderStateItem(title: string, value: boolean): React.ReactNode {
        return (
            <p>
                <span>{title}:</span>
                <span className={this.css(value && styles.active)}>{value ? "✓" : "-"}</span>
            </p>
        );
    }

    public render(): React.ReactNode {
        const systemInfo: SystemInfo | null = this.state.systemInfo;

        return (
            <PageContainer className={styles.adminConsole}>
                <PageHeader title={Localizer.adminConsolePageTitle} subtitle={this.getContext().username}/>
                <PageRow>
                    <div className="col">

                        <TwoColumns className={"justify-content-xl-between"}
                                    leftClassName={"pl-3"}
                                    rightClassName={"pl-3"}>

                            <OneColumn className="mr-4">

                                <Inline>

                                    <Button icon={{name: "fad eraser"}}
                                            label={"Clear Redis Cache"}
                                            confirm={"Are you sure you want to clear Redis cache and session?"}
                                            onClick={() => this.clearRedisCacheAsync()}
                                    />

                                    <Button icon={{name: "fad skull-crossbones"}}
                                            label={"Drops User Sessions"}
                                            confirm={"Are you sure you want to drop all user session, user will need to re-login?"}
                                            onClick={() => this.clearRedisSessionAsync()}
                                    />

                                </Inline>

                                <Inline>

                                    <Button icon={{name: "file-pdf"}}
                                            label={"Test Pdf"}
                                            onClick={() => this.testPdfAsyncAsync()}
                                    />

                                </Inline>

                                <Inline>

                                    <PhoneInput id="test_sms"
                                                width={"14rem"}
                                                value={this.state.phone}
                                                onChange={async (_, value) => this.setPhoneAsync(value)}
                                    />

                                    <Button icon={{name: "phone"}}
                                            label={"Test SMS"}
                                            disabled={!this.state.phone}
                                            onClick={() => this.testSmsAsync()}
                                    />

                                </Inline>

                                <Inline>

                                    <EmailInput id="test_email"
                                                width={"14rem"}
                                                value={this.state.email}
                                                onChange={async (_, value) => this.setEmailAsync(value)}
                                    />

                                    <Button icon={{name: "envelope-open"}}
                                            label={"Test Email"}
                                            disabled={!this.state.email}
                                            onClick={() => this.testEmailAsync()}
                                    />

                                </Inline>

                            </OneColumn>

                            <OneColumn className={this.css(styles.info)}>

                                {
                                    (!this.mobile) ?
                                        (
                                            <p>
                                                <span>Version:</span>
                                                <span>{ch.version}</span>
                                            </p>
                                        )
                                        :
                                        (
                                            <>
                                                <p>
                                                    <span>Version:</span>
                                                </p>
                                                <p>
                                                    <span>{ch.version}</span>
                                                </p>
                                            </>
                                        )
                                }

                                <p>
                                    <span>Country:</span><span>{ch.country}</span>
                                </p>

                                {this.renderStateItem("Development", ch.isDevelopment)}

                                {
                                    (systemInfo) &&
                                    (
                                        <React.Fragment>
                                            {this.renderStateItem("Debug", systemInfo.isDebug)}
                                            {this.renderStateItem("CL Debug", systemInfo.isComponentLibraryDebug)}
                                            {this.renderStateItem("UI Debug", ch.debug)}
                                            {this.renderStateItem("Logger Debug", systemInfo.isLoggerDebug)}
                                            {this.renderStateItem("Optimized", systemInfo.isOptimized)}
                                            <p>
                                                <span>Cores per server:</span><span>{systemInfo.coresPerServer}</span>
                                            </p>
                                            <p>
                                                <span>Session timeout, min:</span><span>{systemInfo.sessionTimeoutMinutes}</span>
                                            </p>
                                        </React.Fragment>
                                    )
                                }

                                <p>
                                    <strong>Enabled feature flags</strong>
                                </p>
                                {
                                    UnleashHelper.getEnabledFlags().map(flag => this.renderStateItem(flag.name, flag.isEnabled))
                                }

                            </OneColumn>

                        </TwoColumns>

                    </div>
                </PageRow>

                <PageRow>
                    <div className="col">
                        <h6>Migrations</h6>

                        <OneColumn>
                            <Inline>
                                <Button icon={{name: "file-pdf"}}
                                        label={"Migrate project PDFs"}
                                        onClick={async () => await this.invokeConstructionSiteAttachmentMigrationAsync()}
                                />

                                <div>Status: { this.state.constructionSiteAttachmentMigrationStatus ? "running" : "not running" }</div>
                                    
                            </Inline>
                        </OneColumn>

                        <TwoColumns>
                            <Inline>
                                <OneColumn>
                                    <Button icon={{name: "search"}}
                                            label={"Migrate project search index"}
                                            onClick={async () => await this.invokeConstructionSiteSearchMigrationAsync()}
                                    />

                                    <Button icon={{name: "recycle"}}
                                            label={"Rebuild project search index"}
                                            onClick={async () => await this.rebuildConstructionSiteSearchAsync()}
                                    />
                                </OneColumn>
                                
                                <div>Status: { this.state.constructionSiteSearchMigrationStatus ? "running" : "not running" }</div>

                            </Inline>
                        </TwoColumns>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}