import {SortDirection} from "@renta-apps/athenaeum-toolkit";

export default class ListConstructionSiteAttachmentsRequest {
    public constructionSiteId: string = "";

    public public: boolean = false;

    public pageNumber: number = 1;

    public pageSize: number = 100;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public isListConstructionSiteAttachmentsRequest: boolean = true;
}