import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";
import {Button, ButtonType, IconSize, Inline, Modal} from "@renta-apps/athenaeum-react-components";
import CatalogDataProvider, {ICatalogDataProvider} from "@/providers/CatalogDataProvider";
import Catalog, {CatalogMode} from "../Catalog";
import WorkOrderExtraCharge from "@/models/server/WorkOrderExtraCharge";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";
import Localizer from "@/localization/Localizer";
import {CatalogType} from "@/models/Enums";

import styles from "./CatalogModal.module.scss";

interface IMassRentalCatalogModalProps {
    dataProvider: ICatalogDataProvider;
    onChange?(sender: MassRentalCatalogModal, item: WorkOrderEquipment | WorkOrderExtraCharge | WorkOrderRentalItemModel): Promise<void>;
    addEquipmentItem(sender: MassRentalCatalogModal, equipmentItems: WorkOrderEquipment[]): Promise<void>;
    onFavoriteChange?(sender: MassRentalCatalogModal, productId: string, favorite: boolean): Promise<void>;
    onModalChange?(): Promise<void>;
}

interface IMassRentalCatalogModalState {
    equipment: WorkOrderEquipment[];
    dataProvider: ICatalogDataProvider;
}

export default class MassRentalCatalogModal extends BaseComponent<IMassRentalCatalogModalProps, IMassRentalCatalogModalState> {
    
    public state: IMassRentalCatalogModalState = {
        equipment: [],
        dataProvider: new CatalogDataProvider([], [], [], true)
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _categoriesOrProductsRef: React.RefObject<Catalog> = React.createRef();

    private async onChangeItemAsync(item: WorkOrderEquipment | WorkOrderExtraCharge | WorkOrderRentalItemModel): Promise<void> {
        if (item instanceof WorkOrderEquipment) {
            const  { equipment } = this.state;
            const existingIndex: number = equipment.findIndex((equipment : WorkOrderEquipment) : boolean => equipment.productId === item.productId);
            if (existingIndex !== -1) {
                equipment[existingIndex] = item;
            } else {
                equipment.push(item);
            }
            this.setState({equipment})
        } 
        
        if (this.props.onChange) {
            await this.props.onChange(this, item);
        }
    }

    private async onFavoriteChangeAsync(productId: string, favorite: boolean): Promise<void> {
        if (this.props.onFavoriteChange) {
            await this.props.onFavoriteChange(this, productId, favorite);
        }
    }
    
    private async onModalChangeClickAsync (): Promise<void> {
        await this.closeAsync();
        if (this.props.onModalChange) {
            await this.props.onModalChange();
        }
    }

    private async onAddItemsAsync (): Promise<void> {
        await this.props.addEquipmentItem(this, this.state.equipment);
        this.setState({equipment: []})
        await this.closeAsync();
    }

    public async openAsync(): Promise<void> {
        if (this._modalRef.current) {
            const newProvider : CatalogDataProvider = new CatalogDataProvider(this.state.equipment, [], [], true)
            this.setState({equipment: [], dataProvider: newProvider});
            await this._categoriesOrProductsRef.current!.reloadAsync();
            await this._modalRef.current.openAsync();
        }
    }

    public async reloadAsync(search: string | null = null, categoryId: string | null = null, push: boolean = true): Promise<void> {
        if (this._categoriesOrProductsRef.current) {
            await this._categoriesOrProductsRef.current.reloadAsync(search, categoryId, push);
        }
    }

    public async closeAsync(): Promise<void> {
        if (this._modalRef.current) {
            await this._modalRef.current.closeAsync();
        }
        await this.reRenderAsync();
    }

    public static get modalId(): string {
        return "massRentalCategoriesOrProductsModal";
    }

    public get isOpen(): boolean {
        return ((this._modalRef.current != null) && (this._modalRef.current.isOpen));
    }

    public render(): React.ReactNode {
        return (
            <Modal className={styles.catalogModal}
                   id={MassRentalCatalogModal.modalId}
                   ref={this._modalRef}
                   title={Localizer.rentaTasksAddEquipmentCategoriesOrProductsModalTitleRentalMassEquipment}
                   onClose={() => this.onAddItemsAsync()}
                   customCloseButton={Localizer.catalogModalAddAndClose}
                   subtitle={Localizer.rentaTasksAddEquipmentCategoriesOrProductsModalSubtitleChooseEquipment}
            >

                <div>
                    <Inline>
                        <Button icon={{name: "arrow-left", size: IconSize.Large}}
                                label={Localizer.catalogModalAddRentalEquipmentInstead}
                                type={ButtonType.Orange}
                                onClick={() => this.onModalChangeClickAsync()}/>
                    </Inline>
                    
                    <Catalog id={"addEquipmentModeCatalog"}
                             type={CatalogType.RentalMassProduct}
                             ref={this._categoriesOrProductsRef}
                             dataProvider={this.state.dataProvider}
                             mode={CatalogMode.AddEquipment}
                             onChange={(_, item) => this.onChangeItemAsync(item)}
                             onFavoriteChange={(_, item, favorite) => this.onFavoriteChangeAsync(item, favorite)}
                    />
                </div>

            </Modal>
        )
    }
}