import React from "react";
import {ApiProvider, ch, IManualProps, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Icon, IconSize, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../../models/base/AuthorizedPage";
import PageDefinitions from "../../../providers/PageDefinitions";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import {MounterPermission, WorkOrderStatus} from "@/models/Enums";
import GetWorkOrderRequest from "@/models/server/requests/GetWorkOrderRequest";
import {GeoLocation, Utility} from "@renta-apps/athenaeum-toolkit";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";
import {ActivateWorkOrderRequest} from "@/models/server/requests/ActivateWorkOrderRequest";
import WorkOrderChangeStatusModal, {ChangeStatusAction} from "@/components/WorkOrderChangeStatusModal/WorkOrderChangeStatusModal";
import StatusMessageModal from "@/components/StatusMessageModal/StatusMessageModal";
import EnumProvider from "@/providers/EnumProvider";
import RentaTasksController, {RentaTasksAction} from "@/pages/RentaTasks/RentaTasksController";
import WorkOrderProvider from "@/providers/WorkOrderProvider";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "../../../localization/Localizer";
import throwIfFalsy from "@/functions/ThrowIfFalsy";

import styles from "./WorkOrder.module.scss";
import rentaTaskStyles from "../../RentaTask.module.scss";

export interface IWorkOrderPageProps {
}

/** @private */
interface IWorkOrderPageState {
    workOrder: WorkOrderModel | null;
}

/**
 * {@link RentaTasksAction}s which can be performed in the {@link WorkOrderPage}.
 */
type WorkOrderPageAction =
    RentaTasksAction.AddEquipment |
    RentaTasksAction.CompleteWorkOrder |
    RentaTasksAction.EditHoursAndDistances |
    RentaTasksAction.EditWorkOrder |
    RentaTasksAction.PreviewDetails |
    RentaTasksAction.SignOut;

export default class WorkOrderPage extends AuthorizedPage<IWorkOrderPageProps, IWorkOrderPageState> {

    public state: IWorkOrderPageState = {
        workOrder: null
    };

    private readonly _changeStatusModalRef: React.RefObject<WorkOrderChangeStatusModal> = React.createRef();
    private readonly _statusMessageModalRef: React.RefObject<StatusMessageModal> = React.createRef();

    private get workOrder(): WorkOrderModel | null {
        return this.state.workOrder;
    }

    public get title(): string {
        return TransformProvider.toString(this.workOrder);
    }

    private get changeStatusModal(): WorkOrderChangeStatusModal {
        return this._changeStatusModalRef.current!;
    }

    private get showStatusMessageIcon(): boolean {
        return (
            (this.workOrder != null) &&
            (
                (
                    (this.workOrder.currentStatusComment != null && this.workOrder.currentStatusComment.length > 0) &&
                    (WorkOrderModel.isReactivated(this.workOrder))
                )
            )
        );
    }

    private get hasMounters(): boolean {
        return (this.workOrder != null && this.workOrder.mounters.length > 0);
    }

    private get canCompleteWorkOrder(): boolean {
        return !this.userContext.isSubcontractorMounter || RentaTasksController.can(MounterPermission.CompleteWorkOrder);
    }

    private get canProcessForm(): boolean {
        return !this.userContext.isSubcontractorMounter || RentaTasksController.can(MounterPermission.ProcessForm);
    }

    private static async getWorkOrderAsync(workOrderId: string): Promise<WorkOrderModel> {
        throwIfFalsy(workOrderId, nameof(workOrderId));

        const request: GetWorkOrderRequest = new GetWorkOrderRequest();

        request.workOrderId = workOrderId;
        request.excludeDeletedUserSalaryHours = true;
        request.includeMounters = true;

        return await WorkOrderProvider.getWorkOrderAsync(ApiProvider, request);
    }

    private static getFailureMessage(action: WorkOrderPageAction): string {
        switch (action) {
            case RentaTasksAction.AddEquipment:
                return Localizer.rentaTasksWorkOrderAddEquipmentFailedAlert;
            case RentaTasksAction.CompleteWorkOrder:
                return Localizer.rentaTasksWorkOrderCompleteFailedAlert;
            case RentaTasksAction.EditHoursAndDistances:
                return Localizer.rentaTasksWorkOrderEditHoursAndDistancesFailedAlert;
            case RentaTasksAction.EditWorkOrder:
                return Localizer.rentaTasksWorkOrderEditFailedAlert;
            case RentaTasksAction.SignOut:
                return Localizer.rentaTasksWorkOrderSignOutFailedAlert;
            case RentaTasksAction.PreviewDetails:
                return Localizer.rentaTasksWorkOrderPreviewDetailsFailedAlert;
            default:
                throw new TypeError(`Unsupported action '${action}'`);
        }
    }

    private static canSignIn(workOrder: WorkOrderModel): boolean {
        return RentaTasksController.canSignIn(workOrder);
    }

    private async activateWorkOrderAsync(workOrderId: string, sendNotification: boolean, comment: string | null): Promise<void> {
        const request = new ActivateWorkOrderRequest();
        request.workOrderId = workOrderId;
        request.sendNotification = sendNotification;
        request.comment = comment;

        await this.postAsync("/api/rentaTasks/activateWorkOrder", request);

        this.state.workOrder = await WorkOrderPage.getWorkOrderAsync(workOrderId);

        await this.alertMessageAsync(Localizer.workOrdersAlertMessageWorkOrderActivated.format(this.workOrder), true);

        await this.reRenderAsync();
    }

    private async reActivateWorkOrderAsync(workOrder: WorkOrderModel): Promise<void> {
        const active: boolean = (
            Utility.inInterval(
                Utility.now(),
                workOrder.activationDate,
                workOrder.completionDate
            )
        );

        const invoiced: boolean = (workOrder.currentStatus == WorkOrderStatus.Invoiced);
        const readyForInvoicing: boolean = WorkOrderModel.isReadyForInvoicing(workOrder);

        const canActivate: boolean = (!active) &&
            (!invoiced) &&
            (!readyForInvoicing);

        if (canActivate) {
            await this.changeStatusModal.openAsync(workOrder);
        } else {
            await this.alertMessageAsync(Localizer.rentaTasksWorkOrderAlertWorkOrderNotActivated.format(EnumProvider.getWorkOrderStatusText(workOrder.currentStatus)), true);
        }
    }

    private async signInAsync(workOrder: WorkOrderModel): Promise<void> {
        const confirmed: boolean = (workOrder.currentStatus == WorkOrderStatus.InProgress) || (await ch.confirmAsync(Localizer.rentaTasksWorkOrderSignInConfirm));

        if (!confirmed) {
            return;
        }

        // reload the WO to check the actual WO status
        workOrder = await WorkOrderPage.getWorkOrderAsync(workOrder.id);

        if (!WorkOrderPage.canSignIn(workOrder)) {
            await this.alertMessageAsync(Localizer.rentaTasksWorkOrderSignInFailedAlert);
        } else {
            await RentaTasksController.checkInAsync(workOrder);
            
            // Reload the work order to get up to date mounter information
            workOrder = await WorkOrderPage.getWorkOrderAsync(workOrder.id);
            await this.alertMessageAsync(Localizer.rentaTasksWorkOrderSignInAlert, true);
        }

        // Set up to date work order.
        await this.setState({workOrder: workOrder});
    }

    private async startActionAsync(action: WorkOrderPageAction, workOrder: WorkOrderModel): Promise<void> {
        throwIfFalsy(workOrder, nameof(workOrder));

        RentaTasksController.setWizardContextWorkOrder(workOrder);

        const failureMessage: string = WorkOrderPage.getFailureMessage(action);

        if (!await RentaTasksController.startActionAsync(action, failureMessage)) {
            workOrder = await WorkOrderPage.getWorkOrderAsync(workOrder.id);

            await this.setState({workOrder});

            await this.reRenderAsync();
        }
    }

    private async onConfirmAsync(workOrderId: string, sendNotification: boolean, comment: string | null): Promise<void> {
        if (this._changeStatusModalRef.current) {
            await this.activateWorkOrderAsync(workOrderId, sendNotification, comment);
        }
    }

    private async openStatusMessage(): Promise<void> {
        if (this._statusMessageModalRef.current && this.workOrder) {
            await this._statusMessageModalRef.current!.openAsync(this.workOrder);
        }
    }

    public getManualProps(): IManualProps {
        const manual: string | undefined = this.workOrder?.description || undefined;

        return {
            title: Localizer.rentaTasksWorkOrderManualTitle,
            icon: "fal info",
            manual,
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const workOrderId: string | null = this.routeId;

        if (!workOrderId) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute, true, true);
            return;
        }

        const workOrder: WorkOrderModel = await WorkOrderPage.getWorkOrderAsync(workOrderId);

        await this.setState({workOrder});

        ch.reloadTopNav();
    }

    private static renderPhoneNumberLink(phoneNumber?: string): React.ReactNode {
        if (!phoneNumber) {
            return "-";
        }

        return (
            <a href={`tel:${phoneNumber}`}>
                {phoneNumber}
            </a>
        );
    }

    private static renderWorkOrderData(workOrder: WorkOrderModel): React.ReactNode {
        throwIfFalsy(workOrder, nameof(workOrder));

        const customerName: string = workOrder.owner?.organizationContract?.name || "-";
        const constructionSiteName: string = workOrder.owner?.name || "-";
        const invoiceReference: string = workOrder.invoiceReference || "-";

        const customerApproverName: string = (workOrder.customerApprover)
            ? "{0}".format(workOrder.customerApprover)
            : "-";

        const customerOrdererName: string = (workOrder.customerOrderer)
            ? "{0}".format(workOrder.customerOrderer)
            : "-";

        const managerName: string = (workOrder.manager)
            ? "{0}".format(workOrder.manager)
            : "-";

        const ownerLocation: GeoLocation | null | undefined = workOrder.owner?.location;
        const approverPhone: string | undefined = workOrder.customerApprover?.phone;
        const ordererPhone: string | undefined = workOrder.customerOrderer?.phone;
        const managerPhone: string | undefined = workOrder.manager?.phone;
        const mounters: string = WorkOrderModel.assignedMountersFullNames(workOrder);
        let mountersToDisplay: string = (workOrder.mounters.length > 1)
            ? `${TransformProvider.userToString(workOrder.mounters[0])}...(${workOrder.mounters.length})`
            : (workOrder.mounters.length == 1)
                ? mounters
                : "-";

        const distance: number = (workOrder.distances || []).sum(distance => distance.value);
        const normalHours: number = (workOrder.userSalaryHours || []).sum(salaryHour => salaryHour.normalHours);
        const overtime50Hours: number = (workOrder.userSalaryHours || []).sum(salaryHour => salaryHour.overtime50Hours);
        const overtime100Hours: number = (workOrder.userSalaryHours || []).sum(salaryHour => salaryHour.overtime100Hours);
        const totalHours: number = normalHours + overtime50Hours + overtime100Hours;

        const formattedDistance: string = Localizer.genericKm.format(distance);

        const workingHours: string = Localizer.genericHours.format(
            totalHours,
            normalHours,
            overtime50Hours,
            overtime100Hours
        );

        const tableClass: string = Utility.css(styles.table, "table table-striped");

        return (
            <table className={tableClass}>
                <tbody>
                <tr>
                    <td>{Localizer.genericNumber}</td>
                    <td>{workOrder.number}</td>
                </tr>

                <tr>
                    <td>{Localizer.rentaTasksWorkOrderLabelCustomerName}</td>
                    <td>{customerName}</td>
                </tr>

                <tr>
                    <td>{Localizer.rentaTasksWorkOrderLabelConstructionSite}</td>
                    <td>{constructionSiteName}</td>
                </tr>

                <tr>
                    <td>{Localizer.rentaTasksWorkOrderLabelAddress}</td>
                    <td>
                        {
                            (!!ownerLocation)
                                ? (
                                    <address>
                                        <a href={`https://www.google.com/maps/dir//${ownerLocation.lat},${ownerLocation.lon}`}>
                                            {"{0}".format(ownerLocation)}
                                        </a>
                                    </address>
                                )
                                : "-"
                        }
                    </td>
                </tr>

                <tr>
                    <td>{Localizer.rentaTasksWorkOrderLabelInvoiceReference}</td>
                    <td>{invoiceReference}</td>
                </tr>

                <tr>
                    <td>{Localizer.rentaTasksWorkOrderLabelOrderer}</td>
                    <td>{customerOrdererName}</td>
                </tr>

                <tr>
                    <td>{Localizer.rentaTasksWorkOrderLabelOrdererPhone}</td>
                    <td>
                        {WorkOrderPage.renderPhoneNumberLink(ordererPhone)}
                    </td>
                </tr>

                <tr>
                    <td>{Localizer.rentaTasksWorkOrderLabelApprover}</td>
                    <td>{customerApproverName}</td>
                </tr>

                <tr>
                    <td>{Localizer.rentaTasksWorkOrderLabelApproverPhone}</td>
                    <td>
                        {WorkOrderPage.renderPhoneNumberLink(approverPhone)}
                    </td>
                </tr>

                <tr>
                    <td>{Localizer.rentaTasksWorkOrderLabelManager}</td>
                    <td>{managerName}</td>
                </tr>

                <tr>
                    <td>{Localizer.rentaTasksWorkOrderLabelManagerPhone}</td>
                    <td>
                        {WorkOrderPage.renderPhoneNumberLink(managerPhone)}
                    </td>
                </tr>

                <tr>
                    <td>{Localizer.genericMounters}</td>
                    <td className={"d-flex"}>{mountersToDisplay}
                        {
                            (workOrder.mounters.length > 1) &&
                            (
                                <InlineTooltip text={mounters}
                                               className={"d-flex align-content-center pl-2 pr-2"}
                                               icon=
                                                   {
                                                       <Icon
                                                           id={`allMountersIcon`}
                                                           name="far comment-alt-dots"
                                                           size={IconSize.Normal}
                                                       />
                                                   }
                                >
                                </InlineTooltip>
                            )
                        }
                    </td>
                </tr>

                <tr>
                    <td>{Localizer.rentaTasksWorkOrderLabelDistance}</td>
                    <td>{formattedDistance}</td>
                </tr>

                <tr>
                    <td>{Localizer.genericWorkingHours}</td>
                    <td>
                        {workingHours}
                    </td>
                </tr>

                {
                    (workOrder.type != null) &&
                    (
                        <tr>
                            <td>{Localizer.genericWorkOrderTypeLabel}</td>
                            <td>{TransformProvider.toString(workOrder.type)}</td>
                        </tr>
                    )
                }
                
                <tr>
                    <td>{Localizer.workOrderContractTypeDropdownLabel}</td>
                    <td>{EnumProvider.getWorkOrderContractTypeText(workOrder.contractType)}</td>
                </tr>
                </tbody>
            </table>
        );
    }

    private renderWorkOrder(): React.ReactNode {
        const workOrder: WorkOrderModel | null = this.workOrder;

        if (!workOrder) {
            return null;
        }

        const stateDescription: string = WorkOrderModel.getStateDescription(workOrder);

        const activationDate: string = (workOrder.activationDate)
            ? "{0:dd.MM.yyyy}".format(workOrder.activationDate)
            : "-";

        const completionDate: string = (workOrder.completionDate)
            ? "{0:dd.MM.yyyy}".format(workOrder.completionDate)
            : "-";

        return (
            <div className={styles.container}>
                <div className={styles.data}>
                    <div>
                        {WorkOrderPage.renderWorkOrderData(workOrder)}
                    </div>
                </div>

                <div id="workOrderInfo" className={styles.info}>
                    <p>
                        {Localizer.rentaTasksWorkOrderLabelStatus}
                        <mark className={this.css((workOrder.hasBlockingForms) && "text-danger")}> {stateDescription}</mark>
                    </p>

                    <p>
                        {Localizer.rentaTasksWorkOrderLabelActivationDate}
                        <mark> {activationDate}</mark>
                    </p>

                    <p>
                        {Localizer.rentaTasksWorkOrderLabelCompletionDate}
                        <mark> {completionDate}</mark>
                    </p>
                </div>

                {
                    (RentaTasksController.canActivate(workOrder)) &&
                    (
                        <Button block
                                className={rentaTaskStyles.bigButton}
                                type={ButtonType.Success}
                                label={Localizer.rentaTasksWorkOrderButtonActivate}
                                icon={{name: "fas user-clock"}}
                                onClick={async () => await this.reActivateWorkOrderAsync(workOrder)}
                        />
                    )
                }

                {
                    (WorkOrderPage.canSignIn(workOrder)) &&
                    (
                        <Button id="workOrderSignInButton"
                                block
                                className={rentaTaskStyles.bigButton}
                                type={ButtonType.Orange}
                                label={Localizer.rentaTasksWorkOrderButtonLabelSignIn}
                                icon={{name: "fas user-clock"}}
                                onClick={async () => await this.signInAsync(workOrder)}
                        />
                    )
                }

                {
                    (RentaTasksController.canSignOut(workOrder)) &&
                    (
                        <Button id="workOrderSignOutButton"
                                block
                                className={rentaTaskStyles.bigButton}
                                type={ButtonType.Orange}
                                label={Localizer.rentaTasksWorkOrderButtonLabelSignOut}
                                icon={{name: "fas user-clock"}}
                                onClick={async () => await this.startActionAsync(RentaTasksAction.SignOut, workOrder)}
                        />
                    )
                }

                <Button id="workOrderShowDetailsButton"
                        block
                        className={rentaTaskStyles.bigButton}
                        type={ButtonType.Orange}
                        label={Localizer.rentaTasksWorkOrderButtonLabelShowDetails}
                        icon={{name: "fas info"}}
                        onClick={async () => await this.startActionAsync(RentaTasksAction.PreviewDetails, workOrder)}
                />

                <Button id="workOrderAttachmentsButton"
                        block
                        type={ButtonType.Orange}
                        className={rentaTaskStyles.bigButton}
                        icon={{name: "fal list-alt"}}
                        label={Localizer.rentaTasksWorkOrderButtonLabelAttachments}
                        route={PageDefinitions.rentaTasksAttachmentsRoute(workOrder.id)}
                />

                {
                    (this.canProcessForm) &&
                    (
                        <Button block
                                id="workOrderFormsButton"
                                type={ButtonType.Orange}
                                className={rentaTaskStyles.bigButton}
                                icon={{name: "fal list-alt"}}
                                label={Localizer.rentaTasksWorkOrderFormsButton}
                                disabled={!RentaTasksController.canConductForms(workOrder)}
                                route={PageDefinitions.rentaTasksWorkOrderForms(workOrder.id)}
                        />
                    )
                }

                <Button block
                        id="workOrderEditButton"
                        className={rentaTaskStyles.bigButton}
                        type={ButtonType.Orange}
                        label={Localizer.workOrdersModalActionsEdit}
                        icon={{name: "far edit"}}
                        disabled={!RentaTasksController.canEdit(workOrder)}
                        onClick={async () => await this.startActionAsync(RentaTasksAction.EditWorkOrder, workOrder)}
                />

                <Button block
                        id="workOrderAddEquipmentButton"
                        className={rentaTaskStyles.bigButton}
                        type={ButtonType.Orange}
                        label={Localizer.rentaTasksWorkOrderButtonLabelAddEquipment}
                        icon={{name: "fas tools"}}
                        disabled={!RentaTasksController.canAddEquipment(workOrder)}
                        onClick={async () => await this.startActionAsync(RentaTasksAction.AddEquipment, workOrder)}
                />

                <Button block
                        id="workOrderAddHoursAndDistancesButton"
                        className={rentaTaskStyles.bigButton}
                        type={ButtonType.Orange}
                        label={Localizer.rentaTasksWorkOrderButtonLabelEditHoursAndDistances}
                        icon={{name: "fas clock"}}
                        disabled={!RentaTasksController.canEditHoursAndDistances(workOrder)}
                        onClick={async () => await this.startActionAsync(RentaTasksAction.EditHoursAndDistances, workOrder)}
                />

                {
                    (this.canCompleteWorkOrder) &&
                    (
                        <Button block
                                id="workOrderCompleteButton"
                                className={rentaTaskStyles.bigButton}
                                type={ButtonType.Success}
                                label={Localizer.workOrdersModalActionsComplete}
                                icon={{name: "far check-circle"}}
                                disabled={!RentaTasksController.canComplete(workOrder)}
                                onClick={async () => await this.startActionAsync(RentaTasksAction.CompleteWorkOrder, workOrder)}
                        />
                    )
                }

                <Button block
                        id="workOrderBackButton"
                        className={rentaTaskStyles.bigButton}
                        type={ButtonType.Blue}
                        label={Localizer.rentaTasksWorkOrderButtonLabelBack}
                        route={PageDefinitions.rentaTasksWorkOrdersRoute}
                />
            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaTaskStyles.alert}
                           className={this.css(rentaTaskStyles.pageContainer, styles.workOrder)}
            >

                <PageHeader title={this.title}
                            className={this.css(rentaTaskStyles.leftPageHeader, styles.title)}
                >

                    {
                        (this.showStatusMessageIcon) &&
                        (
                            <Icon id={`statusMessageIcon`}
                                  className={styles.bell}
                                  name="fas fa-bell-exclamation"
                                  size={IconSize.X2}
                                  onClick={() => this.openStatusMessage()}
                            />
                        )
                    }
                </PageHeader>

                <PageRow className={this.css(rentaTaskStyles.pageRow)}>
                    {this.renderWorkOrder()}
                </PageRow>

                <WorkOrderChangeStatusModal ref={this._changeStatusModalRef}
                                            notificationTrigger={(this.hasMounters) ? true : undefined}
                                            forceComment={(this.hasMounters) ? true : undefined}
                                            action={ChangeStatusAction.Reactivate}
                                            onConfirmAsync={(workOrderId, sendNotification, comment) => this.onConfirmAsync(workOrderId, sendNotification, comment)}
                />

                <StatusMessageModal ref={this._statusMessageModalRef} />
                
            </PageContainer>
        );
    }
}