import {FormItemType, FormMeasuringOperator} from "@/models/Enums";
import FormCheckItem from "@/models/server/forms/FormCheckItem";
import {FileModel, INumberFormat, NumberUtility, TFormat} from "@renta-apps/athenaeum-toolkit";

export default class FormItem {

    public id: string | null = null;

    public group: string = "";

    public type: FormItemType = FormItemType.Question;

    public name: string | null = null;

    public icon: string | null = null;

    public manual: string | null = null;

    public step: number | null = null;

    public exportSize: number = 100;

    /* Resource, Text */
    public min: number | null = null;

    /* Resource, Text */
    public max: number | null = null;

    /* Text */
    public rows: number | null = null;

    /* Resource */
    public valueStep: number | null = null;

    /* Resource */
    public default: number | null = null;

    public passedValue: number | null = null;

    /* Options, Text */
    public required: boolean | null = null;

    /* Options */
    public commentRequired: boolean | null = null;

    /* Options */
    public multiple: boolean | null = null;

    public passedValueOperator: FormMeasuringOperator | null = null;

    /* Checks, Options */
    public checks: FormCheckItem[] | null = null;

    public valueTypeId: string | null = null;

    public valueTypeIds: string[] | null = null;

    /* Resource */
    public value: number | null = null;

    /* Question, QuestionPictures  */
    public ok: boolean | null = null;

    /* Text */
    public text: string | null = null;

    /* Questions */
    public commented: boolean = false;

    /* Questions, Options */
    public comment: string | null = null;

    /* Questions */
    public picture: FileModel | null = null;

    /* Pictures */
    public pictures: FileModel[] | null = null;

    public isFormItem: true = true;

    public static hasPictures(item: FormItem): boolean {
        return item.pictures?.some(file => file != null) === true;
    }

    public static getFormat(item: FormItem): TFormat {
        const valueStep = (item.valueStep && (item.valueStep != 0))
            ? item.valueStep
            : 1;

        const numberFormat: INumberFormat = NumberUtility.resolveFormat(valueStep, null);
        return numberFormat.format;
    }

    /**
     * @returns True if the form item has been properly filled; false otherwise.
     * @see isOk
     */
    public static isValid(item: FormItem | FormItem[]): boolean {
        if (Array.isArray(item)) {
            return item.every(formItem => FormItem.isValid(formItem));
        }

        switch (item.type) {
            case FormItemType.Checks:
                return (item.checks != null) && item.checks!.every(check => (check != null) && (check.isRequired ? check.ok != null : true));

            case FormItemType.Options:
                let isValid = (item.checks != null) && (item.checks.every(check => (check != null) && (check.ok != null)));
                isValid = isValid && ((!item.required) || (item.checks!.some(item => item.ok == true)));
                isValid = isValid && ((!item.commentRequired) || (item.checks![item.checks!.length - 1].ok != true) || (!!item.comment));
                return isValid;

            case FormItemType.Pictures:
                return FormItem.hasPictures(item);

            case FormItemType.Question:
                // OK/not OK has been selected, if not OK comment has been provided.
                return (typeof item.ok === "boolean") &&
                    (
                        (item.ok === true) || (!!item.comment?.trim())
                    );

            case FormItemType.QuestionPictures:
                // OK/not OK has been selected, if not OK comment has been provided, has at least one picture.
                return (typeof item.ok === "boolean") &&
                    (
                        (item.ok === true) || (!!item.comment?.trim())
                    ) &&
                    (
                        (!!item.picture) || (FormItem.hasPictures(item))
                    );

            case FormItemType.Resource:
                return (item.value != null) && ((item.min == null) || (item.value >= item.min)) && ((item.max == null) || (item.value <= item.max));

            case FormItemType.Text:
                return (!item.required) || (!!item.text) && ((item.min == null) || (item.text.length >= item.min)) && ((item.max == null) || (item.text.length <= item.max));

            default:
                throw new Error(`Unsupported form item type ${item.type}`);
        }
    }

    /**
     * @returns True if the form item {@link isValid} and the result is a pass; false otherwise.
     */
    public static isOk(item: FormItem): boolean {
        if (!FormItem.isValid(item)) {
            return false;
        }
        switch (item.type) {
            case FormItemType.Checks:
                return item.checks!.every(item => item.isRequired ? item.ok === true : item.ok !== false);

            case FormItemType.Options:
                return ((!item.required) || (item.checks!.some(item => item.ok == true)));

            case FormItemType.Question:
            case FormItemType.QuestionPictures:
                return item.ok === true;

            case FormItemType.Pictures:
            case FormItemType.Resource:
            case FormItemType.Text:
                return true;

            default:
                throw new Error(`Unsupported form item type ${item.type}`);
        }
    }

    public static initialize(item: FormItem | FormItem[]): void {
        if (Array.isArray(item)) {
            item.map(formItem => FormItem.initialize(formItem));
            return;
        }

        if (item.type === FormItemType.Options) {
            const checks: FormCheckItem[] = item.checks || (item.checks = []);
            for (let i: number = 0; i < checks.length; i++) {
                if (checks[i].ok == null) {
                    checks[i].ok = false;
                }
            }
        }
    }
}