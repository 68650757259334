import React from "react";
import {Button, ButtonContainer, ButtonType, Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import User from "@/models/server/User";
import Localizer from "@/localization/Localizer";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import TransformProvider from "@/providers/TransformProvider";

interface ISubcontractorAssignmentModalProps {
    unAssignMounter(mounter: User): Promise<void>;
    assignMounter(mounter: User): Promise<void>;
}

interface ISubcontractorAssignmentModalState {
    mounter: User | null;
}

export default class SubcontractorAssignmentModal extends BaseComponent<ISubcontractorAssignmentModalProps, ISubcontractorAssignmentModalState> {
    public state: ISubcontractorAssignmentModalState = {
        mounter: null,
    }

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }
    public async openAsync(mounter: User): Promise<void> {
        this.setState({mounter: mounter});
        await this.modal.openAsync();
    }
    public async closeAsync(): Promise<void> {
        this.setState({ mounter: null });
        await this.modal.closeAsync();
    }

    private get mounter(): User | null {
        return this.state.mounter;
    }

    public static get modalId(): string {
        return "subcontractorAssignmentModal";
    }

    public render(): React.ReactNode {
        return (
            <Modal id="subcontractorAssignmentModal"
                   ref={this._modalRef}
                   size={ModalSize.Default}
                   hideCloseButton={true}
                   title={Localizer.subcontractorAssignmentModalTitle}
            >
                <p>{Localizer.subcontractorAssignmentModalWarning}</p>

                {
                    this.mounter &&
                    (
                        <React.Fragment>
                            <p>{TransformProvider.userToString(this.mounter)}<br />{this.mounter.role.organizationContract?.name}</p>
                
                            <ButtonContainer>
                                <Button
                                    label={Localizer.genericUnassign}
                                    type={ButtonType.Danger}
                                    onClick={async () => await this.props.unAssignMounter(this.mounter!)}
                                />
            
                                <Button
                                    label={Localizer.genericAssign}
                                    type={ButtonType.Success}
                                    onClick={async () => await this.props.assignMounter(this.mounter!)}
                                />
                            </ButtonContainer>
                        </React.Fragment>
                    )
                }

            </Modal>
        )
            ;
    }
}