import React from "react";

import {BaseComponent} from "@renta-apps/athenaeum-react-common";

import styles from "./FormButton.module.scss";
import {Button, IButtonProps} from "@renta-apps/athenaeum-react-components";

interface IFormButtonProps extends IButtonProps {
    big?: boolean;
    secret?: boolean;
    noPointerEvents?: boolean;
    buttonId?: number;
}

export default class FormButton extends BaseComponent<IFormButtonProps> {

    render(): React.ReactNode {
        const bigStyle: string = this.props.big ? styles.formButton : "";
        const secretStyle: string = this.props.secret ? styles.formSecretButton : "";
        const noPointerEventsStyle: string = this.props.noPointerEvents ? styles.formNoPointerEventsButton : "";

        return (
            <Button {...this.props} className={this.css(bigStyle, secretStyle, noPointerEventsStyle, this.props.className)} />
        );
    }
};