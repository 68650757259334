import {NotificationType} from "@/models/Enums";

context("Global notification settings", () => {

    beforeEach(() => {
        cy.init("Desktop");
    });

    it("Should work correctly for Admin", () => {
        cy.loginAsAdmin();
        test();
    });

    function test(): void {
        describe('Update global "Work orders processed approval" notification settings', () => {
            cy.modifyNotificationSettings(NotificationType.WorkOrdersProcessedApproval, true);
        });

        describe('Update global "Work orders pending approval" notification settings', () => {
            cy.modifyNotificationSettings(NotificationType.WorkOrdersPendingApproval, true);
        });

        describe('Update global "Work order reactivation" notification settings', () => {
            cy.modifyNotificationSettings(NotificationType.WorkOrderReactivation, true);
        });

        describe('Update global "Work order assignment" notification settings', () => {
            cy.modifyNotificationSettings(NotificationType.WorkOrderAssignment, true);
        });
    }
});