import {getRandomInteger, onRedirect, pageData} from "@/helpers/CypressHelper";
import {Utility} from "@renta-apps/athenaeum-toolkit";

context("(Mobile) Renta Tasks: Add equipment to work order", () => {

    beforeEach(() => {
        cy.init("Mobile");
    });

    const equipmentName: string = "RootCategoryProduct1";
    let equipmentAmount: number = getRandomInteger(null, 9999);

    let customEquipmentName: string = getCustomEquipmentName();
    let customEquipmentAmount: number = getRandomInteger(null, 9999);

    const extraChargeName: string = "Test type";
    let extraChargeAmount: number = getRandomInteger(null, 9999);

    function getCustomEquipmentName(): string {
        return "Custom equipment " + getRandomInteger(null, 9999);
    }

    const next = function () {
        pageData().dashboard.wizard.next().parent().should("not.have.class", "athenaeum-navigation-widget-disabled");
        onRedirect(() => pageData().dashboard.wizard.next().click());
    }

    it("Should add equipment to work order by mounter", () => {

        cy.loginAsMounter();
        testAdd();
        next();
    });

    it("Should edit equipment for work order by mounter", () => {

        cy.loginAsMounter();
        testEdit();
        next();
    });

    it("Should delete equipment from the work order by mounter", () => {

        cy.loginAsMounter();
        testDelete();
    });

    it("Should add equipment to work order by manager", () => {

        cy.loginAsManager();

        equipmentAmount = getRandomInteger(null, 9999);
        customEquipmentAmount = getRandomInteger(null, 9999);
        extraChargeAmount = getRandomInteger(null, 9999);
        customEquipmentName = getCustomEquipmentName();
        
        testAdd(true);
        next();
    });

    it("Should edit equipment for work order by manager", () => {

        cy.loginAsManager();
        testEdit();
        next();
    });

    it("Should delete equipment from the work order by manager", () => {

        cy.loginAsManager();
        testDelete();
    });
    
    function testAdd(isManagerOrHigher: boolean = false): void {
        onRedirect(() => pageData().dashboard.widgets.mobile.workOrders().click());

        cy.contains('Test work order').click();

        cy.contains('button', 'Add equipment').click();

        const addEquipmentSettings: EquipmentSettingsMobile = {
            path: ["RootCategoryWithProducts"],
            equipmentName: equipmentName,
            amount: equipmentAmount,
            description: `Description of the "${equipmentName}".`,
        }
        
        cy.addEquipmentMobile(addEquipmentSettings);

        const addCustomEquipment: CustomEquipmentSettingsMobile = {
            name: customEquipmentName,
            amount: customEquipmentAmount,
            unit: "Bottle",
        };
        
        if (isManagerOrHigher) {
            addCustomEquipment.price = 2000;
        }

        cy.addCustomEquipmentMobile(addCustomEquipment, isManagerOrHigher);

        const extraCharge: ExtraChargeSettingsMobile = {
            type: extraChargeName,
            date: Utility.utcNow().addDays(-1),
            amount: extraChargeAmount,
            description: `Description of extra charge "${extraChargeName}"`
        };

        cy.addExtraChargeMobile(extraCharge, isManagerOrHigher);
    }
    
    function testEdit(): void {
        onRedirect(() => pageData().dashboard.widgets.mobile.workOrders().click());

        cy.contains('Test work order').click();

        cy.contains('button', 'Add equipment').click();

        const editEquipmentSetting: EditCatalogItemSettingsMobile = {
            name: equipmentName,
            initialAmount: equipmentAmount,
            amount: 100,
            description: `Updated description of "${equipmentName}"`,
            favorite: true
        };

        cy.editCatalogItemMobile(editEquipmentSetting, false, false);

        const editCustomEquipmentSetting: EditCatalogItemSettingsMobile = {
            name: customEquipmentName,
            initialAmount: customEquipmentAmount,
            amount: 200,
            description: `Updated description of "${customEquipmentName}"`,
        };

        cy.editCatalogItemMobile(editCustomEquipmentSetting, true, false);

        const editExtraChargeSetting: EditCatalogItemSettingsMobile = {
            name: extraChargeName,
            initialAmount: extraChargeAmount,
            amount: 300,
            description: `Updated description of "${extraChargeName}"`,
        };

        cy.editCatalogItemMobile(editExtraChargeSetting, false, true);
    }
    
    function testDelete(): void {
        onRedirect(() => pageData().dashboard.widgets.mobile.workOrders().click());

        cy.contains('Test work order').click();

        cy.contains('button', 'Add equipment').click();

        const editEquipmentSetting: EditCatalogItemSettingsMobile = {
            name: equipmentName,
            amount: 0,
        };

        cy.editCatalogItemMobile(editEquipmentSetting, false, false);

        const editCustomEquipmentSetting: EditCatalogItemSettingsMobile = {
            name: customEquipmentName,
            amount: 0,
        };

        cy.editCatalogItemMobile(editCustomEquipmentSetting, true, false);

        const editExtraChargeSetting: EditCatalogItemSettingsMobile = {
            name: extraChargeName,
            amount: 0,
        };

        cy.editCatalogItemMobile(editExtraChargeSetting, false, true);
        
        next();

        cy.contains('button', 'Add equipment').click();

        pageData().catalogManagement.categoriesAndProductsList().within(() => {
            cy.get(`#equipmentItem_${equipmentName.replace(/\s/g, '_')}`).should("not.exist")
        });

        pageData().catalogManagement.categoriesAndProductsList().within(() => {
            cy.get(`#equipmentItem_${customEquipmentName.replace(/\s/g, '_')}`).should("not.exist")
        });

        pageData().catalogManagement.categoriesAndProductsList().within(() => {
            cy.get(`#extraChargeItem_${extraChargeName.replace(/\s/g, '_')}`).should("not.exist")
        });

        next();
    }
});
