import {IBreadCrumbItem} from "@/components/Catalog/BreadCrumb/BreadCrumb";
import {CatalogType} from "@/models/Enums";

export default class Category implements IBreadCrumbItem {
    public id: string = "";
    
    public catalogType: CatalogType = CatalogType.SalesProduct;

    public name: string = "";

    public icon: string = "";

    public parent: Category | null = null;

    public parentId: string | null = null;

    public isCategory: boolean = true;

    constructor(id: string = "", name: string = "", icon: string = "", parent: Category | null = null) {
        this.id = id;
        this.name = name;
        this.icon = icon;
        this.parent = parent;
    }

    /**
     * Return all parent {@link Category}s of a {@link Category}, along with the category itself.
     */
    public static toBreadCrumbs(category: Category): Category[] {
        return (category.parent)
            ? [...Category.toBreadCrumbs(category.parent), category]
            : [category];
    }
}