import AuthorizedPage from "@/models/base/AuthorizedPage";
import React from "react";
import {
    Button,
    ButtonType, CellAction,
    CellModel,
    ColumnDefinition,
    ColumnType,
    Grid,
    GridHoveringType,
    GridModel,
    GridOddType,
    IconSize,
    Inline,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow,
    RowModel,
    ToolbarContainer
} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import Region from "@/models/server/Region";
import {ActionType, TextAlign} from "@renta-apps/athenaeum-react-common";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import {getCellAction} from "@/helpers/GridHelpers";
import AddRegionRequest from "@/models/server/requests/AddRegionRequest";
import SaveRegionRequest from "@/models/server/requests/SaveRegionRequest";

import styles from "./RegionManagement.module.scss";

interface IRegionManagementProps {
}

interface IRegionManagementState {
    regions: Region[],
}

export default class RegionManagement extends AuthorizedPage<IRegionManagementProps, IRegionManagementState> {
    state: IRegionManagementState = {
        regions: [],
    }

    private readonly _gridRef: React.RefObject<Grid<Region>> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            className: "grey",
            textAlign: TextAlign.Center,
            minWidth: 40,
            noWrap: true
        },
        {
            header: Localizer.genericName,
            accessor: nameof(Region.name),
            type: ColumnType.Text,
            minWidth: 600,
            editable: true,
            settings: {
                maxLength: RentaTaskConstants.dbKeyLength,
            },
            callback: async (cell: CellModel<Region>, _) => await this.onNameChangeAsync(cell)
        },
        {
            header: Localizer.genericActions,
            init: (cell) => this.initActions(cell),
            minWidth: 70,
            maxWidth: 70,
            actions: [
                {
                    name: "save",
                    title: Localizer.genericActionSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell: CellModel<Region>, _) => await this.saveTypeAsync(cell)
                },
                {
                    name: "cancel",
                    title: Localizer.genericActionCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell) => await cell.row.cancelAsync()
                },
                {
                    name: "delete",
                    title: Localizer.genericActionDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    callback: async (cell) => await cell.grid.deleteAsync(cell.row.index)
                },
            ]
        }
    ];

    private get grid(): GridModel<Region> {
        return this._gridRef.current!.model;
    }

    private get newRowAlreadyExists(): boolean {
        return (this.grid.rows.some(row => !row.deleted && !row.model.id));
    }

    private initRow(row: RowModel<Region>): void {
        const model: Region = row.model;

        const isNew: boolean = ((model.id === "") || (model.id === null));
        const isValid: boolean = this.isNameValid(model);

        row.className = (!isValid)
            ? "danger"
            : (isNew)
                ? "bg-processed"
                : "";
    }

    private isNameValid(model: Region): boolean {
        return (model.name.trim().length > 0);
    }

    private initActions(cell: CellModel<Region>) {
        const model: Region = cell.row.model;
        const isNew: boolean = !model.id;
        const modified: boolean = cell.row.modified;
        const isValid: boolean = this.isNameValid(model);

        const saveAction: CellAction<Region> = getCellAction(cell.actions, "save");
        saveAction.visible = ((modified) || (isNew)) && (isValid);

        const cancelAction: CellAction<Region> = getCellAction(cell.actions, "cancel");
        cancelAction.visible = (modified) && (!isNew);

        const deleteAction: CellAction<Region> = getCellAction(cell.actions, "delete");
        deleteAction.visible = (isNew);
    }

    private async getRegionsAsync(): Promise<Region[]> {
        return await this.getAsync("api/region/getRegions");
    }

    private async onNameChangeAsync(cell: CellModel<Region>): Promise<void> {
        const isValid: boolean = this.isNameValid(cell.model);

        await cell.setValidAsync(isValid);
    }

    private async saveTypeAsync(cell: CellModel<Region>): Promise<void> {
        if (cell.model.id === "") {
            // Create
            const request: AddRegionRequest = {
                name: cell.model.name,
            };

            await this.postAsync("api/region/addRegion", request);
        } else {
            // Update
            const request: SaveRegionRequest = {
                id: cell.model.id,
                name: cell.model.name,
            };

            await this.postAsync("api/region/saveRegion", request);
        }

        await this.refreshDataAsync();
    }

    private async onAddClickAsync(): Promise<void> {
        if (!this.newRowAlreadyExists) {
            const newRegion: Region = new Region();

            // Add to grid
            const rows: RowModel<Region>[] = await this.grid.insertAsync(0, newRegion);
            // Set focus to new regions's name column
            await rows[0].get(nameof(Region.name)).editAsync();
        }
    }

    public getTitle(): string {
        return Localizer.regionManagementPageTitle;
    }

    public async componentDidMount(): Promise<void> {
        await this.refreshDataAsync();
    }

    private async refreshDataAsync(): Promise<void> {
        const regions: Region[] = await this.getRegionsAsync();
        await this.setState({regions: regions});
    }


    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.workOrderTypeManagement}>
                <PageHeader title={Localizer.regionManagementPageTitle}/>

                <PageRow>
                    <div className="col">

                        <div className={styles.container}>

                            <ToolbarContainer className={styles.toolbar}>

                                <Inline justify={JustifyContent.End}>

                                    <Button id="addRegion"
                                            icon={{name: "plus", size: IconSize.Large}}
                                            title={Localizer.genericActionAdd}
                                            type={ButtonType.Orange}
                                            onClick={async (_, __) => await this.onAddClickAsync()}
                                    />

                                </Inline>

                            </ToolbarContainer>

                            <Grid responsive
                                  id="regionsGrid"
                                  ref={this._gridRef}
                                  className={styles.grid}
                                  minWidth="auto"
                                  hovering={GridHoveringType.EditableCell}
                                  odd={GridOddType.None}
                                  columns={this._columns}
                                  data={this.state.regions}
                                  initRow={(row: RowModel<Region>) => this.initRow(row)}
                                  noDataText={Localizer.genericNoData}
                            />

                        </div>

                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}
