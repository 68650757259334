import React, {ReactNode} from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Icon, IconSize, IconStyle} from "@renta-apps/athenaeum-react-components";

import styles from "./CatalogItem.module.scss";


export interface ICatalogItemProps {
    id?: string;
    className?: string;
    iconName?: string;
    onClick?(): Promise<void>;
    children?: ReactNode;
}

export default class CatalogItem extends BaseComponent<ICatalogItemProps> {

    private get className(): string {
        return this.css(
            styles.catalogItem,
            this.props.className,
        );
    }

    private get iconName(): string {
        return this.props.iconName?.replace("fa-faucet", "fa-expand").replace("fal", "fas")
            || "fas expand";
    }

    private async onClick(): Promise<void> {
        (this.props.onClick) && await this.props.onClick();
    }

    public render(): JSX.Element {
        return (
            <div id={this.props.id}
                 className={this.className}
                 onClick={async () => await this.onClick()}
            >

                {
                    (this.props.iconName) &&
                    (
                        <div className={styles.icon}>
                            <Icon name={this.iconName}
                                  size={IconSize.X3}
                                  style={IconStyle.Solid}
                            />
                        </div>
                    )
                }

                {
                    this.props.children
                }

            </div>
        );
    }
}