import React, {ReactElement} from "react";
import FormItem from "@/models/server/forms/FormItem";
import {Icon, IconSize} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";

import styles from "./FormSummaryTableRow.module.scss";

export interface IFormSummaryTableRowProps {
    formItem: Readonly<FormItem>;
    onClick: (formItem: Readonly<FormItem>) => Promise<void>;
}

export default function FormSummaryTableRow(props: Readonly<IFormSummaryTableRowProps>): ReactElement {
    const {formItem, onClick} = props;

    const status: "ok" | "todo" | "fail" = (!FormItem.isValid(formItem))
        ? "todo"
        : (FormItem.isOk(formItem))
            ? "ok"
            : "fail";

    const validityClass: string = (status === "ok")
        ? styles.ok
        : (status === "todo")
            ? styles.inProgress
            : styles.fail;

    const checkIcon: string = (status === "ok")
        ? "far check-circle"
        : (status === "todo")
            ? "far question-circle"
            : "far times-circle";

    const className: string = Utility.css(styles.formSummaryTableRow, validityClass);

    return (
        <div className={className}
             onClick={async () => await onClick(formItem)}
        >
            <div className={styles.left}>
                <h6>
                    {formItem.name}
                </h6>
            </div>

            <div className={styles.right}>
                {
                    (formItem.comment) && (
                        <Icon name={"far comment-alt-dots"}
                              size={IconSize.X2}
                        />
                    )
                }

                {
                    (formItem.picture || formItem.pictures) && (
                        <Icon name={"far camera"}
                              size={IconSize.X2}
                        />
                    )
                }

                <Icon name={checkIcon}
                      size={IconSize.X2}
                      className={validityClass}
                />
            </div>
        </div>
    );
}