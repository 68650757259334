import React from "react";
import {Button, ButtonContainer, ButtonType, Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";

interface IDownloadPdfModalProps {
    id: string;
}

interface IDownloadPdfModalState {
    file: FileModel;
}

export default class DownloadPdfModal extends BaseComponent<IDownloadPdfModalProps, IDownloadPdfModalState> {

    state: IDownloadPdfModalState = {
        file: new FileModel()
    };

    private readonly _modalRef: React.RefObject<Modal<FileModel>> = React.createRef();

    private get modal(): Modal<FileModel> {
        return this._modalRef.current!;
    }

    protected async downloadPdfAsync(): Promise<void> {
        await ch.download(this.state.file);
    }

    public hasSpinner(): boolean {
        return true;
    }

    public async openAsync(file: FileModel): Promise<void> {
        await this.setState({ file });
        await this.modal.openAsync(file);
    }

    public static get modalId(): string {
        return "downloadPdfTaskAttachmentModal";
    }

    public render(): React.ReactNode {
        return (
            <Modal id={DownloadPdfModal.modalId}
                   ref={this._modalRef}
                   size={ModalSize.ExtraLarge}
                   title={Localizer.formInputFilePreview}
                   subtitle={this.state.file.name ?? ""}

            >

                <p>{Localizer.imageModalPreviewNotSupported}</p>

                <ButtonContainer>
                    <Button label={Localizer.imageModalDownload}
                            type={ButtonType.Orange}
                            onClick={async () => this.downloadPdfAsync()}
                    />
                </ButtonContainer>

            </Modal>
        );
    }
};