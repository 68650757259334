import {WorkOrderStatus} from "@/models/Enums";

export default class GetNotificationDataRequest {
    public notificationId: string = "";

    public customerIds: string[] = [];

    public constructionSiteIds: string[] = [];

    public status: WorkOrderStatus | null = null;

    /* Approver, decliner, or sender, based on notification type. */
    public userId: string | null = null;

    public from: Date | null = null;

    public to: Date | null = null;
}