import React from "react";
import ReactDOM from "react-dom";
import {PwaHelper} from "@renta-apps/athenaeum-toolkit";
import App from "./App";

/* FA (Font Awesome Pro) */
import "./lib/fa/fa";
/* Bootstrap 4 */
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "bootstrap";
/* JQuery required by bootstrap */
import "jquery";
/* Popper.js required by bootstrap */
import "popper.js";
/* App styles */
import "./base.scss";

/* CL style overrides */
import "@/athenaeum.overrides.scss";

window.addEventListener("beforeinstallprompt", (e) => PwaHelper.onBeforeInstallPrompt(e));

document.addEventListener("DOMContentLoaded", () => {
    const reactRootElement: HTMLElement | null = document.getElementById("root");

    if (!reactRootElement) {
        // Alert the user that things have gone wrong, and offer assistance on how to continue.
        alert("Application initialization failed. Please try clearing your browser cache, and contact IT support if the problem persists.")

        // Throw an error so the event gets logged.
        throw new Error("React root element could not be found");
    }

    // Render the app only after the document has completely finished parsing, AND the root element has been found
    ReactDOM.render(<App />, reactRootElement);
});