import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import PageDefinitions from "../../providers/PageDefinitions";
import MySitesOrWarehousesWidget from "@/pages/Dashboard/MySitesOrWarehousesWidget/MySitesOrWarehousesWidget";
import {IconStyle, PageContainer, RouteWidget, WidgetContainer} from "@renta-apps/athenaeum-react-components";
import Localizer from "../../localization/Localizer";

import rentaTaskStyles from "../RentaTask.module.scss";
import NewsCarousel from "@/components/NewsCarousel/NewsCarousel";
import {NewsItem} from "@/models/server/NewsItem";

interface IDashboardProps {
    name: string | null;
}

interface IDashboardState {
    newsItems: NewsItem[];
}

export default class Dashboard extends AuthorizedPage<IDashboardProps, IDashboardState> {

    state: IDashboardState = {
        newsItems: [],
    };

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const newsItems: NewsItem[] = await this.postAsync("/api/Application/GetNewsItems");
        await this.setState({newsItems});
    }

    public getManual(): string {
        return Localizer.dashboardGetManual;
    }

    public getTitle(): string {
        return Localizer.topNavFrontpage;
    }

    public render(): React.ReactNode {

        return (
            <PageContainer transparent className={rentaTaskStyles.pageContainer} alertClassName={rentaTaskStyles.alert}>

                <WidgetContainer controller="Dashboard" async>

                    <RouteWidget id="Employees" async icon={{ name: "users" }} route={PageDefinitions.employeesRoute} label={Localizer.topNavEmployees} description={Localizer.widgetEmployeesDescription} />

                    <RouteWidget id="ConstructionSites" async icon={{ name: "list", style: IconStyle.Solid }} route={PageDefinitions.constructionSitesRoute} label={Localizer.widgetConstructionSitesLabel} description={Localizer.widgetConstructionSitesDescription} />

                    <RouteWidget id="MyWorkReports" icon={{name: "fas tools" }} route={PageDefinitions.myWorkReportsRoute} label={Localizer.widgetMyWorkReportsLabel} description={Localizer.widgetMyWorkReportsDescription} />

                    <RouteWidget id="MyForms" icon={{name: "fal list-alt", style: IconStyle.Regular}} route={PageDefinitions.myFormsRoute} label={Localizer.widgetMyFormsLabel} description={Localizer.widgetMyFormsDescription}/>

                    <MySitesOrWarehousesWidget />

                </WidgetContainer>

                <NewsCarousel className={rentaTaskStyles.newsCarousel}
                              newsItems={this.state.newsItems}
                />

            </PageContainer>
        );
    }
}