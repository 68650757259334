import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Inline, JustifyContent, Modal, ModalSize,} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import TransformProvider from "@/providers/TransformProvider";
import User from "@/models/server/User";

export interface IConfirmAssignDialogProps {
    onMounterAssignRejected(): Promise<void>;
    onMounterAssignConfirmed(concatMounters: boolean): Promise<void>;
}

interface IConfirmAssignDialogState {
    assigningNewSubContractor: boolean;
    mounter: User;
}

export default class ConfirmAssignDialog extends BaseComponent<IConfirmAssignDialogProps, IConfirmAssignDialogState> {
    
    public state: IConfirmAssignDialogState = {
        assigningNewSubContractor: false,
        mounter: new User(),
    }

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    public async openAsync(assigningNewSubContractor: boolean, mounter: User): Promise<void> {
        this.setState({
            assigningNewSubContractor: assigningNewSubContractor,
            mounter: mounter
        });
        await this._modalRef.current?.openAsync();
    }

    public async closeAsync(): Promise<void> {
        await this._modalRef.current?.closeAsync();
    }

    
    public render(): React.ReactNode {
        return (
                <Modal ref={this._modalRef}
                       title={Localizer.calendarPageCalendarDataConfirmAssignDialogTitle}
                       size={this.state.assigningNewSubContractor ? ModalSize.Large : ModalSize.Default}
                >
                    <p>{Localizer.calendarPageCalendarDataConfirmAssignDialogBody}</p>
                    
                        {
                            (this.state.assigningNewSubContractor) &&
                            <React.Fragment>
                                <p>{TransformProvider.userToString(this.state.mounter)}<br/>{this.state.mounter.role.organizationContract?.name}</p>
                                <p>{Localizer.subcontractorAssignmentModalWarning}</p>
                            </React.Fragment>
                        }

                    <Inline justify={JustifyContent.End}>
                        {
                            (this.state.assigningNewSubContractor) &&
                            <Button label={Localizer.genericActionCancel}
                                    type={ButtonType.Warning}
                                    icon={{name: "far ban"}}
                                    onClick={async () => await this.props.onMounterAssignRejected()}
                            />
                        }
                        
                        <Button label={Localizer.calendarPageCalendarDataConfirmAssignDialogAddButton}
                                type={ButtonType.Orange}
                                icon={{name: "far plus"}}
                                onClick={async () => await this.props.onMounterAssignConfirmed(true)}
                        />

                        <Button label={Localizer.calendarPageCalendarDataConfirmAssignDialogReplaceButton}
                                type={ButtonType.Orange}
                                icon={{name: "far exchange"}}
                                onClick={async () => await this.props.onMounterAssignConfirmed(false)}
                        />
                    </Inline>
                </Modal>
        );
    }
};