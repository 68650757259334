import {SortDirection} from "@renta-apps/athenaeum-toolkit";

export default class GetUserCheckInsRequest {
    public userSalaryDayId: string = "";

    public userId: string = "";

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;
}