import {CommunicationLanguage} from "@/models/Enums";
import {ch} from "@renta-apps/athenaeum-react-common";

export default class LocalizationHelper {

    /**
     * Gets the default language for the current environment.
     */
    public static get defaultLanguage(): CommunicationLanguage {
        if (ch.isFinland) {
            return CommunicationLanguage.Finnish;
        }
        
        if (ch.isSweden) {
            return CommunicationLanguage.Swedish;
        }
        
        return CommunicationLanguage.English;
    }
}