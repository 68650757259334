import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Icon, IconSize} from "@renta-apps/athenaeum-react-components";

import styles from "./ArsenalPagination.module.scss";

export interface IArsenalPaginationProps {
    className?: string;
    pageNumber: number;
    pageCount: number;
    loop?: boolean;
    onChange?(sender: ArsenalPagination, pageNumber: number): Promise<void>;
}

interface IArsenalPaginationState {
    pageNumber: number;
}

export default class ArsenalPagination extends BaseComponent<IArsenalPaginationProps, IArsenalPaginationState> {
    
    state: IArsenalPaginationState = {
        pageNumber: this.props.pageNumber,
    };

    private async changePageAsync(increase: boolean): Promise<void> {

        let newPageNumber = (increase)
            ? (this.pageNumber < this.pageCount)
                ? this.pageNumber + 1
                : (this.props.loop)
                    ? 1
                    : this.pageNumber
            : (this.pageNumber > 1)
                ? this.pageNumber - 1
                : (this.props.loop)
                    ? this.pageCount
                    : this.pageNumber;
        
        if (newPageNumber !== this.pageNumber) {
            
            await this.setState({ pageNumber: newPageNumber });
            
            if (this.props.onChange) {
                await this.props.onChange(this, newPageNumber);
            }
        }
    }
    
    public get pageNumber(): number {
        return this.state.pageNumber;
    }

    public get pageCount(): number {
        return this.props.pageCount;
    }
    
    render(): React.ReactNode {
        return (
            <div className={this.css(this.props.className, styles.arsenalPagination)}>
                
                <Icon name="fas angle-left" size={IconSize.X3} onClick={async () => await this.changePageAsync(false)} />
                <div>
                    <span>{this.pageNumber} / {this.pageCount}</span>
                </div>
                <Icon name="fas angle-right" size={IconSize.X3} onClick={async () => await this.changePageAsync(true)} />
                
            </div>
        );
    }
};