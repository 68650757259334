import {executeWithIntercept, pageData, testConstants} from "@/helpers/CypressHelper";

context("(Desktop) Extra charge type management", () => {
    beforeEach(() => {
        cy.init("Desktop");
    });

    const {name: seedExtraChargeTypeName, unit: seedExtraChargeTypeUnit, externalId: seedExtraChargeTypeExternalId, price: seedExtraChargeTypePrice} = testConstants.extraChargeType;
    const seedWorkOrderName: string = testConstants.workOrder1.name;
    const timestamp: string = new Date().toISOString();

    const newExtraChargeTypeName = "Cypress extra charge type" + timestamp;
    const newExtraChargeTypeUnit = "Cubic meter";
    const newExtraChargeTypeExternalId = 'eid-' + timestamp;
    const newExtraChargeTypePrice = 123.4;

    const editedExtraChargeTypeName = "Cypress edited" + timestamp;
    const editedExtraChargeTypeUnit = "Kilometer";
    const editedExtraChargeTypeExternalId = 'eid-edited-' + timestamp;
    const editedExtraChargeTypePrice = 234.5;

    const ensureVisibleExtraChargeTypesCount = (expected: number) => {
        pageData().extraChargeTypeManagement.extraChargeTypesGrid().within(() => {
            cy.get("tbody tr.athenaeum-grid-gridRow").filter(":visible").should("have.length", expected);
        });
    };

    const ensureVisibleExtraChargeType = (
        name: string,
        unit: string,
        externalId: string,
        price: number,
        status: "used" | "notUsed"
    ) => {
        pageData().extraChargeTypeManagement.extraChargeTypesGrid().within(() => {
            pageData().extraChargeTypeManagement.extraChargeTypesGridCell(name, "name")
                .should('contain.text', name)
                .should('be.visible');

            pageData().extraChargeTypeManagement.extraChargeTypesGridCell(name, "unit")
                .should('contain.text', unit)
                .should('be.visible');

            pageData().extraChargeTypeManagement.extraChargeTypesGridCell(name, "externalId")
                .should('contain.text', externalId)
                .should('be.visible');

            pageData().extraChargeTypeManagement.extraChargeTypesGridCell(name, "price")
                .should('contain.text', price)
                .should('be.visible');

            pageData().extraChargeTypeManagement.extraChargeTypesGridCell(name, "status").within(() => {
                if (status === "used") {
                    cy.get('i.assigned-icon')
                        .should('exist')
                        .should('be.visible');
                } else {
                    cy.get('i').should('not.exist');
                }
            });
        });
    };

    const search = (search: string) => {
        pageData().extraChargeTypeManagement.extraChargeTypesGridToolbarItems.searchInput().click();
        cy.focused().clear();
        cy.focused().type(search);
        executeWithIntercept(
            () => pageData().extraChargeTypeManagement.extraChargeTypesGridToolbarItems.searchButton().click(),
            [pageData().extraChargeTypeManagement.routes.getAdminExtraChargeTypes]
        );
    };

    const clearSearchAndFilters = () => {
        executeWithIntercept(
            () => pageData().extraChargeTypeManagement.extraChargeTypesGridToolbarItems.reloadExtraChargeTypesButton().click(),
            [pageData().extraChargeTypeManagement.routes.getAdminExtraChargeTypes]
        );
    };

    const fillExtraChargeTypeDetails = (name: string, unit: string, externalId: string, price: number) => {
        // wait for name input element to become focused
        cy.wait(250);

        // name
        cy.focused().clear();
        cy.focused().type(name + "{enter}");

        // unit
        pageData().extraChargeTypeManagement.extraChargeTypesGridCell(name, "unit").selectDropdownValue(unit);

        // external id
        pageData().extraChargeTypeManagement.extraChargeTypesGridCell(name, "externalId").trigger('click');
        cy.focused().clear();
        cy.focused().type(externalId + "{enter}");

        // price
        pageData().extraChargeTypeManagement.extraChargeTypesGridCell(name, "price").trigger('click');
        cy.focused().clear();
        cy.focused().type(price.toString() + "{enter}");
    };

    const navigateToWorkOrderPageExtraChargesTab = () => {
        cy.navigateToWorkOrderPage(seedWorkOrderName);
        pageData().workOrderDetailsPanel.extraChargesTab.tab().click();
    };

    const showDeletedExtraChargeTypes = () => {
        executeWithIntercept(
            () => pageData().extraChargeTypeManagement.extraChargeTypesGridToolbarItems.showDeletedExtraChargeTypesCheckbox().click(),
            [pageData().extraChargeTypeManagement.routes.getAdminExtraChargeTypes]
        );
    };

    const test = () => {
        cy.navigateToAdminExtraChargeTypeManagementPage();

        // Ensure seed extra charge types are visible
        cy.log("Ensure seed extra charge types are visible");
        ensureVisibleExtraChargeTypesCount(1);
        ensureVisibleExtraChargeType(
            seedExtraChargeTypeName,
            seedExtraChargeTypeUnit,
            seedExtraChargeTypeExternalId,
            seedExtraChargeTypePrice,
            "notUsed"
        );

        // Create extra charge type (cancel before saving)
        cy.log("Create extra charge type (cancel before saving)");
        pageData().extraChargeTypeManagement.extraChargeTypesGridToolbarItems.addExtraChargeTypeButton().click();
        fillExtraChargeTypeDetails(newExtraChargeTypeName, newExtraChargeTypeUnit, newExtraChargeTypeExternalId, newExtraChargeTypePrice);
        pageData().extraChargeTypeManagement.extraChargeTypesGridCell(newExtraChargeTypeName, "actions").within(() => {
            cy.get('[title=Delete]').click();
        });
        cy.reload();
        ensureVisibleExtraChargeTypesCount(1);
        ensureVisibleExtraChargeType(
            seedExtraChargeTypeName,
            seedExtraChargeTypeUnit,
            seedExtraChargeTypeExternalId,
            seedExtraChargeTypePrice,
            "notUsed"
        );

        // Create extra charge type (save)
        cy.log("Create extra charge type (save)");
        pageData().extraChargeTypeManagement.extraChargeTypesGridToolbarItems.addExtraChargeTypeButton().click();
        fillExtraChargeTypeDetails(newExtraChargeTypeName, newExtraChargeTypeUnit, newExtraChargeTypeExternalId, newExtraChargeTypePrice);
        pageData().extraChargeTypeManagement.extraChargeTypesGridCell(newExtraChargeTypeName, "actions").within(() => {
            executeWithIntercept(
                () => cy.get('[title=Save]').click(),
                [pageData().extraChargeTypeManagement.routes.createExtraChargeType]
            );
        });
        cy.reload();
        ensureVisibleExtraChargeTypesCount(2);
        search(seedExtraChargeTypeName);
        ensureVisibleExtraChargeTypesCount(1);
        ensureVisibleExtraChargeType(
            seedExtraChargeTypeName,
            seedExtraChargeTypeUnit,
            seedExtraChargeTypeExternalId,
            seedExtraChargeTypePrice,
            "notUsed"
        );
        search(newExtraChargeTypeName);
        ensureVisibleExtraChargeTypesCount(1);
        ensureVisibleExtraChargeType(
            newExtraChargeTypeName,
            newExtraChargeTypeUnit,
            newExtraChargeTypeExternalId,
            newExtraChargeTypePrice,
            "notUsed"
        );
        clearSearchAndFilters();

        // Edit extra charge type (cancel before saving)
        cy.log("Edit extra charge type (cancel before saving)");
        pageData().extraChargeTypeManagement.extraChargeTypesGridCell(newExtraChargeTypeName, "name").trigger('click');
        fillExtraChargeTypeDetails(editedExtraChargeTypeName, editedExtraChargeTypeUnit, editedExtraChargeTypeExternalId, editedExtraChargeTypePrice);
        pageData().extraChargeTypeManagement.extraChargeTypesGridCell(editedExtraChargeTypeName, "actions").within(() => {
            cy.get('[title=Cancel]').click();
        });
        cy.reload();
        ensureVisibleExtraChargeTypesCount(2);
        search(seedExtraChargeTypeName);
        ensureVisibleExtraChargeTypesCount(1);
        ensureVisibleExtraChargeType(
            seedExtraChargeTypeName,
            seedExtraChargeTypeUnit,
            seedExtraChargeTypeExternalId,
            seedExtraChargeTypePrice,
            "notUsed"
        );
        search(newExtraChargeTypeName);
        ensureVisibleExtraChargeTypesCount(1);
        ensureVisibleExtraChargeType(
            newExtraChargeTypeName,
            newExtraChargeTypeUnit,
            newExtraChargeTypeExternalId,
            newExtraChargeTypePrice,
            "notUsed"
        );
        clearSearchAndFilters();

        // Edit extra charge type (save)
        cy.log("Edit extra charge type (save)");
        pageData().extraChargeTypeManagement.extraChargeTypesGridCell(newExtraChargeTypeName, "name").trigger('click');
        fillExtraChargeTypeDetails(editedExtraChargeTypeName, editedExtraChargeTypeUnit, editedExtraChargeTypeExternalId, editedExtraChargeTypePrice);
        pageData().extraChargeTypeManagement.extraChargeTypesGridCell(editedExtraChargeTypeName, "actions").within(() => {
            executeWithIntercept(
                () => cy.get('[title=Save]').click(),
                [pageData().extraChargeTypeManagement.routes.saveExtraChargeType]
            );
        });
        cy.reload();
        ensureVisibleExtraChargeTypesCount(2);
        search(seedExtraChargeTypeName);
        ensureVisibleExtraChargeTypesCount(1);
        ensureVisibleExtraChargeType(
            seedExtraChargeTypeName,
            seedExtraChargeTypeUnit,
            seedExtraChargeTypeExternalId,
            seedExtraChargeTypePrice,
            "notUsed"
        );
        search(editedExtraChargeTypeName);
        ensureVisibleExtraChargeTypesCount(1);
        ensureVisibleExtraChargeType(
            editedExtraChargeTypeName,
            editedExtraChargeTypeUnit,
            editedExtraChargeTypeExternalId,
            editedExtraChargeTypePrice,
            "notUsed"
        );
        clearSearchAndFilters();
        navigateToWorkOrderPageExtraChargesTab();
        pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGridToolbar.addExtraChargeButton().click();
        cy.wait(250);
        pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGrid().within(() => {
            pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGridCell("Nothing is selected", "type").dropdownShouldContainValue(editedExtraChargeTypeName);
        });
        cy.navigateToAdminExtraChargeTypeManagementPage();

        // Delete unused extra charge type
        cy.log("Delete unused extra charge type");
        pageData().extraChargeTypeManagement.extraChargeTypesGridCell(editedExtraChargeTypeName, "actions").within(() => {
            cy.get('[title=Delete]').trigger('click');
        });
        executeWithIntercept(
            () => pageData().common.confirmationDialog.confirmButton().trigger('click'),
            [pageData().extraChargeTypeManagement.routes.deleteExtraChargeType]
        );
        cy.reload();
        ensureVisibleExtraChargeTypesCount(1);
        ensureVisibleExtraChargeType(
            seedExtraChargeTypeName,
            seedExtraChargeTypeUnit,
            seedExtraChargeTypeExternalId,
            seedExtraChargeTypePrice,
            "notUsed"
        );
        showDeletedExtraChargeTypes();
        ensureVisibleExtraChargeTypesCount(1);
        ensureVisibleExtraChargeType(
            seedExtraChargeTypeName,
            seedExtraChargeTypeUnit,
            seedExtraChargeTypeExternalId,
            seedExtraChargeTypePrice,
            "notUsed"
        );
        clearSearchAndFilters();
        navigateToWorkOrderPageExtraChargesTab();
        pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGridToolbar.addExtraChargeButton().click();
        pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGrid().within(() => {
            pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGridCell("Nothing is selected", "type").dropdownShouldNotContainValue(editedExtraChargeTypeName);
            pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGridCell("Nothing is selected", "type").selectDropdownValue(seedExtraChargeTypeName);
            pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGridCell(seedExtraChargeTypeName, "actions").within(() => {
                executeWithIntercept(
                    () => cy.get('[title="Save changes"]').click(),
                    [pageData().workOrderDetailsPanel.routes.saveWorkOrderExtraCharge]
                );
            });
        });
        cy.navigateToAdminExtraChargeTypeManagementPage();

        // Delete used extra charge type
        cy.log("Delete used extra charge type");
        pageData().extraChargeTypeManagement.extraChargeTypesGridCell(seedExtraChargeTypeName, "actions").within(() => {
            cy.get('[title=Delete]').trigger('click');
        });
        executeWithIntercept(
            () => pageData().common.confirmationDialog.confirmButton().trigger('click'),
            [pageData().extraChargeTypeManagement.routes.deleteExtraChargeType]
        );
        cy.reload();
        showDeletedExtraChargeTypes();
        ensureVisibleExtraChargeTypesCount(1);
        ensureVisibleExtraChargeType(
            seedExtraChargeTypeName,
            seedExtraChargeTypeUnit,
            seedExtraChargeTypeExternalId,
            seedExtraChargeTypePrice,
            "used"
        );
        navigateToWorkOrderPageExtraChargesTab();
        pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGridToolbar.addExtraChargeButton().click();
        pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGrid().within(() => {
            pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGridCell("Nothing is selected", "type").dropdownShouldNotContainValue(seedWorkOrderName);
        });
        cy.navigateToAdminExtraChargeTypeManagementPage();

        // Restore deleted used extra charge type
        cy.log("Restore deleted used extra charge type");
        showDeletedExtraChargeTypes();
        pageData().extraChargeTypeManagement.extraChargeTypesGridCell(seedExtraChargeTypeName, "actions").within(() => {
            executeWithIntercept(
                () => cy.get('[title=Restore]').click(),
                [pageData().extraChargeTypeManagement.routes.restoreExtraChargeType]
            );
        });
        cy.reload();
        ensureVisibleExtraChargeTypesCount(1);
        ensureVisibleExtraChargeType(
            seedExtraChargeTypeName,
            seedExtraChargeTypeUnit,
            seedExtraChargeTypeExternalId,
            seedExtraChargeTypePrice,
            "used"
        );
        navigateToWorkOrderPageExtraChargesTab();
        pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGridToolbar.addExtraChargeButton().click();
        pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGrid().within(() => {
            pageData().workOrderDetailsPanel.extraChargesTab.extraChargesGridCell("Nothing is selected", "type").dropdownShouldContainValue(seedExtraChargeTypeName);
        });
    };

    it("Should work properly for Admin", () => {
        cy.loginAsAdmin();
        test();
    });
});