import {NotificationType} from "@/models/Enums";
import {pageData} from "@/helpers/CypressHelper";

context("Mobile. User notification settings", () => {

    beforeEach(() => {
        cy.init("Mobile");
    });

    it("Should work correctly for Mounter", () => {
        cy.loginAsMounter();
        test();
    });

    function test(): void {
        describe('Update mounter "Work orders processed approval" notification settings', () => {
            cy.navigateToAccountPage();
            pageData().account.tabs.tabContainer.notificationsSettingsTab().click();

            cy.modifyNotificationSettings(NotificationType.WorkOrderReactivation,false)
        });

        describe('Update mounter "Work orders assignment" notification settings', () => {
            cy.navigateToAccountPage();
            pageData().account.tabs.tabContainer.notificationsSettingsTab().click();

            cy.modifyNotificationSettings(NotificationType.WorkOrderAssignment, false);
        });
    }
});