import React from "react";
import AnonymousPage from "../../models/base/AnonymousPage";
import {ActionType} from "@/models/Enums";
import FormModel from "@/models/server/forms/FormModel";
import {ch} from "@renta-apps/athenaeum-react-common";
import {FileModel, Utility} from "@renta-apps/athenaeum-toolkit";
import User from "@/models/server/User";
import {CellAction, CellModel, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, GridOddType, PageContainer, PageHeader, PageRow, RowModel} from "@renta-apps/athenaeum-react-components";
import Localizer from "../../localization/Localizer";

export default class MyForms extends AnonymousPage {

    private readonly _formsGridRef: React.RefObject<Grid<FormModel>> = React.createRef();

    private readonly _formsColumns: ColumnDefinition[] = [
        {
            header: Localizer.formsDataNameLanguageItemName,
            accessor: "name",
            minWidth: 140,
        },
        {
            header: Localizer.addTaskTaskNameLanguageItemName,
            accessor: "workOrder.name",
            minWidth: 140,
        },
        {
            header: Localizer.constructionSitesNameLanguageItemName,
            group: Localizer.genericConstructionSiteOrWarehouseLanguageItemName,
            accessor: "constructionSite.name",
            minWidth: 140,
        },
        {
            header: Localizer.formInputAddressLanguageItemName,
            group: Localizer.genericConstructionSiteOrWarehouseLanguageItemName,
            accessor: "constructionSite.location.formattedAddress",
            type: ColumnType.Address,
            minWidth: 140,
        },
        {
            header: Localizer.tasksPanelTypeLanguageItemName,
            group: Localizer.genericApprovalLanguageItemName,
            accessor: "approvalType",
            format: "CustomerApprovalType",
            minWidth: 80,
        },
        {
            accessor: "sentAt",
            header: Localizer.tasksPanelSentAtLanguageItemName,
            group: Localizer.genericApprovalLanguageItemName,
            format: "D",
            minWidth: 80,
        },
        {
            accessor: "approvedAt",
            header: Localizer.formsDataApprovedAtLanguageItemName,
            group: Localizer.genericApprovalLanguageItemName,
            format: "D",
            minWidth: 80,
        },
        {
            header: Localizer.tasksPanelActionsLanguageItemName,
            minWidth: 140,
            init: (cell) => this.initFormOperations(cell),
            actions: [
                {
                    name: "approve",
                    title: Localizer.myFormsApproveFormReportLanguageItemName,
                    icon: "far thumbs-up",
                    type: ActionType.Create,
                    confirm: (cell: CellModel<FormModel>) => Localizer.get(Localizer.myFormsApproveConfirmation, cell.model.name),
                    callback: async (cell, action) => await this.processFormOperationAsync(cell, action)
                },
                {
                    name: "preview",
                    title: Localizer.myFormsPreviewFormReportLanguageItemName,
                    icon: "far search",
                    type: ActionType.Default,
                    right: true,
                    callback: async (cell, action) => await this.processFormOperationAsync(cell, action)
                },
                {
                    name: "download",
                    title: Localizer.myFormsDownloadFormReportLanguageItemName,
                    icon: "far download",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processFormOperationAsync(cell, action)
                }
            ]
        },
    ];

    private async getFormsAsync(): Promise<FormModel[]> {
        return await this.formsGrid.getAsync<FormModel[]>("api/myForms/getContactPersonForms");
    }

    private initFormsRow(row: RowModel<FormModel>): void {

        const model: FormModel = row.model;

        const approved: boolean = model.approved;

        row.className = (approved)
            ? "bg-approved"
            : "";
    }

    private initFormOperations(cell: CellModel<FormModel>): void {
        const model: FormModel = cell.row.model;
        const approved: boolean = model.approved;

        const approveAction: CellAction<FormModel> = cell.actions[0];
        const previewAction: CellAction<FormModel> = cell.actions[1];
        const downloadAction: CellAction<FormModel> = cell.actions[2];

        approveAction.visible = (!approved) && (model.passed);
        previewAction.visible = true;
        downloadAction.visible = true;
    }

    private async processFormOperationAsync(cell: CellModel<FormModel>, action: CellAction<FormModel>): Promise<void> {

        const model: FormModel = cell.model;

        if (action.action.name === "preview") {

            await this.previewFormPdfAsync(cell);

        } else if (action.action.name === "download") {

            const file: FileModel = await this.postAsync("api/myForms/getFormPdf", model.id);

            ch.download(file as any);
        } else if (action.action.name === "approve") {

            model.approved = true;
            model.approvedAt = Utility.now();
            model.approvedBy = ch.getUser<User>();
            model.approvedAt = Utility.now();

            await cell.grid.postAsync("api/myForms/approveForm", model.id);

            await cell.row.bindAsync();
        }
    }

    private async previewFormPdfAsync(cell: CellModel<FormModel>): Promise<void> {
        const formId: string = cell.model.id;
        await ch.documentPreviewAsync("api/myForms/getFormPdf", formId, Localizer.documentPreviewModalFormTitle);
    }

    private get formsGrid(): GridModel<FormModel> {
        return this._formsGridRef.current!.model;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>

                <PageHeader title={Localizer.myFormsTitle}/>

                <PageRow>

                    <div className="col">

                        <Grid ref={this._formsGridRef}
                              hovering={GridHoveringType.Row}
                              odd={GridOddType.None}
                              noDataText={Localizer.myFormsNoFormReports}
                              columns={this._formsColumns}
                              initRow={(row) => this.initFormsRow(row)}
                              fetchData={async () => await this.getFormsAsync()}
                        />

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}