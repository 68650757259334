import CostPool from "@/models/server/CostPool";
import {PostAsync} from "@/types/PostAsync";
import {PageCacheProvider} from "@renta-apps/athenaeum-react-common";

/**
 * {@link CostPool} provider.
 */
class CostPoolProvider {

    public async getCostPoolsAsync(sender: PostAsync<void, CostPool[]>): Promise<CostPool[]> {
        const endpoint: string = "api/admin/getCostPools";
        return await PageCacheProvider.getAsync(
            endpoint,
            () => sender.postAsync(endpoint)
        );
    }
}

/**
 * {@link CostPoolProvider} singleton.
 */
export default new CostPoolProvider();