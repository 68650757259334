import React from "react";
import FormItemModel from "@/models/server/forms/FormItem";
import FormContent from "@/models/server/forms/FormContent";
import RentaTasksFormWizardPage from "@/pages/RentaTasks/RentaTasksFormWizardPage";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import Localizer from "@/localization/Localizer";
import FormItemView from "@/components/Form/FormItem/FormItemView";

import styles from "@/pages/RentaTasks/RentaTasksFormWizardPage.module.scss";

export interface IFormPageProps extends BasePageParameters {
    formItemIds?: string[];
}

interface IFormPageState {
}

export default class FormPage extends RentaTasksFormWizardPage<IFormPageProps, IFormPageState> {
    state: IFormPageState = {};

    protected async saveContextAsync(): Promise<void> {
        this.saveContext();
    }

    protected get preview(): boolean {
        return this.context.formPreview;
    }

    protected get formItem(): FormItemModel | FormItemModel[] {
        if (this.form) {
            const itemIndex: number = this.currentFormIndex;
            if (itemIndex >= 0) {
                const items: FormItemModel[] | FormItemModel[][] = FormContent.getItems(this.form, true);
                if (itemIndex < items.length) {
                    const item: FormItemModel | FormItemModel[] = items[itemIndex];
                    if ((Array.isArray(item)) && (item.length == 1)) {
                        return item[0];
                    }

                    return item;
                }
            }
        }

        throw Error(Localizer.rentaTasksFormWizardPageFormItemNotFound);
    }

    public getManual(): string {
        const formItem: FormItemModel | FormItemModel[] = this.formItem;
        return (Array.isArray(formItem))
            ? formItem.where(item => item != null).map(item => item.manual!).join("\n")
            : formItem.manual || "";
    }

    public renderContent(): React.ReactNode {
        const formItems: FormItemModel[] = Array.isArray(this.formItem)
            ? this.formItem
            : [this.formItem];

        return (
            <div className={styles.formText}>
                {
                    formItems.map((formItem, index) => (
                        <React.Fragment key={formItem.id}>
                            <FormItemView formItem={formItem}
                                          readonly={this.preview}
                                          onChange={async () => await this.saveContextAsync()}
                            />

                            {
                                (index < formItems.length - 1) && <hr/>
                            }
                        </React.Fragment>
                    ))
                }
            </div>
        );
    }
}