import WorkReportAttachment from "@/models/server/WorkReportAttachment";
import {AttachmentType} from "@/models/Enums";
import {SortDirection} from "@renta-apps/athenaeum-toolkit";

export default class ListWorkOrderAttachmentsRequest {
    public workOrderId: string = "";
    
    public public: boolean = false;

    public types: AttachmentType[] = [];
    
    public pageNumber: number = 1;

    public pageSize: number = 100;
    
    public date: Date | null = null;

    public from: Date | null = null;

    public to: Date | null = null;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public isEmpty(): boolean {
        return (!this.date)
            && (!this.from)
            && (!this.to)
            && (this.types.length == 0);
    }

    public clear(): void {
        this.date = null;
        this.from = null;
        this.to = null;
        this.types = [];
    }

    public getDate(attachment: WorkReportAttachment): Date {
        return attachment.createdAt;
    }

    public isListWorkOrderAttachmentsRequest: boolean = true;
}