import OrganizationContract from "@/models/server/OrganizationContract";
import User from "@/models/server/User";
import ConstructionSite from "@/models/server/ConstructionSite";

export default class NotificationWorkOrdersStatusesFiltersData
{
    public customers: OrganizationContract[] = [];

    public constructionSites: ConstructionSite[] = [];
    
    public users: User[] = [];

    public isNotificationWorkOrdersStatusesFiltersData: boolean = true;
}