import React from "react";
import {BaseComponent, Justify, PageRoute, PageRouteProvider, TextAlign} from "@renta-apps/athenaeum-react-common";
import {CellModel, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, GridOddType, RowModel} from "@renta-apps/athenaeum-react-components";
import WorkOrderStatusModel from "@/models/server/WorkOrderStatusModel";
import {ActionType, NotificationType, WorkOrderStatus} from "@/models/Enums";
import GetNotificationDataRequest from "@/models/server/requests/GetNotificationDataRequest";
import ToolbarModel from "@/pages/Notifications/Toolbar/ToolbarModel";
import {SortDirection} from "@renta-apps/athenaeum-toolkit";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import PageDefinitions from "@/providers/PageDefinitions";
import TransformProvider from "@/providers/TransformProvider";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import Localizer from "../../../localization/Localizer";

export interface INotificationsPanelProps {
    notificationId: string;
    notificationType: NotificationType;
    filters: ToolbarModel;
}

interface INotificationsPanelState {
}

export default class NotificationsPanel extends BaseComponent<INotificationsPanelProps, INotificationsPanelState> {
    state: INotificationsPanelState = {};

    private readonly _workOrdersStatusesGridRef: React.RefObject<Grid<WorkOrderStatusModel>> = React.createRef();

    private readonly _workOrdersStatusesColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            textAlign: TextAlign.Center,
            minWidth: 40,
            stretch: false
        },
        {
            group: Localizer.genericWorkOrderLanguageItemName,
            header: Localizer.tasksPanelCodeLanguageItemName,
            accessor: "workOrder.code",
            minWidth: 50,
            textAlign: TextAlign.Left,
            noWrap: true,
            sorting: true,
            className: "grey",
        },
        {
            group: Localizer.genericWorkOrderLanguageItemName,
            header: Localizer.tasksNameLanguageItemName,
            accessor: "workOrder.name",
            minWidth: 270,
            sorting: true,
            route: NotificationsPanel.getWorkOrderRoute,
            actions: [
                {
                    name: nameof<WorkOrderModel>(o => o.name),
                    title: Localizer.genericOpen,
                    icon: "fal external-link-square",
                    type: ActionType.Default,
                    callback: async (cell) => await this.triggerRouteAsync(cell.route)
                },
            ]
        },
        {
            group: Localizer.genericWorkOrderLanguageItemName,
            header: Localizer.workOrderCurrentStatusLanguageItemName,
            name: "status",
            minWidth: 40,
            type: ColumnType.Icon,
            textAlign: TextAlign.Center,
            accessor: (model: WorkOrderStatusModel) => WorkOrderModel.getStateIcon(model.workOrder),
            sorting: true,
        },
        {
            header: Localizer.checkInsPanelSiteOrWarehouseLanguageItemName,
            accessor: "workOrder.owner.contractName",
            textAlign: TextAlign.Left,
            minWidth: 250,
            maxWidth: 250,
            sorting: true,
            settings: {
                infoAccessor: "workOrder.owner",
                infoTransform: (_, value) => (value) ? TransformProvider.constructionSiteOrWarehouseToString(value, true) : "",
            },
        },
        {
            header: (this.isWorkOrdersProcessedApproval) ? Localizer.genericApprovedOrDeclinedAtLanguageItemName : Localizer.genericSentAtLanguageItemName,
            accessor: nameof.full<WorkOrderStatusModel>(workOrderStatus => workOrderStatus.createdAt),
            format: "D",
            minWidth: 80,
            sorting: true,
        },
        {
            header: (this.isWorkOrdersProcessedApproval) ? Localizer.genericApprovedOrDeclinedByLanguageItemName : Localizer.genericSentByLanguageItemName,
            accessor: nameof.full<WorkOrderStatusModel>(workOrderStatus => workOrderStatus.createdBy),
            minWidth: 150,
            noWrap: true,
            sorting: true,
            init: (cell: CellModel<WorkOrderStatusModel>) => this.initCreatedByColumn(cell),
            settings: {
                descriptionAccessor: nameof<WorkOrderStatusModel>(o => o.comment),
                descriptionTitle: Localizer.genericCommentLanguageItemName,
                descriptionJustify: Justify.Right,
                descriptionMaxLength: 1024,
            },
        },
    ];

    private get isWorkOrdersProcessedApproval(): boolean {
        return (this.props.notificationType == NotificationType.WorkOrdersProcessedApproval);
    }

    private get sortColumn(): string {
        return UserInteractionDataStorage.getFilters("createdAt", "WorkOrderStatusModel.SortColumn");
    }

    private get sortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "WorkOrderStatusModel.SortDirection");
    }

    public get workOrdersStatusesGrid(): GridModel<WorkOrderStatusModel> {
        return this._workOrdersStatusesGridRef.current!.model;
    }

    private static getWorkOrderRoute(cell: CellModel<WorkOrderStatusModel>): PageRoute {
        return PageDefinitions.workOrderRoute(cell.model.workOrderId);
    }

    private initWorkOrderStatusRow(row: RowModel<WorkOrderStatusModel>): void {

        const model: WorkOrderStatusModel = row.model;

        const approved: boolean = model.status == WorkOrderStatus.ApprovedByCustomer;
        const declined: boolean = model.status == WorkOrderStatus.DeclinedByCustomer;

        row.className = (approved)
            ? "bg-approved"
            : (declined)
                ? "bg-declined"
                : "";
    }

    private initCreatedByColumn(cell: CellModel<WorkOrderStatusModel>): void {
        const model: WorkOrderStatusModel = cell.model;

        cell.descriptionReadonly = true;

        if (cell.descriptionAction && !model.comment) {
            cell.descriptionAction.visible = false;
        }
    }

    private async triggerRouteAsync(route: PageRoute | null): Promise<void> {
        if (route) {
            await PageRouteProvider.redirectAsync(route);
        }
    }

    public async getWorkOrdersStatusesAsync(sortColumnName: string | null, sortDirection: SortDirection | null): Promise<WorkOrderStatusModel[]> {
        UserInteractionDataStorage.setFilters(sortColumnName, "WorkOrderStatusModel.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "WorkOrderStatusModel.SortDirection");

        const request = new GetNotificationDataRequest();
        request.notificationId = this.props.notificationId;
        request.customerIds = this.props.filters.customerIds;
        request.constructionSiteIds = this.props.filters.constructionSiteIds;
        request.status = this.props.filters.status;
        request.userId = this.props.filters.user?.id ?? null;
        request.from = this.props.filters.from;
        request.to = this.props.filters.to;

        return await this.postAsync("api/notification/getNotificationData", request);
    }

    public render(): React.ReactNode {
        return (
            <div className={"col"}>
                <Grid responsive
                      ref={this._workOrdersStatusesGridRef}
                      minWidth={"auto"}
                      hovering={GridHoveringType.Row}
                      odd={GridOddType.None}
                      noDataText={Localizer.genericNoData}
                      defaultSortColumn={this.sortColumn}
                      defaultSortDirection={this.sortDirection}
                      columns={this._workOrdersStatusesColumns}
                      initRow={(row) => this.initWorkOrderStatusRow(row)}
                      fetchData={async (_, __, ___, sortColumnName, sortDirection) => await this.getWorkOrdersStatusesAsync(sortColumnName, sortDirection)}
                />
            </div>
        );
    }
}