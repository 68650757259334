import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import {WorkOrderStatus} from "@/models/Enums";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";

import styles from "./StatusMessageModal.module.scss";

interface IStatusMessageModalModalProps {
}

interface IStatusMessageModalState {
    workOrder: WorkOrderModel;
}

export default class StatusMessageModal extends BaseComponent<IStatusMessageModalModalProps, IStatusMessageModalState> {

    state: IStatusMessageModalState = {
        workOrder: new WorkOrderModel(),
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    private get workOrder(): WorkOrderModel {
        return this.state.workOrder;
    }

    private get title(): string {
            if (WorkOrderModel.isReactivated(this.workOrder)) {
                return Localizer.statusMessageModalTitleWorkOrderReactivated;
            }

            if (this.workOrder.currentStatus == WorkOrderStatus.DeclinedByCustomer) {
                return Localizer.statusMessageModalTitleWorkReportDeclined;
            }

        return "...";
    }

    private get hasComment(): boolean {
        return (this.workOrder.currentStatusComment != null && this.workOrder.currentStatusComment.length > 0);
    }

    public async openAsync(workOrder: WorkOrderModel): Promise<void> {
        await this.setState({workOrder});
        await this.modal.openAsync();
    }

    private async onOkAsync(): Promise<void> {
        if (this._modalRef.current) {
            await this.modal.closeAsync();
        }
    }

    public static get statusMessageModalModalId(): string {
        return "statusMessageModal";
    }

    public render(): React.ReactNode {
        return (
            <Modal noHeader
                   id={StatusMessageModal.statusMessageModalModalId}
                   ref={this._modalRef}
                   className={styles.statusMessageModal}
                   size={ModalSize.Small}>

                <div className={styles.customHeader}>
                    <h5>{this.title}</h5>
                </div>

                {
                    (this.hasComment) &&
                    (
                        <span>
                            {Utility.format(Localizer.statusMessageModalCommentedReason, `\n${this.workOrder.currentStatusComment}`)}
                        </span>
                    )
                }

                <div className={styles.expander}/>

                <div>
                    <Button block
                            className={styles.okButton}
                            label={Localizer.genericOk}
                            type={ButtonType.Orange}
                            onClick={() => this.onOkAsync()}
                    />
                </div>

            </Modal>
        );
    }
}