import RegionWorkNumber from "@/models/server/RegionWorkNumber";

export default class Region {
    public id: string = "";

    public name: string = "";
    
    public workNumbers: RegionWorkNumber[] = [];
    
    public isRegion: boolean = false;
}