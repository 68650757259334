export default class AddSubcontractorRequest {

    public warehouseId: string | null = null;

    public constructionSiteId: string | null = null;

    public organizationContractId: string | null = null;

    public name: string | null = null;

    public vatId: string | null = null;

    public formattedAddress: string = "";

    public managerId: string | null = null;
}