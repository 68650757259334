import {pageData, testConstants} from "@/helpers/CypressHelper";

context("(Desktop) Cost pool", () => {
    beforeEach(() => {
        cy.init("Desktop");
    });

    const {name: seedCostPoolName, description: seedCostPoolDescription} = testConstants.costPool;

    const timestamp: string = new Date().toISOString();

    const newCostPoolName = "New cost pool" + timestamp;
    const newCostPoolDescription = "New cost pool description";

    const editedCostPoolName = "🫸🏼oｏOＯ⭕️" + timestamp;
    const editedCostPoolDescription = "あ・え・い・う・え・お・あお!!";

    const ensureVisibleCostPoolsCount = (expected: number) => {
        pageData().warehouses.costPoolsTab.costPoolsGrid().within(() => {
            cy.get("tbody tr.athenaeum-grid-gridRow").filter(":visible").should("have.length", expected);
        });
    };

    const ensureVisibleCostPool = (name: string, description: string) => {
        pageData().warehouses.costPoolsTab.costPoolsGridCell(name, "name")
            .should("contain.text", name)
            .should('be.visible');
        pageData().warehouses.costPoolsTab.costPoolsGridCell(name, "description")
            .should("contain.text", description)
            .should('be.visible');
    };

    const fillSelectedCostPoolData = (costPoolName: string, costPoolDescription: string) => {
        // name
        cy.focused().clear();
        cy.focused().type(costPoolName + "{enter}");

        // description
        pageData().warehouses.costPoolsTab.costPoolsGridCell(costPoolName, "description").trigger('click');
        cy.focused().clear();
        cy.focused().type(costPoolDescription + "{enter}");

        // save
        pageData().warehouses.costPoolsTab.costPoolsGridCell(costPoolDescription, "actions").within(() => {
            cy.get("[title=Save]").click();
        });
    };

    const deleteCostPool = (name: string) => {
        pageData().warehouses.costPoolsTab.costPoolsGridCell(name, "actions").within(() => {
            cy.get("[title=Delete]").trigger("click");
        });
        pageData().common.confirmationDialog.confirmButton().trigger('click');
    };

    const test = () => {
        cy.navigateToAdminWarehousesPage();
        pageData().warehouses.costPoolsTab.tab().click();

        // Existing cost pool should be visible
        ensureVisibleCostPoolsCount(1);
        ensureVisibleCostPool(seedCostPoolName, seedCostPoolDescription);

        // Add new cost pool
        pageData().warehouses.costPoolsTab.costPoolsGridToolbarItems.addCostPoolButton().click();
        fillSelectedCostPoolData(newCostPoolName, newCostPoolDescription);
        cy.reload();
        ensureVisibleCostPoolsCount(2);
        ensureVisibleCostPool(seedCostPoolName, seedCostPoolDescription);
        ensureVisibleCostPool(newCostPoolName, newCostPoolDescription);

        // Update existing cost pool
        pageData().warehouses.costPoolsTab.costPoolsGridCell(newCostPoolName, "name").trigger('click');
        fillSelectedCostPoolData(editedCostPoolName, editedCostPoolDescription);
        cy.reload();
        ensureVisibleCostPoolsCount(2);
        ensureVisibleCostPool(seedCostPoolName, seedCostPoolDescription);
        ensureVisibleCostPool(editedCostPoolName, editedCostPoolDescription);

        // Delete existing cost pool
        deleteCostPool(editedCostPoolName);
        cy.reload();
        ensureVisibleCostPoolsCount(1);
        ensureVisibleCostPool(seedCostPoolName, seedCostPoolDescription);

        // Restore deleted cost pool
        pageData().warehouses.costPoolsTab.costPoolsGridToolbarItems.showDeletedCostPoolsCheckbox().click();
        // note: amount of visible deleted cost pools cannot be ensured, since ones created and deleted by earlier test runs might exist.
        ensureVisibleCostPool(seedCostPoolName, seedCostPoolDescription);
        ensureVisibleCostPool(editedCostPoolName, editedCostPoolDescription);
        pageData().warehouses.costPoolsTab.costPoolsGridCell(editedCostPoolName, "actions").within(() => {
            cy.get("[title=Restore]").click();
        });
        cy.reload();
        ensureVisibleCostPoolsCount(2);
        ensureVisibleCostPool(seedCostPoolName, seedCostPoolDescription);
        ensureVisibleCostPool(editedCostPoolName, editedCostPoolDescription);

        // "Cleanup" (cost pools cannot be permanently deleted)
        deleteCostPool(editedCostPoolName);
    };

    it("Should work correctly for Admin", () => {
        cy.loginAsAdmin();
        test();
    });
});