import React from "react";
import {CustomerApprovalType} from "@/models/Enums";
import {ButtonType, DropdownOrderBy, DropdownWidget, ITitleModel, TextInputWidget} from "@renta-apps/athenaeum-react-components";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import RentaTasksFormWizardPage from "@/pages/RentaTasks/RentaTasksFormWizardPage";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import TransformProvider from "../../../providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import FormModel from "@/models/server/forms/FormModel";
import PageDefinitions from "@/providers/PageDefinitions";
import FormPageRow from "@/components/Form/FormPageRow/FormPageRow";
import FormButton from "@/components/Form/FormButton/FormButton";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import SignatureWidget from "@/components/SignatureWidget/SignatureWidget";

export interface IApproveFormProps {
}

interface IApproveFormState {
    signatureCanvasData: string | null;
    nameClarification: string | null;
}

export default class ApproveForm extends RentaTasksFormWizardPage<IApproveFormProps, IApproveFormState> {

    state: IApproveFormState = {
        signatureCanvasData: null,
        nameClarification: null,
    };

    private async setApprovalTypeAsync(approvalType: CustomerApprovalType): Promise<void> {
        if (approvalType !== this.approvalType) {

            this.form!.approvalType = approvalType;

            RentaTasksController.saveMounterContext();

            await this.reRenderAsync();
        }
    }

    private get approvalType(): CustomerApprovalType {
        return this.form!.approvalType;
    }

    private get isSignature(): boolean {
        return (this.approvalType === CustomerApprovalType.Signature);
    }

    public get hasSignature(): boolean {
        return (this.isSignature) &&
            (!!this.state.signatureCanvasData) &&
            (!!this.state.nameClarification) &&
            (this.state.nameClarification.length > 0);
    }

    private get processed(): boolean {
        return FormModel.isProcessed(this.form!)
    }

    private async saveAsync(): Promise<void> {
        if (this.processed) {
            if (this.canNext) {
                RentaTasksController.signatureSrc = this.state.signatureCanvasData;
                RentaTasksController.nameClarification = this.state.nameClarification;

                //TODO: check why we cant use super.nextAsync() here.
                this.resetWorkOrderModifiedValue();

                await RentaTasksController.saveFormAsync(true);

                await this.alertMessageAsync(Localizer.formSummaryPageFormSaved, true);
                
                await PageRouteProvider.redirectAsync(PageDefinitions.rentaTasksWorkOrder(this.form!.workOrderId), true);
            } else {
                await this.alertErrorAsync(Localizer.approveFormPageAlertErrorSignatureIsRequired, true);
            }
        }
    }

    private async onSignatureSignAsync(canvasData: string | null): Promise<void> {
        await this.setState({ signatureCanvasData: canvasData})
    }

    private async onSignatureNameClarificationChangeAsync(nameClarification: string | null): Promise<void> {
        await this.setState({ nameClarification: nameClarification})
    }

    public hideNext(): boolean | null {
        if (this.isLastStep) {
            return true;
        }
        return null;
    }

    protected get canNext(): boolean {
        return ((!this.isSignature) || (this.hasSignature));
    }

    public async nextAsync(): Promise<void> {
        if (this.approvalType !== CustomerApprovalType.Phone) {
            await super.nextAsync();
        }
    }

    public get title(): ITitleModel | undefined {
        return TransformProvider.toTitle(this.form!.workOrder);
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <DropdownWidget wide required expanded
                                icon={{name: "fas tools"}}
                                orderBy={DropdownOrderBy.None}
                                label={Localizer.signatureApprovalType}
                                description={Localizer.signatureApprovalTypeDescription}
                                selectedItem={EnumProvider.getCustomerApprovalTypeItem(this.approvalType)}
                                items={EnumProvider.getCustomerApprovalTypeItems()}
                                onChange={async (_, item) => await this.setApprovalTypeAsync(parseInt(item!.value))}
                />

                {
                    (this.isSignature) &&
                    (
                        <React.Fragment>
                            <SignatureWidget wide preserveSignature
                                             label={Localizer.signatureSignature}
                                             description={Localizer.signatureSignatureDescription}
                                             initialSignatureData={this.state.signatureCanvasData}
                                             onSign={async (signature) => {
                                                 await this.onSignatureSignAsync(signature);
                                             }}
                            />

                            <TextInputWidget wide
                                             id="nameClarification"
                                             label={Localizer.genericNameClarification}
                                             description={Localizer.signatureNameClarificationDescription}
                                             maxLength={RentaTaskConstants.dbKeyLength}
                                             value={this.state.nameClarification ?? undefined}
                                             onChange={async (_, nameClarification) => {
                                                 await this.onSignatureNameClarificationChangeAsync(nameClarification);
                                             }}
                            />

                            <FormPageRow>
                                <FormButton type={ButtonType.Orange} block big
                                            label={Localizer.rentaManagementSummaryPageButtonTitle}
                                            disabled={!this.processed && !this.canNext}
                                            onClick={async () => await this.saveAsync()}
                                />
                            </FormPageRow>
                        </React.Fragment>
                    )
                }

                {
                    (this.approvalType === CustomerApprovalType.Phone) &&
                    (
                        <FormPageRow>
                            <FormButton type={ButtonType.Orange} block big
                                        label={Localizer.rentaManagementSummaryPageButtonTitle}
                                        disabled={!this.processed}
                                        onClick={async () => await this.saveAsync()}
                            />
                        </FormPageRow>
                    )
                }

            </React.Fragment>
        );
    }
}