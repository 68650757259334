import React from "react";
import FormItem from "@/models/server/forms/FormItem";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {ImageInput} from "@renta-apps/athenaeum-react-components";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import FormPageRow from "@/components/Form/FormPageRow/FormPageRow";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "@/localization/Localizer";
import ImageHelper from "@/helpers/ImageHelper";

import styles from "./FormPictures.module.scss";

export interface IFormPicturesProps {
    formItem: FormItem;
    preview?: boolean;
    onChange?(sender: FormPictures): Promise<void>;
}

/** @private */
interface IFormPicturesState {
    imageInputKey: string;
}

export default class FormPictures extends BaseComponent<IFormPicturesProps, IFormPicturesState> {

    public state: IFormPicturesState = {
        imageInputKey: this.id,
    };

    private get formItem(): FormItem {
        return this.props.formItem;
    }

    public async onImageEditorChangeAsync(formItem: FormItem, pictures: FileModel[]): Promise<void> {
        const validPictures: FileModel[] = pictures.filter(picture => ImageHelper.isSupportedImage(picture));

        if (pictures.length !== validPictures.length) {
            ch.alertErrorAsync(Localizer.genericUnsupportedImageType, true, true);

            /**
             * Force the image input to clear its state if invalid images are uploaded to it.
             */
            await this.setState({
                imageInputKey: ch.getComponentId(),
            })
        }

        formItem.pictures = validPictures;

        if (this.props.onChange) {
            await this.props.onChange(this);
        }

        await this.reRenderAsync()
    }

    public render(): React.ReactNode {
        const {preview} = this.props;

        return (
            <div className={styles.formPictures}>

                <FormPageRow>
                    <span className={styles.title}>{this.formItem.name}</span>
                </FormPageRow>

                <FormPageRow className={this.css(styles.photoContainer)}>

                    <ImageInput multi
                                key={this.state.imageInputKey}
                                className="flex-1"
                                readonly={preview}
                                pictures={this.formItem.pictures}
                                maxImageRequestSizeInBytes={RentaTaskConstants.maxImageRequestSizeInBytes}
                                convertImage={(file) => RentaTasksController.convertImageAsync(file, true)}
                                onChange={(_, pictures) => this.onImageEditorChangeAsync(this.formItem, pictures)}
                                fileTypes={[...RentaTaskConstants.supportedImageFileExtensions]}
                                previewToolbar={{
                                    previewButton: true,
                                    downloadButton: true,
                                }}
                                editToolbar={{
                                    previewButton: true,
                                    downloadButton: true,
                                }}
                                selectionToolbar={{
                                    previewButton: true,
                                    downloadButton: true,
                                }}
                    />

                </FormPageRow>

            </div>
        );
    }
}