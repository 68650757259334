import {ExtraChargeTypeUnit} from "@/models/Enums";

export default class ExtraChargeType {
    public id: string = "";
    
    /** Name of the type to be displayed on the UI. */
    public name: string = "";
    
    /** Unit of the extra charge used for cost calculation and invoicing. */
    public unit: ExtraChargeTypeUnit = ExtraChargeTypeUnit.H;
    
    /** External id of the type used to map extra charges in ERP. */
    public externalId: string = "";

    /** Used as default price for new work order extra charge. */
    public price: number = 0;

    /**
     * If true, the type cannot be added to extra charges.
     * It remains in extra charges that already had it.
     */
    public deleted: boolean = false;

    public isExtraChargeType: boolean = true;
}
