import {GeoCoordinate} from "@renta-apps/athenaeum-toolkit";
import BaseConcurrencyRequest from "@/models/server/requests/BaseConcurrencyRequest";

export default class TaskCheckOutRequest extends BaseConcurrencyRequest {
    public location: GeoCoordinate | null = null;

    public normalHours: number | null = null;

    public overtime50Hours: number | null = null;

    public overtime100Hours: number | null = null;
    
    public comment: string | null = null;
}