import User from "@/models/server/User";
import {CustomerApprovalType} from "@/models/Enums";

export default class ApproveWorkOrderByEmailRequest {
    
    public workOrderId: string = "";
    
    public customerApprover: User | null = null;
    
    public approved: boolean = false;

    public approvalType: CustomerApprovalType = CustomerApprovalType.Email; 
}