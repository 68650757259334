import User from "@/models/server/User";

export default class CostPool {
    public id: string = "";
    
    public name: string = "";

    public externalId: string = "";
    
    public description: string | null = null;
    
    public deleted: boolean = false;

    public deletedAt: Date | null = null;

    public deletedBy: User | null = null;
    
    public isCostPool: boolean = true;
}