import React, {ReactElement} from "react";
import {BaseComponent, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ITimelineItemTooltipRendererProps, ITimelineSelectedItemIds, Timeline,} from "@renta-apps/athenaeum-react-components";
import {TimelineItem} from "@/pages/Calendar/CalendarData/Models/TimelineItem";
import {TimelineRow} from "@/pages/Calendar/CalendarData/Models/TimelineRow";
import {TimelineRowHeaderColumn} from "@/pages/Calendar/CalendarData/Models/TimelineRowHeaderColumn";
import CalendarItemBuilder from "@/pages/Calendar/CalendarData/CalendarItemBuilder";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import UpdateMountersAndTimesRequest from "@/models/server/requests/UpdateMountersAndTimesRequest";
import PageDefinitions from "@/providers/PageDefinitions";
import User from "@/models/server/User";
import {WorkOrderStatus} from "@/models/Enums";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";
import Subcontractor from "@/models/server/Subcontractor";
import SubcontractorAssignmentModal from "@/components/SubcontractorAssignmentModal/SubcontractorAssignmentModal";
import ConfirmAssignDialog from "@/pages/Calendar/CalendarData/ConfirmAssignDialog/ConfirmAssignDialog";

import styles from "./CalendarData.module.scss";

export interface ICalendarDataProps {
    id: string;
    tasks: WorkOrderModel[];
    mounters: User[];
    selectedMounters: User[];
    // teams: Team[];
    // selectedTeamIds: string[];
    startDate: Date;
    endDate: Date;
    snapSeconds: number;

    /**
     * @param request fresh data.
     * @param subContractorChanged true of the work order change affects sub contractors.
     */
    onWorkOrderChange(request: UpdateMountersAndTimesRequest, subContractorChanged: boolean): Promise<void>;
    subContractors: Subcontractor[];
}

interface ICalendarDataState {
    // teams: Team[];
    // selectedTeamIds: string[];
    draggedItems: TimelineItem[];
    items: readonly Readonly<TimelineItem>[];
    rows: Readonly<TimelineRow>[];
    rowHeaderColumns: TimelineRowHeaderColumn[];
    selectedItemsIds: ITimelineSelectedItemIds[];
    dragTargetRow: Readonly<TimelineRow> | null;

    /** If true, a new sub contractor is being assigned to a construction site. */
    assigningNewSubContractor: boolean;
}

export default class CalendarData extends BaseComponent<ICalendarDataProps, ICalendarDataState> {

    // Fields
    private readonly _assignConfirmModalRef: React.RefObject<ConfirmAssignDialog> = React.createRef();
    private readonly _confirmSubContractorAssignationDialogRef: React.RefObject<SubcontractorAssignmentModal> = React.createRef();

    public state: ICalendarDataState = {
        // teams: [],
        // selectedTeamIds: [],
        draggedItems: [],
        items: [],
        rows: [],
        rowHeaderColumns: [],
        selectedItemsIds: [],
        dragTargetRow: null,
        assigningNewSubContractor: false,
    };


    // Properties

    private get multiselect(): boolean {
        return false;
    }

    //
    // private get teams(): Team[] {
    //     return this.state.teams;
    // }
    //
    // private get selectedTeamIds(): string[] {
    //     return this.state.selectedTeamIds;
    // }


    // Methods

    private async onItemClickAsync(
        _: React.MouseEvent,
        __: Readonly<Timeline<TimelineRow, TimelineItem, TimelineRowHeaderColumn>>,
        row: Readonly<TimelineRow>,
        item: Readonly<TimelineItem>
    ): Promise<void> {
        const itemId: string = item.id;
        const existingSelectedItemIds: ITimelineSelectedItemIds | undefined = this.state.selectedItemsIds.find(selectedItemIds => (selectedItemIds.itemId === itemId));

        let newSelectedItemsIds: ITimelineSelectedItemIds[];

        if (!existingSelectedItemIds) {
            // item not yet selected

            const newSelectedItemIds: ITimelineSelectedItemIds ={
                itemId,
                rowIds: [row.id],
            };

            if (this.multiselect) {
                // add to selected
                newSelectedItemsIds = [
                    ...this.state.selectedItemsIds,
                    newSelectedItemIds,
                ];
            }
            else {
                // replace selected
                newSelectedItemsIds = [newSelectedItemIds];
            }
        }
        else {
            // item already selected

            const rowId: string = row.id;
            const existingSelectedRowId: string | undefined = existingSelectedItemIds.rowIds.find(selectedRowId => (selectedRowId === rowId));
            newSelectedItemsIds = this.state.selectedItemsIds;

            if (existingSelectedRowId) {
                // row already selected
                if (this.multiselect) {
                    // remove from selection
                    if (existingSelectedItemIds.rowIds.length === 1) {
                        // only row
                        newSelectedItemsIds.remove(existingSelectedItemIds);
                    }
                    else {
                        // other rows
                        existingSelectedItemIds.rowIds = existingSelectedItemIds.rowIds.filter(selectedRowId => (selectedRowId !== rowId));
                    }
                }
                else {
                    // empty selection
                    newSelectedItemsIds = [];
                }
            }
            else {
                // row not yet selected
                if (this.multiselect) {
                    // add row to selection
                    existingSelectedItemIds.rowIds.push(rowId);
                }
                else {
                    // replace selection
                    existingSelectedItemIds.rowIds = [rowId];
                }
            }
        }

        await this.setState({selectedItemsIds: newSelectedItemsIds})
    }

    private async onItemRightClickAsync(
        _: React.MouseEvent,
        __: Readonly<Timeline<TimelineRow, TimelineItem, TimelineRowHeaderColumn>>,
        ___: Readonly<TimelineRow>,
        item: Readonly<TimelineItem>
    ): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.workOrderRoute(item.task.id));
    }

    private async onItemResizeStartAsync(resizedItems: readonly Readonly<TimelineItem>[]): Promise<readonly Readonly<TimelineItem>[]> {
        return resizedItems.filter(resizedItem => (this.timeChangeAllowed(resizedItem.task)));
    }

    private async onItemResizeEndAsync(resizedItems: TimelineItem[]): Promise<void> {
        const resizedItem: TimelineItem = resizedItems[0];
        const mounterUserIds: string[] = resizedItem.task.mountersIds;
        const startTime: Date = resizedItem.start as Date;
        const endTime: Date = resizedItem.end as Date;
        const pauseStart : Date | null = resizedItem.task.pauseStartDate;
        const pauseEnd : Date | null = resizedItem.task.pauseEndDate;

        await this.onWorkOrderChangeAsync(
            resizedItem.task.id,
            mounterUserIds,
            startTime,
            endTime,
            pauseStart,
            pauseEnd
        );
    }

    private async onItemDragStartAsync(selectedItems: readonly Readonly<TimelineItem>[]): Promise<readonly Readonly<TimelineItem>[]> {
        // Even though in theory the user could just drag to another row and keep the time
        // in practise the time will always change because of the timeline's high resolution.
        return selectedItems.filter(item => this.timeChangeAllowed(item.task));
    }

    private async onItemDragEndAsync(
        draggedItems: TimelineItem[],
        _: number,
        dragTargetRow?: Readonly<TimelineRow>
    ): Promise<void> {
        if ((!dragTargetRow) || (dragTargetRow.isTeam)) {
            return;
        }

        const draggedToUnassignedRow: boolean = (dragTargetRow.id === CalendarItemBuilder.unassignedRow.id);

        //NOTE: won't work if multiselect is enabled (both assigned and unassigned Tasks can be selected at the same time)
        const draggedFromUnassignedRow: boolean = draggedItems
            .some(item => item.task.mountersIds.length === 0);

        //NOTE: won't work if multiselect is enabled (some selected Tasks might be already assigned to the mounter, some not)
        const draggedWithinTheSameRow: boolean = draggedItems
            .some(item => item.task.mountersIds.includes(dragTargetRow.id));

        const draggedAssignedTaskToAnotherMounterRow: boolean = !draggedToUnassignedRow && !draggedFromUnassignedRow && !draggedWithinTheSameRow;

        const draggedItem: TimelineItem = draggedItems[0];
        let mounterUserIds: string[] = [];
        const startTime: Date = draggedItem.start as Date;
        const endTime: Date = draggedItem.end as Date;
        const pauseStart : Date | null = draggedItem.task.pauseStartDate;
        const pauseEnd : Date | null = draggedItem.task.pauseEndDate;

        if (draggedToUnassignedRow) {
            // Un-assign (remove mounters).
            mounterUserIds = [];
        } else if (draggedFromUnassignedRow) {
            // Add new mounter to the un-assigned task.
            mounterUserIds = [dragTargetRow.id];
        } else if (draggedWithinTheSameRow) {
            // No need to update mounters.
            mounterUserIds = draggedItem.task.mountersIds;
        }
        
        const assigningNewSubContractor: boolean = this.subContractorHasContractForConstructionSite(
            dragTargetRow.id, draggedItem.task.constructionSiteId!);

        // Need to prompt user what to do
        if (assigningNewSubContractor || draggedAssignedTaskToAnotherMounterRow) {
            // Save state for later use
            this.setState({
                draggedItems,
                dragTargetRow,
                assigningNewSubContractor: assigningNewSubContractor
            });
            
            if (assigningNewSubContractor && !draggedAssignedTaskToAnotherMounterRow) {
                // Only assigning a new sub contractor
                await this._confirmSubContractorAssignationDialogRef.current!.openAsync(dragTargetRow.mounter);
            } else {
                // Assigning an already assigned task, possibly to a new sub contractor
                await this._assignConfirmModalRef.current!.openAsync(assigningNewSubContractor, dragTargetRow.mounter);
            }

            // To be continued in the modal handlers depending on user selection
            return;
        }

        // No need to prompt the user, just update
        await this.onWorkOrderChangeAsync(
            draggedItem.task.id,
            mounterUserIds,
            startTime,
            endTime,
            pauseStart,
            pauseEnd,
            dragTargetRow.id
        );
    }
    
    private subContractorHasContractForConstructionSite(mounterId: string, constructionSiteId: string): boolean {
        const targetMounter: User | undefined = this.props.mounters.find(mounter => mounter.id === mounterId);
        if (!targetMounter || !targetMounter.isSubcontractorMounter) {
            return false;
        }
        
        const existingSubContractor: Subcontractor | undefined = this.props.subContractors
            .find(sub =>
                sub.constructionSiteId === constructionSiteId &&
                sub.organizationContractId === targetMounter.role.organizationContractId);

        return !existingSubContractor;
    }

    /** Time changes in the timeline are allowed before work order is completed. */
    private timeChangeAllowed(task: WorkOrderModel): boolean {
        return task.currentStatus < WorkOrderStatus.Completed;
    }

    private async onRowClickAsync(): Promise<void> {
        await this.setState({selectedItemsIds: []});
    }

    private async onMounterAssignRejected(): Promise<void> {
        // Reset state
        this.setState({
            draggedItems: [],
            dragTargetRow: null,
            assigningNewSubContractor: false,
        });

        await this._assignConfirmModalRef.current?.closeAsync();
        await this._confirmSubContractorAssignationDialogRef.current?.closeAsync();
    }

    private async onMounterAssignConfirmed(concatMounters: boolean): Promise<void> {
        const updatedTask: WorkOrderModel = this.state.draggedItems[0].task;
        const draggedItem: TimelineItem = this.state.draggedItems[0];
        const dragTargetRowId: string = this.state.dragTargetRow!.id;

        // Update task
        const startTime: Date = draggedItem.start as Date;
        const endTime: Date = draggedItem.end as Date;
        const pauseStart : Date | null = draggedItem.task.pauseStartDate;
        const pauseEnd : Date | null = draggedItem.task.pauseEndDate;
        const mounterUserIds: string[] = concatMounters
            ? [...updatedTask.mountersIds, dragTargetRowId]
            : [dragTargetRowId]

        await this.onWorkOrderChangeAsync(
            draggedItem.task.id,
            mounterUserIds,
            startTime,
            endTime,
            pauseStart,
            pauseEnd,
            dragTargetRowId
        );

        // Reset state
        await this.setState({
            draggedItems: [],
            dragTargetRow: null,
            assigningNewSubContractor: false,
        });

        await this._assignConfirmModalRef.current?.closeAsync();
        await this._confirmSubContractorAssignationDialogRef.current?.closeAsync();
    }

    private async onWorkOrderChangeAsync(
        workOrderId: string,
        mounterUserIds: string[],
        startTime: Date,
        endTime: Date,
        pauseStartDate: Date | null,
        pauseEndDate: Date | null,
        dragTargetRowId?: string
    ): Promise<void> {
        const request: UpdateMountersAndTimesRequest = new UpdateMountersAndTimesRequest();

        request.workOrderId = workOrderId;
        request.mounterUserIds = mounterUserIds;
        request.activationDate = startTime;
        request.plannedCompletionDate = endTime;
        request.pauseStartDate = pauseStartDate;
        request.pauseEndDate = pauseEndDate;

        const subContractorChanged: boolean = this.state.assigningNewSubContractor;
        await this.props.onWorkOrderChange(request, subContractorChanged);

        if (dragTargetRowId) {
            // Select drag target row
            const selectedItemsIds: ITimelineSelectedItemIds[] = [
                {
                    itemId: workOrderId,
                    rowIds: [dragTargetRowId],
                }
            ];

            await this.setState({selectedItemsIds});
        }
    }

    public static getDerivedStateFromProps(nextProps: ICalendarDataProps, prevState: ICalendarDataState): ICalendarDataState {
        const {items, rows, rowHeaderColumns, selectedItemsIds} = CalendarItemBuilder.buildItems(
            nextProps.startDate,
            nextProps.endDate,
            nextProps.tasks,
            nextProps.mounters,
            nextProps.selectedMounters,
            // this.props.selectedTeamIds,
            prevState.selectedItemsIds,
            nextProps.snapSeconds);

        return {
            ...prevState,
            items,
            rows,
            rowHeaderColumns,
            selectedItemsIds,
        };
    }

    private static renderItemTooltip(props: ITimelineItemTooltipRendererProps<TimelineRow, TimelineItem>): ReactElement {
        const isPaused : boolean = props.item.task.pauseStartDate != null;
        
        const pauseEndText : string = (props.item.task.pauseEndDate != null) ? Utility.toShortDateShortTimeString(props.item.task.pauseEndDate) : "TBD";
        return (
            <div className={styles.tooltip}>
                {props.item.task.owner?.contractName}<br/>
                {props.item.task.name}<br/>
                {props.item.task.owner?.name}<br/>
                {WorkOrderModel.getStateWithBlockingIcon(props.item.task).tooltip}<br/>
                {isPaused && (Localizer.calendarPageCalendarDataPauseOverlayText.format(Utility.toShortDateShortTimeString(props.item.task.pauseStartDate!), pauseEndText))}
            </div>
        );
    }

    public render(): React.ReactNode {

        return (
            <div>
                <div id={this.props.id}
                     className={styles.calendarData}>

                    <Timeline<TimelineRow, TimelineItem, TimelineRowHeaderColumn> canResize canSelect canDrag showCurrentTimeMarker
                                                                                  startTime={this.props.startDate}
                                                                                  endTime={this.props.endDate}
                                                                                  snapSeconds={this.props.snapSeconds}
                                                                                  items={this.state.items}
                                                                                  selectedItemsIds={this.state.selectedItemsIds}
                                                                                  onItemClick={this.onItemClickAsync.bind(this)}
                                                                                  onItemContextMenu={this.onItemRightClickAsync.bind(this)}
                                                                                  onItemResizeStart={this.onItemResizeStartAsync.bind(this)}
                                                                                  onItemResizeEnd={this.onItemResizeEndAsync.bind(this)}
                                                                                  onItemDragStart={this.onItemDragStartAsync.bind(this)}
                                                                                  onItemDragEnd={this.onItemDragEndAsync.bind(this)}
                                                                                  itemTooltipRenderer={CalendarData.renderItemTooltip}
                                                                                  rows={this.state.rows}
                                                                                  onRowClick={this.onRowClickAsync.bind(this)}
                                                                                  rowHeaderColumns={this.state.rowHeaderColumns}
                    />
                </div>

                <ConfirmAssignDialog
                    ref={this._assignConfirmModalRef}
                    onMounterAssignRejected={async () => await this.onMounterAssignRejected()}
                    onMounterAssignConfirmed={async (concatMounters: boolean) => await this.onMounterAssignConfirmed(concatMounters)}
                />

                <SubcontractorAssignmentModal
                    ref={this._confirmSubContractorAssignationDialogRef}
                    unAssignMounter={async () => await this.onMounterAssignRejected()}
                    assignMounter={async () => await this.onMounterAssignConfirmed(true)}
                />
            </div>
        );
    }
};