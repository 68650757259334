import Localizer from "@/localization/Localizer";
import {TextInput} from "@renta-apps/athenaeum-react-components";
import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";

/**
 * Props for {@link WorkOrderSearchInput}.
 */
export interface IWorkOrderSearchInputProps {

    /**
     * Search string displayed in the {@link WorkOrderSearchInput}.
     */
    value: string;

    /**
     * Called when the value of the {@link WorkOrderSearchInput} changes.
     *
     * @param search Changed search string.
     */
    onChange(search: string): Promise<void>;

    /** @see ITextInputProps.inline */
    inline?: boolean;

    /** @see ITextInputProps.label */
    label?: string;

    /** @see ITextInputProps.width */
    width?: string;

    /** @see ITextInputProps.readonly */
    readonly?: boolean;
}

/**
 * A {@link TextInput} specialiced as a Work Order search field.
 */
export default class WorkOrderSearchInput extends BaseComponent<IWorkOrderSearchInputProps, never> {

    private onChangeAsync = async (_: never, search: string): Promise<void> => {
        await this.props.onChange(search);
    }

    public render(): JSX.Element {
        return (
            <TextInput readonly={this.props.readonly}
                       label={this.props.label}
                       inline={this.props.inline}
                       value={this.props.value}
                       width={this.props.width}
                       placeholder={Localizer.tasksToolbarSearchFieldPlaceholder}
                       title={Localizer.tasksToolbarSearchFieldPlaceholder}
                       onChange={this.onChangeAsync}
            />
        );
    }
}