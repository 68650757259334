import React from "react";
import {AlertModel, ApiProvider, BasePageParameters, DataStorageType, DialogResult, PageRoute, PageRouteProvider, UserInteractionDataStorage} from "@renta-apps/athenaeum-react-common";
import WizardPage from "../../models/base/WizardPage";
import WizardContext from "@/pages/RentaTasks/Models/WizardContext";
import PageDefinitions from "@/providers/PageDefinitions";
import {IIconProps, ITitleModel, IWizardStep, IWizardSteps, PageContainer, WizardContainer} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import MounterContext from "@/pages/RentaTasks/Models/MounterContext";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import FormModel from "@/models/server/forms/FormModel";
import RentaTasksController, {IWizardNextStep, RentaTasksAction} from "./RentaTasksController";
import Localizer from "@/localization/Localizer";

import rentaTaskStyles from "../RentaTask.module.scss";

export default abstract class RentaTasksWizardPage<TParams extends BasePageParameters = {}, TState = {}>
    extends WizardPage<TParams, TState> {

    private readonly _wizardContainerRef: React.RefObject<WizardContainer> = React.createRef();
    private _canNext: boolean | null = null;

    private isWorkOrderModified(): boolean {
        const equipmentModified: boolean = UserInteractionDataStorage.get(RentaTaskConstants.userInteraction.workOrderEquipmentModified, false, DataStorageType.Session);
        if (equipmentModified) {
            return true;
        }
        const original: number = UserInteractionDataStorage.get(RentaTaskConstants.userInteraction.initialWorkOrderHashCode, 1, DataStorageType.Session);

        const currentHashCode: number = UserInteractionDataStorage.get(RentaTaskConstants.userInteraction.currentWorkOrderHashCode, -1, DataStorageType.Session);

        if (currentHashCode === -1) {
            return false;
        }

        return (original !== currentHashCode);
    }
    
    protected async onSaveAndCloseAsync(): Promise<void> {
        return;
    }
    
    protected get showMiddle(): boolean {
        return false;
    }

    protected resetWorkOrderModifiedValue() {
        UserInteractionDataStorage.set(RentaTaskConstants.userInteraction.workOrderEquipmentModified, false, DataStorageType.Session);
        UserInteractionDataStorage.set(RentaTaskConstants.userInteraction.initialWorkOrderHashCode, 1, DataStorageType.Session);
        UserInteractionDataStorage.set(RentaTaskConstants.userInteraction.currentWorkOrderHashCode, -1, DataStorageType.Session);
        UserInteractionDataStorage.set(RentaTaskConstants.userInteraction.initialFormHashCode, 1, DataStorageType.Session);
        UserInteractionDataStorage.set(RentaTaskConstants.userInteraction.currentFormHashCode, -1, DataStorageType.Session);
    }

    protected nextRouteWithinWizard(nextRoute: PageRoute) {
        const allSteps: IWizardSteps | null = RentaTasksController.getWizardSteps();

        if (allSteps) {
            const steps: IWizardStep[] = allSteps.steps;

            return steps.some(step => step.route.name == nextRoute.name);
        }
        return false;
    }

    protected get wizard(): WizardContext {
        return RentaTasksController.wizardContext;
    }

    protected get form(): FormModel | null {
        return RentaTasksController.form;
    }

    protected findWidget<TWidget>(id: string): TWidget | null {
        return (this.wizardContainer != null)
            ? this.wizardContainer.findComponent(id) as TWidget | null
            : null;
    }

    protected getNoToggle(): boolean {
        return false;
    }

    protected get workOrderRequired(): boolean {
        return true;
    }

    protected get formRequired(): boolean {
        const action = RentaTasksController.wizardContext.action;

        return (action === RentaTasksAction.Form);
    }

    protected saveContext(): void {
        const currentWizardContextWorkOrderHashCode: number = Utility.getHashCode(RentaTasksController.wizardContextWorkOrder);

        UserInteractionDataStorage.set(RentaTaskConstants.userInteraction.currentWorkOrderHashCode, currentWizardContextWorkOrderHashCode, DataStorageType.Session);

        RentaTasksController.saveMounterContext();
    }

    protected async validateAsync(): Promise<boolean> {
        const canNext: boolean = this.canNext;
        if (canNext !== this._canNext) {
            this._canNext = canNext;
            await this.reRenderAsync();
        }
        return canNext;
    }

    protected async invokeRedirectAsync(nextRoute: PageRoute): Promise<void> {
        const alert: AlertModel | null = this.alert;

        await this.redirectNextAsync(nextRoute);

        if (alert != null) {
            await this.alertAsync(alert);
        }
    }

    protected async navigateAsync(step: IWizardStep): Promise<void> {
        await this.redirectNextAsync(step.route);
    }

    protected get mounterContext(): MounterContext {
        return RentaTasksController.mounterContext;
    }

    private get wizardContainer(): WizardContainer | null {
        return this._wizardContainerRef.current;
    }

    protected get controller(): string | null {
        return ((this.wizardContainer != null) != null) ? this.wizardContainer!.controller : null;
    }

    protected get action(): RentaTasksAction {
        return this.wizard.action;
    }

    protected get title(): ITitleModel | undefined {
        return RentaTasksController.wizardTitle;
    }

    protected get steps(): IWizardStep[] {
        const stepsContainer: IWizardSteps | null = RentaTasksController.getWizardSteps();
        return stepsContainer?.steps ?? [];
    }
    protected get isFistStep(): boolean {
        return RentaTasksController.isFistStep(this.route);
    }
    protected get isLastStep(): boolean {
        return RentaTasksController.isLastStep(this.route);
    }

    protected get canPrev(): boolean {
        return true;
    }

    protected get canNext(): boolean {
        return true;
    }

    public async prevAsync(): Promise<void> {
        if (this.canPrev) {
            const expired: boolean = await RentaTasksController.checkExpirationAsync();

            if (!expired) {

                const prevRoute: PageRoute = await RentaTasksController.getPrevStepAsync(this.route);

                await this.redirectPrevAsync(prevRoute);
            }
        }
    }

    public async beforeRedirectAsync(nextRoute: PageRoute, innerRedirect: boolean): Promise<boolean> {
        const nextRouteInsideWizard: boolean = this.nextRouteWithinWizard(nextRoute);

        if (nextRouteInsideWizard) {
            return super.beforeRedirectAsync(nextRoute, innerRedirect);
        }

        let isModified: boolean = this.isWorkOrderModified();

        if (isModified) {
            const result: DialogResult = await this.messageBoxAsync(Localizer.addEquipmentSaveChangesConfirmation, "", {
                yesButton: Localizer.addEquipmentSaveButton,
                noButton: Localizer.addEquipmentDontSaveButton,
                cancelButton: Localizer.addEquipmentCloseButton
            });

            if (result == DialogResult.Cancel) {
                return false;
            }

            this.resetWorkOrderModifiedValue();

            if (result == DialogResult.No) {
                return true;
            }
            if (result == DialogResult.Yes) {
                if (this.action === RentaTasksAction.CompleteWorkOrder) {
                    this.wizard.action = RentaTasksAction.EditWorkOrder;
                }
                await RentaTasksController.completeActionAsync(true);
            }
        }

        return true;
    }

    private async completeAsync(nextRoute: PageRoute): Promise<void> {
        await RentaTasksController.completeActionAsync(true);

        await this.invokeRedirectAsync(nextRoute);
    }

    public async nextAsync(): Promise<void> {

        const canNext: boolean = await this.validateAsync();

        if (canNext) {
            const expired: boolean = await RentaTasksController.checkExpirationAsync();

            if (!expired) {

                const nextStep: IWizardNextStep = await RentaTasksController.getNextStepAsync(this.route);

                const nextRoute: PageRoute = nextStep.nextRoute;

                if (nextStep.lastStep) {
                    this.resetWorkOrderModifiedValue();
                    await ApiProvider.invokeWithForcedSpinnerAsync(() => this.completeAsync(nextRoute));
                } else {
                    await this.invokeRedirectAsync(nextRoute);
                }
            }
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const expired: boolean = await RentaTasksController.checkExpirationAsync();

        const redirect: boolean = (expired) ||
            ((this.workOrderRequired) && (!this.wizard.workOrder)) ||
            ((this.formRequired) && (!this.form));

        if (redirect) {
            const route: PageRoute = this.wizard.actionInitialPageRoute || PageDefinitions.rentaTasksRoute;

            await PageRouteProvider.redirectAsync(route, true, true);
        }
    }

    public abstract getManual(): string;

    public nextIcon(): IIconProps | null {
        return null;
    }

    public nextDescription(): string | null {
        return null;
    }

    public hideNext(): boolean | null {
        return null;
    }

    public hidePrev(): boolean | null {
        return null;
    }

    public get noToggle(): boolean {
        return this.getNoToggle();
    }

    protected abstract renderContent(): React.ReactNode

    public renderContainer(): React.ReactNode {
        return this.renderContent();
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullHeight
                           className={rentaTaskStyles.pageContainer}
                           alertClassName={rentaTaskStyles.alert}>

                <WizardContainer responsive
                                 id="rentaTasksWizardContainer"
                                 ref={this._wizardContainerRef}
                                 controller="RentaTasks"
                                 navigationClassName={rentaTaskStyles.navigation}
                                 canNext={this.canNext}
                                 nextIcon={this.nextIcon() || undefined}
                                 nextDescription={this.nextDescription() || undefined}
                                 canPrev={this.canPrev}
                                 steps={RentaTasksController.getWizardSteps() || undefined}
                                 noToggle={this.noToggle}
                                 hideNext={this.hideNext() || undefined}
                                 hidePrev={this.hidePrev() || undefined}
                                 middleLabel={Localizer.mobileFormSaveAndCloseLabel}
                                 middleDescription={Localizer.mobileFormSaveAndCloseDescription}
                                 showMiddle={this.showMiddle}
                                 onMiddleClick={async () => await this.onSaveAndCloseAsync()}
                >

                    {this.renderContainer()}

                </WizardContainer>

            </PageContainer>
        );
    }
}