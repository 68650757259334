import React from 'react';
import {TextInput} from "@renta-apps/athenaeum-react-components";
import User from "@/models/server/User";
import Localizer from "@/localization/Localizer";
import TransformProvider from "@/providers/TransformProvider";
import SignatureWidget from "@/components/SignatureWidget/SignatureWidget";

import styles from "./UserSignature.module.scss";

/**
 * Props for {@link UserSignature}.
 */
export interface IUserSignatureProps {
    id: string;
    readonly?: boolean;
    src?: string | null;
    selectedMounter: User;
    nameClarification: string;
    onSignatureSign(signatureSrc: string | null, user: User | undefined): Promise<void>;
    onNameClarificationChange(nameClarification: string, user: User | undefined): Promise<void>;
}

export const UserSignature = (userSignatureProps: IUserSignatureProps) => {

    return (
        <div className={`${styles.userSignatureWrapper} user-signature`}>

            <p>{Localizer.signatureSignatureFor}: {TransformProvider.userToString(userSignatureProps.selectedMounter)}</p>

            {
                (!userSignatureProps.readonly) ?
                    (
                        <SignatureWidget wide
                                         className={"pb-4"}
                                         preserveSignature={true}
                                         initialSignatureData={userSignatureProps.src}
                                         label={Localizer.signatureSignature}
                                         description={Localizer.signatureSignatureDescription}
                                         onSign={async (canvasData) => await userSignatureProps.onSignatureSign(canvasData, userSignatureProps.selectedMounter)}
                        />
                    )
                    :
                    (userSignatureProps.src) &&
                    (
                        <img src={userSignatureProps.src}
                             alt={TransformProvider.userToString(userSignatureProps.selectedMounter)}
                        />
                    )
            }

            <TextInput required
                       className="signature-name-clarification"
                       readonly={userSignatureProps.readonly}
                       label={Localizer.signatureSigneeName}
                       value={userSignatureProps.nameClarification}
                       onChange={async (_, signeeName) => await userSignatureProps.onNameClarificationChange(signeeName, userSignatureProps.selectedMounter)}
            />

        </div>
    )
};