import React from "react";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import {Modal, ModalSize, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import User from "@/models/server/User";
import UsersContactInfo from "@/pages/WorkOrder/UsersInfoModal/UsersContactInfo";
import Localizer from "@/localization/Localizer";
import TransformProvider from "@/providers/TransformProvider";

interface IUsersInfoModalProps {
}

interface IUsersInfoModalState {
    mounters: User[];
}

export default class UsersInfoModal extends BaseComponent<IUsersInfoModalProps, IUsersInfoModalState> {

    state: IUsersInfoModalState = {
        mounters: [],
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    private get mounters(): User[] {
        return this.state.mounters;
    }
    
    private get modal(): Modal {
        return this._modalRef.current!;
    }

    public async openAsync(mounters: User[]): Promise<void> {
        await this.setState({mounters});
        await this.modal.openAsync();
    }

    public static get modalId(): string {
        return "usersInfoModal";
    }
    
    public renderMounterRow(mounter: User): React.ReactNode {
        return (
            <React.Fragment key={ch.getComponentId()}>

                <TwoColumns>

                    <TextInput readonly
                               id={`${UsersInfoModal.modalId}FullName`}
                               label={Localizer.genericFullName}
                               value={TransformProvider.userToString(mounter)}
                    />

                    <UsersContactInfo email={mounter.email}
                                      phone={mounter.phone}
                    />

                </TwoColumns>

                <hr/>

            </React.Fragment>
        );
    }

    public render(): React.ReactNode {
        return (
            <Modal id={UsersInfoModal.modalId}
                   ref={this._modalRef}
                   title={Localizer.genericMounters}
                   size={ModalSize.Large}
            >
                
                {this.mounters.map((mounter) => this.renderMounterRow(mounter))}
                
            </Modal>
        );
    }
}