import React, {ReactElement} from "react";
import CalendarItemData from "@/pages/Calendar/CalendarData/Models/CalendarItemData";
import User from "@/models/server/User";
import {TimelineRow} from "@/pages/Calendar/CalendarData/Models/TimelineRow";
import {TimelineRowHeaderColumn} from "@/pages/Calendar/CalendarData/Models/TimelineRowHeaderColumn";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import {TimelineItem} from "@/pages/Calendar/CalendarData/Models/TimelineItem";
import {ITimelineItemRendererProps, ITimelineRowHeaderColumnCellRendererProps, ITimelineSelectedItemIds} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import {TaskStatusFilter} from "@/models/Enums";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import styles from "@/pages/Calendar/CalendarData/CalendarData.module.scss";

export default class CalendarItemBuilder {

    /**
     * A dummy unassigned "mounter" row. Unassigned tasks are displayed under this.
     */
    public static get unassignedRow(): Readonly<TimelineRow> {
        return {
            id: RentaTaskConstants.defaultGuid,
            name: Localizer.calendarPageCalendarDataUnassignedRow,
            mounter: null!,
            isTeam: false,
            belongsToTeam: false,
            color: null
        };
    }

    /**
     * Headers for the calendar.
     */
    private static get rowHeaderColumns(): TimelineRowHeaderColumn[] {
        return [
            {
                headerLabel: Localizer.genericName,
                cellRenderer: (props: ITimelineRowHeaderColumnCellRendererProps<TimelineRow, TimelineRowHeaderColumn>): ReactElement => {
                    return (
                        <div className={props.row.isTeam ? styles.teamHeader : styles.mounterHeader}>
                        <div className={styles.teamColor}
                             style={{backgroundColor: props.row.color ?? ""}}>
                        </div>
                        <div>{props.row.name}</div>
                        </div>
                )}
            }
        ];
    }

    /**
     * Builds items to be displayed in mounter/team calendar.
     *
     * @param startDate Start date of the calendar.
     * @param endDate End date of the calendar.
     * @param tasks tasks to process.
     * @param allMounters all existing mounters.
     * @param selectedMounters selected mounters.
     * @param selectedTeamIds selected {@see Team}s' ids.
     * @param selectedItemsIds ids of the selected {@see tasks}.
     */
    public static buildItems(
        startDate: Date,
        endDate: Date,
        tasks: WorkOrderModel[],
        allMounters: User[],
        selectedMounters: User[],
        // selectedTeamIds: string[],
        selectedItemsIds: ITimelineSelectedItemIds[],
        snapSeconds: number,
    ): CalendarItemData {

        const mountersSelected: boolean = (selectedMounters.length != null && selectedMounters.length > 0);
        // const teamsSelected: boolean = (selectedTeamIds.length != null && selectedTeamIds.length > 0);
        // const teamsSelected: boolean = false;
        const mounters: User[] = (mountersSelected)
             ? selectedMounters
        //     : (teamsSelected)
        //         ? this.getMountersByTeamIds(allMounters, [])
                 : allMounters;

        const mountersById: Record<string, Readonly<User>> = {};

        // Calendar rows
        const rows: TimelineRow[] =
            this.recreateTeamRows(mounters, mountersById)
                //.concat(this.recreateNoTeamRow())
                .concat(this.recreateNonTeamRows(mounters, mountersById))
                .concat(this.recreateUnassignedRow(mountersById));

        // Header rows
        const rowHeaderColumns: TimelineRowHeaderColumn[] = this.rowHeaderColumns;

        interface PauseOverlayProps {
            timelineItemProps: ITimelineItemRendererProps<TimelineRow, TimelineItem>;
            shouldRender: boolean;
        }

        const PauseOverlay: React.FC<PauseOverlayProps> = ({ shouldRender, timelineItemProps }) => {
            if (!shouldRender) return null;

            const item: Readonly<TimelineItem> = timelineItemProps.item;
            const pixelsPerMillisecond: number = timelineItemProps.pixelsPerMillisecond;
            const pauseStart: Date = item.task.pauseStartDate!;
            const pauseEnd: Date = item.task.pauseEndDate ?? item.end;
            const millisecondsFromStart: number = pauseStart!.valueOf() - item.start.valueOf();
            const pauseDuration: number = pauseEnd.valueOf() - pauseStart.valueOf();
            const width: number = Math.round(pauseDuration * pixelsPerMillisecond);
            const left: number = Math.round(millisecondsFromStart * pixelsPerMillisecond);

            const className: string = Utility.css(item.className, styles.pauseOverlay);

            return (
                <div className={className} 
                     style={{left, width}}
                />
            );
        };

        const CustomItemRenderer = (props: ITimelineItemRendererProps<TimelineRow, TimelineItem>): ReactElement => {
            const left: number = Math.round(props.millisecondsFromStart * props.pixelsPerMillisecond);
            const itemPaddingLeft : number = (left < 0) ? -left : 0;
            
            const shouldRenderPauseOverlay : boolean = props.item.task.pauseStartDate != null;
            
            return (
                <div className={props.defaultClassItemName} style={props.item.style}>
                    <span style={{paddingLeft: itemPaddingLeft}}>{props.item.text}</span>
                    <PauseOverlay shouldRender={shouldRenderPauseOverlay} timelineItemProps={props}/>
                </div>
            );
        }

        // Calendar items (tasks)
        const itemsById: Record<string, Readonly<TimelineItem>> = {};
        const items: Readonly<TimelineItem>[] = tasks.map((task) => {
            const taskId: string = task.id;

            const rowIds: string[] = (task.mountersIds.length > 0)
                ? task.mountersIds.where(mounterId => (!!mountersById[mounterId]))
                : [CalendarItemBuilder.unassignedRow.id];

            const itemTimespan: [Date, Date] = CalendarItemBuilder.getTaskTimespan(task, snapSeconds);

            const item: TimelineItem = {
                id: taskId,
                rowIds,
                task,
                start: itemTimespan[0],
                end: itemTimespan[1],
                text: `${task.owner?.contractName} ${task.owner?.name}`,
                className: Utility.css(styles.calendarItem, CalendarItemBuilder.getWorkOrderStatusClass(task)),
                customRenderer: CustomItemRenderer
            };

            itemsById[taskId] = item;

            return item;
        });

        const updatedSelectedItemsIds: ITimelineSelectedItemIds[] = this.filterSelectedItemsIds(
            startDate,
            endDate,
            selectedItemsIds,
            itemsById,
            mountersById
        );

        return {
            rows: rows,
            items: items,
            rowHeaderColumns: rowHeaderColumns,
            selectedItemsIds: updatedSelectedItemsIds,
        };
    }

    /**
     * Creates rows where each team has a "header" row and mounter rows below it.
     * @example:
     *     Team Awesome
     *     John the mounter
     *     Mike the mounter
     *     Team Less Awesome
     *     Zorro the Mounter
     *
     * Adds mounters to {@see mountersById}.
     */
    private static recreateTeamRows(_: User[], __: Record<string, Readonly<User>>): TimelineRow[] {
        const rows: TimelineRow[] = [];
        return rows;
        //
        // // Teams
        // const mountersWithTeams: TaskMounter[] = mounters
        //     .filter(mounter => (mounter.user.team))
        //     .sort((a: TaskMounter, b: TaskMounter) => a.user.team!.name.localeCompare(b.user.team!.name));
        // const teamGroups: TaskMounter[][] = mountersWithTeams.groupBy(mounter => mounter.user.team!.id);
        // teamGroups.forEach((group) => {
        //     // Add team row
        //     const team = group[0].user.team!;
        //     rows.push({
        //         mounter: null!,
        //         id: team.id,
        //         name: team.name,
        //         isTeam: true,
        //         belongsToTeam: false,
        //         color: team.color,
        //     })
        //
        //     // Add team members
        //     const teamMounters: TimelineRow[] = group.map(mounter => {
        //         const mounterId: string = mounter.user.id;
        //         mountersById[mounterId] = mounter;
        //
        //         return {
        //             mounter,
        //             id: mounterId,
        //             name: TransformProvider.userToString(mounter.user),
        //             isTeam: false,
        //             belongsToTeam: true,
        //             color: mounter.user.team!.color,
        //         };
        //     });
        //
        //     rows.push(... teamMounters);
        // });
        //
        // return rows;
    }

    /**
     * Creates a row for each mounter who doesn't belong to any team.
     * @example:
     *     John the mounter
     *     Mike the mounter
     *     Zorro the Mounter
     *
     * Adds mounters to {@see mountersById}.
     */
    private static recreateNonTeamRows(mounters: User[], mountersById: Record<string, Readonly<User>>): TimelineRow[] {
        //const mountersWithoutTeams = mounters.filter(mounter => (!mounter.user.team));
        const mountersWithoutTeams = mounters;
        const mounterRows: TimelineRow[] = mountersWithoutTeams.map((mounter) => {
            const mounterId: string = mounter.id;
            mountersById[mounterId] = mounter;

            return {
                mounter,
                id: mounterId,
                name: TransformProvider.userToCalendarName(mounter),
                isTeam: false,
                belongsToTeam: false,
                color: null,
            };
        });

        return mounterRows;
    }

    /**
     * Creates a row for unassigned tasks. All unassigned tasks are displayed under it.
     * @example:
     *     Unassigned
     *
     * Adds unassigned "mounter" to {@see mountersById}.
     */
    private static recreateUnassignedRow(mountersById: Record<string, Readonly<User>>): TimelineRow {
        const unassignedRow: Readonly<TimelineRow> = CalendarItemBuilder.unassignedRow;
        mountersById[unassignedRow.id] = {} as User;

        return unassignedRow;
    }

    /**
     * Filter away selections which are not visible.
     */
    private static filterSelectedItemsIds(
        startDate: Date,
        endDate: Date,
        selectedItemsIds: ITimelineSelectedItemIds[],
        itemsById: Record<string, Readonly<TimelineItem>>,
        mountersById: Record<string, Readonly<User>>
    ): ITimelineSelectedItemIds[] {

        return selectedItemsIds
            .map(selectedItemId => {
                const item: Readonly<TimelineItem> = itemsById[selectedItemId.itemId];

                const itemNotVisible: boolean = (!item) ||
                    (item.start > endDate) ||
                    (item.end < startDate);

                if (itemNotVisible) {
                    return null;
                }

                const newSelectedItemRowIds: string[] = selectedItemId.rowIds.filter(rowId => {
                    const mounter: Readonly<User> = mountersById[rowId];

                    // (Selected on "unassigned" row AND task has no mounters) OR (mounter row exists AND task has the mounter assigned)
                    return (
                        (rowId === this.unassignedRow.id) &&
                        (item.task.mountersIds.length <= 0)
                    ) ||
                    (
                        (!!mounter) &&
                        (item.task.mountersIds.includes(mounter.id))
                    );
                });

                if (newSelectedItemRowIds.length <= 0) {
                    // Item is not selected on any visible rows
                    return null;
                }

                const newSelectedItemsIds: ITimelineSelectedItemIds = {
                    itemId: selectedItemId.itemId,
                    rowIds: newSelectedItemRowIds,
                };

                return newSelectedItemsIds;
            })
            .filter(selectedItemIds => (!!selectedItemIds)) as ITimelineSelectedItemIds[];
    }

    /**
     * Returns mounters who belong to any of the given teams.
     */
    // private static getMountersByTeamIds(mounters: User[], teamIds: string[]): User[] {
    //     return mounters.filter(mounter =>
    //         mounter.user.team &&
    //         teamIds.includes(mounter.user.team!.id)
    //     );
    // }
    //
    /**
     * @returns a timespan for a task on the timeline. First index is the start and second the end date.
     * Timespan is always at least snapSeconds of the timeline.
     */
    private static getTaskTimespan(task: WorkOrderModel, snapSeconds: number): [Date, Date] {
        const today: Date = Utility.today();
        let timespan: [Date, Date];

        function max(date1: Date | null, date2: Date | null): Date {
            if (date1 === null) {
                if (date2 === null) {
                    throw new Error("Need at least one non-null date.")
                }

                return date2;
            }

            if (date2 === null) {
                return date1;
            }

            return (date1 > date2) ? date1 : date2;
        }

        // Not started
        if (task.activationDate === null) {
            // Draw one day long task for today (so visible for users of the timeline)
            timespan = [today, today.addDays(1)];
        }
        // Completed
        else if (task.completionDate !== null) {
            // Draw the task as it is
            timespan = [task.activationDate, task.completionDate];
        }
        // Started in the past
        else if (task.activationDate < today) {
            // Draw end at least until today (so visible for users of the timeline)
            timespan = [task.activationDate, max(task.plannedCompletionDate, today.addDays(1))];
        }
        // To be started
        else if (task.activationDate >= today) {
            // Draw end using plannedEnd(if exist) or one day after start
            timespan = [task.activationDate, ((task.plannedCompletionDate) ? task.plannedCompletionDate : task.activationDate.addDays(1))];
        }
        else {
            throw new Error(
                "Not supported combination (start, planned end, actual end):\n" +
                `${task.activationDate}\n${task.plannedCompletionDate}\n${task.completionDate}`);
        }

        const duration: number = timespan[1].valueOf() - timespan[0].valueOf();
        const snapTime: number = 1000 * snapSeconds;
        if (duration < snapTime) {
            // Add minimum duration (the same as snapSeconds in Timeline).
            // Makes it look nicer and prevents items with zero duration to be rendered on top of each other.
            timespan[1] = timespan[1].addMilliseconds(snapTime);
        }

        return timespan;
    }

    private static getWorkOrderStatusClass(workOrder: WorkOrderModel): string {
        const status: TaskStatusFilter = WorkOrderModel.getCurrentStatusAsTaskStatusFilter(workOrder);

        switch (status) {
            case TaskStatusFilter.Unscheduled:          return styles.unscheduled;
            case TaskStatusFilter.Upcoming:             return styles.upcoming;
            case TaskStatusFilter.InProgress:           return styles.inProgress;
            case TaskStatusFilter.Completed:            return styles.completed;
            case TaskStatusFilter.SentToCustomer:       return styles.sentToCustomer;
            case TaskStatusFilter.ApprovedByCustomer:   return styles.approvedByCustomer;
            case TaskStatusFilter.DeclinedByCustomer:   return styles.declinedByCustomer;
            case TaskStatusFilter.ReadyForInvoicing:    return styles.readyForInvoicing;
            case TaskStatusFilter.Invoiced:             return styles.invoiced;
            default:                                    throw new Error(`Unsupported status ${status}`);
        }
    }
}