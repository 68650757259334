import {IBaseComponent} from "@renta-apps/athenaeum-react-common";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";
import Product from "@/models/server/Product";
import Category from "@/models/server/Category";
import SetFavoriteRequest from "@/models/server/requests/SetFavoriteRequest";
import SaveProductResponse from "@/models/server/responses/SaveProductResponse";
import SaveCategoryResponse from "@/models/server/responses/SaveCategoryResponse";
import DeleteProductResponse from "@/models/server/responses/DeleteProductResponse";
import WorkOrderExtraCharge from "@/models/server/WorkOrderExtraCharge";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";
import Localizer from "@/localization/Localizer";
import {CatalogType} from "@/models/Enums";

export interface ICatalogDataProvider {

    // TODO: remove addedEquipment related methods (UI state handling belongs to React components)

    /**
     * Return added {@link WorkOrderEquipment} which fulfill the search criteria.
     */
    getAddedEquipmentAsync(sender: IBaseComponent, search?: string | null): Promise<WorkOrderEquipment[]>;

    getAddedExtraChargesAsync(sender: IBaseComponent): Promise<WorkOrderExtraCharge[]>;

    getAddedRentalEquipmentsAsync(sender: IBaseComponent): Promise<WorkOrderRentalItemModel[]>;

    /**
     * Add a new {@link WorkOrderEquipment} to customEquipment, if one does not already exist.
     */
    addCustomEquipmentAsync(equipment: WorkOrderEquipment): Promise<void>;

    /**
     * Add a new {@link WorkOrderExtraCharge} to extraCharges
     */
    addExtraChargeAsync(extraCharge: WorkOrderExtraCharge): Promise<void>;

    /**
     * Add a new {@link WorkOrderRentalItemModel} to rentalEquipments
     */
    addRentalEquipmentAsync(rentalEquipment: WorkOrderRentalItemModel): Promise<void>;

    /**
     * Remove a {@link WorkOrderRentalItemModel} from rentalEquipments
     */
    removeRentalEquipmentAsync(rentalEquipment: WorkOrderRentalItemModel): Promise<void>;


    /**
     * Add a new {@link WorkOrderEquipment} to equipments
     */
    addEquipmentAsync(equipment: WorkOrderEquipment): Promise<void>;

    /**
     * Get all {@link Category}s with the specified type {@link CatalogType}.
     */
    getAllCategoriesAsync(sender: IBaseComponent, catalogType: CatalogType): Promise<Category[]>;

    /**
     * Get all {@link Category}s with the specified type {@link CatalogType} and parent {@link Category}.
     * Returns root categories if {@link categoryId} is null.
     */
    getCategoriesAsync(sender: IBaseComponent, catalogType: CatalogType, categoryId: string | null): Promise<Category[]>;

    /**
     * Save a new {@link Category} to the database.
     * Requires manager-privileges.
     */
    createCategoryAsync(sender: IBaseComponent, newCategory: Category): Promise<SaveCategoryResponse>;

    /**
     * Save a modified {@link Category} to the database.
     * Requires manager-privileges.
     */
    editCategoryAsync(sender: IBaseComponent, editedCategory: Category): Promise<SaveCategoryResponse>;

    /**
     * Remove an existing {@link Category} from the database.
     * Requires manager-privileges.
     */
    removeCategoryAsync(sender: IBaseComponent, categoryId: string): Promise<void>;


    /**
     * Get all {@link Product}s which belong to the specified {@link Category} and pass the filter criteria.
     */
    getProductsAsync(sender: IBaseComponent, catalogType: CatalogType): Promise<Product[]>;

    /**
     * Save a new {@link Product} to the database.
     * Requires manager-privileges.
     */
    createProductAsync(sender: IBaseComponent, newProduct: Product): Promise<SaveProductResponse>;

    /**
     * Save a modified {@link Product} to the database.
     * Requires manager-privileges.
     */
    editProductAsync(sender: IBaseComponent, editedProduct: Product): Promise<SaveProductResponse>;

    /**
     * Remove an existing {@link Product} from the database.
     * Requires manager-privileges.
     */
    removeProductAsync(sender: IBaseComponent, productId: string): Promise<DeleteProductResponse>;

    /**
     * Restore a marked as deleted {@link Product} from the database.
     * Requires manager-privileges.
     */
    restoreProductAsync(sender: IBaseComponent, productId: string): Promise<Product>;


    /**
     * Get all {@link WorkOrderEquipment}s which belong to the specified {@link Category} and pass the filter criteria.
     */
    getEquipmentAsync(sender: IBaseComponent, catalogType: CatalogType, showDeleted: boolean, categoryId?: string | null, search?: string | null): Promise<WorkOrderEquipment[]>;


    /**
     * Set a {@link Product} as a user favorite.
     */
    setFavoriteAsync(sender: IBaseComponent, productId: string, favorite: boolean): Promise<void>;


    isCategoriesOrProductsDataProvider: true;
}

export default class CatalogDataProvider implements ICatalogDataProvider {
    protected _products: Product[] = [];
    protected _categories: Category[] = [];
    protected readonly _equipment: WorkOrderEquipment[] = [];
    protected readonly _extraCharges: WorkOrderExtraCharge[] = [];
    protected readonly _rentalEquipments: WorkOrderRentalItemModel[] = [];

    private readonly _addedCustomEquipment: WorkOrderEquipment[] = [];
    private readonly _favorite: Category = new Category("favorite", Localizer.rentaTasksAddEquipmentAddEquipmentDataProviderCategoryFavorite, "far star");
    private readonly _includeFavoriteCategory: boolean;

    private _initialized: boolean = false;

    protected includeDeleted(): boolean {
        return false;
    }

    // TODO: there is a risk that _equipment is filled twice or more.

    protected async initializeAsync(sender: IBaseComponent): Promise<void> {
        if (!this._initialized) {
            const includeDeleted: boolean = this.includeDeleted();

            this._products = await sender.postAsync("api/rentaTasks/getAllProducts", includeDeleted);
            this._categories = await sender.postAsync("api/rentaTasks/getAllCategories", includeDeleted);
            this.initializeEquipment();
            this._initialized = true;
        }
    }
    
    private getCatalogCategories(type: CatalogType): Category[] {
        return this._categories.filter(category => category.catalogType === type);
    }

    private initializeEquipment(): void {
        // "clone" array to preserve references to initial equipment
        const initialEquipment: WorkOrderEquipment[] = this._equipment.map((equipment: WorkOrderEquipment) => equipment);

        // create equipment for all products, unless if in initial equipment
        this._products.forEach((product: Product) => {
            const initialEquipmentItem: WorkOrderEquipment | undefined = initialEquipment.find((equipment: WorkOrderEquipment) => equipment.productId === product.id);
            if (initialEquipmentItem) {
                initialEquipmentItem.product = product;
            }
            else {
                const equipment = CatalogDataProvider.productToEquipment(product);
                this._equipment.push(equipment);
            }
        });
    }

    protected static productToEquipment(product: Product): WorkOrderEquipment {
        const equipment = new WorkOrderEquipment();

        equipment.product = product;
        equipment.productId = product.id;
        equipment.price = product.price;

        return equipment;
    }

    constructor(addedEquipment: WorkOrderEquipment[], addedExtraCharges: WorkOrderExtraCharge[], addedRentalEquipments: WorkOrderRentalItemModel[], includeFavoriteCategory: boolean) {
        this._equipment = addedEquipment;
        this._extraCharges = addedExtraCharges;
        this._rentalEquipments = addedRentalEquipments;
        this._includeFavoriteCategory = includeFavoriteCategory;
    }

    private get addedEquipment(): WorkOrderEquipment[] {
        let addedEquipment: WorkOrderEquipment[] = [...this._equipment, ...this._addedCustomEquipment];
        addedEquipment = addedEquipment.where((equipment: WorkOrderEquipment) => (equipment.amount > 0) || (!!equipment.id));
        return addedEquipment;
    }

    public async addCustomEquipmentAsync(equipment: WorkOrderEquipment): Promise<void> {
        const exists: boolean = this._addedCustomEquipment.some(item => item.name === equipment.name);
        if (!exists) {
            this._addedCustomEquipment.push(equipment);
        }
    }

    public async getAddedEquipmentAsync(sender: IBaseComponent, search?: string | null): Promise<WorkOrderEquipment[]> {
        await this.initializeAsync(sender);
        let addedEquipment: WorkOrderEquipment[] = this.addedEquipment;

        if (search) {
            search = search.toLowerCase();
            addedEquipment = addedEquipment.where(item => this.isMatch(item, search!));
        }

        return addedEquipment.sort((a: WorkOrderEquipment, b: WorkOrderEquipment) => a.name?.localeCompare(b.name || "") || 0);
    }

    // Extra Charges

    private get addedExtraCharges(): WorkOrderExtraCharge[] {
        let addedExtraCharges: WorkOrderExtraCharge[] = [...this._extraCharges];
        addedExtraCharges = addedExtraCharges.where((extraCharge: WorkOrderExtraCharge) => (extraCharge.amount > 0) || (!!extraCharge.id));
        return addedExtraCharges;
    }

    private get addedRentalEquipments(): WorkOrderRentalItemModel[] {
        return [...this._rentalEquipments];
    }

    // Add an extra charge to task
    public async addExtraChargeAsync(extraCharge: WorkOrderExtraCharge): Promise<void> {
        this._extraCharges.push(extraCharge);
    }

    public async addRentalEquipmentAsync(rentalEquipment: WorkOrderRentalItemModel): Promise<void> {
        this._rentalEquipments.push(rentalEquipment);
    }

    public async removeRentalEquipmentAsync(rentalEquipment: WorkOrderRentalItemModel): Promise<void> {
        const index: number = this._rentalEquipments.indexOf(rentalEquipment);
        if (index >= 0) {
            this._rentalEquipments.removeAt(index);
        }
    }

    public async addEquipmentAsync(equipment: WorkOrderEquipment): Promise<void> {
        this._equipment.push(equipment)
    }

    public async getAddedExtraChargesAsync(sender: IBaseComponent): Promise<WorkOrderExtraCharge[]> {
        await this.initializeAsync(sender);
        return this.addedExtraCharges;
    }

    public async getAddedRentalEquipmentsAsync(sender: IBaseComponent): Promise<WorkOrderRentalItemModel[]> {
        await this.initializeAsync(sender);
        return this.addedRentalEquipments;
    }

    public isMatch(item: WorkOrderEquipment, search: string): boolean {
        return (!!item.product?.name?.toLowerCase().includes(search)) ||
            ((item.product != null) && (Product.isMatch(item.product!, search))) ||
            (!!item.description?.toLowerCase().includes(search)) ||
            (!!item.name?.toLowerCase().includes(search));
    }

    // Categories

    public async getAllCategoriesAsync(sender: IBaseComponent, catalogType: CatalogType): Promise<Category[]> {
        await this.initializeAsync(sender);

        return this.getCatalogCategories(catalogType)
            .sort((a: Category, b: Category) => a.name.localeCompare(b.name));
    }

    public async getCategoriesAsync(sender: IBaseComponent, catalogType: CatalogType, parentCategoryId: string | null): Promise<Category[]> {
        await this.initializeAsync(sender);

        const categories: Category[] = this.getCatalogCategories(catalogType)
            .filter(category => category.parentId === parentCategoryId)
            .sort((a: Category, b: Category) => a.name.localeCompare(b.name));

        return ((parentCategoryId === null) && (this._includeFavoriteCategory))
            ? [this._favorite, ...categories]
            : categories;
    }

    public async createCategoryAsync(_: IBaseComponent, __: Category): Promise<SaveCategoryResponse> {
        throw new Error("Not authorized to create categories");
    }

    public async editCategoryAsync(_: IBaseComponent, __: Category): Promise<SaveCategoryResponse> {
        throw new Error("Not authorized to edit categories");
    }

    public async removeCategoryAsync(_: IBaseComponent, __: string): Promise<void> {
        throw new Error("Not authorized to remove categories");
    }

    // Products

    public async getProductsAsync(sender: IBaseComponent, catalogType: CatalogType): Promise<Product[]> {
        await this.initializeAsync(sender);

        let products: Product[] = this._products.filter(product => product.category?.catalogType === catalogType);
        return products.sort((a: Product, b: Product) => a.name.localeCompare(b.name));
    }

    public async createProductAsync(_: IBaseComponent, __: Product): Promise<SaveProductResponse> {
        throw new Error("Not authorized to create products");
    }

    public async editProductAsync(_: IBaseComponent, __: Product): Promise<SaveProductResponse> {
        throw new Error("Not authorized to edit products");
    }

    public async removeProductAsync(_: IBaseComponent, __: string): Promise<DeleteProductResponse> {
        throw new Error("Not authorized to remove products");
    }

    public async restoreProductAsync(_: IBaseComponent, __: string): Promise<Product> {
        throw new Error("Not authorized to restore products");
    }


    // Equipment

    public async getEquipmentAsync(
        sender: IBaseComponent,
        catalogType: CatalogType,
        showDeleted: boolean,
        categoryId?: string | null,
        search?: string | null): Promise<WorkOrderEquipment[]> {

        await this.initializeAsync(sender);

        let equipment: WorkOrderEquipment[] = this._equipment
            .filter(equipment => equipment.product?.category?.catalogType === catalogType);

        if (categoryId) {
            equipment = (categoryId === this._favorite.id)
                ? equipment.where(equipmentItem => equipmentItem.product?.favorite === true)
                : equipment.where(equipmentItem => equipmentItem.product?.category?.id === categoryId);
        }

        if (search) {
            search = search.toLowerCase();
            equipment = equipment.where(equipmentItem => this.isMatch(equipmentItem, search!));
        }

        if (!showDeleted) {
            equipment = equipment.where(equipment => (equipment.product != null) && (!equipment.product.deleted));
        }

        return equipment.sort((a: WorkOrderEquipment, b: WorkOrderEquipment) => a.product?.name.localeCompare(b.product?.name || "") || 0);
    }


    // Favorites

    public async setFavoriteAsync(sender: IBaseComponent, productId: string, favorite: boolean): Promise<void> {
        await this.initializeAsync(sender);

        const product: Product | null = this._products.find(product => product.id === productId) || null;
        if (product) {
            product.favorite = favorite;

            const request = new SetFavoriteRequest(productId, favorite);
            // do not await, we do not need result
            // noinspection ES6MissingAwait
            sender.postAsync("api/rentaTasks/setFavorite", request);
        }
    }


    // Other

    public get isCategoriesOrProductsDataProvider(): true {
        return true;
    }
}