import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {AttachmentType} from "@/models/Enums";

export default class SaveWorkOrderAttachmentRequest {
    public id: string | null = null;
    
    public workOrderId: string = "";

    public public: boolean = false;
    
    public file: FileModel | null = null;

    public type: AttachmentType = AttachmentType.WorkOrderAttachment;

    public readonly isSaveWorkOrderAttachmentRequest: boolean = true;
}