import React, {ReactNode} from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {Icon} from "@renta-apps/athenaeum-react-components";

import styles from "@/components/InlineTooltip/InlineTooltip.module.scss";

interface IInlineTooltipProps {
    /**
     * Text displayed in the tooltip.
     */
    text: string;
    icon?: JSX.Element;
    className?: string;
    children?: ReactNode;
}

interface IInlineTooltipState {
}

export default class InlineTooltip extends BaseComponent<IInlineTooltipProps, IInlineTooltipState> {

    private configure(dispose: boolean = false): void {

        if (dispose) {
            this.node.tooltip("dispose");
        }
        else {
            this.node.tooltip({trigger: "click hover"});
        }
    }

    public async componentDidMount(): Promise<void> {
        await super.componentDidMount();
        this.configure();
    }

    public async componentWillReceiveProps(nextProps: IInlineTooltipProps): Promise<void> {
        await super.componentWillReceiveProps(nextProps);
        this.node.tooltip('hide').attr('data-original-title', nextProps.text);
    }

    public async componentWillUnmount(): Promise<void> {
        await super.componentWillUnmount();
        this.configure(true);
    }

    protected get node(): JQuery {
        return this.JQuery(`#${this.id} > a`);
    }

    public async onGlobalClick(e: React.SyntheticEvent): Promise<void> {

        const target = e.target as Node;

        const outside: boolean = Utility.clickedOutside(target, this.id);

        if (outside) {
            this.node.tooltip("hide");
        }
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id}
                 className={this.css(styles.tooltip, this.props.className)}
            >
                <a data-toggle="tooltip"
                   data-container="body"
                   title={this.props.text}
                >

                    {
                        (this.props.icon) ? this.props.icon : (
                            <Icon name={"far question-circle"}/>
                        )
                    }
                </a>
            </div>
        );
    }
}