import React from "react";
import RentaTasksWizardPage from "../RentaTasksWizardPage";
import {TitleWidget} from "@renta-apps/athenaeum-react-components";
import WorkOrderDetails from "@/pages/RentaTasks/PreviewDetails/WorkOrderDetails/WorkOrderDetails";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "../../../localization/Localizer";
import WizardContext from "@/pages/RentaTasks/Models/WizardContext";

export interface IPreviewDetailsProps {
}

interface IPreviewDetailsState {
}

export default class PreviewDetails extends RentaTasksWizardPage<IPreviewDetailsProps, IPreviewDetailsState> {

    state: IPreviewDetailsState = {
    };

    private get wizardContext(): WizardContext {
        return RentaTasksController.wizardContext!;
    }
    public hideNext(): boolean | null {
        if (this.isLastStep) {
            return true;
        }
        return null;
    }

    public getManual(): string {
        return Localizer.rentaTasksPreviewDetailsGetManual;
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <TitleWidget wide
                             model={this.title}
                />

                <WorkOrderDetails workOrder={this.wizardContext.workOrder!}
                                  equipments={this.wizardContext.equipment}
                                  extraCharges={this.wizardContext.extraCharges}
                                  rentalEquipments={this.wizardContext.rentalEquipments}
                />

            </React.Fragment>
        );
    }
}