import React from "react";
import {GridModel, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import NotificationModel from "@/models/server/notification/NotificationModel";
import WorkOrderStatusModel from "@/models/server/WorkOrderStatusModel";
import NotificationsPanel from "@/pages/Notifications/NotificationsPanel/NotificationsPanel";
import ToolbarModel from "@/pages/Notifications/Toolbar/ToolbarModel";
import Toolbar from "@/pages/Notifications/Toolbar/Toolbar";
import {NotificationType} from "@/models/Enums";
import Localizer from "@/localization/Localizer";

import styles from "./WorkOrdersPendingApproval.module.scss";

interface IWorkOrdersPendingApprovalProps extends BasePageParameters {
    
}

interface IWorkOrdersPendingApprovalState {
    notification: NotificationModel;
    filters: ToolbarModel;
}

export default class WorkOrdersPendingApproval extends AuthorizedPage<IWorkOrdersPendingApprovalProps, IWorkOrdersPendingApprovalState> {
    state: IWorkOrdersPendingApprovalState = {
        notification: new NotificationModel(),
        filters: new ToolbarModel()
    };

    private readonly _workOrdersStatusesGridRef: React.RefObject<NotificationsPanel> = React.createRef();

    private get title(): string {
        return (this.notification != null && this.notification.occurrence)
            ? Localizer.get(Localizer.notificationTitle, this.notification.occurrence.format("D"))
            : Localizer.notificationTitleLoading;
    }

    public get filters(): ToolbarModel {
        return this.state.filters;
    }

    public get notificationId(): string {
        return this.routeId!;
    }

    public get notification(): NotificationModel {
        return this.state.notification;
    }

    private get notificationsPanel(): NotificationsPanel {
        return this._workOrdersStatusesGridRef.current!;
    }

    private get workOrdersStatusesGrid(): GridModel<WorkOrderStatusModel> {
        return this.notificationsPanel.workOrdersStatusesGrid;
    }

    public async onFiltersChange(filters: ToolbarModel): Promise<void> {

        await this.setSpinnerAsync(true);

        await this.setState({filters});

        await this.workOrdersStatusesGrid.reloadAsync();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const notification: NotificationModel = await this.postAsync("api/notification/getNotification", this.notificationId);

        await this.setState({notification});
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.workOrdersPendingApproval}>
                <PageHeader title={this.title}
                    subtitle={Localizer.notificationWorkOrdersPendingApprovalSubtitle}
                />

                <PageRow>
                    <div>
                        <Toolbar notificationId={this.notificationId}
                                 notificationType={NotificationType.WorkOrdersPendingApproval}
                                 model={this.filters}
                                 onChange={async (model) => this.onFiltersChange(model)}
                        />

                        <NotificationsPanel ref={this._workOrdersStatusesGridRef}
                                            notificationId={this.notificationId}
                                            notificationType={NotificationType.WorkOrdersPendingApproval}
                                            filters={this.state.filters}
                        />
                    </div>
                </PageRow>

            </PageContainer>
        );
    }
}