export enum LoginResultStatus {
    Success = 0,

    NotFound = 1,

    Deleted = 2,

    Locked = 3,

    /**
     * Token security stamp does not correspond to user security stamp
     */
    TokenInvalidSecurityStamp = 4,

    /**
     * Token already used
     */
    TokenUsed = 5,

    /**
     * Token invalid or expired
     */
    TokenInvalidOrExpired = 6
}

export enum LinkTarget {
    /**
     * Load in a new window
     */
    Blank = "_blank",

    /**
     * Load in the same frame as it was clicked
     */
    Self = "_self"
}

export enum BaseInputType {
    Text = "text",

    Email = "email",

    Password = "password",

    Number = "number",

    Currency = "currency",

    TextArea = "textarea",

    Dropdown = "dropdown",

    Checkbox = "checkbox",

    File = "file"
}

export enum InputValidationRule {
    /**
     * Default empty matcher
     */
    Default = "",

    /**
     * Value must be a proper email form
     */

    Email = ".+@.+\..+",

    /**
     * Value must contain at least 1 lowercase, 1 uppercase character and 1 special character
     * Value must be 8 characters or longer
     */
    Password = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9 :])(?=.{8,})",

    /**
     * General phone number validation for Finland, Estonia and Sweden.
     * It will pass old and new format numbers.
     * Between numbers space or dash can be used ones.
     * Number has to start +358 or 00358 or +372 or +41 or 0 are code can be 2 or 3 digit.
     */
    Phone = "^((([\\+][\\s]{0,1})|([0]{2}[\\s-]{0,1}))((358|372|41)[\\s-]{0,1})|([0]{1}))(([1-9]{1}[0-9]{0,1})([\\s-]{0,1})([0-9]{2,4})([\\s-]{0,1})([0-9]{2,4})([\\s-]{0,1}))([0-9]{0,3}){1}$"
}

export enum PasswordValidationRule {
    LowerCaseCharacter = "^(?=.*[a-z])",

    UpperCaseCharacter = "^(?=.*[A-Z])",

    NumberCharacter = "^(?=.*[0-9])",

    SpecialCharacter = "^(?=.*[^a-zA-Z0-9 :])"
}

export enum PasswordValidationError {
    /**
     * One lowercase ('a'-'z') characters.
     */
    Lowercase = 0,

    /**
     * One uppercase ('A'-'Z') characters.
     */
    Uppercase = 1,

    /**
     * One non alphanumeric characters.
     */
    NonAlphabetic = 2,

    /**
     * Min 6 and at max 100 characters long.
     */
    Length = 3
}

export enum WorkDayState {
    Normal = 0,

    /**
     * Sairas
     */
    SickLeave = 1,

    /**
     * Loma
     */
    Vacation = 2,

    /**
     * Vko lepo
     */
    WeeklyRest = 3,

    /**
     * Maksullinen vapaapäivä
     */
    PaidDayOff = 4,

    /**
     * Pekkanen
     */
    FlexHours = 5,
}

export enum DropdownSchema {
    Default = 0,

    Widget = 1,

    Transparent = 2
}

export enum ConstructionSiteStatus {
    Active = 0,

    Inactive = 1,

    Closed = 2
}

export enum CommunicationLanguage {
    Finnish = 0,

    English = 1,

    Swedish = 2
}

export enum WorkOrderStatus {
    Created = 0,

    InProgress = 1,

    Completed = 2,

    SentToCustomer = 3,

    ApprovedByCustomer = 4,

    DeclinedByCustomer = 5,

    ReadyForInvoicing = 6,

    Invoiced = 7,

    Deleted = 8,

    Unknown = 9
}

export enum ConstructionSiteWorkOrderStatus {

    //Active tasks
    HasActiveTasks = 0,

    //Tasks completed, not sent workReports
    WorkReportNotSent = 1,

    //WorkReport sent, not approved by customer
    WorkReportNotApprovedByCustomer = 2,

    //Tasks completed, not approved by manager
    TaskNotApprovedByManager = 3,

    //InvoiceRows not approved by manager
    InvoiceRowsNotApprovedNyManager = 4,

    //InvoiceRows approved, not sent to Pagero (not created actual invoice)
    InvoiceNotCreated = 5
}

export enum UserRoleGroup {
    Admins = 0,

    Managers = 1,

    Employees = 2,

    ContactPersons = 3,

    Subcontractors = 4,
}

export enum ConstructionSiteOrWarehouseType {
    ConstructionSite = 0,

    Warehouse = 1
}

export enum TaskStatusFilter {
    Unscheduled = 0,

    InProgress = 1,

    Upcoming = 2,

    Completed = 3,

    SentToCustomer = 4,

    ApprovedByCustomer = 5,

    DeclinedByCustomer = 6,

    ReadyForInvoicing = 7,

    Invoiced = 8
}

export enum CustomerApprovalType {
    Email = 0,

    Phone = 1,

    Signature = 2
}

export enum ProductUnit {
    Piece = 0,

    Meter = 1,

    Kilometer = 2,

    Meter2 = 3,

    Liter = 4,

    Box = 5,

    Pair = 6,

    Bottle = 7,

    Can = 8,

    Bag = 9,

    Roll = 10,

    Custom = 11,
}

export enum EquipmentType {
    Product = 0,

    Custom = 1
}

export enum UserDocumentLevel {
    First = 0,

    Second = 1
}

export enum ActionType {
    Default = 0,

    /**
     * "text-success"
     */
    Create = 1,

    Edit = 2,

    /**
     * red
     */
    Delete = 3,

    /**
     * grey
     */
    Muted = 4,

    /**
     * "text-light"
     */
    Light = 5,

    /**
     * "text-secondary"
     */
    Secondary = 6,

    /**
     * cyan
     */
    Info = 7,

    Grey = 8,

    Blue = 9,
}

export enum UserSalaryAggregateType {
    Day = 0,

    Month = 1
}

export enum InvitationType {
    Invitation = 0,

    ResetPassword = 1,

    ForgotPassword = 2
}

export enum AuthType {
    Email = 0,

    Phone = 1,
}

export enum AuditTimestamp {
    CreatedAt = 0,

    ModifiedAt = 1
}

export enum SavePasswordResultStatus {
    Success = 0,

    WeakPassword = 1,

    WrongCurrent = 2
}

export enum OrganizationContractLevel {
    Operator = 0,

    Company = 1,

    SubCompany = 2
}

export enum OrganizationContractType {
    Customer = 0,

    Subcontractor = 1
}

export enum FiltersBehavior {
    SystemDefault = 0,

    UserSpecified = 1
}

export enum CalendarUnitTime {
    Week = 0,

    Month = 1,
    
    Day = 2,
}

export enum WorkOrderContactPersonType {
    Approver = 0,

    Orderer = 1,
}

export enum FormHeaderType {
    CustomerName = 0,

    CustomerNumber = 1,

    ConstructionSiteName = 2,

    ConstructionSiteNumber = 3,

    ConstructionSiteAddress = 4,

    WorkOrderManagerName = 5,

    WorkOrderManagerEmail = 6,

    WorkOrderManagerPhone = 7,

    WorkOrderOrdererName = 8,

    WorkOrderOrdererEmail = 9,

    WorkOrderOrdererPhone = 10,
}

export enum FormTimeslotType {
    /// <summary>
    /// Anytime
    /// </summary>
    Anytime = 0,

    /// <summary>
    /// Before work order is started
    /// </summary>
    Before = 1,

    /// <summary>
    /// When work order is completed
    /// </summary>
    After = 2
}

export enum CustomFormType {
    Undefined = 0,

    Audit = 1
}

export enum FormItemType {
    QuestionPictures = 0,

    Question = 1,

    Pictures = 2,

    Checks = 3,

    Options = 4,

    Resource = 5,

    Text = 6
}

export enum FormMeasuringOperator {
    Less = 0,

    LessOrEqual = 1,

    Bigger = 2,

    BiggerOrEqual = 3,

    Equal = 4,

    NotEqual = 5
}

export enum WorkOrderFormStatusFilter {
    New = 0,

    InProgress = 1,

    Processed = 2,

    Sent = 3,

    Approved = 4
}

export enum AttachmentType
{
    WorkOrderAttachment = 0,

    FormAttachment = 1,

    ConstructionSiteAttachment = 2,
    
    ExternalNotificationAttachment = 3,
}

export enum ExtraChargeTypeUnit
{
    /** Piece. */
    Pcs = 0,

    /** Square meter. */
    M2 = 1,

    /** Hour. */
    H = 2,

    /** Cubic meter. */
    M3 = 3,

    /** Meter. */
    M1 = 4,

    /** Kilometer */
    Km = 5,
}

export enum NotificationType {
    /** Notification for the managers when work orders gets approved/declined by the customer. */
    WorkOrdersProcessedApproval = 0,

    /** Notification for the managers of work orders which are pending approval by the customer. */
    WorkOrdersPendingApproval = 1,

    /**
     * Notification for the mounters when work order gets reactivated by the manager.
     * NB! Work order reactivation - is the process when the status of the work order change to {@link WorkOrderStatus.InProgress} from either
     * {@link WorkOrderStatus.Completed} or {@link WorkOrderStatus.SentToCustomer} or
     * {@link WorkOrderStatus.ApprovedByCustomer} or {@link WorkOrderStatus.DeclinedByCustomer}.
     * */
    WorkOrderReactivation = 2,

    /**
     * Notification sent to mounters when they have been assigned to a work order by a user other than themselves.
     */
    WorkOrderAssignment = 3,

    /**
     * An on-demand notification sent to the manager of a work order when a customer rejects it.
     */
    WorkOrderDeclined = 4,
}

export enum NotificationInterval {
    OnDemand = 0,

    Daily = 1,

    Weekly = 2
}

export enum NotificationMediaType {
    Email = 0,

    Sms = 1,

    EmailAndSms = 2
}

export enum NotificationStatus {
    Sent = 0,

    ContactNotFound = 1,

    Failed = 2
}

export enum DayOfWeek {
    Sunday = 0,

    Monday = 1,

    Tuesday = 2,

    Wednesday = 3,

    Thursday = 4,

    Friday = 5,

    Saturday = 6
}

export enum MounterPermission {
    CreateWorkOrder = 0,

    CompleteWorkOrder = 1,

    ProcessForm = 2,
}

export enum RentalItemActionType {
    Leave = 0,

    Return = 1
}

/**
 * Renta.Connect.TaskApp.ApiModels.ProductStatus
 */
export enum ProductStatus
{
    InRent = 0,
    InStock = 1,
    PartialReturn = 2,
    Invoiced = 3,
}

/** What kind of a business contract the work order is based and invoiced on? */
export enum WorkOrderContractType
{
    /**
     *  Invoiced normally based on resources (time, equipment, etc.) spent.
     *  Default type.
     */
    Normal = 0,

    /** Customer pays fixed price no matter how much resources (time, equipment, etc.) was spent. */
    FixedPrice = 1,

    /// <summary>
    /// </summary>
    
    /**
     * Something in warranty went wrong that needs to be fixed.
     * Exactly the same as <see cref="FixedPrice"/> but needs to be
     * separated for business reporting needs.
     */
    Warranty = 2,
}

export enum CatalogType {
    /**
     * Normal products which are sold.
     */
    SalesProduct = 0,

    /**
     * Rental _mass_ products are always available and can always be rented.
     * Normal rental items need to be available to be rented (not to be
     * confused with {@link CatalogType.SalesProduct}).
     */
    RentalMassProduct = 1,
}

/** Defines types of work logged on a work order which is invoiced. */
export enum WorkType {
    /** Normal hours logged in user salary hours. */
    NormalHours = 0,

    /** Overtime 50% hours logged in user salary hours. */
    Overtime50 = 1,

    /** Overtime 100% hours logged in user salary hours. */
    Overtime100 = 2,

    /** Mileages logged in work order distances */
    Mileages = 3,

    /** Hours logged as alarm job. */
    AlertJobHours = 4,

    /** Fixed hour's cost. */
    FixedHoursCost = 5,

    /** Total of fixed cost. */
    FixedCost = 6,
    
    /** Fixed product cost. */
    FixedProductCost = 7,

    /** Fixed transportation cost. */
    FixedTransportationCost = 8,

    /**
     * Custom products on a work order.
     * Custom products don't have a product number of their own like sales or rental products.
     */
    CustomProducts = 9,
}

/**
 * Single sign on provider.
 */
export enum SsoProvider
{
    /**
     * Azure Active Directory.
     */
    AAD = 0,
}