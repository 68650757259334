import {GeoLocation} from "@renta-apps/athenaeum-toolkit";

export default class LocationValidator {
    /** Is the given location valid and ready to be saved? */
    public static isLocationValid(location: GeoLocation | null | undefined): boolean {
        return (
            (!!location) &&
            (location.lat !== 0) &&
            (location.lon !== 0)
        );
    }
}