import React from "react";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import NewsEditor from "@/components/NewsEditor/NewsEditor";
import Localizer from "@/localization/Localizer";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import AuthorizedPage from "@/models/base/AuthorizedPage";

export interface IAdminNewsEditorParams extends BasePageParameters {
}

interface IAdminNewsEditorState {
}

export default class AdminNewsEditor extends AuthorizedPage<IAdminNewsEditorParams, IAdminNewsEditorState> {

    public state: IAdminNewsEditorState = {};

    protected get title(): string {
        return Localizer.newsEditorPageTitle;
    }

    public render() {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer>
                <PageHeader title={this.title}
                />
                <NewsEditor/>
            </PageContainer>
        );
    }
}