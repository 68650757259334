import {CustomerApprovalType} from "../../Enums";
import User from "@/models/server/User";
import BaseConcurrencyRequest from "@/models/server/requests/BaseConcurrencyRequest";

export default class ApproveWorkOrderRequest extends BaseConcurrencyRequest {

    public workOrderId: string = "";

    public invoiceReference: string | null = null;

    public approvalType: CustomerApprovalType = CustomerApprovalType.Email;

    /**
     * Raw signature data.
     *
     * @see signatureNameClarification
     */
    public signatureSrc: string | null = null;

    /** @see WorkOrderModel.signatureNameClarification */
    public signatureNameClarification: string | null = null;

    public customerApprover: User | null = null;

    public readonly isCreateWorkOrderRequest: true = true;
}