import React, {ReactNode} from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";

import styles from "./FormContainer.module.scss";

interface IFormPageRowProps {
    className?: string;
    children?: ReactNode;
}

export default class FormContainer extends BaseComponent<IFormPageRowProps> {
    render(): React.ReactNode {
        return (
            <div className={this.css(styles.formContainer, this.props.className)}>
                {this.props.children}
            </div>
        );
    }
};