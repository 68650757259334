import {ch} from "@renta-apps/athenaeum-react-common";

export async function getWithXSFRTokenAsync(path: string): Promise<Response> {
    const httpRequest = {
        url: path,
        method: "GET"
    } as RequestInit;
    const headers = new Headers();
    headers.set("Content-Type", "application/json");

    // XSRF Token
    const xsrfToken: string | null = ch.getXsrfToken();
    if (xsrfToken != null) {
        headers.set("xsrf-token", xsrfToken);
    }

    //Version
    headers.set("renta-version", ch.version);
    httpRequest.credentials = "include";

    httpRequest.headers = headers;
    return await fetch(path, httpRequest);
}
