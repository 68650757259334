
export default class GetEmployeesRequest {
    constructor(constructionSiteId: string | null = null, excludeSubcontractorEmployees: boolean = false) {
        this.constructionSiteId = constructionSiteId;
        this.excludeSubcontractorEmployees = excludeSubcontractorEmployees;
    }

    /**
     * If given will return sub contractor employees only for this site.
     * Only applied if {@link excludeSubcontractorEmployees} is false.
     */
    public constructionSiteId: string | null = null;

    public warehouseId: string | null = null;

    /** If false sub contractor employees won't be returned. */
    public excludeSubcontractorEmployees: boolean = false;

    public showDeleted: boolean = false;
}