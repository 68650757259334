import React from "react";
import {PageContainer, PageHeader, PageRow, Tab, TabContainer} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import WarehousesData from "@/pages/Warehouses/WarehousesData/WarehousesData";
import CostPoolsData from "@/pages/Warehouses/CostPoolsData/CostPoolsData";
import {PageCacheProvider} from "@renta-apps/athenaeum-react-common";

import Localizer from "../../localization/Localizer";

interface IWarehousesProps  {
}

interface IWarehousesState {
}

export default class Warehouses extends AuthorizedPage<IWarehousesProps, IWarehousesState> {

    state: IWarehousesState = {
        showDeleted: false
    };

    private readonly _warehousesDataRef: React.RefObject<WarehousesData> = React.createRef();
    private readonly _costPoolsDataRef: React.RefObject<CostPoolsData> = React.createRef();
    
    private async reloadWarehousesTabAsync(): Promise<void> {
        if (this._warehousesDataRef.current) {
            PageCacheProvider.clear();
            await this._warehousesDataRef.current!.reloadAsync();
        }
    }

    public getTitle(): string {
        return Localizer.topNavWarehouse;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>

                <PageHeader title={Localizer.warehousesPageTitle} />

                <PageRow>

                    <div className="col">

                        <TabContainer id="warehousesPageTabs" scale>

                            <Tab id={"warehousesTab"} title={Localizer.warehousesWarehousesTab}>
                            
                                <WarehousesData ref={this._warehousesDataRef} />
                            
                            </Tab>

                            <Tab id={"costPoolsTab"} title={Localizer.warehousesCostPoolsTab}>

                                <CostPoolsData ref={this._costPoolsDataRef}
                                               reloadWarehouses={async () => await this.reloadWarehousesTabAsync()}
                                />
                                
                            </Tab>

                        </TabContainer>

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}