/**
 * Updates a work order's mounters and timespan.
 */
export default class UpdateMountersAndTimesRequest {
    /** Id of the work order. */
    workOrderId: string = "";

    /** UserIds of the mounters. */
    mounterUserIds: string[] = [];

    /** Start of work order timespan. */
    activationDate: Date = new Date();

    /** Estimated end of work order timespan. */
    plannedCompletionDate: Date = new Date();

    /** The date of the pause start. */
    pauseStartDate: Date | null = null;

    /** The date of the pause end. */
    pauseEndDate: Date | null = null;
}