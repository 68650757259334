import React from "react";
import {FileModel, Utility} from "@renta-apps/athenaeum-toolkit";
import {ch} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import PageDefinitions from "@/providers/PageDefinitions";
import FormModel from "@/models/server/forms/FormModel";
import FormSummaryTable from "@/components/Form/FormSummaryTable/FormSummaryTable";
import FormItem from "@/models/server/forms/FormItem";
import TransformProvider from "@/providers/TransformProvider";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "@/localization/Localizer";
import RentaTasksFormWizardPage from "@/pages/RentaTasks/RentaTasksFormWizardPage";

import styles from "./FormPreviewPage.module.scss";
import rentaTaskStyles from "../../RentaTask.module.scss";

interface IFormPreviewPageProps {
}

interface IFormPreviewPageState {

}

export default class FormPreviewPage extends AuthorizedPage<IFormPreviewPageProps, IFormPreviewPageState> {

    private async downloadReportPdfAsync(): Promise<void> {
        const formId: string = this.form.id;
        const file: FileModel = await this.postAsync("api/rentaTasks/getFormPdf", formId);
        ch.download(file as any);
    }

    private async openStepAsync(item: FormItem): Promise<void> {
        return await RentaTasksController.openStepAsync(item.id);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    protected get form(): FormModel {
        return RentaTasksController.form!;
    }

    private get title(): string {
        return `${this.form.workOrder!.name}\n${Localizer.formPreviewPageTitleCompletedForm} ${Utility.format("{0:dd.MM.yyyy}", this.form.processedAt)}`;
    }

    public getManual(): string {
        return Localizer.formPreviewPageGetManual;
    }

    private get reporter() {
        return `${Localizer.formPreviewPageReporter}: ${TransformProvider.toString(this.form.user)}`;
    }

    private get constructionSite() {
        return `${Localizer.genericConstructionsite}: ${TransformProvider.toString(this.form.constructionSite)}`;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaTaskStyles.alert} className={this.css(rentaTaskStyles.pageContainer, styles.formPreview)}>

                <PageHeader title={this.title} className={rentaTaskStyles.leftPageHeader} />

                <PageRow className={this.css(styles.help, styles.previewPageRow)}>

                    <span className={styles.clickStepText}>{this.toMultiLines(Localizer.formPreviewPageTitle)}</span>

                    <section className={styles.inspectionInfo}>
                        {this.reporter} <br/> {this.constructionSite}
                        <Button type={ButtonType.Orange} label={Localizer.genericActionDownload} onClick={async () => await this.downloadReportPdfAsync()} />
                    </section>

                </PageRow>

                <PageRow className={styles.previewPageRow}>

                    <FormSummaryTable model={this.form}
                                      openStep={async (item) => await this.openStepAsync(item)}
                                      openMountersSignaturesStep={async() => await RentaTasksFormWizardPage.openMountersSignaturesStepAsync()}
                    />

                </PageRow>

                <PageRow className={styles.previewPageRow}>

                    <Button block type={ButtonType.Orange} label={Localizer.genericBack} route={PageDefinitions.rentaTasksWorkOrderForms(this.form.workOrder!.id)}/>

                </PageRow>

            </PageContainer>
        );
    }
}