import React from "react";
import Product from "@/models/server/Product";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {TFormat, Utility} from "@renta-apps/athenaeum-toolkit";
import {Button, ButtonType, DateInput, Dropdown, Icon, IconSize, IconStyle, NumberInput, TextAreaInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import {CatalogType, EquipmentType, ProductUnit} from "@/models/Enums";
import CatalogItem from "../CatalogItem/CatalogItem";
import EnumProvider from "@/providers/EnumProvider";

import styles from "./EquipmentItem.module.scss";
import Localizer from "@/localization/Localizer";


// TODO: expand only one at a time?


export interface IEquipmentItemProps {
    equipment: WorkOrderEquipment;
    catalogType: CatalogType;
    className?: string;
    editing?: boolean;
    expanded?: boolean;
    shoppingCart?: boolean;
    onChange?(sender: EquipmentItem, equipment: WorkOrderEquipment): Promise<void>;
    onDescriptionToggle?(sender: EquipmentItem, equipment: WorkOrderEquipment, expanded: boolean): Promise<void>;
    onFavoriteChange?(sender: EquipmentItem, productId: string, favorite: boolean): Promise<void>;
}

interface IEquipmentItemState {
    expanded: boolean;
    initialAmount: number;
}

export default class EquipmentItem extends BaseComponent<IEquipmentItemProps, IEquipmentItemState> {

    // Inherited / implemented

    public state: IEquipmentItemState = {
        expanded: (this.props.expanded === true),
        initialAmount: EquipmentItem.getInitialAmount(this.equipment),
    }

    public async componentWillReceiveProps(nextProps: IEquipmentItemProps): Promise<void> {

        const nexExpanded: boolean = (nextProps.expanded !== this.props.expanded);

        await super.componentWillReceiveProps(nextProps);

        if (nexExpanded) {
            const expanded: boolean = (this.props.expanded === true);
            if (expanded != this.state.expanded) {
                await this.setState({expanded});
            }
        }
    }


    // Fields

    private readonly _inputRef: React.RefObject<NumberInput> = React.createRef();


    // Getters

    private get equipment(): WorkOrderEquipment {
        return this.props.equipment;
    }

    private get shoppingCart(): boolean {
        return (this.props.shoppingCart === true);
    }

    private get delta(): number {
        return (this.equipment.amount - this.state.initialAmount);
    }

    private get product(): Product | null {
        return this.equipment.product;
    }

    private get equipmentName(): string {
        return this.equipment.name || this.product?.name || "*";
    }

    private get modified(): boolean {
        return (this.delta !== 0);
    }

    private get equipmentItemId(): string {
        return `equipmentItem_${this.equipmentName.replace(/\s/g,'_')}`;
    }


    // Sync-methods

    private static getInitialAmount(equipment: WorkOrderEquipment): number {
        const tag: string = "__initialAmount";
        const instance = equipment as any;
        return (typeof instance[tag] === "number")
            ? instance[tag]
            : (instance[tag] = equipment.amount);
    }


    // Async-methods

    private async toggleFavoriteAsync(e: React.MouseEvent): Promise<void> {

        e.stopPropagation();

        if (this.product) {
            this.product.favorite = (!this.product.favorite);

            await this.reRenderAsync();

            if (this.props.onFavoriteChange) {
                await this.props.onFavoriteChange(this, this.product.id, this.product.favorite);
            }
        }
    }

    private async onChangeDescriptionAsync(description: string): Promise<void> {
        if (this.equipment.description !== description) {
            this.equipment.description = description;
            await this.reRenderAsync();

            if (this.props.onChange) {
                await this.props.onChange(this, this.equipment);
            }
        }
    }

    private async onChangeAmountAsync(amount: number): Promise<void> {
        if (this.equipment.amount !== amount) {
            this.equipment.amount = amount;

            if (amount <= 0) {
                this.equipment.description = "";
                this.state.expanded = false;
            }

            await this.reRenderAsync();

            if (this.props.onChange) {
                await this.props.onChange(this, this.equipment);
            }
        }
    }

    private async increaseAsync(): Promise<void> {
        if (this._inputRef.current) {
            await this._inputRef.current.increaseAsync();
        }
    }

    private async decreaseAsync(): Promise<void> {
        if (this._inputRef.current) {
            await this._inputRef.current.decreaseAsync();
        }
    }

    private async toggleAsync(): Promise<void> {
        const expanded: boolean = (!this.state.expanded);

        await this.setState({expanded});

        if (this.props.onDescriptionToggle) {
            await this.props.onDescriptionToggle(this, this.equipment, expanded);
        }
    }


    // Renders

    private get renderNormal(): JSX.Element {
        const unit: ProductUnit = this.equipment.unit ?? this.product?.unit ?? ProductUnit.Custom;
        const unitName: string = EnumProvider.getProductUnitText(unit);

        const step: number = Product.getStep(this.product);

        const format: TFormat = Product.getFormat(this.product);

        const deltaSign: string = (this.delta > 0)
            ? "+"
            : "";

        const name: string = this.equipmentName;

        const favoriteIconStyle: IconStyle = (this.product?.favorite === true)
            ? IconStyle.Solid
            : IconStyle.Regular;

        const descriptionButtonIconName: string = (this.equipment.description)
            ? "far comment-alt-dots"
            : "far comment-alt"

        const noFavoriteStyle = ((this.equipment.type === EquipmentType.Custom) || (!this.product)) && styles.noFavorite;

        return (
            <React.Fragment>

                <div id={"itemHeader"} className={styles.title}>

                    <div id={"itemFavorite"} className={this.css(styles.favorite, noFavoriteStyle)}
                         onClick={async (e: React.MouseEvent) => await this.toggleFavoriteAsync(e)}
                    >
                        <Icon name={"star"}
                              style={favoriteIconStyle}
                              size={IconSize.Large}
                        />
                    </div>

                    <div id={"itemName"} className={styles.name}>
                        <span>
                            {name} ({unitName})
                        </span>
                    </div>

                    <div id={"itemInitialAmount"} className={styles.initialAmount}>
                        <span>
                            {this.state.initialAmount}
                        </span>
                    </div>

                </div>

                <div id={"itemContent"} className={styles.content}>

                    <div className={styles.actions}>
                        <div>
                            <Button block
                                    id={"itemMinus"}
                                    type={ButtonType.Orange}
                                    icon={{name: "fas minus"}}
                                    onClick={() => this.decreaseAsync()}
                            />
                        </div>
                    </div>

                    <div id={"itemDataContainer"} className={styles.data}>

                        <div id={"itemAmountInputWrapper"} className={styles.input}>
                            <NumberInput hideArrows clickToEdit
                                         id="amountInput"
                                         ref={this._inputRef}
                                         min={0}
                                         max={99999.99}
                                         step={step}
                                         format={format}
                                         value={this.equipment.amount}
                                         onChange={(_, amount) => this.onChangeAmountAsync(amount)}
                            />
                        </div>

                        <div id={"itemDescription"} className={styles.description}>
                            <span>
                                {this.equipment.description}
                            </span>
                        </div>

                    </div>

                    <div className={styles.actions}>

                        <div>
                            <Button block
                                    id={"itemPlus"}
                                    type={ButtonType.Orange}
                                    icon={{name: "fas plus"}}
                                    onClick={() => this.increaseAsync()}
                            />

                            {
                                (this.modified) &&
                                (
                                    <span className={styles.delta}>
                                        {deltaSign}{Utility.formatValue(this.delta, format)}
                                    </span>
                                )
                            }

                        </div>

                        <div>
                            <Button block
                                    id={"itemDescriptionToggler"}
                                    type={ButtonType.Orange}
                                    disabled={(this.equipment.amount <= 0)}
                                    icon={{name: descriptionButtonIconName}}
                                    onClick={() => this.toggleAsync()}
                            />
                        </div>

                    </div>

                </div>

                <div className={styles.descriptionInput}>
                    {
                        (this.state.expanded) &&
                        (
                            <TextAreaInput autoFocus
                                           id={"itemDescriptionInput"}
                                           value={this.equipment.description || ""}
                                           onChange={(_, value) => this.onChangeDescriptionAsync(value)}
                            />
                        )
                    }
                </div>

                {
                    (this.props.catalogType === CatalogType.RentalMassProduct) &&
                    <TwoColumns>
                        <DateInput className="rentDate"
                                   required
                                   readonly={false}
                                   label={Localizer.genericDate}
                                   value={this.equipment.rentDate}
                                   onChange={async (value) => {
                                       this.equipment!.rentDate = value;
                                       await this.reRenderAsync();
                                   }}
                        />

                        <Dropdown className="actionType"
                                  required
                                  label={Localizer.genericType}
                                  minWidth="105px"
                                  items={EnumProvider.getRentalItemActionTypeItems()}
                                  selectedItem={this.equipment.actionType}
                                  onChange={async (_, item) => {
                                      this.equipment!.actionType = parseInt(item!.value);
                                      await this.reRenderAsync();
                                  }}
                        />
                    </TwoColumns>
                }

            </React.Fragment>
        );
    }

    public render(): React.ReactNode {
        const className: string = this.css(
            styles.equipmentItem,
            this.props.className,
            (this.shoppingCart) && (this.equipment.amount > 0) && styles.active);

        return (
            <CatalogItem id={this.equipmentItemId}
                         className={className}>
                {
                    this.renderNormal
                }
            </CatalogItem>
        );
    }
};