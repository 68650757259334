import React from "react";
import {ActionType, BaseComponent, ch, IBaseComponent, PageCacheProvider, TextAlign} from "@renta-apps/athenaeum-react-common";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    Checkbox,
    ColumnDefinition,
    ColumnType,
    Grid,
    GridHoveringType,
    GridModel,
    GridOddType,
    IconSize,
    Inline,
    JustifyContent,
    RowModel,
} from "@renta-apps/athenaeum-react-components";
import Warehouse from "@/models/server/Warehouse";
import WarehouseCostPool from "@/models/server/WarehouseCostPool";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import User from "@/models/server/User";
import CostPool from "@/models/server/CostPool";
import AddWarehouseCostPoolRequest from "@/models/server/requests/AddWarehouseCostPoolRequest";
import AddWarehouseCostPoolResponse from "@/models/server/responses/AddWarehouseCostPoolResponse";
import GetWarehouseCostPoolsRequest from "@/models/server/requests/GetWarehouseCostPoolsRequest";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import styles from "./WarehouseDetails.module.scss";

interface IWarehouseDetailsProps {
    warehouse: Warehouse;
}

interface IWarehouseDetailsState {
    selectedTabIndex: number,
    showDeleted: boolean
}

export default class WarehouseDetails extends BaseComponent<IWarehouseDetailsProps, IWarehouseDetailsState> {

    state: IWarehouseDetailsState = {
        selectedTabIndex: 0,
        showDeleted: false
    };

    private readonly _warehouseCostPoolGridRef: React.RefObject<Grid<WarehouseCostPool>> = React.createRef();

    private readonly _warehouseCostPoolsColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 50,
            noWrap: true,
            className: "grey",
            textAlign: TextAlign.Center
        },
        {
            header: Localizer.genericCostPoolLanguageItemName,
            accessor: "costPool",
            transform: (_, value) => (value) ? TransformProvider.costPoolToString(value, true) : "",
            type: ColumnType.Dropdown,
            editable: true,
            minWidth: 150,
            sorting: true,
            noWrap: true,
            settings: {
                required: true,
                nothingSelectedText: Localizer.componentDropdownNothingSelectedLanguageItemName,
                groupSelected: true,
                fetchItems: async () => this.getCostPoolsAsync(this.warehouseCostPoolGrid.instance)
            }
        },
        {
            header: Localizer.genericDescriptionLanguageItemName,
            accessor: "costPool.description",
            type: ColumnType.Custom,
            minWidth: 600,
            editable: false,
            noWrap: true
        },
        {
            stretch: true,
            minWidth: "6rem",
            removable: false,
            init: (cell) => this.initWarehouseCostPoolOperationsAsync(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.genericActionSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processWarehouseCostPoolOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.genericActionCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processWarehouseCostPoolOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.genericActionDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell) => this.getDeleteConfirmation(cell),
                    callback: async (cell, action) => await this.processWarehouseCostPoolOperationAsync(cell, action)
                },
                {
                    name: "restore",
                    title: Localizer.genericActionRestoreLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processWarehouseCostPoolOperationAsync(cell, action)
                }
            ]
        }
    ];

    private get warehouseCostPoolGrid(): GridModel<WarehouseCostPool> {
        return this._warehouseCostPoolGridRef.current!.model;
    }

    private get warehouse(): Warehouse {
        return this.props.warehouse;
    }

    private get newRowAlreadyExists(): boolean {
        return (this.warehouseCostPoolGrid.rows.some(row => !row.deleted && !row.model.id));
    }

    private initRow(row: RowModel<WarehouseCostPool>): void {
        const model: WarehouseCostPool = row.model;

        const isNew: boolean = ((model.id == "") || (model.id == null));
        const isValid: boolean = this.isValid(model);
        row.deleted = model.deleted;
        row.readonly = (model.costPool != null);
        row.className = (!isValid)
            ? "danger"
            : (isNew)
                ? "bg-processed"
                : "";
    }

    private isValid(warehouseCostPool: WarehouseCostPool): boolean {
        return (!!warehouseCostPool.costPool);
    }

    private getDeleteConfirmation(cell: CellModel<WarehouseCostPool>): string {
        const model: WarehouseCostPool = cell.model;
        const isNew: boolean = !model.id;
        return (isNew)
            // Grid types do not allow the returned value to be null, but it has been like this forever.
            ? null as any
            : Utility.format(Localizer.warehousesWarehouseDetailsDeleteConfirmation, model.costPool);
    }

    private async addWarehouseCostPoolAsync(): Promise<void> {
        if (!this.newRowAlreadyExists) {
            const model = new WarehouseCostPool();

            const rows: RowModel<WarehouseCostPool>[] = await this.warehouseCostPoolGrid.insertAsync(0, model);
            const row: RowModel<WarehouseCostPool> = rows[0];
            const nameCell: CellModel<WarehouseCostPool> = row.get("costPool");
            await nameCell.editAsync(true);
        }
    }

    private async getCostPoolsAsync(sender: IBaseComponent): Promise<CostPool[]> {
        return await PageCacheProvider.getAsync("getCostPoolsAsync", () => sender.postAsync("api/admin/getCostPools", null));
    }

    private async initWarehouseCostPoolOperationsAsync(cell: CellModel<WarehouseCostPool>): Promise<void> {

        const model: WarehouseCostPool = cell.row.model;

        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const isValid: boolean = this.isValid(model);
        const isNew: boolean = !model.id;
        const costPoolNotDeleted: boolean = (model.costPool != null) && (!model.costPool.deleted);

        const saveAction: CellAction<WarehouseCostPool> = cell.actions[0];
        const cancelAction: CellAction<WarehouseCostPool> = cell.actions[1];
        const deleteAction: CellAction<WarehouseCostPool> = cell.actions[2];
        const restoreAction: CellAction<WarehouseCostPool> = cell.actions[3];

        saveAction.visible = (modified) && (isValid) && (costPoolNotDeleted);
        cancelAction.visible = (modified) && (!isNew);
        deleteAction.visible = (!deleted) && (costPoolNotDeleted) && ((!modified) || (isNew));
        restoreAction.visible = (deleted) && (costPoolNotDeleted);
    }

    private async processWarehouseCostPoolOperationAsync(cell: CellModel<WarehouseCostPool>, action: CellAction<WarehouseCostPool>): Promise<void> {

        await ch.hideAlertAsync();

        const model: WarehouseCostPool = cell.model;
        const isNew: boolean = (!model.id);

        if (action.action.name === "save") {

            if (isNew) {

                const request = new AddWarehouseCostPoolRequest();
                request.costPoolId = model.costPool!.id;
                request.warehouseId = this.warehouse.id;

                const response: AddWarehouseCostPoolResponse = await cell.grid.postAsync("api/admin/addWarehouseCostPool", request);

                if (response.costPoolAlreadyAssigned) {
                    await ch.alertErrorAsync(Utility.format(Localizer.warehousesWarehouseDetailsAlertCostPoolAlreadyAssigned, model.costPool), true);
                    return;
                }

                cell.row.model = response.warehouseCostPool!;

                await cell.row.saveAsync();
            }

            await cell.row.bindAsync();

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "delete") {

            model.deleted = true;
            model.deletedAt = new Date();
            model.deletedBy = ch.getUser<User>();

            if (isNew) {
                await cell.grid.deleteAsync(cell.row.index);
            } else {

                await cell.grid.postAsync("api/admin/deleteWarehouseCostPool", model.id);

                await cell.row.setDeletedAsync(true);
            }

        } else if (action.action.name === "restore") {

            const restoreOnServer: boolean = !isNew;

            if (restoreOnServer) {
                await cell.grid.postAsync("api/admin/restoreWarehouseCostPool", model.id);

                model.deleted = false;
                model.deletedAt = null;
                model.deletedBy = null;
            }

            await cell.row.setDeletedAsync(false);
        }
    }

    public async reloadAsync(): Promise<void> {
        await this.warehouseCostPoolGrid.reloadAsync();
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.reloadAsync();
    }

    private async getWarehouseCostPoolsAsync(): Promise<WarehouseCostPool[]> {
        const request = new GetWarehouseCostPoolsRequest();
        request.warehouseId = this.warehouse.id;
        request.showDeleted = this.state.showDeleted;
        return await this.warehouseCostPoolGrid.postAsync("api/admin/GetWarehouseCostPools", request);
    }

    public render(): React.ReactNode {
        return (


            <div className="col">

                <Inline justify={JustifyContent.End}>

                    <Checkbox inline
                              id="showDeletedWarehouseCostPools"
                              label={Localizer.genericShowDeleted}
                              value={this.state.showDeleted}
                              onChange={async (_, value) => await this.setShowDeletedAsync(value)}
                    />

                    <Button id="reloadWarehouseCostPools"
                            className="ml-1"
                            title={Localizer.genericActionReload}
                            icon={{name: "far history", size: IconSize.Large}}
                            type={ButtonType.Info}
                            onClick={async () => await this.reloadAsync()}
                    />

                    <Button id="addWarehouseCostPool"
                            icon={{name: "plus", size: IconSize.Large}}
                            label={Localizer.warehousesCostPoolsDataAddNewCostPool}
                            type={ButtonType.Orange}
                            onClick={async () => await this.addWarehouseCostPoolAsync()}
                    />

                </Inline>

                <div className={styles.container}>
                    <Grid ref={this._warehouseCostPoolGridRef}
                          id={`warehouseCostPoolsGrid`}
                          columns={this._warehouseCostPoolsColumns}
                          noDataText={Localizer.genericNoData}
                          minWidth="auto"
                          hovering={GridHoveringType.Row}
                          odd={GridOddType.None}
                          initRow={(row) => this.initRow(row)}
                          fetchData={async () => await this.getWarehouseCostPoolsAsync()}
                    />
                </div>

            </div>

        );
    }
}