//Autogenerated

import {BaseComponentLocalizer} from "@renta-apps/athenaeum-react-common";

class DateInputWidgetLocalizer extends BaseComponentLocalizer {

    //Constants


    constructor() {

        super(
            [
                { code: "en", label: "English" },
                { code: "nb", label: "Norsk bokmål" },
                { code: "pl", label: "Polski" },
                { code: "sv", label: "Svenska" },
                { code: "fi", label: "Suomi" },
                { code: "da", label: "Dansk" },
                { code: "uk", label: "Українська" },
                { code: "ru", label: "Русский" }
            ],
            "en");
        
        //Initializer

    }


}

//Singleton
export default new DateInputWidgetLocalizer();