import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import FormDefinition from "@/models/server/forms/FormDefinition";
import PageDefinitions from "@/providers/PageDefinitions";
import FormItem from "@/models/server/forms/FormItem";
import FormCheckItem from "@/models/server/forms/FormCheckItem";
import {CustomFormType, FormHeaderType, FormItemType, FormTimeslotType} from "@/models/Enums";
import {ApiProvider, BasePageParameters, ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import EnumProvider from "@/providers/EnumProvider";
import {
    Button,
    ButtonContainer,
    ButtonType,
    Checkbox,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    DropdownRequiredType,
    Form,
    Icon,
    IconSize,
    InlineType,
    Modal,
    ModalSize,
    NumberInput,
    OneColumn,
    PageContainer,
    PageHeader,
    PageRow,
    SelectListItem,
    TextAreaInput,
    TextInput,
    ThreeColumns,
    TwoColumns
} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import WorkOrderType from "@/models/server/WorkOrderType";
import DeleteFormDefinitionResponse from "@/models/server/responses/DeleteFormDefinitionResponse";
import SaveFormDefinitionRequest from "@/models/server/requests/SaveFormDefinitionRequest";
import Localizer from "@/localization/Localizer";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import FormItemView from "@/components/Form/FormItem/FormItemView";

import styles from "./FormDefinition.module.scss";

const MAX_RESOURCE_VALUE: number = 999999;

export interface IFormDefinitionParameters extends BasePageParameters {
    formDefinition?: FormDefinition | null;
}

interface IFormDefinitionState {
    definition: FormDefinition;
    workOrderTypes: WorkOrderType[];
}

export default class FormDefinitionPage extends AuthorizedPage<IFormDefinitionParameters, IFormDefinitionState> {

    state: IFormDefinitionState = {
        definition: new FormDefinition(),
        workOrderTypes: []
    };

    private readonly _parameters: IFormDefinitionParameters | null = this.parameters as IFormDefinitionParameters | null;
    private _validationErrorMessage: string[] = [];
    private _formPreviewModalRef: React.RefObject<Modal> = React.createRef();

    private get definition(): FormDefinition {
        return this.state.definition!;
    }

    // The Form Definition which passed in page parameters to copy data to a template.
    private get sourceFormDefinition(): FormDefinition | null {
        return this._parameters?.formDefinition ?? null;
    }

    private get items(): FormItem[] {
        return this.definition.items;
    }

    private get disableRequire(): boolean {
        return (this.definition.deleted) || (this.definition.mapping.timeslot === FormTimeslotType.Anytime) || (this.definition.mapping.multiple);
    }

    private get disableMultiple(): boolean {
        return (this.definition.deleted)
            || (this.definition.mapping.require)
            || (this.definition.mapping.timeslot === FormTimeslotType.Before)
            || (this.definition.mapping.timeslot === FormTimeslotType.After);
    }

    private get workOrderTypes(): WorkOrderType[] {
        return this.state.workOrderTypes;
    }

    private get enabledWorkOrderTypes(): WorkOrderType[] {
        return this.workOrderTypes.where(item => !item.disabled);
    }

    private getCheckItems(item: FormItem): FormCheckItem[] {
        return item.checks || (item.checks = []);
    }

    private isValid(): boolean {
        const namesValid: boolean = this.items.every((item: FormItem) => !!item.name);
        const stepsExist: boolean = this.definition.items.length > 0;
        const checkStepsValid: boolean = this.isValidCheckSteps(this.definition);
        const optionsStepsValid: boolean = this.isValidOptionsSteps(this.definition);
        const isMinMaxValid: boolean = this.isIsValidMinMax(this.definition);
        const isValueStepValid: boolean = this.areValueStepsValid(this.definition)
        const groupsAreValid: boolean = this.areGroupsValid(this.definition);

        if (!namesValid) {
            this.addInfoToErrorMessage(null, Localizer.formDefinitionPageMissingName);
        }

        return (
            (namesValid) &&
            (stepsExist) &&
            (checkStepsValid) &&
            (isMinMaxValid) &&
            (groupsAreValid) &&
            (isValueStepValid) &&
            (optionsStepsValid)
        );
    }

    private areValueStepsValid(item: FormDefinition): boolean {
        const steps = item.items.filter(step => step.type === FormItemType.Resource);
        return (steps.length == 0) || (steps.every(step => this.isValueStepValid(step)));
    }

    private isIsValidMinMax(item: FormDefinition): boolean {
        const steps = item.items.filter(step => step.type === FormItemType.Checks || FormItemType.Resource);
        return (steps.length == 0) || (steps.every(step => this.isValidMinMax(step)));
    }

    /**
     * Checks if groups are valid. Items within the same group must be in consecutive order.
     */
    private areGroupsValid(definition: FormDefinition): boolean {
        const groupIndexesAreConsecutive = (group: string, items: FormItem[]) => {
            let previousIndex = -1;
            for (let i: number = 0; i < items.length; i++) {
                if (items[i].group === group) {
                    if (previousIndex < 0) {
                        // First index
                        previousIndex = i;
                        continue;
                    }

                    if (previousIndex !== i - 1) {
                        // Non-consecutive
                        return false;
                    }

                    previousIndex = i;
                }
            }

            return true;
        };

        const groups: string[] = definition.items
            .filter(item => item.group.length > 0)
            .map(item => item.group)
            .distinct(group => group);

        for (let i: number = 0; i < groups.length; i++) {
            if (!groupIndexesAreConsecutive(groups[i], definition.items)) {
                this.addInfoToErrorMessage(null, Localizer.formDefinitionPageNonConsecutiveGroups);
                return false;
            }
        }

        return true;
    }

    private addInfoToErrorMessage(item: FormItem | null, text: string): void {
        const itemName: boolean = ((item != null) && ((item.name != null && item.name.length > 0)));

        if (itemName && text) {
            this._validationErrorMessage.push(`${item!.name} - ${text}`);
        } else {
            this._validationErrorMessage.push(text);
        }
    }

    private isValidMinMax(item: FormItem): boolean {

        let isValid: boolean = true;

        if (item.min != null && item.max != null) {
            isValid = item.max > item.min;

            if (item.default != null) {
                isValid = item.default >= item.min && item.max >= item.default;
            }

            if (!isValid) {
                this.addInfoToErrorMessage(item, Localizer.formDefinitionPageMinMaxIncorrect);
            }

            return isValid;
        }
        return isValid;
    }

    private isValueStepValid(item: FormItem): boolean {
        const isValid: boolean = (!!item.valueStep && (item.valueStep > 0));

        if (!isValid) {
            this.addInfoToErrorMessage(item, Localizer.formDefinitionPageValueStepIncorrect);
        }

        return isValid;
    }

    private isValidCheckSteps(item: FormDefinition): boolean {
        const checkSteps = item.items.filter(step => step.type === FormItemType.Checks);
        return (checkSteps.length == 0) || (checkSteps.every(checkStep => this.isValidCheckStep(checkStep)));
    }

    private isValidCheckStep(item: FormItem): boolean {
        const isValid: boolean = ((item.checks != null) && (item.checks.length > 0) && (item.checks.every((item) => !!item.name)));

        if (!isValid) {
            this.addInfoToErrorMessage(item, Localizer.formDefinitionPageChecks);
        }

        return isValid;
    }

    private isValidOptionsSteps(item: FormDefinition): boolean {
        const checkSteps: FormItem[] = item.items.filter(step => step.type === FormItemType.Options);
        return checkSteps.every(checkStep => this.isValidOptionsStep(checkStep));
    }

    private isValidOptionsStep(item: FormItem): boolean {
        const isValid: boolean = ((item.checks != null) && (item.checks.length > 0) && (item.checks.every((item) => !!item.name)));

        if (!isValid) {
            this.addInfoToErrorMessage(item, Localizer.formDefinitionPageOptions);
        }

        return isValid;
    }

    private get subtitle(): string {
        return this.definition.name;
    }

    private async upAsync(item: FormItem, index: number): Promise<void> {
        if (index > 0) {

            const previous: FormItem = this.items[index - 1];

            this.items[index - 1] = item;
            this.items[index] = previous;

            await this.reRenderAsync();
        }
    }

    private async downAsync(item: FormItem, index: number): Promise<void> {
        if (index < this.items.length - 1) {

            const next: FormItem = this.items[index + 1];

            this.items[index + 1] = item;
            this.items[index] = next;

            await this.reRenderAsync();
        }
    }

    private async deleteAsync(index: number): Promise<void> {

        this.items.splice(index, 1);

        await this.reRenderAsync();
    }

    private async restoreReportDefinitionAsync(): Promise<void> {
        if (this.definition.id) {

            await this.postAsync("api/form/restoreFormDefinition", this.definition.id);

            this.definition.deleted = false;

            this.setState({definition: this.definition});

            await ch.flyoutMessageAsync(Localizer.formDefinitionPageRestored);
        }
    }

    private async deleteReportDefinitionAsync(): Promise<void> {
        if (this.definition.id) {
            const response: DeleteFormDefinitionResponse = await this.postAsync("api/form/deleteFormDefinition", this.definition.id);

            if (response.removedPermanently) {

                await PageRouteProvider.redirectAsync(PageDefinitions.formDefinitionsRoute);

                await ch.flyoutMessageAsync(Localizer.formDefinitionPageDeletedPermanently);

            } else {

                this.definition.deleted = true;

                this.setState({definition: this.definition});

                await ch.flyoutMessageAsync(Localizer.formDefinitionPageMarkedAsDeleted);
            }
        }
    }

    private async saveChangesAsync(): Promise<void> {
        if (!this.isValid()) {
            let message: string = Localizer.formDefinitionPageValidationErrors;

            if (this._validationErrorMessage.length > 0) {
                const items: string = this._validationErrorMessage.join(";\n");
                message += Localizer.formDefinitionPageErrors.format(items);
            }

            await ch.alertErrorAsync(message, false, true);

            this._validationErrorMessage = [];
            return;
        }

        const request = new SaveFormDefinitionRequest();

        request.formDefinitionId = this.definition!.id!;
        request.name = this.definition.name;
        request.description = this.definition.description;
        request.customType = this.definition.customType;
        request.headers = this.definition.headers;
        request.mapping = this.definition.mapping;
        request.items = this.definition.items;

        const definition: FormDefinition = await this.postAsync("api/form/saveFormDefinition", request);

        this._validationErrorMessage = [];

        await this.setState({definition});

        if (this.sourceFormDefinition || this.routeId == null) {
            {
                await PageRouteProvider.redirectAsync(PageDefinitions.formDefinition(definition.id), true);
            }
        }

        await ch.flyoutMessageAsync(Localizer.formDefinitionPageSaved);
    }

    private async setCheckItemNameAsync(item: FormCheckItem, value: string): Promise<void> {
        if (item.name != value) {
            item.name = value;
        }
    }

    private async setCheckItemRequiredAsync(item: FormCheckItem, value: boolean): Promise<void> {
        if (item.isRequired != value) {
            item.isRequired = value;
        }
    }

    private async addCheckItemAsync(item: FormItem): Promise<void> {
        const checks = this.getCheckItems(item);
        checks.push(new FormCheckItem());
        await this.setState({definition: this.definition});
    }

    private async removeCheckItemAsync(item: FormItem, index: number): Promise<void> {
        if (item.checks && item.checks.length) {
            item.checks.splice(index, 1);

            this.items.map(i => i === item);

            await this.setState({definition: this.definition});
        }
    }

    private async changeStepTypeAsync(formItem: FormItem, selectedItem: SelectListItem) {
        if (selectedItem == null || selectedItem.value == null) {
            return;
        }

        formItem.type = Number(selectedItem.value);

        formItem.checks = ((formItem.type === FormItemType.Checks) || (formItem.type === FormItemType.Options))
            ? formItem.checks || []
            : null;

        formItem.valueStep = (formItem.type === FormItemType.Resource)
            ? 1
            : null;

        // if (formItem.type === FormItemType.MeasuringResource) {
        //     if (formItem.passedValue == null) {
        //         formItem.passedValue = formItem.min!;
        //     }
        //     if (formItem.passedValueOperator == null) {
        //         formItem.passedValueOperator = FormMeasuringOperator.Bigger;
        //     }
        // } else {
        //     formItem.passedValue = null;
        //     formItem.passedValueOperator = null;
        // }

        await this.setState({definition: this.definition});
    }

    private async addAsync(index: number, after: boolean = true): Promise<void> {
        let item = new FormItem();
        item.type = FormItemType.Question;

        let newIndex: number = (after) ? index + 1 : index;
        this.items.splice(newIndex, 0, item);

        await this.setState({definition: this.definition});
    }

    private async setFormItemManualAsync(item: FormItem, value: string) {
        item.manual = value;
    }

    private async setFormItemRequiredAsync(item: FormItem, value: boolean) {
        item.required = value;
    }

    private async setFormItemMultipleAsync(item: FormItem, value: boolean) {
        item.multiple = value;
    }

    private async setFormItemCommentRequiredAsync(item: FormItem, value: boolean) {
        item.commentRequired = value;
    }

    private async setFormItemRowsAsync(item: FormItem, value: number) {
        item.rows = value;
    }

    private async setFormItemMinAsync(item: FormItem, value: number) {
        item.min = value;
    }

    private async setFormExportSizeAsync(item: FormItem, value: number) {
        item.exportSize = value;
    }

    private async setFormItemMaxAsync(item: FormItem, value: number, reRender: boolean = false) {
        item.max = value;
        if (reRender) {
            await this.reRenderAsync();
        }
    }

    private async setFormItemDefaultAsync(item: FormItem, value: number) {
        item.default = value;
    }

    private async setFormItemValueStepAsync(item: FormItem, value: number) {
        item.valueStep = value;
    }

    private async setNameAsync(value: string): Promise<void> {
        this.definition.name = value;
    }

    private async setDescriptionAsync(value: string): Promise<void> {
        this.definition.description = value;
    }

    private async setCustomTypeAsync(value: CustomFormType): Promise<void> {
        this.definition.customType = value;
    }

    private async setFormTimeslotAsync(value: FormTimeslotType): Promise<void> {
        this.definition.mapping.timeslot = value;

        if (value === FormTimeslotType.Anytime) {
            this.definition.mapping.require = false;
        }

        if (value === FormTimeslotType.Before || FormTimeslotType.After) {
            this.definition.mapping.multiple = false;
        }

        await this.reRenderAsync();
    }

    private async setRequireAsync(value: boolean): Promise<void> {
        this.definition.mapping.require = value;

        await this.reRenderAsync();
    }

    private async setMultipleAsync(value: boolean): Promise<void> {
        this.definition.mapping.multiple = value;

        await this.reRenderAsync();
    }

    private async setApprovableAsync(value: boolean): Promise<void> {
        this.definition.mapping.approvable = value;
    }

    private async setMounterSignaturesRequired(value: boolean): Promise<void> {
        this.definition.mapping.mounterSignaturesRequired = value;
    }

    private async setHeadersAsync(value: FormHeaderType[]): Promise<void> {
        this.definition.headers = value;
    }

    private async setWorkOrderTypesAsync(value: WorkOrderType[]): Promise<void> {
        this.definition.mapping.workOrderTypes = value;
    }

    private async setReportDefinitionNameAsync(item: FormItem, value: string): Promise<void> {
        item.name = value;
    }

    private async setReportDefinitionGroupAsync(item: FormItem, value: string): Promise<void> {
        item.group = value;
    }

    private async setStepPictureAsync(item: FormItem, value: string): Promise<void> {
        item.icon = value;
    }

    private hasMinMax(item: FormItem) {
        return (item.type == FormItemType.Resource);
    }

    private hasRequire(item: FormItem) {
        return (item.type == FormItemType.Options) || (item.type == FormItemType.Text);
    }
    
    private async openPreviewAsync(): Promise<void> {
        // Need to re-render for changes to form items to be visible in modal.
        await this.reRenderAsync();
        await this._formPreviewModalRef.current!.openAsync();
    }

    public async initializeAsync(): Promise<void> {
        let definition: FormDefinition;
        const isNew: boolean = (this.routeId == null);

        if (!isNew) {
            definition = await ApiProvider.postAsync("api/form/getFormDefinition", this.routeId);
        } else if (this._parameters != null && this.sourceFormDefinition) {
            definition = Utility.clone(this.sourceFormDefinition);
            definition.id = "";
            definition.name = "";
            definition.deleted = false;
            definition.deletedAt = null;
            definition.items.map((formItem: FormItem) => {
                formItem.id = null;
            });
        } else {
            definition = new FormDefinition();
            definition.items.push(new FormItem());
        }

        const workOrderTypes: WorkOrderType[] = await this.getAsync("api/workOrderType/getWorkOrderTypes");

        await this.setState({definition, workOrderTypes});
    }

    private renderStep(item: FormItem, index: number, itemsAmount: number): React.ReactNode {
        const isLastFormItem: boolean = (index == itemsAmount);
        const paddingStyle = (!isLastFormItem) && "pb-5";

        return (
            <React.Fragment key={ch.getId()}>
                <tr key={index}>
                    <td className={this.css(paddingStyle)}>
                        <span>
                            <TextInput id={`icon_${index}`} value={item.icon!}
                                       prepend={item.icon != null && <Icon name={item.icon!}/>}
                                       label={Localizer.formDefinitionPageIcon}
                                       readonly={this.definition.deleted}
                                       onChange={async (_, value) => await this.setStepPictureAsync(item, value)}
                            />

                            <TextInput label={Localizer.formDefinitionPageGroup}
                                       value={item.group!}
                                       id={`group_${index}`}
                                       readonly={this.definition.deleted}
                                       onChange={async (_, value) => await this.setReportDefinitionGroupAsync(item, value)}
                            />

                            <TextInput required
                                       label={Localizer.formDefinitionPageName}
                                       value={item.name!}
                                       id={`name_${index}`}
                                       readonly={this.definition.deleted}
                                       onChange={async (_, value) => await this.setReportDefinitionNameAsync(item, value)}
                            />

                            <Dropdown id={`type_${index}`} noFilter noWrap required
                                      label={Localizer.formDefinitionPageType}
                                      disabled={this.definition.deleted}
                                      orderBy={DropdownOrderBy.None}
                                      items={EnumProvider.getFormItemTypeItems()}
                                      selectedItem={EnumProvider.getFormItemTypeItem(item.type)}
                                      onChange={async (_, value) => await this.changeStepTypeAsync(item, value!)}
                            />

                            <TextAreaInput id={`manual_${index}`}
                                           rows={3}
                                           label={Localizer.formDefinitionPageManual}
                                           maxLength={RentaTaskConstants.bigStringLength}
                                           value={item.manual!}
                                           readonly={this.definition.deleted}
                                           onChange={async (_, value) => await this.setFormItemManualAsync(item, value)}
                            />

                            {
                                (this.hasMinMax(item)) &&
                                (
                                    <React.Fragment>

                                        <ThreeColumns>

                                            <NumberInput id={`min_${index}`}
                                                         label={Localizer.formDefinitionPageMin}
                                                         step={0.5}
                                                         value={item.min!}
                                                         readonly={this.definition.deleted}
                                                         onChange={async (_, value) => await this.setFormItemMinAsync(item, value)}
                                            />

                                            <NumberInput id={`max_${index}`}
                                                         label={Localizer.formDefinitionPageMax}
                                                         step={0.5}
                                                         value={item.max!}
                                                         readonly={this.definition.deleted || item.max === MAX_RESOURCE_VALUE}
                                                         onChange={async (_, value) => await this.setFormItemMaxAsync(item, value)}
                                            />

                                            <Checkbox id={`unlimited_${index}`}
                                                      label={Localizer.formDefinitionPageUnlimited}
                                                      className={this.css(styles.checkbox, styles.auto)}
                                                      value={item.max === MAX_RESOURCE_VALUE}
                                                      onChange={async (_, value) => await this.setFormItemMaxAsync(item, value ? MAX_RESOURCE_VALUE : 0, true)}
                                            />

                                        </ThreeColumns>

                                        <TwoColumns>

                                            <NumberInput id={`input_valueStep_${index}`}
                                                         label={Localizer.formDefinitionPageStep}
                                                         step={0.5}
                                                         min={0.01}
                                                         value={item.valueStep}
                                                         readonly={this.definition.deleted}
                                                         onChange={async (_, value) => await this.setFormItemValueStepAsync(item, value)}
                                            />

                                            <NumberInput id={`input_default_${index}`}
                                                         label={Localizer.formDefinitionPageDefault}
                                                         value={item.default!}
                                                         readonly={this.definition.deleted}
                                                         onChange={async (_, value) => await this.setFormItemDefaultAsync(item, value)}
                                            />

                                        </TwoColumns>

                                    </React.Fragment>
                                )
                            }

                            {
                                (this.hasRequire(item)) &&
                                (
                                    <React.Fragment>

                                        <Checkbox id={`required_${index}`}
                                                  label={Localizer.formDefinitionPageFormItemRequired}
                                                  className={styles.checkbox}
                                                  inline
                                                  inlineType={InlineType.Right}
                                                  value={item.required || false}
                                                  readonly={this.definition.deleted}
                                                  onChange={async (_, value) => await this.setFormItemRequiredAsync(item, value)}
                                        />

                                    </React.Fragment>
                                )
                            }

                            {
                                (item.type == FormItemType.Options) &&
                                (
                                    <React.Fragment>

                                        <Checkbox id={`multiple_${index}`}
                                                  label={Localizer.formDefinitionPageFormItemMultiple}
                                                  className={styles.checkbox}
                                                  inline
                                                  inlineType={InlineType.Right}
                                                  value={item.multiple || false}
                                                  readonly={this.definition.deleted}
                                                  onChange={async (_, value) => await this.setFormItemMultipleAsync(item, value)}
                                        />

                                        <Checkbox id={`comment_required_${index}`}
                                                  label={Localizer.formDefinitionPageCommentRequired}
                                                  className={styles.checkbox}
                                                  inline
                                                  inlineType={InlineType.Right}
                                                  value={item.commentRequired || false}
                                                  readonly={this.definition.deleted}
                                                  onChange={async (_, value) => await this.setFormItemCommentRequiredAsync(item, value)}
                                        />

                                    </React.Fragment>
                                )
                            }

                            {
                                ((item.type == FormItemType.Checks) || (item.type == FormItemType.Options)) &&
                                (
                                    <React.Fragment>

                                        {
                                            this.getCheckItems(item).map((checkItem, itemIndex) => (
                                                <div id={item.type == FormItemType.Checks ? `check_item_${index}_${itemIndex}` : `option_item_${index}_${itemIndex}`}
                                                     key={item.type == FormItemType.Checks ? `check_item_${index}_${itemIndex}` : `option_item_${index}_${itemIndex}`}
                                                     className={"d-flex align-items-center"}>

                                                        <TextInput id={item.type == FormItemType.Checks ? `checkItemName_${index}_${itemIndex}` : `optionItemName_${index}_${itemIndex}`}
                                                                   required
                                                                   className={'mr-3'}
                                                                   label={Localizer.formDefinitionPageItemName}
                                                                   value={checkItem.name || ""}
                                                                   readonly={this.definition.deleted}
                                                                   onChange={(_, value) => this.setCheckItemNameAsync(checkItem, value)}
                                                        />
                                                        <div className={"d-flex align-items-center"}>
                                                                { item.type == FormItemType.Checks &&
                                                                    <Checkbox id={`checkItemIsRequired_${index}_${itemIndex}`}
                                                                              className={styles.checkbox}
                                                                              inline
                                                                              label={Localizer.formDefinitionPageFormItemRequired}
                                                                              inlineType={InlineType.Right}
                                                                              value={checkItem.isRequired}
                                                                              readonly={this.definition.deleted}
                                                                              onChange={async (_, value) => await this.setCheckItemRequiredAsync(checkItem, value)}
                                                                    />
                                                                }

                                                                {
                                                                    (this.getCheckItems(item).length !== 1) &&
                                                                    (
                                                                        <Icon id={item.type == FormItemType.Checks ? `check_item_delete_${index}_${itemIndex}` : `option_item_delete_${index}_${itemIndex}`}
                                                                              key={item.type == FormItemType.Checks ? `check_item_delete_${index}_${itemIndex}` : `option_item_delete_${index}_${itemIndex}`}
                                                                              className={"ml-2 cursor-pointer"}
                                                                              name={"times-circle"}
                                                                              size={IconSize.Large}
                                                                              disabled={this.definition.deleted}
                                                                              onClick={async () => await this.removeCheckItemAsync(item, itemIndex)}
                                                                        />
                                                                    )
                                                                }
                                                        </div>
                                                </div>
                                            ))
                                        }

                                        <Button id={(item.type == FormItemType.Checks) ? `addNewCheck_${index}` : `addNewOption_${index}`}
                                                label={(item.type == FormItemType.Checks) ? Localizer.formDefinitionPageAddNewCheck : Localizer.formDefinitionPageAddNewOption}
                                                icon={{name: "plus"}}
                                                disabled={this.definition.deleted}
                                                onClick={async () => await this.addCheckItemAsync(item)}
                                        />

                                    </React.Fragment>
                                )
                            }

                            {
                                (item.type == FormItemType.Options || item.type == FormItemType.Resource || item.type == FormItemType.Checks ||
                                    item.type == FormItemType.Question) && 
                                (
                                    <React.Fragment>
                                        <Dropdown id={`size_${index}`} noFilter noWrap required
                                                  label={Localizer.formDefinitionPageItemExportSize}
                                                  disabled={this.definition.deleted}
                                                  orderBy={DropdownOrderBy.None}
                                                  items={[25, 50, 75, 100].map(value => ({value: value.toString(), text: `${value.toString()}%`}))}
                                                  selectedItem={item.exportSize!}
                                                  onChange={async (_, listItem) => await this.setFormExportSizeAsync(item, Number(listItem!.value))} />
                                    </React.Fragment>
                                )
                            }

                            {
                                (item.type == FormItemType.Text) &&
                                (
                                    <React.Fragment>

                                        <NumberInput id={`rows_${index}`}
                                                     label={Localizer.formDefinitionPageRows}
                                                     step={1}
                                                     max={10}
                                                     value={item.rows || 1}
                                                     readonly={this.definition.deleted}
                                                     onChange={async (_, value) => await this.setFormItemRowsAsync(item, value)}
                                        />

                                        <NumberInput id={`min_${index}`}
                                                     label={Localizer.formDefinitionPageMinLength}
                                                     step={1}
                                                     value={item.min!}
                                                     readonly={this.definition.deleted}
                                                     onChange={async (_, value) => await this.setFormItemMinAsync(item, value)}
                                        />

                                        <NumberInput id={`max_${index}`}
                                                     label={Localizer.formDefinitionPageMaxLength}
                                                     step={1}
                                                     max={1024}
                                                     value={item.max || 255}
                                                     readonly={this.definition.deleted}
                                                     onChange={async (_, value) => await this.setFormItemMaxAsync(item, value)}
                                        />

                                    </React.Fragment>
                                )
                            }

                        </span>
                    </td>
                    <td>
                    </td>
                    <td className={styles.add}>

                        <Icon id={`add_step_${index}`}
                              name="far plus-circle"
                              size={IconSize.X2}
                              disabled={(this.definition.deleted)}
                              onClick={async () => await this.addAsync(index)}
                        />

                    </td>

                    <td className={styles.up}>

                        <Icon id={`move_up_step_${index}`}
                              name="far arrow-alt-circle-up"
                              size={IconSize.X2}
                              onClick={async () => this.upAsync(item, index)}
                              disabled={(this.definition.deleted) || (index === 0)}
                        />

                    </td>

                    <td className={styles.down}>

                        <Icon id={`move_down_step_${index}`}
                              name="far arrow-alt-circle-down"
                              size={IconSize.X2}
                              onClick={async () => this.downAsync(item, index)}
                              disabled={(this.definition.deleted) || (index === this.items.length - 1)}
                        />

                    </td>

                    <td className={styles.delete}>

                        <Icon id={`delete_step_${index}`}
                              name="far times-circle"
                              size={IconSize.X2}
                              onClick={async () => this.deleteAsync(index)}
                              disabled={(this.definition.deleted) || (this.definition.items.length === 1)}
                        />

                    </td>
                </tr>

            </React.Fragment>
        );
    }

    public getTitle(): string {
        return Localizer.formDefinitionPageTitle;
    }

    public render(): React.ReactNode {

        return (
            <PageContainer id="formDefinition" className={styles.formDefinition}>

                <PageHeader title={this.getTitle()} subtitle={this.subtitle}/>

                <PageRow>
                    <OneColumn className="col">
                        <Button small
                                id="previewButtonTop"
                                label={Localizer.genericActionPreview}
                                icon={{name: "eye", size: IconSize.Large}}
                                type={ButtonType.Default}
                                onClick={async () => this._formPreviewModalRef.current!.openAsync()}
                        />
                    </OneColumn>
                </PageRow>

                <PageRow>

                    <OneColumn className="col">

                        <Form onSubmit={() => this.saveChangesAsync()}>

                            <ThreeColumns>

                                <TextInput id="name" required autoFocus
                                           label={Localizer.formDefinitionPageFormName}
                                           value={this.definition.name}
                                           readonly={this.definition.deleted}
                                           onChange={(_, value) => this.setNameAsync(value)}
                                />

                                <Dropdown id="customType" noFilter noWrap required
                                          label={Localizer.formDefinitionPageCustomType}
                                          disabled={this.definition.deleted}
                                          orderBy={DropdownOrderBy.None}
                                          items={EnumProvider.getCustomFormTypeItems()}
                                          selectedItem={EnumProvider.getCustomFormTypeItem(this.definition.customType)}
                                          onChange={async (_, item) => await this.setCustomTypeAsync(Number(item!.value))}
                                />

                            </ThreeColumns>

                            <div className={"row"}>

                                <TextAreaInput id="description"
                                               className="col-md-8"
                                               label={Localizer.formDefinitionPageDescription}
                                               maxLength={RentaTaskConstants.bigStringLength}
                                               value={this.definition.description || ""}
                                               readonly={this.definition.deleted}
                                               onChange={(_, value) => this.setDescriptionAsync(value)}
                                />

                            </div>

                            <ThreeColumns>

                                <Dropdown id="headers" noFilter noWrap required multiple
                                          requiredType={DropdownRequiredType.Restricted}
                                          align={DropdownAlign.Left}
                                          label={Localizer.formDefinitionPageReportingHeaders}
                                          disabled={this.definition.deleted}
                                          orderBy={DropdownOrderBy.None}
                                          items={EnumProvider.getFormHeaderTypeItems()}
                                          selectedItems={this.definition.headers.map(item => EnumProvider.getFormHeaderTypeItem(item))}
                                          onChange={async (input) => await this.setHeadersAsync(input.selectedListItems.map(item => Number(item.value)))}
                                />

                            </ThreeColumns>

                            <ThreeColumns>

                                {
                                    (this.enabledWorkOrderTypes != null && this.enabledWorkOrderTypes.length > 0) &&
                                    (
                                        <Dropdown id="mappingWorkOrderTypes" noFilter noWrap required multiple autoGroupSelected
                                                  requiredType={DropdownRequiredType.Restricted}
                                                  align={DropdownAlign.Left}
                                                  label={Localizer.formDefinitionPageWorkOrders}
                                                  disabled={this.definition.deleted}
                                                  orderBy={DropdownOrderBy.None}
                                                  items={this.enabledWorkOrderTypes}
                                                  selectedItems={this.definition.mapping.workOrderTypes || []}
                                                  onChange={async (sender) => await this.setWorkOrderTypesAsync(sender.selectedItems)}
                                        />
                                    )
                                }

                                <Dropdown id="mappingTimeslot" noFilter noWrap required
                                          label={Localizer.formDefinitionPageTimeslot}
                                          disabled={this.definition.deleted}
                                          orderBy={DropdownOrderBy.None}
                                          items={EnumProvider.getFormTimeslotTypeItems()}
                                          selectedItem={EnumProvider.getFormTimeslotTypeItem(this.definition.mapping.timeslot)}
                                          onChange={async (_, item) => await this.setFormTimeslotAsync(Number(item!.value))}
                                />

                            </ThreeColumns>

                            <TwoColumns>

                                <Checkbox id="mappingRequire"
                                          label={Localizer.formDefinitionPageRequire}
                                          value={this.definition.mapping.require}
                                          readonly={this.disableRequire}
                                          onChange={async (_, value) => await this.setRequireAsync(value)}
                                />

                                <Checkbox id="mappingMultiple"
                                          label={Localizer.formDefinitionPageMultiple}
                                          value={this.definition.mapping.multiple}
                                          readonly={this.disableMultiple}
                                          onChange={async (_, value) => await this.setMultipleAsync(value)}
                                />

                            </TwoColumns>

                            <TwoColumns>

                                <Checkbox id="mappingApprovable"
                                          label={Localizer.formDefinitionPageApprovable}
                                          value={this.definition.mapping.approvable}
                                          onChange={async (_, value) => await this.setApprovableAsync(value)}
                                />

                                <Checkbox id="mappingMounterSignaturesRequired"
                                          label={Localizer.formDefinitionPageMountersSignaturesRequired}
                                          value={this.definition.mapping.mounterSignaturesRequired}
                                          onChange={async (_, value) => await this.setMounterSignaturesRequired(value)}
                                />

                            </TwoColumns>

                            <table id="formDefinitionSteps" className={styles.table}>
                                <tbody>
                                {
                                    this.definition.items.map((item, index) => this.renderStep(item, index, this.definition.items.length - 1))
                                }
                                </tbody>
                            </table>

                            <ButtonContainer className={styles.buttons}>
                                {
                                    (!this.definition.deleted) &&
                                    (
                                        <Button id="saveFormDefinition"
                                                submit
                                                type={ButtonType.Orange}
                                                icon={{name: "far save"}}
                                                label={Localizer.genericSave}
                                        />
                                    )
                                }

                                {
                                    (this.definition.deleted)
                                        ?
                                        (
                                            <Button id="restoreFormDefinition"
                                                    small
                                                    minWidth={90}
                                                    label={Localizer.genericRestore}
                                                    icon={{name: "trash-restore", size: IconSize.Large}}
                                                    type={ButtonType.Primary}
                                                    onClick={async () => await this.restoreReportDefinitionAsync()}
                                            />
                                        )
                                        :
                                        (
                                            (!!this.definition.id) && (

                                                <Button id="deleteFormDefinition"
                                                        small
                                                        minWidth={90}
                                                        label={Localizer.genericDelete}
                                                        icon={{name: "trash-alt", size: IconSize.Large}}
                                                        type={ButtonType.Primary}
                                                        onClick={async () => await this.deleteReportDefinitionAsync()}
                                                        confirm={Localizer.formDefinitionPageDeleteConfirmation}
                                                />
                                            )
                                        )
                                }

                                <Button small
                                        id="previewButtonBottom"
                                        label={Localizer.genericActionPreview}
                                        icon={{name: "eye", size: IconSize.Large}}
                                        type={ButtonType.Default}
                                        onClick={async () => await this.openPreviewAsync()}
                                />
                                
                                <Button id="backButton"
                                        small
                                        minWidth={90}
                                        label={Localizer.genericBack}
                                        icon={{name: "arrow-left", size: IconSize.Large}}
                                        type={ButtonType.Default}
                                        route={PageDefinitions.formDefinitionsRoute}
                                />

                            </ButtonContainer>

                        </Form>

                    </OneColumn>

                </PageRow>

                <Modal
                    id="formPreviewModal"
                    title={this.definition.name}
                    ref={this._formPreviewModalRef}
                    size={ModalSize.Large}
                >
                    {
                        this.definition.items.map((formItem, index) =>
                            <FormItemView
                                key={index}
                                formItem={formItem}
                                readonly={true}
                            />
                        )
                    }
                    
                    <ButtonContainer>
                        <Button
                            id="closeFormPreviewButton"
                            type={ButtonType.Primary}
                            label={Localizer.genericOk}
                            onClick={async () => await this._formPreviewModalRef.current!.closeAsync()}
                        />
                    </ButtonContainer>
                </Modal>
                
            </PageContainer>
        );
    }
}