/**
 * Request for getting work order data for the calendar page.
 */
import {TaskStatusFilter} from "@/models/Enums";

export default class GetCalendarWorkOrdersRequest {
    /** Return only work orders where any of the mounters is an assignee, and unassigned ones. */
    mounterIds: string[] = [];

    /** Return only work orders that have any of the given managers. */
    managerIds: string[] = [];

    /** Return only work orders where construction site is any of the given ones. */
    constructionSiteIds: string[] = [];

    /** Return only work orders where warehouse is any of the given ones. */
    warehouseIds: string[] = [];

    /** Return only work orders matching the statuses. Deleted are always excluded. */
    workOrderStatuses: TaskStatusFilter[] = [];
    
    /** Return only work orders where any of the assigned mounters have any of the given cost pools. */
    mounterCostPoolIds: string[] = [];

    /**
     * If defined, only those Work Orders which field(s) contain the search string will be displayed.
     */
    public search: string | null = null;

    /**
     * Start of the current view in calendar.
     */
    public viewStart: Date = new Date();

    /**
     * End of the current view in calendar.
     */
    public viewEnd: Date = new Date();
}