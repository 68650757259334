import React from "react";
import FormCheckItem from "@/models/server/forms/FormCheckItem";
import FormItem from "@/models/server/forms/FormItem";
import {Checkbox, InlineType, TextAreaInput} from "@renta-apps/athenaeum-react-components";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import FormPageRow from "@/components/Form/FormPageRow/FormPageRow";
import Localizer from "@/localization/Localizer";

import styles from "./FormOptions.module.scss";

export interface IFormOptionsProps {

    /**
     * NOTE: Mutated!
     */
    formItem: FormItem;
    preview?: boolean;

    /**
     * Invoked when the {@link formItem} is mutated.
     */
    onChange?(): Promise<void>;
}

interface IFormOptionsState {
}

export default class FormOptions extends BaseComponent<IFormOptionsProps, IFormOptionsState> {

    public state: IFormOptionsState = {};

    private get formItem(): FormItem {
        return this.props.formItem;
    }

    private async setValueAsync(formItem: FormItem, checkItem: FormCheckItem, value: boolean): Promise<void> {
        const isSingle = (!this.multiple(formItem));

        if ((isSingle) && (formItem.checks)) {
            formItem.checks.map(check => check.ok = false);
        }

        checkItem.ok = value;
        formItem.ok = value;

        if (this.props.onChange) {
            await this.props.onChange();
        }

        await this.reRenderAsync();
    }

    private async setComment(formItem: FormItem, comment: string): Promise<void> {
        formItem.comment = comment;

        if (this.props.onChange) {
            await this.props.onChange();
        }

        await this.reRenderAsync();
    }

    private multiple(formItem: FormItem): boolean {
        return (formItem.multiple == true);
    }

    private commentRequired(formItem: FormItem): boolean {
        return (formItem.commentRequired == true);
    }

    private isLastOptionOk(formItem: FormItem): boolean {
        const items: FormCheckItem[] = formItem.checks || [];
        const theLastOption: FormCheckItem | null = (items.length > 0) ? items[items.length - 1] : null;
        return (theLastOption != null) && (theLastOption.ok == true);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        FormItem.initialize(this.formItem);
    }

    public render(): React.ReactNode {
        const {preview} = this.props;
        const checks: FormCheckItem[] = this.formItem.checks || (this.formItem.checks = []);

        return (
            <div className={styles.formOptions}>

                <FormPageRow>
                    <span className={styles.title}>{this.formItem.name}</span>
                </FormPageRow>

                {
                    checks.map((checkItem) => (
                        <Checkbox inline
                                  key={checkItem.name}
                                  className={styles.item}
                                  inlineType={InlineType.Right}
                                  radio={!this.multiple(this.formItem)}
                                  label={Localizer.get(checkItem.name)}
                                  readonly={preview}
                                  value={checkItem.ok == true}
                                  onChange={async (_, value) => await this.setValueAsync(this.formItem, checkItem, value)}
                        />
                    ))
                }

                {
                    ((this.commentRequired(this.formItem)) && (this.isLastOptionOk(this.formItem))) &&
                    (
                        <TextAreaInput id="comment" required autoFocus
                                       placeholder={Localizer.genericComment}
                                       className={this.css(styles.comment)}
                                       rows={3}
                                       readonly={preview}
                                       value={this.formItem.comment || ""}
                                       onChange={async (_, value) => await this.setComment(this.formItem, value)}
                        />
                    )
                }

            </div>
        );
    }
}