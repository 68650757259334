import RentaTaskConstants from "@/helpers/RentaTaskConstants";

/**
 * Test if a Guid is null or default.
 *
 * @param guid Guid to test for being null or default.
 * @returns true if the {@link guid} is null or default; false otherwise.
 */
export default function isNullOrDefaultGuid(guid?: string | null): boolean {
    return (!guid) || (guid === RentaTaskConstants.defaultGuid);
}