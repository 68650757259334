import React from "react";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, IconStyle, IIconProps, TextInput} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";

interface IUsersContactInfoProps {
    email: string;
    phone: string;
}

interface IUsersContactInfoState {
    showPhone: boolean;
}

export default class UsersContactInfo extends BaseComponent<IUsersContactInfoProps, IUsersContactInfoState> {
    state: IUsersContactInfoState = {
        showPhone: false,
    };

    private get email(): string {
        return this.props.email;
    }

    private get phone(): string {
        return this.props.phone;
    }

    private get showPhone(): boolean {
        return this.state.showPhone;
    }

    private get buttonIcon(): IIconProps {
        return (!this.showPhone)
            ? {name: "fad fa-phone", style: IconStyle.Regular}
            : {name: "fad fa-envelope", style: IconStyle.Regular};
    }

    private async onShowPhoneAsync(showPhone: boolean): Promise<void> {
        await this.setState({showPhone});
    }

    public render(): React.ReactNode {
        return (
            <React.Fragment key={ch.getComponentId()}>

                <div className={"d-flex w-100 align-items-end"}>
                    {
                        (!this.showPhone) ?
                            (
                                <TextInput readonly
                                           id={"userContactInfoEmail"}
                                           className={"w-100"}
                                           label={Localizer.rentaTasksWorkOrderDetailsContactInfo}
                                           value={(this.email) ? this.email : "-"}
                                />
                            )
                            :
                            (
                                <TextInput readonly
                                           id={"userContactInfoPhone"}
                                           className={"w-100"}
                                           label={Localizer.rentaTasksWorkOrderDetailsContactInfo}
                                           value={(this.phone) ? this.phone : "-"}
                                />
                            )
                    }

                    <ButtonContainer className={"pl-2"}>
                        <Button id={"userContactInfoShowPhone"}
                                type={ButtonType.Info}
                                icon={this.buttonIcon}
                                onClick={() => this.onShowPhoneAsync(!this.showPhone)}
                        />
                    </ButtonContainer>
                </div>

            </React.Fragment>
        );
    }
}