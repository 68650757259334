import React from "react";
import {Button, ButtonContainer, ButtonType, Checkbox, FileInput, Form, InlineType, Modal, OneColumn} from "@renta-apps/athenaeum-react-components";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import AddConstructionSiteAttachmentsRequest from "@/models/server/requests/AddConstructionSIteAttachmentsRequest";
import {AttachmentType} from "@/models/Enums";
import Localizer from "@/localization/Localizer";

import styles from "./AddConstructionSiteAttachmentModal.module.scss";

interface IAddConstructionSiteAttachmentModalProps {
    id: string;
    constructionSiteId: string;
    addConstructionSiteAttachments(sender: IBaseComponent, request: AddConstructionSiteAttachmentsRequest): Promise<void>;
}

interface IAddConstructionSiteAttachmentModalState {
    public: boolean,
    files: FileModel[]
}

export default class AddConstructionSiteAttachmentModal extends BaseComponent<IAddConstructionSiteAttachmentModalProps, IAddConstructionSiteAttachmentModalState> {

    state: IAddConstructionSiteAttachmentModalState = {
        public: false,
        files: []
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    private get readonly(): boolean {
        return (this.state.files == null || this.state.files.length == 0);
    }

    private async onChangePublicAsync(value: boolean) {
        await this.setState({public: value});
    }

    private async onChangeAttachmentFilesAsync(value: FileModel | FileModel[]): Promise<void> {
        if (value instanceof Array) {
            await this.setState({files: value});
        } else {
            await this.setState({files: [value]});
        }
    }

    private async removeAttachmentHandlerAsync(value: FileModel) {
        const indexToRemove: number | null = this.state.files && this.state.files.indexOf(value);

        if (indexToRemove !== null) {
            const files: FileModel[] = this.state.files || [];
            files.splice(indexToRemove, 1);

            await this.setState({files});
        }
    };

    private async onSubmitAsync(): Promise<void> {
        const request = new AddConstructionSiteAttachmentsRequest();

        request.constructionSiteId = this.props.constructionSiteId;
        request.public = this.state.public;
        request.type = AttachmentType.ConstructionSiteAttachment;
        request.files = this.state.files;

        await this.props.addConstructionSiteAttachments(this.modal, request);

        await this.modal.closeAsync();
    }

    private async clearStateAsync(): Promise<void> {
        this.state.public = false;
        this.state.files = [];

        await this.setState(this.state);
    }

    private async onCloseAsync(): Promise<void> {
        await this.clearStateAsync();
    }

    public hasSpinner(): boolean {
        return true;
    }

    public async openAsync(): Promise<void> {
        await this.modal.openAsync();
    }

    public render(): React.ReactNode {

        return (
            <Modal id={this.id}
                   ref={this._modalRef}
                   className={styles.addConstructionSiteAttachmentModal}
                   title={Localizer.addConstructionSiteAttachmentModalTitle}
                   onClose={async () => await this.onCloseAsync()}>

                <div className="row">
                    <div className="col">

                        <Form ref={this._formRef} id="addConstructionSiteAttachment" onSubmit={async () => await this.onSubmitAsync()}>

                            <OneColumn>

                                <Checkbox id="public"
                                      label={Localizer.addConstructionSiteAttachmentModalPublicCheckbox}
                                      value={this.state.public}
                                      inlineType={InlineType.Right}
                                      onChange={async (_, value) => await this.onChangePublicAsync(value)}
                                />

                            </OneColumn>

                            <FileInput dropZone required multiple
                                       id={`file${this.id}`}
                                       label={Localizer.formInputFileChooseFiles}
                                       maxTotalSize={RentaTaskConstants.maxFileUploadSizeInBytes}
                                       maxSize={RentaTaskConstants.maxFileUploadSizeInBytes}
                                       fileTypes={RentaTaskConstants.allAttachmentFileTypes}
                                       value={this.state.files}
                                       onChange={(_, value: FileModel | FileModel[]) => this.onChangeAttachmentFilesAsync(value)}
                                       onRemove={async (value: FileModel) => await this.removeAttachmentHandlerAsync(value)}
                            />

                            <ButtonContainer>

                                <Button submit
                                        label={Localizer.genericActionSave}
                                        disabled={this.readonly}
                                        type={ButtonType.Orange}
                                        icon={{name: "far save"}}
                                />

                            </ButtonContainer>

                        </Form>

                    </div>
                </div>

            </Modal>
        );
    }
};