import React from "react";
import FormItem from "@/models/server/forms/FormItem";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import FormQuestionInput from "@/components/Form/FormQuestionInput/FormQuestionInput";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";

export interface IFormQuestionProps {
    formItem: FormItem;
    preview?: boolean;
    onChange?(sender: FormQuestion): Promise<void>;
}

export default class FormQuestion extends BaseComponent<IFormQuestionProps> {

    private get formItem(): FormItem {
        return this.props.formItem;
    }

    private async onChangeAsync(): Promise<void> {
        if (this.props.onChange) {
            await this.props.onChange(this);
        }
    }

    public render(): React.ReactNode {
        const {preview} = this.props;

        return (
            <FormQuestionInput model={this.formItem!}
                               preview={preview}
                               onChange={async () => await this.onChangeAsync()}
                               rotateImageLeft={async (file) => await RentaTasksController.rotateImageLeftAsync(file)}
                               rotateImageRight={async (file) => await RentaTasksController.rotateImageRightAsync(file)}
                               convertImage={async (file) => await RentaTasksController.convertImageAsync(file, true)}
            />
        )
    }
}