import {CustomerApprovalType, WorkOrderFormStatusFilter} from "@/models/Enums";
import {SortDirection} from "@renta-apps/athenaeum-toolkit";

export default class ListFormsRequest {
    
    public constructionSiteId?: string | null = null;
    
    public workOrderId?: string | null = null;

    public from: Date | null = null;

    public to: Date | null = null;

    public status: WorkOrderFormStatusFilter | null = null;

    public approvalTypes: CustomerApprovalType[] = [];

    public reportersIds: string[] = [];
    
    public pageNumber: number = 1;

    public pageSize: number = 100;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;
}