import React from "react";
import {GeoLocation, TimeSpan, Utility} from "@renta-apps/athenaeum-toolkit";
import {AlertModel, AlertType, ch, IBaseComponent, TextAlign, VerticalAlign} from "@renta-apps/athenaeum-react-common";
import {
    AddressDivider,
    Alert,
    Button,
    ButtonContainer,
    ButtonType,
    CellModel,
    Checkbox,
    ColumnDefinition,
    ColumnType,
    Dropdown,
    DropdownOrderBy,
    DropdownRequiredType,
    EmailInput,
    Form,
    Grid,
    IconSize,
    Inline,
    List, OneColumn,
    PageContainer,
    PageHeader,
    PageRow,
    PhoneInput,
    SelectListItem,
    Spinner,
    Tab,
    TabContainer,
    TextInput, ThreeColumns,
    ToolbarButton,
    ToolbarContainer,
    TwoColumns
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import User from "../../models/server/User";
import Setting from "../../models/server/Setting";
import UserRole from "../../models/server/UserRole";
import SaveUserRequest from "../../models/server/requests/SaveUserRequest";
import OrganizationContract from "../../models/server/OrganizationContract";
import {AuthType, LoginResultStatus, UserRoleGroup} from "@/models/Enums";
import DeleteUserResponse from "@/models/server/responses/DeleteUserResponse";
import UserInvitation from "@/models/server/UserInvitation";
import SaveUserResponse from "@/models/server/responses/SaveUserResponse";
import GetUsersRequest from "@/models/server/requests/GetUsersRequest";
import CostPool from "@/models/server/CostPool";
import Warehouse from "@/models/server/Warehouse";
import AddSubcontractorContractModal from "@/pages/UserManagement/AddSubcontractorContractModal/AddSubcontractorContractModal";
import FeatureFlags from "@/helpers/FeatureFlags";
import AddSubcontractorRequest from "@/models/server/requests/AddSubcontractorRequest";
import AddSubcontractorResponse from "@/models/server/responses/AddSubcontractorResponse";
import GetSubcontractorContractsRequest from "@/models/server/requests/GetSubcontractorContractsRequest";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "../../localization/Localizer";
import {FeatureSwitch} from "@/components/FeatureSwitch/FeatureSwitch";
import LocalizationHelper from "@/helpers/LocalizationHelper";

import styles from "../UserManagement/UserManagement.module.scss";

interface IUserManagementProps {
    organizationContracts: OrganizationContract[] | null;
}

interface IUserManagementState {
    filterShowDeleted: boolean;
    filterRoleNames: string[];
    settingsModified: boolean;
    user: User | null;
    prevUser: User | null;
    roles: UserRole[];
    showAddButton: boolean;
    isModified: boolean;
    organizationContracts: OrganizationContract[];
    subcontractorContracts: OrganizationContract[];
    costPools: CostPool[] | null;
    warehouses: Warehouse[] | null;
}

export default class UserManagement extends AuthorizedPage<IUserManagementProps, IUserManagementState> {

    public getTitle(): string {
        return Localizer.topNavAdmin;
    }

    state: IUserManagementState = {
        filterShowDeleted: false,
        filterRoleNames: [],
        user: null,
        prevUser: null,
        roles: [],
        showAddButton: true,
        settingsModified: true,
        isModified: false,
        organizationContracts: [],
        subcontractorContracts: [],
        costPools: null,
        warehouses: null
    };

    private readonly _settingsGridRef: React.RefObject<Grid<Setting>> = React.createRef();
    private readonly _listRef: React.RefObject<List<User>> = React.createRef();
    private readonly _emailRef: React.RefObject<EmailInput> = React.createRef();
    private _warehousesIdsRef: React.RefObject<Dropdown<Warehouse>> = React.createRef();
    private _costPoolsIdsRef: React.RefObject<Dropdown<CostPool>> = React.createRef();
    private readonly _subcontractorsDropdownRef: React.RefObject<Dropdown<OrganizationContract>> = React.createRef();

    private _originalUser: User | null = null;

    private readonly _addSubcontractorContractModalRef: React.RefObject<AddSubcontractorContractModal> = React.createRef();

    private readonly _invitationColumns: ColumnDefinition[] = [
        {
            header: Localizer.userManagementGridCreatedByLanguageItemName,
            accessor: "createdBy",
            minWidth: 100,
            maxWidth: 150,
        },
        {
            header: Localizer.userManagementGridTypeLanguageItemName,
            accessor: "type",
            type: ColumnType.Custom,
            format: "InvitationType",
            minWidth: 90
        },
        {
            header: Localizer.userManagementGridAuthTypeLanguageItemName,
            accessor: "authType",
            type: ColumnType.Custom,
            format: "AuthType",
            minWidth: 90,
        },
        {
            header: Localizer.userManagementGridCreatedAtLanguageItemName,
            accessor: "createdAt",
            format: "D",
            textAlign: TextAlign.Center,
            minWidth: 90,
            verticalAlign: VerticalAlign.Middle
        },
        {
            header: Localizer.userManagementGridExpiresAtLanguageItemName,
            accessor: "validTill",
            format: "D",
            textAlign: TextAlign.Center,
            minWidth: 90,
            init: (cell) => this.initValidTill(cell)
        },
        {
            header: Localizer.userManagementGridProcessedAtLanguageItemName,
            accessor: "processedAt",
            format: "D",
            textAlign: TextAlign.Center,
            minWidth: 90
        },
        {
            header: Localizer.userManagementGridReusableLanguageItemName,
            accessor: (model: UserInvitation) => model.reusable ? "✓" : "",
            textAlign: TextAlign.Center,
            minWidth: 90
        }
    ];

    private readonly _columns: ColumnDefinition[] = [
        {
            header: Localizer.userManagementSettingsKeyLanguageItemName,
            accessor: "key",
            transform: (cell): string => this.transformKeyCell(cell),
            minWidth: 300,
        },
        {
            header: Localizer.userManagementSettingsValueLanguageItemName,
            accessor: "value",
            editable: true,
            type: ColumnType.Text,
            minWidth: 150,
            callback: async (cell) => await this.onChangeSettingsAsync(cell)
        }
    ];

    private get settings(): Setting[] {
        return this.settingsGrid.model.items;
    }

    private get hasSettingsAccess(): boolean {
        const user: User | null = this.findUser();
        return (user != null) && (user.isAdmin);
    }

    private initValidTill(cell: CellModel<UserInvitation>): void {
        const model: UserInvitation = cell.row.model;
        const diff: TimeSpan = Utility.diff(model.validTill, Utility.utcNow());
        const expired: boolean = (diff.totalMilliseconds < 0);
        if (expired) {
            cell.className = "danger";
        }
    }

    private transformKeyCell(cell: CellModel<Setting>): string {
        const setting: Setting = cell.model;

        const key: string = setting.key;

        return Localizer.get(`KnownSettings.${key}`);
    }

    private async cancelModifyingAsync(): Promise<void> {
        Utility.copyTo(this._originalUser, this.user);
        await this.setIsModifiedAsync(false);
    }

    private async setSelectedUser(selectedUser: User | null): Promise<void> {
        if (this.user !== selectedUser) {
            if (this.state.showAddButton) {
                await this.setState({user: selectedUser, prevUser: null, showAddButton: true});
            }

            this._originalUser = Utility.clone(selectedUser);
        }
    }

    private get userFullName(): string {
        return (this.user) ? TransformProvider.userToString(this.user) : "";
    }

    private async handleSettingsSubmitAsync(): Promise<void> {

        await this.postAsync("api/admin/SaveSettings", this.settings);

        await this.alertMessageAsync(Localizer.userManagementSettingsSaved, true);
    }

    private async saveAsync(): Promise<void> {
        const user: User = this.user!;
        const newUser: boolean = (!user.id);

        const request = new SaveUserRequest();
        request.id = user.id;
        request.authType = user.authType;
        request.roleName = user.role.roleName;
        request.email = user.email;
        request.phone = user.phone;
        request.firstname = user.firstname;
        request.lastName = user.lastName;
        request.middleName = user.middleName;
        request.language = user.language;
        request.location = user.homeLocation;
        request.agreementAccepted = this.user!.agreementAccepted;
        request.registrationAccepted = this.user!.registrationAccepted;
        request.warehouseId = this.user!.warehouseId;
        request.costPoolIds = (this.user!.costPools || []).map(costPool => costPool.id);
        request.filtersBehavior = user.filtersBehavior;
        request.externalId = user.externalId;
        request.useConstructionSiteCommunicationLanguage = user.useConstructionSiteCommunicationLanguage;
        request.communicationLanguage = user.communicationLanguage ?? LocalizationHelper.defaultLanguage;

        if (this.showEmployeeNumber) {
            request.employeeNumber = user.employeeNumber;
        }

        if (this.isSubcontractorRole) {
            request.organizationContractIds = [user.role.organizationContractId];
            
            if (this.isSubcontractorMounter) {
                request.canCreateWorkOrder = user.canCreateWorkOrder;
                request.canCompleteWorkOrder = user.canCompleteWorkOrder;
                request.canProcessForm = user.canProcessForm;
            }
        } else if (this.isContactPersonRole) {
            request.organizationContractIds = user.roles.map(item => item.organizationContractId);
        }

        const response: SaveUserResponse = await this.postAsync("api/admin/saveUser", request);

        if (response.failed) {
            let message: string;

            if (response.emailExists && response.phoneExists) {
                message = `${Utility.format(Localizer.genericUserEmailExists, request.email)}\n${Utility.format(Localizer.genericUserPhoneExists, request.phone)}`;
            } else if (response.emailExists) {
                message = Utility.format(Localizer.genericUserEmailExists, request.email)
            } else {
                message = Utility.format(Localizer.genericUserPhoneExists, request.phone);
            }

            await this.alertErrorAsync(message, true);
            return;
        }

        if (response.invitationSentFailed) {
            const message: string = Utility.format(Localizer.userManagementAlertErrorPhoneNumberInvalid, this.userFullName);
            await this.alertErrorAsync(message, true);
            return;
        }

        const message: string = Utility.format(Localizer.userManagementAlertMessageAccountSaved, this.userFullName);
        await this.alertMessageAsync(message, true);

        const responseUser: User = response.user!;

        this._originalUser = responseUser!;

        if (newUser) {

            await this.list.reloadAsync();

            await this.setState({user: responseUser, showAddButton: true, isModified: false});

            this.list.scrollToSelected();

        } else {
            await this.setIsModifiedAsync(false);

            await this.list.reRenderAsync();
        }
    }

    private async resetPasswordAsync(): Promise<void> {
        const userId: string = this.user!.id;
        await this.postAsync<LoginResultStatus>("api/admin/ResetPassword", userId);
        await this.list.reloadAsync();
        await this.list.reRenderAsync();
    }

    private async setFilterShowDeletedAsync(filterShowDeleted: boolean): Promise<void> {
        await this.setState({filterShowDeleted});
        await this.list.reloadAsync();
    }

    private async setFilterRoleNamesAsync(filterRoles: UserRole[]): Promise<void> {
        const filterRoleNames: string[] = filterRoles.map(item => item.roleName);
        await this.setState({filterRoleNames});
        await this.list.reloadAsync();
    }

    private async addUserAsync(): Promise<void> {
        const prevUser: User | null = this.user;

        const user = new User();
        user.language = Localizer.language;
        user.role = this.state.roles[0];
        user.warehouse = ((this.state.warehouses) && (this.state.warehouses.length > 0)) ? this.state.warehouses[0] : null;
        user.warehouseId = (user.warehouse) ? user.warehouse.id : null;

        await this.setState({user, prevUser, showAddButton: false});

        if (this._emailRef.current) {
            this._emailRef.current.focus();
        }
    }

    private async resendInvitationAsync(): Promise<void> {
        const userId: string = this.user!.id;
        await this.postAsync("api/admin/resendInvitation", userId);
        await this.list.reloadAsync();
        await this.reRenderAsync();
        await this.list.reRenderAsync();
    }

    private async setIsModifiedAsync(isModified = true, forceReRender: boolean = false): Promise<void> {
        isModified = isModified && this.state.showAddButton;
        if ((forceReRender) || (isModified !== this.state.isModified)) {
            await this.setState({isModified});
        }
    }

    private get isModified(): boolean {
        return this.state.isModified;
    }

    private async setPhoneAsync(value: string): Promise<void> {
        this.user!.phone = value;
        await this.setIsModifiedAsync();
    }

    private async setEmailAsync(value: string): Promise<void> {
        this.user!.email = value;
        await this.setIsModifiedAsync();
    }

    private async setAuthTypeAsync(item: SelectListItem, userInteration: boolean): Promise<void> {
        if (userInteration) {
            await this.setIsModifiedAsync();
        }

        const user: User = this.user!;
        user.authType = parseInt(item.value);
        await this.reRenderAsync();
    }

    private async setWarehouseAsync(value: Warehouse, userInteraction: boolean): Promise<void> {

        if (userInteraction) {
            await this.setIsModifiedAsync();
        }

        this.user!.warehouseId = value.id;
        this.user!.warehouse = value;

        await this.reRenderAsync();
    }

    private async setCostPoolsAsync(sender: Dropdown<CostPool>, userInteraction: boolean): Promise<void> {
        if (userInteraction) {
            await this.setIsModifiedAsync();
        }

        this.user!.costPools = sender.selectedItems;

        await this.reRenderAsync();
    }

    public get alert(): AlertModel {
        const alertModel = new AlertModel();
        alertModel.message = Utility.format(Localizer.userManagementAlertUserPasswordInfo, this.userFullName);
        alertModel.dismissible = false;
        alertModel.alertType = AlertType.Info;
        return alertModel;
    }

    public async deleteUserAsync(): Promise<void> {
        const user: User = this.user!;

        const response: DeleteUserResponse = await this.postAsync("api/admin/deleteUser", user.id);

        const message: string = (response.removedPermanently)
            ? Utility.format(Localizer.userManagementUserDeletedPermanently, this.userFullName)
            : Utility.format(Localizer.get(Localizer.userManagementUserDeleted, this.userFullName));

        await this.alertMessageAsync(message, true);

        if (!this.filterShowDeleted || response.removedPermanently) {
            await this.setState({user: null});

            await this.list.reloadAsync();

            this.list.scrollToSelected();
        } else {
            user.isDeleted = true;

            await this.list.reRenderAsync();

            await this.reRenderAsync();
        }
    }

    private async restoreUserAsync(): Promise<void> {
        const user: User = this.user!;

        await this.postAsync("api/admin/restoreUser", user.id);

        user.isDeleted = false;

        await this.alertMessageAsync(Localizer.get(Localizer.userManagementUserRestored, user.email), true);

        await this.list.reRenderAsync();

        await this.reRenderAsync();
    }

    private async unlockUserAsync(): Promise<void> {
        const user: User = this.user!;

        await this.postAsync("api/admin/unlockUser", user.id);

        user.isLocked = false;

        const message: string = Utility.format(Localizer.userManagementAlertMessageUnlockUser, this.userFullName);

        await this.alertMessageAsync(message, true);

        await this.list.reRenderAsync();

        await this.reRenderAsync();
    }

    private async cancelAddUserAsync() {
        await this.setState({user: this.state.prevUser, prevUser: null, showAddButton: true});
    }

    private get canDelete(): boolean {
        const me: boolean = (ch.getUser().id == this.user!.id);
        return !me;
    }

    private async getUsersAsync(sender: List<User>): Promise<User[]> {
        const request = new GetUsersRequest();
        request.showDeleted = this.filterShowDeleted;
        request.roleNames = this.filterRoleNames;
        return await sender.postAsync("api/admin/getUsers", request);
    }

    private async onFirstNameChangeAsync(item: string) {
        const user = this.user;
        user!.firstname = item;
        await this.setIsModifiedAsync();
    }

    private async onLastNameChangeAsync(item: string) {
        const user = this.user;
        user!.lastName = item;
        await this.setIsModifiedAsync();
    }

    private async onMiddleNameChangeAsync(item: string) {
        const user = this.user;
        user!.middleName = item;
        await this.setIsModifiedAsync();
    }

    private async onCommunicationLanguageChangeAsync(item: number, userInteraction: boolean): Promise<void> {
        if (userInteraction) 
        {
            const user = this.user;
            user!.communicationLanguage = item;
            await this.setIsModifiedAsync();
        }
    }

    private async onEmployeeNumberChangeAsync(item: string) {
        const user = this.user;
        user!.employeeNumber = item;
        await this.setIsModifiedAsync();
    }

    private async onExternalIdChangeAsync(item: string) {
        const user = this.user;
        user!.externalId = item;
        await this.setIsModifiedAsync();
    }

    private async onCanCreateWorkOrderChangeAsync(canCreateWorkOrder: boolean): Promise<void> {
        const user = this.user;
        user!.canCreateWorkOrder = canCreateWorkOrder;
        await this.setIsModifiedAsync();
    }

    private async onCanCompleteWorkOrderChangeAsync(canCompleteWorkOrder: boolean): Promise<void> {
        const user = this.user;
        user!.canCompleteWorkOrder = canCompleteWorkOrder;
        await this.setIsModifiedAsync();
    }

    private async onCanProcessFormChangeAsync(canProcessForm: boolean): Promise<void> {
        const user = this.user;
        user!.canProcessForm = canProcessForm;
        await this.setIsModifiedAsync();
    }

    private async onAddressChangeAsync(item: GeoLocation) {
        const user = this.user;
        user!.homeLocation = item;
        await this.setIsModifiedAsync();
    }
    
    private async onCommunicationLanguageCheckboxChangeAsync(value: boolean) {
        const user = this.user;
        user!.useConstructionSiteCommunicationLanguage = value;
        await this.setIsModifiedAsync();
    }

    private async setUserRole(userRole: UserRole, userInteraction: boolean): Promise<void> {
        if (userInteraction) {

            const user: User = this.user!;
            const prevUserRole: UserRole = user.role;

            if (this.isSubcontractorRole) {
                const subcontractorOrganizationId: string = user.role.organizationContractId;
                user.role = {...userRole, organizationContractId: subcontractorOrganizationId};
            } else {
                user.role = userRole;
            }

            if (userRole.group == UserRoleGroup.Admins) {
                user.costPools = this.state.costPools || [];
            } else if (prevUserRole.group == UserRoleGroup.Admins) {
                user.costPools = [];
            }

            await this.setIsModifiedAsync(true, true);

            await this.reRenderAsync();

            await this.reloadUserCostPoolsIds();
        }
    }

    private get isSubcontractorRole(): boolean {
        return (this.user != null) && (this.user.role.group == UserRoleGroup.Subcontractors);
    }

    private async getSettingsAsync(grid: Grid<Setting>): Promise<Setting[]> {
        return await grid.getAsync("api/admin/listSettings");
    }

    private async onChangeSettingsAsync(cell: CellModel<unknown>): Promise<void> {
        const settingsModified: boolean = cell.grid.modified;
        await this.setState({settingsModified});
    }

    private async reloadUserCostPoolsIds(): Promise<void> {
        //Multi-dropdown does not support SelectedListItems, should be reloaded manually
        if (this._costPoolsIdsRef.current) {
            await this._costPoolsIdsRef.current.reRenderAsync();
        }
    }

    private get isContactPersonRole(): boolean {
        return (this.user != null) && (this.user.role.roleName == "ContactPerson");
    }

    private get isSubcontractorMounter(): boolean {
        return (this.user != null) && (this.user.role.roleName == "SubcontractorMounter");
    }

    private get showEmployeeNumber(): boolean {
        return (UnleashHelper.isEnabled(FeatureFlags.EmployeeNumber)) &&
            (this.user != null) &&
            (this.user.role.group === UserRoleGroup.Employees || this.user.role.group === UserRoleGroup.Managers);
    }

    private get showExternalId(): boolean {
        return (this.user != null) &&
            (this.user.role.group === UserRoleGroup.Managers);
    }

    private async setRoleContractAsync(contract: OrganizationContract, userInteraction: boolean): Promise<void> {
        if ((this.user != null) && (this.user.role.organizationContractId != contract.id)) {
            this.user.role.organizationContractId = contract.id;

            if (userInteraction) {
                await this.setIsModifiedAsync();
            }
        }
    }

    private async setRoleContractsAsync(contracts: OrganizationContract[], userInteraction: boolean): Promise<void> {
        if (this.user != null) { //&& (this.user.role.organizationContractId != contract.id)) {
            const roleName: string = this.user.role.roleName;
            const group: UserRoleGroup = this.user.role.group;
            this.user.roles = contracts.map(contract => new UserRole(roleName, contract.id, group));

            if (userInteraction) {
                await this.setIsModifiedAsync();
            }
        }
    }

    private get subcontractorContracts(): OrganizationContract[] {
        return this.state.subcontractorContracts;
    }

    private get organizationContracts(): OrganizationContract[] {
        return this.state.organizationContracts;
    }

    private get user(): User | null {
        return this.state.user;
    }

    private get list(): List<User> {
        return this._listRef.current!;
    }

    private get settingsGrid(): Grid<Setting> {
        return this._settingsGridRef.current!;
    }

    private get filterShowDeleted(): boolean {
        return this.state.filterShowDeleted;
    }

    private get filterRoleNames(): string[] {
        return this.state.filterRoleNames;
    }

    private get isEmailRequired(): boolean {
        return ((this.user != null) && ((this.user.authType == AuthType.Email) || (this.user.role.roleName == "ContactPerson")));
    }

    private get isPhoneRequired(): boolean {
        return ((this.user != null) && ((this.user.authType == AuthType.Phone) || (this.user.role.roleName == "ContactPerson")));
    }

    private get multipleCostPools(): boolean {
        return ((this.user != null) && (this.user!.role.group != UserRoleGroup.Admins));
    }

    private get multipleCostPoolsText(): string {
        return (!this.multipleCostPools)
            ? Localizer.userManagementDropdownMultipleCostPoolsText
            : "";
    }

    private async addSubcontractorContract(sender: IBaseComponent, request: AddSubcontractorRequest): Promise<boolean> {
        const response: AddSubcontractorResponse = await sender.postAsync("api/admin/addSubcontractor", request);

        if (response.contractAlreadyExists) {
            return false;
        }

        const message = Localizer.get(Localizer.subcontractorCreatedFlyout, response.organizationContract!.name);

        await ch.flyoutMessageAsync(message);

        const subcontractorContract: OrganizationContract = response.organizationContract!;

        this.state.subcontractorContracts.push(subcontractorContract);

        this.user!.role.organizationContractId = subcontractorContract.id;

        await this.setIsModifiedAsync(true, true);

        return true;
    }

    public async initializeAsync(): Promise<void> {

        await this.setSpinnerAsync(true);
        
        const roles: UserRole[] = await this.getAsync("api/admin/getUserRoles");

        const request = new GetSubcontractorContractsRequest();
        const subcontractorContracts: OrganizationContract[] = await this.postAsync("api/admin/getSubcontractorContracts", request);

        const organizationContracts: OrganizationContract[] = await this.postCacheAsync("api/admin/getActiveCustomers", 60000);

        const warehouses: Warehouse[] = await this.postAsync("api/admin/getWarehouses");

        const costPools: CostPool[] = await this.postAsync("api/admin/getCostPools");

        await this.setState({roles, subcontractorContracts, organizationContracts, costPools, warehouses});
        await this.setSpinnerAsync(false);
    }

    private async openAddSubcontractorContractModalAsync() {
        await this._addSubcontractorContractModalRef.current!.openAsync();
    }

    public hasSpinner(): boolean {
        return true;
    }
    
    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.userManagement} fullHeight>
                {
                    (this.isSpinning()) && <Spinner/>
                }

                <PageHeader title={Localizer.userManagementTitle} withTabs/>

                <PageRow>
                    <div className="col">

                        <TabContainer id="userManagementTabs" scale>

                            <Tab id="accounts" title={Localizer.userManagementUsersTab}>

                                <ToolbarContainer>

                                    <Form inline className="d-flex">

                                        <Dropdown inline noWrap multiple
                                                  id="roleNameFilter"
                                                  minWidth={210}
                                                  nothingSelectedText={Localizer.userManagementChooseRole}
                                                  label={Localizer.formInputRole}
                                                  orderBy={DropdownOrderBy.None}
                                                  items={this.state.roles}
                                                  onChange={async (sender) => await this.setFilterRoleNamesAsync(sender.selectedItems)}
                                        />

                                        <Checkbox inline
                                                  id="showDeletedCheckbox"
                                                  label={Localizer.userManagementShowDeleted}
                                                  value={this.filterShowDeleted}
                                                  onChange={async (_, value) => await this.setFilterShowDeletedAsync(value)}
                                        />

                                    </Form>

                                    <Inline>

                                        <ToolbarButton id="addUserButton"
                                                       label={Localizer.userManagementAddUser}
                                                       icon={{name: "plus", size: IconSize.Large}}
                                                       type={ButtonType.Orange}
                                                       disabled={this.isSpinning() || !this.state.showAddButton || this.isModified}
                                                       onClick={async () => await this.addUserAsync()}
                                        />

                                    </Inline>

                                </ToolbarContainer>

                                <div className="row h-100">

                                    <div className="col-md-4">

                                        <List required noGrouping absoluteListItems
                                              id="users"
                                              ref={this._listRef}
                                              maxHeight={"auto"}
                                              disabled={!this.state.showAddButton || this.isModified}
                                              orderBy={DropdownOrderBy.None}
                                              filterMinLength={10}
                                              fetchItems={async (sender) => await this.getUsersAsync(sender)}
                                              selectedItem={this.user || undefined}
                                              onChange={async (_, item) => await this.setSelectedUser(item)}
                                        />

                                    </div>

                                    <div className="col-md-8">

                                        {
                                            (this.user != null) &&
                                            (
                                                <TabContainer id="userManagementTabs">

                                                    <Tab id="account" title={Localizer.topNavAccount}>

                                                        <Form id="accountForm" onSubmit={async () => await this.saveAsync()}>

                                                            <TwoColumns>
                                                                <Dropdown required noSubtext
                                                                          id="authType"
                                                                          label={Localizer.userManagementLabelAuthenticationType}
                                                                          disabled={!!this.user.id}
                                                                          orderBy={DropdownOrderBy.None}
                                                                          items={EnumProvider.getAuthTypeItems()}
                                                                          selectedItem={EnumProvider.getAuthTypeItem(this.user.authType)}
                                                                          onChange={async (_, item, userInteraction) => await this.setAuthTypeAsync(item!, userInteraction)}
                                                                />

                                                                <Dropdown required
                                                                          id="roleName"
                                                                          nothingSelectedText={Localizer.userManagementChooseRole}
                                                                          label={Localizer.formInputRole}
                                                                          orderBy={DropdownOrderBy.None}
                                                                          value={this.user.role.roleName}
                                                                          items={this.state.roles}
                                                                          selectedItem={this.user.role}
                                                                          onChange={(_, item, userInteraction) => this.setUserRole(item!, userInteraction)}
                                                                />

                                                            </TwoColumns>

                                                            <TwoColumns>

                                                                {
                                                                    (this.isContactPersonRole) &&
                                                                    (
                                                                        <Dropdown required multiple autoGroupSelected noWrap noSubtext
                                                                                  id="organizationContractIds"
                                                                                  requiredType={DropdownRequiredType.Manual}
                                                                                  label={Localizer.userManagementOrganizationContractsLabel}
                                                                                  filterMaxLength={10000000}
                                                                                  items={this.organizationContracts}
                                                                                  selectedItems={this.user.roles.map(item => item.organizationContractId)}
                                                                                  onChange={(sender, _, userInteraction) => this.setRoleContractsAsync(sender.selectedItems, userInteraction)}
                                                                        />
                                                                    )
                                                                }

                                                            </TwoColumns>
                                                            
                                                            <FeatureSwitch flagName={FeatureFlags.AzureAdSso}>
                                                                {
                                                                    (this.user.sso) &&
                                                                    <p>{Localizer.userManagementUserSsoInfoLabel}</p>
                                                                }
                                                            </FeatureSwitch>

                                                            <TwoColumns>

                                                                <EmailInput id="email"
                                                                            ref={this._emailRef}
                                                                            label={Localizer.formInputEmail}
                                                                            required={this.isEmailRequired}
                                                                            value={this.user.email}
                                                                            readonly={(this.user.authType == AuthType.Email) && (!!this.user.id)}
                                                                            onChange={async (_, value) => await this.setEmailAsync(value)}
                                                                />

                                                                <PhoneInput id="phone"
                                                                            label={Localizer.formInputPhone}
                                                                            required={this.isPhoneRequired}
                                                                            value={this.user.phone}
                                                                            readonly={(this.user.authType == AuthType.Phone) && (!!this.user.id)}
                                                                            onChange={async (_, value) => await this.setPhoneAsync(value)}
                                                                />

                                                            </TwoColumns>

                                                            {
                                                                (this.isSubcontractorRole) &&
                                                                (
                                                                    <OneColumn className={this.css(styles.subcontractorSection, styles.open)}>
                                                                        <TwoColumns>

                                                                            <Dropdown id="subcontractorContracts" ref={this._subcontractorsDropdownRef}
                                                                                      required
                                                                                      items={this.subcontractorContracts}
                                                                                      label={Localizer.userManagementDropdownSubcontractor}
                                                                                      selectedItem={this.user.role.organizationContractId}
                                                                                      onChange={(_, value, userInteraction) => this.setRoleContractAsync(value!, userInteraction)}
                                                                            />

                                                                            <Button id={"addSubcontractorContract"} icon={{name: "plus", size: IconSize.Large}} className={styles.padding}
                                                                                    type={ButtonType.Orange}
                                                                                    title={Localizer.userManagementButtonAddNewSubcontractor}
                                                                                    label={Localizer.userManagementButtonAddNewSubcontractor}
                                                                                    onClick={async () => await this.openAddSubcontractorContractModalAsync()}
                                                                            />

                                                                        </TwoColumns>
                                                                    </OneColumn>
                                                                )
                                                            }

                                                            {
                                                                (!this.isContactPersonRole && !this.isSubcontractorMounter) &&
                                                                (
                                                                    <TwoColumns>

                                                                        <Dropdown required autoGroupSelected noSubtext
                                                                                  id="warehousesIds"
                                                                                  ref={this._warehousesIdsRef}
                                                                                  label={Localizer.genericWarehouse}
                                                                                  requiredType={DropdownRequiredType.Restricted}
                                                                                  items={this.state.warehouses || []}
                                                                                  selectedItem={this.user.warehouse || undefined}
                                                                                  onChange={async (_, item, userInteraction) => await this.setWarehouseAsync(item!, userInteraction)}
                                                                        />

                                                                        <Dropdown required noSubtext multiple autoGroupSelected
                                                                                  id="costPoolsIds"
                                                                                  ref={this._costPoolsIdsRef}
                                                                                  label={Localizer.genericCostPools}
                                                                                  requiredType={DropdownRequiredType.Restricted}
                                                                                  disabled={!this.multipleCostPools}
                                                                                  items={this.state.costPools || []}
                                                                                  selectedItems={this.user.costPools || []}
                                                                                  selectedTextTransform={() => this.multipleCostPoolsText}
                                                                                  onChange={async (sender, _, userInteraction) => await this.setCostPoolsAsync(sender, userInteraction)}
                                                                        />

                                                                    </TwoColumns>
                                                                )
                                                            }

                                                            {
                                                                (this.showEmployeeNumber || this.showExternalId) &&
                                                                (
                                                                    <TwoColumns>
                                                                        
                                                                        {
                                                                            (this.showEmployeeNumber &&
                                                                                <TextInput required
                                                                                           id="employeeNumber"
                                                                                           label={Localizer.formInputEmployeeNumber}
                                                                                           value={this.user.employeeNumber}
                                                                                           onChange={async (_, item) => await this.onEmployeeNumberChangeAsync(item!)}
                                                                                />
                                                                            )
                                                                        }
                                                                        
                                                                        {
                                                                            (this.showExternalId &&
                                                                                <TextInput required
                                                                                           id="externalId"
                                                                                           label={Localizer.formInputExternalId}
                                                                                           value={this.user.externalId}
                                                                                           onChange={async (_, item) => await this.onExternalIdChangeAsync(item!)}
                                                                                />
                                                                            )
                                                                        }
                                                                        
                                                                    </TwoColumns>
                                                                )
                                                            }

                                                            {
                                                                (this.isSubcontractorMounter) &&
                                                                (
                                                                    <ThreeColumns>

                                                                        <Checkbox id={"canCreateWorkOrderPermissions"}
                                                                                  label={Localizer.userManagementMounterPermissionsCanCreateWorkOrder}
                                                                                  value={this.user.canCreateWorkOrder}
                                                                                  onChange={async (_, value) => await this.onCanCreateWorkOrderChangeAsync(value)}
                                                                        />

                                                                        <Checkbox id={"canCompleteWorkOrderPermissions"}
                                                                                  label={Localizer.userManagementMounterPermissionsCanCompleteWorkOrder}
                                                                                  value={this.user.canCompleteWorkOrder}
                                                                                  onChange={async (_, value) => await this.onCanCompleteWorkOrderChangeAsync(value)}
                                                                        />

                                                                        <Checkbox id={"canProcessFormPermissions"}
                                                                                  label={Localizer.userManagementMounterPermissionsCanProcessForm}
                                                                                  value={this.user.canProcessForm}
                                                                                  onChange={async (_, value) => await this.onCanProcessFormChangeAsync(value)}
                                                                        />

                                                                    </ThreeColumns>
                                                                )
                                                            }

                                                            <TwoColumns>

                                                                <TextInput required
                                                                           id="firstname"
                                                                           label={Localizer.formInputFirstname}
                                                                           value={this.user.firstname}
                                                                           onChange={async (_, item) => await this.onFirstNameChangeAsync(item!)}
                                                                />

                                                                <TextInput required
                                                                           id="lastName"
                                                                           label={Localizer.formInputLastname}
                                                                           value={this.user.lastName}
                                                                           onChange={async (_, item) => await this.onLastNameChangeAsync(item!)}
                                                                />

                                                            </TwoColumns>

                                                            <TwoColumns>

                                                                <TextInput id="middleName"
                                                                           label={Localizer.formInputMiddlename}
                                                                           value={this.user.middleName}
                                                                           onChange={async (_, item) => await this.onMiddleNameChangeAsync(item!)}
                                                                />

                                                                <Dropdown required
                                                                          autoGroupSelected
                                                                          requiredType={DropdownRequiredType.Restricted}
                                                                          id="communicationLanguage"
                                                                          label={Localizer.formInputLanguage}
                                                                          items={EnumProvider.getCommunicationLanguageItems()}
                                                                          selectedItem={EnumProvider.getCommunicationLanguageItem(this.user.communicationLanguage ?? LocalizationHelper.defaultLanguage)}
                                                                          onChange={async (_, item, userInteraction) => await this.onCommunicationLanguageChangeAsync(parseInt(item!.value), userInteraction)}
                                                                />

                                                            </TwoColumns>

                                                            <AddressDivider id="formattedAddress"
                                                                            location={this.user.homeLocation || undefined}
                                                                            onChange={async (_, item) => await this.onAddressChangeAsync(item!)}
                                                            />

                                                            <TwoColumns>

                                                                <Checkbox label={Localizer.userManagementUseConstructionSiteCommunicationLanguage}
                                                                          value={this.user.useConstructionSiteCommunicationLanguage}
                                                                          onChange={async (_, value) => await this.onCommunicationLanguageCheckboxChangeAsync(value)}
                                                                />

                                                            </TwoColumns>

                                                            <ButtonContainer>

                                                                {
                                                                    (!this.state.showAddButton) &&
                                                                    (
                                                                        <Button small
                                                                                id="cancelAddUserButton"
                                                                                minWidth={90}
                                                                                label={Localizer.genericActionCancel}
                                                                                type={ButtonType.Primary}
                                                                                icon={{name: "far ban", size: IconSize.Large}}
                                                                                onClick={async () => await this.cancelAddUserAsync()}
                                                                        />
                                                                    )
                                                                }

                                                                {
                                                                    (this.isModified) &&
                                                                    (
                                                                        <Button small
                                                                                id="cancelChangesButton"
                                                                                minWidth={90}
                                                                                label={Localizer.genericActionCancel}
                                                                                type={ButtonType.Primary}
                                                                                icon={{name: "far ban", size: IconSize.Large}}
                                                                                confirm={Localizer.userManagementConfirmationButtonRollback}
                                                                                onClick={async () => await this.cancelModifyingAsync()}
                                                                        />
                                                                    )
                                                                }

                                                                {
                                                                    ((this.state.showAddButton) && (!this.user.isDeleted)) &&
                                                                    (
                                                                        <Button small
                                                                                id="deleteUserButton"
                                                                                minWidth={90}
                                                                                label={Localizer.userManagementButtonDelete}
                                                                                icon={{name: "trash-alt", size: IconSize.Large}}
                                                                                type={ButtonType.Primary}
                                                                                disabled={!this.canDelete || this.isModified}
                                                                                onClick={async () => await this.deleteUserAsync()}
                                                                                confirm={Utility.format(Localizer.userManagementConfirmationButtonDeleteUser, this.userFullName)}
                                                                        />
                                                                    )
                                                                }

                                                                {
                                                                    ((this.state.showAddButton) && (this.user.isDeleted)) &&
                                                                    (
                                                                        <Button small
                                                                                id="restoreUserButton"
                                                                                minWidth={90}
                                                                                label={Localizer.userManagementButtonRestore}
                                                                                icon={{name: "trash-restore", size: IconSize.Large}}
                                                                                type={ButtonType.Primary}
                                                                                onClick={async () => await this.restoreUserAsync()}
                                                                                confirm={Utility.format(Localizer.userManagementConfirmationButtonRestoreUser, this.userFullName)}
                                                                        />
                                                                    )
                                                                }

                                                                {
                                                                    ((this.state.showAddButton) && (!this.user.isDeleted) && (this.user.isLocked)) &&
                                                                    (
                                                                        <Button small
                                                                                id="unlockUserButton"
                                                                                minWidth={90}
                                                                                label={Localizer.userManagementButtonUnlock}
                                                                                icon={{name: "unlock", size: IconSize.Large}}
                                                                                type={ButtonType.Success}
                                                                                onClick={async () => await this.unlockUserAsync()}
                                                                                confirm={Utility.format(Localizer.userManagementConfirmationButtonUnlockUser, this.userFullName)}
                                                                        />
                                                                    )
                                                                }

                                                                <Button submit
                                                                        id="saveUserButton"
                                                                        label={Localizer.formSave}
                                                                        type={ButtonType.Orange}
                                                                        icon={{name: "far save"}}
                                                                />

                                                            </ButtonContainer>

                                                        </Form>

                                                    </Tab>

                                                    {
                                                        (this.state.showAddButton) &&
                                                        (

                                                            <Tab id="invitations" title={Localizer.userManagementTabInvitations}>

                                                                <div className={styles.passwordInfo}>
                                                                    {
                                                                        (!this.user.hasPassword) &&
                                                                        (
                                                                            <Alert model={this.alert}/>
                                                                        )
                                                                    }
                                                                </div>

                                                                <div className={styles.invitations}>

                                                                    <Grid id={"invitationsGrid"}
                                                                          columns={this._invitationColumns}
                                                                          data={this.user.invitations}
                                                                          noDataText={Localizer.userManagementGridNoInvitationsText}
                                                                    />

                                                                </div>

                                                                <ButtonContainer>

                                                                    <Button small
                                                                            minWidth={90}
                                                                            label={Localizer.userManagementButtonResendInvitation}
                                                                            icon={{name: "envelope", size: IconSize.Large}}
                                                                            disabled={this.user.isDeleted || this.user.isLocked || this.user.hasPassword}
                                                                            type={ButtonType.Primary}
                                                                            onClick={async () => await this.resendInvitationAsync()}
                                                                            id="resend"
                                                                            confirm={Utility.format(Localizer.userManagementConfirmationButtonResendInvitation, this.userFullName)}
                                                                    />

                                                                    {
                                                                        (!this.user.sso) &&
                                                                        <Button small
                                                                                minWidth={90}
                                                                                label={Localizer.userManagementButtonResetPassword}
                                                                                icon={{name: "repeat", size: IconSize.Large}}
                                                                                disabled={this.user.isDeleted || this.user.isLocked || !this.user.hasPassword}
                                                                                type={ButtonType.Success}
                                                                                onClick={async () => await this.resetPasswordAsync()}
                                                                                confirm={Utility.format(Localizer.userManagementConfirmationButtonResetPassword, this.userFullName)}
                                                                        />
                                                                    }

                                                                </ButtonContainer>

                                                            </Tab>
                                                        )
                                                    }

                                                    {
                                                        (this.isContactPersonRole && UnleashHelper.isEnabled(FeatureFlags.ShowContactPersonExternalIds)) &&
                                                        <Tab id="organizationContractMappings" title="External ids">
                                                            <div>
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Contract</th>
                                                                            <th>External id</th>
                                                                        </tr>
                                                                    </thead>
                                                                    
                                                                    <tbody>
                                                                        { this.user.roles.map(role =>
                                                                            <tr key={role.organizationContractId + role.externalId}>
                                                                                <td>{role.organizationContract?.name} ({role.organizationContract?.externalId})</td>
                                                                                <td>{role.externalId}</td>
                                                                            </tr>
                                                                        )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Tab>
                                                    }
                                                </TabContainer>
                                            )
                                        }

                                    </div>

                                </div>

                            </Tab>

                            <Tab id="system" title={Localizer.userManagementSettingsTab}>

                                <Form id="form" onSubmit={async () => await this.handleSettingsSubmitAsync()}>

                                    <div>

                                        <Grid id="settings"
                                              ref={this._settingsGridRef}
                                              minWidth={400}
                                              readonly={!this.hasSettingsAccess}
                                              columns={this._columns}
                                              fetchData={async (sender) => await this.getSettingsAsync(sender)}
                                        />

                                    </div>

                                    <ButtonContainer>

                                        {
                                            (this.hasSettingsAccess) &&
                                            (
                                                <Button submit
                                                        id="saveSettingsButton"
                                                        label={Localizer.formSave}
                                                        disabled={!this.state.settingsModified}
                                                        type={ButtonType.Orange}
                                                        icon={{name: "far save"}}
                                                />
                                            )
                                        }

                                    </ButtonContainer>

                                </Form>

                            </Tab>

                        </TabContainer>
                    </div>
                </PageRow>

                <AddSubcontractorContractModal
                    ref={this._addSubcontractorContractModalRef}
                    addSubcontractorContract={async (sender, request) => await this.addSubcontractorContract(sender, request)}
                />

            </PageContainer>
        );
    }
}