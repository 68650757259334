import User from "@/models/server/User";
import CostPool from "@/models/server/CostPool";

export default class WarehouseCostPool {
    public id: string = "";
    
    public costPoolId: string = "";
    
    public costPool: CostPool | null = null;

    public warehouseId: string = "";

    public deleted: boolean = false;

    public deletedAt: Date | null = null;

    public deletedBy: User | null = null;
    
    public isWarehouseCostPool: boolean = true;
}