import FormItem from "@/models/server/forms/FormItem";
import {CustomFormType, FormHeaderType} from "@/models/Enums";
import FormMapping from "@/models/server/forms/FormMapping";

export default class SaveFormDefinitionRequest {    
   
    public formDefinitionId: string | null = null;
    
    public name: string = "";
    
    public description: string | null = null;

    public customType: CustomFormType = CustomFormType.Undefined;

    public headers: FormHeaderType[] = [];

    public mapping: FormMapping = new FormMapping();

    public items: FormItem[] = [];
}