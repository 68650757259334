import {onRedirect, pageData} from "@/helpers/CypressHelper";

context("(Mobile) Renta Tasks: List work orders", () => {

    beforeEach(() => {
        cy.init("Mobile");
    });

    it("Should list work orders for mounter", () => {

        cy.loginAsMounter();
        test();
    });
    
    function test(): void {
        onRedirect(() => pageData().dashboard.widgets.mobile.workOrders().click());

        // Use seed data work orders
        pageData().dashboard.workOrders.workOrdersList().should("have.length.at.least", 1);
    }
});
