import React, {ReactNode} from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import FormItemModel from "@/models/server/forms/FormItem";
import User from "@/models/server/User";
import SendFormReportRequest from "@/models/server/requests/SendFormReportRequest";
import FormModel from "@/models/server/forms/FormModel";
import {Button, ButtonType, Checkbox, ICheckboxProps, InlineType, Modal} from "@renta-apps/athenaeum-react-components";
import FormButton from "@/components/Form/FormButton/FormButton";
import {UserRoleGroup} from "@/models/Enums";
import Localizer from "@/localization/Localizer";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import FormSummaryTableGroup from "@/components/Form/FormSummaryTable/FormSummaryTableGroup/FormSummaryTableGroup";
import FormItemView from "@/components/Form/FormItem/FormItemView";
import FormSummarySignaturesGroup from "@/components/Form/FormSummaryTable/FormSummarySignaturesGroup/FormSummarySignaturesGroup";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";

import styles from "./FormSummaryTable.module.scss";

export interface IFormSummaryTableProps {
    className?: string;
    model: FormModel;

    openStep(item: FormItemModel): Promise<void>;

    openMountersSignaturesStep(): Promise<void>
}

interface IFormSummaryTableState {
    /** Users who can be selected to receive the Form. */
    viewers: User[];

    /** Users who are selected to receive the Form. */
    receivers: User[];
}

export default class FormSummaryTable
    extends BaseComponent<IFormSummaryTableProps, IFormSummaryTableState> {

    public state: IFormSummaryTableState = {
        viewers: [],
        receivers: [],
    };

    private get form(): FormModel {
        return this.props.model;
    }

    private get canSend(): boolean {
        return (this.state.receivers.length > 0);
    }

    private async sendFormReportAsync(): Promise<void> {
        if (!this.canSend) {
            await this.getPage().alertErrorAsync(Localizer.rentaTasksFormSummaryTableChooseReceivers, true);
            return;
        }

        const request: SendFormReportRequest = new SendFormReportRequest();

        request.id = this.form.id;
        request.emailReceivers = this.state.receivers
            .map((user) => user.email)
            .join(FormModel.emailReceiversSeparator);

        await this.postAsync("api/rentaTasks/sendFormReport", request);
        await this.getPage().alertMessageAsync(Localizer.formReceiversPageReportSentToCustomer, true, false);
    }

    private get items(): FormItemModel[] {
        return this.form.items;
    }

    private displayMounterSignatures(): boolean {
        return this.form.mapping.mounterSignaturesRequired && !!this.props.model.usersSignatures;
    }

    private displayApproverSignature(): boolean {
        return (this.form.mapping.approvable) && (this.form.approverSignature != null);
    }

    private groupUsersByRoleGroup(users: User[]): Record<string, User[]> {
        const usersByGroupname: Record<string, User[]> = {};

        users.forEach((user: User) : void => {
            const roleGroup : UserRoleGroup = user.role.group;
            const localizedGroupName : string = EnumProvider.getUserRoleGroupText(roleGroup);
            if (localizedGroupName) {
                if (!usersByGroupname[localizedGroupName]) {
                    usersByGroupname[localizedGroupName] = [];
                }
                usersByGroupname[localizedGroupName].push(user);
            }
        });

        return usersByGroupname;
    }

    private handleCheckboxChange(user: User, checked: boolean): void {
        const { receivers } = this.state;
        let updatedReceivers;

        if (checked) {
            updatedReceivers = [...receivers, user];
        } else {
            updatedReceivers = receivers.filter((checkedUser) => checkedUser.id !== user.id);
        }

        RentaTasksController.mounterContext.form!.emailReceivers = updatedReceivers.map((user) => user.email).join(FormModel.emailReceiversSeparator)

        this.setState({ receivers: updatedReceivers });
    };

    public async initializeAsync(): Promise<void> {
        const viewers: User[] = await this.postAsync("api/rentaTasks/GetManagerAndContactPersons", this.form.workOrderId);
        const distinctViewersWrapper: Record<string, User> = {};

        for (const user of viewers) {
            distinctViewersWrapper[user.id] = user;
        }

        const distinctViewers: User[] = Object.values(distinctViewersWrapper);

        await this.setState({viewers: distinctViewers});
    }

    private renderSignatures(): ReactNode {
        return (
            <React.Fragment>
                { this.displayMounterSignatures() &&
                    (
                        <React.Fragment>
                            <h5>{Localizer.formSummaryTableMountersSignatures}</h5>
                            <FormSummarySignaturesGroup
                                signatures={this.props.model.usersSignatures!.map(x => {
                                    return {
                                        nameClarification: x.nameClarification,
                                        signatureSrc: x.file?.src ?? null
                                    };
                                })}
                                onSignatureGroupClick={this.props.openMountersSignaturesStep}
                            />
                        </React.Fragment>
                   )
                }

                { this.displayApproverSignature() &&
                    (
                        <React.Fragment>
                            <h5>{Localizer.formSummaryTableApproverSignature}</h5>
                            <FormSummarySignaturesGroup
                                signatures={[{
                                    nameClarification: this.props.model.approverNameClarification, 
                                    signatureSrc: this.props.model.approverSignature!.src
                                }]}
                            />
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }

    private renderUserGroups(usersByRoleGroup: Record<string, User[]>): React.ReactNode {
        return Object.entries(usersByRoleGroup).map(([groupName]) => (
            <div key={groupName}
                 className="mb-2"
            >
                <h5>{groupName}</h5>
                {
                    usersByRoleGroup[groupName].map((user : User) => (
                        <Checkbox inline
                                  key={user.id}
                                  id={`${user.id}SendForm`}
                                  label={TransformProvider.userToString(user)}
                                  inlineType={InlineType.Right}
                                  onChange={async (_ : Checkbox<ICheckboxProps>, value : boolean) : Promise<void> => this.handleCheckboxChange(user, value)}
                                  value={this.state.receivers.some((checkedUser : User) : boolean => checkedUser.id === user.id)}
                        />
                    ))
                }
            </div>
        ));
    }

    public render(): React.ReactNode {
        const itemsByGroupName: Map<string, FormItemModel[]> = new Map();

        this.items.forEach((formItem: FormItemModel) => {
            const groupName: string = formItem.group;
            const existingGroup: FormItemModel[] | undefined = itemsByGroupName.get(groupName);

            if (existingGroup) {
                existingGroup.push(formItem);
            }
            else {
                itemsByGroupName.set(groupName, [formItem]);
            }
        });

        let formReviewModal: Modal | null = null;

        const usersByRoleGroup : Record<string, User[]> = this.groupUsersByRoleGroup(this.state.viewers);

        return (
            <div className={this.css(styles.formSummaryTable, this.props.className)}>
                {
                    Array.from(itemsByGroupName).map(([groupName, groupItems]) => (
                        <FormSummaryTableGroup key={groupName}
                                               groupName={groupName}
                                               groupItems={groupItems}
                                               onItemClick={this.props.openStep}
                        />
                    ))
                }

                <hr/>

                {
                    this.renderSignatures()
                }

                <div id="choose-receivers">
                    <h3 className="mb-2 text-center">{Localizer.formSummaryTableDropdownNothingSelectedText}</h3>
                    {this.renderUserGroups(usersByRoleGroup)}
                </div>

                {
                    (this.state.receivers.length > 0) && (
                        <div id="formReceivers">
                            <h5>{Localizer.formSummaryTableSelectedReceiversHeader}</h5>
                            <ul className={styles.receiverList}>
                                {
                                    this.state.receivers.map((receiver) => (
                                        <li key={receiver.email}>
                                            {receiver.email}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }

                {
                    (this.form.processed) &&
                    (
                        <Button className={styles.actionButton}
                                block
                                type={ButtonType.Orange}
                                label={Localizer.formSend}
                                disabled={!this.canSend}
                                onClick={async () => await this.sendFormReportAsync()}
                        />
                    )
                }

                <FormButton className={styles.actionButton}
                            big block
                            type={ButtonType.Default}
                            icon={{name: "search"}}
                            label={Localizer.genericActionPreview}
                            onClick={async () => await formReviewModal?.openAsync()}
                />

                <Modal ref={(instance) => formReviewModal = instance}
                       title={Localizer.genericActionPreview}
                       subtitle={this.form.name}
                >
                    {
                        this.form.items.map((formItem) => (
                            <React.Fragment key={formItem.id}>
                                <FormItemView readonly
                                              formItem={formItem}
                                />

                                <hr/>
                            </React.Fragment>
                        ))
                    }

                    {
                        this.renderSignatures()
                    }

                    <Button block
                            type={ButtonType.Orange}
                            label={Localizer.componentModalClose}
                            onClick={async () => await formReviewModal?.closeAsync()}
                    />
                </Modal>
            </div>
        );
    }
};