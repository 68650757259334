import React from "react";
import {AttachmentType} from "@/models/Enums";
import {BaseComponent, ch, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {FileModel, IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {CellAction, CellModel, GridModel, ImageModal, ModalSize, ToolbarContainer} from "@renta-apps/athenaeum-react-components";
import ConstructionSiteAttachmentsPanelToolbar from "@/pages/ConstructionSiteManagement/ConstructionSiteAttachmentsPanel/Toolbar/ConstructionSiteAttachmentsPanelToolbar";
import {ConstructionSiteAttachmentsGrid} from "@/pages/ConstructionSiteManagement/ConstructionSiteAttachmentsPanel/ConstructionSiteAttachmentsGrid/ConstructionSiteAttachmentsGrid";
import ToolbarModel from "@/pages/ConstructionSiteManagement/ConstructionSiteAttachmentsPanel/Toolbar/ToolbarModel";
import ConstructionSiteAttachment from "@/models/server/ConstructionSiteAttachment";
import AddConstructionSiteAttachmentsRequest from "@/models/server/requests/AddConstructionSIteAttachmentsRequest";
import SaveConstructionSiteAttachmentRequest from "@/models/server/requests/SaveConstructionSiteAttachmentRequest";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import ListConstructionSiteAttachmentsRequest from "@/models/server/requests/ListConstructionSiteAttachmentsRequest";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import Localizer from "../../../localization/Localizer";

interface IConstructionSiteAttachmentsPanelProps {
    constructionSiteId: string;
    readonly: boolean;
}

interface IConstructionSiteAttachmentsPanelState {
    filters: ToolbarModel;
}

export default class ConstructionSiteAttachmentsPanel extends BaseComponent<IConstructionSiteAttachmentsPanelProps, IConstructionSiteAttachmentsPanelState> {

    state: IConstructionSiteAttachmentsPanelState = {
        filters: ConstructionSiteAttachmentsPanel.initializeFilters()
    };

    private readonly _attachmentsGridRef: React.RefObject<ConstructionSiteAttachmentsGrid> = React.createRef();
    private readonly _previewPictureDocumentRef: React.RefObject<ImageModal> = React.createRef();

    private get readonly(): boolean {
        return this.props.readonly;
    }

    private static initializeFilters(): ToolbarModel {
        return UserInteractionDataStorage.getFilters(new ToolbarModel());
    }

    private async previewPictureDocumentAsync(picture: FileModel): Promise<void> {
        if (this._previewPictureDocumentRef.current) {
            await this._previewPictureDocumentRef.current!.openAsync(picture);
        }
    }

    private async processAttachmentOperationAsync(cell: CellModel<ConstructionSiteAttachment>, action: CellAction<ConstructionSiteAttachment>): Promise<void> {

        const model: ConstructionSiteAttachment = cell.model;

        switch (action.action.name) {
            case "save":
                const request = new SaveConstructionSiteAttachmentRequest();
                request.id = model.id!;
                request.constructionSiteId = this.props.constructionSiteId;
                request.public = model.public;
                request.type = AttachmentType.ConstructionSiteAttachment;
                request.file = model.file;
                await cell.grid.postAsync("api/constructionSiteManagement/saveConstructionSiteAttachment", request);

                await cell.row.bindAsync();
                await cell.row.reRenderAsync();
                break;
            case "cancel":
                await cell.row.cancelAsync();
                break;
            case "delete":
                await cell.grid.postAsync("api/constructionSiteManagement/removeConstructionSiteAttachmentByFileId", model.fileId);
                await cell.grid.deleteAsync(cell.row.index);

                await cell.row.reRenderAsync();
                break;
            case "preview":
                const previewFile: FileModel = await this.postAsync("api/constructionSiteManagement/getConstructionSiteAttachmentFile", model.fileId);

                if (RentaTaskConstants.imageFileTypes.includes(previewFile.type)) {
                    await this.previewPictureDocumentAsync(previewFile);
                } else {
                    await ch.documentPreviewAsync("api/constructionSiteManagement/getConstructionSiteAttachmentFile", model.fileId);
                }
                break;
            case "download":
                const downloadFile: FileModel = await this.postAsync("api/constructionSiteManagement/getConstructionSiteAttachmentFile", model.fileId);
                ch.download(downloadFile);
                break;
        }
    }

    private async fetchAttachments(pageNumber: number,
                                   pageSize: number,
                                   sortColumnName: string | null,
                                   sortDirection: SortDirection | null): Promise<IPagedList<ConstructionSiteAttachment>> {

        UserInteractionDataStorage.setFilters(sortColumnName, "ConstructionSiteAttachments.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "ConstructionSiteAttachments.SortDirection");

        const request = new ListConstructionSiteAttachmentsRequest();
        request.constructionSiteId = this.props.constructionSiteId;
        request.public = this.state.filters.public;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;

        return await this.postAsync("/api/ConstructionSiteManagement/listConstructionSiteAttachmentFiles", request);
    }

    private get attachmentsGridModel(): GridModel<ConstructionSiteAttachment> {
        return this._attachmentsGridRef.current!.gridRef!.model;
    }

    private async onFiltersChange(filters: ToolbarModel): Promise<void> {
        await this.setState({ filters });

        UserInteractionDataStorage.setFilters(filters);

        await this.attachmentsGridModel.reloadAsync();
    }

    private async addConstructionSiteAttachmentsAsync(sender: IBaseComponent, request: AddConstructionSiteAttachmentsRequest): Promise<void> {
        await sender.postAsync("api/ConstructionSiteManagement/addConstructionSiteAttachments", request);

        await this.attachmentsGridModel.reloadAsync();
    }

    private get constructionSiteAttachmentsSortColumn(): string {
        return UserInteractionDataStorage.getFilters("", "ConstructionSiteAttachments.SortColumn");
    }

    private get constructionSiteAttachmentsSortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "ConstructionSiteAttachments.SortDirection");
    }

    public async reloadConstructionSiteAttachmentsAsync(): Promise<void> {
        await this.attachmentsGridModel.reloadAsync();
    }

    public render(): React.ReactNode {
        return (
            <div>
                <ToolbarContainer>

                    <ConstructionSiteAttachmentsPanelToolbar model={this.state.filters} 
                                                             readonly={this.readonly} 
                                                             constructionSiteId={this.props.constructionSiteId} 
                                                             onChange={async (model) => this.onFiltersChange(model)} 
                                                             addConstructionSiteAttachments={async (sender, request) => await this.addConstructionSiteAttachmentsAsync(sender, request)} 
                                                             reloadConstructionSiteAttachments={async () => await this.reloadConstructionSiteAttachmentsAsync()}
                    />

                </ToolbarContainer>
                
                <ConstructionSiteAttachmentsGrid displayPublicity
                                                 ref={this._attachmentsGridRef}
                                                 readonly={this.readonly}
                                                 processAttachmentOperation={async (cell, action) => await this.processAttachmentOperationAsync(cell, action)}
                                                 fetchAttachments={async (pageNumber, pageSize, sortColumnName, sortDirection) => await this.fetchAttachments(pageNumber, pageSize, sortColumnName, sortDirection)}
                                                 attachmentsSortColumn={this.constructionSiteAttachmentsSortColumn}
                                                 attachmentsSortDirection={this.constructionSiteAttachmentsSortDirection}
                />
                
                <ImageModal id={`${this.id}_previewConstructionSiteAttachment`}
                                    ref={this._previewPictureDocumentRef}
                                    size={ModalSize.ExtraLarge}
                                    title={Localizer.formInputFilePreview}
                />
            </div>
        );
    }
};