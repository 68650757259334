import React from "react";

import styles from "./FormSummarySignatureTableRow.module.scss";

export interface IFormSummarySignatureTableRowProps {
    nameClarification: string | null;
    signatureSrc: string | null;
}

export function FormSummarySignatureTableRow(props: Readonly<IFormSummarySignatureTableRowProps>): JSX.Element {
    return (
        <div className={styles.formSummarySignatureTableRow}>
            { (!!props.nameClarification) && (
                <div>
                    <h6>
                        {props.nameClarification}
                    </h6>
                </div>
            )}

            { (!!props.signatureSrc) && (
                
                <div>
                    <img src={props.signatureSrc} alt={props.nameClarification ?? ""}/>
                </div>
            )}
        </div>
    );
}
