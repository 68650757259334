import React from "react";
import {BaseComponent, ch, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, IIconProps} from "@renta-apps/athenaeum-react-components";
import FormModel from "@/models/server/forms/FormModel";
import UserContext from "@/models/server/UserContext";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./FormsList.module.scss";


interface IFormsListProps {
    isActiveForms?: boolean;
    forms: FormModel[];

    onOpenFormsListItem(sender: IBaseComponent, item: FormModel): Promise<void>;
    onUnlockForm(sender: IBaseComponent, formId: string): Promise<void>;
}

interface IFormsListState {
}

export default class FormsList extends BaseComponent<IFormsListProps, IFormsListState> {

    state: IFormsListState = {
        isLoading: false
    };

    protected getEndpoint(): string {
        return "";
    }

    public get isActiveForms(): boolean {
        return (this.props.isActiveForms === true);
    }

    public isAsync(): boolean {
        return true;
    }

    public getManual(): string {
        return Localizer.formsListGetManual;
    }

    private get userContext(): UserContext {
        return ch.getContext() as UserContext;
    }

    private canContinueForm(form: FormModel): boolean {
        const context: UserContext = this.userContext;

        return (context.user!.id === form.userId);
    }

    private get canUnlockForm(): boolean {
        const context: UserContext = this.userContext;

        return (context.isAdmin)
            || (context.isManager)
            || (context.isBusinessManager);
    }

    private get forms(): FormModel[] {
        return this.props.forms;
    }

    public render(): React.ReactNode {

        if (this.forms.length === 0) {
            return (
                <div className={this.css(styles.formsList)}>
                    {Localizer.genericNoData}
                </div>
            );
        }

        const SecondColumnHeader: string = (this.isActiveForms)
            ? Localizer.genericType
            : Localizer.formsListButtonTitleCompletionDate;

        return (
            <div className={styles.formsList}>
                <table className={this.css(styles.table, "table table-striped table-borderless")}>
                    <thead>
                        <tr>
                            <th >
                                {Localizer.genericName}
                            </th>

                            <th>
                                {SecondColumnHeader}
                            </th>

                            <th>
                                {
                                    (this.isActiveForms) 
                                        ? (Localizer.genericActions) 
                                        : (Localizer.formsListCompletedColumnHeaderPreview)
                                }
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                    {
                        this.forms.map((form, index) => {

                            let buttonTitle: string;
                            let buttonIcon: IIconProps;
                            let buttonType: ButtonType = ButtonType.Orange;
                            let buttonDisabled: boolean = false;
                            let buttonCallback: () => Promise<void> = async () => this.props.onOpenFormsListItem(this, form);

                            if (!this.isActiveForms) {
                                buttonTitle = "";
                                buttonIcon = {name: "eye"};
                            }
                            else if (!form.locked) {
                                buttonTitle = Localizer.formsListButtonTitleFill;
                                buttonIcon = {name: "pen"};
                            }
                            else if (this.canContinueForm(form)) {
                                buttonTitle = Localizer.formsListButtonTitleContinue;
                                buttonIcon = {name: "pen"};
                                buttonType = ButtonType.Success;
                            }
                            else if (this.canUnlockForm) {
                                buttonTitle = Localizer.formsListButtonTitleUnlock;
                                buttonIcon = {name: "unlock-alt"};
                                buttonType = ButtonType.Danger;
                                buttonCallback = async () => this.props.onUnlockForm(this, form.id);
                            }
                            else {
                                buttonTitle = Localizer.formsListButtonTitleLocked;
                                buttonIcon = {name: "lock"};
                                buttonDisabled = true;
                                buttonCallback = async () => {};
                            }

                            return (
                                <tr key={form.formDefinitionId + index}>
                                    <td className={styles.formName}>
                                        {form.name}{(form.required) && "*"}
                                    </td>

                                    <td className={styles.secondColumn}>
                                        {
                                            (this.isActiveForms)
                                                ? EnumProvider.getFormTimeslotTypeText(form.formDefinition!.mapping.timeslot)
                                                : Utility.format("{0:dd.MM.yyyy}", form.processedAt)
                                        }
                                    </td>

                                    <td className={styles.actionButtons}>
                                        <Button small
                                                type={buttonType}
                                                icon={buttonIcon}
                                                title={buttonTitle}
                                                disabled={buttonDisabled}
                                                label={buttonTitle}
                                                onClick={async () => await buttonCallback()}
                                        />

                                        {
                                            (form.locked) && (this.canContinueForm(form)) &&
                                            (
                                                <Button type={ButtonType.Danger}
                                                        icon={{name: "ban"}}
                                                        title={Localizer.genericActionCancel}
                                                        label={Localizer.genericActionCancel}
                                                        onClick={async () => await this.props.onUnlockForm(this, form.id)}
                                                />
                                            )
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}