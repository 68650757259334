import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import RentaTasksWizardPage from "../RentaTasksWizardPage";
import UserSalaryHour from "@/models/server/UserSalaryHour";
import {RentaTasksAction} from "@/pages/RentaTasks/RentaTasksController";
import {CheckboxWidget, TitleWidget} from "@renta-apps/athenaeum-react-components";
import HoursWidget from "@/components/HoursWidget/HoursWidget";
import Localizer from "@/localization/Localizer";

export interface ICheckOutProps {
}

interface ICheckOutState {
    addEquipment: boolean;
    myHours: UserSalaryHour;
}

export default class CheckOut extends RentaTasksWizardPage<ICheckOutProps, ICheckOutState> {

    state: ICheckOutState = {
        addEquipment: this.wizard.addEquipment,
        myHours: new UserSalaryHour(),
    };

    private get addEquipmentVisible(): boolean {
        return (this.wizard.action != RentaTasksAction.CompleteWorkOrder);
    }

    private get addEquipment(): boolean {
        return (this.state.addEquipment);
    }

    private get myHours(): UserSalaryHour {
        this.state.myHours.day = Utility.today();
        this.state.myHours.user = this.getUser();
        this.state.myHours.userId = this.getUserId();
        this.state.myHours.normalHours = this.wizard.myHours.normalHours;
        this.state.myHours.overtime50Hours = this.wizard.myHours.overtime50Hours;
        this.state.myHours.overtime100Hours = this.wizard.myHours.overtime100Hours;
        this.state.myHours.comment = this.wizard.myHours.comment;
        return this.state.myHours;
    }

    private async setMyHours(normalHours: number, overtime50Hours: number, overtime100Hours: number, comment: string | null): Promise<void> {
        if ((normalHours !== this.myHours.normalHours) || 
            (overtime50Hours != this.myHours.overtime50Hours) || 
            (overtime100Hours != this.myHours.overtime100Hours) ||
            (comment != this.myHours.comment)) {
            this.wizard.myHours.normalHours = normalHours;
            this.wizard.myHours.overtime50Hours = overtime50Hours;
            this.wizard.myHours.overtime100Hours = overtime100Hours;
            this.wizard.myHours.comment = comment;
            this.saveContext();
            await this.reRenderAsync();
        }
    }

    private async setAddEquipment(addEquipment: boolean): Promise<void> {
        if (addEquipment !== this.addEquipment) {
            this.wizard.addEquipment = addEquipment;
            this.saveContext();
            await this.setState({addEquipment});
        }
    }

    public getManual(): string {
        return Localizer.taskGetManual;
    }

    public renderContent(): React.ReactNode {

        return (
            <React.Fragment>

                <TitleWidget wide
                             model={this.title}
                />

                <HoursWidget label={Localizer.workOrderLabelsMyHours}
                             description={Localizer.taskHoursDescription}
                             wide={!this.addEquipmentVisible}
                             normalHours={this.myHours.normalHours}
                             overtime50Hours={this.myHours.overtime50Hours}
                             overtime100Hours={this.myHours.overtime100Hours}
                             comment={this.myHours.comment}
                             onChange={async (_, normalHours, overtime50Hours, overtime100Hours, comment) =>
                                 await this.setMyHours(normalHours, overtime50Hours, overtime100Hours, comment)}
                />

                {
                    (this.addEquipmentVisible) &&
                    (
                        <CheckboxWidget checked={this.addEquipment}
                                        label={Localizer.taskAdditionalEquipments}
                                        description={Localizer.taskAdditionalEquipmentsDescription}
                                        onChange={async (_, checked) => await this.setAddEquipment(checked)}
                        />
                    )
                }

            </React.Fragment>
        );
    }
}