import React from "react";
import {BaseComponent, TextAlign} from "@renta-apps/athenaeum-react-common";
import UserSalaryHour from "../../../../models/server/UserSalaryHour";
import UserCheckIn from "../../../../models/server/UserCheckIn";
import UserSignIn from "../../../../models/server/UserSignIn";
import ConstructionSiteOrWarehouse from "../../../../models/server/ConstructionSiteOrWarehouse";
import WorkOrderModel from "../../../../models/server/WorkOrderModel";
import Comparator from "../../../../helpers/Comparator";
import GetUserCheckInsRequest from "@/models/server/requests/GetUserCheckInsRequest";
import {CellModel, ColumnDefinition, Grid, GridHoveringType, GridOddType, RowModel} from "@renta-apps/athenaeum-react-components";
import TransformProvider from "../../../../providers/TransformProvider";
import Localizer from "../../../../localization/Localizer";
import {SortDirection} from "@renta-apps/athenaeum-toolkit";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";

import styles from "./CheckInsPanel.module.scss";

interface ICheckInsPanelProps  {
    userSalaryHourRow: RowModel<UserSalaryHour>;
}

interface ICheckInsPanelState {
}

export default class CheckInsPanel extends BaseComponent<ICheckInsPanelProps, ICheckInsPanelState> {

    state: ICheckInsPanelState = {
    };

    private readonly _gridRef: React.RefObject<Grid<UserCheckIn>> = React.createRef();

    private readonly columns: ColumnDefinition[] = [
        {
            group: Localizer.checkInsPanelSignLanguageItemName,
            header: Localizer.checkInsPanelSignInLanguageItemName,
            accessor: "signIn.signInAt",
            format: "t",
            textAlign: TextAlign.Center,
            sorting: true,
            init: (cell) => this.initSignInAtColumn(cell),
        },
        {
            group: Localizer.checkInsPanelSignLanguageItemName,
            header: Localizer.checkInsPanelSignOutLanguageItemName,
            accessor: "signIn.signOutAt",
            format: "t",
            textAlign: TextAlign.Center,
            sorting: true,
            init: (cell) => this.initSignOutAtColumn(cell),
        },{
            header: Localizer.checkInsPanelSiteOrWarehouseLanguageItemName,
            accessor: "owner",
            transform: (_, value) => (value) ? TransformProvider.constructionSiteOrWarehouseToString(value, true) : "",
            minWidth: 250,
            sorting: true,
            init: (cell) => this.groupBySiteOrWarehouse(cell),
        },
        {
            header: Localizer.checkInsPanelTaskLanguageItemName,
            accessor: "workOrder",
            minWidth: 300,
            sorting: true,
            init: (cell) => this.groupByTask(cell),
        },
        {
            group: Localizer.checkInsPanelCheckInLanguageItemName,
            header: Localizer.checkInsPanelCheckInAtLanguageItemName,
            accessor: "checkInAt",
            format: "t",
            textAlign: TextAlign.Center,
            sorting: true,
            init: (cell) => this.initCheckInAtColumn(cell),
        },
        {
            group: Localizer.checkInsPanelCheckInLanguageItemName,
            header: Localizer.checkInsPanelCheckInAddressLanguageItemName,
            accessor: "checkInLocation",
            sorting: true,
            minWidth: 200
        },
        {
            group: Localizer.checkInsPanelCheckOutLanguageItemName,
            header: Localizer.checkInsPanelCheckOutAtLanguageItemName,
            accessor: "checkOutAt",
            format: "t",
            textAlign: TextAlign.Center,
            sorting: true,
            init: (cell) => this.initCheckOutAtColumn(cell),
        },
        {
            group: Localizer.checkInsPanelCheckOutLanguageItemName,
            header: Localizer.checkInsPanelCheckOutAddressLanguageItemName,
            accessor: "checkOutLocation",
            sorting: true,
            minWidth: 200
        },
        {
            header: Localizer.genericWorkingHoursLanguageItemName,
            accessor: "autoHours",
            format: "0.00",
            minWidth: 80,
            sorting: true,
            textAlign: TextAlign.Center
        },
        {

            header: Localizer.taskHoursPanelExtraHoursLanguageItemName,
            accessor: "extraHours",
            format: "0.00",
            minWidth: 80,
            sorting: true,
            textAlign: TextAlign.Center
        },
    ];

    private async fetchDataAsync(
        sortColumnName: string | null,
        sortDirection: SortDirection | null): Promise<UserCheckIn[]> {

        UserInteractionDataStorage.setFilters(sortColumnName, "CheckInsPanel.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "CheckInsPanel.SortDirection");

        const request = new GetUserCheckInsRequest();
        request.userSalaryDayId = this.userSalaryHour.userSalaryDay!.id;
        request.userId = this.userSalaryHour.user!.id;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;

        return await this.grid.postAsync("api/employees/getUserCheckIns", request);
    }

    private groupBySignIn(cell: CellModel<UserCheckIn>): void {
        if (!cell.spanned) {
            const model: UserCheckIn = cell.row.model;
            const signIn: UserSignIn | null = model.signIn;
            const sameRows: RowModel<UserCheckIn>[] = (cell.nextRows(item => Comparator.isEqual(item.signIn, signIn)));
            cell.rowSpan = (sameRows.length + 1);
        }
    }

    private groupBySiteOrWarehouse(cell: CellModel<UserCheckIn>): void {
        if (!cell.spanned) {
            const model: UserCheckIn = cell.row.model;
            const signIn: UserSignIn | null = model.signIn;
            const constructionSiteOrWarehouse: ConstructionSiteOrWarehouse | null = model.owner;
            const sameRows: RowModel<UserCheckIn>[] = (cell.nextRows(item =>
                (Comparator.isEqual(item.signIn, signIn)) &&
                (Comparator.isEqual(item.owner, constructionSiteOrWarehouse))));
            cell.rowSpan = (sameRows.length + 1);
        }
    }

    private groupByTask(cell: CellModel<UserCheckIn>): void {
        if (!cell.spanned) {
            const model: UserCheckIn = cell.row.model;
            const signIn: UserSignIn | null = model.signIn;
            const constructionSiteOrWarehouse: ConstructionSiteOrWarehouse | null = model.owner;
            const workOrder: WorkOrderModel | null = model.workOrder;
            const sameRows: RowModel<UserCheckIn>[] = (cell.nextRows(item =>
                (Comparator.isEqual(item.signIn, signIn)) &&
                (Comparator.isEqual(item.owner, constructionSiteOrWarehouse)) &&
                (Comparator.isEqual(item.workOrder, workOrder))));
            cell.rowSpan = (sameRows.length + 1);
        }
    }

    private initSignInAtColumn(cell: CellModel<UserCheckIn>): void {
        this.groupBySignIn(cell);
        const model: UserCheckIn = cell.row.model;
        const signIn: UserSignIn = model.signIn!;
        cell.className = (signIn.signOutAt == null)
            ? "green"
            : "";
    }

    private initSignOutAtColumn(cell: CellModel<UserCheckIn>): void {
        this.groupBySignIn(cell);
        const model: UserCheckIn = cell.row.model;
        const signIn: UserSignIn = model.signIn!;
        cell.className = (signIn.expired)
            ? "danger"
            : "";
    }

    private initCheckInAtColumn(cell: CellModel<UserCheckIn>): void {
        const model: UserCheckIn = cell.row.model;
        cell.className = (model.checkOutAt == null)
            ? "green"
            : "";
    }

    private initCheckOutAtColumn(cell: CellModel<UserCheckIn>): void {
        const model: UserCheckIn = cell.row.model;
        cell.className = (model.expired)
            ? "danger"
            : "";
    }

    private get userSalaryHour(): UserSalaryHour {
        return this.userSalaryHourRow.model;
    }

    private get userSalaryHourRow(): RowModel<UserSalaryHour> {
        return this.props.userSalaryHourRow;
    }

    private get grid(): Grid<UserCheckIn> {
        return this._gridRef.current!;
    }

    private get sortColumn(): string {
        return UserInteractionDataStorage.getFilters("signIn.signInAt", "CheckInsPanel.SortColumn");
    }

    private get sortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "CheckInsPanel.SortDirection");
    }

    public hasSpinner(): boolean {
        return true;
    }

    public render(): React.ReactNode {

        return (
            <div className={this.css(styles.checkInsPanel)}>

                <Grid id="userCheckIns" ref={this._gridRef}
                      noDataNoHeader
                      noDataText={Localizer.checkInsPanelNoDataText}
                      className={this.css(styles.grid)}
                      minWidth="auto"
                      hovering={GridHoveringType.None}
                      odd={GridOddType.None}
                      columns={this.columns}
                      fetchData={async (_, __, ___, sortColumnName, sortDirection) => await this.fetchDataAsync(sortColumnName, sortDirection)}
                      defaultSortColumn={this.sortColumn}
                      defaultSortDirection={this.sortDirection}
                />

            </div>
        );
    }
};