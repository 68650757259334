import React from "react";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {AddressInput, ButtonContainer, Form, Modal, OneColumn, TextInput} from "@renta-apps/athenaeum-react-components";
import {Button, ButtonType} from "@renta-apps/athenaeum-react-components";
import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import ConstructionSite from "@/models/server/ConstructionSite";
import AddSubcontractorRequest from "@/models/server/requests/AddSubcontractorRequest";
import Localizer from "../../../localization/Localizer";

import "./BootstrapOverride.scss";

interface IAddSubcontractorContractModalProps {
    addSubcontractorContract(sender: IBaseComponent, request: AddSubcontractorRequest): Promise<boolean>;
}

interface IAddSubcontractorContractModalState {
    location: GeoLocation | null,
    name: string;
    vatId: string,
}

export default class AddSubcontractorContractModal extends BaseComponent<IAddSubcontractorContractModalProps, IAddSubcontractorContractModalState> {

    state: IAddSubcontractorContractModalState = {
        location: null,
        name: "",
        vatId: "",
    };

    private readonly _modalRef: React.RefObject<Modal<ConstructionSite>> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();

    public async openAsync(): Promise<void> {
        await this._modalRef.current!.openAsync();
    }

    private async onSubmitAsync(): Promise<void> {
        const request = new AddSubcontractorRequest();
        request.name = this.state.name;
        request.vatId = this.state.vatId;
        request.formattedAddress = this.state.location!.formattedAddress;

        const success: boolean = await this.props.addSubcontractorContract(this.modal, request);

        if (!success) {
            const form: Form | null = this._formRef.current;
            if (form != null) {
                const validationError: string = Localizer.addSubcontractorModalValidationErrorSubcontractorAlreadyExists;
                await form.setValidationErrorsAsync(validationError);
            }

            return;
        }

        await this.modal.closeAsync();
    }

    private get modal(): Modal<ConstructionSite> {
        return this._modalRef.current!;
    }

    public static get modalId(): string {
        return "addSubcontractorContractModal";
    }

    private async onNameChangeAsync(name: string) {
        this.setState({name});
    }

    private async onVatIdChangeAsync(vatId: string) {
        this.setState({vatId});
    }

    private async onPlaceSelected(location: GeoLocation) {

        this.setState({location});
    }

    public render(): React.ReactNode {
        return (
            <Modal id={AddSubcontractorContractModal.modalId} ref={this._modalRef}
                   title={Localizer.subContractorsAddSubcontractor}
            >

                <div className="row">
                    <div className="col">

                        <Form ref={this._formRef} id="addSubcontractorContract" onSubmit={async () => await this.onSubmitAsync()}>

                            <OneColumn>
                                <TextInput required
                                           id="name"
                                           label={Localizer.genericName}
                                           value={this.state.name!}
                                           onChange={async (_, item) => await this.onNameChangeAsync(item!)}
                                />

                                <TextInput required
                                           id="vatId"
                                           label={Localizer.genericVatId}
                                           value={this.state.vatId!}
                                           onChange={async (_, item) => await this.onVatIdChangeAsync(item!)}
                                />

                                <AddressInput required
                                              id="address"
                                              label={Localizer.formInputAddress}
                                              value={this.state.location != null ? this.state.location.formattedAddress : ""}
                                              onChange={location => this.onPlaceSelected(location)}
                                />

                            </OneColumn>

                            <ButtonContainer>
                                <Button submit
                                        id={"save"}
                                        type={ButtonType.Orange}
                                        label={Localizer.formSave}
                                />
                            </ButtonContainer>

                        </Form>

                    </div>
                </div>

            </Modal>
        )
    }
}