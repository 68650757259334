import React from "react";
import {IBaseComponent} from "@renta-apps/athenaeum-react-common";
import RentaTasksWizardPage from "../RentaTasksWizardPage";
import SummaryWidget from "@/pages/RentaTasks/Summary/SummaryWidget/SummaryWidget";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import User from "@/models/server/User";
import {ITitleModel, TitleWidget} from "@renta-apps/athenaeum-react-components";
import GetAndCreateIfMissingContactPersonRequest from "@/models/server/requests/GetAndCreateIfMissingContactPersonRequest";
import GetAndCreateIfMissingContactPersonResponse from "@/models/server/responses/GetAndCreateIfMissingContactPersonResponse";
import TransformProvider from "../../../providers/TransformProvider";
import Localizer from "@/localization/Localizer";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import throwIfFalsy from "@/functions/ThrowIfFalsy";

interface ISummaryProps {
}

interface ISummaryState {
}

export default class Summary extends RentaTasksWizardPage<ISummaryProps, ISummaryState> {

    state: ISummaryState = {};

    private get wizardContextWorkOrder(): WorkOrderModel {
        return RentaTasksController.wizardContextWorkOrder!;
    }

    private async getContactPersonsAsync(sender: IBaseComponent): Promise<User[]> {
        const constructionSiteId: string = this.wizardContextWorkOrder.constructionSiteId!;
        return await sender.postAsync("api/rentaTasks/getContactPersons", constructionSiteId);
    }

    private async getAndCreateIfMissingContactPersonAsync(sender: IBaseComponent, request: GetAndCreateIfMissingContactPersonRequest): Promise<GetAndCreateIfMissingContactPersonResponse> {
        request.constructionSiteId = this.wizardContextWorkOrder.constructionSiteId!;
        return await sender.postAsync("api/rentaTasks/getAndCreateIfMissingContactPerson", request);
    }

    private async onChangeAsync(workOrder: WorkOrderModel): Promise<void> {
        throwIfFalsy(workOrder, nameof(workOrder));

        RentaTasksController.setWizardContextWorkOrder(workOrder);

        this.saveContext();
        await this.validateAsync();
        await this.reRenderAsync();
    }

    protected getNoToggle(): boolean {
        return true;
    }

    protected get canNext(): boolean {
        return (!!this.wizardContextWorkOrder.customerApprover);
    }

    public get title(): ITitleModel {
        return TransformProvider.toTitle(this.wizardContextWorkOrder);
    }

    public get isEditable(): boolean {
        return (!WorkOrderModel.isApproverOrOrdererValid(this.wizardContextWorkOrder.customerApprover));
    }

    public async nextAsync(): Promise<void> {
        if ((this.wizardContextWorkOrder.customerApprover) && (!this.isEditable)) {
            await super.nextAsync();
        }
    }

    public getManual(): string {
        return Localizer.summaryManual;
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <TitleWidget model={this.title} wide />

                <SummaryWidget wide
                               workOrder={this.wizardContextWorkOrder}
                               getContactPersons={async (sender) => await this.getContactPersonsAsync(sender)}
                               getAndCreateIfMissingContactPerson={async (sender, request) => await this.getAndCreateIfMissingContactPersonAsync(sender, request)}
                               onChange={async (_, workOrder) => await this.onChangeAsync(workOrder)}
                />

            </React.Fragment>
        );
    }
}