import Warehouse from "@/models/server/Warehouse";
import ConstructionSite from "@/models/server/ConstructionSite";
import CostPool from "@/models/server/CostPool";
import User from "@/models/server/User";

/** Data to populate filters on the Calendar page. */
export default class CalendarFiltersData {
    /** All warehouses. */
    public warehouses: Warehouse[] = [];

    /** All active cost pools. */
    public costPools: CostPool[] = [];

    /** All active construction sites. */
    public constructionSites: ConstructionSite[] = [];

    /** All managers. */
    public managers: User[] = [];

    /** All mounters (except subcontractor mounters). */
    public mounters: User[] = [];
    
    public isCalendarFiltersData: true = true;
}