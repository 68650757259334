import restoreDeserializedDate from "@/functions/RestoreDeserializedDate";

/**
 * Create a deep clone of a value.
 *
 * NOTE: object values prototype and own properties and methods are not preserved
 *
 * @param value Value to create a deep clone out of
 * @returns Deep clone of the {@link value}
 */
export default function clone<T>(value: Readonly<T>): T {
    switch (typeof value) {
        case "bigint":
        case "boolean":
        case "function":
        case "number":
        case "symbol":
        case "undefined":
        case "string":
            return value;
        case "object":
            if (value === null) {
                return value;
            }

            if (value instanceof Date) {
                const unixMilliseconds: number = (value as Date).valueOf();

                return new Date(unixMilliseconds) as any;
            }

            const clonedValue: T = JSON.parse(JSON.stringify(value));

            // Restore deserialized dates (doesn't restore dates of nested objects or arrays).
            for (const valueKey in value) {
                if (value[valueKey] instanceof Date) {
                    const deserializedDate: string = clonedValue[valueKey] as any;
                    clonedValue[valueKey] = restoreDeserializedDate(deserializedDate) as any;
                }
            }

            return clonedValue;
        default:
            throw new TypeError(`How can this be happening`);
    }
}