import Organization from "./Organization";
import {GeoLocation, Utility} from "@renta-apps/athenaeum-toolkit";
import User from "./User";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

export default class Subcontractor {
    public id: string = "";

    public name: string = "";

    public externalId: string = "";

    public organizationId: string = "";

    public organizationContractId: string = "";

    public warehouseId: string | null = null;

    public constructionSiteId: string | null = null;

    public organization: Organization | null = null;

    public manager: User | null = null;

    public managerUserId: string | null = null;

    public location: GeoLocation | null = null;

    public deleted: boolean = false;

    public deletedAt: Date | null = null;

    public deletedBy: User | null = null;

    public isSubcontractor: boolean = true;

    public static deletedSubcontractorInfo(subcontractor: Readonly<Subcontractor>): string {
        return (subcontractor.deleted)
            ? "{0}: {1}.\n{2}: {3}".format(Localizer.genericDeletedAt, Utility.format(subcontractor.deletedAt!.toISODateString()), Localizer.genericDeletedBy, TransformProvider.userToString(subcontractor.deletedBy!))
            : "";
    }
}