import React from "react";
import DatePicker from "react-datepicker";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import DateInputWidgetLocalizer from "./DateInputWidgetLocalizer";

import "react-datepicker/dist/react-datepicker.css";
import "./DateInputWidget.scss";
import {BaseExpandableWidget} from "@renta-apps/athenaeum-react-components";
import {IBaseExpandableWidgetProps} from "@renta-apps/athenaeum-react-components/src/components/WidgetContainer/BaseExpandableWidget";
import {IBaseWidgetState} from "@renta-apps/athenaeum-react-components/src/components/WidgetContainer/BaseWidget";

interface IDatepickerWidgetProps extends IBaseExpandableWidgetProps {
    value?: Date;
    minDate?: Date;
    todayButton?: string;
    onChange?(date: Date): Promise<void>;
}

export default class DateInputWidget extends BaseExpandableWidget<IDatepickerWidgetProps> {

    private async handleChangeAsync(date: Date): Promise<void> {
        if (this.props.onChange) {
            await this.props.onChange(date);
        }

        await this.hideContentAsync();
    }

    protected async onClickAsync(e: React.MouseEvent): Promise<void> {
        const target: Element = e.target as Element;

        if (target.parentElement) {
            if (target.parentElement.className.startsWith("react-datepicker")) {
                return;
            }
        }

        await super.toggleContentAsync();
    }

    public async initializeAsync(): Promise<void> {
        this.state.icon = {name: "far calendar-day"};
        await super.initializeAsync();
    }

    public static getDerivedStateFromProps(nextProps: IDatepickerWidgetProps, prevState: IBaseWidgetState<never>){
        if (prevState.icon !== nextProps.icon) {
            return {
                ...prevState,
                icon: { name: "far calendar-day" },
            };
        }
        return null;
    }

    private renderDate(date: Date): React.ReactNode {
        return (
            <span className="date">
                {Utility.toDateString(date)}
            </span>
        );
    }

    protected renderExpanded(): React.ReactNode {
        return (
            <div className="dateInputContainer">
                <DatePicker inline
                            selected={this.props.value || new Date()}
                            minDate={this.props.minDate}
                            onChange={(date: Date) => this.handleChangeAsync(date)}
                            className="form-control"
                            calendarClassName="datepicker dateInputWidget"
                            todayButton={this.props.todayButton}
                            locale={DateInputWidgetLocalizer.language}
                />
            </div>
        )
    }

    protected renderContent(renderHidden: boolean = false): React.ReactNode {
        return (this.contentVisible)
            ?
            (
                <div>
                    {this.renderExpanded()}
                </div>
            )
            : (this.props.value)
                ?
                (
                    this.renderDate(this.props.value)
                )
                :
                (
                    this.renderContent()
                );
    }
}