import {ConstructionSiteOrWarehouseType} from "@/models/Enums";
import CostPool from "@/models/server/CostPool";
import User from "@/models/server/User";

export default class ToolbarModel {

    public from: Date | null = null;

    public to: Date | null = null;

    public source: ConstructionSiteOrWarehouseType | null = null;
    
    public reportType: string | Date | null = null;
    
    /**
     * Manager users who are selected in the {@link Toolbar}.
     */
    public managers: User[] = [];

    /**
     * Mounter users who are selected in the {@link Toolbar}.
     */
    public mounters: User[] = [];

    /**
     * Cost pools that are selected in the {@link Toolbar} 
     */
    costPools: CostPool[] = [];
}