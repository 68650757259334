import RentaTasksWizardPage from "@/pages/RentaTasks/RentaTasksWizardPage";
import React from "react";
import {AddressHelper, TextInput} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import styles from "../../RentaTasks.module.scss"
import summaryStyles from "./ActivateConstructionSiteSummary.module.scss"

interface IActivateConstructionSiteSummaryState {
}

interface IActivateConstructionSiteSummaryProps {
}

export default class ActivateConstructionSiteSummary
    extends RentaTasksWizardPage<IActivateConstructionSiteSummaryProps, IActivateConstructionSiteSummaryState> {

    private get workSiteName(): string {
        return this.wizard.owner!.name;
    }

    private get customerName(): string {
        return this.wizard.organization?.name ?? "";
    }

    private get formattedAddress(): string  {
        return this.wizard.owner?.location?.formattedAddress ?? "";
    }

    private get contractExternalId(): string {
        return this.wizard.owner?.organizationContract?.externalId ?? "";
    }

    private get contractName(): string {
        return this.wizard.owner?.organizationContract?.name ?? "";
    }

    private async onWorkSiteNameChangeAsync(value: string): Promise<void> {
        this.wizard.owner!.name = value;
        await this.reRenderAsync()
    }

    protected get workOrderRequired(): boolean {
        return false;
    }

    public getManual(): string {
        return "";
    }

    renderContent(): React.ReactNode {
        return (
            <div className={styles.wizardContent}>
                <p>
                    {Localizer.rentaTasksControllerStepsActivateConstructionSiteTitleSummary}
                </p>

                <TextInput className={summaryStyles.constructionSiteNameInput}
                           id="worksitename"
                           label={Localizer.activateConstructionSiteSummaryWorksiteNameLabel}
                           value={this.workSiteName}
                           onChange={async (_, value) => await this.onWorkSiteNameChangeAsync(value)}
                />

                <div>
                    <span>{Localizer.activateConstructionSiteSummaryCustomerLabel}</span>
                    <p>
                        {this.customerName}<br />
                        {this.contractExternalId}<br />
                        {this.contractName}<br />
                    </p>
                </div>

                <div>
                    <span>{Localizer.activateConstructionSiteSummaryAddressLabel}</span>
                    <p>
                        {AddressHelper.removeLatLon(this.formattedAddress)}
                    </p>
                </div>
            </div>
        );
    }

}