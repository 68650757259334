import {SortDirection} from "@renta-apps/athenaeum-toolkit";

export default class ListCostPoolsRequest {
    public pageNumber: number = 1;

    public pageSize: number = 100;

    public showDeleted: boolean = false;
    
    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;
}