import React from "react";
import {FileModel, IPagedList} from "@renta-apps/athenaeum-toolkit";
import {Button, ButtonType, ImageModal, ModalSize, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {ch, UserInteractionDataStorage} from "@renta-apps/athenaeum-react-common";
import ListWorkOrderAttachmentsRequest from "@/models/server/requests/ListWorkOrderAttachmentsRequest";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import WorkReportAttachment from "@/models/server/WorkReportAttachment";
import PageDefinitions from "@/providers/PageDefinitions";
import AttachmentsList from "@/pages/RentaTasks/Attachments/AttachmentsList/AttachmentsList";
import DownloadPdfModal from "@/pages/RentaTasks/Attachments/DownloadPdfModal/DownloadPdfModal";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import GetWorkOrderRequest from "@/models/server/requests/GetWorkOrderRequest";
import WorkOrderProvider from "@/providers/WorkOrderProvider";
import Localizer from "@/localization/Localizer";
import throwIfFalsy from "@/functions/ThrowIfFalsy";

import rentaTaskStyles from "../../RentaTask.module.scss";
import styles from "./Attachments.module.scss";

export interface IAttachmentsProps {
}

/** @private */
interface IAttachmentsState {
    request: ListWorkOrderAttachmentsRequest;

    /**
     * The Work Order which attachments to display.
     */
    workOrder: WorkOrderModel | null;
}

export default class Attachments extends AuthorizedPage<IAttachmentsProps, IAttachmentsState> {

    public state: IAttachmentsState = {
        request: Attachments.initializeListWorkOrderAttachmentsRequest(),
        workOrder: null,
    };

    private readonly _previewPictureModalRef: React.RefObject<ImageModal> = React.createRef();
    private readonly _downloadPdfModalRef: React.RefObject<DownloadPdfModal> = React.createRef();

    private static initializeListWorkOrderAttachmentsRequest(): ListWorkOrderAttachmentsRequest {
        return UserInteractionDataStorage.restore("ListWorkOrderAttachmentsRequest", new ListWorkOrderAttachmentsRequest());
    }

    private async onOpenAttachmentListItemAsync(workOrderAttachmentItem: WorkReportAttachment): Promise<void> {
        const file: FileModel = await this.postAsync("api/rentaTasks/GetWorkOrderAttachmentFile", workOrderAttachmentItem.fileId);

        if (!file) {
            await ch.flyoutErrorAsync(Localizer.genericFileNotFound);
            return;
        }

        if (RentaTaskConstants.imageFileTypes.includes(file.type)) {
            if (this._previewPictureModalRef.current) {
                await this._previewPictureModalRef.current!.openAsync(file);
            }
        } else {
            if (this._downloadPdfModalRef.current) {
                await this._downloadPdfModalRef.current!.openAsync(file);
            }
        }
    }

    private async fetchDataAsync(workOrderId: string): Promise<IPagedList<WorkReportAttachment>> {
        throwIfFalsy(workOrderId, nameof(workOrderId));

        const request: ListWorkOrderAttachmentsRequest = new ListWorkOrderAttachmentsRequest();

        request.workOrderId = workOrderId;
        request.public = false;
        request.types = this.state.request.types;
        request.pageNumber = this.state.request.pageNumber;
        request.pageSize = this.state.request.pageSize;
        request.date = this.state.request.date;
        request.from = this.state.request.from;
        request.to = this.state.request.to;

        UserInteractionDataStorage.set("ListWorkOrderAttachmentsRequest", this.state.request);

        return await this.postAsync("/api/rentaTasks/listWorkOrderAttachmentFiles", request);
    }

    public getManual(): string {
        return Localizer.attachmentsPageGetManual;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const workOrderId: string | null = this.routeId;

        throwIfFalsy(workOrderId);

        const request: GetWorkOrderRequest = new GetWorkOrderRequest();

        request.workOrderId = workOrderId!;
        request.excludeDeletedUserSalaryHours = true;

        const workOrder: WorkOrderModel = await WorkOrderProvider.getWorkOrderAsync(this, request);

        await this.setState({workOrder});
    }

    public render(): React.ReactNode {
        const workOrder: WorkOrderModel | null = this.state.workOrder;

        if (!workOrder) {
            return null;
        }

        const workOrderId: string = workOrder.id;
        const title: string = workOrder.name ?? '';
        const containerClass: string = this.css(rentaTaskStyles.pageContainer, styles.attachments);

        return (
            <PageContainer alertClassName={rentaTaskStyles.alert}
                           className={containerClass}
            >
                <PageHeader title={title}
                            className={rentaTaskStyles.leftPageHeader}
                >
                    <span className={rentaTaskStyles.rightPageHeader}/>
                </PageHeader>

                <PageRow className={styles.pageRow}>
                    <AttachmentsList noDataText={Localizer.genericNoData}
                                     request={this.state.request}
                                     fetchData={async () => await this.fetchDataAsync(workOrderId)}
                                     onOpenAttachmentListItem={async (_, item) => await this.onOpenAttachmentListItemAsync(item)}
                    />
                </PageRow>

                <PageRow className={styles.pageRow}>
                    <Button block
                            type={ButtonType.Orange}
                            label={Localizer.genericActionReturn}
                            route={PageDefinitions.rentaTasksWorkOrder(workOrderId)}
                    />
                </PageRow>

                <ImageModal id={"_previewPictureTaskAttachmentModal"}
                            ref={this._previewPictureModalRef}
                            size={ModalSize.ExtraLarge}
                            title={Localizer.formInputFilePreview}
                />

                <DownloadPdfModal id={"downloadPdfTaskAttachmentModal"}
                                  ref={this._downloadPdfModalRef}
                />
            </PageContainer>
        );
    }
}