import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import Category from "@/models/server/Category";
import {Button, ButtonType, Dropdown, Form, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import CatalogItem from "@/components/Catalog/CatalogItem/CatalogItem";
import Localizer from "@/localization/Localizer";

import styles from "./CategoryItem.module.scss";


export enum CategoryItemMode {
    Displaying = 0,
    Creating = 1,
    Editing = 2,
}

export interface ICategoryItemProps {
    category: Category;

    /**
     * If set to {@link CategoryItemMode.Creating}, {@link availableParentCategories}, {@link existingNames} and {@link onSaveChanges} must also be provided.
     *
     * If set to {@link CategoryItemMode.Editing}, {@link availableParentCategories}, {@link existingNames}, {@link onRemove} and {@link onSaveChanges} must also be provided.
     */
    mode: CategoryItemMode;

    /**
     * Required if {@link mode} is {@link CategoryItemMode.Creating} or {@link CategoryItemMode.Editing}. Has no effect otherwise.
     */
    availableParentCategories?: Category[];
    canRemove?: boolean;
    className?: string;

    /**
     * Required if {@link mode} is {@link CategoryItemMode.Creating} or {@link CategoryItemMode.Editing}. Has no effect otherwise.
     */
    existingNames?: string[];

    onClick?(): Promise<void>

    /**
     * Required if {@link mode} is {@link CategoryItemMode.Editing}. Has no effect otherwise.
     */
    onRemove?(category: Category): Promise<void>;

    /**
     * Required if {@link mode} is {@link CategoryItemMode.Creating} or {@link CategoryItemMode.Editing}. Has no effect otherwise.
     */
    onSaveChanges?(category: Category): Promise<void>;
}

interface ICategoryItemState {
    category: Category;
}

export default class CategoryItem extends BaseComponent<ICategoryItemProps, ICategoryItemState> {

    public state: ICategoryItemState = {
        category: CategoryItem.cloneCategory(this.props.category),
    }


    // Getters

    private get availableParentCategories(): Category[] {
        return this.props.availableParentCategories ?? [];
    }

    private get canRemove(): boolean {
        return (this.props.canRemove === true);
    }

    private get category(): Category {
        return this.state.category;
    }

    private get existingNames(): string[] {
        return this.props.existingNames!
    }

    private get hasChanged(): boolean {
        return (JSON.stringify(this.props.category) !== JSON.stringify(this.category));
    }

    private get mode(): CategoryItemMode {
        return this.props.mode;
    }

    private get creatingEditing(): boolean {
        return (this.mode === CategoryItemMode.Creating) || (this.mode === CategoryItemMode.Editing);
    }

    private get isValid(): boolean {
        return (this.validateName(this.category.name) === null);
    }

    private get categoryItemId(): string {
        return `categoryItem_${this.category.name.replace(/\s/g,'_')}`;
    }


    // Sync-methods

    private static cloneCategory(category: Category): Category {
        return {
            icon: category.icon,
            id: category.id,
            isCategory: category.isCategory,
            name: category.name,
            parent: category.parent,  // NOT CLONED!!
            parentId: category.parentId,
            catalogType: category.catalogType,
        };
    }

    private validateName(name: string): string | null {
        const originalName: string = this.props.category.name.trim().toLowerCase();
        const newName: string = name.trim().toLowerCase();

        return (newName === "")
            ? Localizer.validatorsRequired.format(Localizer.genericName)
            : (newName !== originalName) && (this.existingNames.some((existingName: string) => existingName.trim().toLowerCase() === newName))
                ? Localizer.categoryItemValidationCategoryWithSameNameAlreadyExists
                : null;
    }


    // Async-methods

    private async onClickAsync(): Promise<void> {
        (this.props.onClick) && await this.props.onClick();
    }

    private async onNameChangeAsync(name: string): Promise<void> {
        this.category.name = name;
        this.reRender();
    }

    private async onIconChangeAsync(icon: string): Promise<void> {
        this.category.icon = icon;
        this.reRender();
    }

    private async onParentChange(parent: Category | null): Promise<void> {
        this.category.parent = parent;
        this.category.parentId = parent?.id ?? null;
        this.reRender();
    }

    private async resetChangesAsync(): Promise<void> {
        const category = CategoryItem.cloneCategory(this.props.category);
        this.setState({
            category
        });
    }

    private async saveChangesAsync(): Promise<void> {
        if (!this.isValid) {
            return;
        }

        await this.props.onSaveChanges!(this.category);

        // the category will be received as props -> need to clone it again.
        const category: Category = CategoryItem.cloneCategory(this.category);
        this.setState({
            category
        });
    }

    private get renderCreatingEditing(): JSX.Element {
        return (
            <React.Fragment>

                <Form id={"categoryItemForm"} onSubmit={async (form) => {await form.validateAsync(); await this.saveChangesAsync();}}>

                    <TwoColumns>

                        <TextInput id={"categoryName"}
                                   required
                                   label={Localizer.genericName}
                                   value={this.category.name}
                                   onChange={async (_, name: string) => await this.onNameChangeAsync(name)}
                                   validators={[(value: string) => this.validateName(value)]}
                        />

                        <Dropdown id={"parentCategory"}
                                  autoGroupSelected 
                                  label={Localizer.categoryItemParentCategory}
                                  items={this.availableParentCategories}
                                  selectedItem={this.category.parent}
                                  onChange={async (_, parent: Category | null) => await this.onParentChange(parent)}
                        />

                    </TwoColumns>

                    <TwoColumns>

                        <TextInput id={"categoryIcon"}
                                   label={Localizer.genericIcon}
                                   value={this.category.icon}
                                   onChange={async (_, icon: string) => await this.onIconChangeAsync(icon)}
                        />

                        <div className={styles.editButtons}>

                            {
                                (this.mode === CategoryItemMode.Editing) &&
                                (
                                    <React.Fragment>

                                        <Button small
                                                id={"goIntoCategory"}
                                                label={Localizer.genericGoInto}
                                                icon={{name: "fa-sign-in"}}
                                                type={ButtonType.Default}
                                                onClick={async () => await this.onClickAsync()}
                                        />

                                        {
                                            (this.canRemove) &&
                                            (
                                                <Button small
                                                        id={"deleteCategory"}
                                                        label={Localizer.genericActionDelete}
                                                        icon={{name: "fa-trash"}}
                                                        type={ButtonType.Danger}
                                                        onClick={async () => await this.props.onRemove!(this.props.category)}
                                                />
                                            )
                                        }

                                    </React.Fragment>
                                )
                            }

                            {
                                (this.hasChanged) &&
                                (
                                    <React.Fragment>

                                        <Button small
                                                id={"cancelCategoryChanges"}
                                                label={Localizer.genericActionCancel}
                                                icon={{name: "fa-ban"}}
                                                type={ButtonType.Warning}
                                                onClick={async () => await this.resetChangesAsync()}
                                        />

                                        {
                                            (this.isValid) &&
                                            (
                                                <Button submit small
                                                        id={"saveCategory"}
                                                        label={Localizer.genericActionSave}
                                                        icon={{name: "fa-save"}}
                                                        type={ButtonType.Primary}
                                                />
                                            )
                                        }

                                    </React.Fragment>
                                )
                            }

                        </div>

                    </TwoColumns>

                </Form>

            </React.Fragment>
        );
    }

    private get renderNormal(): JSX.Element {
        return (
            <div className={styles.content}>
                <span>
                    {this.category.name}
                </span>
            </div>
        );
    }

    render(): React.ReactNode {
        const className: string = this.css(
            styles.categoryItem,
            this.props.className,
            (this.creatingEditing) && styles.creatingEditing);

        return (
            <CatalogItem id={this.categoryItemId}
                         className={className}
                         iconName={this.category.icon}
                         onClick={async () => {(this.mode === CategoryItemMode.Displaying) && await this.onClickAsync();}}
            >
                {
                    (this.creatingEditing)
                        ? this.renderCreatingEditing
                        : this.renderNormal
                }
            </CatalogItem>
        );
    }
};