import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";
import {Button, ButtonType, IconSize, Inline, Modal} from "@renta-apps/athenaeum-react-components";
import {ICatalogDataProvider} from "@/providers/CatalogDataProvider";
import Catalog, {CatalogMode} from "../Catalog";
import WorkOrderExtraCharge from "@/models/server/WorkOrderExtraCharge";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";
import Localizer from "@/localization/Localizer";
import {CatalogType} from "@/models/Enums";

import styles from "./CatalogModal.module.scss";


interface ICatalogModalProps {
    dataProvider: ICatalogDataProvider;
    onChange?(sender: CatalogModal, item: WorkOrderEquipment | WorkOrderExtraCharge | WorkOrderRentalItemModel): Promise<void>;
    onFavoriteChange?(sender: CatalogModal, productId: string, favorite: boolean): Promise<void>;
    onModalChange?(): Promise<void>;
}

interface ICatalogModalState {
    catalogType: CatalogType;
}

export default class CatalogModal extends BaseComponent<ICatalogModalProps, ICatalogModalState> {
    
    public state: ICatalogModalState = {
        catalogType: CatalogType.SalesProduct,
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _categoriesOrProductsRef: React.RefObject<Catalog> = React.createRef();

    private async onChangeItemAsync(item: WorkOrderEquipment | WorkOrderExtraCharge | WorkOrderRentalItemModel): Promise<void> {
        if (this.props.onChange) {
            await this.props.onChange(this, item);
        }
    }

    private async onFavoriteChangeAsync(productId: string, favorite: boolean): Promise<void> {
        if (this.props.onFavoriteChange) {
            await this.props.onFavoriteChange(this, productId, favorite);
        }
    }
    
    private async onModalChangeClickAsync (): Promise<void> {
        await this.closeAsync();
        if (this.props.onModalChange) {
            await this.props.onModalChange();
        }
    }

    public async openAsync(catalogType: CatalogType): Promise<void> {
        if (this._modalRef.current) {
            await this.setState({catalogType: catalogType});
            await this._categoriesOrProductsRef.current!.reloadAsync();
            await this._modalRef.current.openAsync();
        }
    }

    public async reloadAsync(search: string | null = null, categoryId: string | null = null, push: boolean = true): Promise<void> {
        if (this._categoriesOrProductsRef.current) {
            await this._categoriesOrProductsRef.current.reloadAsync(search, categoryId, push);
        }
    }

    public async closeAsync(): Promise<void> {
        if (this._modalRef.current) {
            await this._modalRef.current.closeAsync();
        }
        await this.reRenderAsync();
    }

    public static get modalId(): string {
        return "categoriesOrProductsModal";
    }

    public get isOpen(): boolean {
        return ((this._modalRef.current != null) && (this._modalRef.current.isOpen));
    }

    public render(): React.ReactNode {
        const title : string = this.state.catalogType === CatalogType.RentalMassProduct 
            ? Localizer.rentaTasksAddEquipmentCategoriesOrProductsModalTitleRentalMassEquipment
            : Localizer.rentaTasksAddEquipmentCategoriesOrProductsModalTitleEquipment;
        return (
            <Modal className={styles.catalogModal}
                   id={CatalogModal.modalId}
                   ref={this._modalRef}
                   title={title}
                   subtitle={Localizer.rentaTasksAddEquipmentCategoriesOrProductsModalSubtitleChooseEquipment}
                   customCloseButton={Localizer.catalogModalAddAndClose}
            >

                <div>
                    {
                        (this.state.catalogType === CatalogType.RentalMassProduct) &&
                        <Inline>
                            <Button icon={{name: "arrow-left", size: IconSize.Large}}
                                    label={Localizer.catalogModalAddRentalEquipmentInstead}
                                    type={ButtonType.Orange}
                                    onClick={() => this.onModalChangeClickAsync()}/>
                        </Inline>
                    }
                    
                    <Catalog id={"addEquipmentModeCatalog"}
                             type={this.state.catalogType}
                             ref={this._categoriesOrProductsRef}
                             dataProvider={this.props.dataProvider}
                             mode={CatalogMode.AddEquipment}
                             onChange={(_, item) => this.onChangeItemAsync(item)}
                             onFavoriteChange={(_, item, favorite) => this.onFavoriteChangeAsync(item, favorite)}
                    />
                </div>

            </Modal>
        )
    }
}