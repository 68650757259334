import {getRandomInteger, getRandomPhoneNumber, pageData} from "@/helpers/CypressHelper";

context("(Desktop) User Management", () => {
    beforeEach(() => {
        cy.init("Desktop");
    });

    it("Should add a new Manager user and display all invitations", () => {
        const role: Role = "Manager";
        const name: string = `${role}-${getRandomInteger()}`;
        const email: string = `${name}@test.com`;
        const phone: string = getRandomPhoneNumber();
        const userSettings: AddUserSettings = generateUserSettings(
            role,
            email,
            phone,
            name,
            "Default warehouse",
            "Test cost pool",
            "123123123");

        cy.loginAsAdmin();
        cy.navigateToAdminUserManagementPage();
        cy.addUser(userSettings);

        assertTableRowsNumber(1);

        shouldResendInvitationTest(userSettings);
    });

    it("Should add a new Subcontractor Contract and Subcontractor mounter user", () => {
        const role: Role = "Mounter";
        const name: string = `${role}-${getRandomInteger()}`;
        const email: string = `${name}@test.com`;
        const phone: string = getRandomPhoneNumber();

        const subcontractorName: string = `SubcontractorContract-${getRandomInteger(null, 100)}`;
        const vatId: string = `${getRandomInteger(1000000, 9999999)}-${getRandomInteger(null, 9)}`;
        
        const newSubcontractorContract: AddSubcontractorContractSettings = generateSubcontractorContractSettings(
            subcontractorName,
            vatId,
            "Helsinginkatu, Turku, Finland"
        );
        const userSettings: AddUserSettings = generateUserSettings(
            role, 
            email,
            phone,
            name,
            null,
            null,
            null,
            true,
            true,
            true,
            "SubContractorOrganization.SubCompanyContract1",
            newSubcontractorContract);

        cy.loginAsAdmin();
        cy.navigateToAdminUserManagementPage();
        cy.addUser(userSettings);

        assertTableRowsNumber(1);
    });
});

function shouldResendInvitationTest(addUserSettings: AddUserSettings): void {
    describe("Resend invitation", () => {
        cy.get('#resend').trigger('click');

        cy.contains(`Are you sure you want to invite user "${addUserSettings.firstName} ${addUserSettings.lastName}"?`).parent().within(() => {
            cy.contains('button', 'Confirm').click();
        });

        assertTableRowsNumber(2);
    });
}

function assertTableRowsNumber(expectedRows: number){
    pageData().userManagement.invitationsTab.tab().click();
    pageData().userManagement.invitationsTab.invitationsGrid().within(() => {
        cy.get("tbody tr.athenaeum-grid-gridRow").filter(":visible").should("have.length", expectedRows);
    });
}

function generateUserSettings(
    role: Role,
    email: string,
    phoneNumber: string,
    name: string,
    warehouse?: string | null,
    costPool?: string | null,
    employeeNumber?: string | null,
    canCreateWorkOrder?: boolean | null,
    canCompleteWorkOrder?: boolean | null,
    canProcessForm?: boolean | null,
    subcontractorContract?: string | null,
    newSubcontractorContract?: AddSubcontractorContractSettings | null,
) : AddUserSettings {
    return {
        authType: "Email",
        role: role,
        email: email,
        phoneNumber: phoneNumber,
        firstName: name,
        lastName: "Doe",
        middleName: "Patrick",
        warehouse: warehouse,
        costPool: costPool,
        employeeNumber: employeeNumber,
        canCreateWorkOrder: canCreateWorkOrder,
        canCompleteWorkOrder: canCompleteWorkOrder,
        canProcessForm: canProcessForm,
        language: "English",
        address: "Helsinginkatu, Turku, Finland",
        street: "Helsinginkatu",
        city: "Turku",
        postalCode: "",
        subcontractorContract: subcontractorContract,
        newSubcontractorContract: newSubcontractorContract
    };
}

function generateSubcontractorContractSettings(
    name: string,
    vatId: string,
    address: string,
) : AddSubcontractorContractSettings {
    return {
        name: name,
        vatId: vatId,
        address: address
    };
}
