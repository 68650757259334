import {FileModel} from "@renta-apps/athenaeum-toolkit";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";

/**
 * An image file helper.
 */
class ImageHelper {

    /**
     * Check if a file is an image with a supported format.
     *
     * @param image File to check for being a supported image.
     * @return true if the {@link image} is a supported image; false otherwise.
     */
    public isSupportedImage(image: Readonly<FileModel>): boolean {
        return RentaTaskConstants.supportedImageFileExtensions.some(extension => image.name.toLowerCase().endsWith(extension));
    }
}

/** {@link ImageHelper} singleton. */
export default new ImageHelper();