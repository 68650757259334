import CatalogDataProvider from "../../providers/CatalogDataProvider";
import {IBaseComponent} from "@renta-apps/athenaeum-react-common";
import Category from "@/models/server/Category";
import Product from "@/models/server/Product";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";
import AddCategoryRequest from "@/models/server/requests/AddCategoryRequest";
import SaveCategoryResponse from "@/models/server/responses/SaveCategoryResponse";
import SaveCategoryRequest from "@/models/server/requests/SaveCategoryRequest";
import DeleteCategoryResponse from "@/models/server/responses/DeleteCategoryResponse";
import AddProductRequest from "@/models/server/requests/AddProductRequest";
import SaveProductResponse from "@/models/server/responses/SaveProductResponse";
import SaveProductRequest from "@/models/server/requests/SaveProductRequest";
import DeleteProductResponse from "@/models/server/responses/DeleteProductResponse";
import Localizer from "@/localization/Localizer";


export default class CatalogManagementDataProvider extends CatalogDataProvider {

    public constructor() {
        super([], [], [], false);
    }

    protected includeDeleted(): boolean {
        return true;
    }

    private checkCategoryIsValid(category: Category, creating: boolean): string | null {
        if (!category.name.trim()) {
            return "Categorys name cannot be empty"
        }

        if ((creating) && (category.id.trim())) {
            return "Category cannot have an id assigned before creation";
        }

        if ((!creating) && (!this._categories.some((category2: Category) => category.id === category2.id))) {
            return `Category with id '${category.id}' does not exist`;
        }

        const categoryExistsInSameCatalog: boolean = this._categories.some((category2: Category) =>
            (category.catalogType === category2.catalogType)
            && (category.id !== category2.id)
            && (category.name.trim().toLowerCase() === category2.name.trim().toLowerCase()));

        if (categoryExistsInSameCatalog) {
            return `Category with name '${category.name}' already exists`;
        }

        return null;
    }

    private static checkSaveCategoryResponseIsValid(category: Category, response: SaveCategoryResponse): void {
        if ((category.id) && (response.category) && (response.category.id !== category.id)) {
            throw new Error(`Original categories id '${category.id}' is different from the returned categories id '${response.category.id}'`);
        }
    }

    private checkProductIsValid(product: Product, creating: boolean): string | null {
        if (!product.name.trim()) {
            return "Products name cannot be empty";
        }

        if (!this._categories.some((category: Category) => category.id === product.categoryId)) {
            return "Products categoryId must belong to an existing category";
        }

        if ((creating) && (product.id.trim())) {
            return "Product cannot have an id assigned before creation";
        }

        if ((!creating) && (!this._products.some((product2: Product) => product2.id === product.id))) {
            return `Product with id '${product.id}' does not exist`;
        }

        const productExistsInSameCatalog: boolean = this._products
            .filter((product2: Product) => product2.category.catalogType === product.category.catalogType)
            .some((product2: Product) => (product.id !== product2.id) && (product.name.trim().toLowerCase() === product2.name.trim().toLowerCase()));

        if (productExistsInSameCatalog) {
            return `Product with name '${product.name}' already exists`;
        }
        return null;
    }

    private static checkSaveProductResponseIsValid(product: Product, response: SaveProductResponse): void {
        if ((product.id) && (response.product) && (response.product.id !== product.id)) {
            throw new Error(`Original products id '${product.id}' is different from the returned products id '${response.product.id}'`);
        }
    }


    // Categories

    public async createCategoryAsync(sender: IBaseComponent, category: Category): Promise<SaveCategoryResponse> {
        await this.initializeAsync(sender);

        let validityError: string | null = this.checkCategoryIsValid(category, true);
        if (validityError) {
            throw new Error(validityError);
        }

        const request: AddCategoryRequest = {
            catalogType: category.catalogType,
            icon: category.icon,
            name: category.name,
            parentId: category.parentId,
        };

        const response: SaveCategoryResponse = await sender.postAsync("api/admin/addCategory", request);
        CatalogManagementDataProvider.checkSaveCategoryResponseIsValid(category, response);

        const createdCategory: Category | null = response.category;
        if (createdCategory) {
            this._categories.push(createdCategory);
        }

        return response;
    }

    public async editCategoryAsync(sender: IBaseComponent, category: Category): Promise<SaveCategoryResponse> {
        await this.initializeAsync(sender);

        const existingCategoryIndex: number = this._categories.findIndex((existingCategory: Category) => existingCategory.id === category.id);
        if (existingCategoryIndex < 0) {
            throw new Error(`Category with id '${category.id}' does not exist`);
        }

        let validityError: string | null = this.checkCategoryIsValid(category, false);
        if (validityError) {
            throw new Error(validityError);
        }

        const request: SaveCategoryRequest = {
            categoryId: category.id,
            icon: category.icon,
            name: category.name,
            parentId: category.parentId,
        };

        const response: SaveCategoryResponse = await sender.postAsync("api/admin/saveCategory", request);
        CatalogManagementDataProvider.checkSaveCategoryResponseIsValid(category, response);

        const editedCategory: Category | null = response.category;
        if (editedCategory) {
            this._categories
                .where((category: Category) => category.parentId === category.id)
                .forEach((category: Category) => {
                    category.parent = editedCategory;
                });
            this._products
                .where((product: Product) => product.categoryId === category.id)
                .forEach((product: Product) => {
                    product.category = editedCategory;
                });
            this._categories.splice(existingCategoryIndex, 1, editedCategory);
        }

        return response;
    }

    public async removeCategoryAsync(sender: IBaseComponent, categoryId: string): Promise<void> {
        await this.initializeAsync(sender);

        const existingCategoryIndex: number = this._categories.findIndex((existingCategory: Category) => (existingCategory.id === categoryId));
        if (existingCategoryIndex < 0) {
            throw new Error(`Category with id '${categoryId}' does not exist`);
        }

        const response: DeleteCategoryResponse = await sender.postAsync(`api/admin/deleteCategory?categoryId=${categoryId}`, null);

        if (response.categoryDoesNotExist) {
            throw new Error(`Category with id '${categoryId}' does not exist`);
        }
        if (response.categoryNotEmpty) {
            throw new Error(`Category with id '${categoryId}' is not empty`);
        }

        this._categories.splice(existingCategoryIndex, 1);
    }

    // Products

    public async createProductAsync(sender: IBaseComponent, product: Product): Promise<SaveProductResponse> {
        await this.initializeAsync(sender);

        let validationError: string | null = this.checkProductIsValid(product, true);
        if (validationError) {
            throw new Error(validationError);
        }

        const request: AddProductRequest = {
            categoryId: product.categoryId!,
            customUnit: product.customUnit,
            externalId: product.externalId,
            icon: product.icon,
            name: product.name,
            price: product.price,
            unit: product.unit,
            keywords: product.keywords
        };

        const response: SaveProductResponse = await sender.postAsync("api/admin/addProduct", request);
        CatalogManagementDataProvider.checkSaveProductResponseIsValid(product, response);

        const createdProduct: Product | null = response.product;
        if (createdProduct) {
            this._products.push(createdProduct);
            const createdEquipment: WorkOrderEquipment = CatalogDataProvider.productToEquipment(createdProduct);
            this._equipment.push(createdEquipment);
        }

        return response;
    }

    public async editProductAsync(sender: IBaseComponent, product: Product): Promise<SaveProductResponse> {
        await this.initializeAsync(sender);

        const existingProductIndex: number = this._products.findIndex((existingProduct: Product) => existingProduct.id === product.id);
        if (existingProductIndex < 0) {
            throw new Error(`Product with id '${product.id}' does not exist`);
        }

        let validationError: string | null = this.checkProductIsValid(product, false);
        if (validationError) {
            throw new Error(validationError);
        }

        const request: SaveProductRequest = {
            categoryId: product.categoryId!,
            customUnit: product.customUnit,
            externalId: product.externalId,
            icon: product.icon,
            name: product.name,
            price: product.price,
            productId: product.id,
            unit: product.unit,
            keywords: product.keywords
        }

        const response: SaveProductResponse = await sender.postAsync("api/admin/saveProduct", request);
        CatalogManagementDataProvider.checkSaveProductResponseIsValid(product, response);

        const editedProduct: Product | null = response.product;
        if (editedProduct) {
            await this.updateProductsDataAsync(existingProductIndex, editedProduct);
        }

        return response;
    }

    public async updateProductsDataAsync(productIndex: number, product: Product): Promise<void> {
        this._products[productIndex] = product;
        // this._products.splice(productIndex, 1, product);
        this._equipment
            .where((equipment: WorkOrderEquipment) => equipment.productId === product.id)
            .forEach((equipment: WorkOrderEquipment) => {
                equipment.product = product;
            });
    }

    public async removeProductAsync(sender: IBaseComponent, productId: string): Promise<DeleteProductResponse> {
        await this.initializeAsync(sender);

        const existingProductIndex: number = this._products.findIndex((existingProduct: Product) => existingProduct.id === productId);
        if (existingProductIndex < 0) {
            throw new Error(`Product with id '${productId}' does not exist`);
        }

        const response: DeleteProductResponse = await sender.postAsync(`api/admin/deleteProduct?productId=${productId}`, null);

        if (response.removedPermanently) {
            this._products.splice(existingProductIndex, 1);
            const existingEquipmentIndex: number = this._equipment.findIndex((equipment: WorkOrderEquipment) => equipment.productId === productId);
            if (existingProductIndex >= 0) {
                this._equipment.splice(existingEquipmentIndex, 1);
            }
        } else {
            await this.updateProductsDataAsync(existingProductIndex, response.product!);
        }

        return response;
    }

    public async restoreProductAsync(sender: IBaseComponent, productId: string): Promise<Product> {
        await this.initializeAsync(sender);

        const productToRestoreIndex: number = this._products.findIndex((productToRestore: Product) => productToRestore.id === productId);
        if (productToRestoreIndex < 0) {
            throw new Error(Localizer.get(Localizer.catalogManagementErrorMessageRestoreProductNotExists, productId));
        }

        const restoredProduct: Product = await sender.postAsync(`api/admin/restoreProduct?productId=${productId}`, null);

        await this.updateProductsDataAsync(productToRestoreIndex, restoredProduct);

        return restoredProduct;
    }
}