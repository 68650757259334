import CatalogManagement from "@/pages/CatalogManagement/CatalogManagement";
import {CatalogType} from "@/models/Enums";
import Localizer from "@/localization/Localizer";

export default class SalesProductCatalog extends CatalogManagement {
    public getType(): CatalogType {
        return CatalogType.SalesProduct;
    }

    public getTitle(): string {
        return Localizer.catalogManagementPageTitle;
    }
}