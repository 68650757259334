import {FormItemType} from "@/models/Enums";
import React from "react";
import FormPictures from "@/components/FormSteps/FormPictures/FormPictures";
import FormChecks from "@/components/FormSteps/FormChecks/FormChecks";
import FormOptions from "@/components/FormSteps/FormOptions/FormOptions";
import FormResource from "@/components/FormSteps/FormResource/FormResource";
import FormText from "@/components/FormSteps/FormText/FormText";
import FormQuestion from "@/components/FormSteps/FormQuestion/FormQuestion";
import FormItemModel from "@/models/server/forms/FormItem";

export interface IFormItemProps {

    /**
     * NOTE: Mutated!
     */
    formItem: FormItemModel;
    readonly?: boolean;

    /**
     * Invoked when the {@link formItem} is mutated.
     */
    onChange?: () => Promise<void>;
}

export default function FormItemView(props: Readonly<IFormItemProps>): JSX.Element {
    const {formItem, onChange, readonly} = props;

    switch (formItem.type) {
        case FormItemType.Pictures:
            return (
                <FormPictures preview={readonly}
                              formItem={formItem}
                              onChange={onChange}
                />
            );
        case FormItemType.Checks:
            return (
                <FormChecks preview={readonly}
                            formItem={formItem}
                            onChange={onChange}
                />
            );

        case FormItemType.Options:
            return (
                <FormOptions preview={readonly}
                             formItem={formItem}
                             onChange={onChange}
                />
            );
        case FormItemType.Resource:
            return (
                <FormResource preview={readonly}
                              formItem={formItem}
                              onChange={onChange}
                />
            );
        case FormItemType.Text:
            return (
                <FormText preview={readonly}
                          formItem={formItem}
                          onChange={onChange}
                />
            );
        case FormItemType.Question:
        case FormItemType.QuestionPictures:
            return (
                <FormQuestion preview={readonly}
                              formItem={formItem}
                              onChange={onChange}
                />
            );
        default:
            throw new Error(`Unsupported form item type ${formItem.type}`);
    }
}