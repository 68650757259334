import React from "react";
import {Button, ButtonType, Checkbox, IconSize, Inline, Spinner, ToolbarContainer} from "@renta-apps/athenaeum-react-components";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import AddConstructionSiteAttachmentModal from "@/pages/ConstructionSiteManagement/ConstructionSiteAttachmentsPanel/AddConstructionSiteAttachmentModal/AddConstructionSiteAttachmentModal";
import AddConstructionSiteAttachmentsRequest from "@/models/server/requests/AddConstructionSIteAttachmentsRequest";
import ToolbarModel from "@/pages/ConstructionSiteManagement/ConstructionSiteAttachmentsPanel/Toolbar/ToolbarModel";
import Localizer from "@/localization/Localizer";

import styles from "./Toolbar.module.scss";

interface IToolbarProps  {
    model: ToolbarModel;
    constructionSiteId: string;
    readonly: boolean
    addConstructionSiteAttachments(sender: IBaseComponent, request: AddConstructionSiteAttachmentsRequest): Promise<void>;
    reloadConstructionSiteAttachments(sender: IBaseComponent): Promise<void>;
    onChange?(model: ToolbarModel): Promise<void>;
}

interface IToolbarState {
    model: ToolbarModel;
}

export default class ConstructionSiteAttachmentsPanelToolbar extends BaseComponent<IToolbarProps, IToolbarState> {

    // Fields

    private readonly addAttachmentModalId: string = `addConstructionSiteAttachmentModal_${this.props.constructionSiteId}`;

    public state: IToolbarState = {
        model: this.props.model
    };

    // Methods

    private get disabled(): boolean {
        return this.props.readonly;
    }

    private async processOnChange(invoke: boolean = false): Promise<void> {
        await this.setState({ model: this.state.model });
        if (invoke) {
            await this.props.onChange?.(this.state.model);
        }
    }

    private async setAttachmentPublicAsync(value: boolean): Promise<void> {
        this.state.model.public = value;
        await this.processOnChange(true);
    }

    private async resetFiltersAsync(): Promise<void> {
        this.state.model.public = false;
        await this.processOnChange(true);
    }

    public hasSpinner(): boolean {
        return true;
    }

    public render(): React.ReactNode {
        return (
            <ToolbarContainer className={styles.toolbarContainer}>
                {
                    (this.isSpinning()) && <Spinner />
                }

                <Inline>
                    <Checkbox inline
                              id="public"
                              label={Localizer.constructionSiteAttachmentsPanelToolbarPublicCheckbox}
                              value={this.state.model.public}
                              onChange={async(_,value) => await this.setAttachmentPublicAsync(value)}
                    />

                    <Button toggleModal
                            label={Localizer.constructionSiteAttachmentsPanelToolbarAddAttachmentsButton}
                            className="ml-1"
                            icon={{name: "plus", size: IconSize.Normal}}
                            type={ButtonType.Orange}
                            dataTarget={this.addAttachmentModalId}
                    />

                    <Button title={Localizer.genericActionReload}
                            disabled={this.disabled}
                            className="ml-1"
                            icon={{name: "far history", size: IconSize.Large }}
                            type={ButtonType.Info}
                            onClick={async (_) => await this.resetFiltersAsync()}
                    />

                    <Button title={Localizer.genericSearch}
                            disabled={this.disabled}
                            className="ml-1"
                            icon={{name: "far search", size: IconSize.Large }}
                            type={ButtonType.Orange}
                            onClick={async (sender) => await this.props.reloadConstructionSiteAttachments(sender)}
                    />
                </Inline>

                <AddConstructionSiteAttachmentModal id={this.addAttachmentModalId} 
                                                    constructionSiteId={this.props.constructionSiteId} 
                                                    addConstructionSiteAttachments={this.props.addConstructionSiteAttachments}
                />

            </ToolbarContainer>
        );
    }
};