import React from "react";
import {BaseComponent, TextAlign} from "@renta-apps/athenaeum-react-common";
import {CellAction, CellModel, ColumnActionType, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridOddType} from "@renta-apps/athenaeum-react-components";
import {ActionType} from "@/models/Enums";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import ConstructionSiteAttachment from "@/models/server/ConstructionSiteAttachment";
import Localizer from "@/localization/Localizer";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import FileTypeTranslator from "@/helpers/FileTypeTranslator";


interface IConstructionSiteAttachmentsGridProps {
    readonly?: boolean;
    displayPublicity?: boolean;
    attachmentsSortColumn?: string;
    attachmentsSortDirection?: SortDirection;

    fetchAttachments(pageNumber: number,
                     pageSize: number,
                     sortColumnName: string | null,
                     sortDirection: SortDirection | null): Promise<IPagedList<ConstructionSiteAttachment>>
    processAttachmentOperation?(cell: CellModel<ConstructionSiteAttachment>, action: CellAction<ConstructionSiteAttachment>): Promise<void>;
}

export class ConstructionSiteAttachmentsGrid extends BaseComponent<IConstructionSiteAttachmentsGridProps> {

    // Fields

    private readonly _gridRef: React.RefObject<Grid<ConstructionSiteAttachment>> = React.createRef();

    private readonly _attachmentsColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            textAlign: TextAlign.Left,
            minWidth: 40,
            stretch: false
        },
        {
            header: Localizer.constructionSiteAttachmentsGridFileNameLanguageItemName,
            accessor: nameof.full<ConstructionSiteAttachment>(a => a.file.name),
            textAlign: TextAlign.Left,
            minWidth: 200,
            sorting: true
        },
        {
            header: Localizer.constructionSiteAttachmentsGridFileTypeLanguageItemName,
            accessor: nameof.full<ConstructionSiteAttachment>(a => a.file.type),
            textAlign: TextAlign.Left,
            sorting: true,
            transform: (_, cellValue: string, __) => FileTypeTranslator.translate(cellValue),
        },
        {
            header: Localizer.constructionSiteAttachmentsGridPublicLanguageItemName,
            accessor: nameof<ConstructionSiteAttachment>(a => a.public),
            type: ColumnType.Boolean,
            textAlign: TextAlign.Left,
            visible: this.displayPublicity,
            sorting: true
        },
        {
            header: Localizer.genericActionsLanguageItemName,
            minWidth: 180,
            init: (cell) => this.initAttachmentOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.genericActionSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processAttachmentOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.genericActionCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processAttachmentOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.constructionSiteAttachmentsGridDeleteAttachmentLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell: CellModel<ConstructionSiteAttachment>) => cell.model.id && Localizer.constructionSiteAttachmentsGridConfirmDelete.format(cell.model.file.name),
                    callback: async (cell, action) => await this.processAttachmentOperationAsync(cell, action)
                },
                {
                    name: "preview",
                    title: Localizer.genericActionPreviewLanguageItemName,
                    type: ColumnActionType.Preview,
                    right: true,
                    callback: async (cell, action) => await this.processAttachmentOperationAsync(cell, action)
                },
                {
                    title: Localizer.constructionSiteAttachmentsGridDownloadAttachmentLanguageItemName,
                    type: ColumnActionType.Download,
                    right: true,
                    callback: async (cell, action) => await this.processAttachmentOperationAsync(cell, action)
                }
            ]
        },
    ];

    // Properties

    private get displayPublicity(): boolean {
        return (this.props.displayPublicity === true);
    }

    private get readonly(): boolean {
        return (this.props.readonly === true);
    }

    public get gridRef(): Grid<ConstructionSiteAttachment> | null {
        return this._gridRef.current;
    }


    // Methods

    private initAttachmentOperations(cell: CellModel<ConstructionSiteAttachment>): void {
        const attachment: ConstructionSiteAttachment = cell.row.model;

        const readonly: boolean = (this.readonly);
        const modified: boolean = (cell.row.modified);
        const isValid: boolean = (attachment.file.isFileModel);
        const isNew: boolean = (!attachment.id);
        const canPreview: boolean = (RentaTaskConstants.previewFileTypes.includes(attachment.file.type));

        const saveAction: CellAction<ConstructionSiteAttachment> = cell.actions[0];
        const cancelAction: CellAction<ConstructionSiteAttachment> = cell.actions[1];
        const deleteAction: CellAction<ConstructionSiteAttachment> = cell.actions[2];
        const previewAction: CellAction<ConstructionSiteAttachment> = cell.actions[3];
        const downloadAction: CellAction<ConstructionSiteAttachment> = cell.actions[4];

        saveAction.visible = (!readonly) && (modified) && (isValid);
        cancelAction.visible = (!readonly) && (modified) && (!isNew);
        deleteAction.visible = (!readonly) && (isValid);
        previewAction.visible = (isValid) && (canPreview);
        downloadAction.visible = (isValid) && (!isNew);
    }

    private async processAttachmentOperationAsync(cell: CellModel<ConstructionSiteAttachment>, action: CellAction<ConstructionSiteAttachment>): Promise<void> {
        await this.props.processAttachmentOperation?.(cell, action);
    }

    private async fetchAttachments(pageNumber: number,
                                   pageSize: number,
                                   sortColumnName: string | null,
                                   sortDirection: SortDirection | null): Promise<IPagedList<ConstructionSiteAttachment>> {
        return await this.props.fetchAttachments(pageNumber, pageSize, sortColumnName, sortDirection);
    }

    public async reloadAsync(): Promise<void> {
        await this._gridRef.current?.reloadAsync();
    }

    public render() {
        return (
            <Grid ref={this._gridRef}
                  columns={this._attachmentsColumns}
                  readonly={this.readonly}
                  minWidth="auto"
                  className={"mr-auto"}
                  noDataText={Localizer.constructionSiteAttachmentsGridNoDataText}
                  hovering={GridHoveringType.Row}
                  odd={GridOddType.None}
                  fetchData={async (_, pageNumber, pageSize, sortColumnName, sortDirection) => await this.fetchAttachments(pageNumber, pageSize, sortColumnName, sortDirection)}
                  pagination={10}
                  defaultSortColumn={this.props.attachmentsSortColumn}
                  defaultSortDirection={this.props.attachmentsSortDirection}
            />
        );
    }
}