import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {AttachmentType} from "@/models/Enums";

export default class SaveConstructionSiteAttachmentRequest {
    public id: string = "";
    
    public constructionSiteId: string = "";

    public public: boolean = false;

    public file: FileModel | null = null;

    public type: AttachmentType = AttachmentType.ConstructionSiteAttachment;

    public readonly isSaveConstructionSiteAttachmentRequest: boolean = true;
}