import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import ToolbarModel from "./ToolbarModel";
import {ConstructionSiteOrWarehouseType} from "@/models/Enums";
import ConstructionSiteOrWarehouse from "../../../../models/server/ConstructionSiteOrWarehouse";
import WorkOrderModel from "../../../../models/server/WorkOrderModel";
import AddMounterModal from "../AddMounterModal/AddMounterModal";
import UserStatus from "../../../../models/server/UserStatus";
import AddMounterHoursRequest from "../../../../models/server/requests/AddMounterHoursRequest";
import Localizer from "../../../../localization/Localizer";
import {
    Button,
    ButtonType,
    DateInput,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    Form, Icon,
    IconSize, IconStyle,
    Inline,
    JustifyContent,
    SelectListGroup,
    SelectListItem,
    SelectListSeparator,
    Spinner,
    ToolbarButton,
    ToolbarContainer,
    ToolbarRow
} from "@renta-apps/athenaeum-react-components";
import User from "@/models/server/User";
import GetEmployeesRequest from "@/models/server/requests/GetEmployeesRequest";
import CostPool from "@/models/server/CostPool";
import UnleashHelper from "@/helpers/UnleashHelper";
import FeatureFlags from "@/helpers/FeatureFlags";
import UserProvider from "@/providers/UserProvider";

import styles from "./Toolbar.module.scss";

interface IToolbarProps {
    model: ToolbarModel;

    onChange?(model: ToolbarModel): Promise<void>;

    fetchMounters(sender: IBaseComponent): Promise<UserStatus[]>;

    fetchConstructionSites(sender: IBaseComponent): Promise<ConstructionSiteOrWarehouse[]>;

    fetchTasks(sender: IBaseComponent, constructionSiteOrWarehouseId: string): Promise<WorkOrderModel[]>;

    addHours(sender: IBaseComponent, request: AddMounterHoursRequest): Promise<void>;
}

interface IToolbarState {
    model: ToolbarModel;

    /**
     * Manager users displayed in a filter dropdown.
     */
    managers: readonly Readonly<User>[];

    /**
     * Mounter users displayed in a filter dropdown.
     */
    mounters: readonly Readonly<User>[];

    /**
     * Cost pools displayed in a filter dropdown.
     */
    costPools: CostPool[];
}

export default class Toolbar extends BaseComponent<IToolbarProps, IToolbarState> {

    state: IToolbarState = {
        model: this.props.model,
        managers: [],
        mounters: [],
        costPools: [],
    };

    private async processOnChange(invoke: boolean = false, clearUsers: boolean = false): Promise<void> {
        if (clearUsers) {
            const newModel: ToolbarModel = {
                from: this.state.model.from,
                to: this.state.model.from,
                reportType: this.state.model.reportType,
                source: this.state.model.source,
                costPools: this.state.model.costPools,
                mounters: [],
                managers: [],
            };

            await this.setState({model: newModel});
        } else {
            await this.setState({model: this.state.model});
        }

        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this.state.model);
        }
    }

    private getDataTypeItemList(reportType: string | Date | null): SelectListItem[] {

        const items: SelectListItem[] = [];
        //
        const groupDailyHours = new SelectListGroup();
        groupDailyHours.name = Localizer.workDayPanelToolbarDailyHoursLanguageItemName;
        groupDailyHours.order = 0;
        const item: SelectListItem = new SelectListItem();
        item.value = "";
        item.ref = "*";
        item.text = Localizer.workDayPanelToolbarDailyHoursLanguageItemName;
        item.subtext = Localizer.workDayPanelToolbarDailyHoursSubTextLanguageItemName;
        item.selected = (!reportType);
        item.group = groupDailyHours;
        items.push(item);
        //
        const groupMonths = new SelectListGroup();
        groupMonths.name = Localizer.workDayPanelToolbarMonthsLanguageItemName;
        groupMonths.order = 1;
        const now: Date = Utility.now();
        const month: Date = new Date(now.getFullYear(), now.getMonth(), 1);
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach((index) => {
            const date: Date = Utility.addMonths(month, -index);
            const item = new SelectListItem();
            item.value = date.toDateString();
            item.ref = date;
            item.selected = ((!!reportType) && (date.toString() == reportType.toString()));
            item.text = Utility.format("{0:MM.yyyy}", date);
            item.subtext = Utility.format("{0} {1}", Utility.getMonth(date), date.getFullYear());
            item.group = groupMonths;
            items.push(item);
        });
        return items;
    }

    private async setDataTypeAsync(reportType: string | Date | null): Promise<void> {
        if (reportType === "*") {
            reportType = null;
        }
        this.state.model.reportType = reportType;
        await this.processOnChange(true);
    }

    private async setConstructionSiteTypeAsync(item: SelectListItem): Promise<void> {
        this.state.model.source = (item.value === "0")
            ? ConstructionSiteOrWarehouseType.ConstructionSite
            : (item.value === "1")
                ? ConstructionSiteOrWarehouseType.Warehouse
                : null;
        await this.processOnChange(true, true);
    }

    private async setFromAsync(from: Date | null): Promise<void> {
        if (this.state.model.from !== from) {
            this.state.model.from = from;
            await this.processOnChange();
        }
    }

    private async setToAsync(to: Date | null): Promise<void> {
        if (this.state.model.to !== to) {
            this.state.model.to = to;
            await this.processOnChange();
        }
    }

    private async clearAsync(): Promise<void> {
        this.state.model = new ToolbarModel();
        await this.processOnChange(true);
    }

    private getConstructionSiteTypeSelectedItem(): SelectListItem {
        const value: string = (this.state.model.source != null)
            ? this.state.model.source.toString()
            : "";
        return new SelectListItem(value);
    }

    private getConstructionSiteTypeItemList(): SelectListItem[] {
        const item0: SelectListItem = new SelectListItem();
        item0.value = "";
        item0.text = Localizer.workDayPanelToolbarAnyLanguageItemName;
        item0.subtext = Localizer.workDayPanelToolbarAnySubTextLanguageItemName;

        const item2: SelectListItem = new SelectListItem();
        item2.value = ConstructionSiteOrWarehouseType.ConstructionSite.toString();
        item2.text = Localizer.constructionSitesSitesInfoLanguageItemName;
        item2.subtext = Localizer.workDayPanelToolbarConstructionSitesInfoSubTextLanguageItemName;

        const item3: SelectListItem = new SelectListItem();
        item3.value = ConstructionSiteOrWarehouseType.Warehouse.toString();
        item3.text = Localizer.dropdownGroupWarehousesLanguageItemName;
        item3.subtext = Localizer.workDayPanelToolbarAssignedWarehousesLanguageItemName;

        return [item0, new SelectListSeparator(), item2, item3];
    }

    private get dailyHours(): boolean {
        return !this.state.model.reportType;
    }

    private readonly onManagersChangeAsync = async (dropdown: Dropdown<User>, _: User | null, userInteraction: boolean): Promise<void> => {
        this.state.model.managers = dropdown.selectedItems;

        if (userInteraction) {
            await this.processOnChange(true);
        }
    };

    public async componentDidMount(): Promise<void> {
        const [managers, mounters, costPools] = await Promise.all([
            UserProvider.getManagersAsync(this),
            UserProvider.getMountersAsync(this, new GetEmployeesRequest()),
            this.postAsync("api/employees/getCostPools"),
        ]);

        await this.setState({
            managers,
            mounters,
            costPools: costPools as CostPool[],
        });
    }

    private readonly onMountersChangeAsync = async (dropdown: Dropdown<User>, _: User | null, userInteraction: boolean): Promise<void> => {
        this.state.model.mounters = dropdown.selectedItems;

        if (userInteraction) {
            await this.processOnChange(true);
        }
    };

    private readonly onCostPoolsChangeAsync = async (dropdown: Dropdown<CostPool>, _: CostPool | null, userInteraction: boolean): Promise<void> => {
        const costPools: CostPool[] = dropdown.selectedItems;
        
        if (UnleashHelper.isEnabled(FeatureFlags.CostPoolMounterAutoSelect)) {
            this.state.model.mounters = User.getWithCostPools([...this.state.mounters], costPools);
        }
        
        this.state.model.costPools = costPools;

        if (userInteraction) {
            await this.processOnChange(true);
        }
    };


    protected getEndpoint(): string {
        return "api/employees/getLastPeriods";
    }

    public hasSpinner(): boolean {
        return true;
    }

    public isAsync(): boolean {
        return true;
    }

    public render(): React.ReactNode {

        return (
            <ToolbarContainer className={styles.toolbar}>

                <ToolbarRow justify={JustifyContent.SpaceBetween}>

                    <Form inline>

                        <Dropdown small required noValidate noSubtext autoGroupSelected noWrap
                                  align={DropdownAlign.Left}
                                  label={Localizer.workDayPanelToolbarReportType}
                                  minWidth="170px"
                                  orderBy={DropdownOrderBy.None}
                                  items={this.getDataTypeItemList(this.state.model.reportType)}
                                  onChange={async (_, item) => await this.setDataTypeAsync(item as any)}
                        />

                        {
                            (this.dailyHours) &&
                            <Dropdown small required noValidate noSubtext noWrap
                                      align={DropdownAlign.Left}
                                      label={Localizer.workDayPanelToolbarSource}
                                      minWidth="200px"
                                      orderBy={DropdownOrderBy.None}
                                      items={this.getConstructionSiteTypeItemList()}
                                      selectedItem={this.getConstructionSiteTypeSelectedItem()}
                                      onChange={async (_, item) => await this.setConstructionSiteTypeAsync(item!)}
                            />
                        }

                        {
                            (this.dailyHours) &&
                            (
                                <Dropdown<User> multiple autoGroupSelected small noValidate noSubtext noWrap
                                                label={Localizer.genericManager}
                                                minWidth="180px"
                                                align={DropdownAlign.Left}
                                                orderBy={DropdownOrderBy.None}
                                                items={this.state.managers}
                                                selectedItems={this.state.model.managers}
                                                disabled={this.state.managers.length <= 0}
                                                onChange={this.onManagersChangeAsync}
                                />
                            )
                        }

                        <Dropdown<User> multiple autoGroupSelected small noValidate noSubtext noWrap
                                        label={Localizer.genericMounters}
                                        minWidth="180px"
                                        align={DropdownAlign.Left}
                                        orderBy={DropdownOrderBy.None}
                                        items={this.state.mounters}
                                        selectedItems={this.state.model.mounters}
                                        disabled={this.state.mounters.length <= 0}
                                        onChange={this.onMountersChangeAsync}
                        />

                        <Dropdown<CostPool> multiple autoGroupSelected small noValidate noSubtext noWrap
                                            label={Localizer.genericCostPools}
                                            minWidth="180px"
                                            align={DropdownAlign.Left}
                                            orderBy={DropdownOrderBy.None}
                                            items={this.state.costPools}
                                            selectedItems={this.state.model.costPools}
                                            disabled={this.state.costPools.length <= 0}
                                            onChange={this.onCostPoolsChangeAsync}
                        />

                        {
                            (this.dailyHours) &&
                            <DateInput small rentaStyle
                                       id="from"
                                       label={Localizer.workDayPanelToolbarDate}
                                       maxDate={new Date()}
                                       value={this.state.model.from || undefined}
                                       onChange={async (date) => await this.setFromAsync(date)}
                                       append={(
                                           <Icon name="fad fa-window-close"
                                                 style={IconStyle.Regular}
                                                 size={IconSize.Normal}
                                                 className={styles.clearDate}
                                                 tooltip={Localizer.genericClear}
                                                 onClick={() => this.setFromAsync(null)}
                                           />
                                       )}
                            />
                        }

                        {
                            (this.dailyHours) &&
                            <Inline className={styles.filtersTopPadding}>

                                <span className={this.css(styles.dateDelimiter)}>-</span>

                                <DateInput inline small rentaStyle
                                           maxDate={new Date()}
                                           value={this.state.model.to || undefined}
                                           onChange={async (date) => await this.setToAsync(date)}
                                           append={(
                                               <Icon name="fad fa-window-close"
                                                     style={IconStyle.Regular}
                                                     size={IconSize.Normal}
                                                     className={styles.clearDate}
                                                     tooltip={Localizer.genericClear}
                                                     onClick={() => this.setToAsync(null)}
                                               />
                                           )}
                                />

                                <Button
                                    id="searchEmployees"
                                    small label={Localizer.workDayPanelToolbarSearch}
                                    icon={{name: "fas search"}}
                                    type={ButtonType.Orange}
                                    onClick={async () => await this.processOnChange(true)}
                                />

                                <Button
                                    small title={Localizer.addConstructionsiteToolbarClearFilter}
                                    icon={{name: "far history", size: IconSize.Large}}
                                    type={ButtonType.Info}
                                    onClick={async () => await this.clearAsync()}
                                />

                            </Inline>
                        }
                    </Form>

                    {
                        (this.dailyHours) &&
                        (
                            <Inline>

                                <ToolbarButton label={Localizer.workDayPanelToolbarAddMounter}
                                               icon={{name: "plus", size: IconSize.Large}}
                                               type={ButtonType.Orange}
                                               dataTarget="addMounterModal" toggleModal
                                />

                            </Inline>
                        )
                    }

                </ToolbarRow>

                {
                    (this.isSpinning()) && <Spinner/>
                }

                <AddMounterModal
                    id="addMounterModal"
                    fetchMounters={this.props.fetchMounters}
                    fetchConstructionSites={this.props.fetchConstructionSites}
                    fetchTasks={this.props.fetchTasks}
                    addHours={this.props.addHours}
                />

            </ToolbarContainer>
        );
    }
}
