import React from "react";
import {PwaHelper} from "@renta-apps/athenaeum-toolkit";
import {WebApplicationType, ApplicationContext, ch} from "@renta-apps/athenaeum-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import Localizer from "../../localization/Localizer";

import styles from "./Home.module.scss";
import {IconStyle, LinkWidget, PageContainer, RouteWidget, WidgetContainer} from "@renta-apps/athenaeum-react-components";

interface IHomeProps {
    name: string | null;
}

interface IHomeState {
    pwaInstall: boolean;
}

export default class Home extends AnonymousPage<IHomeProps, IHomeState> {

    state: IHomeState = {
        pwaInstall: false
    };

    private get pwaInstall(): boolean {
        return this.state.pwaInstall;
    }

    private static get widgetRentaLinkUrl(): string {
        if (ch.isFinland) {
            return "https://renta.fi/";
        } else if (ch.isSweden) {
            return "https://renta.se/"
        }
        
        return "https://renta.fi/";
    }
    
    private async onPwaHelperInitialized(): Promise<void> {
        const context: ApplicationContext = this.getContext();
        const applicationType: WebApplicationType = context.applicationType;
        const pwaInstall: boolean = (PwaHelper.canBeInstalled) && ((applicationType == WebApplicationType.MobileBrowser) || ((context.isDevelopment) && (applicationType == WebApplicationType.DesktopBrowser)));
        await this.setState({pwaInstall});
    }

    private async installPwaAsync(): Promise<void> {
        await PwaHelper.installAsync();
        await this.reRenderAsync();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await PwaHelper.subscribe(async () => await this.onPwaHelperInitialized());
    }

    public getTitle(): string {
        return Localizer.topNavFrontpage;
    }
    
    public render(): React.ReactNode {
        return (
            <PageContainer transparent className={styles.home} alertClassName={styles.alert}>
                
                <WidgetContainer>

                    {
                        (this.pwaInstall)
                            ? <RouteWidget icon={{name: "fas tablet-alt"}} label={Localizer.widgetPwaInstall} description={Localizer.widgetPwaDescription} onClick={() => this.installPwaAsync()} />
                            : <LinkWidget icon={{ name: "android", style: IconStyle.Brands }} url={Home.widgetRentaLinkUrl} label={Localizer.widgetRentaLinkLabel} description={Localizer.widgetRentaLinkDescription} />
                    }

                    <RouteWidget icon={{ name: "sign-in" }} route={PageDefinitions.loginRoute} label={Localizer.topNavLogin} description={Localizer.widgetLoginDescription} />
                    
                </WidgetContainer>

            </PageContainer>
        );
    }
}