import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Dropdown, Form, NumberInput, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import CatalogItem from "@/components/Catalog/CatalogItem/CatalogItem";
import Category from "@/models/server/Category";
import Product from "@/models/server/Product";
import {CatalogType, ProductUnit} from "@/models/Enums";
import DeleteProductResponse from "@/models/server/responses/DeleteProductResponse";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./ProductItem.module.scss";


export enum ProductItemMode {
    Displaying = 0,
    Creating = 1,
    Editing = 2,
}

export interface IProductItemProps {
    mode: ProductItemMode;
    product: Product;

    /**
     * Required if {@link mode} is {@link ProductItemMode.Creating} or {@link ProductItemMode.Editing}. Has no effect otherwise.
     */
    availableParentCategories?: Category[];
    className?: string;

    /**
     * Required if {@link mode} is {@link CategoryItemMode.Creating} or {@link CategoryItemMode.Editing}. Has no effect otherwise.
     */
    existingExternalIds?: string[];

    /**
     * Required if {@link mode} is {@link CategoryItemMode.Creating} or {@link CategoryItemMode.Editing}. Has no effect otherwise.
     */
    existingNames?: string[];

    /**
     * Required if {@link mode} is {@link ProductItemMode.Editing}. Has no effect otherwise.
     */
    onRemove?(product: Product): Promise<DeleteProductResponse | null>;

    /**
     * Required if {@link mode} is {@link ProductItemMode.Editing} and {@link Product} is deleted. Has no effect otherwise.
     */
    onRestore?(product: Product): Promise<Product>;

    /**
     * Required if {@link mode} is {@link ProductItemMode.Creating} or {@link ProductItemMode.Editing}. Has no effect otherwise.
     */
    onSaveChanges?(product: Product): Promise<void>;
}

interface IProductItemState {
    product: Product;
}

export default class ProductItem extends BaseComponent<IProductItemProps, IProductItemState> {

    public state: IProductItemState = {
        product: ProductItem.cloneProduct(this.props.product)
    };


    // Getters

    private get availableParentCategories(): Category[] {
        return this.props.availableParentCategories || [];
    }

    private get mode(): ProductItemMode {
        return this.props.mode;
    }

    private get existingExternalIds(): string[] {
        return this.props.existingExternalIds!;
    }

    private get existingNames(): string[] {
        return this.props.existingNames!
    }

    private get product(): Product {
        return this.state.product;
    }

    private get isDeletedProduct(): boolean {
        return (this.state.product.deleted);
    }

    private get creatingEditing(): boolean {
        return (this.mode === ProductItemMode.Creating) || (this.mode === ProductItemMode.Editing);
    }

    private get isEditingMode(): boolean {
        return (this.mode === ProductItemMode.Editing);
    }

    private get hasChanged(): boolean {
        return (JSON.stringify(this.props.product) !== JSON.stringify(this.product));
    }

    private get isValid(): boolean {
        return (this.validateCustomUnit(this.product.customUnit) === null)
            && (this.validateExternalId(this.product.externalId) === null)
            && (this.validateName(this.product.name) === null)
            && (!this.isDeletedProduct);
    }

    private get productItemId(): string {
        return `productItem_${this.product.name.replace(/\s/g,'_')}`;
    }


    // Sync-methods

    private static cloneProduct(product: Product): Product {
        const clone: Product = new Product();

        clone.category = product.category;  // NOT CLONED!!
        clone.categoryId = product.categoryId;
        clone.customUnit = product.customUnit;
        clone.externalId = product.externalId;
        clone.favorite = product.favorite;
        clone.icon = product.icon;
        clone.id = product.id;
        clone.isProduct = product.isProduct;
        clone.name = product.name;
        clone.price = product.price;
        clone.unit = product.unit;
        clone.keywords = product.keywords;
        clone.deleted = product.deleted;
        clone.deletedAt = product.deletedAt;
        clone.deletedById = product.deletedById;
        clone.deletedBy = product.deletedBy;

        return clone;
    }

    private validateCustomUnit(customUnit: string | null): string | null {
        if ((this.product.unit !== ProductUnit.Custom) || (customUnit === null)) {
            return null;
        }

        const newCustomUnit: string = customUnit.trim().toLowerCase();

        return (newCustomUnit === "")
            ? Localizer.validatorsRequired.format(Localizer.genericName)
            : null;
    }

    private validateExternalId(externalId: string | null): string | null {
        if ((externalId === null) || (externalId.length <= 0)) {
            return null;
        }

        const originalExternalId: string | undefined = this.props.product.externalId?.trim().toLowerCase();
        const newExternalId: string = externalId.trim().toLowerCase();

        return (newExternalId === "")
            ? Localizer.validatorsRequired.format(Localizer.genericExternalId)
            : (newExternalId !== originalExternalId) && (this.existingExternalIds.some((existingExternalId: string) => existingExternalId.trim().toLowerCase() === newExternalId))
                ? Localizer.productItemValidationProductWithSameExternalIdAlreadyExists
                : null;
    }

    private validateName(name: string): string | null {
        const originalName: string = this.props.product.name.trim().toLowerCase();
        const newName: string = name.trim().toLowerCase();

        return (newName === "")
            ? Localizer.validatorsRequired.format(Localizer.genericName)
            : (newName !== originalName) && (this.existingNames.some((existingName: string) => existingName.trim().toLowerCase() === newName))
                ? Localizer.productItemValidationProductWithSameNameAlreadyExists
                : null;
    }


    // Async-methods

    private async onNameChangeAsync(name: string): Promise<void> {
        this.product.name = name;
        this.reRender();
    }

    private async onIconChangeAsync(icon: string): Promise<void> {
        this.product.icon = icon;
        this.reRender();
    }

    private async onKeywordsChangeAsync(value: string): Promise<void> {
        this.product.keywords = value;
        this.reRender();
    }

    private async onCategoryChange(category: Category): Promise<void> {
        this.product.category = category;
        this.product.categoryId = category.id;
        this.reRender();
    }

    private async onPriceChange(price: number): Promise<void> {
        this.product.price = price;
        this.reRender();
    }

    private async onUnitChange(unit: ProductUnit): Promise<void> {
        this.product.unit = unit;
        if (unit !== ProductUnit.Custom) {
            this.product.customUnit = null;
        }

        this.reRender();
    }

    private async onCustomUnitChange(unit: string): Promise<void> {
        this.product.customUnit = unit;
        this.reRender();
    }

    private async onExternalIdChange(externalId: string): Promise<void> {
        this.product.externalId = externalId;
        this.reRender();
    }

    private async onCancelAsync(): Promise<void> {
        const product = ProductItem.cloneProduct(this.props.product);
        this.setState({product});
    }

    private async onRemoveAsync(): Promise<void> {
        const response: DeleteProductResponse | null = await this.props.onRemove!(this.props.product);

        if (response != null) {
            if (!response.removedPermanently) {
                const product: Product = ProductItem.cloneProduct(response.product!);

                this.setState({product});
            }
        }
    }

    private async onRestoreAsync(): Promise<void> {
        const restoredProduct: Product = await this.props.onRestore!(this.props.product);
        const product: Product = ProductItem.cloneProduct(restoredProduct);

        this.setState({product});
    }

    private async saveChangesAsync(): Promise<void> {
        if (!this.isValid) {
            return;
        }

        await this.props.onSaveChanges!(this.product);

        // the product might be received as props -> need to clone it again.

        // TODO: favorite is "false", even if it is "null" in prop...

        const product: Product = ProductItem.cloneProduct(this.product);
        this.setState({product});
    }


    // Renders

    private get renderNormal(): JSX.Element {
        return (
            <span>
                {this.product.name}
            </span>
        );
    }

    /** Sales products have a price, mass rental items don't. */
    private displayPrice() {
        return this.product.category?.catalogType === CatalogType.SalesProduct;
    }
    
    private get renderEditing(): JSX.Element {
        return (
            <Form readonly={this.isDeletedProduct} onSubmit={async (form) => {await form.validateAsync(); await this.saveChangesAsync();}}>

                <TwoColumns>

                    <TextInput required
                               id={"productName"}
                               readonly={this.isDeletedProduct}
                               label={Localizer.genericName}
                               value={this.product.name}
                               onChange={async (_, value: string) => await this.onNameChangeAsync(value)}
                               validators={[(value: string) => this.validateName(value)]}
                    />

                    <Dropdown required autoGroupSelected
                              id={"productCategory"}
                              label={Localizer.genericCategory}
                              disabled={this.isDeletedProduct}
                              items={this.availableParentCategories}
                              selectedItem={this.product.category}
                              onChange={async (_, category: Category) => await this.onCategoryChange(category)}
                    />

                </TwoColumns>

                <TwoColumns>

                    <TextInput id={"productExternalId"}
                               label={Localizer.genericExternalId}
                               readonly={this.isDeletedProduct}
                               value={this.product.externalId || undefined}
                               onChange={async (_, value: string) => await this.onExternalIdChange(value)}
                               validators={[(value: string) => this.validateExternalId(value)]}
                    />

                    <TextInput id={"productIcon"}
                               label={Localizer.genericIcon}
                               readonly={this.isDeletedProduct}
                               value={this.product.icon}
                               onChange={async (_, value: string) => await this.onIconChangeAsync(value)}
                    />

                </TwoColumns>

                <TwoColumns>

                    {
                        this.displayPrice() &&
                        (
                            <NumberInput id={"productPrice"}
                                         label={Localizer.genericPrice}
                                         readonly={this.isDeletedProduct}
                                         value={this.product.price}
                                         step={0.01}
                                         onChange={async (_, value) => await this.onPriceChange(value)}
                            />
                        )
                    }

                    <Dropdown required noFilter
                              id={"productUnit"}
                              label={Localizer.genericUnit}
                              disabled={this.isDeletedProduct}
                              items={EnumProvider.getProductUnitItems()}
                              selectedItem={EnumProvider.getProductUnitItem(this.product.unit)}
                              onChange={async (_, item) => await this.onUnitChange(parseInt(item!.value))}
                    />

                </TwoColumns>

                {
                    (this.product.unit === ProductUnit.Custom) &&
                    (
                        <TwoColumns>
                            <TextInput required
                                       id={"productCustomUnit"}
                                       label={Localizer.genericCustomUnit}
                                       readonly={this.isDeletedProduct}
                                       value={this.product.customUnit || undefined}
                                       onChange={async (_, value) => await this.onCustomUnitChange(value)}
                                       validators={[(value: string) => this.validateCustomUnit(value)]}
                            />
                        </TwoColumns>
                    )
                }

                <TwoColumns>

                    <TextInput id={"productKeywords"}
                               label={Localizer.productItemProductKeywords}
                               readonly={this.isDeletedProduct}
                               value={this.product.keywords || ""}
                               onChange={async (_, value: string) => await this.onKeywordsChangeAsync(value)}
                    />

                </TwoColumns>

                <div className={styles.editButtons}>

                    {
                        (this.isEditingMode && !this.isDeletedProduct) &&
                        (
                            <Button small
                                    id={"deleteProduct"}
                                    label={Localizer.genericActionDelete}
                                    icon={{name: "fa-trash"}}
                                    type={ButtonType.Danger}
                                    onClick={async () => await this.onRemoveAsync()}
                            />
                        )
                    }

                    {
                        (this.isEditingMode && this.isDeletedProduct) &&
                        (
                            <Button small
                                    id={"restoreProduct"}
                                    label={Localizer.genericActionRestore}
                                    icon={{name: "trash-restore"}}
                                    type={ButtonType.Blue}
                                    onClick={async () => await this.onRestoreAsync()}
                            />
                        )
                    }

                    {
                        (this.hasChanged) &&
                        (
                            <React.Fragment>

                                <Button small
                                        id={"cancelProductChanges"}
                                        label={Localizer.genericActionCancel}
                                        icon={{name: "fa-ban"}}
                                        type={ButtonType.Warning}
                                        onClick={async () => await this.onCancelAsync()}
                                />

                                {
                                    (this.isValid) &&
                                    (
                                        <Button submit small
                                                id={"saveProduct"}
                                                label={Localizer.genericActionSave}
                                                icon={{name: "fa-save"}}
                                                type={ButtonType.Primary}
                                        />
                                    )
                                }

                            </React.Fragment>
                        )
                    }

                </div>

            </Form>
        );
    }

    public render(): JSX.Element {
        const className: string = this.css(
            styles.productItem,
            this.props.className,
            (this.creatingEditing) && styles.creatingEditing
        );

        return (
            <CatalogItem id={this.productItemId}
                         className={className}
                         iconName={this.product.icon}
            >
                {
                    (this.creatingEditing)
                        ? this.renderEditing
                        : this.renderNormal
                }

            </CatalogItem>
        );
    }
}