import ExtraChargeType from "@/models/server/ExtraChargeType";

export default class WorkOrderExtraCharge {
    
    public id: string = "";
    
    public workOrderId: string = "";

    public extraChargeTypeId: string = "";
    
    public extraChargeType: ExtraChargeType | null = null;
    
    public amount: number = 0;
    
    public price: number = 0;

    public cost: number = 0;

    public description: string | null = null;

    public extraChargeDate: Date = new Date();

    public isWorkOrderExtraCharge: boolean = true;
}