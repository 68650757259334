// Need at least one import or export if --isolatedmodules is true

import {pageData} from "@/helpers/CypressHelper";

export{}

context("(Mobile) Renta Tasks: Create new work order", () => {
    beforeEach(() => {
        cy.init("Mobile");
    });

    it("Should create a new work order as mounter", () => {

        cy.loginAsMounter();

        const settings: AddWorkOrderSettingsMobile = {
            name: "Mobile work order - " + new Date(),
            workOrderType: "Enabled",
            description: "Description of the work order - " + new Date(),
        };

        cy.addWorkOrderMobile(settings);
    });

    it("Should create a new unscheduled work order as mounter", () => {

        cy.loginAsMounter();

        const settings: AddWorkOrderSettingsMobile = {
            name: "Mobile work order - " + new Date(),
            workOrderType: "Enabled",
            description: "Description of the work order - " + new Date(),
            unschedule: true
        };

        cy.addWorkOrderMobile(settings);
    });

    it("Should create a new unscheduled work order as subcontractor mounter", () => {

        cy.loginAsSubcontractorMounterCanCreateWorkOrder();

        const settings: AddWorkOrderSettingsMobile = {
            name: "Mobile work order - " + new Date(),
            workOrderType: "Enabled",
            description: "Description of the work order - " + new Date(),
            unschedule: true
        };

        cy.addWorkOrderMobile(settings);
    });

    it("Should not create a new work order as subcontractor mounters without permissions", () => {

        cy.loginAsSubcontractorMounterCanCompleteWorkOrder();

        pageData().dashboard.widgets.mobile.addWorkOrder().should("not.be.exist");
    });
});