import React from "react";
import {IPagedList} from "@renta-apps/athenaeum-toolkit";
import {BaseAsyncComponent, IBaseAsyncComponentState, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import ListWorkOrderAttachmentsRequest from "@/models/server/requests/ListWorkOrderAttachmentsRequest";
import WorkReportAttachment from "@/models/server/WorkReportAttachment";
import AttachmentsFiltersModal from "@/pages/RentaTasks/Attachments/AttachmentsFiltersModal/AttachmentsFiltersModal";
import {Button, ButtonType, IconSize} from "@renta-apps/athenaeum-react-components";
import ArsenalPagination from "@/components/ArsenalPagination/ArsenalPagination";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./AttachmentsList.module.scss";

interface IAttachmentsListProps {
    noDataText: string;
    request: ListWorkOrderAttachmentsRequest;

    fetchData(sender: IBaseComponent, request: ListWorkOrderAttachmentsRequest): Promise<IPagedList<WorkReportAttachment>>;
    onOpenAttachmentListItem(sender: IBaseComponent, item: WorkReportAttachment): Promise<void>;
}

interface IAttachmentsListState extends IBaseAsyncComponentState<IPagedList<WorkReportAttachment>> {
    request: ListWorkOrderAttachmentsRequest;
}

export default class AttachmentsList extends BaseAsyncComponent<IAttachmentsListProps, IAttachmentsListState, IPagedList<WorkReportAttachment>> {

    state: IAttachmentsListState = {
        data: null,
        isLoading: false,
        request: this.props.request
    };

    private readonly _attachmentsFiltersModalRef: React.RefObject<AttachmentsFiltersModal> = React.createRef();

    private async openFiltersAsync(): Promise<void> {
        await this._attachmentsFiltersModalRef.current!.openAsync();
    }

    private async submitFiltersAsync(request: ListWorkOrderAttachmentsRequest): Promise<void> {
        await this.setState({request});
        await this.reloadAsync();
    }

    private async onItemClickAsync(item: WorkReportAttachment): Promise<void> {
        await this.props.onOpenAttachmentListItem(this, item);
    }

    protected async fetchDataAsync(): Promise<IPagedList<WorkReportAttachment>> {
        return this.props.fetchData(this, this.state.request);
    }

    private async changePageNumberAsync(pageNumber: number): Promise<void> {
        this.state.request.pageNumber = pageNumber;

        await this.reloadAsync();
    }

    protected getEndpoint(): string {
        return "";
    }

    protected getIconName(): string {
        return (this.state.request.isEmpty())
            ? "far filter"
            : "fas filter";
    }

    public isAsync(): boolean {
        return true;
    }

    public getManual(): string {
        return Localizer.attachmentsListPageGetManual;
    }

    public render(): React.ReactNode {

        return (
            <div className={styles.attachmentsList}>

                <div className={this.css(styles.header)}>

                    <h4>{Localizer.attachmentsTitle}</h4>

                    <Button small icon={{name: this.getIconName(), size: IconSize.X2}}
                                type={ButtonType.Blue}
                                className={this.css(styles.filter)}
                                onClick={async () => await this.openFiltersAsync()}
                    />

                </div>

                {
                    (!this.isLoading) && ((this.state.data == null) || (this.state.data.items.length == 0)) &&
                    (
                        <div className={this.css(styles.attachmentListItem, styles.noItems)}>
                            {this.props.noDataText}
                        </div>
                    )
                }

                {
                    (this.state.data) &&
                    (
                        <React.Fragment>

                            <table className={styles.table}>
                                <tbody>
                                {
                                    this.state.data.items.map((item, index) =>
                                        (
                                            <tr key={index}>

                                                <td className={styles.info}>

                                                    <span className={"align-items-center"}
                                                          onClick={async () => await this.onItemClickAsync(item)}>

                                                        <div className={styles.attachmentItem}>

                                                            <div className={this.css(styles.twoLines, styles.topSpace)}>
                                                                <span>{EnumProvider.getAttachmentTypeText(item.type)}</span>
                                                                {
                                                                    (item.file) &&
                                                                    (
                                                                        <span>{item.file.name}</span>
                                                                    )
                                                                }
                                                            </div>

                                                        </div>

                                                        <div className={this.css(styles.user, styles.twoLines, styles.topSpace)}>
                                                            <span>{item.createdAt.format("D")}</span>
                                                            <span>{(item.createdBy) && (TransformProvider.userToString(item.createdBy))}</span>
                                                        </div>

                                                    </span>
                                                </td>

                                            </tr>
                                        ))
                                }
                                </tbody>
                            </table>

                            <ArsenalPagination pageNumber={this.state.request.pageNumber}
                                               pageCount={this.state.data.pageCount}
                                               onChange={async (_, pageNumber) => await this.changePageNumberAsync(pageNumber)}
                            />

                        </React.Fragment>
                    )
                }

                <AttachmentsFiltersModal ref={this._attachmentsFiltersModalRef}
                                      title={Localizer.attachmentsFiltersModalTitle}
                                      request={this.state.request}
                                      onSubmit={async (_, request) => await this.submitFiltersAsync(request)}
                />

            </div>
        );
    }
}