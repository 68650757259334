import RentaTaskConstants from "@/helpers/RentaTaskConstants";

/**
 * Request for getting a {@link WorkOrderModel}
 */

export default class GetWorkOrderRequest {

    /**
     * Id of the {@link WorkOrderModel} to get.
     */
    public workOrderId: string = RentaTaskConstants.defaultGuid;

    /**
     * If true, {@link WorkOrderModel.userSalaryHours} which are {@link UserSalaryHour.deleted} are not included in the returned {@link WorkOrderModel}; otherwise has no effect.
     */
    public excludeDeletedUserSalaryHours: boolean = false;
    
    public includeMounters: boolean = false;
}