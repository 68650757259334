import {executeWithIntercept, pageData, testConstants} from "@/helpers/CypressHelper";

// TODO: fix failing test
context.skip("(Desktop) Warehouse", () => {
    beforeEach(() => {
        cy.init("Desktop");
    });

    const seedWarehouse1Name = "Default warehouse";
    const seedWarehouse1Manager = "Manager Tester";
    const seedWarehouse1Address = "Gullhaug Torg 1, Oslo, 0484, Norge";

    const seedWarehouse2Name = "Second warehouse";
    const seedWarehouse2Manager = "Manager Tester";
    const seedWarehouse2Address = "Gullhaug Torg 2, Oslo, 0484, Norge";

    const {name: seedCostPoolName, description: seedCostPoolDescription} = testConstants.costPool;

    const timestamp: string = new Date().toISOString();

    // NOTE: addresses are limited to the environment country. These ones are valid in Finland.

    const newWarehouseName = "New warehouse" + timestamp;
    const newWarehouseManager = "Manager Tester";
    const newWarehouseAddress = "Kisukantie, Ulvila, 29340, Suomi";

    const editedWarehouseName = "🫸🏼oｏOＯ⭕️" + timestamp;
    const editedWarehouseManager = "Manager the Third Tester";
    const editedWarehouseAddress = "Mustinmäentie, Leppävirta, 79100, Suomi";

    const ensureVisibleWarehousesCount = (expected: number) => {
        pageData().warehouses.warehousesTab.warehousesGrid().within(() => {
            cy.get("tbody tr.athenaeum-grid-gridRow").filter(":visible").should("have.length", expected);
        });
    };

    const ensureVisibleWarehouse = (name: string, manager: string, address: string) => {
        pageData().warehouses.warehousesTab.warehousesGridCell(name, "name")
            .should("contain.text", name)
            .should('be.visible');
        pageData().warehouses.warehousesTab.warehousesGridCell(name, "manager")
            .should("contain.text", manager)
            .should('be.visible');
        pageData().warehouses.warehousesTab.warehousesGridCell(name, "address")
            .should("contain.text", address)
            .should('be.visible');
    };

    const fillSelectedWarehouseData = (name: string, manager: string, address: string) => {
        // name
        cy.focused().clear();
        cy.focused().type(name + "{enter}");

        // manager
        pageData().warehouses.warehousesTab.warehousesGridCell(name, "manager").selectDropdownValue(manager);

        // address
        pageData().warehouses.warehousesTab.warehousesGridCell(name, "address").trigger("click");
        cy.focused().clear();
        cy.focused().type(address);
        // wait for Google address dropdown to appear
        cy.wait(1000);
        cy.focused().type("{downarrow}{enter}");
        cy.wait(500);

        // save
        pageData().warehouses.warehousesTab.warehousesGridCell(name, "actions").within(() => {
            cy.get("[title=Save]").click();
        });
    };

    const deleteWarehouse = (name: string) => {
        pageData().warehouses.warehousesTab.warehousesGridCell(name, "actions").within(() => {
            cy.get("[title=Delete]").trigger("click");
        });
        pageData().common.confirmationDialog.confirmButton().trigger('click');
    };

    const openCostPoolsInnerGrid = (warehouseName: string): void => {
        pageData().warehouses.warehousesTab.warehousesGridCell(warehouseName, "name").within(() => {
            executeWithIntercept(() => {
                cy.get('[title="Cost pools"]').click();
            }, [pageData().warehouses.routes.getWarehouseCostPools]);
        });
    }

    const ensureVisibleCostPool = (name: string, description: string): void => {
        pageData().warehouses.warehousesTab.warehouseCostPoolsGrid().within(() => {
            pageData().warehouses.warehousesTab.warehouseCostPoolsGridCell(name, "name")
                .should('contain.text', name)
                .should('be.visible');
            pageData().warehouses.warehousesTab.warehouseCostPoolsGridCell(name, "description")
                .should('contain.text', description)
                .should('be.visible');
        });
    };

    const ensureVisibleCostPoolsCount = (expected: number): void => {
        pageData().warehouses.warehousesTab.warehouseCostPoolsGrid().within(() => {
            const selector = 'tbody tr.athenaeum-grid-gridRow';
            if (expected === 0) {
                cy.get('tbody tr.athenaeum-grid-gridRow').should('not.exist');
            }
            else {
                cy.get(selector).filter(':visible').should('have.length', expected);
            }
        });
    };

    const test = () => {
        cy.navigateToAdminWarehousesPage();
        pageData().warehouses.warehousesTab.tab().click();

        // Existing warehouses should be visible
        ensureVisibleWarehousesCount(2);
        ensureVisibleWarehouse(seedWarehouse1Name, seedWarehouse1Manager, seedWarehouse1Address);
        ensureVisibleWarehouse(seedWarehouse2Name, seedWarehouse2Manager, seedWarehouse2Address);

        // Add new warehouse
        pageData().warehouses.warehousesTab.warehousesGridToolbarItems.addWarehouseButton().click();
        fillSelectedWarehouseData(newWarehouseName, newWarehouseManager, newWarehouseAddress);
        cy.reload();
        ensureVisibleWarehousesCount(3);
        ensureVisibleWarehouse(seedWarehouse1Name, seedWarehouse1Manager, seedWarehouse1Address);
        ensureVisibleWarehouse(seedWarehouse2Name, seedWarehouse2Manager, seedWarehouse2Address);
        ensureVisibleWarehouse(newWarehouseName, newWarehouseManager, newWarehouseAddress);

        // Update added warehouse
        pageData().warehouses.warehousesTab.warehousesGridCell(newWarehouseName, "name").trigger('click');
        fillSelectedWarehouseData(editedWarehouseName, editedWarehouseManager, editedWarehouseAddress);
        cy.reload();
        ensureVisibleWarehousesCount(3);
        ensureVisibleWarehouse(seedWarehouse1Name, seedWarehouse1Manager, seedWarehouse1Address);
        ensureVisibleWarehouse(seedWarehouse2Name, seedWarehouse2Manager, seedWarehouse2Address);
        ensureVisibleWarehouse(editedWarehouseName, editedWarehouseManager, editedWarehouseAddress);

        // Delete existing warehouse
        deleteWarehouse(editedWarehouseName);
        cy.reload();
        ensureVisibleWarehousesCount(2);
        ensureVisibleWarehouse(seedWarehouse1Name, seedWarehouse1Manager, seedWarehouse1Address);
        ensureVisibleWarehouse(seedWarehouse2Name, seedWarehouse2Manager, seedWarehouse2Address);

        // Restore deleted warehouse
        pageData().warehouses.warehousesTab.warehousesGridToolbarItems.showDeletedWarehousesCheckbox().click();
        // note: amount of visible deleted warehouses cannot be ensured, since ones created and deleted by earlier test runs might exist.
        ensureVisibleWarehouse(seedWarehouse1Name, seedWarehouse1Manager, seedWarehouse1Address);
        ensureVisibleWarehouse(seedWarehouse2Name, seedWarehouse2Manager, seedWarehouse2Address);
        ensureVisibleWarehouse(editedWarehouseName, editedWarehouseManager, editedWarehouseAddress);
        pageData().warehouses.warehousesTab.warehousesGridCell(editedWarehouseName, "actions").within(() => {
            cy.get("[title=Restore]").click();
        });
        cy.reload();
        ensureVisibleWarehousesCount(3);
        ensureVisibleWarehouse(seedWarehouse1Name, seedWarehouse1Manager, seedWarehouse1Address);
        ensureVisibleWarehouse(seedWarehouse2Name, seedWarehouse2Manager, seedWarehouse2Address);
        ensureVisibleWarehouse(editedWarehouseName, editedWarehouseManager, editedWarehouseAddress);

        // Add cost pool mapping
        openCostPoolsInnerGrid(editedWarehouseName);
        cy.wait(500);
        ensureVisibleCostPoolsCount(0);
        pageData().warehouses.warehousesTab.warehouseCostPoolsGridToolbarItems.addWarehouseCostPoolButton().trigger('click');
        pageData().warehouses.warehousesTab.warehouseCostPoolsGrid().within(() => {
            pageData().warehouses.warehousesTab.warehouseCostPoolsGridCell("Nothing is selected", "name").selectDropdownValue(seedCostPoolName);
            pageData().warehouses.warehousesTab.warehouseCostPoolsGridCell(seedCostPoolName, "actions").within(() => {
                cy.get("[title=Save]").click();
            });
        });
        cy.reload();
        openCostPoolsInnerGrid(editedWarehouseName);
        ensureVisibleCostPoolsCount(1);
        ensureVisibleCostPool(seedCostPoolName, seedCostPoolDescription);

        // Remove cost pool mapping
        pageData().warehouses.warehousesTab.warehouseCostPoolsGrid().within(() => {
            pageData().warehouses.warehousesTab.warehouseCostPoolsGridCell(seedCostPoolName, "actions").within(() => {
                cy.get("[title=Delete]").trigger('click');
            });
        });
        pageData().common.confirmationDialog.confirmButton().trigger('click');
        cy.reload();
        openCostPoolsInnerGrid(editedWarehouseName);
        ensureVisibleCostPoolsCount(0);
        pageData().warehouses.warehousesTab.warehouseCostPoolsGrid().within(() => {
            cy.contains(seedCostPoolName).should('not.exist');
            cy.contains(seedCostPoolDescription).should("not.exist");
        });
        pageData().warehouses.warehousesTab.warehouseCostPoolsGridToolbarItems.showDeletedWarehouseCostPoolsCheckbox().click();
        ensureVisibleCostPoolsCount(1);
        ensureVisibleCostPool(seedCostPoolName, seedCostPoolDescription);

        // "Cleanup" (warehouses cannot be permanently deleted)
        deleteWarehouse(editedWarehouseName);
    };

    it("Should work correctly for Admin", () => {
        cy.loginAsAdmin();
        test();
    });
});