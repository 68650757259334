import React from "react";
import RentaTasksWizardPage from "../RentaTasksWizardPage";
import {CustomerApprovalType} from "@/models/Enums";
import {BaseInputWidget, DropdownOrderBy, DropdownWidget, SelectListItem, TextInputWidget, TitleWidget} from "@renta-apps/athenaeum-react-components";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import SignatureWidget from "@/components/SignatureWidget/SignatureWidget";
import WorkOrderModel from "@/models/server/WorkOrderModel";

import styles from "./Approve.module.scss"

interface ISignatureProps {
}

interface ISignatureState {
    approvalType: CustomerApprovalType
}

export default class Signature extends RentaTasksWizardPage<ISignatureProps, ISignatureState> {

    state: ISignatureState = {
        approvalType: this.wizard.approvalType
    };

    private readonly SignatureId = "Signature";

    private get signatureWidget(): SignatureWidget | null {
        return this.findWidget(this.SignatureId);
    }

    private get signatureNameClarification(): string | null {
        return this.wizard.signatureNameClarification?.trim() ?? null;
    }

    private async setApprovalTypeAsync(approvalType: CustomerApprovalType): Promise<void> {
        if (approvalType !== this.approvalType) {

            this.wizard.approvalType = approvalType;
            this.saveContext();

            await this.setState({approvalType});

            if ((this.requireSignature) && (this.signatureWidget)) {
                await this.signatureWidget!.showContentAsync();
            }

            await this.validateAsync();
        }
    }

    private onSignatureNameClarificationChangeAsync = async (_: BaseInputWidget, nameClarification: string): Promise<void> => {
        this.wizard.signatureNameClarification = nameClarification.trim();

        this.saveContext();

        // Needed to update "next" buttons status
        await this.reRenderAsync();
    }
    
    /** Returns approval types permitted for the work order. */
    private get customerApprovalTypeItems(): SelectListItem[] {
        const allItems: SelectListItem[] = EnumProvider.getCustomerApprovalTypeItems();

            // Signature or email is not allowed for fixed price and warranty work orders.
        if (!WorkOrderModel.allowCustomerApproval(this.wizard.workOrder!)) {
            return allItems.filter(item => {
                const itemType: CustomerApprovalType = Number.parseInt(item.value) as CustomerApprovalType;
                return (itemType !== CustomerApprovalType.Signature && itemType !== CustomerApprovalType.Email); 
            });
        }

        return allItems;
    }

    protected getNoToggle(): boolean {
        return true;
    }

    protected get canNext(): boolean {
        return (!this.requireSignature) || 
            ((!!this.signatureSrc) &&
            (!!this.signatureNameClarification));
    }

    public get approvalType(): CustomerApprovalType {
        return this.state.approvalType;
    }

    public get requireSignature(): boolean {
        return (this.approvalType === CustomerApprovalType.Signature);
    }

    public get signatureSrc(): string | null {
        return this.wizard.signatureSrc;
    }

    public async onSignatureSignAsync(signature: string | null): Promise<void> {
        this.wizard.signatureSrc = signature;

        this.saveContext();

        // Needed to update "next" buttons status
        await this.reRenderAsync();
    }

    public async nextAsync(): Promise<void> {
        await super.nextAsync();
    }

    public getManual(): string {
        return Localizer.signatureGetManual;
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <TitleWidget model={this.title} wide/>

                <DropdownWidget id="approvalType"
                                wide required autoCollapse
                                icon={{name: "fas tools"}}
                                orderBy={DropdownOrderBy.None}
                                label={Localizer.signatureApprovalType} description={Localizer.signatureApprovalTypeDescription}
                                items={this.customerApprovalTypeItems}
                                selectedItem={EnumProvider.getCustomerApprovalTypeItem(this.approvalType)}
                                onChange={async (_, item) => await this.setApprovalTypeAsync(parseInt(item!.value))}/>

                {
                    (this.requireSignature) &&
                    (
                        <>
                            <SignatureWidget wide preserveSignature
                                             id={this.SignatureId}
                                             label={Localizer.signatureSignature}
                                             description={Localizer.signatureSignatureDescription}
                                             initialSignatureData={this.signatureSrc}
                                             onSign={async (signature) => await this.onSignatureSignAsync(signature)}
                            />

                            <TextInputWidget wide
                                             id="nameClarification"
                                             className={styles.signatureNameClarification}
                                             label={Localizer.genericNameClarification}
                                             description={Localizer.signatureNameClarificationDescription}
                                             maxLength={RentaTaskConstants.dbKeyLength}
                                             value={this.signatureNameClarification ?? undefined}
                                             onChange={this.onSignatureNameClarificationChangeAsync}
                            />
                        </>
                    )
                }
            </React.Fragment>
        );
    }
}