import {CustomerApprovalType, WorkOrderFormStatusFilter} from "@/models/Enums";
import User from "@/models/server/User";

export default class ToolbarModel {

    public from: Date | null = null;

    public to: Date | null = null;

    public status: WorkOrderFormStatusFilter | null = null;
    
    public approvalTypes: CustomerApprovalType[] = [];

    public reporters: User[] = [];
}