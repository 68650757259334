import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import UserInvitation from "@/models/server/UserInvitation";
import {AuthType, CommunicationLanguage, FiltersBehavior} from "@/models/Enums";
import UserRole from "@/models/server/UserRole";
import {IUser} from "@renta-apps/athenaeum-react-common";
import Warehouse from "@/models/server/Warehouse";
import CostPool from "@/models/server/CostPool";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import UserNotificationSetting from "@/models/server/notification/UserNotificationSetting";
import clone from "@/functions/Clone";
import UserSso from "@/models/server/UserSso";

export default class User implements IUser {

    public id: string = "";

    public username: string = "";

    public email: string = "";

    public phone: string = "";

    public firstname: string = "";

    public lastName: string = "";

    public middleName: string = "";

    public language: string = "";

    public employeeNumber: string = "";

    public externalId: string = "";

    public homeLocation: GeoLocation | null = null;

    public role: UserRole = new UserRole();

    public roles: UserRole[] = [];

    public address: string = "";

    public city: string = "";

    public postalCode: string = "";

    public invitations: UserInvitation[] = [];

    public authType: AuthType = AuthType.Email;

    public filtersBehavior: FiltersBehavior = FiltersBehavior.UserSpecified;

    public warehouseId: string | null = null;

    public warehouse: Warehouse | null = null;

    public costPools: CostPool[] | null = null;

    public isLocked: boolean = false;

    public isDeleted: boolean = false;

    public hasPassword: boolean = false;

    public isAdmin: boolean = false;

    public isMounter: boolean = false;

    public isEmployee: boolean = false;

    public isManager: boolean = false;
    
    public isBusinessManager: boolean = false;

    public isSubcontractorManager: boolean = false;

    public isSubcontractorMounter: boolean = false;

    public agreementAccepted: boolean = false;

    public registrationAccepted: boolean = false;
    
    public useConstructionSiteCommunicationLanguage: boolean = false;

    public communicationLanguage: CommunicationLanguage | null = null;

    public notificationSetting: UserNotificationSetting[] = [];

    /**
     * Subcontractor mounter permission.
     */
    public canCreateWorkOrder: boolean = false;

    /**
     * Subcontractor mounter permission.
     */
    public canCompleteWorkOrder: boolean = false;

    /**
     * Subcontractor mounter permission.
     */
    public canProcessForm: boolean = false;
    
    public sso: UserSso | null = null;

    public isUser: true = true;

    public static isContactPersonValid(contactPerson: Readonly<User>): boolean {
        return (!!contactPerson.firstname)
            && (!!contactPerson.lastName)
            && (!!contactPerson.email)
            && (!!contactPerson.phone);
    }

    /**
     * Set empty ids as default Guids to prevent errors when the {@link User} is deserialized in FE controllers.
     */
    public static initializeEmptyGuids(user: User): void {
        if (!user.id) {
            user.id = RentaTaskConstants.defaultGuid;
        }

        if (user.role) {
            UserRole.initializeEmptyGuids(user.role);
        }
    }

    /**
     * Creates a copy of the given user, resets all nullable complex properties
     * and empties arrays and returns the copy.
     */
    public static RemoveComplexData(user: User): User {
        const copy: User = clone(user);

        // Reset all nullable complex types and arrays
        copy.homeLocation = null;
        copy.warehouse = null;
        copy.costPools = [];
        copy.invitations = [];
        copy.notificationSetting = [];
        copy.roles = [];

        return copy;
    }

    /**
     * Returns users who are assigned to any of the given cost pools.
     */
    public static getWithCostPools(users: User[], costPools: CostPool[]) {
        return users.filter(
            mounter => mounter.costPools?.some(
                mounterCostPool => costPools.some(
                    costPool => mounterCostPool.id === costPool.id)));
    }
}