import React from "react";
import {ButtonType, EmailInput, EmailValidator, ITitleModel} from "@renta-apps/athenaeum-react-components";
import RentaTasksFormWizardPage from "@/pages/RentaTasks/RentaTasksFormWizardPage";
import FormButton from "@/components/Form/FormButton/FormButton";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import FormContainer from "@/components/Form/FormContainer/FormContainer";
import FormPageRow from "@/components/Form/FormPageRow/FormPageRow";
import FormModel from "@/models/server/forms/FormModel";
import TransformProvider from "@/providers/TransformProvider";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "@/localization/Localizer";

import styles from "./FormReceivers.module.scss";
import {CustomerApprovalType} from "@/models/Enums";

export interface IFormReceiversProps {
}

interface IFormReceiversState {
    approverEmail: string;
    noContactPersonWarning: string | null;
}

export default class FormReceivers extends RentaTasksFormWizardPage<IFormReceiversProps, IFormReceiversState> {

    state: IFormReceiversState = {
        approverEmail: this.initApprover,
        noContactPersonWarning: null
    };

    private get initApprover(): string {
        return (this.contactPersonEmail)
            ? this.contactPersonEmail
            : "";
    }

    private get contactPersonEmail(): string | null {
        return (this.mounterContext.form && this.mounterContext.form.contactUser)
            ? this.mounterContext.form.contactUser.email
            : null;
    }

    private get approver(): string {
        return this.state.approverEmail;
    }

    private get noContactPersonWarning(): string | null {
        return this.state.noContactPersonWarning;
    }

    private async setApproverAsync(value: string): Promise<void> {
        await this.setState({approverEmail: value});
    }

    private getEmailValidationError(value: string): string | null {
        return EmailValidator.validator(value);
    }

    protected get canSend(): boolean {
        return (!!this.approver && !this.getEmailValidationError(this.approver));
    }

    public async sendAsync(): Promise<void> {
        if (this.canSend) {
            const form: FormModel = this.form!;

            form.approverEmail = this.approver;

            this.saveContext();

            //TODO: check why we cant use super.nextAsync() here.
            this.resetWorkOrderModifiedValue();

            await RentaTasksController.saveFormAsync(true);

            await PageRouteProvider.redirectAsync(PageDefinitions.rentaTasksWorkOrder(form.workOrderId), true);

            await this.alertMessageAsync(Localizer.formReceiversPageReportSentToCustomer, true, false);
        }
    }

    public get title(): ITitleModel | undefined {
        return TransformProvider.toTitle(this.form);
    }

    public async initializeAsync(): Promise<void> {
        if (!this.contactPersonEmail) {
            await this.setState({noContactPersonWarning: Localizer.formReceiversPageNoContactPersonWarning});
        }

        return super.initializeAsync();
    }

    public async nextAsync(): Promise<void> {
        //not action by swiping, "Send" button should be pressed
    }

    public hideNext(): boolean {
        return true;
    }

    public getManual(): string {
        return Localizer.formReceiversPageSendToReceivers;
    }

    public renderReceiverEmailInput(item: string): React.ReactNode {
        return (
            <div className={styles.receiverContainer}>

                <EmailInput id={`receiver_email_$`} required autoFocus
                            label={Localizer.formEmailInputReceiver}
                            value={item}
                            onChange={async (_, value) => await this.setApproverAsync(value)}
                />

            </div>
        )
    }

    public renderContent(): React.ReactNode {
        return (
            <FormContainer className={styles.formReceivers}>

                <FormPageRow className="text-center">
                    {
                        this.noContactPersonWarning && <span>{Localizer.get(this.noContactPersonWarning)}</span>
                    }
                </FormPageRow>

                {
                    (!this.form!.approved && this.form!.approvalType === CustomerApprovalType.Email) &&
                    (
                        <FormPageRow>

                            {this.renderReceiverEmailInput(this.approver)}

                        </FormPageRow>
                    )
                }

                <FormPageRow>

                    <FormButton block big
                                type={ButtonType.Orange}
                                label={Localizer.formSend}
                                onClick={async () => await this.sendAsync()}
                    />

                </FormPageRow>

            </FormContainer>
        );
    }
}