import React from "react"
import {ButtonType} from "@renta-apps/athenaeum-react-components";
import FormModel from "@/models/server/forms/FormModel";
import FormButton from "@/components/Form/FormButton/FormButton";
import FormSummaryTable from "@/components/Form/FormSummaryTable/FormSummaryTable";
import FormPageRow from "@/components/Form/FormPageRow/FormPageRow";
import RentaTasksFormWizardPage from "@/pages/RentaTasks/RentaTasksFormWizardPage";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "@/localization/Localizer";

interface IFormSummaryProps  {
}

interface IFormSummaryState {
}

export default class FormSummaryPage extends RentaTasksFormWizardPage<IFormSummaryProps, IFormSummaryState> {
    private async saveAsync(): Promise<void> {
        if (this.processed) {
            if (this.preview) {
                await super.redirectToSendStepAsync();
            } else {

                const needToSave: boolean = (!this.approvable);

                if (needToSave) {
                    await RentaTasksController.saveFormAsync(true);

                    await super.nextAsync();

                    await this.alertMessageAsync(Localizer.formSummaryPageFormSaved, true);
                } else {
                    await super.nextAsync();
                }
            }
        }
    }

    private get approvable(): boolean {
        return (this.form != null) && (this.form.mapping.approvable);
    }

    private get processed(): boolean {
        return FormModel.isProcessed(this.form!);
    }
    
    private get canBeCompleted(): boolean {
        return FormModel.canBeCompleted(this.form!);
    }

    protected get canNext(): boolean {
        return (this.processed);
    }

    public hideNext(): boolean {
        return (!this.approvable);
    }

    public getManual(): string {
        return Localizer.rentaManagementSummaryPageManual;
    }

    public async nextAsync(): Promise<void> {
        if (this.approvable && this.processed) {
            await super.nextAsync();
        }

        //not action by swiping if form is not approvable, "Complete" button should be pressed
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (this.preview) {
            await PageRouteProvider.redirectAsync(PageDefinitions.rentaTasksFormPreviewPageRoute);
        }
    }

    public renderContent(): React.ReactNode {
        return (
            <FormPageRow>
                <FormSummaryTable model={this.form!}
                                  openStep={async (item) => await this.openStepAsync(item)}
                                  openMountersSignaturesStep={async() => await RentaTasksFormWizardPage.openMountersSignaturesStepAsync()}
                />

                {
                    (!this.approvable) &&
                    (
                        <FormButton type={ButtonType.Orange} block big
                                    label={Localizer.rentaManagementSummaryPageButtonTitle}
                                    disabled={!this.canBeCompleted}
                                    id='completeForm'
                                    onClick={async () => await this.saveAsync()}
                        />
                    )
                }
            </FormPageRow>
        );
    }
}