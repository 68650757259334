import { TaskStatusFilter } from "@/models/Enums";
import CostPool from "@/models/server/CostPool";
import User from "@/models/server/User";

/**
 * {@link WorkOrdersToolbar} model.
 */
export default class WorkOrdersToolbarModel {

    public from: Date | null = null;

    public to: Date | null = null;

    public taskStatusesFilter: TaskStatusFilter[] = [];

    public notAssigned: boolean = false;

    public managerCostPoolsFilter: CostPool[] = [];

    /** @see GetWorkOrdersRequest.search */
    public search: string | null = null;

    public bulkPdfReceiver: string | null = null;
    
    /**
     * Manager users who are selected in the {@link WorkOrdersToolbar}.
     */
    public managers: User[] = [];

    /**
     * Mounter users who are selected in the {@link WorkOrdersToolbar}.
     */
    public mounters: User[] = [];
    
    
}