import {CustomFormType, FormHeaderType} from "@/models/Enums";
import FormMapping from "@/models/server/forms/FormMapping";
import FormItem from "@/models/server/forms/FormItem";

export default class FormContent {
    public name: string = "";
    
    public description: string | null = null;

    public customType: CustomFormType = CustomFormType.Undefined;

    public headers: FormHeaderType[] = [];

    public mapping: FormMapping = new FormMapping();

    public items: FormItem[] = [];

    public isFormContent: true = true;

    public static getItems(form: FormContent, group: boolean = false): FormItem[] | FormItem[][] {
        let items: FormItem[] | FormItem[][] = form.items;
        items.order(item => item.step);

        if (group) {
            let group: FormItem[] = [];
            let groupName: string = "";
            let result: (FormItem | FormItem[])[] = [];
            
            for (let i: number = 0; i < items.length; i++) {
                const item: FormItem = items[i];

                if (!!item.group) {
                    if (groupName != item.group) {
                        groupName = item.group;
                        group = [];
                        result.push(group);
                    }

                    group.push(item);
                } else {
                    result.push(item);
                }
            }

            return result as FormItem[] | FormItem[][];
        }
        
        return items;
    }
}