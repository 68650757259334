import {executeWithIntercept, pageData, testConstants} from "@/helpers/CypressHelper";

context("(Desktop) Work order type management", () => {
    beforeEach(() => {
        cy.init("Desktop");
    });

    const timestamp: string = new Date().toISOString();

    const {name: seedWorkOrderType1Name, enabled: seedWorkOrderType1Enabled} = testConstants.workOrderType1;
    const {name: seedWorkOrderType2Name, enabled: seedWorkOrderType2Enabled} = testConstants.workOrderType2;

    const newWorkOrderTypeName = `Cypress work order type ${timestamp}`;
    const editedWorkOrderTypeName = `Edited Cyprus work order typo ${timestamp}`;

    const ensureVisibleWorkOrderTypesCount = (expected: number) => {
        pageData().workOrderTypeManagement.workOrderTypesGrid().within(() => {
            cy.get( 'tbody tr.athenaeum-grid-gridRow').filter(':visible').should('have.length', expected);
        });
    };

    const ensureVisibleWorkOrderType = (name: string, enabled: boolean) => {
        pageData().workOrderTypeManagement.workOrderTypesGrid().within(() => {
            pageData().workOrderTypeManagement.workOrderTypesGridCell(name, "name")
                .should('contain.text', name)
                .should('be.visible');

            pageData().workOrderTypeManagement.workOrderTypesGridCell(name, "actions").within(() => {
                if (enabled) {
                    cy.get('[title=Disable]').should('be.visible');
                }
                else {
                    cy.get('[title=Enable]').should('be.visible');
                }
            });
        });
    };

    const test = () => {
        cy.navigateToAdminWorkOrderTypeManagementPage();

        // Ensure seed work order types exist
        ensureVisibleWorkOrderTypesCount(2);
        ensureVisibleWorkOrderType(seedWorkOrderType1Name, seedWorkOrderType1Enabled);
        ensureVisibleWorkOrderType(seedWorkOrderType2Name, seedWorkOrderType2Enabled);

        // Create new work order type, delete before saving
        pageData().workOrderTypeManagement.workOrderTypesGridToolbarItems.addWorkOrderTypeButton().click();
        cy.focused().type(newWorkOrderTypeName + "{enter}");
        ensureVisibleWorkOrderTypesCount(3);
        pageData().workOrderTypeManagement.workOrderTypesGrid().within(() => {
            cy.get('[title=Delete]').click();
        });
        cy.reload();
        ensureVisibleWorkOrderTypesCount(2);
        ensureVisibleWorkOrderType(seedWorkOrderType1Name, seedWorkOrderType1Enabled);
        ensureVisibleWorkOrderType(seedWorkOrderType2Name, seedWorkOrderType2Enabled);

        // Create new work order type, save
        pageData().workOrderTypeManagement.workOrderTypesGridToolbarItems.addWorkOrderTypeButton().click();
        cy.focused().type(newWorkOrderTypeName + "{enter}");
        ensureVisibleWorkOrderTypesCount(3);
        pageData().workOrderTypeManagement.workOrderTypesGrid().within(() => {
            executeWithIntercept(
                () => cy.get('[title=Save]').click(),
                [pageData().workOrderTypeManagement.routes.createWorkOrderType]
            );
        });
        cy.reload();
        ensureVisibleWorkOrderTypesCount(3);
        ensureVisibleWorkOrderType(seedWorkOrderType1Name, seedWorkOrderType1Enabled);
        ensureVisibleWorkOrderType(seedWorkOrderType2Name, seedWorkOrderType2Enabled);
        ensureVisibleWorkOrderType(newWorkOrderTypeName, true);

        // Edit existing work order type, cancel
        pageData().workOrderTypeManagement.workOrderTypesGrid().within(() => {
            pageData().workOrderTypeManagement.workOrderTypesGridCell(newWorkOrderTypeName, "name").trigger('click');
            cy.focused().clear();
            cy.focused().type(editedWorkOrderTypeName + "{enter}");
            cy.get('[title=Cancel]').click();
        });
        cy.reload();
        ensureVisibleWorkOrderTypesCount(3);
        ensureVisibleWorkOrderType(seedWorkOrderType1Name, seedWorkOrderType1Enabled);
        ensureVisibleWorkOrderType(seedWorkOrderType2Name, seedWorkOrderType2Enabled);
        ensureVisibleWorkOrderType(newWorkOrderTypeName, true);

        // Edit existing work order type, save
        pageData().workOrderTypeManagement.workOrderTypesGrid().within(() => {
            pageData().workOrderTypeManagement.workOrderTypesGridCell(newWorkOrderTypeName, "name").trigger('click');
            cy.focused().clear();
            cy.focused().type(editedWorkOrderTypeName + "{enter}");
            executeWithIntercept(
                () => cy.get('[title=Save]').click(),
                [pageData().workOrderTypeManagement.routes.saveWorkOrderType]
            );
        });
        cy.reload();
        ensureVisibleWorkOrderTypesCount(3);
        ensureVisibleWorkOrderType(seedWorkOrderType1Name, seedWorkOrderType1Enabled);
        ensureVisibleWorkOrderType(seedWorkOrderType2Name, seedWorkOrderType2Enabled);
        ensureVisibleWorkOrderType(editedWorkOrderTypeName, true);

        // Disable work order type
        pageData().workOrderTypeManagement.workOrderTypesGrid().within(() => {
            pageData().workOrderTypeManagement.workOrderTypesGridCell(editedWorkOrderTypeName, "actions").within(() => {
                executeWithIntercept(
                    () => cy.get('[title=Disable]').click(),
                    [pageData().workOrderTypeManagement.routes.saveWorkOrderType]
                );
            });
        });
        cy.reload();
        ensureVisibleWorkOrderTypesCount(3);
        ensureVisibleWorkOrderType(seedWorkOrderType1Name, seedWorkOrderType1Enabled);
        ensureVisibleWorkOrderType(seedWorkOrderType2Name, seedWorkOrderType2Enabled);
        ensureVisibleWorkOrderType(editedWorkOrderTypeName, false);
        cy.navigateToWorkOrdersPage();
        pageData().workOrders.toolbar.addNewWorkOrderButton().click();
        pageData().workOrder.workOrderTypeDropdown().dropdownShouldNotContainValue(editedWorkOrderTypeName);
        cy.navigateToAdminWorkOrderTypeManagementPage();

        // Enable work order type
        pageData().workOrderTypeManagement.workOrderTypesGrid().within(() => {
            pageData().workOrderTypeManagement.workOrderTypesGridCell(editedWorkOrderTypeName, "actions").within(() => {
                executeWithIntercept(
                    () => cy.get('[title=Enable]').click(),
                    [pageData().workOrderTypeManagement.routes.saveWorkOrderType]
                );
            });
        });
        cy.reload();
        ensureVisibleWorkOrderTypesCount(3);
        ensureVisibleWorkOrderType(seedWorkOrderType1Name, seedWorkOrderType1Enabled);
        ensureVisibleWorkOrderType(seedWorkOrderType2Name, seedWorkOrderType2Enabled);
        ensureVisibleWorkOrderType(editedWorkOrderTypeName, true);
        cy.navigateToWorkOrdersPage();
        pageData().workOrders.toolbar.addNewWorkOrderButton().click();
        pageData().workOrder.workOrderTypeDropdown().dropdownShouldContainValue(editedWorkOrderTypeName);

        // Receive alert when trying to save a type with already existing name
        cy.navigateToAdminWorkOrderTypeManagementPage();
        cy.contains(seedWorkOrderType1Name).should('be.visible');
        pageData().workOrderTypeManagement.workOrderTypesGridToolbarItems.addWorkOrderTypeButton().click();
        cy.focused().type(seedWorkOrderType1Name + "{enter}");
        ensureVisibleWorkOrderTypesCount(4);
        
        pageData().workOrderTypeManagement.workOrderTypesGrid().within(() => {
            cy.get('[title=Save]').click();
        });
        const expectedAlert = `Work order type ${seedWorkOrderType1Name} already exists`
        
        cy.get('.athenaeum-alert-message')
            .should('contain.text', expectedAlert)
            .should('be.visible');
        
        cy.reload();
        ensureVisibleWorkOrderTypesCount(3);
        ensureVisibleWorkOrderType(seedWorkOrderType1Name, seedWorkOrderType1Enabled);
        ensureVisibleWorkOrderType(seedWorkOrderType2Name, seedWorkOrderType2Enabled);
        ensureVisibleWorkOrderType(editedWorkOrderTypeName, true);
    };

    it("Should work correctly for Admin", () => {
        cy.loginAsAdmin();
        test();
    });
});