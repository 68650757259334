import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import NotificationSettings from "@/components/NotificationSettings/NotificationSettings";
import Localizer from "@/localization/Localizer";

import styles from "./GlobalNotificationSettings.module.scss";

interface IGlobalNotificationSettingsProps {
}

interface IGlobalNotificationSettingsState {
}

export default class GlobalNotificationSettings extends AuthorizedPage<IGlobalNotificationSettingsProps, IGlobalNotificationSettingsState> {

    state: IGlobalNotificationSettingsState = {};

    public render(): React.ReactNode {
        return (
            <PageContainer className={this.css(styles.globalNotificationSettings)}>

                <PageHeader title={Localizer.globalNotificationSettingsPageTitle} />
                <PageRow>
                    
                    <NotificationSettings />

                </PageRow>

            </PageContainer>
        );
    }
}