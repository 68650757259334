import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import Organization from "./Organization";

export default class OrganizationContract {

    /**
     * Id of the Contract in Renta Task.
     *
     * @see externalId
     * @see customerNumber
     */
    public id: string = "";

    /**
     * Name of the Contract in Renta Task.
     *
     * @see rentaName
     */
    public name: string | null = null;

    /**
     * Name of the Contract in ERP.
     *
     * @see name
     */
    public rentaName: string | null = null;

    /**
     * Id of the Contract in ERP.
     *
     * @see id
     * @see customerNumber
     */
    public externalId: string | null = null;

    /**
     * Customer number of the Contract in ERP.
     *
     * @see id
     * @see externalId
     */
    public customerNumber: string | null = null;

    /**
     * Address of the Contract in ERP.
     *
     * @see location
     * @see virtualAddress
     */
    public externalAddress: string | null = null;

    /**
     * OVT
     */
    public transactionIdentifier: string | null = null;

    /**
     * Välittäjätunnus
     */
    public intermediator: string | null = null;

    /**
     * Id of {@link organization}.
     */
    public organizationId: string = "";

    /**
     * The Organization where the Contract belongs to.
     *
     * @see organizationId
     */
    public organization: Organization | null = null;

    /**
     * Location of the Contract.
     *
     * @see externalAddress
     * @see virtualAddress
     */
    public location: GeoLocation | null = null;

    /**
     * Virtual billing address, without existing location, comma separated: "{PostalBox}, {PostalCode}, {Address}".
     *
     * @see location
     * @see externalAddress
     */
    public virtualAddress: string | null = null;

    public readonly isOrganizationContract: true = true;
}