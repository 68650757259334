import {NotificationType} from "@/models/Enums";

context("Desktop. User notification settings", () => {
        beforeEach(() => {
            cy.init("Desktop");
        });

        it("Should work correctly for Manager", () => {
            cy.loginAsManager();
            test();
        });

    function test(): void {
        describe('Update "Work orders processed approval" notification settings', () => {
            cy.modifyNotificationSettings(NotificationType.WorkOrdersProcessedApproval, false);
        });

        describe('Update "Work orders pending approval" notification settings', () => {
            cy.modifyNotificationSettings(NotificationType.WorkOrdersPendingApproval, false);
        });

        describe('Update "Work order reactivation" notification settings', () => {
            cy.modifyNotificationSettings(NotificationType.WorkOrderReactivation, false);
        });

        describe('Update "Work order assignment" notification settings', () => {
            cy.modifyNotificationSettings(NotificationType.WorkOrderAssignment, false);
        });
    }
});