import React from "react";
import {FormSummarySignatureTableRow} from "@/components/Form/FormSummaryTable/FormSummarySignatureTableRow/FormSummarySignatureTableRow";

export interface IFormSummaryTableGroupProps {
    signatures: readonly Readonly<FormSummarySignature>[] | null;
    onSignatureGroupClick?: () => Promise<void> | null;
}

type FormSummarySignature = {
    nameClarification: string | null;
    signatureSrc: string | null;
};

export default function FormSummarySignaturesGroup(props: Readonly<IFormSummaryTableGroupProps>): JSX.Element {
    const {signatures, onSignatureGroupClick} = props;

    const signatureContainer: JSX.Element = (
        <div>
            {
                signatures !== null && signatures.length > 0 && (
                    signatures.map((signature) => (
                        <FormSummarySignatureTableRow
                            nameClarification={signature.nameClarification}
                            signatureSrc={signature.signatureSrc}
                        />
                    ))
                )
            }
        </div>
    );

    return (
            <div onClick={onSignatureGroupClick}>
                {signatureContainer}
            </div>
        );
}