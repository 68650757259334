import {SortDirection} from "@renta-apps/athenaeum-toolkit";

export default class ListSubcontractorsRequest {
    public constructionSiteId: string | null = null;

    public warehouseId: string | null = null;

    public showDeleted: boolean = false;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public pageNumber: number = 1;

    public pageSize: number = 100;
}