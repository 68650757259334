import {SortDirection} from "@renta-apps/athenaeum-toolkit";
import {ConstructionSiteStatus} from "../Enums";

export default class ConstructionSitesFilters {

    public searchTerm: string | null = null;

    public status: ConstructionSiteStatus | null = null;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;
}