import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import {ConstructionSiteOrWarehouseType} from "../Enums";
import {DefaultPrices} from "@/models/server/DefaultPrices";
import OrganizationContract from "@/models/server/OrganizationContract";
import WarehouseCostPool from "@/models/server/WarehouseCostPool";

export default class ConstructionSiteOrWarehouse {
    public id: string = "";

    public name: string = "";

    public type: ConstructionSiteOrWarehouseType = ConstructionSiteOrWarehouseType.ConstructionSite;

    public externalId: string = "";

    public invoiceReference: string | null = null;

    public readonly projectNumber: string | null = null;

    public location: GeoLocation | null = null;

    public hoursPrice: number | null = null;

    public mileagePrice: number | null = null;

    /** @see ConstructionSite.alarmJobPrice */
    public alarmJobPrice: number | null = null;

    public costPools: WarehouseCostPool[] = [];

    public organizationContract: OrganizationContract | null = null;

    public contractName: string | null = null;

    public contractExternalId: string | null = null;

    public qrCode: string | null = null;

    public timeTrackingDeviceId: string | null = null;

    // Ids of the construction site or warehouse subcontractors organization contracts.
    public subcontractorsOrganizationContractIds: string[] = [];

    public favorite: boolean = false;

    public isConstructionSiteOrWarehouse: boolean = true;

    public static getHoursPrice(owner: ConstructionSiteOrWarehouse | null, defaultPrices: DefaultPrices | null = null): number {
        return owner?.hoursPrice ?? defaultPrices?.defaultHoursPrice ?? 0;
    }

    public static getDistancesPrice(owner: ConstructionSiteOrWarehouse | null, defaultPrices: DefaultPrices | null = null): number {
        return owner?.mileagePrice ?? defaultPrices?.defaultMileagePrice ?? 0;
    }

    public static getAlarmJobPrice(owner: ConstructionSiteOrWarehouse | null, defaultPrices: DefaultPrices | null = null): number {
        return owner?.alarmJobPrice ?? defaultPrices?.defaultAlarmJobPrice ?? 0;
    }
}