import React from "react";
import {Button, ButtonType, Checkbox, Dropdown, DropdownAlign, DropdownOrderBy, IconSize, Inline, SelectListItem, Spinner, ToolbarContainer} from "@renta-apps/athenaeum-react-components";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import AddWorkReportAttachmentModal from "@/pages/WorkOrders/WorkOrderDetailsPanel/AddWorkReportAttachmentModal/AddWorkReportAttachmentModal";
import ToolbarModel from "@/pages/WorkOrders/WorkOrderDetailsPanel/Toolbar/ToolbarModel";
import AddWorkOrderAttachmentsRequest from "@/models/server/requests/AddWorkOrderAttachmentsRequest";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./Toolbar.module.scss";

interface IToolbarProps  {
    model: ToolbarModel;
    workOrderId: string;
    addWorkReportAttachments(sender: IBaseComponent, request: AddWorkOrderAttachmentsRequest): Promise<void>;
    reloadWorkReportAttachments(sender: IBaseComponent): Promise<void>;
    onChange?(model: ToolbarModel): Promise<void>;
}

interface IToolbarState {
    model: ToolbarModel;
}

export default class WorkOrderDetailsPanelToolbar extends BaseComponent<IToolbarProps, IToolbarState> {

    // Fields

    private readonly addAttachmentModalId: string = `addWorkReportAttachmentModal_${this.props.workOrderId}`;

    public state: IToolbarState = {
        model: this.props.model
    };


    // Methods

    private async processOnChange(invoke: boolean = false): Promise<void> {
        await this.setState({ model: this.state.model });
        if (invoke) {
            await this.props.onChange?.(this.state.model);
        }
    }

    private async selectAttachmentTypesAsync(items: SelectListItem[], invoke: boolean): Promise<void> {

        this.state.model.types = items.map(item => parseInt(item.value));

        if (invoke) {
            await this.processOnChange(true);
        }
    }

    private async setAttachmentPublicAsync(value: boolean): Promise<void> {
        this.state.model.public = value;
        await this.processOnChange(true);
    }

    public hasSpinner(): boolean {
        return true;
    }

    public isAsync(): boolean {
        return true;
    }

    protected getEndpoint(): string {
        return ""
    }

    public render(): React.ReactNode {

        return (
            <ToolbarContainer className={styles.toolbarContainer}>
                {
                    (this.isSpinning()) && <Spinner />
                }

                <Inline>
                    <Checkbox inline
                              id="public"
                              label={Localizer.workOrderDetailsPanelToolbarPublicCheckbox}
                              value={this.state.model.public}
                              onChange={async(_,value) => await this.setAttachmentPublicAsync(value)}
                    />

                    <Dropdown multiple groupSelected
                              name={"types"}
                              minWidth="180px"
                              align={DropdownAlign.Left}
                              orderBy={DropdownOrderBy.None}
                              items={EnumProvider.getAttachmentTypeItems()}
                              selectedItems={this.state.model.types}
                              onChange={async(sender) => await this.selectAttachmentTypesAsync(sender.selectedItems, true)}
                    />

                    <Button toggleModal
                            label={Localizer.workOrderDetailsPanelToolbarAddAttachmentsButton}
                            className="ml-1"
                            icon={{name: "plus", size: IconSize.Normal}}
                            type={ButtonType.Orange}
                            dataTarget={this.addAttachmentModalId}
                    />

                    <Button title={Localizer.genericActionReload}
                            className="ml-1"
                            icon={{name: "far history", size: IconSize.Large }}
                            type={ButtonType.Info}
                            onClick={async (sender) => await this.props.reloadWorkReportAttachments(sender)}
                    />
                </Inline>

                <AddWorkReportAttachmentModal id={this.addAttachmentModalId}
                                              workOrderId={this.props.workOrderId}
                                              addWorkReportAttachments={this.props.addWorkReportAttachments}
                />

            </ToolbarContainer>
        );
    }
};