import {Utility} from "@renta-apps/athenaeum-toolkit";
import WorkOrderCheckIn from "./WorkOrderCheckIn";
import WorkOrderModel from "../../../models/server/WorkOrderModel";
import RentaTaskConstants from "../../../helpers/RentaTaskConstants";
import WizardContext from "@/pages/RentaTasks/Models/WizardContext";
import {WorkOrderStatus} from "@/models/Enums";
import FormModel from "@/models/server/forms/FormModel";
import throwIfFalsy from "@/functions/ThrowIfFalsy";

export default class MounterContext {
    public signInAt: Date | null = null;

    /**
     * The Work Order to which the mounter is currently checked in to.
     */
    public workOrder: WorkOrderModel | null = null;

    public checkIns: WorkOrderCheckIn[] = [];

    public form: FormModel | null = null;

    public formPreview: boolean = false;

    public wizard: WizardContext = new WizardContext();

    public get isExpired(): boolean {
        return (this.signInAt != null) &&
            (
                Utility.diff(Utility.now(), this.signInAt).totalHours > RentaTaskConstants.signOutExpirationTimeOut
            );
    }

    /**
     * @see isCheckedIn
     */
    public get isSignedIn(): boolean {
        return (this.signInAt != null);
    }

    /**
     * @see isSignedIn
     */
    public get isCheckedIn(): boolean {
        return (this.isSignedIn) && (!!this.workOrder);
    }

    /**
     * @returns Id of the Work Order to which the Mounter is currently checked in to.
     */
    public get workOrderId(): string | null {
        return (this.isCheckedIn)
            ? this.workOrder!.id
            : null;
    }

    /**
     * Check in to a Work Order.
     *
     * @param workOrder The Work Order to check in to.
     * @see checkOut
     */
    public checkIn(workOrder: WorkOrderModel): void {
        throwIfFalsy(workOrder, nameof(workOrder));

        if (!this.isSignedIn) {
            this.signInAt = Utility.now();
        }

        if (workOrder.currentStatus == WorkOrderStatus.Created) {
            workOrder.currentStatus = WorkOrderStatus.InProgress;
            workOrder.activationDate = Utility.today();
        }

        const checkIn: WorkOrderCheckIn = new WorkOrderCheckIn();

        checkIn.workOrder = workOrder;
        checkIn.checkInAt = Utility.now();

        this.checkIns.push(checkIn);

        this.workOrder = workOrder;
    }

    /**
     * Check out of the current {@link workOrder}.
     *
     * @see checkIn
     */
    public checkOut(): void {
        this.workOrder = null;
    }

    public signOut(): void {
        this.signInAt = null;
        this.checkIns = [];
    }

    /**
     * Restore a JSON-serialized {@link MounterContext}.
     *
     * @param json The JSON-serialized {@link MounterContext} to restore.
     * @returns A new context if {@link json} is null or empty; otherwise a {@link MounterContext} deserialized from {@link json}.
     */
    public static restore(json: string | null): MounterContext {
        if (!json) {
            return new MounterContext();
        }

        const from: MounterContext = JSON.parse(json);
        const to: MounterContext = new MounterContext();

        Utility.restoreDate(from);
        Utility.copyTo(from, to);

        return to;
    }
}