import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Icon, IconSize} from "@renta-apps/athenaeum-react-components";

import styles from "./BreadCrumb.module.scss";

const icons: any = {
    arrow: require("./icons/bcarrow.png"),
    arrowDarkEnd: require("./icons/bcarrow-darkend.png")
};

export interface IBreadCrumbItem {
    readonly id: string;

    readonly name: string;
}

export interface IBreadCrumbProps<TBreadCrumbItem extends IBreadCrumbItem> {
    className?: string;
    items: TBreadCrumbItem[];

    onClick?(sender: BreadCrumb<TBreadCrumbItem>, item: TBreadCrumbItem, index: number): Promise<void>;
    onHome?(sender: BreadCrumb<TBreadCrumbItem>): Promise<void>;
}

export default class BreadCrumb<TBreadCrumbItem extends IBreadCrumbItem> extends BaseComponent<IBreadCrumbProps<TBreadCrumbItem>> {

    private async onHomeAsync() : Promise<void> {
        if (this.props.onHome) {
            await this.props.onHome(this);
        }
    }

    private async onClickAsync(item: TBreadCrumbItem, index: number) : Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(this, item, index);
        }
    }

    private renderItem(item: TBreadCrumbItem | null = null, index: number = -1): React.ReactNode {
        const length: number = this.props.items.length;
        const prevLast: boolean = (index == (length - 2));
        const last: boolean = (index == (length - 1));
        const icon: any = (last) ? icons.arrowDarkEnd : icons.arrow;

        return (
            <div key={index}
                 className={this.css(styles.item, prevLast && styles.prevLast, last && styles.last)}
                 style={{zIndex: 99 - index}}>

                <h1>
                    {
                        (item)
                            ?
                            (
                                <a onClick={async () => await this.onClickAsync(item, index)}>
                                    <span>{item.name}</span>
                                </a>
                            )
                            :
                            (
                                <a onClick={() => this.onHomeAsync()}>
                                    <Icon id={"breadCrumbsHome"} name="home" size={IconSize.X2} />
                                </a>
                            )
                    }

                </h1>

                <img src={icon} alt=">" />

            </div>
        )
    }

    public render(): React.ReactNode {
        return (
            <div key={"BreadCrumbs"} className={this.css(styles.breadCrumb, this.props.className)}>
                <div>

                    {this.renderItem()}

                    {
                        this.props.items.map((item, index) => this.renderItem(item, index))
                    }

                </div>
            </div>
        );
    }
}