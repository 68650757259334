import React from "react";
import SignatureCanvas from "react-signature-canvas";
import SignatureWidgetLocalizer from "./SignatureWidgetLocalizer";

import styles from "./SignatureWidget.module.scss";
import {BaseExpandableWidget, Button, ButtonType, IBaseExpandableWidgetProps, IBaseWidgetState} from "@renta-apps/athenaeum-react-components";

export interface ISignatureWidgetProps extends IBaseExpandableWidgetProps {
    
    /**
     * Optional callback for 'Done' action
     */
    onSign?(signature: string | null): Promise<void>;

    /**
     * Optional signature DataURL with base64 content to pre-populate the canvas
     */
    initialSignatureData?: string | null;

    /**
     * Signature is preserved when hiding the widget
     */
    preserveSignature?: boolean | false;
}

interface ISignatureWidgetState extends IBaseWidgetState<never>{
    signatureDataBucket?: string | null;
}

export default class SignatureWidget extends BaseExpandableWidget<ISignatureWidgetProps>{

    private _canvasRef: React.RefObject<SignatureCanvas> = React.createRef();
    private _emptyCanvasValue: string | null =  null;

    state: ISignatureWidgetState = {
        ...this.state,
        signatureDataBucket: this.props.initialSignatureData || null
    };

    private initializeCanvas(): void {
        const signatureCanvas: SignatureCanvas | null = this._canvasRef.current;
        
        if (signatureCanvas) {
            const canvas: HTMLCanvasElement = signatureCanvas.getCanvas();
            const ctx: CanvasRenderingContext2D = canvas.getContext("2d")!;

            const width: number = canvas.scrollWidth;
            const height: number = canvas.scrollHeight;
            const bottom: number = height - 0.20 * height;

            ctx.beginPath();

            ctx.moveTo(10, bottom);
            ctx.lineTo(width - 11, bottom);
            ctx.strokeStyle = "white";
            ctx.stroke();

            this._emptyCanvasValue = this._emptyCanvasValue ?? signatureCanvas.toDataURL();

            if (this.state.signatureDataBucket) {
                signatureCanvas.fromDataURL(this.state.signatureDataBucket);
            }
        }
    }

    private async clearCanvas(): Promise<void> {
        if (this._canvasRef.current) {
            this._canvasRef.current.clear();
            await this.setSignatureDataBucket(null);
            this.initializeCanvas();
        }
    }

    private async saveSignature(): Promise<void> {
        if (this._canvasRef.current) {
            const canvasData = this.canvasData;
            if (this.props.preserveSignature){
                await this.setSignatureDataBucket(canvasData);
            }
            if (this.props.onSign) {
                await this.props.onSign(canvasData);
            }
        }

        await super.hideContentAsync();
    }

    protected async onClickAsync(e: React.MouseEvent): Promise<void> {
        const target: Element = e.target as Element;

        if (target.parentElement !== null) {
            if((target.parentElement.className === styles.signature &&
                target.className !== styles.icon) ||
                target.parentElement.className === styles.buttonContainer) {
                return;
            }
        }

        await super.toggleContentAsync();

        if (this.contentVisible) {
            this.initializeCanvas();
        }
    }

    protected async onMouseDownAsync(e: React.MouseEvent): Promise<void> {
        e.preventDefault();
    }

    private preventSwipe(e: React.TouchEvent): void {
        e.stopPropagation();
    }
    
    private async setSignatureDataBucket(value: string | null): Promise<void> {
        await this.setState({signatureDataBucket: value} as ISignatureWidgetState);
    }

    public get canvasData(): string | null {
        const canvas = this._canvasRef.current || null;
        if (canvas) {
            const canvasData : string = canvas.toDataURL();
            if (canvasData !== this._emptyCanvasValue) {
                return canvasData
            }
        }

        return null;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.setState({icon: { name: "far file-contract" }});
    }
    
    public static getDerivedStateFromProps(nextProps: ISignatureWidgetProps, prevState: ISignatureWidgetState){
        if (prevState.icon !== nextProps.icon) {
            return {
                ...prevState,
                icon: { name: "far file-contract" },
            };
        }
        return null;
    }

    protected renderExpanded(): React.ReactNode {
        return (
            <div className={styles.signature} onTouchStart={(e: React.TouchEvent) => this.preventSwipe(e)}>
                <SignatureCanvas penColor='#007bff'
                                 canvasProps={{className: styles.signaturePad}}
                                 ref={this._canvasRef}
                />

                <div className={styles.buttonContainer}>
                    <Button type={ButtonType.Orange} onClick={() => this.clearCanvas()} label={SignatureWidgetLocalizer.clear} />
                    <Button type={ButtonType.Blue} onClick={() => this.saveSignature()} label={SignatureWidgetLocalizer.done} />
                </div>
            </div>
        );
    }
}