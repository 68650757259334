import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, DateInput, Dropdown, DropdownAlign, DropdownOrderBy, Form, Modal, NumberInput, OneColumn, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import WorkOrderExtraCharge from "@/models/server/WorkOrderExtraCharge";
import ExtraChargeType from "@/models/server/ExtraChargeType";
import EnumProvider from "@/providers/EnumProvider";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "@/localization/Localizer";

import styles from "./AddExtraChargeModal.module.scss";

interface IAddExtraChargeModalProps {
    addExtraCharge(sender: AddExtraChargeModal, extraCharge: WorkOrderExtraCharge): Promise<void>;
}

interface IAddExtraChargeModalState {
    extraCharge: WorkOrderExtraCharge;
    ExtraChargeTypes: ExtraChargeType[];
}

export default class AddExtraChargeModal extends BaseComponent<IAddExtraChargeModalProps, IAddExtraChargeModalState> {

    state: IAddExtraChargeModalState = {
        extraCharge: new WorkOrderExtraCharge(),
        ExtraChargeTypes: []
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    private get extraCharge(): WorkOrderExtraCharge {
        return this.state.extraCharge;
    }

    private get extraChargeTypes(): ExtraChargeType[] {
        return this.state.ExtraChargeTypes;
    }

    private async onOpenAsync(): Promise<void> {
        const extraChargeTypes: ExtraChargeType[] = await this.postAsync("api/ExtraChargeType/getExtraChargeTypes", false);
        const extraCharge = new WorkOrderExtraCharge();

        this.state.extraCharge = extraCharge;
        this.state.ExtraChargeTypes = extraChargeTypes;

        if (extraChargeTypes.length > 0) {
            extraCharge.extraChargeType = this.extraChargeTypes[0];
            extraCharge.extraChargeTypeId = this.extraChargeTypes[0].id;
            extraCharge.price = this.extraChargeTypes[0].price;
        }

        await this.setState({ extraCharge, ExtraChargeTypes: extraChargeTypes });
    }

    private async onSubmitAsync(): Promise<void> {
        await this.props.addExtraCharge(this, this.extraCharge);

        await this.modal.closeAsync();
    }

    private async setTypeAsync(item: ExtraChargeType): Promise<void> {
        this.extraCharge.extraChargeType = item;
        this.extraCharge.extraChargeTypeId = item.id;
        this.extraCharge.price = item.price;

        await this.reRenderAsync();
    }

    private async setAmountAsync(value: number): Promise<void> {
        this.extraCharge.amount = value;
        await this.reRenderAsync();
    }

    private async setPriceAsync(value: number): Promise<void> {
        this.extraCharge.price = value;
        await this.reRenderAsync();
    }

    private async closeAsync(): Promise<void> {
        if (this.modal) {
            await this.modal.closeAsync();
        }
        await this.reRenderAsync();
    }

    public async openAsync(): Promise<void> {
        if (this.modal) {
            await this.modal.openAsync();
        }
    }

    public render(): React.ReactNode {
        return (
            <Modal ref={this._modalRef}
                   id="addExtraChargeModal"
                   className={styles.addExtraChargeModal}
                   title={Localizer.addExtraChargeModalTitle}
                   subtitle={Localizer.addExtraChargeModalSubtitle}
                   onOpen={() => this.onOpenAsync()}
            >

                <div className="row">

                    <div className="col">

                        <Form ref={this._formRef}
                              onSubmit={() => this.onSubmitAsync()}
                        >

                            <TwoColumns>

                                <Dropdown required autoGroupSelected
                                          id="extraChargeType"
                                          label={Localizer.addExtraChargeModalExtraChargeTypeDropdownLabel}
                                          minWidth="105px"
                                          align={DropdownAlign.Right}
                                          orderBy={DropdownOrderBy.None}
                                          items={this.extraChargeTypes}
                                          selectedItem={this.extraCharge.extraChargeType}
                                          onChange={(_, item) => this.setTypeAsync(item!)}
                                />

                                <TextInput readonly
                                           id="extraChargeTypeUnit"
                                           label={Localizer.addExtraChargeModalExtraChargeTypeUnitTextInputLabel}
                                           value={(this.extraCharge.extraChargeType) ? EnumProvider.getExtraChargeTypeUnitText(this.extraCharge.extraChargeType?.unit) : null}
                                />

                            </TwoColumns>

                            <TwoColumns>

                                <DateInput id="extraChargeDate"
                                           label={Localizer.genericDate}
                                           value={this.extraCharge.extraChargeDate}
                                           onChange={async (value) => {this.extraCharge.extraChargeDate = value}}
                                />

                                <NumberInput required
                                             id="extraChargeAmount"
                                             label={Localizer.addExtraChargeModalExtraChargeAmountNumberInputLabel}
                                             format="0.0"
                                             value={this.extraCharge.amount}
                                             min={0.01}
                                             onChange={(_, value) => this.setAmountAsync(value)}
                                />

                            </TwoColumns>

                            {
                                (RentaTasksController.isBusinessManagerOrHigher) &&
                                (
                                    <OneColumn>

                                        <NumberInput id="extraChargePrice"
                                                     label={Localizer.genericPrice}
                                                     step={0.01}
                                                     format="0.0"
                                                     value={this.extraCharge.price}
                                                     onChange={(_, value) => this.setPriceAsync(value)}
                                        />

                                    </OneColumn>
                                )
                            }

                            <div>

                                <Button id={"cancel"}
                                        className={styles.buttons}
                                        type={ButtonType.Light}
                                        label={"Cancel"}
                                        onClick={async () => await this.closeAsync()}
                                />

                                <Button submit
                                        id={"save"}
                                        className={this.css(styles.buttons, "float-right")}
                                        type={ButtonType.Orange}
                                        label={Localizer.genericOk}
                                />

                            </div>

                        </Form>

                    </div>

                </div>

            </Modal>
        )
    }
}