import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Checkbox, InlineType, Modal, ModalSize, TextAreaInput} from "@renta-apps/athenaeum-react-components";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";

import styles from "./WorkOrderChangeStatusModal.module.scss";

export enum ChangeStatusAction {
    Reactivate = 0,
    Decline = 1,
}

interface IWorkOrderChangeStatusModalProps {
    action: ChangeStatusAction;
    forceComment?: boolean;
    notificationTrigger?: boolean;
    onConfirmAsync(workOrderId: string, sendNotification: boolean, comment: string | null): Promise<void>;
}

interface IWorkOrderChangeStatusModalState {
    workOrder: WorkOrderModel;
    sendNotification: boolean;
    showComment: boolean;
    comment: string | null;
}

export default class WorkOrderChangeStatusModal extends BaseComponent<IWorkOrderChangeStatusModalProps, IWorkOrderChangeStatusModalState> {

    state: IWorkOrderChangeStatusModalState = {
        workOrder: new WorkOrderModel(),
        sendNotification: false,
        showComment: false,
        comment: null,
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    private get forceComment(): boolean {
        return this.props.forceComment || false;
    }

    private get notificationTrigger(): boolean {
        return this.props.notificationTrigger || false;
    }

    private get workOrder(): WorkOrderModel {
        return this.state.workOrder;
    }

    private get sendNotification(): boolean {
        return this.state.sendNotification;
    }

    private get showComment(): boolean {
        return this.state.showComment && this.forceComment;
    }

    private get comment(): string | null {
        return this.state.comment;
    }

    private get title(): string {
        switch (this.props.action) {
            case ChangeStatusAction.Reactivate:
                return ((this.workOrder) && WorkOrderModel.isApproved(this.workOrder))
                    ? Localizer.workOrderActionsConfirmCompletedActivation
                    : Localizer.workOrderActionsConfirmActivation.format(this.workOrder);

            case ChangeStatusAction.Decline:
                return Utility.format(Localizer.myWorkReportsConfirmationDecline, this.workOrder.code);

            default:
                throw new TypeError(`Invalid action type ${this.props.action}`);
        }
    }

    private get notificationTriggerTitle(): string {
        switch (this.props.action) {
            case ChangeStatusAction.Reactivate:
                return Localizer.workOrderChangeStatusModalTitleReactivate;

            case ChangeStatusAction.Decline:
                return Localizer.workOrderChangeStatusModalTitleDecline;

            default:
                throw new TypeError(`Invalid action type ${this.props.action}`);
        }
    }

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    private async clearStateAsync(): Promise<void> {
        this.state.showComment = this.showComment;
        this.state.sendNotification = this.notificationTrigger;
        this.state.comment = null;

        await this.setState(this.state);
    }

    public async onChangeSendNotificationAsync(value: boolean): Promise<void> {
        if (!value) {
            this.state.comment = null;
        }

        await this.setState({sendNotification: value, showComment: value});
    }

    public async onChangeCommentAsync(comment: string): Promise<void> {
        await this.setState({comment});
    }

    public async openAsync(workOrder: WorkOrderModel): Promise<void> {
        await this.setState({
            workOrder,
            sendNotification: this.notificationTrigger,
            showComment: this.forceComment
        });

        await this.modal.openAsync();
    }

    public async onCancelAsync(): Promise<void> {
        await this.clearStateAsync();

        if (this.modal) {
            await this.modal.closeAsync();
        }
    }

    public async onConfirmAsync(): Promise<void> {
        await this.props.onConfirmAsync(this.workOrder.id, this.sendNotification, this.comment);

        if (this.modal) {
            await this.modal.closeAsync();
        }
    }

    public static get workOrderChangeStatusModalId(): string {
        return "workOrderChangeStatusModal";
    }

    public render(): React.ReactNode {
        return (
            <Modal noHeader
                   id={WorkOrderChangeStatusModal.workOrderChangeStatusModalId}
                   ref={this._modalRef}
                   className={styles.workOrderChangeStatusModal}
                   size={ModalSize.Default}
                   onClose={() => this.onCancelAsync()}
            >

                <div className={styles.customHeader}>
                    <h5>{this.toMultiLines(this.title)}</h5>
                </div>

                <div className={styles.commentContainer}>
                    {
                        (this.notificationTrigger) &&
                        (
                            <Checkbox inline
                                      id={`${WorkOrderChangeStatusModal}SendNotification`}
                                      inlineType={InlineType.Right}
                                      label={this.notificationTriggerTitle}
                                      value={this.sendNotification}
                                      onChange={(_, value) => this.onChangeSendNotificationAsync(value)}
                            />
                        )
                    }

                    {
                        (this.showComment) &&
                        (
                            <TextAreaInput required
                                           id={`${WorkOrderChangeStatusModal}Comment`}
                                           className={this.css(styles.comment, (this.sendNotification) && styles.open)}
                                           rows={5}
                                           placeholder={Localizer.workOrderChangeStatusModalPlaceholderCommentReason}
                                           value={this.comment}
                                           onChange={async (_, value) => await this.onChangeCommentAsync(value)}
                            />
                        )
                    }
                </div>

                <div className={styles.expander}/>

                <div>
                    <Button block
                            disabled={(this.showComment && !this.comment)}
                            type={ButtonType.Orange}
                            label={Localizer.confirmationDialogConfirm}
                            icon={{name: "far save"}}
                            onClick={() => this.onConfirmAsync()}
                    />

                    <Button block
                            label={Localizer.genericActionCancel}
                            icon={{name: "fa-ban"}}
                            type={ButtonType.Default}
                            onClick={() => this.onCancelAsync()}
                    />
                </div>
            </Modal>
        );
    }
}