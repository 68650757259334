import RentaTasksWizardPage from "@/pages/RentaTasks/RentaTasksWizardPage";
import React from "react";
import {AddressHelper, DropdownRequiredType, DropdownWidget, SelectListItem} from "@renta-apps/athenaeum-react-components";
import {IBaseComponent} from "@renta-apps/athenaeum-react-common";
import ConstructionSiteOrWarehouse from "@/models/server/ConstructionSiteOrWarehouse";
import GetInactiveConstructionSitesRequest from "@/models/server/requests/GetInactiveConstructionSitesRequest";
import Localizer from "@/localization/Localizer";

interface ISelectInactiveConstructionSiteState {
} 

interface ISelectInactiveConstructionSiteProps {
}

export default class SelectInactiveConstructionSite extends RentaTasksWizardPage
    <ISelectInactiveConstructionSiteProps, ISelectInactiveConstructionSiteState> {

    private readonly ConstructionSitesId = "ConstructionSites";
    
    private async fetchConstructionSitesAsync(sender: IBaseComponent): Promise<ConstructionSiteOrWarehouse[]> {
        const request: GetInactiveConstructionSitesRequest = {
            organizationId: this.wizard.organization?.id ?? null
        };
        return await sender.postAsync("api/rentaTasks/GetInactiveConstructionSites", request);
    }

    private get constructionSite(): ConstructionSiteOrWarehouse | null {
        return this.wizard.owner;
    }

    private async onSetConstructionSiteAsync(constuctionSite: ConstructionSiteOrWarehouse | null): Promise<void> {
        this.wizard.owner = constuctionSite;
        this.saveContext();
        await this.reRenderAsync();
    }

    /** Returns list item formatted for this step's construction site dropdown. */
    private transform(item: ConstructionSiteOrWarehouse): SelectListItem {
        const selectListItem = new SelectListItem();
        selectListItem.ref = item;
        selectListItem.value = item.id;
        selectListItem.subtext = AddressHelper.removeLatLon(item.location?.formattedAddress ?? "");
        selectListItem.text = item.name;
        
        // Append invoice reference to name if exists.
        if (item.invoiceReference) {
            selectListItem.text += ` | ${item.invoiceReference}`;
        }

        return selectListItem;
    }

    protected get canNext(): boolean {
        return (!!this.constructionSite);
    }


    protected get workOrderRequired(): boolean {
        return false;
    }
    
    public getManual(): string {
        return "";
    }
    
    renderContent(): React.ReactNode {
        return (
            <DropdownWidget<ConstructionSiteOrWarehouse> required wide autoCollapse autoGroupSelected
                                                         id={this.ConstructionSitesId}
                                                         requiredType={DropdownRequiredType.Manual}
                                                         icon={{name: "fas users-cog"}}
                                                         label={Localizer.rentaTasksControllerStepsActivateConstructionSiteTitleSelectConstructionSite}
                                                         transform={this.transform}
                                                         fetchDataAsync={(sender) => this.fetchConstructionSitesAsync(sender)}
                                                         selectedItem={this.constructionSite || undefined}
                                                         onChange={async (_, item) => await this.onSetConstructionSiteAsync(item)}  
            />
        );
    }
}