import React from "react";
import {AddressInput, Button, ButtonType, Checkbox, Form, Modal, OneColumn, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import {ButtonContainer} from "@renta-apps/athenaeum-react-components";
import {Dropdown} from "@renta-apps/athenaeum-react-components";
import OrganizationContract from "../../../../models/server/OrganizationContract";
import GetSubcontractorContractsRequest from "../../../../models/server/requests/GetSubcontractorContractsRequest";
import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import AddSubcontractorRequest from "@/models/server/requests/AddSubcontractorRequest";
import ConstructionSite from "@/models/server/ConstructionSite";
import Localizer from "../../../../localization/Localizer";

import "./BootstrapOverride.scss";
import styles from "../AddSubcontractorModal/AddSubcontractorModal.module.scss";

interface IAddSubcontractorModalProps {
    newSubcontractor?: boolean;
    warehouseId?: string;
    constructionSiteId?: string;
    addWarehouseSubcontractor(sender: IBaseComponent, request: AddSubcontractorRequest): Promise<boolean>;
}

interface IAddSubcontractorModalState {
    location: GeoLocation | null,
    name: string;
    vatId: string,
    subcontractors: OrganizationContract[];
    subcontractor: OrganizationContract | null;
    newSubcontractor: boolean;
}

export default class AddSubcontractorModal extends BaseComponent<IAddSubcontractorModalProps, IAddSubcontractorModalState> {

    state: IAddSubcontractorModalState = {
        location: null,
        name: "",
        vatId: "",
        subcontractor: null,
        subcontractors: [],
        newSubcontractor: this.props.newSubcontractor!
    };

    private readonly _modalRef: React.RefObject<Modal<ConstructionSite>> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();

    public async openAsync(): Promise<void> {

        const request = new GetSubcontractorContractsRequest();
        request.excludeConstructionSiteId = this.props.constructionSiteId || null;
        request.excludeWarehouseId = this.props.warehouseId || null;

        const subcontractors: OrganizationContract[] = await this.postAsync("api/constructionSiteManagement/getSubcontractorContracts", request);

        let newSubcontractor: boolean;
        let subcontractor: OrganizationContract | null = null;

        if (this.state.newSubcontractor) {
            newSubcontractor = true;
        } else if (subcontractors.length) {
            newSubcontractor = false;
            subcontractor = subcontractors[0];
        } else {
            newSubcontractor = true;
        }

        await this.setState({subcontractors, subcontractor, newSubcontractor});
        await this._modalRef.current!.openAsync();
    }

    private async onSubmitAsync(): Promise<void> {
        const request = new AddSubcontractorRequest();

        request.warehouseId = this.props.warehouseId || null;
        request.constructionSiteId = this.props.constructionSiteId || null;

        if (this.state.newSubcontractor) {
            request.name = this.state.name;
            request.vatId = this.state.vatId;
            request.formattedAddress = this.state.location!.formattedAddress;
        } else {
            request.organizationContractId = this.state.subcontractor!.id;
            request.formattedAddress = this.state.subcontractor!.location!.formattedAddress;
        }

        const success: boolean = await this.props.addWarehouseSubcontractor(this.modal, request);

        if (!success) {
            const form: Form | null = this._formRef.current;
            if (form != null) {
                const validationError: string = Localizer.addSubcontractorModalValidationErrorSubcontractorAlreadyExists;
                await form.setValidationErrorsAsync(validationError);
            }

            return;
        }

        await this.modal.closeAsync();
    }

    private get modal(): Modal<ConstructionSite> {
        return this._modalRef.current!;
    }

    private async onNameChangeAsync(name: string) {
        await this.setState({name});
    }

    private async onVatIdChangeAsync(vatId: string) {
        await this.setState({vatId});
    }

    private async onPlaceSelectedAsync(location: GeoLocation) {
        await this.setState({location});
    }

    private get newSubcontractor(): boolean {
        return this.state.newSubcontractor;
    }

    private get subcontractors(): OrganizationContract[] {
        return this.newSubcontractor ? [] : this.state.subcontractors;
    }

    private get hasSubcontractors(): boolean {
        return (this.state.subcontractors.length > 0);
    }

    private get selectedSubcontractor(): OrganizationContract | undefined {
        return (!this.newSubcontractor && this.state.subcontractor) ? this.state.subcontractor : undefined;
    }

    private async setSubcontractorAsync(subcontractor: OrganizationContract): Promise<void> {
        await this.setState({subcontractor});
    }

    public static get modalId(): string {
        return "addSubcontractorModal";
    }

    public render(): React.ReactNode {
        return (
            <Modal id={AddSubcontractorModal.modalId} ref={this._modalRef}
                   title={Localizer.subContractorsAddSubcontractor}
            >

                <div className="row">
                    <div className="col">

                        <Form ref={this._formRef} id="addSubcontractor" onSubmit={async () => await this.onSubmitAsync()} noValidate={!this.newSubcontractor}>

                            <TwoColumns>
                                <Dropdown id={`${AddSubcontractorModal.modalId}_subcontractors`} required
                                          items={this.subcontractors}
                                          label={Localizer.subContractorsSubcontractor}
                                          selectedItem={this.selectedSubcontractor}
                                          disabled={this.newSubcontractor}
                                          onChange={async (_, value) => await this.setSubcontractorAsync(value!)}
                                />

                                <Checkbox id={`${AddSubcontractorModal.modalId}_new`}
                                          label={Localizer.subContractorsAddNewSubcontractor}
                                          className={styles.checkbox}
                                          value={this.newSubcontractor}
                                          readonly={!this.hasSubcontractors}
                                          onChange={async (_, value) => await this.setState({newSubcontractor: value})}/>
                            </TwoColumns>

                            <OneColumn className={this.css(styles.newContactPerson, this.newSubcontractor && styles.open)}>
                                <TextInput required 
                                           id={`${AddSubcontractorModal.modalId}_name`}
                                           label={Localizer.genericName}
                                           value={this.state.name!}
                                           onChange={async (_, item) => await this.onNameChangeAsync(item!)}
                                />

                                <TextInput required
                                           id={`${AddSubcontractorModal.modalId}_vatId`}
                                           label={Localizer.genericVatId}
                                           value={this.state.vatId!}
                                           onChange={async (_, item) => await this.onVatIdChangeAsync(item!)}
                                />

                                <AddressInput required
                                              id={`${AddSubcontractorModal.modalId}_address`}
                                              label={Localizer.formInputAddress}
                                              value={this.state.location != null ? this.state.location!.formattedAddress : ""}
                                              onChange={async (location) => await this.onPlaceSelectedAsync(location)}
                                />

                            </OneColumn>

                            <ButtonContainer>
                                <Button id={`${AddSubcontractorModal.modalId}_save`}
                                        type={ButtonType.Orange}
                                        submit
                                        label={Localizer.formSave}
                                />
                            </ButtonContainer>

                        </Form>

                    </div>
                </div>

            </Modal>
        )
    }
}