import {ProductUnit, RentalItemActionType} from "@/models/Enums";

export default class SaveWorkOrderEquipmentRequest {

    public id: string | null = null;
    
    public workOrderId: string = "";
    
    public productId: string | null = null;
    
    public name: string | null = null;

    public description: string | null = null;

    public unit: ProductUnit | null = null;

    public customUnit: string | null = null;

    public amount: number = 0;

    public price: number = 0;

    public rentDate: Date = new Date();

    public actionType: RentalItemActionType = RentalItemActionType.Return;
}