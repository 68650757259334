import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";

import styles from "./FormPageRow.module.scss";
import {IPageRowProps, PageRow} from "@renta-apps/athenaeum-react-components";

interface IFormPageRowProps extends IPageRowProps {
    className?: string;
}

export default class FormPageRow extends BaseComponent<IFormPageRowProps> {
    render(): React.ReactNode {
        return (
            <PageRow className={this.css(this.props.className, styles.formPageRow)}>
                {this.props.children}
            </PageRow>
        );
    }
};