
export default class SaveSubcontractorRequest {

    public id: string = "";

    public organizationName: string | null = null;

    public organizationFormattedAddress: string | null = null;

    public managerUserId: string | null = null;
}