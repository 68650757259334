import React from "react";
import FormModel from "@/models/server/forms/FormModel";
import {Button, ButtonType, DateInput, Dropdown, DropdownAlign, DropdownOrderBy, Form, Grid, Icon, IconSize, IconStyle, SelectListItem, Spinner, ToolbarContainer} from "@renta-apps/athenaeum-react-components";
import {BaseAsyncComponent, IBaseAsyncComponentState} from "@renta-apps/athenaeum-react-common";
import ToolbarModel from "@/pages/ConstructionSiteManagement/FormsData/Toolbar/ToolbarModel";
import User from "@/models/server/User";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./Toolbar.module.scss";

interface IToolbarProps {
    grid: Grid<FormModel> | null;
    model: ToolbarModel;
    readonly?: boolean;

    onChange?(model: ToolbarModel): Promise<void>;
}

interface IToolbarState extends IBaseAsyncComponentState<User[]> {
    model: ToolbarModel;
}

export default class Toolbar extends BaseAsyncComponent<
    IToolbarProps,
    IToolbarState,
    User[]
> {

    state: IToolbarState = {
        data: null,
        isLoading: false,
        model: this.props.model
    };

    private get readonly(): boolean {
        return (!!this.props.readonly);
    }

    private async processOnChange(invoke: boolean = false): Promise<void> {
        await this.setState({model: this.state.model});
        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this.state.model);
        }
    }

    private async setFromAsync(from: Date | null): Promise<void> {
        if (this.state.model.from !== from) {
            this.state.model.from = from;
            await this.processOnChange();
        }
    }

    private async setToAsync(to: Date | null): Promise<void> {
        if (this.state.model.to !== to) {
            this.state.model.to = to;
            await this.processOnChange();
        }
    }

    private async setFormStatusFilterAsync(item: SelectListItem | null): Promise<void> {
        this.state.model.status = (item) ? parseInt(item.value) : null;
        await this.processOnChange(true);
    }

    private async setFormApprovalTypeFilterAsync(sender: Dropdown<SelectListItem>): Promise<void> {
        this.state.model.approvalTypes = sender.selectedListItems.map(item => parseInt(item.value));
        await this.processOnChange(true);
    }

    private async setFormReportersFilterAsync(sender: Dropdown<User>): Promise<void> {
        this.state.model.reporters = sender.selectedItems;
        await this.processOnChange(true);
    }

    private async clearAsync(): Promise<void> {
        this.state.model = new ToolbarModel();
        await this.processOnChange(true);
    }

    protected getEndpoint(): string {
        return "api/constructionSiteManagement/getReporters";
    }

    public hasSpinner(): boolean {
        return true;
    }

    public isAsync(): boolean {
        return true;
    }

    public render(): React.ReactNode {

        return (
            <ToolbarContainer className={styles.toolbar}>

                <Form inline
                      readonly={this.readonly}
                >

                    <Dropdown inline small noValidate noSubtext noWrap noFilter
                              align={DropdownAlign.Left}
                              label={Localizer.formsDataToolbarFiltersStatus}
                              minWidth="180px"
                              orderBy={DropdownOrderBy.None}
                              items={EnumProvider.getWorkOrderFormStatusFilterItems()}
                              selectedItem={this.state.model.status || undefined}
                              onChange={async (_, item) => await this.setFormStatusFilterAsync(item)}
                    />

                    <Dropdown inline small noValidate noSubtext noWrap noFilter multiple
                              align={DropdownAlign.Left}
                              label={Localizer.formsDataToolbarFiltersApprovalType}
                              minWidth="180px"
                              orderBy={DropdownOrderBy.None}
                              items={EnumProvider.getCustomerApprovalTypeItems()}
                              selectedItems={this.state.model.approvalTypes.map(item => EnumProvider.getCustomerApprovalTypeItem(item))}
                              onChange={async (sender) => await this.setFormApprovalTypeFilterAsync(sender)}
                    />

                    <Dropdown inline small noValidate noSubtext noWrap noFilter multiple autoGroupSelected
                              align={DropdownAlign.Left}
                              label={Localizer.formsDataToolbarFiltersReporters}
                              minWidth="180px"
                              orderBy={DropdownOrderBy.None}
                              items={this.state.data || []}
                              selectedItems={this.state.model.reporters}
                              onChange={async (sender) => await this.setFormReportersFilterAsync(sender)}
                    />

                    <DateInput inline small rentaStyle
                               id="from"
                               label={Localizer.formsDataToolbarFiltersFrom}
                               maxDate={new Date()}
                               value={this.state.model.from || undefined}
                               onChange={async (date) => await this.setFromAsync(date)}
                               append={(
                                   <Icon name="fad fa-window-close"
                                         style={IconStyle.Regular}
                                         size={IconSize.Normal}
                                         className={styles.clearDate}
                                         tooltip={Localizer.genericClear}
                                         onClick={() => this.setFromAsync(null)}
                                   />
                               )}
                    />

                    <DateInput inline small rentaStyle
                               id={"to"}
                               label={Localizer.formsDataToolbarFiltersTo}
                               maxDate={new Date()}
                               value={this.state.model.to || undefined}
                               onChange={async (date) => await this.setToAsync(date)}
                               append={(
                                   <Icon name="fad fa-window-close"
                                         style={IconStyle.Regular}
                                         size={IconSize.Normal}
                                         className={styles.clearDate}
                                         tooltip={Localizer.genericClear}
                                         onClick={() => this.setToAsync(null)}
                                   />
                               )}
                    />

                    <Button small
                            disabled={this.readonly}
                            label={Localizer.tasksToolbarSearch}
                            icon={{name: "fas search"}}
                            type={ButtonType.Orange}
                            onClick={async () => await this.processOnChange(true)}
                    />

                    <Button small
                            disabled={this.readonly}
                            title={Localizer.tasksToolbarClearFilters}
                            icon={{name: "far history", size: IconSize.Large}}
                            type={ButtonType.Info}
                            onClick={async () => await this.clearAsync()}
                    />

                </Form>

                {
                    (this.isSpinning()) && <Spinner/>
                }

            </ToolbarContainer>
        );
    }
};