import FormContent from "@/models/server/forms/FormContent";

export default class FormDefinition extends FormContent {

    public id: string = "";

    public deleted: boolean = false;
    
    public deletedAt: Date | null = null;

    public isFormDefinition: true = true;
}