import React from "react";
import FormItem from "@/models/server/forms/FormItem";
import FormModel from "@/models/server/forms/FormModel";
import Comparator from "@/helpers/Comparator";
import PageDefinitions from "@/providers/PageDefinitions";
import {AlertModel, BasePageParameters, DataStorageType, DialogResult, IBasePage, IManualProps, PageRoute, PageRouteProvider, UserInteractionDataStorage} from "@renta-apps/athenaeum-react-common";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {ITitleModel, TitleWidget} from "@renta-apps/athenaeum-react-components";
import RentaTasksWizardPage from "@/pages/RentaTasks/RentaTasksWizardPage";
import FormNavigation from "@/components/Form/FormNavigation/FormNavigation";
import FormContainer from "@/components/Form/FormContainer/FormContainer";
import MounterContext from "@/pages/RentaTasks/Models/MounterContext";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import TransformProvider from "@/providers/TransformProvider";
import RentaTasksController, {IFormWizardStep} from "./RentaTasksController";
import Localizer from "@/localization/Localizer";

export default abstract class RentaTasksFormWizardPage<TParams extends BasePageParameters = {}, TState = {}>
    extends RentaTasksWizardPage<TParams, TState> {

    protected async openSummaryAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.rentaTasksFormSummaryPageRoute);
    }

    protected async openStepAsync(item: FormItem): Promise<void> {
        const steps: IFormWizardStep[] = this.formSteps;

        return await RentaTasksController.openStepAsync(item.id, steps);
    }

    protected async saveContextAsync(): Promise<void> {
        this.saveContext();
    }

    protected saveContext(): void {
        RentaTasksController.saveMounterContext();

        const currentWizardFormHashCodeHashCode: number = Utility.getHashCode(RentaTasksController.mounterContext.form);

        UserInteractionDataStorage.set(RentaTaskConstants.userInteraction.currentFormHashCode, currentWizardFormHashCodeHashCode, DataStorageType.Session);
    }
    public static async openMountersSignaturesStepAsync(): Promise<void> {
        return await RentaTasksController.openMountersSignaturesStepAsync();
    }

    // @ts-ignore - ?
    public get context(): MounterContext {
        return RentaTasksController.mounterContext;
    }

    public set context(_) {
        // do nothing
    }

    public get title(): ITitleModel | undefined {
        return TransformProvider.toTitle(this.form);
    }

    protected get form(): FormModel | null {
        return this.context.form;
    }

    protected get preview(): boolean {
        return this.context.formPreview;
    }
    
    protected get isReadonly(): boolean {
        return this.context.form!.processed
    }

    protected findFormItem(): FormItem | null {
        return RentaTasksController.formItem;
    }

    protected get currentFormIndex(): number {
        const page: IBasePage = this.getPage();
        const route: PageRoute = page.route;
        return this.formSteps.findIndex(step => Comparator.isEqual(step.route, route));
    }

    private isFormModified(): boolean {
        const original: number = UserInteractionDataStorage.get(RentaTaskConstants.userInteraction.initialFormHashCode, 1, DataStorageType.Session);
        const currentHashCode: number = UserInteractionDataStorage.get(RentaTaskConstants.userInteraction.currentFormHashCode, -1, DataStorageType.Session);

        if (currentHashCode === -1) {
            return false;
        }

        return (original !== currentHashCode);
    }

    public async beforeRedirectAsync(nextRoute: PageRoute): Promise<boolean> {

        const nextRouteInsideWizard: boolean = this.nextRouteWithinWizard(nextRoute);

        if (nextRouteInsideWizard) {
            return true;
        }

        let isModified: boolean = !this.isReadonly ? this.isFormModified() : false;

        if (isModified) {
            const result: DialogResult = await this.messageBoxAsync(Localizer.addEquipmentSaveChangesConfirmation, "", {
                yesButton: Localizer.addEquipmentSaveButton,
                noButton: Localizer.addEquipmentDontSaveButton,
                cancelButton: Localizer.addEquipmentCloseButton
            });

            if (result == DialogResult.Cancel) {
                return false;
            }

            this.resetWorkOrderModifiedValue();

            if (result == DialogResult.No) {
                return true;
            }
            if (result == DialogResult.Yes) {
                await RentaTasksController.saveFormAsync(false);
                await this.alertMessageAsync(Localizer.formSummaryPageFormSaved, true);
            }
        }

        return true;
    }

    protected get formSteps(): IFormWizardStep[] {
        return this
            .steps
            .filter(step => ((step as any).isFormWizardStep === true))
            .map(step => step as IFormWizardStep);
    }

    protected async onSaveAndCloseAsync(): Promise<void> {
        this.resetWorkOrderModifiedValue();
        const workOrderId: string = RentaTasksController.mounterContextWorkOrder!.id;
        await RentaTasksController.saveFormAsync(false);
        await PageRouteProvider.redirectAsync(PageDefinitions.rentaTasksWorkOrder(workOrderId));
        await this.alertMessageAsync(Localizer.formSummaryPageFormSaved, true);
    }

    protected get showMiddle(): boolean {
        return !this.form!.processed;
    }

    public async redirectToSendStepAsync(): Promise<void> {

        const alert: AlertModel | null = this.alert;

        await this.redirectNextAsync(PageDefinitions.rentaTasksFormReceiversPageRoute);

        if ((alert != null) && (alert.autoClose)) {
            await this.alertAsync(alert);
        }
    }

    public getManualProps(): IManualProps {
        return {
            manual: this.getManual(),
            icon: "fal info",
        }
    }

    public getManual(): string {
        return "";
    }

    public renderContainer(): React.ReactNode {
        return (
            <React.Fragment>

                <TitleWidget model={this.title || undefined} wide />

                <FormContainer>

                    {
                        (this.formSteps.length > 0) && (this.currentFormIndex !== -1) &&
                        (
                            <FormNavigation currentIndex={this.currentFormIndex}
                                             steps={this.formSteps}
                                             onNavigate={async (step)=> await this.navigateAsync(step)}
                                             onClick={async () => await this.openSummaryAsync()}
                            />
                        )
                    }

                    {
                        this.renderContent()
                    }

                </FormContainer>

            </React.Fragment>
        );
    }
}