import React from "react";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, Modal, ModalSize,} from "@renta-apps/athenaeum-react-components";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import GetWorkOrderRequest from "@/models/server/requests/GetWorkOrderRequest";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";
import RentalItemDetailView from "@/models/view/RentalItemDetailView";
import UserContext from "@/models/server/UserContext";

import styles from "./ApproveWorkOrderModal.module.scss"

interface ISendWorkOrderData {
    workOrder: WorkOrderModel;

}

interface IApproveWorkOrderModalProps {
    onApproveAsync(workOrderId: string): Promise<void>;
}

interface IApproveWorkOrderModalState {
    initialized: boolean;
}

export default class ApproveWorkOrderModal extends BaseComponent<IApproveWorkOrderModalProps, IApproveWorkOrderModalState> {
    state: IApproveWorkOrderModalState = {
        initialized: false
    };

    private readonly _modalRef: React.RefObject<Modal<ISendWorkOrderData>> = React.createRef();
    private _resolver: ((successfully: boolean) => void) | null = null;
    private _successfully: boolean = false;

    private transformData(data: any): ISendWorkOrderData {
        return ((data instanceof WorkOrderModel) || ((data as WorkOrderModel).isWorkOrderModel))
            ? {workOrder: data as WorkOrderModel}
            : data as ISendWorkOrderData;
    }


    private async onOpenAsync(): Promise<void> {
        this._successfully = false;

        await this.setState({initialized: true});
    }

    private async onCloseAsync(): Promise<void> {

        if (this._resolver) {
            this._resolver(this._successfully);
            this._resolver = null;
        }
        await this.setState({initialized: false});
    }

    private async approveWorkOrderAsync(): Promise<void> {
        await this.closeAsync();
        await this.props.onApproveAsync(this.workOrderId)
    }

    private get modal(): Modal<ISendWorkOrderData> {
        return this._modalRef.current!;
    }

    private get hasData(): boolean {
        return ((this._modalRef.current != null) && (this.modal.data != null));
    }

    private get data(): ISendWorkOrderData {
        return this.modal.data!;
    }

    private get initialized(): boolean {
        return this.state.initialized;
    }

    private get title(): string {
        return (this.initialized)
            ? Localizer.get(Localizer.workOrderApprovalModalTitle, this.workOrder.name)
            : "";
    }

    private get subtitle(): string {
        return (this.initialized) ?
            this.workOrder.invoiceReference!
            : "";
    }

    public get workOrder(): WorkOrderModel {
        return this.data.workOrder;
    }

    private get workOrderId(): string {
        return this.workOrder.id;
    }

    public get successfully(): boolean {
        return this._successfully;
    }

    public async closeAsync(): Promise<void> {
        await this.modal.closeAsync();
    }

    public async openAsync(workOrder: WorkOrderModel): Promise<void> {
        const request = new GetWorkOrderRequest();
        request.workOrderId = workOrder.id;
        request.excludeDeletedUserSalaryHours = true;

        workOrder = await this.postAsync("/api/constructionSiteManagement/getWorkOrder", request);

        const data: ISendWorkOrderData = {workOrder};

        await this.modal.openAsync(data);
    }

    public async showAsync(workOrder: WorkOrderModel): Promise<boolean> {
        await this.openAsync(workOrder);

        return new Promise((resolve) => {
            this._resolver = resolve;
        });
    }

    public static get modalId(): string {
        return "approveWorkReportModal";
    }
    
    private renderSalesProducts(): React.ReactNode {
        const salesEquipment: WorkOrderEquipment[] = this.workOrder.equipments!.filter(x => WorkOrderEquipment.isSalesProduct(x));
        return (salesEquipment.length > 0) && (
            <div className={styles.approveWorkOrderModal}>
                <h5>{Localizer.workOrderApprovalEquipmentsTableName}</h5>
                <table className={styles.list}>
                    <thead>
                    <tr>
                        <th>{Localizer.workOrderApprovalEquipmentDescription}</th>
                        <th>{Localizer.workOrderApprovalEquipmentInfo}</th>
                        <th>{Localizer.workOrderEquipmentAmount}</th>
                        <th>{Localizer.workOrderApprovalEquipmentUnit}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        salesEquipment.map(equipment => {
                            return (
                                <tr className={styles.content} key={equipment.id}>
                                    <td>{equipment.product?.name ?? equipment.name}</td>
                                    <td>{equipment.description}</td>
                                    <td>{equipment.amount}</td>
                                    <td>{equipment.customUnit ?? EnumProvider.getProductUnitText(equipment.unit!)}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }

    private renderRentalProducts(): React.ReactNode {
        const individualRentalEquipment: RentalItemDetailView[] = this.workOrder
            .rentalEquipments!.map(x => new RentalItemDetailView(x));
        
        const massRentalEquipment: RentalItemDetailView[] = this.workOrder
            .equipments!.filter(x => WorkOrderEquipment.isRentalProduct(x)).map(x => new RentalItemDetailView(x));
        
        const rentalEquipment: RentalItemDetailView[] = [...individualRentalEquipment, ...massRentalEquipment];
        
        return (rentalEquipment.length > 0) && (
            <div className={styles.approveWorkOrderModal}>
                <h5>{Localizer.rentalEquipmentRentalEquipment}</h5>
                <table className={styles.list}>
                    <thead>
                    <tr>
                        <th>{Localizer.workOrderApprovalEquipmentDescription}</th>
                        <th>{Localizer.workOrderApprovalEquipmentInfo}</th>
                        <th>{Localizer.workOrderApprovalEquipmentUnit}</th>
                        <th>{Localizer.workOrderEquipmentAmount}</th>
                        <th>{Localizer.genericDate}</th>
                        <th>{Localizer.rentalEquipmentActionType}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rentalEquipment.map(equipment => {
                            return (
                                <tr className={styles.content} key={equipment.id}>
                                    <td>{equipment.name}</td>
                                    <td>{equipment.comment}</td>
                                    <td>{equipment.unit != null ? EnumProvider.getProductUnitText(equipment.unit) : "-"}</td>
                                    <td>{equipment.amount}</td>
                                    <td>{Utility.format("{0:dd.MM.yyyy}", equipment.rentDate)}</td>
                                    <td>{EnumProvider.getRentalItemActionTypeText(equipment.actionType)}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }
    
    private renderHours(): React.ReactNode {
        return (this.workOrder.userSalaryHours && this.workOrder.userSalaryHours.length > 0) && (
            <div className={styles.approveWorkOrderModal}>
                <h5>{Localizer.workOrderApprovalHoursTableName}</h5>
                <table className={styles.list}>
                    <thead>
                    <tr>
                        <th>{Localizer.workOrderApprovalHoursDay}</th>
                        <th>{Localizer.workOrderApprovalHoursUser}</th>
                        <th>{Localizer.workOrderApprovalHoursNormalHours}</th>
                        <th>{Localizer.workOrderApprovalHoursOvertime50Hours}</th>
                        <th>{Localizer.workOrderApprovalHoursOvertime100Hours}</th>
                    </tr>
                    </thead>

                    <tbody>
                    {

                        this.workOrder.userSalaryHours.map(salaryHour => {
                            return (
                                <tr className={styles.content} key={salaryHour.id}>
                                    <td>{Utility.format(salaryHour.day.toISODateString(), "D")}</td>
                                    <td>{salaryHour.user?.email}</td>
                                    <td>{salaryHour.normalHours}</td>
                                    <td>{salaryHour.overtime50Hours}</td>
                                    <td>{salaryHour.overtime100Hours}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }
    
    private renderDistances(): React.ReactNode {
        return (this.workOrder.distances && this.workOrder.distances.length > 0) && (
            <div className={styles.approveWorkOrderModal}>
                <h5>{Localizer.workOrderApprovalMileagesTableName}</h5>
                <table className={styles.list}>
                    <thead>
                    <tr>
                        <th>{Localizer.workOrderApprovalMileagesDate}</th>
                        <th>{Localizer.workOrderApprovalMileagesVehicleAmount}</th>
                        <th>{Localizer.workOrderApprovalMileagesTotalMileages}</th>
                    </tr>
                    </thead>

                    <tbody>
                    {

                        this.workOrder.distances.map(distance => {
                            return (
                                <tr className={styles.content} key={distance.id}>
                                    <td>{Utility.format(distance.day.toISODateString(), "D")}</td>
                                    <td>{distance.vehicles}</td>
                                    <td>{distance.value}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }
    
    private renderExtraCharges(): React.ReactNode {
        return (this.workOrder.extraCharges && this.workOrder.extraCharges.length > 0) && (
            <div className={styles.approveWorkOrderModal}>
                <h5>{Localizer.rentaTasksWorkOrderDetailsExtraCharges}</h5>
                <table className={styles.list}>
                    <thead>
                    <tr>
                        <th>{Localizer.workOrderDetailsPanelGridExtraChargesType}</th>
                        <th>{Localizer.workOrderDetailsPanelGridExtraChargesUnit}</th>
                        <th>{Localizer.genericComment}</th>
                        <th>{Localizer.genericDate}</th>
                        <th>{Localizer.workOrderDetailsPanelGridExtraChargesAmount}</th>
                    </tr>
                    </thead>

                    <tbody>
                    {

                        this.workOrder.extraCharges.map(extraCharge => {
                            return (
                                <tr className={styles.content} key={extraCharge.id}>
                                    <td>{extraCharge.extraChargeType!.name}</td>
                                    <td>{EnumProvider.getExtraChargeTypeUnitText(extraCharge.extraChargeType!.unit)}</td>
                                    <td>{extraCharge.description}</td>
                                    <td>{Utility.format("{0:dd.MM.yyyy}", extraCharge.extraChargeDate)}</td>
                                    <td>{extraCharge.amount}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }
    
    private renderFixedCosts(): React.ReactNode {
        const context: UserContext = ch.getContext() as UserContext;
        const isBusinessManagerOrAdmin: boolean = context.isBusinessManager || context.isAdmin;
        
        return (isBusinessManagerOrAdmin && WorkOrderModel.isFixedPrice(this.workOrder)) && (
            <div className={styles.approveWorkOrderModal}>
                <h5>{Localizer.workOrderFixedPriceHeader}</h5>
                <table className={styles.list}>
                    <thead>
                    <tr>
                        <th>{Localizer.workOrderFixedTransportationCostLabel}</th>
                        <th>{Localizer.workOrderFixedHoursCostLabel}</th>
                        <th>{Localizer.workOrderFixedProductCostLabel}</th>
                        <th>{Localizer.workOrderFixedCostLabel}</th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr className={styles.content}>
                            <td>{this.workOrder.fixedTransportationCost}</td>
                            <td>{this.workOrder.fixedHoursCost}</td>
                            <td>{this.workOrder.fixedProductCost}</td>
                            <td>{this.workOrder.fixedCost}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <Modal id={ApproveWorkOrderModal.modalId} ref={this._modalRef}
                   title={this.title}
                   size={ModalSize.Large}
                   subtitle={this.subtitle}
                   transform={this.transformData}
                   onOpen={async () => await this.onOpenAsync()}
                   onClose={async () => await this.onCloseAsync()}
            >

                {
                    (this.hasData) &&
                    (
                        <>
                            { this.renderSalesProducts() }
                            { this.renderRentalProducts() }
                            { this.renderHours() }
                            { this.renderDistances() }
                            { this.renderExtraCharges() }
                            { this.renderFixedCosts() }

                            <ButtonContainer>
                                <Button submit
                                        type={ButtonType.Orange}
                                        label={Localizer.rentaTasksControllerStepsTitleApprove}
                                        onClick={async () => await this.approveWorkOrderAsync()}
                                        icon={{name: "thumbs-up"}}/>
                            </ButtonContainer>
                        </>
                    )
                }

            </Modal>
        )
    }
}