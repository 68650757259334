import Product from "@/models/server/Product";
import {CatalogType, EquipmentType, ProductUnit, RentalItemActionType} from "@/models/Enums";

export default class WorkOrderEquipment {
    
    constructor(type: EquipmentType = EquipmentType.Product) {
        this.type = type;
    }
    
    public id: string = "";
    
    public workOrderId: string = "";

    public productId: string | null = null;
    
    public product: Product | null = null;

    public type: EquipmentType = EquipmentType.Product;
    
    public name: string | null = null;

    public unit: ProductUnit | null = null;

    public customUnit: string | null = null;
    
    public amount: number = 0;
    
    public price: number = 0;

    public cost: number = 0;

    public description: string | null = null;

    public isWorkOrderEquipment: boolean = true;

    public rentDate: Date = new Date();

    public actionType: RentalItemActionType = RentalItemActionType.Leave;
    
    public static isSalesProduct(item: WorkOrderEquipment): boolean {
        return item.product?.category.catalogType === CatalogType.SalesProduct || item.type === EquipmentType.Custom;
    }

    public static isRentalProduct(item: WorkOrderEquipment): boolean {
        return item.product?.category.catalogType === CatalogType.RentalMassProduct;
    }
}