import React, {createRef, ReactNode, RefObject} from "react";
import RentaTasksFormWizardPage from "@/pages/RentaTasks/RentaTasksFormWizardPage";
import FormPageRow from "@/components/Form/FormPageRow/FormPageRow";
import FormButton from "@/components/Form/FormButton/FormButton";
import {ButtonType, Dropdown, DropdownAlign, DropdownOrderBy} from "@renta-apps/athenaeum-react-components";
import User from "@/models/server/User";
import UserSignatureModel from "@/models/server/UserSignatureModel";
import {UserSignature} from "@/components/UsersSignatures/UserSignature";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "@/localization/Localizer";
import TransformProvider from "@/providers/TransformProvider";

import styles from "./MountersSignatures.module.scss";

interface IMountersSignaturesState {
    usersSignatures: UserSignatureModel[];
    selectedUsers: User[];    
    showValidationMessage: boolean;
}

export default class MountersSignatures extends RentaTasksFormWizardPage {

    public state: IMountersSignaturesState = {
        usersSignatures: [],
        selectedUsers: this.initializeSelectedUsers(),
        showValidationMessage: true
    };

    private readonly _mountersDropdownRef: RefObject<Dropdown<User>> = createRef();

    private getMounters(): User[] {
        const formMounters = this.context.wizard.workOrder!.mounters;

        const user: User = this.getUser();
        if (!formMounters.find((mounter: User): boolean => mounter.id === user.id)) {
            formMounters.push(user);
        }

        return formMounters;
    }

    private get getShowValidationMessage(): boolean {
        return this.state.showValidationMessage;
    }

    private updateValidationMessageState() {
        const showValidationMessage = !UserSignatureModel.areSignaturesValid(this.getSignatures);
        this.setState({showValidationMessage})
    }

    private get getSignatures(): UserSignatureModel[] {
        if (this.context.form!.usersSignatures) {
            this.state.usersSignatures = this.context.form!.usersSignatures;
        }
        return this.state.usersSignatures;
    }

    private readonly onSignatureSign = async (signatureSrc: string | null, user: User | undefined): Promise<void> => {
        if (user && signatureSrc) {
            const signature: UserSignatureModel | undefined = this.state.usersSignatures
                .find(signature => signature.user?.id === user.id);

            if (signature) {
                await RentaTasksController.uploadUserSignature(signature, signatureSrc!)
            }
        }
        this.updateValidationMessageState();
    }

    private readonly onNameClarificationChange = async (nameClarification: string, user: User | undefined): Promise<void> => {
        if (user) {
            const userSignature: UserSignatureModel | undefined = this.state.usersSignatures
                .find(signature => signature.user?.id === user.id);

            if (userSignature) {
                userSignature.nameClarification = nameClarification;
            }
        }

        this.updateValidationMessageState();
    }

    private initializeSelectedUsers(): User[] {
        if (this.context.form!.usersSignatures) {
            const signaturesUsers: User[] = this.context.form!.usersSignatures
                .map((signature) => signature.user!);

            if (signaturesUsers.length > 0) {
                return signaturesUsers;
            }
        }

        return [];
    }

    private onMounterSelected(mounter: User) {
        const existingSignature: boolean = this
            .getSignatures
            .some((signature) => signature.user?.id === mounter.id);

        if (!existingSignature) {
            const newSignature: UserSignatureModel = new UserSignatureModel();
            newSignature.id = RentaTasksController.generateGuid();
            newSignature.user = mounter;
            newSignature.nameClarification = TransformProvider.userToString(mounter);
            const usersSignatures: UserSignatureModel[] = this.getSignatures;

            usersSignatures.push(newSignature);
            this.setState({usersSignatures})
        }
    }

    private async onSignatureDelete(user: User): Promise<void> {
        const userSignature: UserSignatureModel | undefined = this.getSignatures
            .find(signature => signature.user?.id === user.id);

        if (userSignature) {
            const userSignatureIndex = this.getSignatures.indexOf(userSignature);

            if (userSignatureIndex > -1) {
                this.getSignatures.splice(userSignatureIndex, 1);

                const usersSignatures = this.getSignatures;
                this.setState({usersSignatures})
            }
        }
    }

    private readonly onMountersChangeAsync = async (mounters: User[]): Promise<void> => {
        await mounters.forEachAsync(async (mounter) => this.onMounterSelected(mounter))

        const signaturesUsers: (User | null)[] = this.getSignatures.map((signature) => signature.user);

        const userIsUnselected: (User | null)[] = signaturesUsers
            .filter(user => !mounters.some(mounter => mounter.id === user?.id));

        if (userIsUnselected.length === 1) {
            await this.onSignatureDelete(userIsUnselected[0]!);
        }
        this.updateValidationMessageState();
    };

    protected get canNext(): boolean {
        if (UserSignatureModel.areSignaturesValid(this.getSignatures)) {
            this.mounterContext.form!.usersSignatures = this.getSignatures;
            this.saveContext();
            return true;
        }

        return false;
    }

    public async nextAsync(): Promise<void> {
        if (this.canNext) {
            await super.nextAsync();
        }
    }

    private renderSignature(signature: UserSignatureModel): ReactNode {
        return (
            <UserSignature key={signature.id}
                           readonly={this.isReadonly}
                           src={signature.file?.src}
                           id={signature.id}
                           selectedMounter={signature.user!}
                           onSignatureSign={this.onSignatureSign}
                           nameClarification={signature.nameClarification}
                           onNameClarificationChange={this.onNameClarificationChange}
            />
        );
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                {
                    !this.isReadonly && <div><p>{Localizer.mountersSignaturesInstructionMessage}</p>

                        <Dropdown<User> id="mountersSignaturesDropdown"
                                        multiple groupSelected small noValidate noSubtext noWrap
                                        className={"pb-4"}
                                        ref={this._mountersDropdownRef}
                                        label={Localizer.genericMounters}
                                        minWidth="180px"
                                        align={DropdownAlign.Left}
                                        orderBy={DropdownOrderBy.None}
                                        items={this.getMounters()}
                                        selectedItems={this.state.selectedUsers}
                                        onChange={(sender) => this.onMountersChangeAsync(sender.selectedItems)}
                        />
                    </div>
                }

                {
                    this.getSignatures.map((signature) => {
                        return this.renderSignature(signature);
                    })
                }

                {
                    !this.isReadonly &&
                    <FormPageRow>

                        {
                            this.getShowValidationMessage &&
                            <div className={styles.validationMessage}>{Localizer.mountersSignaturesValidationMessage}</div>
                        }

                        <FormButton type={ButtonType.Orange} block big
                                    label={Localizer.mountersSignaturesSubmitSignatures}
                                    onClick={async () => this.nextAsync()}
                        />
                    </FormPageRow>
                }

            </React.Fragment>
        )
    }
}