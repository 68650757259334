import React from "react";
import {NewsItem} from "@/models/server/NewsItem";
import ReactMarkdown from "react-markdown";

import styles from "./NewsCarousel.module.scss";

interface INewsCarouselItemProps {
    newsItem: NewsItem, 
    index: number,
}
const LinkRenderer = (props: any) => {
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    );
}
    
const NewsCarouselItem = (props: INewsCarouselItemProps) => {    
    
    const getNewsItemImage = (newsItem: NewsItem): string => {
        if (newsItem.file && newsItem.file.name !== null) {
            return `${newsItem.file.src}`;
        }
        return '';
    }

    return (
        <div key={props.index + props.newsItem.title}
             className={styles.slide}
        >
            <div className={styles.background} style={{backgroundImage: `url(${getNewsItemImage(props.newsItem)})`}}></div>
            <div className={styles.overlay}></div>
            <h2 className={styles.header}>
                {props.newsItem.title}
            </h2>

            <ReactMarkdown className={styles.content}
                           components={{a: LinkRenderer}}
            >
                {props.newsItem.description}
            </ReactMarkdown>
        </div>
    );
}

export default NewsCarouselItem;