import {executeWithIntercept, getRandomInteger, gridColumns, pageData} from "@/helpers/CypressHelper";
import Chainable = Cypress.Chainable;

context("(Desktop) ConstructionSiteManagement SubcontractorsPanel", () => {
    beforeEach(() => {
        cy.init("Desktop");
    });

    function subcontractorCell(cellContent: string, column: typeof gridColumns.subcontractorsPanel.subcontractorsGrid[number]): Chainable<JQuery<HTMLTableCellElement>> {
        return pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors.subcontractorsGridCell(cellContent, column);
    }

    it("Should create a new Subcontractor Contract and add subcontractor to construction site.", () => {
        cy.loginAsAdmin();

        // Navigate
        const constructionSiteName: string = "Customer.CompanyContract.ConstructionSite";
        cy.navigateToConstructionSiteManagementPage(constructionSiteName);

        pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors.tab().click();

        // Create and add
        const subcontractor: AddSubcontractorSettings = generateSubcontractorSettings(true, true, true);

        cy.addSubcontractor(subcontractor, true);

        // Assert
        assertSubcontractorData(subcontractor.name, subcontractor.vatId, subcontractor.address);
    });

    it("Should add an existing Subcontractor to construction site.", () => {
        cy.loginAsAdmin();

        // Navigate
        const constructionSiteName: string = "Customer.CompanyContract.ConstructionSite";
        cy.navigateToConstructionSiteManagementPage(constructionSiteName);

        pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors.tab().click();

        // Add
        const subcontractor: AddSubcontractorSettings = generateSubcontractorSettings(
            "SubContractorOrganization.SubCompanyContract2");

        cy.addSubcontractor(subcontractor, false);

        // Assert
        assertSubcontractorData(subcontractor.name);
    });

    it("Should update an existing Subcontractor.", () => {
        cy.loginAsAdmin();

        // Navigate
        const constructionSiteName: string = "Customer.CompanyContract.ConstructionSite";
        cy.navigateToConstructionSiteManagementPage(constructionSiteName);

        pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors.tab().click();

        // Create  and add
        const subcontractor: AddSubcontractorSettings = generateSubcontractorSettings(
            "SubContractorOrganization.SubCompanyContract3");

        cy.addSubcontractor(subcontractor, false);

        // Update
        const newSubcontractorName: string = `Subcontractor-${getRandomInteger()}`;

        subcontractorCell(subcontractor.name, "name").trigger('click');
        cy.focused().clear();
        cy.focused().type(newSubcontractorName + "{enter}");

        executeWithIntercept(
            () => subcontractorCell(newSubcontractorName, "actions").find('.fa-save').click(),
            [pageData().constructionSiteManagement.routes.saveSubcontractor]
        );

        // Assert
        assertSubcontractorData(newSubcontractorName);
    });

    it("Should create, delete and restore a Subcontractor.", () => {
        cy.loginAsAdmin();

        // Navigate
        const constructionSiteName: string = "Customer.CompanyContract.ConstructionSite";
        cy.navigateToConstructionSiteManagementPage(constructionSiteName);

        pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors.tab().click();

        // Create and add
        const subcontractor: AddSubcontractorSettings = generateSubcontractorSettings(true, true, true);

        cy.addSubcontractor(subcontractor, true);

        // Delete
        subcontractorCell(subcontractor.name, "actions").find('.fa-trash-alt').trigger('click');

        executeWithIntercept(
            () => cy.contains(`Are you sure, you want to delete the subcontractor '${subcontractor.name}'?`).parent().within(() => {
                cy.contains('button', 'Confirm').trigger('click');
            }),
            [pageData().constructionSiteManagement.routes.deleteConstructionSiteSubcontractor]
        );

        // Assert
        assertSubcontractorDeleted(subcontractor.name, subcontractor.vatId!);
        
        // Restore
        // Show deleted
        pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors.subcontractorsGridHeading
            .showDeletedCheckbox().click();
        pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors.subcontractorsGridHeading
            .showDeletedCheckbox().should('have.class', 'fa-check-square');

        executeWithIntercept(
            () => subcontractorCell(subcontractor.name, "actions").find('.fa-undo-alt').trigger('click'),
            [pageData().constructionSiteManagement.routes.restoreConstructionSiteSubcontractor]
        );

        // Hide deleted
        pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors.subcontractorsGridHeading
            .showDeletedCheckbox().click();
        pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors.subcontractorsGridHeading
            .showDeletedCheckbox().should('have.class', 'fa-square');

        // Assert
        assertSubcontractorData(subcontractor.name, subcontractor.vatId, subcontractor.address);
    });
});

function assertSubcontractorData(name: string, vatId?: string, address?: string) {
    if (name) {
        pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors.subcontractorsGridCell(name, "name")
            .should("contain.text", name)
            .should('be.visible');
    }
    
    if (vatId) {
        pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors.subcontractorsGridCell(vatId, "vatId")
            .should("contain.text", vatId)
            .should('be.visible');
    }
    
    if (address) {
        pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors.subcontractorsGridCell(address, "address")
            .should("contain.text", address)
            .should('be.visible');
    }
}

function assertSubcontractorDeleted(name: string, vatId: string) {
    pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors.subcontractorsGridHeading
        .reloadButton().click();

    pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors
        .subcontractorsGrid().should('not.have.value', name);

    pageData().constructionSiteManagement.constructionSiteManagementTabsContainer.subcontractors
        .subcontractorsGrid().should('not.have.value', vatId);
}

// if 'true' - default value is generated for a property.
function generateSubcontractorSettings(
    name: string | true,
    vatId?: string | true,
    address?: string | true,
) : AddSubcontractorSettings {
    return {
        name: (typeof name === "string")
            ? name
            : `SubcontractorContract-${getRandomInteger(null, 100)}`,
        vatId: (typeof vatId === "string")
            ? vatId
            : (vatId === true)
                ? `${getRandomInteger(1000000, 9999999)}-${getRandomInteger(null, 9)}`
                : undefined,
        address: (typeof address === "string")
            ? address
            : (address === true)
                ? "Helsinginkatu, Turku, Suomi"
                : undefined,
    };
}
