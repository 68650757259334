import React from "react";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, DateInput, Dropdown, DropdownAlign, DropdownOrderBy, Form, Inline, Modal, SelectListItem} from "@renta-apps/athenaeum-react-components";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import ListWorkOrderAttachmentsRequest from "@/models/server/requests/ListWorkOrderAttachmentsRequest";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./AttachmentsFiltersModal.module.scss";

interface IAttachmentsFiltersModalProps {
    request: ListWorkOrderAttachmentsRequest;
    title: string;

    onSubmit(sender: IBaseComponent, request: ListWorkOrderAttachmentsRequest): Promise<void>;
}

interface IAttachmentsFiltersModalState {
    request: ListWorkOrderAttachmentsRequest;
}

export default class AttachmentsFiltersModal extends BaseComponent<IAttachmentsFiltersModalProps, IAttachmentsFiltersModalState> {
    state: IAttachmentsFiltersModalState = {
        request: this.props.request
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _fromRef: React.RefObject<DateInput> = React.createRef();
    private readonly _beforeRef: React.RefObject<DateInput> = React.createRef();
    private readonly _atRef: React.RefObject<DateInput> = React.createRef();
    private readonly _attachmentsTypesRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();

    private async clearAsync(): Promise<void> {
        this.state.request.clear();

        await this.setState({request: this.state.request});
    }

    private async clearAtAsync(): Promise<void> {
        this._atRef.current!.clear();
    }

    private async clearRangeAsync(): Promise<void> {
        this._fromRef.current!.clear();
        this._beforeRef.current!.clear();
    }

    private async submitAsync(data: Dictionary<string, any>): Promise<void> {
        this.copyTo(data, this.state.request);

        await this._modalRef.current!.closeAsync();

        await this.props.onSubmit(this, this.state.request);
    }

    public async openAsync(): Promise<void> {
        await this._modalRef.current!.openAsync();
    }

    public render(): React.ReactNode {

        return (
            <Modal id={this.id} title={this.props.title} ref={this._modalRef} className={styles.attachmentsFiltersModal}>

                <Form className={styles.form}
                      onSubmit={async (_, data) => await this.submitAsync(data)}
                >

                    <Inline className={styles.inline}>

                        <Dropdown multiple
                                  name={"types"}
                                  ref={this._attachmentsTypesRef}
                                  label={Localizer.attachmentsFiltersModalType}
                                  minWidth="180px"
                                  align={DropdownAlign.Left}
                                  orderBy={DropdownOrderBy.None}
                                  items={EnumProvider.getAttachmentTypeItems()}
                                  selectedItems={this.props.request.types ?? []}
                        />

                        <DateInput popup
                                   name="date"
                                   ref={this._atRef}
                                   label={Localizer.attachmentsFiltersModalUploadedAt}
                                   value={this.props.request.date || undefined}
                                   onChange={async () => await this.clearRangeAsync()}
                        />

                    </Inline>

                    <Inline className={styles.inline}>
                        <DateInput popup
                                   name="from"
                                   ref={this._fromRef}
                                   label={Localizer.attachmentsFiltersModalUploadedFrom}
                                   value={this.props.request.from || undefined}
                                   onChange={async () => await this.clearAtAsync()}
                        />

                        <DateInput popup
                                   name="to"
                                   ref={this._beforeRef}
                                   label={Localizer.attachmentsFiltersModalUploadedBefore}
                                   value={this.props.request.to || undefined}
                                   onChange={async () => await this.clearAtAsync()}
                        />
                    </Inline>

                    <ButtonContainer className={this.css(styles.buttons, this.mobile && styles.mobile)}>

                        <Button submit
                                type={ButtonType.Orange}
                                label={Localizer.workOrdersFiltersButtonApply}
                        />

                        <Button type={ButtonType.Blue}
                                label={Localizer.tasksToolbarClearFilters}
                                onClick={async () => await this.clearAsync()}
                        />

                    </ButtonContainer>

                </Form>

            </Modal>
        );
    }
};