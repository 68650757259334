 import FormModel from "@/models/server/forms/FormModel";

export default class SaveFormRequest {

    public form: FormModel | null = null;

    public signatureSrc: string | null = null;

    public nameClarification: string | null = null;
    
    public completeAction: boolean = false;
}