import React, {ReactNode} from "react";
import {Button, ButtonContainer, ButtonType, Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import User from "@/models/server/User";
import Localizer from "@/localization/Localizer";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";

interface IAssignedMountersWarningModalProps {
    assignedMounters: User[];
}

interface IAssignedMountersWarningModalState {
}

export default class AssignedMountersWarningModal extends BaseComponent<IAssignedMountersWarningModalProps, IAssignedMountersWarningModalState> {

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    private get assignedMounters(): User[] {
        return this.props.assignedMounters;
    }
    public async openAsync(): Promise<void> {
        await this.modal.openAsync();
    }
    public async closeAsync(): Promise<void> {
        await this.modal.closeAsync();
    }

    private renderContent(mounter: User): ReactNode {
        return (
            <span><br/>{mounter.firstname} {mounter.lastName}</span>
        )
    }
    public render(): React.ReactNode {
        return (
            <Modal id="assignedMountersWarningModal"
                   ref={this._modalRef}
                   size={ModalSize.Default}
                   title={Localizer.assignedMountersWarningModalAssignedMounters}
            >
                <p>{Localizer.assignedMountersWarningModalMountersAreAssigned}</p>
                <p>{Localizer.assignedMountersWarningModalAssignedMounters}:
                    {
                        this.assignedMounters.map((mounter) => {
                            return this.renderContent(mounter);
                            }
                        )
                    }
                </p>

                <ButtonContainer>
                    <Button
                        label={Localizer.genericOk}
                        type={ButtonType.Info}
                        onClick={async () => await this.closeAsync()}
                    />
                </ButtonContainer>

            </Modal>
        )
            ;
    }
}