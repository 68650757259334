import React from "react";
import FormItem from "@/models/server/forms/FormItem";
import {NumberWidget, WidgetContainer} from "@renta-apps/athenaeum-react-components";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import FormPageRow from "@/components/Form/FormPageRow/FormPageRow";
import {TFormat} from "@renta-apps/athenaeum-toolkit";

import styles from "./FormResource.module.scss";

export interface IFormResourceProps {

    /**
     * NOTE: Mutated!
     */
    formItem: FormItem;
    preview?: boolean;

    /**
     * Invoked when the {@link formItem} is mutated.
     */
    onChange?(): Promise<void>;
}

interface IFormResourceState {
}

export default class FormResource extends BaseComponent<IFormResourceProps, IFormResourceState> {

    state: IFormResourceState = {
    };

    private get formItem(): FormItem {
        return this.props.formItem;
    }

    private async setValueAsync(formItem: FormItem, value: number): Promise<void> {
        formItem.value = value;

        if (this.props.onChange) {
            await this.props.onChange();
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (!this.formItem.value && this.formItem.default) {
            this.formItem.value = this.formItem.default;

            await this.reRenderAsync();
        }
    }

    public render(): React.ReactNode {
        const {preview} = this.props;
        const format: TFormat = FormItem.getFormat(this.formItem);

        return (
            <div className={styles.formResource}>

                <FormPageRow>
                    <span className={styles.title}>{this.formItem.name}</span>
                </FormPageRow>

                <WidgetContainer>
                    <NumberWidget wide
                                  format={format}
                                  step={this.formItem.valueStep || 1}
                                  min={this.formItem.min || 0}
                                  max={this.formItem.max || 20}
                                  readonly={preview}
                                  value={this.formItem.value || 0}
                                  onChange={async (_, value) => await this.setValueAsync(this.formItem, value)}
                    />
                </WidgetContainer>
            </div>
        );
    }
}