import {ActionType, BaseComponent} from "@renta-apps/athenaeum-react-common";
import React from "react";
import {CellAction, CellModel, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridOddType} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import WorkOrderType from "@/models/server/WorkOrderType";
import Region from "@/models/server/Region";
import {WorkType} from "@/models/Enums";
import RegionWorkNumber from "@/models/server/RegionWorkNumber";
import {getCellAction} from "@/helpers/GridHelpers";
import EnumProvider from "@/providers/EnumProvider";
import SaveRegionWorkNumbersRequest from "@/models/server/requests/SaveRegionWorkNumbersRequest";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";

import styles from "@/pages/WorkOrderTypeManagement/WorkOrderTypeManagement.module.scss";

interface ITypeRegionMapperProps {
    workOrderType: WorkOrderType,
}

interface ITypeRegionMapperState {
}

export default class TypeRegionMapper extends BaseComponent<ITypeRegionMapperProps, ITypeRegionMapperState> {
    state: ITypeRegionMapperState = {
    }

    private readonly _gridRef: React.RefObject<Grid<Region>> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: Localizer.genericRegion,
            accessor: "name",
        },
        this.getWorkTypeColumn(WorkType.NormalHours),
        this.getWorkTypeColumn(WorkType.Overtime50),
        this.getWorkTypeColumn(WorkType.Overtime100),
        this.getWorkTypeColumn(WorkType.Mileages),
        this.getWorkTypeColumn(WorkType.AlertJobHours),
        this.getWorkTypeColumn(WorkType.FixedCost),
        this.getWorkTypeColumn(WorkType.FixedHoursCost),
        this.getWorkTypeColumn(WorkType.FixedProductCost),
        this.getWorkTypeColumn(WorkType.FixedTransportationCost),
        this.getWorkTypeColumn(WorkType.CustomProducts),
        {
            header: Localizer.genericActions,
            init: (cell) => this.initRegionOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.genericActionSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell: CellModel<Region>, _) => await this.saveAsync(cell)
                },
                {
                    name: "cancel",
                    title: Localizer.genericActionCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell) => await cell.row.cancelAsync()
                },
            ]
        }
    ];
    
    private getWorkTypeColumn(type: WorkType): ColumnDefinition {
        return {
            header: EnumProvider.getWorkTypeText(type),
            accessor: (model: Region) => this.getWorkNumber(model, type),
            callback: async (cell: CellModel<Region>, _) => await this.onWorkNumberChange(cell, type),
            type: ColumnType.Text,
            wordBreak: true,
            settings: {
                maxLength: RentaTaskConstants.dbKeyLength
            },
     };   
    }
    
    private getWorkNumber(region: Region, workType: WorkType): string {
        const workNumber: RegionWorkNumber | undefined = region.workNumbers
            .find(x => x.workOrderTypeId === this.props.workOrderType.id && x.workType === workType);

        return workNumber?.workNumber ?? "";
    }

    private initRegionOperations(cell: CellModel<Region>) {
        const modified: boolean = cell.row.modified;

        const saveAction: CellAction<Region> = getCellAction(cell.actions, "save");
        saveAction.visible = (modified);

        const cancelAction: CellAction<Region> = getCellAction(cell.actions, "cancel");
        cancelAction.visible = (modified);
    }

    private async onWorkNumberChange(cell: CellModel<Region>, workType: WorkType): Promise<void> {
        const region: Region = cell.model;
        const workNumber: string = cell.value;
        
        let regionWorkNumber: RegionWorkNumber | undefined = region.workNumbers
            .find(x => x.workOrderTypeId === this.props.workOrderType.id && x.workType === workType);
        
        if (regionWorkNumber === undefined) {
           regionWorkNumber = {
               regionId: region.id,
               workOrderTypeId: this.props.workOrderType.id,
               workType: workType,
               workNumber: workNumber,
           } 
           
           region.workNumbers = [...region.workNumbers, regionWorkNumber];
        }

        regionWorkNumber.workNumber = workNumber;
        await cell.row.setModelAsync(region);        
    }
    
    private async saveAsync(cell: CellModel<Region>): Promise<void> {
        const request: SaveRegionWorkNumbersRequest = {
            workNumbers: cell.model.workNumbers,
        }
        
        await cell.instance.postAsync("api/region/saveRegionWorkNumbers", request);
        await cell.grid.reloadAsync();
    }

    private async fetchRegionsAsync(sender: Grid<Region>): Promise<Region[]> {
        return await sender.getAsync("api/region/getRegions");
    }

    public render(): React.ReactNode {
        return (
            <Grid
                id="typeRegionMapperGrid"
                ref={this._gridRef}
                className={styles.grid}
                minWidth="auto"
                hovering={GridHoveringType.EditableCell}
                odd={GridOddType.None}
                columns={this._columns}
                fetchData={async (sender) => this.fetchRegionsAsync(sender)}
                noDataText={Localizer.genericNoData}
            />
        );
    }
}