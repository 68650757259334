import React from "react";
import {GeoLocation, Utility} from "@renta-apps/athenaeum-toolkit";
import {ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {
    ButtonContainer,
    Button,
    ButtonType,
    Form,
    AddressDivider,
    InlineType,
    Checkbox,
    ModalSize,
    Modal,
    Dropdown,
    EmailInput,
    PhoneInput,
    TextInput,
    OneColumn,
    Inline,
    TwoColumns,
    PageContainer,
    PageHeader, PageRow, TabContainer, Tab, DropdownRequiredType
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import User from "../../models/server/User";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import {AuthType} from "@/models/Enums";
import PageDefinitions from "../../providers/PageDefinitions";
import SaveUserRequest from "../../models/server/requests/SaveUserRequest";
import SaveUserResponse from "@/models/server/responses/SaveUserResponse";
import NotificationSettings from "@/components/NotificationSettings/NotificationSettings";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "../../localization/Localizer";
import EnumProvider from "@/providers/EnumProvider";
import LocalizationHelper from "@/helpers/LocalizationHelper";

interface IAccountProps  {
}

interface IAccountState {
}

export default class Account extends AuthorizedPage<IAccountProps, IAccountState> {
    state: IAccountState = {
    };

    private readonly _agreementRef: React.RefObject<Checkbox> = React.createRef();
    private readonly _registrationRef: React.RefObject<Checkbox> = React.createRef();

    private get agreementCheckbox(): Checkbox {
        return this._agreementRef.current!;
    }

    private get registrationCheckbox(): Checkbox {
        return this._registrationRef.current!;
    }

    public getTitle(): string {
        return Localizer.topNavAccount;
    }

    public async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {

        if (!this.agreementCheckbox.checked || !this.registrationCheckbox.checked) {
            return await ch.alertErrorAsync(Localizer.myAccountPageAcceptanceRequired);
        }

        const request = new SaveUserRequest();
        request.id = this.user.id;
        request.authType = this.user.authType;
        request.externalId = this.user.externalId;

        this.copyTo(data, request, this.user);

        request.location = this.user.homeLocation;
        request.useConstructionSiteCommunicationLanguage = this.user.useConstructionSiteCommunicationLanguage;

        const response: SaveUserResponse = await this.postAsync("api/account/saveUserAccount", request);

        if (response.failed) {
            let message: string;

            if (response.emailExists && response.phoneExists) {
                message = `${Utility.format(Localizer.genericUserEmailExists, request.email)}\n${Utility.format(Localizer.genericUserPhoneExists, request.phone)}`;
            } else if (response.emailExists) {
                message = Utility.format(Localizer.genericUserEmailExists, request.email)
            } else {
                message = Utility.format(Localizer.genericUserPhoneExists, request.phone);
            }

            await this.alertErrorAsync(message, true);
            return;
        }

        const message: string = Utility.format(Localizer.myAccountPageDataSaved, TransformProvider.userToString(this.user));
        await this.alertMessageAsync(message, true);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const user: User = this.getUser();
        await this.setState({ user });
    }

    public get user(): User {
        return this.getUser();
    }

    private async redirectToPasswordPage() {
        await PageRouteProvider.redirectAsync(PageDefinitions.changePasswordRoute);
    }

    private async onLocationChangeAsync(location: GeoLocation): Promise<void> {
        this.user.homeLocation = location;
    }

    private async onCommunicationLanguageCheckboxChangeAsync(value: boolean): Promise<void> {
        this.user.useConstructionSiteCommunicationLanguage = value;
    }
    
    private async onCommunicationLanguageChangeAsync(value: number): Promise<void> {
        this.user.communicationLanguage = value;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader withTabs
                            title={Localizer.myAccountPageTitle}
                            subtitle={Localizer.myAccountPageSubtitle}
                />

                <PageRow>
                    <div className="col">
                        <TabContainer id="accountTabs">
                            <Tab id="personal"
                                 title={Localizer.myAccountPageMyInfoTabTitle}
                            >
                        {
                            (this.user) &&
                            (
                                <Form id="form" onSubmit={async (_, data) => await this.handleSubmitAsync(data)}>

                                    <TwoColumns>
                                        <EmailInput id="email"
                                                    label={Localizer.formInputEmail}
                                                    value={this.user!.email}
                                                    required={(this.user.authType == AuthType.Email)}
                                                    readonly={!!this.user.email && (this.user.authType == AuthType.Email)} />

                                        <PhoneInput id="phone"
                                                    label={Localizer.formInputPhone}
                                                    value={this.user.phone}
                                                    required={(this.user.authType == AuthType.Phone)}
                                                    readonly={!!this.user.phone && (this.user.authType == AuthType.Phone)} />
                                    </TwoColumns>

                                    <TwoColumns>
                                        <TextInput id="firstname" label={Localizer.formInputFirstname} value={this.user.firstname} required />

                                        <TextInput id="lastName" label={Localizer.formInputLastname} value={this.user.lastName} required />
                                    </TwoColumns>

                                    <TwoColumns>
                                        <TextInput id="middleName" label={Localizer.formInputMiddlename} value={this.user.middleName} />

                                        <Dropdown
                                            required
                                            autoGroupSelected
                                            requiredType={DropdownRequiredType.Restricted}
                                            id="communicationLanguage"
                                            label={Localizer.formInputLanguage}
                                            items={EnumProvider.getCommunicationLanguageItems()}
                                            selectedItem={EnumProvider.getCommunicationLanguageItem(this.user.communicationLanguage ?? LocalizationHelper.defaultLanguage)}
                                            onChange={async (_, item) => await this.onCommunicationLanguageChangeAsync(parseInt(item!.value))}
                                        />
                                    </TwoColumns>

                                    <AddressDivider required
                                                    id="location"
                                                    location={this.user.homeLocation || undefined}
                                                    onChange={async (_, location) => await this.onLocationChangeAsync(location)}
                                    />

                                    <TwoColumns>
                                        <Dropdown required
                                                  id="filtersBehavior"
                                                  label={Localizer.myAccountPageFiltersBehaviorLabel}
                                                  items={EnumProvider.getFiltersBehaviorItems()}
                                                  selectedItem={this.user.filtersBehavior} />

                                        <Checkbox label={Localizer.userManagementUseConstructionSiteCommunicationLanguage}
                                                  value={this.user.useConstructionSiteCommunicationLanguage}
                                                  onChange={async (_, value) => await this.onCommunicationLanguageCheckboxChangeAsync(value)}
                                        />
                                    </TwoColumns>
                                    <OneColumn className="mb-3">
                                        <Inline>
                                            <Checkbox ref={this._agreementRef}
                                                      id="agreementAccepted"
                                                      label={Localizer.myAccountPageAcceptance}
                                                      inline
                                                      inlineType={InlineType.Right}
                                                      value={this.user.agreementAccepted}
                                                      readonly={this.user.agreementAccepted}
                                            />

                                            <Button className={"ml-n2"}
                                                    label={Localizer.myAccountPageAcceptanceTerms}
                                                    type={ButtonType.Text}
                                                    toggleModal
                                                    dataTarget="agreementModal"
                                            />
                                        </Inline>

                                        <Inline>
                                            <Checkbox ref={this._registrationRef}
                                                      id="registrationAccepted"
                                                      label={Localizer.myAccountPageAcceptance}
                                                      inline
                                                      inlineType={InlineType.Right}
                                                      value={this.user.registrationAccepted}
                                                      readonly={this.user.registrationAccepted}
                                            />

                                            <Button className={"ml-n2"}
                                                    label={Localizer.myAccountPageAcceptancePrivacyNotice}
                                                    type={ButtonType.Text}
                                                    toggleModal
                                                    dataTarget="privacyNoticeModal"
                                            />
                                        </Inline>
                                    </OneColumn>

                                    <ButtonContainer>
                                        {
                                            (!this.user.sso) &&
                                            <Button type={ButtonType.Blue} onClick={() => this.redirectToPasswordPage()} label={Localizer.changePasswordButton}/>
                                        }

                                        <Button id="saveAccount" type={ButtonType.Orange} label={Localizer.formSave} icon={{name: "far save"}} submit />
                                    </ButtonContainer>

                                </Form>
                            )
                        }
                            </Tab>

                            {
                                ((this.isManager || this.isBusinessManager) || this.isMounter) &&
                                (
                                    <Tab id="userNotificationsSettings"
                                         title={Localizer.myAccountPageNotificationTabTitle}
                                    >
                                        <NotificationSettings user={this.user}/>

                                    </Tab>
                                )
                            }

                        </TabContainer>
                    </div>
                </PageRow>

                <Modal id="agreementModal" info keepTextFormatting
                       title={Localizer.myAccountPageAcceptanceTermsTitle}
                       content={Localizer.myAccountPageAcceptanceTermsContent}
                       size={ModalSize.Large}
                />

                <Modal id="privacyNoticeModal" info keepTextFormatting
                       title={Localizer.myAccountPageAcceptancePrivacyNoticeTitle}
                       content={Localizer.myAccountPageAcceptancePrivacyNoticeContent}
                       size={ModalSize.Large}
                />

            </PageContainer>
        );
    }
}