import {BaseTransformProvider, TFormat, Utility, GeoLocation} from "@renta-apps/athenaeum-toolkit";
import ConstructionSite from "../models/server/ConstructionSite";
import Warehouse from "../models/server/Warehouse";
import ConstructionSiteOrWarehouse from "../models/server/ConstructionSiteOrWarehouse";
import FavoriteItem from "../models/server/FavoriteItem";
import {ConstructionSiteOrWarehouseType} from "@/models/Enums";
import WorkOrderModel from "../models/server/WorkOrderModel";
import Organization from "../models/server/Organization";
import Product from "../models/server/Product";
import TaskMounter from "../models/server/TaskMounter";
import User from "../models/server/User";
import UserStatus from "../models/server/UserStatus";
import UserRole from "../models/server/UserRole";
import OrganizationContract from "../models/server/OrganizationContract";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import {IconSize, IconStyle, ITitleModel, SelectListGroup, SelectListItem, StatusListItem} from "@renta-apps/athenaeum-react-components";
import CostPool from "@/models/server/CostPool";
import ExtraChargeType from "@/models/server/ExtraChargeType";
import WorkOrderType from "@/models/server/WorkOrderType";
import FormModel from "@/models/server/forms/FormModel";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "../localization/Localizer";
import Region from "@/models/server/Region";

class TransformProvider extends BaseTransformProvider {

    protected createSelectListItem(value: string, text: string, subtext: string, groupName?: string | null, favorite?: boolean | null): SelectListItem {
        const listItem = new SelectListItem(value, text, subtext);
        listItem.favorite = (favorite == true);
        if (groupName) {
            listItem.group = SelectListGroup.create(groupName);
        }
        return listItem;
    }

    public constructor() {
        super();
    }

    public toTitle(item: any): ITitleModel {

        let label: string | null = null;
        let description: string | null = null;

        if (item != null) {

            if ((item instanceof WorkOrderModel) || (item.isWorkOrderModel)) {
                return this.toWorkOrderModelTitle(item as WorkOrderModel);
            }

            if ((item instanceof ConstructionSiteOrWarehouse) || (item.isConstructionSiteOrWarehouse)) {
                return this.toConstructionSiteOrWarehouseTitle(item as ConstructionSiteOrWarehouse);
            }

            if ((item instanceof FormModel) || (item.isFormModel === true)) {
                return this.toFormModelTitle(item as FormModel);
            }

            label = Utility.findStringValueByAccessor(item, ["label", "name"]);
            description = Utility.findStringValueByAccessor(item, ["description", "text"]);
        }

        return {
            description: description || "",
            label: label || "",
            icon: null
        };
    }

    public toWorkOrderModelTitle(item: WorkOrderModel): ITitleModel {
        const label: string = (item.owner != null)
            ? `${item.name!}, ${item.owner.name}`
            : item.name!;

        let description: string | null = item.description || "";
        if (description) {
            description = (description.length > RentaTaskConstants.maxTitleDescriptionLength)
                ? description.substr(0, RentaTaskConstants.maxTitleDescriptionLength) + "..."
                : description;
        } else {
            description = ((item.owner != null) && (item.owner.location != null))
                ? (item.code)
                    ? `#${item.code}, ${this.locationToString(item.owner.location)}`
                    : this.locationToString(item.owner.location)
                : (item.code)
                    ? `#${item.code}`
                    : "";
        }

        return {
            label: label,
            description: description,
            icon: {name: "far layer-plus"}
        };
    }

    public toConstructionSiteOrWarehouseTitle(item: ConstructionSiteOrWarehouse): ITitleModel {
        const type: string = (item.type === ConstructionSiteOrWarehouseType.ConstructionSite) ? Localizer.genericConstructionsite : Localizer.genericWarehouse;
        const address: string = this.locationToString(item.location);
        return {
            label: `${item.name}`,
            description: (address) ? `${address} \n ${type}` : type,
            icon: (item.type === ConstructionSiteOrWarehouseType.ConstructionSite)
                ? { name: "fas user-hard-hat" }
                : { name: "far forklift" }
        };
    }

    public toFormModelTitle(item: FormModel): ITitleModel {
        return {
            label: item.name,
            description: (item.workOrder)
                ? item.workOrder.name ?? ""
                : "",
            icon: {name: "fal file-chart-pie"}
        };
    }

    public workOrderModelToString(item: WorkOrderModel): string {
        return item.name!;
    }

    public productToString(item: Product): string {
        return item.name;
    }

    public extraChargeTypeToString(item: ExtraChargeType): string {
        return item.name;
    }

    public constructionSiteOrWarehouseToString(item: ConstructionSiteOrWarehouse, nameOnly: boolean = false): string {
        const address = (nameOnly) ? "" : this.locationToString(item.location);
        return (address) ? `${item.name}, ${address}` : item.name;
    }

    public costPoolToString(item: CostPool, nameOnly: boolean = false): string {
        return (!nameOnly)
            ? `${item.name}, ${item.description}`
            : item.name;
    }

    public userToString(item: User): string {
        const firstname: string = (item.firstname != null) ? item.firstname : "";
        const lastName: string = (item.lastName != null) ? item.lastName : "";
        if (firstname || lastName) {
            return `${firstname} ${lastName}`.trim();
        }
        return item.username;
    }

    public userToCalendarName(item: User): string {
        const name: string = this.userToString(item);
        
        return item.isSubcontractorMounter
            ? `${name} ${Localizer.calendarPageSubContractorMounterSuffix}`
            : name;
    }

    public constructionSiteToString(item: ConstructionSite): string {
        const address: string = this.locationToString(item.location);
        return (address)
            ? `${item.name}, ${address}`
            : item.name;
    }

    public organizationToString(item: Organization): string {
        return (item.vatId)
            ? `${item.name}, ${item.vatId}`
            : item.name;
    }

    public organizationContractToString(item: OrganizationContract): string {
        return (item.externalId)
            ? `${item.name}, ${item.externalId}`
            : `${item.name}`;
    }

    public locationToString(location: GeoLocation | null) {
        return (location != null)
            ? [location.address, location.postalCode, location.city].filter(item => !!item).join(", ")
            : "";
    }

    public workOrderTypeToString(type: WorkOrderType): string {
        return type.name ?? "";
    }
    
    public regionToString(region: Region): string {
        return region.name;
    }

    public toString(item: any, format?: TFormat | null): string {

        if (item == null) {
            return "";
        }

        if ((item instanceof ConstructionSiteOrWarehouse) || (item.isConstructionSiteOrWarehouse)) {
            return this.constructionSiteOrWarehouseToString(item as ConstructionSiteOrWarehouse);
        }

        if ((item instanceof CostPool) || (item.isCostPool)) {
            return this.costPoolToString(item as CostPool);
        }

        if ((item instanceof ConstructionSite) || (item.isConstructionSite)) {
            return this.constructionSiteToString(item as ConstructionSite);
        }

        if ((item instanceof Organization) || (item.isOrganization)) {
           return this.organizationToString(item as Organization);
        }

        if ((item instanceof OrganizationContract) || (item.isOrganizationContract)) {
            return this.organizationContractToString(item as OrganizationContract);
        }

        if ((item instanceof User) || (item.isUser === true)) {
            return this.userToString(item as User);
        }

        if ((item instanceof GeoLocation) || (item.isGeoLocation)) {
            return this.locationToString(item as GeoLocation);
        }

        if ((item instanceof WorkOrderModel) || (item.isWorkOrderModel)) {
            return this.workOrderModelToString(item as WorkOrderModel);
        }

        if ((item instanceof Product) || (item.isProduct)) {
            return this.productToString(item as Product);
        }

        if ((item instanceof ExtraChargeType)  || (item.isExtraChargeType)) {
            return this.extraChargeTypeToString(item as ExtraChargeType);
        }

        if ((item instanceof WorkOrderType) || (item.isWorkOrderType)) {
            return this.workOrderTypeToString(item as WorkOrderType);
        }

        if ((item instanceof Region) || (item.isRegion)) {
            return this.regionToString(item as Region);
        }

        return super.toString(item, format);
    }

    public toSelectListItem(item: any): SelectListItem {

        if ((item instanceof SelectListItem) || (item.isSelectListItem)) {
            return item as SelectListItem;
        }

        if ((item instanceof FavoriteItem) || (item.isFavoriteItem)) {
            return this.toFavoriteListItem(item as FavoriteItem);
        }

        if ((item instanceof ConstructionSiteOrWarehouse) || (item.isConstructionSiteOrWarehouse)) {
            return this.toConstructionSiteOrWarehouseListItem(item as ConstructionSiteOrWarehouse);
        }

        if ((item instanceof WorkOrderModel) || (item.isWorkOrderModel)) {
            return this.toWorkOrderModelListItem(item as WorkOrderModel);
        }

        if ((item instanceof ConstructionSite) || (item.isConstructionSite)) {
            return this.toConstructionSiteListItem(item as ConstructionSite);
        }

        if ((item instanceof Warehouse) || (item.isWarehouse)) {
            return this.toWarehouseListItem(item as Warehouse);
        }

        if ((item instanceof Organization) || (item.isOrganization)) {
            return this.toOrganizationListItem(item as Organization);
        }

        if ((item instanceof OrganizationContract) || (item.isOrganizationContract)) {
            return this.toOrganizationContractListItem(item as OrganizationContract);
        }

        if ((item instanceof Product) || (item.isProduct)) {
            return this.toProductListItem(item as Product);
        }

        if ((item instanceof TaskMounter) || (item.isTaskMounter)) {
            return this.toTaskMounterListItem(item as TaskMounter);
        }

        if ((item instanceof User) || (item.isUser === true)) {
            return this.toUserListItem(item as User);
        }

        if ((item instanceof UserRole) || (item.isUserRole)) {
            return this.toUserRoleListItem(item as UserRole);
        }

        if ((item instanceof UserStatus) || (item.isUserStatus)) {
            return this.toUserStatusListItem(item as UserStatus);
        }

        if ((item instanceof ExtraChargeType) || (item.isExtraChargeType)) {
            return this.toExtraChargeTypeListItem(item as ExtraChargeType);
        }

        if ((item instanceof FormModel) || (item.isFormModel)) {
            return this.toFormModelListItem(item as FormModel);
        }

        return super.toSelectListItem(item) as SelectListItem;
    }

    public toUserListItem(item: User): StatusListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = this.toString(item);
        selectedItem.subtext = item.email || item.phone;
        selectedItem.lineThrough = item.isDeleted;
        selectedItem.completed = !item.isLocked;
        if(item.role != null && item.role.group != null){
            selectedItem.group = item.isSubcontractorMounter && item.role.organizationContract?.name ? this.createSubcontractorGroupName(item) : SelectListGroup.create(EnumProvider.getUserRoleGroupName(item.role.group));
        }

        return selectedItem;
    }
    
    private createSubcontractorGroupName(item: User): SelectListGroup {
        const roleName = Localizer.get(EnumProvider.getUserRoleGroupName(item.role.group));
        return SelectListGroup.create(`${roleName} - ${item.role.organizationContract?.name}`);
    }

    public toUserRoleListItem(item: UserRole): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.roleName;
        selectedItem.text = `RoleName.${item.roleName}`;
        selectedItem.group = SelectListGroup.create(EnumProvider.getUserRoleGroupName(item.group));
        return selectedItem;
    }

    public toUserStatusListItem(item: UserStatus): StatusListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.user.id;
        selectedItem.text = this.toString(item.user);
        selectedItem.subtext = item.user.email || item.user.phone;
        selectedItem.completed = item.completed;
        selectedItem.lineThrough = item.user.isDeleted;
        return selectedItem;
    }

    public toExtraChargeTypeListItem(item: ExtraChargeType): StatusListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = this.toString(item.name);
        selectedItem.subtext = item.externalId;
        selectedItem.completed = true;

        if (item.deleted) {
            selectedItem.group = SelectListGroup.create(Localizer.genericGroupMarkedAsDeleted);
            selectedItem.icon = { name: "fal fa-exclamation-triangle", style: IconStyle.Solid, size: IconSize.Small };
        }

        return selectedItem;
    }

    public toFormModelListItem(item: FormModel): SelectListItem {
        const selectItem: SelectListItem = new SelectListItem();
        selectItem.ref = item;

        // Build a unique value, item.id does not exist for unprocessed anytime forms.
        selectItem.value = item.id + item.formDefinitionId;
        
        const text: string = item.processed
            ? `${item.name} (${this.userToString(item.user!)}, ${Utility.toDateString(item.processedAt!)})`
            : item.name;
        selectItem.text = text;
        selectItem.subtext = item.description ?? "";
        selectItem.group = SelectListGroup.create(item.processed ? Localizer.workOrderFormsCompletedFormsTab : Localizer.workOrderFormsActiveFormsTab);

        return selectItem;
    }

    public toFavoriteListItem(item: FavoriteItem): SelectListItem {
        let selectedItem: SelectListItem | null = null;

        if (item.constructionSite != null) {
            selectedItem = this.toConstructionSiteListItem(item.constructionSite);
        } else if (item.warehouse != null) {
            selectedItem = this.toWarehouseListItem(item.warehouse);
        }

        if (selectedItem == null) {
            selectedItem = this.toSelectListItem(item);
        }

        selectedItem!.favorite = item.favorite;

        return selectedItem!;
    }

    public toConstructionSiteListItem(item: ConstructionSite): SelectListItem {
        const customer: string | null = item.organizationContract?.name ?? item.organizationContract?.organization?.name ?? null;
        const address: string = this.locationToString(item.location);
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name || address;
        selectedItem.subtext = (customer) ? `${customer}, ${address}` : address;
        return selectedItem;
    }

    public toWarehouseListItem(item: Warehouse): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name;
        selectedItem.subtext = this.locationToString(item.location);
        return selectedItem;
    }

    public toConstructionSiteOrWarehouseListItem(item: ConstructionSiteOrWarehouse): SelectListItem {
        const customer: string | null = item.organizationContract?.name ?? item.organizationContract?.organization?.name ?? null;
        const address: string = this.locationToString(item.location);
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name || address;
        selectedItem.subtext = (customer) ? `${customer}\n${address}` : address;
        selectedItem.favorite = item.favorite;
        selectedItem.group = (item.type === ConstructionSiteOrWarehouseType.ConstructionSite)
            ? SelectListGroup.create(Localizer.dropdownGroupConstructionSitesLanguageItemName)
            : SelectListGroup.create(Localizer.dropdownGroupWarehousesLanguageItemName);
        return selectedItem;
    }

    public toWorkOrderModelListItem(item: WorkOrderModel): SelectListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = this.workOrderModelToString(item);
        const activationDate: string = Utility.format("{0:D}", item.activationDate);
        const completed: string = (item.completed) ? ", completed" : "";
        selectedItem.subtext = `#${item.code}, ${item.mountersIds.length} mounters, ${activationDate}${completed}`;
        selectedItem.lineThrough = item.deleted;
        selectedItem.completed = item.locked;
        return selectedItem;
    }

    public toOrganizationListItem(item: Organization): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name || item.vatId;
        selectedItem.subtext = item.vatId;
        selectedItem.favorite = item.favorite;
        return selectedItem;
    }

    public toOrganizationContractListItem(item: OrganizationContract): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name || item.externalId || "";
        selectedItem.subtext = item.externalId || "";
        return selectedItem;
    }

    public toProductListItem(item: Product): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name;
        if (item.category !== null) {
            selectedItem.group = new SelectListGroup();
            selectedItem.group.name = (item.category.parent != null)
                    ? `${item.category.parent.name} / ${item.category.name}`
                    : item.category.name;
        }
        return selectedItem;
    }

    public toTaskMounterListItem(item: TaskMounter): SelectListItem {
        const selectedItem = new SelectListItem();
        const user: User = item.user!;
        selectedItem.ref = item;
        selectedItem.value = user.id;
        selectedItem.text = this.userToString(user);
        selectedItem.subtext = `${item.workingPlace !== null ? 1 : 0}/${item.tasks} tasks`;
        selectedItem.group = SelectListGroup.create(EnumProvider.getUserRoleGroupName(item.user.role.group));
        if (item.workingPlace !== null) {
            selectedItem.group = new SelectListGroup();
            selectedItem.group.name = this.constructionSiteOrWarehouseToString(item.workingPlace);
        }
        return selectedItem;
    }
}

export default new TransformProvider();