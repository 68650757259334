import React from "react";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import {ButtonContainer, Button, ButtonType, Form, IStringInputModel, EmailInput, TextAreaInput, TextInput, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";

import AnonymousPage from "../../models/base/AnonymousPage";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import User from "../../models/server/User";
import Localizer from "../../localization/Localizer";

export interface IContactSupportParameters extends BasePageParameters {
    requestId: string | null;
}

export default class ContactSupport extends AnonymousPage<IContactSupportParameters> {

    public email: IStringInputModel = { value: "" };
    public phone: IStringInputModel = { value: "" };
    public firstname: IStringInputModel = { value: "" };
    public lastname: IStringInputModel = {value: ""};
    public message: IStringInputModel = {value: ""};
    public formRef: React.RefObject<any> = React.createRef();

    public async initializeAsync(): Promise<void> {
        const user: User | null = this.findUser();
        if (user) {
            this.email.value = user.email || "";
            this.phone.value = user.phone || "";
            this.firstname.value = user.firstname || "";
            this.lastname.value = user.lastName || "";
        }
    }

    public async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {
        const contactSupportRequest = {} as any;
        data.keys().map(key => {
            return contactSupportRequest[key] = data.getValue(key);
        });

        await this.postAsync("api/ContactSupport/ContactSupport", contactSupportRequest);
    }

    public get requestId(): string | null {
        return (this.props.parameters != null) ? this.props.parameters.requestId : null;
    }

    public getTitle(): string {
        return Localizer.topNavContactSupport;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.contactSupportPageTitle} />

                <PageRow>
                    <div className="col-lg-12">
                        <Form id="form" onSubmit={async (_, data) => await this.handleSubmitAsync(data)} ref={this.formRef}>
                            <EmailInput id="email" label={Localizer.formInputEmail} model={this.email} required readonly={!!this.email.value} />
                            <TextInput id="phone" label={Localizer.formInputPhone} model={this.phone} />
                            <TextInput id="firstname" label={Localizer.formInputFirstname} model={this.firstname} />
                            <TextInput id="lastname" label={Localizer.formInputLastname} model={this.lastname} />
                            <TextAreaInput id="message" label={Localizer.formInputMessage} model={this.message} required rows={6} />

                            <ButtonContainer>
                                <Button id="sendSupport" type={ButtonType.Orange} label={Localizer.formSend} submit />
                            </ButtonContainer>
                        </Form>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}