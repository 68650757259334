import React from "react";
import {Button, ButtonType, DateInput, Dropdown, DropdownAlign, DropdownOrderBy, IconSize, Inline, SelectListItem, Spinner, ToolbarContainer} from "@renta-apps/athenaeum-react-components";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import NotificationWorkOrdersStatusesFiltersData from "@/models/server/NotificationWorkOrdersStatusesFiltersData";
import ToolbarModel from "@/pages/Notifications/Toolbar/ToolbarModel";
import User from "@/models/server/User";
import {NotificationType, WorkOrderStatus} from "@/models/Enums";
import ConstructionSite from "@/models/server/ConstructionSite";
import OrganizationContract from "@/models/server/OrganizationContract";
import Localizer from "@/localization/Localizer";

import styles from "./Toolbar.module.scss";
import EnumProvider from "@/providers/EnumProvider";

interface IToolbarProps  {
    notificationId: string;
    notificationType: NotificationType;
    model: ToolbarModel;
    onChange?(model: ToolbarModel): Promise<void>;
}

interface IToolbarState {
    model: ToolbarModel;
    filtersData: NotificationWorkOrdersStatusesFiltersData;
}

export default class Toolbar extends BaseComponent<IToolbarProps, IToolbarState> {

    state: IToolbarState = {
        model: this.props.model || new ToolbarModel(),
        filtersData: new NotificationWorkOrdersStatusesFiltersData()
    };

    private get model(): ToolbarModel {
        return this.state.model;
    }

    private get filtersData(): NotificationWorkOrdersStatusesFiltersData {
        return this.state.filtersData;
    }

    private get userFilterLabel(): string {
        switch (this.props.notificationType) {
            case NotificationType.WorkOrdersProcessedApproval:
                return Localizer.genericApprovedOrDeclinedBy;

            case NotificationType.WorkOrdersPendingApproval:
                return Localizer.genericSentBy;

            case NotificationType.WorkOrderReactivation:
            default: throw new Error(`Unsupported notification type ${this.props.notificationType}`);
        }
    }

    private get statusesFilter(): SelectListItem[] {
        return (this.props.notificationType == NotificationType.WorkOrdersProcessedApproval)
            ? [EnumProvider.getWorkOrderStatusItem(WorkOrderStatus.ApprovedByCustomer),
                EnumProvider.getWorkOrderStatusItem(WorkOrderStatus.DeclinedByCustomer)]
            : [];
    }

    private async processOnChange(invoke: boolean = false): Promise<void> {
        await this.setState({ model: this.model });
        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this.model);
        }
    }

    private async setCustomersFilterAsync(customerIds: string[]): Promise<void> {
        this.model.customerIds = customerIds;
        await this.processOnChange(true);
    }

    private async setConstructionSitesFilterAsync(constructionSiteIds: string[]): Promise<void> {
        this.model.constructionSiteIds = constructionSiteIds;
        await this.processOnChange(true);
    }

    private async setUsersFilterAsync(user: User | null): Promise<void> {
        this.model.user = user;
        await this.processOnChange(true);
    }

    private async setStatusFilterAsync(item: SelectListItem | null): Promise<void> {
        this.model.status = (item) ? parseInt(item.value) : null;
        await this.processOnChange(true);
    }

    private async setFromFilterAsync(from: Date | null): Promise<void> {
        if (this.model.from !== from) {

            this.model.from = from?.date()?.addMilliseconds(1) || null;
            await this.processOnChange(true);
        }
    }

    private async setToFilterAsync(to: Date | null): Promise<void> {
        if (this.model.to !== to) {

            this.model.to = to?.date()?.addDays(1)?.addMilliseconds(-1) || null;
            await this.processOnChange(true);
        }
    }

    private async clearAsync(): Promise<void> {
        this.state.model = new ToolbarModel();
        await this.processOnChange(true);
    }

    public hasSpinner(): boolean {
        return true;
    }

    public isAsync(): boolean {
        return true;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const filtersData: NotificationWorkOrdersStatusesFiltersData = await this.postAsync("api/notification/getNotificationWorkOrdersStatusesFiltersData", this.props.notificationId);

        await this.setState({filtersData});
    }

    public render(): React.ReactNode {

        return (
            <ToolbarContainer className={styles.toolbar}>

                <Inline>

                    <Dropdown noValidate noSubtext noWrap multiple autoGroupSelected
                              align={DropdownAlign.Left}
                              label={"EN Customer"}
                              minWidth="180px"
                              orderBy={DropdownOrderBy.None}
                              items={this.filtersData.customers}
                              selectedItems={this.model.customerIds || []}
                              onChange={async (sender: Dropdown<OrganizationContract>) => await this.setCustomersFilterAsync(sender.selectedValues)}
                    />

                    <Dropdown noValidate noSubtext noWrap multiple autoGroupSelected
                              align={DropdownAlign.Left}
                              label={Localizer.genericConstructionsite}
                              minWidth="180px"
                              orderBy={DropdownOrderBy.None}
                              items={this.filtersData.constructionSites || []}
                              selectedItems={this.model.constructionSiteIds}
                              onChange={async (sender: Dropdown<ConstructionSite>) => await this.setConstructionSitesFilterAsync(sender.selectedValues)}
                    />

                    <Dropdown noValidate noSubtext noWrap autoGroupSelected
                              label={this.userFilterLabel}
                              minWidth="180px"
                              align={DropdownAlign.Left}
                              orderBy={DropdownOrderBy.None}
                              items={this.filtersData.users}
                              selectedItem={this.model.user}
                              onChange={async (_, user: User | null) => await this.setUsersFilterAsync(user)}
                    />

                    {
                        (this.props.notificationType == NotificationType.WorkOrdersProcessedApproval) &&
                        (
                            <Dropdown noValidate noSubtext noWrap noFilter
                                      label={Localizer.genericStatus}
                                      minWidth="180px"
                                      align={DropdownAlign.Left}
                                      orderBy={DropdownOrderBy.None}
                                      items={this.statusesFilter}
                                      selectedItem={this.model.status}
                                      onChange={async (_, item: SelectListItem | null) => await this.setStatusFilterAsync(item)}
                            />
                        )
                    }

                    <DateInput small rentaStyle
                               id="notificationToolbarFrom"
                               label={Localizer.genericFrom}
                               maxDate={this.model.to}
                               value={this.model.from || undefined}
                               onChange={async (date: Date) => await this.setFromFilterAsync(date)}
                    />

                    <DateInput small rentaStyle
                               id="notificationToolbarTo"
                               label={Localizer.genericTo}
                               minDate={this.model.from}
                               value={this.model.to || undefined}
                               onChange={async (date: Date) => await this.setToFilterAsync(date)}
                    />

                    <Button className={styles.clearButton}
                            title={Localizer.tasksToolbarClearFilters}
                            icon={{name: "far history", size: IconSize.Large }}
                            type={ButtonType.Info}
                            onClick={async () => await this.clearAsync()}
                    />

                </Inline>

                {
                    (this.isSpinning()) && <Spinner />
                }

            </ToolbarContainer>
        );
    }
};