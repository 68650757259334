import {WorkOrderStatus} from "@/models/Enums";
import User from "@/models/server/User";

export default class ToolbarModel {

    public customerIds: string[] = [];

    public constructionSiteIds: string[] = [];

    public status: WorkOrderStatus | null = null;
    
    public user: User | null = null;

    public from: Date | null = null;

    public to: Date | null = null;
}