import {FileModel, Utility} from "@renta-apps/athenaeum-toolkit";
import {ApiProvider, ch, DataStorageType, IBaseComponent, ILayoutPage, PageCacheProvider, PageRoute, PageRouteProvider, UserInteractionDataStorage} from "@renta-apps/athenaeum-react-common";
import MounterContext from "./Models/MounterContext";
import WorkOrderModel from "../../models/server/WorkOrderModel";
import PageDefinitions from "../../providers/PageDefinitions";
import Comparator from "../../helpers/Comparator";
import UserContext from "../../models/server/UserContext";
import CreateWorkOrderRequest from "../../models/server/requests/CreateWorkOrderRequest";
import TaskCheckOutResponse from "@/models/server/responses/TaskCheckOutResponse";
import RentaTaskConstants from "../../helpers/RentaTaskConstants";
import ConstructionSiteOrWarehouse from "@/models/server/ConstructionSiteOrWarehouse";
import UserSalaryHour from "@/models/server/UserSalaryHour";
import {CustomerApprovalType, MounterPermission, WorkOrderStatus} from "@/models/Enums";
import WizardContext from "@/pages/RentaTasks/Models/WizardContext";
import User from "@/models/server/User";
import SaveWorkOrderDataResponse from "@/models/server/responses/SaveWorkOrderDataResponse";
import BaseConcurrencyResponse from "@/models/server/responses/BaseConcurrencyResponse";
import CompleteWorkOrderResponse from "@/models/server/responses/CompleteWorkOrderResponse";
import ApproveWorkOrderResponse from "@/models/server/responses/ApproveWorkOrderResponse";
import {IIconProps, ITitleModel, IWizardStep, IWizardSteps} from "@renta-apps/athenaeum-react-components";
import ActivateInactiveConstructionSiteRequest from "@/models/server/requests/ActivateInactiveConstructionSiteRequest";
import GetWorkOrderRequest from "@/models/server/requests/GetWorkOrderRequest";
import Product from "@/models/server/Product";
import {PostAsync} from "@/types/PostAsync";
import WorkOrderType from "@/models/server/WorkOrderType";
import {IFormPageProps} from "@/pages/RentaTasks/FormPage/FormPage";
import FormItem from "@/models/server/forms/FormItem";
import FormModel from "@/models/server/forms/FormModel";
import SaveFormRequest from "@/models/server/requests/SaveFormRequest";
import FormContent from "@/models/server/forms/FormContent";
import CompleteWorkOrderRequest from "@/models/server/requests/CompleteWorkOrderRequest";
import TaskCheckOutRequest from "../../models/server/requests/TaskCheckOutRequest";
import SaveWorkOrderDataRequest from "../../models/server/requests/SaveWorkOrderDataRequest";
import ApproveWorkOrderRequest from "../../models/server/requests/ApproveWorkOrderRequest";
import SignInRequest from "../../models/server/requests/SignInRequest";
import SignOutRequest from "../../models/server/requests/SignOutRequest";
import UserSignatureModel from "@/models/server/UserSignatureModel";
import GetEmployeesRequest from "@/models/server/requests/GetEmployeesRequest";
import TransformProvider from "../../providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import throwIfFalsy from "@/functions/ThrowIfFalsy";
import CostPool from "@/models/server/CostPool";


/**
 * Action performable in "RentaTasks" folders wizards.
 */
export enum RentaTasksAction {
    None = 0,

    SignOut = 1,

    NewWorkOrder = 2,

    EditWorkOrder = 3,

    AddEquipment = 4,

    CompleteWorkOrder = 5,

    ActivateConstructionSite = 6,

    EditHoursAndDistances = 7,

    Form = 8,

    PreviewDetails = 9
}

export interface IWizardNextStep {
    nextRoute: PageRoute;

    firstStep: boolean;

    lastStep: boolean;
}

export interface IFormWizardStep extends IWizardStep {
    isFormWizardStep: boolean;
}

/**
 * Controller of "RentaTasks" folders wizards.
 */
class RentaTasksController {
    private _mounterContextKey: string | null = null;
    private _mounterContext: MounterContext | null = null;
    private _signatureSrc: string | null = null;
    private _nameClarification: string | null = null;

    /**
     * @returns The key where the {@link MounterContext} is stored in local storage.
     */
    private get mounterContextKey(): string {
        if (!this._mounterContextKey) {
            this._mounterContextKey = `$${RentaTaskConstants.applicationName}.${RentaTasksController.userContext.email}.${RentaTasksController.name}`;
        }

        return this._mounterContextKey!;
    }

    private invokeIndexOfCurrentStep(wizardSteps: IWizardSteps | null, route: PageRoute): number {
        return (wizardSteps)
            ? wizardSteps.steps.findIndex(step => Comparator.isEqual(step.route, route))
            : -1;
    }
    private indexOfCurrentStep(route: PageRoute): number {
        const wizardSteps: IWizardSteps | null = this.getWizardSteps();
        return this.invokeIndexOfCurrentStep(wizardSteps, route);
    }

    private async postAsync<TResponse>(endpoint: string, request: any | null = null): Promise<TResponse> {
        const layout: ILayoutPage = ch.getLayout();
        return await ApiProvider.postAsync<TResponse>(endpoint, request, layout);
    }

    async uploadUserSignature(signatureModel: UserSignatureModel, canvasData: string): Promise<void> {
        const fileModel: FileModel = new FileModel();
        fileModel.src = canvasData;
        fileModel.name = 'signature';
        const file: FileModel = await this.convertImageAsync(fileModel, true);
        signatureModel.file = file;
    }

    public async initializeAsync(): Promise<void> {
        EnumProvider.initialize();
        TransformProvider.initialize();
        PageDefinitions.initialize();
    }

    public async saveFormAsync(completeAction: boolean): Promise<void> {
        const form: FormModel | null = this.mounterContext.form;

        if (form) {
            const signatureSrc: string | null = this._signatureSrc;
            const nameClarification: string | null = this._nameClarification;

            form.passed = FormModel.isPassed(form);
            form.processed = FormModel.isProcessed(form);
            form.processedAt = Utility.utcNow();
            
            if (form.usersSignatures) {
                form.usersSignatures = form.usersSignatures.filter(signature => !!signature.file);
                
                form.usersSignatures = form.usersSignatures.map(signature => {
                    // Remove unnecessary data before sending request so the payload doesn't get too big.
                    signature.user = signature.user != null
                        ? User.RemoveComplexData(signature.user)
                        : null;
                    
                    return signature;
                })
            }

            const request: SaveFormRequest = {
                signatureSrc: signatureSrc,
                nameClarification: nameClarification,
                completeAction: completeAction,
                form: form,
            };

            await this.postAsync("api/rentaTasks/saveForm", request);
        }
    }

    public async authorizeAsync(): Promise<void> {
    }

    public async onLogoClickAsync(): Promise<void> {
        let route: PageRoute = PageDefinitions.homeRoute;

        if (ch.isAuthenticated) {
            const userContext: UserContext = RentaTasksController.userContext;

            if (userContext.isMounter || userContext.isMobileManager) {
                route = PageDefinitions.rentaTasksRoute;
            } else {
                route = PageDefinitions.dashboardRoute;
            }
        }

        await PageRouteProvider.redirectAsync(route, false, true);
    }

    /**
     * Save the current {@link MounterContext} to the local storage.
     */
    public saveMounterContext(): void {
        const json: string = JSON.stringify(this.mounterContext);
        window.localStorage.setItem(this.mounterContextKey, json);
    }

    /**
     * @returns The current {@link MounterContext}. If undefined, the saved context restored from local storage, or a new one if a saved context does not exist.
     * @see RentaTasksController.wizardContext
     */
    public get mounterContext(): MounterContext {
        if (!this._mounterContext) {
            const json: string | null = window.localStorage.getItem(this.mounterContextKey);

            this._mounterContext = MounterContext.restore(json);
        }

        return this._mounterContext;
    }

    /** Returns true if current user is a manager, business manager or an admin. */
    public get isManagerOrHigher(): boolean {
        const userContext: UserContext = RentaTasksController.userContext;
        return userContext.isAdmin || userContext.isBusinessManager || userContext.isManager;
    }

    /** Returns true if current user is a business manager or an admin. */
    public get isBusinessManagerOrHigher(): boolean {
        const userContext: UserContext = RentaTasksController.userContext;
        return userContext.isAdmin || userContext.isBusinessManager;
    }

    /**
     * @returns {@link MounterContext.wizard}
     * @see RentaTasksController.mounterContext
     */
    public get wizardContext(): WizardContext {
        return this.mounterContext.wizard;
    }

    /**
     * Set the current {@link MounterContext.wizard} to the given value.
     *
     * @see RentaTasksController.mounterContext
     */
    private setWizardContext(wizardContext: WizardContext) {
        throwIfFalsy(wizardContext, nameof(wizardContext));

        this.mounterContext.wizard = wizardContext;
    }

    /** @returns {@link ch.getContext} */
    private static get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    /** @returns {@link MounterContext.isSignedIn} */
    public get isSignedIn(): boolean {
        return this.mounterContext.isSignedIn;
    }

    /** @returns {@link MounterContext.isCheckedIn} */
    public get isCheckedIn(): boolean {
        return this.mounterContext.isCheckedIn;
    }

    /** @returns {@link MounterContext.workOrderId} */
    public get checkedInWorkOrderId(): string | null {
        return this.mounterContext.workOrderId;
    }

    public get permissions(): MounterPermission[] {
        const userContext: UserContext = RentaTasksController.userContext;

        if (userContext.mounterPermissions === undefined) {
            userContext.mounterPermissions = [];
        }
        return userContext.mounterPermissions;
    }

    public can(permission: MounterPermission) {
        return this.permissions.includes(permission);
    }

    public get wizardTitle(): ITitleModel | undefined {
        const wizardContext: WizardContext = this.wizardContext;
        const wizardContextWorkOrder: WorkOrderModel | null = this.wizardContextWorkOrder;

        if (wizardContextWorkOrder) {
            if (wizardContext.action == RentaTasksAction.NewWorkOrder) {
                const workOrder: WorkOrderModel = new WorkOrderModel();

                workOrder.name = wizardContextWorkOrder.name || Localizer.rentaTasksControllerNewWorkOrderName;

                workOrder.owner = wizardContext.owner;

                return TransformProvider.toTitle(workOrder);
            }

            return TransformProvider.toTitle(wizardContextWorkOrder);
        }

        if (wizardContext.owner) {
            return TransformProvider.toTitle(wizardContext.owner);
        }

        return undefined;
    }

    public get signInTitle(): ITitleModel | undefined {
        if (this.isSignedIn) {
            if (this.mounterContextWorkOrder) {
                return TransformProvider.toTitle(this.mounterContextWorkOrder);
            }
        }

        return undefined;
    }

    /**
     * @returns {@link MounterContext.workOrder}
     * @see RentaTasksController.wizardContextWorkOrder
     */
    public get mounterContextWorkOrder(): WorkOrderModel | null {
        return this.mounterContext.workOrder;
    }

    /**
     * @returns {@link WizardContext.workOrder}
     * @see RentaTasksController.mounterContextWorkOrder
     */
    public get wizardContextWorkOrder(): WorkOrderModel | null {
        return this.wizardContext.workOrder;
    }

    public get form(): FormModel | null {
        return this.mounterContext.form
    }

    public get formItem(): FormItem | null {
        if (this.form != null) {
            const route: PageRoute = ch.getPage().route;
            const index: number = this.indexOfCurrentStep(route);
            
            if ((index >= 0) && (index < this.form.items.length)) {
                return this.form.items[index];
            }
        }

        return null;
    }

    public set signatureSrc(value: string | null){
        this._signatureSrc = value
    }

    public set nameClarification(value: string | null){
        this._nameClarification = value
    }

    /**
     * Set the current {@link WizardContext.workOrder} to the given value.
     *
     * @see RentaTasksController.mounterContextWorkOrder
     */
    public setWizardContextWorkOrder(workOrder: WorkOrderModel): void {
        throwIfFalsy(workOrder, nameof(workOrder));

        this.wizardContext.workOrder = workOrder;
    }

    public async startActionAsync(action: RentaTasksAction, failedMessage: string | null = null): Promise<boolean> {
        const currentWizardContextWorkOrder: WorkOrderModel | null = this.wizardContextWorkOrder;
        const currentPageRoute: PageRoute = ch.getPage().route;
        const currentOwner: ConstructionSiteOrWarehouse | null = this.wizardContext.owner;

        const newWizardContext: WizardContext = new WizardContext();

        newWizardContext.workOrder = currentWizardContextWorkOrder;
        newWizardContext.owner = currentOwner;
        newWizardContext.action = action;
        newWizardContext.actionInitialPageRoute = currentPageRoute;

        if (action == RentaTasksAction.NewWorkOrder) {
            const newWorkOrder: WorkOrderModel = new WorkOrderModel();

            newWorkOrder.activationDate = Utility.today();

            newWizardContext.owner = this.mounterContextWorkOrder?.owner ?? null;

            newWizardContext.workOrder = newWorkOrder;
        }

        if ((action == RentaTasksAction.SignOut) && (this.mounterContextWorkOrder)) {
            // Start sign-out wizard
            newWizardContext.workOrder = this.mounterContextWorkOrder;
            newWizardContext.owner = this.mounterContextWorkOrder.owner;
            newWizardContext.addEquipment = true;
        }

        if ((action == RentaTasksAction.ActivateConstructionSite)) {
            // Selected in wizard
            newWizardContext.owner = null;
            newWizardContext.organization = null;
        }

        this.setWizardContext(newWizardContext);

        await this.reloadWizardContextWorkOrderAsync();

        this.saveMounterContext();

        UserInteractionDataStorage.set(
            "initialWorkOrderHashCode",
            Utility.getHashCode(currentWizardContextWorkOrder!),
            DataStorageType.Session
        );

        if (!this.canAction(action, newWizardContext.workOrder)) {

            this.setWizardContext(new WizardContext());

            this.saveMounterContext();

            if (failedMessage) {
                await ch.alertErrorAsync(failedMessage);
            }

            return false;
        }

        if ((action == RentaTasksAction.SignOut) && (!this.canSignOut(newWizardContext.workOrder, true))) {

            await this.checkOutAsync(false);

            if (failedMessage) {
                await ch.alertErrorAsync(failedMessage);
            }

            return false;
        }

        const nextStep: IWizardNextStep = await this.getNextStepAsync(currentPageRoute);

        await PageRouteProvider.redirectAsync(nextStep.nextRoute);

        return true;
    }

    public async completeActionAsync(save: boolean = false): Promise<void> {
        if (save) {
            const action: RentaTasksAction = this.wizardContext.action;

            if (action == RentaTasksAction.SignOut) {
                await this.checkOutAsync();
                await ch.alertMessageAsync(Localizer.rentaTasksWorkOrderSignOutAlert, true);
            }

            if (action == RentaTasksAction.NewWorkOrder) {
                await this.createWorkOrderFromWizardContextAsync();
            }

            if (action == RentaTasksAction.EditWorkOrder) {
                await this.saveWorkOrderDataFromWizardContextAsync();
                await ch.alertMessageAsync(Localizer.rentaTasksWorkOrderSaveAlert, true);
            }

            if (action == RentaTasksAction.AddEquipment) {
                await this.saveWorkOrderDataFromWizardContextAsync();
                await ch.alertMessageAsync(Localizer.rentaTasksWorkOrderUpdateEquipmentAlert, true);
            }

            if (action == RentaTasksAction.EditHoursAndDistances) {
                await this.saveWorkOrderDataFromWizardContextAsync();
                await ch.alertMessageAsync(Localizer.rentaTasksWorkOrderEditHoursAndDistancesAlert, true);
            }

            if (action == RentaTasksAction.CompleteWorkOrder) {

                const wizardContextWorkOrder: WorkOrderModel | null = this.wizardContextWorkOrder;

                if (wizardContextWorkOrder) {
                    const myWorkOrder: boolean = (this.isCheckedIn) && (this.mounterContext.workOrderId == wizardContextWorkOrder.id);

                    if (myWorkOrder) {
                        await this.checkOutAsync();
                    } else {
                        await this.saveWorkOrderDataFromWizardContextAsync();
                    }

                    if ((wizardContextWorkOrder.currentStatus == WorkOrderStatus.Created) || (wizardContextWorkOrder.currentStatus == WorkOrderStatus.InProgress)) {
                        await this.completeWorkOrderFromWizardContextAsync();
                    }

                    await this.approveWorkOrderFromWizardContextAsync();

                    await ch.alertMessageAsync(Localizer.rentaTasksWorkOrderCompleteAlert, true);
                }
            }

            if (action == RentaTasksAction.ActivateConstructionSite) {
                const owner: ConstructionSiteOrWarehouse | null = this.wizardContext.owner;

                if (!owner) {
                    throw new Error("Owner required but missing.");
                }

                if (!owner.location) {
                    throw new Error("Location required but missing.");
                }

                const request: ActivateInactiveConstructionSiteRequest = {
                    constructionSiteId: owner.id,
                    constructionSiteName: owner.name,
                    location: owner.location
                }

                await this.postAsync("api/rentaTasks/activateInactiveConstructionSite", request);
                await ch.alertMessageAsync(Localizer.rentaTasksWorkOrderActivateConstructionSiteAlert, true);
            }
        }

        this.setWizardContext(new WizardContext());

        this.saveMounterContext();
    }

    private canAction(action: RentaTasksAction, workOrder: WorkOrderModel | null): boolean {
        switch (action) {
            case RentaTasksAction.SignOut:
                return this.canSignOut(workOrder);
            case RentaTasksAction.CompleteWorkOrder:
                return this.canComplete(workOrder);
            case RentaTasksAction.EditWorkOrder:
                return this.canEdit(workOrder);
            case RentaTasksAction.AddEquipment:
                return this.canAddEquipment(workOrder);
            case RentaTasksAction.EditHoursAndDistances:
                return this.canEditHoursAndDistances(workOrder);
            default:
                return true;
        }
    }

    public canActivate(workOrder: WorkOrderModel | null): boolean {
        return (workOrder != null) &&
            (!workOrder.deleted) &&
            (this.isManagerOrHigher) &&
            (workOrder.currentStatus == WorkOrderStatus.DeclinedByCustomer);
    }

    public canSignIn(workOrder: WorkOrderModel | null): boolean {
        return (workOrder != null) &&
            (!workOrder.deleted) &&
            (
                (workOrder.currentStatus == WorkOrderStatus.Created) ||
                (workOrder.currentStatus == WorkOrderStatus.InProgress)
            ) &&
            (!this.isSignedIn);
    }

    public canSignOut(workOrder: WorkOrderModel | null, checkWorkOrderStatus: boolean = false): boolean {
        return (!!workOrder) &&
            (!!this.mounterContextWorkOrder) &&
            (workOrder.id == this.mounterContextWorkOrder.id) &&
            (this.isSignedIn) &&
            (
                (!checkWorkOrderStatus) ||
                ([WorkOrderStatus.Created, WorkOrderStatus.InProgress, WorkOrderStatus.Completed].includes(workOrder.currentStatus))
            );
    }

    public canComplete(workOrder: WorkOrderModel | null): boolean {
        return (workOrder != null) && (!workOrder.deleted) &&
            (WorkOrderModel.getEditability(workOrder).editable) && !workOrder.hasBlockingForms;
    }

    public canConductForms(workOrder: WorkOrderModel | null): boolean {
        return (workOrder != null) && (WorkOrderModel.getActions(workOrder, false, false).forms);
    }

    public canEdit(workOrder: WorkOrderModel | null): boolean {
        return (workOrder != null) && (!workOrder.deleted) &&
            (WorkOrderModel.getEditability(workOrder).editable);
    }

    public canAddEquipment(workOrder: WorkOrderModel | null): boolean {
        return (this.canEdit(workOrder));
    }

    public canEditHoursAndDistances(workOrder: WorkOrderModel | null): boolean {
        return (this.canEdit(workOrder));
    }

    /**
     * Complete in server a Work Order with values from current {@link WizardContext}.
     * Does nothing if the current {@link wizardContextWorkOrder} is null.
     */
    private async completeWorkOrderFromWizardContextAsync(): Promise<void> {
        const wizardContextWorkOrder: WorkOrderModel | null = this.wizardContextWorkOrder;

        if (wizardContextWorkOrder) {
            const request: CompleteWorkOrderRequest = new CompleteWorkOrderRequest();

            request.lastModifiedAt = wizardContextWorkOrder.modifiedAt;
            request.workOrderId = wizardContextWorkOrder.id;

            const response: CompleteWorkOrderResponse = await this.postAsync("api/rentaTasks/completeWorkOrder", request);

            await this.checkConcurrencyResponseAsync(response, response.workOrder);
        }
    }

    /**
     * Approve in server a Work Order with values from current {@link WizardContext}.
     * Does nothing if the current {@link wizardContextWorkOrder} is null.
     */
    private async approveWorkOrderFromWizardContextAsync(): Promise<void> {
        const wizardContext: WizardContext = this.wizardContext;
        const wizardContextWorkOrder: WorkOrderModel | null = this.wizardContextWorkOrder;

        if (wizardContextWorkOrder) {
            const approvalType: CustomerApprovalType = wizardContext.approvalType;

            const request: ApproveWorkOrderRequest = new ApproveWorkOrderRequest();

            request.lastModifiedAt = wizardContextWorkOrder.modifiedAt;
            request.workOrderId = wizardContextWorkOrder.id;
            request.invoiceReference = wizardContextWorkOrder.invoiceReference;
            request.customerApprover = wizardContextWorkOrder.customerApprover;
            request.approvalType = approvalType;

            if (approvalType === CustomerApprovalType.Signature) {
                request.signatureSrc = wizardContext.signatureSrc;
                request.signatureNameClarification = wizardContext.signatureNameClarification;
            }

            const response: ApproveWorkOrderResponse = await this.postAsync("api/rentaTasks/approveWorkOrder", request);

            await this.checkConcurrencyResponseAsync(response, response.workOrder);

            const message: string = (wizardContext.approvalType == CustomerApprovalType.Phone)
                ? Localizer.rentaTasksControllerAlertsApproved.format(wizardContextWorkOrder)
                : Localizer.rentaTasksControllerAlertsSentToCustomer.format(wizardContextWorkOrder);

            await ch.alertErrorAsync(message, true);
        }
    }

    /**
     * Create in server a new Work Order from the current {@link WizardContext}.
     * Does nothing if the current {@link wizardContextWorkOrder} is null, has no name or the current {@link WizardContext.owner} is null.
     */
    private async createWorkOrderFromWizardContextAsync(): Promise<void> {
        const owner: ConstructionSiteOrWarehouse | null = this.wizardContext.owner;
        const wizardContextWorkOrder: WorkOrderModel | null = this.wizardContextWorkOrder;

        if ((owner) && (wizardContextWorkOrder?.name)) {
            const request: CreateWorkOrderRequest = new CreateWorkOrderRequest(null);

            request.activationDate = wizardContextWorkOrder.activationDate;
            request.name = wizardContextWorkOrder.name;
            request.description = wizardContextWorkOrder.description;
            request.invoiceReference = owner.invoiceReference;
            request.customerApprover = wizardContextWorkOrder.customerApprover;
            request.customerOrderer = wizardContextWorkOrder.customerOrderer;
            request.managerId = wizardContextWorkOrder.managerId;
            request.constructionSiteOrWarehouseId = owner.id;
            request.mounters = this.wizardContext.mounters || [];
            request.equipment = this.wizardContext.equipment;
            request.extraCharges = this.wizardContext.extraCharges;
            request.rentalItems = this.wizardContext.rentalEquipments;
            request.hoursPrice = owner.hoursPrice;
            request.mileagePrice = owner.mileagePrice;
            request.workOrderTypeId = wizardContextWorkOrder.type!.id;
            request.contractType = wizardContextWorkOrder.contractType;

            await this.postAsync("/api/RentaTasks/createWorkOrder", request);

            await ch.alertMessageAsync(Localizer.rentaTasksControllerAlertsCreated.format(request.name), true);
        }
        else {
            return await ch.alertErrorAsync(Localizer.rentaTasksControllerAlertsRequiredInformationMissing, true)
        }
    }

    public async checkExpirationAsync(): Promise<boolean> {
        if (this.mounterContext.isExpired) {
            this.mounterContext.signOut();

            this.saveMounterContext();

            await PageRouteProvider.redirectAsync(PageDefinitions.rentaTasksRoute, false);

            await ch.alertMessageAsync(Localizer.rentaTasksControllerSignInExpirationAlert, true);

            return true;
        }

        return false;
    }

    private async checkConcurrencyResponseAsync(response: BaseConcurrencyResponse, newWorkOrder: WorkOrderModel | null = null): Promise<void> {
        const wizardContextWorkOrder: WorkOrderModel | null = this.wizardContextWorkOrder;

        if (wizardContextWorkOrder?.id) {
            if (response.concurrency) {

                this.setWizardContext(new WizardContext());

                this.saveMounterContext();

                await PageRouteProvider.redirectAsync(PageDefinitions.rentaTasksWorkOrder(wizardContextWorkOrder.id));

                await ch.alertMessageAsync(
                    Localizer.rentaTasksControllerWorkOrderConcurrencyAlert.format(response.concurrencyBy, response.concurrencyAt),
                    false);

                PageRouteProvider.stopPropagation();

            } else if (newWorkOrder) {
                this.setWizardContextWorkOrder(newWorkOrder);

                this.saveMounterContext();
            }
        }
    }

    /**
     * Reload and reassign the current {@link wizardContextWorkOrder} from server.
     * Does nothing if the current {@link wizardContextWorkOrder} does not exist or does not have an id.
     */
    private async reloadWizardContextWorkOrderAsync(): Promise<void> {
        if (this.wizardContextWorkOrder?.id) {
            const request: GetWorkOrderRequest = new GetWorkOrderRequest();

            request.workOrderId = this.wizardContextWorkOrder.id;
            request.excludeDeletedUserSalaryHours = true;
            request.includeMounters = true;

            const reloadedWorkOrder: WorkOrderModel | null = await this.postAsync("/api/rentaTasks/getWorkOrder", request);

            if (reloadedWorkOrder?.id) {
                const reloadedWorkOrderId: string = reloadedWorkOrder.id;
                const userId: string = ch.getUserId();

                let reloadedMyHours: UserSalaryHour | null = reloadedWorkOrder.userSalaryHours!.find(
                    salaryHour =>
                        (salaryHour.workOrderId == reloadedWorkOrderId) &&
                        (salaryHour.userId == userId) &&
                        (salaryHour.day.isToday())
                ) || null;

                if (!reloadedMyHours) {
                    reloadedMyHours = new UserSalaryHour();

                    reloadedMyHours.day = Utility.today();
                    reloadedMyHours.workOrderId = reloadedWorkOrder.id;
                    reloadedMyHours.user = ch.getUser<User>();
                    reloadedMyHours.userId = ch.getUserId();
                }

                this.setWizardContextWorkOrder(reloadedWorkOrder);
                this.wizardContext.owner = reloadedWorkOrder.owner;
                this.wizardContext.myHours = reloadedMyHours;
            }
        }
    }

    private async signOutAsync(): Promise<void> {
        if (this.isSignedIn) {
            const request: SignOutRequest = new SignOutRequest();

            request.location = await Utility.getLocationAsync();

            await this.postAsync("/api/RentaTasks/signOut", request);

            this.mounterContext.signOut();

            this.saveMounterContext();
        }
    }

    private async checkOutAsync(saveData: boolean = true): Promise<void> {
        const mounterContext: MounterContext = this.mounterContext;
        const wizardContext: WizardContext = this.wizardContext;
        const wizardContextWorkOrder: WorkOrderModel | null = this.wizardContextWorkOrder;

        if ((mounterContext.isCheckedIn) && (wizardContextWorkOrder)) {
            const request: TaskCheckOutRequest = new TaskCheckOutRequest();

            request.lastModifiedAt = wizardContextWorkOrder.modifiedAt;
            request.location = await Utility.getLocationAsync();

            if (saveData) {
                request.normalHours = wizardContext.myHours.normalHours;
                request.overtime50Hours = wizardContext.myHours.overtime50Hours;
                request.overtime100Hours = wizardContext.myHours.overtime100Hours;
                request.comment = wizardContext.myHours.comment;
            }

            const response: TaskCheckOutResponse = await this.postAsync("/api/RentaTasks/taskCheckOut", request);

            if ((saveData) && (!response.concurrency)) {
                await this.saveWorkOrderDataFromWizardContextAsync();
            }

            mounterContext.checkOut();

            this.saveMounterContext();

            await this.signOutAsync();

            await this.checkConcurrencyResponseAsync(response);
        }
    }

    /**
     * Save to server Work Order data with values from current {@link WizardContext}.
     * Throws if the current {@link wizardContextWorkOrder} does not exist or does not have an id.
     */
    private async saveWorkOrderDataFromWizardContextAsync(): Promise<void> {
        const wizardContext: WizardContext = this.wizardContext;
        const wizardContextWorkOrder: WorkOrderModel | null = this.wizardContextWorkOrder;

        if (!wizardContextWorkOrder?.id) {
            throw new Error("An existing work order is missing from context.");
        }

        const request: SaveWorkOrderDataRequest = new SaveWorkOrderDataRequest();

        request.lastModifiedAt = wizardContextWorkOrder.modifiedAt;
        request.workOrderId = wizardContextWorkOrder.id;
        request.name = wizardContextWorkOrder.name!;
        request.description = wizardContextWorkOrder.description || "";
        request.invoiceReference = wizardContextWorkOrder.invoiceReference;
        request.jobSummary = wizardContextWorkOrder.jobSummary;
        request.activationDate = wizardContextWorkOrder.activationDate;
        request.distances = wizardContextWorkOrder.distances;
        request.managerId = wizardContextWorkOrder.managerId;
        request.customerOrdererId = wizardContextWorkOrder.customerOrdererId;
        request.customerApproverId = wizardContextWorkOrder.customerApproverId;
        request.contractType = wizardContextWorkOrder.contractType;

        request.extraCharges = wizardContext.extraCharges;
        request.equipment = wizardContext.equipment;
        request.rentalEquipments = wizardContext.rentalEquipments;

        const response: SaveWorkOrderDataResponse = await this.postAsync("/api/RentaTasks/saveWorkOrderData", request);

        await this.checkConcurrencyResponseAsync(response, response.workOrder);
    }

    public async checkInAsync(workOrder: WorkOrderModel): Promise<void> {
        throwIfFalsy(workOrder, nameof(workOrder));

        const request: SignInRequest = new SignInRequest();

        request.location = await Utility.getLocationAsync();
        request.workOrderId = workOrder.id;
        request.constructionSiteOrWarehouseId = workOrder.owner!.id;

        await this.postAsync(`/api/RentaTasks/signIn`, request);

        this.mounterContext.checkIn(workOrder);

        this.saveMounterContext();
    }

    /**
     * @returns The wizard steps according to the current {@link WizardContext.action}.
     */
    public getWizardSteps(): IWizardSteps | null {
        //Forms:
        const formPage: IFormWizardStep = {route: PageDefinitions.rentaTasksFormPageRoute, title: Localizer.rentaTasksControllerStepsTitleFormPage, icon: {name: "fal clipboard-list-check"}, isFormWizardStep: true, hidden: false};
        const formSummary: IFormWizardStep = {route: PageDefinitions.rentaTasksFormSummaryPageRoute, title: Localizer.rentaTasksControllerStepsTitleFormSummary, icon: {name: "fal clipboard-list-check"}, isFormWizardStep: true, hidden: false};
        const userSignatures: IFormWizardStep = {route: PageDefinitions.rentaTasksUsersSignaturesPageRoute, title: Localizer.formSummaryTableMountersSignatures, isFormWizardStep: false, hidden: true};
        const approveForm: IFormWizardStep = {route: PageDefinitions.rentaTasksApproveFormPageRoute, title: Localizer.rentaTasksControllerStepsTitleApproveForm, isFormWizardStep: false, hidden: true};
        const formReceivers: IFormWizardStep = {route: PageDefinitions.rentaTasksFormReceiversPageRoute, title: Localizer.rentaTasksControllerStepsTitleSendToReceivers, isFormWizardStep: false, hidden: true};

        const selectConstructionSiteStep: IWizardStep = {route: PageDefinitions.selectConstructionSiteRoute, title: Localizer.rentaTasksControllerStepsTitleSelectSite};
        const checkOutWorkOrderStep: IWizardStep = {route: PageDefinitions.checkOutRoute, title: Localizer.rentaTasksControllerStepsTitleTaskStatus};
        const hoursAndDistancesStep: IWizardStep = {route: PageDefinitions.hoursAndDistancesRoute, title: Localizer.rentaTasksControllerStepsTitleHoursAndDistance};
        const addEquipmentStep: IWizardStep = {route: PageDefinitions.addEquipmentRoute, title: Localizer.rentaTasksControllerStepsTitleEquipment};
        const approveStep: IWizardStep = {route: PageDefinitions.approveRoute, title: Localizer.rentaTasksControllerStepsTitleApproving};
        const detailsPreviewStep: IWizardStep = {route: PageDefinitions.previewDetailsRoute, title: Localizer.rentaTasksControllerStepsTitleDetailsPreview};
        const summaryStep: IWizardStep = {route: PageDefinitions.summaryRoute, title: Localizer.rentaTasksControllerStepsTitleSummary};
        const workOrderInfoStep: IWizardStep = {route: PageDefinitions.workOrderInfoRoute, title: Localizer.rentaTasksControllerStepsTitleNewTask};
        const assignMountersStep: IWizardStep = {route: PageDefinitions.assignMountersRoute, title: Localizer.rentaTasksControllerStepsTitleMounters};
        const managersStep: IWizardStep = {route: PageDefinitions.managersRoute, title: Localizer.rentaTasksControllerStepsTitleManagers};

        // "Activate construction site" wizard
        const selectOrganizationStep: IWizardStep = {
            route: PageDefinitions.ActivateConstructionSite.SelectOrganizationRoute,
            title: Localizer.rentaTasksControllerStepsActivateConstructionSiteTitleSelectCustomer
        };
        const selectInactiveConstructionSiteStep: IWizardStep = {
            route: PageDefinitions.ActivateConstructionSite.SelectSiteRoute,
            title: Localizer.rentaTasksControllerStepsActivateConstructionSiteTitleSelectConstructionSite
        };
        const confirmAddressStep: IWizardStep = {
            route: PageDefinitions.ActivateConstructionSite.ConfirmAddressRoute,
            title: Localizer.rentaTasksControllerStepsActivateConstructionSiteTitleConfirmAddress
        };
        const activateConstructionSiteSummaryStep: IWizardStep = {
            route: PageDefinitions.ActivateConstructionSite.SummaryRoute,
            title: Localizer.rentaTasksControllerStepsActivateConstructionSiteTitleSummary
        };

        const mounterContext: MounterContext = this.mounterContext;
        const wizardContext: WizardContext = this.wizardContext;
        const action: RentaTasksAction = wizardContext.action;
        const isSignedIn: boolean = mounterContext.isCheckedIn;

        if (action == RentaTasksAction.NewWorkOrder) {
            const steps: IWizardStep[] = [];
            if (!isSignedIn) {
                steps.push(selectConstructionSiteStep);
            }
            steps.push(workOrderInfoStep, managersStep, assignMountersStep, addEquipmentStep);
            return {steps: steps};
        }

        if (action == RentaTasksAction.SignOut) {
            const steps: IWizardStep[] = [];
            steps.push(checkOutWorkOrderStep);
            if (wizardContext.addEquipment) {
                steps.push(addEquipmentStep);
            }
            return {steps: steps};
        }

        if (action == RentaTasksAction.EditWorkOrder) {
            return {steps: [workOrderInfoStep, managersStep, addEquipmentStep, hoursAndDistancesStep]};
        }

        if (action == RentaTasksAction.PreviewDetails) {
            return {steps: [detailsPreviewStep]};
        }

        if (action == RentaTasksAction.CompleteWorkOrder) {
            const steps: IWizardStep[] = [];
            if (isSignedIn) {
                steps.push(checkOutWorkOrderStep);
            }
            steps.push(addEquipmentStep, hoursAndDistancesStep, detailsPreviewStep, summaryStep, approveStep);
            return {steps: steps};
        }

        if (action == RentaTasksAction.AddEquipment) {
            return {steps: [addEquipmentStep]};
        }

        if (action == RentaTasksAction.EditHoursAndDistances) {
            return {steps: [hoursAndDistancesStep]};
        }

        if (action == RentaTasksAction.ActivateConstructionSite) {
            return {
                steps: [
                    selectOrganizationStep,
                    selectInactiveConstructionSiteStep,
                    confirmAddressStep,
                    activateConstructionSiteSummaryStep
                ]
            };
        }

        if (action == RentaTasksAction.Form) {

            let formSteps: IWizardStep[] = [];

            const form: FormModel | null = this.mounterContext.form;

            if (form != null) {
                const processed: boolean = form.processed;
                const approvable: boolean = form.mapping.approvable;
                const isApprovedByEmail: boolean = form.approvalType === CustomerApprovalType.Email;
                const usersSignaturesRequired: boolean = form.mapping.mounterSignaturesRequired;

                const formItems: (FormItem | FormItem[])[] = FormContent.getItems(form, true);

                formSteps = formItems.map((formItem: FormItem | FormItem[], index) => {
                    const singleFormItem: FormItem = Array.isArray(formItem)
                        ? formItem[0]
                        : formItem;

                    const icon: IIconProps | undefined = (singleFormItem.icon)
                        ? {name: singleFormItem.icon}
                        : formPage.icon;

                    const title: string | null = singleFormItem.name || formPage.title;

                    const formItemIds: string[] = (Array.isArray(formItem))
                        ? formItem.map(item => item.id ?? (item.id = this.generateGuid()))
                        : [singleFormItem.id ?? (singleFormItem.id = this.generateGuid())];

                    const params: IFormPageProps = {
                        formItemIds: formItemIds
                    };

                    const step: IFormWizardStep = {
                        icon: icon,
                        title: title,
                        route: new PageRoute(formPage.route.name, index, null, params),
                        isFormWizardStep: true,
                    };

                    return step;
                });

                if (usersSignaturesRequired) {
                    formSteps.push(userSignatures);
                }

                formSteps.push(formSummary);

                if ((approvable) && (!processed)) {
                    formSteps.push(approveForm);
                    if (isApprovedByEmail) {
                        formSteps.push(formReceivers);
                    }
                }
            }

            //steps:
            const steps: IWizardStep[] = [...formSteps];

            return {steps};
        }

        return null;
    }

    // https://www.c-sharpcorner.com/blogs/generate-guid-using-javascript1
    public generateGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public getFirstStep(): PageRoute {
        const steps: IWizardStep[] = this.getWizardSteps()!.steps;

        return steps[0].route;
    }

    public async openStepAsync(formItemId: string | null, steps?: IWizardStep[] | null): Promise<void> {
        if (!steps) {
            steps = this.getWizardSteps()!.steps;
        }

        const step: IWizardStep | null = (formItemId)
            ? steps.find(step => (step.route.parameters as IFormPageProps)?.formItemIds?.includes(formItemId)) || null
            : null;

        if (step) {
            const route: PageRoute = step.route;
            await PageRouteProvider.redirectAsync(route);
        }
    }

    public async openMountersSignaturesStepAsync(): Promise<void> {
        const steps = this.getWizardSteps()!.steps;

        const step: IWizardStep | undefined = steps.find(step => (step.title === Localizer.formSummaryTableMountersSignatures || undefined));

        if (step) {
            const route: PageRoute = step.route;
            await PageRouteProvider.redirectAsync(route);
        }
    }

    public async getPrevStepAsync(route: PageRoute): Promise<PageRoute> {
        const index: number = this.indexOfCurrentStep(route);

        if ((index === -1) || (index === 0)) {
            return this.wizardContext.actionInitialPageRoute || PageDefinitions.rentaTasksRoute;
        }

        const wizardSteps: IWizardSteps | null = this.getWizardSteps();
        const steps: IWizardStep[] = wizardSteps!.steps;

        return steps[index - 1].route;
    }

    public async getNextStepAsync(route: PageRoute): Promise<IWizardNextStep> {
        const index: number = this.indexOfCurrentStep(route);
        const steps: IWizardStep[] = this.getWizardSteps()!.steps;
        const firstStep: boolean = (index === -1) && (steps.length > 0);

        if (firstStep) {
            return {nextRoute: steps[0].route, firstStep: true, lastStep: false};
        }

        const lastStep: boolean = (index === -1) || (index === steps.length - 1);
        if (lastStep) {
            const wizard: WizardContext = this.wizardContext;

            const nextRoute: PageRoute | null = (!Comparator.isEqualPageRoute(wizard.actionInitialPageRoute, route))
                ? wizard.actionInitialPageRoute
                : null;

            return {nextRoute: nextRoute || PageDefinitions.rentaTasksRoute, firstStep: false, lastStep: true};
        }

        return {nextRoute: steps[index + 1].route, firstStep: false, lastStep: false};
    }

    public isFistStep(route: PageRoute): boolean {
        const wizardSteps: IWizardSteps | null = this.getWizardSteps();
        if (wizardSteps != null) {
            const index: number = this.invokeIndexOfCurrentStep(wizardSteps, route);
            return (index === -1) && (wizardSteps.steps.length > 0);
        }
        return false;
    }

    public isLastStep(route: PageRoute): boolean {
        const wizardSteps: IWizardSteps | null = this.getWizardSteps();
        if (wizardSteps != null) {
            const index: number = this.invokeIndexOfCurrentStep(wizardSteps, route);
            return (index === -1) || (index === wizardSteps.steps.length - 1);
        }
        return true;
    }

    public async getConstructionSitesOrWarehousesAsync(sender: PostAsync<null, ConstructionSiteOrWarehouse[]>): Promise<ConstructionSiteOrWarehouse[]> {
        return await PageCacheProvider.getAsync("getConstructionSitesAsync", () => sender.postAsync("/api/workOrders/getConstructionSitesData", null));
    }

    public async getMountersAsync(sender: PostAsync<GetEmployeesRequest, User[]>, request: GetEmployeesRequest): Promise<User[]> {
        return await PageCacheProvider.getAsync("getMountersAsync", () => sender.postAsync("/api/workOrders/getMounters", request));
    }

    public async getProductsAsync(sender: PostAsync<false, Product[]>): Promise<Product[]> {
        return await sender.postAsync("/api/rentaTasks/getAllProducts", false);
    }

    public async getCostPoolsAsync(sender: PostAsync<false, CostPool[]>): Promise<CostPool[]> {
        return await sender.postAsync("api/employees/getCostPools", false);
    }

    /**
     * Returns active (non-disabled) work order types
     * AND the type of the given workOrder even if it is disabled,
     * so that we can display disabled types for existing work orders.
     */
    public async getActiveWorkOrderTypesAsync(sender: IBaseComponent, workOrder: WorkOrderModel): Promise<WorkOrderType[]> {

        const types: WorkOrderType[] = await PageCacheProvider.getAsync(
            "getWorkOrderTypesAsync",
            () => sender.getAsync("/api/workOrderType/getWorkOrderTypes"));

        return types.filter(type => !type.disabled || type.id === workOrder.type.id);
    }

    public async rotateImageLeftAsync(file: FileModel): Promise<FileModel> {
        return await this.postAsync("api/image/rotateLeft", file);
    }

    public async rotateImageRightAsync(file: FileModel): Promise<FileModel> {
        return await this.postAsync("api/image/rotateRight", file);
    }

    /**
     * Converts the image to a smaller version.
     * Note: the file will be stored as a temporary, and it will be deleted automatically later unless the temp file entry from the database is removed first.
     * @param file File to convert.
     * @param convertToReference If true, will return only a reference to the uploaded image and reset the src property of the given FileModel.
     */
    public async convertImageAsync(file: FileModel, convertToReference: boolean): Promise<FileModel> {
        if (convertToReference) {
            const result: FileModel = await this.postAsync("api/image/convertToReference", file);
            file.id = result.id;
            file.src = "";

            return result;
        }

        return await this.postAsync("api/image/convert", file);
    }
}

/** {@link RentaTasksController} Singleton. */
export default new RentaTasksController();