/**
 * Restore a deserialized {@link Date}
 *
 * @param date Deserialized {@link Date} to restore
 * @returns {@link date} as {@link Date}
 */
export default function restoreDeserializedDate(date: Date | string): Date {
    return (date instanceof Date)
        ? date
        : new Date(date);
}