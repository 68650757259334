/**
 * Importing code which is outside of the src folder cannot be done in code which is inside the src folder, so these helpers are here and not in the cypress folder,
 * as they are used in Cypress tests which are inside the src folder.
 */

import Chainable = Cypress.Chainable;

// Types and constants

/**
 * Constants usable in tests. All of these values should be in the SeedData so that they always exist
 */
export const testConstants = {
    costPool: {
        name: "Test cost pool",
        description: "Right and left, the streets take you waterward.",
    },
    extraChargeType: {
        name: "Test type",
        price: 1.1,
        unit: "Piece",
        externalId: "ccf5d885",
    },
    workOrder1: {
        name: "Test work order",
    },
    workOrderType1: {
        name: "Enabled",
        enabled: true,
    },
    workOrderType2: {
        name: "Disabled",
        enabled: false,
    },
} as const;

/**
 * Data of an interceptable route.
 *
 * @see pageData
 * @see intercept
 */
export type RouteData = {
    /**
     * Path where the request is made.
     */
    path: string;

    /**
     * HTTP method the request is made with.
     */
    method: "GET" | "POST";
}

/**
 * Grid columns by page/component, used when selecting specific cells.
 * Order of the values is important - it is used to determine the number of the column!
 */
export const gridColumns = {
    employees: {
        employeesGrid: [
            "day",
            "user",
            "owner",
            "task",
            "workOrder",
            "normalHours",
            "overtime50Hours",
            "overtime100Hours",
            "overtimeTotalHours",
            "hoursActions",
            "state",
            "actions"
        ]
    },
    constructionSites: {
        constructionSitesGrid: [
            "customer",
            "name",
            "reference",
            "address",
            "status",
            "actions",
        ]
    },
    extraChargeTypeManagement: {
        extraChargeTypesGrid: [
            "number",
            "name",
            "unit",
            "externalId",
            "price",
            "status",
            "actions",
        ],
    },
    warehouses: {
        warehousesGrid: [
            "number",
            "name",
            "manager",
            "address",
            "actions"
        ],
        warehouseCostPoolsGrid: [
            "number",
            "name",
            "description",
            "actions"
        ],
        costPoolsGrid: [
            "number",
            "name",
            "description",
            "actions"
        ],
    },
    workOrderDetailsPanel: {
        extraChargesGrid: [
            "number",
            "type",
            "unit",
            "comment",
            "date",
            "amount",
            "price",
            "cost",
            "actions",
        ],
    },
    workOrders: {
        workOrdersGrid: [
            "code",
            "state",
            "blocked",
            "customerAndSite",
            "name",
            "mounters",
            "manager",
            "orderer",
            "reference",
            "start",
            "done",
            "cost",
            "actions",
        ],
        rentalEquipmentGrid: [
            "number",
            "name",
            "externalId",
            "amount",
            "date",
            "actionType",
            "comment",
            "actions"
        ]
    },
    workOrderTypeManagement: {
        workOrderTypesGrid: [
            "number",
            "name",
            "actions",
        ],
    },
    invitations: {
        invitationsGrid: [
            "createdBy",
            "type",
            "authType",
            "createdAt",
            "expiresAt",
            "processedAt",
            "reusable"
        ],
    },
    subcontractorsPanel: {
        subcontractorsGrid: [
            "code",
            "vatId",
            "name",
            "address",
            // "manager", - not displayed.
            "actions",
        ],
    },
} as const;


// Functions

/**
 * Element selectors and routes by page. Used in Cypress testing to eliminate code duplication.
 */
export function pageData() {

    /**
     * @param rowContent Unique content of the Grid Row which to get
     * @returns The visible Grid Row which contains the {@link rowContent}
     */
    function getGridRow(rowContent: string): Chainable<JQuery<HTMLTableRowElement>> {
        return cy.contains(rowContent).filter(":visible").parents("tr");
    }

    /**
     * @param gridId A Grid identifier
     * @param cellContent Unique content of the Grid Cell which Row to get
     * @returns The Grid Row which contains the {@link cellContent}
     */
    function getExtendedGridRow(gridId: string, cellContent: string): Chainable<JQuery<HTMLTableRowElement>> {
        return cy.get(gridId).contains(cellContent).parents('tr');
    }

    /**
     *
     * @param rowContent Unique content of a Grid Row which Cell to get
     * @param columns Grids Columns names (see {@link gridColumns})
     * @param column Specific Grid Columns name
     * @returns The Grid Cell of the given Grid {@link column} of the Grid Row which contains the {@link rowContent}
     */
    function getGridCell<TColumns extends readonly string[]>(
        rowContent: string,
        columns: Readonly<TColumns>,
        column: Readonly<TColumns>[number]
    ): Chainable<JQuery<HTMLTableCellElement>> {
        const columnNumber: number = columns.indexOf(column) + 1;

        return getGridRow(rowContent).find(`> :nth-child(${columnNumber})`);
    }

    /**
     *
     * @param gridId The grid identifier. A certain grid where a cell will be searched by {@link cellContent} and {@link column}
     * @param cellContent Unique content of a Grid Cell to get
     * @param columns Grids Columns names (see {@link gridColumns})
     * @param column Specific Grid Columns name
     * @returns The Grid Cell of the given Grid {@link column} of the Grid Row which contains the {@link cellContent}
     */
    function getExtendedGridCell<TColumns extends readonly string[]>(
        gridId: string,
        cellContent: string,
        columns: Readonly<TColumns>,
        column: Readonly<TColumns>[number]
    ): Chainable<JQuery<HTMLTableCellElement>> {
        const columnNumber: number = columns.indexOf(column) + 1;

        return getExtendedGridRow(gridId, cellContent).find(`> :nth-child(${columnNumber})`);
    }

    return {
        /**
         * Elements which are common to all pages.
         */
        common: {
            confirmationDialog: {
                dialog: () => cy.get('div.athenaeum-confirmation-dialog-opened'),
                confirmButton: () => cy.get('button').filter(":visible").contains("Confirm"),
                closeButton: () => cy.get('button').filter(":visible").contains("Close"),
            },

            /**
             * <footer> element on bottom of the page.
             */
            footer: () => cy.get('footer.athenaeum-footer-footer'),

            /**
             * <main> element with main page content.
             */
            main: () => cy.get('main.athenaeum-layout-main'),

            /**
             * Elements common to all modals
             */
            modal: {
                closeIcon: () => cy.get('.modal-header i[data-dismiss]'),
            },

            /**
             * <nav> element on top of the page.
             */
            topNav: () => cy.get('nav.athenaeum-top-nav-navigation'),

            /**
             * Middle-section of {@link topNav}, containing {@link topNavMiddleLinks}. Only visible on wide displays. On narrow displays the same links are displayed in {@link hamburger}.
             */
            topNavMiddle: () => cy.get('.athenaeum-top-nav-middle'),

            /**
             * Links to pages. Contained in {@link topNavMiddle} in wide displays and in {@link hamburger} in narrow displays.
             */
            topNavMiddleLinks: {
                account: () => cy.get('a[href=Account]'),
                admin: () => cy.get('a[href=Admin]'),
                constructionSites: () => cy.get('a[href=ConstructionSites]'),
                contactSupport: () => cy.get('a[href=ContactSupport]'),
                /**
                 * @see topNavLinks.mounterDashboard
                 * @see topNavLinks.managerDashboard
                 */
                dashboard: () => cy.get('a[href=Dashboard]'),
                employees: () => cy.get('a[href=Employees]'),
                workOrders: () => cy.get('a[href=WorkOrders]'),
                logout: () => cy.get('a[href=Logout]'),
                /**
                 * @see topNavLinks.dashboard
                 * @see topNavLinks.managerDashboard
                 */
                mounterDashboard: () => cy.get('a[href="RentaTasks/Dashboard"]'),
                /**
                 * @see topNavLinks.tasks
                 */
                mounterWorkOrders: () => cy.get('a[href="RentaTasks/WorkOrders"]'),
                myWorkHours: () => cy.get('a[href="RentaTasks/MyWorkHours"]'),
                /**
                 * @see topNavLinks.mounterTasks
                 */
                calendar: () => cy.get('a[href=Calendar]'),
            },

            signatureWidget: {
                mountersSignaturePanel: () => cy.get('.user-signature a'),
                panel: () => cy.get('#Signature > a'),
                signatureCanvas: () => cy.get('.athenaeum-widget-container-signaturePad'),
                doneButton:  () => cy.get('button.btn-primary'),
            },
            nameClarificationWidget: {
                panel: () => cy.get('#nameClarification > a'),
                inputNameClarification: () => cy.get('#inputnameClarification'),
            },

            /**
             * Elements which are common to all pages but are only visible on mobile devices.
             */
            mobile: {
                /**
                 * Hamburger containing {@link topNavMiddleLinks}. Closed by default. Can be toggled with {@link hamburgerIcon}.
                 */
                hamburger: () => cy.get('.athenaeum-hamburger-hamburger_open'),

                /**
                 * Icon which clicking toggles the {@link hamburger}. Only visible on narrow displays.
                 */
                hamburgerIcon: () => cy.get('.athenaeum-top-nav-right_hamburgerIcon'),
                wizardNextButton: () => cy.get('div[title="Go to next step"]'),
            },

            widgetContainer: () => cy.get('.athenaeum-widget-container-widgetContainer'),
        },

        account: {
            tabContainer: () => cy.get('#accountTabs'),
            tabs:{
                tabContainer: {
                    personalTab: () => cy.get('#tab_personal'),
                    notificationsSettingsTab: () => cy.get('#tab_userNotificationsSettings'),
                },
            },
            routes: {
                saveUserAccount: {
                    path: "api/account/saveUserAccount",
                    method: "POST",
                }
            }
        },

        addHoursModal: {
            modal: () => cy.get('#addMounterModal'),
            mountersDropdown: () => cy.get('#mounters'),
            saveButton: () => cy.get('#saveAddedHours'),
            selectConstructionSiteDropdown: () => cy.get('#addMounterModal_constructionSiteOrWarehouse'),
            selectTaskDropdown: () => cy.get('#addMounterModal_task'),
            dateInput: () => cy.get('#input_addMounterModal_date'),
            mounterWorkingHoursInput: () => cy.get('#input_addMounterModal_normalHours'),
            mounterOvertime50HoursInput: () => cy.get('#input_addMounterModal_overtime50Hours'),
            mounterOvertime1000HoursInput: () => cy.get('#input_addMounterModal_overtime100Hours'),
            addHoursButton: () => cy.get('#addMounterModal_addHours'),
            routes: {
                addMounterHours: {
                    path: "/api/employees/addMounterHours",
                    method: "POST",
                }
            }
        },

        admin: {
            linksContainer: () => cy.get('#adminPageLinks'),
            links: {
                adminConsole: () => cy.get('a[href=AdminConsole]'),
                catalogManagement: () => cy.get('a[href=CatalogManagement]'),
                formDefinitions: () => cy.get('a[href=FormDefinitions]'),
                userManagement: () => cy.get('a[href=UserManagement]'),
                warehouses: () => cy.get('a[href=Warehouses]'),
                workOrderTypeManagement: () => cy.get('a[href=WorkOrderTypeManagement]'),
            },
        },

        calendar: {
            rightPanelContainer: () => cy.get("#calendarPanelRight"),
            rightPanel: {
                calendarData: () => cy.get('#calendarData'),
            },
            routes: {
                getMounters: {
                    path: "/api/calendar/getMounters",
                    method: "POST",
                }
            }
        },

        constructionSites: {
            constructionSitesGrid: () => cy.get('#table_sites'),
            constructionSitesGridCell: (nameOrProjectNumber: string, column: typeof gridColumns.constructionSites.constructionSitesGrid[number]) => getGridCell(nameOrProjectNumber, gridColumns.constructionSites.constructionSitesGrid, column),
            constructionSitesGridToolbarItems: {
                searchInput: () => cy.get('#input_searchTerm'),
                statusDropdown: () => cy.get('#constructionSitesStatus'),
                filterButton: () => cy.get('#filterConstructionSites'),
                clearFiltersButton: () => cy.get('#clearConstructionSitesFilters'),
            },
            routes: {
                getConstructionSites: {
                    path: "/api/constructionSites/getConstructionSites",
                    method: "POST",
                }
            }
        },

        dashboard: {
            widgets: {
                mobile: {
                    workOrders: () => cy.get('#workOrdersWidget'),
                    addWorkOrder: () => cy.get('#addWorkOrderWidget'),
                    myWorkHours: () => cy.get('#myWorkHoursWidget'),
                    activateSite: () => cy.get('#activateConstructionSiteWidget'),
                },
                desktop: {
                    constructionSites: () => cy.get('#constructionSites'),
                    employees: () => cy.get('#employees'),
                    // Contact person
                    myForms: () => cy.get('#myForms'),
                    myWorkReports: () => cy.get('#myWorkReports'),
                },
            },
            wizard: {
                organizationsDropdown: () => cy.get('#StpOrganizations'),
                constructionSitesDropdown: () => cy.get('#ConstructionSites'),
                mountersDropdown: () => cy.get('#Mounters'),
                managersDropdown: () => cy.get('#Managers'),
                orderersDropdown: () => cy.get('#Orderers'),
                approversDropdown: () => cy.get('#Approvers'),
                catalog: {
                    equipmentCatalog: () => cy.get('#EquipmentCatalog'),
                    addEquipmentButtonsContainer: {
                        addEquipmentButtonsContainer: () => cy.get('#addEquipmentButtonsContainer'),
                        addEquipmentButton: () => cy.get('#addEquipmentButton'),
                        addCustomEquipmentButton: () => cy.get('#addCustomEquipmentButton'),
                        addExtraChargeButton: () => cy.get('#addExtraChargeButton'),
                    },
                    categoriesOrProductsModal: () => cy.get('#categoriesOrProductsModal'),
                    addEquipmentModeCatalog: () => cy.get('#addEquipmentModeCatalog'),
                    categoriesAndProductsList: () => cy.get('#categoriesAndProductsList'),
                    equipmentItem: {
                        itemHeader: {
                            header: () => cy.get('#itemHeader'),
                            favorite: () => cy.get('#itemFavorite i'),
                            name: () => cy.get('#itemName span'),
                            initialAmount: () => cy.get('#itemInitialAmount span'),
                        },
                        items: {
                            content: () => cy.get('#itemContent'),
                            dataContainer: {
                                dataContainer: () => cy.get('#itemDataContainer'),
                                amountInputWrapper: () => cy.get('#itemAmountInputWrapper'),
                                description: () => cy.get('#itemDescription span'),
                            },
                            minus: () => cy.get('#itemMinus'),
                            plus: () => cy.get('#itemPlus'),
                            descriptionToggler: () => cy.get('#itemDescriptionToggler'),
                            descriptionInput: () => cy.get('#input_itemDescriptionInput'),
                        },
                    },
                    addCustomProductModal: () => cy.get('#addCustomProductModal'),
                    customProductModal: {
                        nameInput: () => cy.get('#input_name'),
                        unitDropdown: () => cy.get('#unit'),
                        amountNumberInput: () => cy.get('#amount'),
                        amountInput: () => cy.get('#input_amount'),
                        priceInput: () => cy.get('#input_price'),
                    },
                    addExtraChargeModal: () => cy.get('#addExtraChargeModal'),
                    extraCharge: {
                        extraChargeTypeDropdown: () => cy.get('#extraChargeType'),
                        extraChargeTypeUnitInput: () => cy.get('#input_extraChargeTypeUnit'),
                        extraChargeDateInput: () => cy.get('#input_extraChargeDate'),
                        extraChargeAmountInput: () => cy.get('#input_extraChargeAmount'),
                        extraChargePriceInput: () => cy.get('#input_extraChargePrice'),
                    },
                    modalButtons: {
                        saveButton: () => cy.get('#save'),
                        cancelButton: () => cy.get('#cancel'),
                    },
                },
                next: () => cy.get('#rentaTasksWizardContainer .athenaeum-navigation-widget-navigation .athenaeum-navigation-widget-right'),
                nameWidget: () => cy.get('#rentaTasksWizardContainer #name .athenaeum-widget-container-icon'),
                nameInput: () => cy.get('#rentaTasksWizardContainer #name input'),
                descriptionWidget: () => cy.get('#rentaTasksWizardContainer #description .athenaeum-widget-container-icon'),
                descriptionTextarea: () => cy.get('#rentaTasksWizardContainer #description textarea'),
                activationDateWidget: () => cy.get('#rentaTasksWizardContainer #activationDate .athenaeum-widget-container-widget'),
                activationDateInput: () => cy.get('#activationDate'),
                unscheduleCheckboxWidget: () => cy.get('#rentaTasksWizardContainer #unschedule'),
                typeDropdown: () => cy.get('#rentaTasksWizardContainer #workOrderType .athenaeum-dropdown-list'),
                routes: {
                    mobile: {
                        getConstructionsSitesData: {
                            path: "api/RentaTasks/getConstructionSitesData",
                            method: "POST",
                        },
                        getWorkOrderTypes: {
                            path: "api/workOrderType/getWorkOrderTypes",
                            method: "POST",
                        },
                        getContactPersons: {
                            path: "api/rentaTasks/getContactPersons",
                            method: "POST",
                        },
                        getManagers: {
                            path: "api/rentaTasks/getManagers",
                            method: "POST",
                        },
                        getMountersData: {
                            path: "api/RentaTasks/getMountersData",
                            method: "POST",
                        },
                        getAllProducts: {
                            path: "api/rentaTasks/getAllProducts",
                            method: "POST",
                        },
                        getAllCategories: {
                            path: "api/rentaTasks/getAllCategories",
                            method: "POST",
                        },
                        createWorkOrder: {
                            path: "api/RentaTasks/createWorkOrder",
                            method: "POST",
                        },
                    },
                },
            },
            workOrders: {
                workOrdersList: () => cy.get("#workOrdersList [class*='workOrderListItem']"),
            },
        },

        extraChargeTypeManagement: {
            extraChargeTypesGrid: () => cy.get('#table_extraChargeTypesGrid'),
            extraChargeTypesGridCell: (name: string, column: typeof gridColumns.extraChargeTypeManagement.extraChargeTypesGrid[number]) => getGridCell(name, gridColumns.extraChargeTypeManagement.extraChargeTypesGrid, column),
            extraChargeTypesGridToolbarItems: {
                searchInput: () => cy.get('#extraChargeTypesSearchField'),
                searchButton: () => cy.get('#searchExtraChargeTypes'),
                reloadExtraChargeTypesButton: () => cy.get('#reloadExtraChargeTypes'),
                showDeletedExtraChargeTypesCheckbox: () => cy.get('#showDeletedExtraChargeTypes'),
                addExtraChargeTypeButton: () => cy.get('#addExtraChargeType'),
            },
            routes: {
                createExtraChargeType: {
                    path: "api/admin/createExtraChargeType",
                    method: "POST",
                },
                deleteExtraChargeType: {
                    path: "api/admin/deleteExtraChargeType",
                    method: "POST",
                },
                getAdminExtraChargeTypes: {
                    path: "api/admin/getAdminExtraChargeTypes",
                    method: "GET",
                },
                restoreExtraChargeType: {
                    path: "api/admin/restoreExtraChargeType",
                    method: "POST",
                },
                saveExtraChargeType: {
                    path: "api/admin/saveExtraChargeType",
                    method: "POST",
                },
            }
        },

        formModal: {
            modal: () => cy.get('#formModal'),
            formsListDropdown: () => cy.get('#formsList'),
            fillButton: () => cy.get('#fillButton')
        },

        hoursAndDistances: {
            routes: {
                getWorkOrderHoursData: {
                    path: "/api/RentaTasks/getWorkOrderHoursData",
                    method: "POST",
                }
            },
        },

        locationPicker: {
            map: () => cy.get('#locationPicker_address_body [aria-label="Map"]'),
            setLocationButton: () => cy.get('#locationPicker_address_body button'),
        },

        login: {
            loginButton: () => cy.get('#submit'),
            passwordInput: () => cy.get('#password'),
            usernameInput: () => cy.get('#input_username'),
        },

        sendWorkReportModal: {
            modal: () => cy.get('#sendWorkReportModal'),
            contactPersonsDropdown: () => cy.get('#sendWorkReportModalFormContactPersons'),
            assignButton: () => cy.get('#sendWorkReportModalFormAssign')
        },

        userManagement: {
            usersList: () => cy.get('#users_dropdown'),

            accountTab: {
                tab: () => cy.get('#tab_account'),
                accountForm: {
                    form: () => cy.get('#accountForm'),
                    firstnameInput: () => cy.get('#input_firstname'),
                    middleNameInput: () => cy.get('#input_middleName'),
                    lastnameInput: () => cy.get('#input_lastName'),
                    emailInput: () => cy.get('#input_email'),
                    phoneInput: () => cy.get('#input_phone'),
                    authType: () => cy.get('#authType'),
                    roleName: () => cy.get('#roleName'),
                    warehouseDropdown: () => cy.get('#warehousesIds'),
                    costPoolsDropdown: () => cy.get('#costPoolsIds'),
                    employeeNumber: () => cy.get('#input_employeeNumber'),
                    addressInputContainer: () => cy.get('#formattedAddress_formattedAddress'),
                    street: () => cy.get('#input_formattedAddress_address'),
                    city: () => cy.get('#input_formattedAddress_city'),
                    postalCode: () => cy.get('#input_formattedAddress_postalCode'),
                    // Subcontractor details
                    canCreateWorkOrder: () => cy.get('#canCreateWorkOrderPermissions i'),
                    canCompleteWorkOrder: () => cy.get('#canCompleteWorkOrderPermissions i'),
                    canProcessForm: () => cy.get('#canProcessFormPermissions i'),
                    subcontractorContractsDropdown: () => cy.get('#subcontractorContracts'),
                    addSubcontractorContractButton: () => cy.get('#addSubcontractorContract'),
                    addSubcontractorContractModal: {
                        modal: () => cy.get('#addSubcontractorContractModal'),
                        nameInput: () => cy.get('#input_name'),
                        vatIdInput: () => cy.get('#input_vatId'),
                        addressInput: () => cy.get('#address input'),
                        saveButton: () => cy.get('#save'),
                    }
                },
            },

            invitationsTab: {
                tab: () => cy.get('#tab_invitations'),
                invitationsGrid: () => cy.get('#table_invitationsGrid'),
                invitationsGridCell: (cellContent: string, column: typeof gridColumns.invitations.invitationsGrid[number]) => getExtendedGridCell('#tab_invitations', cellContent, gridColumns.invitations.invitationsGrid, column),
            },
            
            routes: {
                saveUser: {
                    path: "api/admin/saveUser",
                    method: "POST",
                },
                getUsers: {
                    path: "api/admin/getUsers",
                    method: "POST",
                },
                getActiveCustomers: {
                    path: "api/admin/getActiveCustomers",
                    method: "POST",
                },
                getWarehouses: {
                    path: "api/admin/getWarehouses",
                    method: "POST",
                },
                getCostPools: {
                    path: "api/admin/getCostPools",
                    method: "POST",
                },
                getUserRoles: {
                    path: "api/admin/getUserRoles",
                    method: "GET",
                },
                listSettings: {
                    path: "api/admin/listSettings",
                    method: "GET",
                },
            },
        },

        globalNotificationSettings: {
            form: () => cy.get('#notificationSettingsForm'),
            notificationSwitcher: () => cy.get('#notificationEnabled'),
            notificationTypeDropdown: () => cy.get('#notificationType'),
            mediaTypeDropdown: () => cy.get('#notificationMediaType'),
            intervalDropdown: () => cy.get('#notificationInterval'),
            weeklySendOccurrenceDropdown: () => cy.get('#notificationWeeklySendOccurrence'),
            pendingDaysInput: () => cy.get('#input_pendingDays'),
            sendTimeDateInput: () => cy.get('#input_notificationSendTime'),
            pausedFromDateInput: () => cy.get('#input_notificationPausedFrom'),
            pausedUntilDateInput: () => cy.get('#input_notificationPausedUntil'),
            backButton: () => cy.get('#back'),
            saveButton: () => cy.get('#save'),
            routes: {
                saveGlobalNotificationSetting: {
                    path: "api/Admin/saveGlobalNotificationSetting",
                    method: "POST",
                },
            },
        },

        catalogManagement: {
            editButton: () => cy.get('#editButton'),
            categoriesAndProductsList: () => cy.get('#categoriesAndProductsList'),
            breadCrumbsHomeIcon: () => cy.get('#breadCrumbsHome'),
            searchInput: () => cy.get('#input_search'),
            searchButton: () => cy.get('#searchButton'),
            category: {
                addCategoryButton: () => cy.get('#addCategory'),
                categoryModal: () => cy.get('#addCategoryModal'),
                categoryNameInput: () => cy.get('#input_categoryName'),
                parentCategoryDropdown: () => cy.get('#parentCategory'),
                categoryIconInput: () => cy.get('#input_categoryIcon'),
                cancelCategoryChangesButton: () => cy.get('#cancelCategoryChanges'),
                saveCategoryButton: () => cy.get('#saveCategory'),
                goIntoCategoryButton: () => cy.get('#goIntoCategory'),
                deleteCategoryButton: () => cy.get('#deleteCategory'),
            },
            product: {
                addProductButton: () => cy.get('#addProduct'),
                productModal: () => cy.get('#addProductModal'),
                productNameInput: () => cy.get('#input_productName'),
                productCategoryDropdown: () => cy.get('#productCategory'),
                productExternalIdInput: () => cy.get('#input_productExternalId'),
                productIconInput: () => cy.get('#input_productIcon'),
                productPriceInput: () => cy.get('#input_productPrice'),
                productUnitDropdown: () => cy.get('#productUnit'),
                productCustomUnitInput: () => cy.get('#input_productCustomUnit'),
                productKeywordsInput: () => cy.get('#input_productKeywords'),
                deleteProductButton: () => cy.get('#deleteProduct'),
                restoreProductButton: () => cy.get('#restoreProduct'),
                cancelProductChangesButton: () => cy.get('#cancelProductChanges'),
                saveProductButton: () => cy.get('#saveProduct'),
            },
            routes: {
                getAllCategories: {
                    path: "api/rentaTasks/getAllCategories",
                    method: "POST",
                },
                addCategory: {
                    path: "api/admin/addCategory",
                    method: "POST",
                },
                saveCategory: {
                    path: "api/admin/saveCategory",
                    method: "POST",
                },
                getAllProducts: {
                    path: "api/rentaTasks/getAllProducts",
                    method: "POST",
                },
                addProduct: {
                    path: "api/admin/addProduct",
                    method: "POST",
                },
                saveProduct: {
                    path: "api/admin/saveProduct",
                    method: "POST",
                },
                deleteProduct: {
                    path: "api/admin/deleteProduct",
                    method: "POST",
                },
            },
        },

        formDefinitions: {
            topItems: {
                showDeletedCheckbox: () => cy.get('#showDeleted'),
                addNewFormDefinition: () => cy.get('#addNewFormDefinition')
            },
            formDefinitionsList: () => cy.get("#formDefinitionsList"),
            formDefinitionItem: () => cy.get(".form-definition-list-item"),
            copyFormDefinitionItemButton: () => cy.get(".copy-form-definition")
        },
        addFormDefinitionModal: {
            modal: () => cy.get('#formDefinition'),
            nameInput: () => cy.get('#input_name'),
            customTypeDropdown: () => cy.get('#customType'),
            description: () => cy.get('#input_description'),
            reportingHeadersDropdown: () => cy.get('#headers'),
            workOrdersDropdown: () => cy.get('#mappingWorkOrderTypes'),
            timeslotDropdown: () => cy.get('#mappingTimeslot'),
            required: () => cy.get('#mappingRequire'),
            multiple: () => cy.get('#mappingMultiple'),
            approvable: () => cy.get('#mappingApprovable'),
            mounterSignaturesRequired: () => cy.get('#mappingMounterSignaturesRequired'),
            saveButton: () => cy.get('#saveFormDefinition'),
            restoreButton: () => cy.get('#restoreFormDefinition'),
            deleteButton: () => cy.get('#deleteFormDefinition'),
            backButton: () => cy.get('#backButton'),
            steps: {
                stepsContainer: () => cy.get("#formDefinitionSteps"),
                icon: (index: number) => cy.get(`#input_icon_${index}`),
                group: (index: number) => cy.get(`#input_group_${index}`),
                name: (index: number) => cy.get(`#input_name_${index}`),
                typeDropdown: (index: number) => cy.get(`#type_${index}`),
                manual: (index: number) => cy.get(`#input_manual_${index}`),
                navigation: {
                    addStep: (index: number) => cy.get(`#add_step_${index}`),
                    moveStepUp: (index: number) => cy.get(`#move_up_step_${index}`),
                    moveStepDown: (index: number) => cy.get(`#move_down_step_${index}`),
                    removeStep: (index: number) => cy.get(`#delete_step_${index}`),
                },
                checksStep: {
                    addNewCheck: (index: number) => cy.get(`#addNewCheck_${index}`),
                    checkItemNameInput: (index: number, checkItemIndex: number) => cy.get(`#input_checkItemName_${index}_${checkItemIndex}`),
                    checkItemRequiredCheckbox: (index: number, checkItemIndex: number) => cy.get(`#checkItemIsRequired_${index}_${checkItemIndex}`),
                    checkItemDelete: (index: number, checkItemIndex: number) => cy.get(`#check_item_delete_${index}_${checkItemIndex}`),
                },
                optionsStep: {
                    addNewOption: (index: number) => cy.get(`#addNewOption_${index}`),
                    requiredCheckbox: (index: number) => cy.get(`#required_${index}`),
                    multipleCheckbox: (index: number) => cy.get(`#multiple_${index}`),
                    commentCheckbox: (index: number) => cy.get(`#comment_required_${index}`),
                    optionItemNameInput: (index: number, optionItemIndex: number) => cy.get(`#input_optionItemName_${index}_${optionItemIndex}`),
                    optionItemDelete: (index: number, optionItemIndex: number) => cy.get(`#option_item_delete_${index}_${optionItemIndex}`),
                },
                resourceStep: {
                    inputMin: (index: number) => cy.get(`#input_min_${index}`),
                    inputMax: (index: number) => cy.get(`#input_max_${index}`),
                    unlimitedCheckbox: (index: number) => cy.get(`#unlimited_${index}`),
                    inputStep: (index: number) => cy.get(`#input_input_valueStep_${index}`),
                    inputDefault: (index: number) => cy.get(`#input_input_default_${index}`),
                },
                textStep: {
                    requiredCheckbox: (index: number) => cy.get(`#required_${index}`),
                    inputRows: (index: number) => cy.get(`#input_rows_${index}`),
                    inputMin: (index: number) => cy.get(`#input_min_${index}`),
                    inputMax: (index: number) => cy.get(`#input_max_${index}`),
                }
            }
        },
        mobileFormSteps:{
            nextButton: () => cy.get('div[title="Go to next step"]'),
            previousButton: () => cy.get('div[title="Return to previous step"]'),
            questionStep: {
                okButton: () => cy.get('#okButton')
            },
            mountersSignaturesStep: {
                mountersSignaturesDropdown: () => cy.get('#mountersSignaturesDropdown'),
                signatureWidget: () => cy.get(".athenaeum-widget-container-widget a"),
                signatureCanvas: () => cy.get(".athenaeum-widget-container-signaturePad"),
                signatureNameClarification: () => cy.get(".signature-name-clarification input")
            },
            summaryStep: {
                completeButton: () => cy.get('#completeForm')
            }
        },

        warehouses: {
            warehousesTab: {
                tab: () => cy.get("#tab_warehousesTab"),
                warehousesGrid: () => cy.get('#table_warehousesGrid'),
                warehousesGridCell: (name: string, column: typeof gridColumns.warehouses.warehousesGrid[number]) => getGridCell(name, gridColumns.warehouses.warehousesGrid, column),
                warehousesGridToolbarItems: {
                    showDeletedWarehousesCheckbox: () => cy.get('#showDeletedWarehouses'),
                    reloadWarehousesButton: () => cy.get('#reloadWarehouses'),
                    addWarehouseButton: () => cy.get('#addWarehouse'),
                },
                warehouseCostPoolsGridToolbarItems: {
                    showDeletedWarehouseCostPoolsCheckbox: () => cy.get('#showDeletedWarehouseCostPools'),
                    reloadWarehouseCostPoolsButton: () => cy.get('#reloadWarehouseCostPools'),
                    addWarehouseCostPoolButton: () => cy.get('#addWarehouseCostPool').filter(':visible'),
                },
                warehouseCostPoolsGrid: () => cy.get('#table_warehouseCostPoolsGrid').filter(':visible'),
                warehouseCostPoolsGridCell: (name: string, column: typeof gridColumns.warehouses.warehouseCostPoolsGrid[number]) => getGridCell(name, gridColumns.warehouses.warehouseCostPoolsGrid, column),
            },
            costPoolsTab: {
                tab: () => cy.get("#tab_costPoolsTab"),
                costPoolsGrid: () => cy.get('#table_costPoolsGrid'),
                costPoolsGridCell: (name: string, column: typeof gridColumns.warehouses.costPoolsGrid[number]) => getGridCell(name, gridColumns.warehouses.costPoolsGrid, column),
                costPoolsGridToolbarItems: {
                    showDeletedCostPoolsCheckbox: () => cy.get('#showDeletedCostPools'),
                    reloadCostPoolsButton: () => cy.get('#reloadCostPools'),
                    addCostPoolButton: () => cy.get('#addCostPool'),
                },
            },
            routes: {
                getWarehouseCostPools: {
                    path: "api/admin/GetWarehouseCostPools",
                    method: "POST"
                }
            },
        },

        workOrder: {
            form: () => cy.get('#workOrderForm'),
            customerAndSiteDropdown: () => cy.get('#workOrderSiteOrWarehouse'),
            numberInput: () => cy.get('#input_workOrderNumber'),
            statusInput: () => cy.get('#input_workOrderStatus'),
            nameInput: () => cy.get('#input_workOrderName'),
            invoiceReferenceInput: () => ('#input_workOrderInvoiceReference'),
            descriptionInput: () => ('#input_workOrderDescription'),
            jobSummaryInput: () => ('#input_workOrderJobSummary'),
            workOrderTypeDropdown: () => cy.get('#workOrderType'),
            mountersDropdown: () => cy.get('#workOrderMounters'),
            managerDropdown: () => cy.get('#workOrderManager'),
            startInput: () => cy.get('#input_workOrderStart'),
            endInput: () => cy.get('#input_workOrderEnd'),
            plannedEndInput: () => cy.get('#input_workOrderPlannedEnd'),
            pricePerKilometerInput: () => cy.get('#input_workOrderPricePerKilometer'),
            pricePerHourInput: () => cy.get('#input_workOrderPricePerHour'),
            costInput: () => cy.get('#input_workOrderCost'),
            ordererFullName: () => cy.get('#input_ordererFullName'),
            assignCustomerOrdererButton: () => cy.get('#assignCustomerOrdererButton'),
            approverFullName: () => cy.get('input#input_approverFullName'),
            approvalDate: () => cy.get('#input_approverFullName_workOrderApprovedAt'),
            approvalType: () => cy.get('#input_approverFullName_workOrderApprovalType'),
            saveButton: () => cy.get('#workOrderSave'),
            deleteButton: () => cy.get('#workOrderRemove'),
            formsButton: () => cy.get('#forms'),
            copyButton: () => cy.get('#workOrderCopy'),
            previewButton: () => cy.get('#workOrderPreview'),
            activateButton: () => cy.get('#workOrderActivate'),
            completeButton: () => cy.get('#workOrderComplete'),
            unlockButton: () => cy.get('#workOrderUnApprove'),
            invoiceButton: () => cy.get('#workOrderInvoice'),
            unInvoiceButton: () => cy.get('#workOrderUnInvoice'),
            sendOrApproveButton: () => cy.get('#sendWorkReportModalFormSendOrApprove'),
            workOrderPreview: () => cy.get('#workOrderPreview_actions span:nth-of-type(1)'),
            invoicePreview: () => cy.get('#workOrderPreview_actions span:nth-of-type(2)'),
            sendWorkOrderReport: () => cy.get('#sendWorkReportModalFormSendOrApprove_actions span:nth-of-type(1)'),
            markAsApproved: () => cy.get('#sendWorkReportModalFormSendOrApprove_actions span:nth-of-type(2)'),
            routes: {
                createWorkOrder: {
                    path: "/api/workOrders/createWorkOrder",
                    method: "POST"
                },
                copyWorkOrder: {
                    path: "/api/workOrders/copyWorkOrder",
                    method: "POST"
                },
                getWorkOrder: {
                    path: "/api/constructionSiteManagement/getWorkOrder",
                    method: "POST"
                },
                getWorkOrderForms: {
                    path: "/api/constructionSiteManagement/getWorkOrderForms",
                    method: "POST"
                },
                getWorkOrderPdf : {
                    path: "/api/workOrders/getWorkOrderPdf",
                    method: "POST"
                },
                getWorkOrderPdfWithPrices: {
                    path: "/api/workOrders/getWorkOrderPdfWithPrices",
                    method: "POST"
                },
                activateWorkOrder: {
                    path: "/api/workOrders/activateWorkOrder",
                    method: "POST"
                },
                completeWorkOrder: {
                    path: "/api/workOrders/completeWorkOrder",
                    method: "POST"
                },
                approveWorkOrderByEmail: {
                    path: "/api/workOrders/approveWorkOrderByEmail",
                    method: "POST"
                },
                setWorkOrderNotReadyForInvoice: {
                    path: "/api/workOrders/setWorkOrderNotReadyForInvoice",
                    method: "POST"
                },
                setInvoiced: {
                    path: "/api/workOrders/setInvoiced",
                    method: "POST"
                },
                unInvoice: {
                    path: "/api/workOrders/unInvoice",
                    method: "POST"
                },
                deleteWorkOrder: {
                    path: "/api/workOrders/deleteWorkOrder",
                    method: "POST"
                },
                getContactPersons: {
                    path: "/api/workOrders/getContactPersons",
                    method: "POST"
                },
                getWorkOrderTypes: {
                    path: "/api/workOrderType/getWorkOrderTypes",
                    method: "POST"
                },
                getWorkOrdersPagedList: {
                    path: "/api/workOrders/getWorkOrdersPagedList",
                    method: "POST"
                },
                getMounters: {
                    path: "api/workOrders/getMounters",
                    method: "POST",
                },
                getWorkOrderEquipment: {
                    path: "api/WorkOrders/getWorkOrderEquipment",
                    method: "POST",
                },
                signIn: {
                    path: "api/RentaTasks/signIn",
                    method: "POST",
                },
            }
        },
        
        constructionSiteManagement: {
            constructionSiteManagementTabsContainer: {
                constructionSiteManagementTabs: () => cy.get('#constructionSiteManagementTabs'),
                
                workOrders: {
                    tab: () => cy.get('#tab_workOrders'),
                },
                
                forms: {
                    tab: () => cy.get('#tab_forms'),
                },
                
                projectAttachments: {
                    tab: () => cy.get('#tab_projectAttachments'),
                },
                
                contract: {
                    tab: () => cy.get('#tab_contract'),    
                },
                
                subcontractors: {
                    tab: () => cy.get('#tab_subcontractors'),
                    subcontractorsGridHeading: {
                        heading: () => cy.get('#subcontractorsHeading'),
                        addSubcontractorButton: () => cy.get('#addSubcontractor'),
                        showDeletedCheckbox: () => cy.get('#showDeletedSubcontractors i'),
                        reloadButton: () => cy.get('#reload'),
                    },
                    addSubcontractorModal: {
                        modal: () => cy.get('#addSubcontractorModal'),
                        subcontractorsDropdown: () => cy.get('#addSubcontractorModal_subcontractors'),
                        newCheckBox: () => cy.get('#addSubcontractorModal_new'),
                        nameInput: () => cy.get('#addSubcontractorModal_name'),
                        vatIdInput: () => cy.get('#input_addSubcontractorModal_vatId'),
                        addressInput: () => cy.get('#addSubcontractorModal_address input'),
                        saveButton: () => cy.get('#addSubcontractorModal_save'),
                    },
                    subcontractorsGrid: () => cy.get('#table_subcontractorsGrid'),
                    subcontractorsGridRow: (cellContent: string) => getExtendedGridRow('#table_subcontractorsGrid', cellContent),
                    subcontractorsGridCell: (cellContent: string, column: typeof gridColumns.subcontractorsPanel.subcontractorsGrid[number]) => getExtendedGridCell('#table_subcontractorsGrid', cellContent, gridColumns.subcontractorsPanel.subcontractorsGrid, column),
                },
            },
            routes: {
                getMounters: {
                    path: "api/workOrders/getMounters",
                    method: "POST"
                },
                getManagers: {
                    path: "api/workOrders/getManagers",
                    method: "POST"
                },
                getWorkOrdersPagedList: {
                    path: "api/workOrders/getWorkOrdersPagedList",
                    method: "POST"
                },
                getSubcontractorContracts: {
                    path: "api/constructionSiteManagement/getSubcontractorContracts",
                    method: "POST"
                },
                listSubcontractors: {
                    path: "api/constructionSiteManagement/listSubcontractors",
                    method: "POST"
                },
                saveSubcontractor: {
                    path: "api/constructionSiteManagement/saveSubcontractor",
                    method: "POST"
                },
                addSubcontractor: {
                    path: "api/constructionSiteManagement/addSubcontractor",
                    method: "POST"
                },
                deleteConstructionSiteSubcontractor: {
                    path: "api/constructionSiteManagement/deleteConstructionSiteSubcontractor",
                    method: "POST"
                },
                restoreConstructionSiteSubcontractor: {
                    path: "api/constructionSiteManagement/restoreConstructionSiteSubcontractor",
                    method: "POST"
                },
            },
        },

        workOrderDetailsPanel: {
            extraChargesTab: {
                tab: () => cy.get('#tab_extraCharge'),
                extraChargesGrid: () => cy.get('#table_extraCharges'),
                extraChargesGridCell: (extraChargeNumberOrComment: string, column: typeof gridColumns.workOrderDetailsPanel.extraChargesGrid[number]) => getGridCell(extraChargeNumberOrComment, gridColumns.workOrderDetailsPanel.extraChargesGrid, column),
                extraChargesGridToolbar: {
                    addExtraChargeButton: () => cy.get('#addExtraCharge'),
                },
            },
            rentalEquipmentButton: () => cy.get('#tab_rentalEquipment'),
            rentalEquipmentGrid: () => cy.get('#table_rentalEquipmentGrid'),
            rentalEquipmentGridCell: (actionType: string, column: typeof gridColumns.workOrders.rentalEquipmentGrid[number]) => getGridCell(actionType, gridColumns.workOrders.rentalEquipmentGrid, column),
            addRentalEquipmentButton: () => cy.get('#workOrderAddRentalEquipment'),
            saveAction: () => cy.get('.athenaeum-grid-gridRow .athenaeum-grid-actions .fa-save'),
            routes: {
                saveWorkOrderExtraCharge: {
                    path: "api/workOrderExtraCharge/saveWorkOrderExtraCharge",
                    method: "POST",
                },
            },
        },

        workOrders: {
            toolbar: {
                toolbarContainer: () => cy.get('#workOrdersToolbar'),
                addNewWorkOrderButton: () => cy.get('#addNewWorkOrderButton'),
                worksOrderPanelGrid: () => cy.get("#table_workOrdersPanelGrid")
            },
            workOrdersGridCell: (workOrderName: string, column: typeof gridColumns.workOrders.workOrdersGrid[number]) => getGridCell(workOrderName, gridColumns.workOrders.workOrdersGrid, column),
        },

        mobileWorkOrder: {
            workOrdersList: () => cy.get('#workOrdersList'),
            workOrderFormsButton: () => cy.get('#workOrderFormsButton'),
            workOrderEditButton: () => cy.get('#workOrderEditButton'),
            workOrderAddEquipmentButton: () => cy.get('#workOrderAddEquipmentButton'),
            workOrderAddHoursAndDistancesButton: () => cy.get('#workOrderAddHoursAndDistancesButton'),
            workOrderCompleteButton: () => cy.get('#workOrderCompleteButton'),
            workOrderInfo: () => cy.get('#workOrderInfo'),
            addEquipment: {
                addRentalEquipmentButton: () => cy.get('#addRentalEquipmentButton'),
                addRentalEquipmentModal: {
                    modal: () => cy.get('#addRentalItemsModal'),
                    nameInput: () => cy.get('#input_rentalEquipmentName'),
                    externalIdInput: () => cy.get('#input_rentalEquipmentExternalId'),
                    amountInput: () => cy.get('#input_rentalEquipmentAmount'),
                    dateInput: () => cy.get('#input_rentalEquipmentRentDate'),
                    actionTypeDropdown: () => cy.get('#rentalEquipmentActionType'),
                    commentInput: () => cy.get('#input_rentalEquipmentComment'),
                    cancelButton: () => cy.get('#cancelAddRentalEquipment'),
                    saveButton: () => cy.get('#saveAddRentalEquipment')
                },
                catalogItemsList: () => cy.get('#categoriesAndProductsList')
            },
            jobSummary: {
                rentalEquipmentAccordion:() => cy.get('#rentalEquipmentAccordion')
            },
            completeWizard: {
                workOrderHoursPanel: () => cy.get('#WorkOrderHours > a'),
                approvalTypePanel: () => cy.get('#approvalType > a'),
                approvalTypeDropdown: () => cy.get('#dropdown_approvalType'),
                addUserHours: () => cy.get('#addUserHours')
            }
        },

        workOrderTypeManagement: {
            workOrderTypesGrid: () => cy.get('#table_workOrderTypesGrid'),
            workOrderTypesGridCell: (name: string, column: typeof gridColumns.workOrderTypeManagement.workOrderTypesGrid[number]) => getGridCell(name, gridColumns.workOrderTypeManagement.workOrderTypesGrid, column),
            workOrderTypesGridToolbarItems: {
                addWorkOrderTypeButton: () => cy.get('#addWorkOrderType'),
            },
            routes: {
                createWorkOrderType: {
                    path: "api/admin/createWorkOrderType",
                    method: "POST",
                },
                saveWorkOrderType: {
                    path: "api/admin/saveWorkOrderType",
                    method: "POST",
                },
            },
        },
    } as const;
}

/**
 * @param route Route to intercept.
 * @return Alias of the interception, which can be waited with {@link cy.wait}.
 * @see pageData
 * @see executeWithIntercept
 */
export function intercept(route: RouteData): string {
    const alias: string = route.path + new Date().toISOString();
    cy.intercept(route.method, route.path).as(alias);

    cy.log(`Intercept route '${route.path}' as alias '${alias}'`);

    return `@${alias}`;
}

/**
 * Execute a function and wait for intercepted routes.
 *
 * @param func Function to execute.
 * @param routes Routes which interceptions to register before and wait after function execution.
 * @see intercept
 */
export function executeWithIntercept(func: () => any, routes: RouteData[]): void {
    const interceptions: string[] = routes.map(route => intercept(route));
    func();
    cy.wait(interceptions);
}

/**
 * Execute a function and wait for /api/Application/OnRedirect.
 * @param func Function to execute.
 * @see executeWithIntercept
 */
export function onRedirect(func: () => any): void {
    executeWithIntercept(
        func,
        [{
            path: "/api/Application/OnRedirect",
            method: "POST",
        }]
    );
}

export function getRandomPhoneNumber(): string {
    return `+358${getRandomInteger(111111111, 999999999)}`;
}

export function getRandomInteger(minValue: number | null = null, maxValue: number | null = null): number {
    if (minValue === null && maxValue === null) {
        // Default range is from 0 to 9999999
        minValue = 0;
        maxValue = 9999999;
    }

    if ((minValue != null && maxValue != null) && (minValue > maxValue)) {
        throw new Error(`A 'minValue' cannot be greater than 'maxValue'. minValue=${minValue}, maxValue=${maxValue}.`);
    }

    if (maxValue != null) {
        return Math.floor(Math.random() * (maxValue + 1));
    }

    if (minValue != null) {
        return Math.floor(Math.random() * (minValue + 1));
    }

    return Math.floor(Math.random() * (maxValue! - minValue! + 1)) + minValue!;
}