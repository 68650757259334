import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import PageDefinitions from "../../providers/PageDefinitions";
import {ButtonType, Checkbox, Icon, IconSize, IconStyle, Inline, PageContainer, PageHeader, PageRow, ToolbarButton} from "@renta-apps/athenaeum-react-components";
import FormDefinition from "@/models/server/forms/FormDefinition";
import {ApiProvider, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import Localizer from "../../localization/Localizer";

import styles from "./FormDefinitions.module.scss";

interface IFormDefinitionsProps {
}

interface IFormDefinitionsState {
    definitions: FormDefinition[];
    showDeleted: boolean;
}

export default class FormDefinitions extends AuthorizedPage<IFormDefinitionsProps, IFormDefinitionsState> {

    state: IFormDefinitionsState = {
        definitions: [],
        showDeleted: false
    };

    private get showDeleted(): boolean {
        return this.state.showDeleted;
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
    }

    private async loadDataAsync(): Promise<void> {

        const definitions: FormDefinition[] = await ApiProvider.postAsync("api/form/listFormDefinitions", null);

        definitions.order(definition => definition.name);

        await this.setState({definitions});
    }

    private async reviewReportDefinitionAsync(definition: FormDefinition): Promise<void> {
        const route: PageRoute = PageDefinitions.formDefinition(definition.id);

        await PageRouteProvider.redirectAsync(route);
    }

    private async addReportDefinitionAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.formDefinition());
    }

    private async copyFormDefinitionAsync(formDefinition: FormDefinition): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.formDefinition(null, formDefinition));
    }

    private get definitions(): FormDefinition[] {
        return this.state.definitions.filter(item => ((!item.deleted) || (this.showDeleted)));
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.loadDataAsync();
    }

    private getDefinitionTitle(definition: FormDefinition): string {
        return Localizer.get(Localizer.formDefinitionsPageDefinitionTitle, definition.name, definition.items.length);
    }

    public renderDefinition(item: FormDefinition, index: number): React.ReactNode {
        const isDeletedStyle: any = item.deleted && `${styles.deleted} form-definition-deleted`;

        return (
            <tr className={this.css(styles.formDefinitionItemRow, "form-definition-list-item")}
                key={index}>
                <td className={styles.itemInfo}>
                    <div className={"d-flex"}>
                    <span id={`formDefinition_${index}`}
                          className={this.css(styles.hover, isDeletedStyle, "cursor-pointer")}
                          onClick={() => this.reviewReportDefinitionAsync(item)}>
                          {item.name}
                        <br/>
                        <small>{this.getDefinitionTitle(item)}</small>
                    </span>

                    <div className={this.css(styles.copyItem, styles.hover, "cursor-pointer", "copy-form-definition")}
                         onClick={() => this.copyFormDefinitionAsync(item)}>

                        <Icon name={"far copy"}
                              style={IconStyle.Solid}
                              size={IconSize.Normal}
                              tooltip={Localizer.formDefinitionsPageIconTooltipCopyFormDefinition}
                        />
                    </div>
                    </div>
                </td>
            </tr>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={this.css(styles.formDefinitions)}>

                <PageHeader title={Localizer.formDefinitionsPageTitle} subtitle={Localizer.formDefinitionsPageSubtitle}/>

                <PageRow>

                    <div className="col">

                        <Inline>

                            <Checkbox id="showDeleted" inline
                                      label={Localizer.formDefinitionsPageShowDeleted}
                                      value={this.showDeleted}
                                      onChange={async (_, value) => await this.setShowDeletedAsync(value)}
                            />

                            <ToolbarButton id="addNewFormDefinition"
                                           label={Localizer.formDefinitionsPageAddNew}
                                           icon={{name: "plus", size: IconSize.Large}}
                                           type={ButtonType.Orange}
                                           onClick={async () => await this.addReportDefinitionAsync()}
                            />

                        </Inline>

                        <table className={styles.table} id="formDefinitionsList">
                            <tbody>
                            {
                                this.definitions.map((item, index) => this.renderDefinition(item, index))
                            }
                            </tbody>
                        </table>

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}