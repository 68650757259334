import React from "react";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import {AddressDivider, Button, ButtonContainer, ButtonType, Dropdown, DropdownRequiredType, Form, NumberInput, Panel, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import {CommunicationLanguage, ConstructionSiteStatus} from "@/models/Enums";
import ConstructionSite from "../../../models/server/ConstructionSite";
import SaveConstructionSiteRequest from "../../../models/server/requests/SaveConstructionSiteRequest";
import SaveConstructionSiteResponse from "@/models/server/responses/SaveConstructionSiteResponse";
import {DefaultPrices} from "@/models/server/DefaultPrices";
import {ActivateConstructionSiteRequest} from "@/models/server/requests/ActivateConstructionSiteRequest";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "../../../localization/Localizer";
import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import LocationValidator from "@/helpers/LocationValidator";
import {FeatureSwitch} from "@/components/FeatureSwitch/FeatureSwitch";
import FeatureFlags from "@/helpers/FeatureFlags";
import EnumProvider from "@/providers/EnumProvider";
import UserContext from "@/models/server/UserContext";
import LocalizationHelper from "@/helpers/LocalizationHelper";

interface IConstructionSitePanelProps {
    constructionSite: ConstructionSite;
    defaultPrices: DefaultPrices;
    readonly?: boolean;
    submit(sender: ConstructionSitePanel, request: SaveConstructionSiteRequest): Promise<SaveConstructionSiteResponse>
    setActiveAsync(sender: ConstructionSitePanel, request: ActivateConstructionSiteRequest, active: boolean): Promise<void>;
}

interface IConstructionSitePanelState {
    /** Has the location changed and not been saved? */
    unsavedLocation: boolean,
    externalId: string;
    projectNumber: string | null;
    name: string;
    location: GeoLocation | null;
    mileagePrice: number;
    hoursPrice: number;
    alarmJobPrice: number;
    invoiceReference: string;
    communicationLanguage: CommunicationLanguage;
}

export default class ConstructionSitePanel extends BaseComponent<IConstructionSitePanelProps, IConstructionSitePanelState> {

    public state: IConstructionSitePanelState = {
        unsavedLocation: false,
        externalId: "",
        projectNumber: null,
        name: "",
        location: null,
        invoiceReference: "",
        mileagePrice: 0,
        hoursPrice: 0,
        alarmJobPrice: 0,
        communicationLanguage: LocalizationHelper.defaultLanguage,
    };

    private get location(): GeoLocation | null {
        return this.state.location || this.props.constructionSite.location;
    }

    private get constructionSite(): ConstructionSite {
        return this.props.constructionSite;
    }

    private get readonly(): boolean {
        return !!this.props.readonly;
    }

    private get isAdmin(): boolean {
        const context: UserContext = ch.getContext() as UserContext;
        return context.isAdmin;
    }

    private async onLocationChangeAsync(location: GeoLocation): Promise<void> {
        await this.setState({
            location: location,
            unsavedLocation: true
        });
    }

    private async onNameChangeAsync(value: string) {
        await this.setState({name: value});
    }

    private async onInvoiceReferenceChangeAsync(value: string) {
        await this.setState({invoiceReference: value});
    }

    private async onHoursPriceChangeAsync(value: number) {
        await this.setState({hoursPrice: value});
    }

    private async onMileagePriceChangeAsync(value: number) {
        await this.setState({mileagePrice: value});
    }

    private async onAlarmJobPriceChangeAsync(value: number) {
        await this.setState({alarmJobPrice: value});
    }

    private async onCommunicationLanguageChangeAsync(value: CommunicationLanguage) {
        await this.setState({communicationLanguage: value});
    }

    private async onExternalIdChangeAsync(value: string) {
        await this.setState({externalId: value});
    }

    private async onProjectNumberChangeAsync(value: string) {
        await this.setState({projectNumber: value});
    }

    private async constructionSiteSubmitAsync(): Promise<void> {
        const location: GeoLocation | null = LocationValidator.isLocationValid(this.location)
            ? this.location
            : null;

        const request: SaveConstructionSiteRequest = {
            // Properties that can be edited
            name: this.state.name,
            location: location,
            hoursPrice: this.state.hoursPrice,
            mileagePrice: this.state.mileagePrice,
            alarmJobPrice: this.state.alarmJobPrice,
            invoiceReference: this.state.invoiceReference,
            externalId: this.state.externalId,
            projectNumber: this.state.projectNumber,
            communicationLanguage: this.state.communicationLanguage,

            // Properties that can't be edited
            constructionSiteId: this.props.constructionSite.id,
            organizationContractId: this.props.constructionSite.organizationContractId,
            status: this.props.constructionSite.status,
        };

        await this.props.submit(this, request);
        await this.setState({unsavedLocation: false});
    }

    private get defaultMileagePrice(): number {
        return this.constructionSite.mileagePrice ?? this.props.defaultPrices.defaultMileagePrice;
    }

    private get defaultHourPrice(): number {
        return this.constructionSite.hoursPrice ?? this.props.defaultPrices.defaultHoursPrice;
    }

    private get defaultAlarmJobPrice(): number {
        return this.constructionSite.alarmJobPrice ?? this.props.defaultPrices.defaultAlarmJobPrice;
    }

    private async toggleConstructionSiteActivationAsync(): Promise<void> {
        const active: boolean | null = (this.constructionSite!.status == ConstructionSiteStatus.Active)
            ? true
            : (this.constructionSite!.status == ConstructionSiteStatus.Inactive)
                ? false : null;

        const request: ActivateConstructionSiteRequest = {
            constructionSiteId: this.props.constructionSite.id,
            mileagePrice: this.state.mileagePrice,
            hoursPrice: this.state.hoursPrice,
            alarmJobPrice: this.state.alarmJobPrice,
        };


        if (active !== null) {
            await this.props.setActiveAsync(this, request, !active);
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.setState({
            externalId: this.props.constructionSite.externalId,
            projectNumber: this.props.constructionSite.projectNumber,
            name: this.props.constructionSite.name,
            invoiceReference: this.props.constructionSite.invoiceReference,
            hoursPrice: this.defaultHourPrice,
            mileagePrice: this.defaultMileagePrice,
            alarmJobPrice: this.defaultAlarmJobPrice,
            communicationLanguage: this.props.constructionSite.communicationLanguage,
        })
    }

    public render(): React.ReactNode {
        return (
            <Panel className="flex-2" title={Localizer.genericConstructionsite}>
                <div className="col-12">
                    {
                        (this.constructionSite) &&
                        (
                            <Form onSubmit={async () => await this.constructionSiteSubmitAsync()}>
                                <TwoColumns>
                                    <TextInput readonly
                                               id="customerName" required
                                               label={Localizer.genericCustomerName}
                                               value={this.constructionSite.organizationContract!.name}
                                    />

                                    {
                                        (ch.isFinland) &&
                                        (
                                            <TextInput readonly
                                                       id="externalId"
                                                       label={Localizer.organizationPanelExternalId}
                                                       value={this.constructionSite.organizationContract!.externalId}
                                            />
                                        )
                                    }

                                    {
                                        (ch.isSweden) &&
                                        (
                                            <TextInput readonly
                                                       id="organizationPanelCustomerNumber"
                                                       label={Localizer.genericCustomerNumber}
                                                       value={this.constructionSite.organizationContract!.customerNumber}
                                            />
                                        )
                                    }
                                </TwoColumns>

                                <TextInput id="name" required
                                           label={Localizer.genericName}
                                           value={this.state.name}
                                           onChange={async (_, value: string) => await this.onNameChangeAsync(value)}
                                />

                                <TextInput required readonly
                                           id="erpName"
                                           label={Localizer.constructionSitePanelExternalName}
                                           value={this.constructionSite.externalName}
                                />

                                {
                                    ((!this.constructionSite.location) && (this.constructionSite.externalAddress)) &&
                                    (
                                        <TextInput id="externalAddress"
                                                   label={Localizer.constructionSitePanelExternalAddress}
                                                   readonly
                                                   value={this.constructionSite.externalAddress}
                                        />
                                    )
                                }

                                <AddressDivider locationPicker required
                                                id="addressPicker"
                                                location={this.location || undefined}
                                                onChange={async (_, location) => await this.onLocationChangeAsync(location)}
                                />

                                <TwoColumns>

                                    <TextInput id="externalId"
                                               noValidate={this.readonly}
                                               label={Localizer.constructionSitePanelERPConstructionSiteId}
                                               readonly={!this.isAdmin}
                                               value={this.state.externalId}
                                               onChange={async (_, value) => await this.onExternalIdChangeAsync(value)}
                                    />

                                    <TextInput id="projectNumber"
                                               noValidate={this.readonly}
                                               label={Localizer.addConstructionsiteProjectNumber}
                                               value={this.state.projectNumber}
                                               onChange={async (_, value) => await this.onProjectNumberChangeAsync(value)}
                                    />

                                </TwoColumns>

                                <TextInput id="externalReference"
                                           noValidate={this.readonly}
                                           label={Localizer.addConstructionSiteReference}
                                           readonly={true}
                                           value={this.constructionSite.externalReference}
                                />

                                <TwoColumns>

                                    <TextInput readonly
                                               id="organizationContract"
                                               label={Localizer.constructionSitePanelOrganizationContract}
                                               value={TransformProvider.toString(this.constructionSite.organizationContract)}
                                    />

                                    <TextInput id="invoiceReference"
                                               noValidate={this.readonly}
                                               label={Localizer.addConstructionSiteInvoiceReference}
                                               readonly={this.readonly}
                                               value={this.state.invoiceReference}
                                               onChange={async (_, value: string) => await this.onInvoiceReferenceChangeAsync(value)}
                                    />

                                </TwoColumns>

                                <TwoColumns>

                                    <NumberInput id="hoursPrice"
                                                 label={Localizer.addConstructionSiteHourPrice}
                                                 step={0.01}
                                                 format="0.0"
                                                 onChange={async (_, value: number) => await this.onHoursPriceChangeAsync(value)}
                                                 value={this.state.hoursPrice}
                                    />

                                    <NumberInput id="mileagePrice"
                                                 label={Localizer.addConstructionSiteMileagePrice}
                                                 step={0.01}
                                                 format="0.0"
                                                 onChange={async (_, value: number) => await this.onMileagePriceChangeAsync(value)}
                                                 value={this.state.mileagePrice}
                                    />

                                </TwoColumns>

                                <TwoColumns>
                                    
                                    <FeatureSwitch flagName={FeatureFlags.AlarmJobs} >
                                        
                                        <NumberInput id="alarmJobPrice"
                                                     label={Localizer.genericDefaultAlarmJobPrice}
                                                     step={0.01}
                                                     format="0.0"
                                                     onChange={async (_, value: number) => await this.onAlarmJobPriceChangeAsync(value)}
                                                     value={this.state.alarmJobPrice}
                                        />
                                        
                                    </FeatureSwitch>

                                    <Dropdown required 
                                              autoGroupSelected
                                              requiredType={DropdownRequiredType.Restricted}
                                              id="communicationLanguage"
                                              label={Localizer.formInputLanguage}
                                              items={EnumProvider.getCommunicationLanguageItems()}
                                              selectedItem={(this.state.communicationLanguage != null) ? EnumProvider.getCommunicationLanguageItem(this.state.communicationLanguage) : undefined}
                                              onChange={async (_, item) => {
                                                  await this.onCommunicationLanguageChangeAsync(parseInt(item!.value))}
                                              }
                                    />
                                </TwoColumns>

                                <ButtonContainer>

                                    {
                                        !(this.constructionSite!.status == ConstructionSiteStatus.Closed) &&
                                        (
                                            <React.Fragment>

                                                {
                                                    (this.constructionSite!.status == ConstructionSiteStatus.Active) &&
                                                    <Button type={ButtonType.Default}
                                                            label={Localizer.constructionSitesDeactivate}
                                                            icon={{name: "far stop-circle"}}
                                                            disabled={(this.state.unsavedLocation)}
                                                            onClick={async () => await this.toggleConstructionSiteActivationAsync()}
                                                            confirm={Localizer.constructionSitePanelDeactivateConfirmation}
                                                    />
                                                }

                                                {
                                                    (this.constructionSite!.status == ConstructionSiteStatus.Inactive) &&
                                                    <Button type={ButtonType.Success}
                                                            label={Localizer.constructionSitesActivate}
                                                            icon={{name: "far play-circle"}}
                                                            disabled={(this.state.unsavedLocation) || (!LocationValidator.isLocationValid(this.constructionSite.location))}
                                                            onClick={async () => await this.toggleConstructionSiteActivationAsync()}
                                                            confirm={Localizer.constructionSitePanelActivateConfirmation}
                                                    />
                                                }

                                            </React.Fragment>
                                        )
                                    }

                                    <Button type={ButtonType.Orange}
                                            label={Localizer.formSave}
                                            icon={{name: "far save"}}
                                            submit
                                    />

                                </ButtonContainer>

                            </Form>
                        )
                    }
                </div>
            </Panel>
        );
    }
};