import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {Button, ButtonType, NumberInput, TextAreaInput} from "@renta-apps/athenaeum-react-components";
import CatalogItem from "../CatalogItem/CatalogItem";
import WorkOrderExtraCharge from "@/models/server/WorkOrderExtraCharge";
import ExtraChargeType from "@/models/server/ExtraChargeType";
import EnumProvider from "@/providers/EnumProvider";
import styles from "./ExtraChargeItem.module.scss";

export interface IExtraChargeItemProps {
    extraCharge: WorkOrderExtraCharge;
    className?: string;
    editing?: boolean;
    expanded?: boolean;
    shoppingCart?: boolean;
    onChange?(sender: ExtraChargeItem, extraCharge: WorkOrderExtraCharge): Promise<void>;
    onDescriptionToggle?(sender: ExtraChargeItem, extraCharge: WorkOrderExtraCharge, expanded: boolean): Promise<void>;
}

interface IExtraChargeState {
    expanded: boolean;
    initialAmount: number;
}

export default class ExtraChargeItem extends BaseComponent<IExtraChargeItemProps, IExtraChargeState> {

    // Inherited / implemented

    public state: IExtraChargeState = {
        expanded: (this.props.expanded === true),
        initialAmount: ExtraChargeItem.getInitialAmount(this.extraCharge),
    }

    public async componentWillReceiveProps(nextProps: IExtraChargeItemProps): Promise<void> {

        const nexExpanded: boolean = (nextProps.expanded !== this.props.expanded);

        await super.componentWillReceiveProps(nextProps);

        if (nexExpanded) {
            const expanded: boolean = (this.props.expanded === true);
            if (expanded != this.state.expanded) {
                await this.setState({expanded});
            }
        }
    }

    // Fields

    private readonly _inputRef: React.RefObject<NumberInput> = React.createRef();

    // Getters

    private get extraCharge(): WorkOrderExtraCharge {
        return this.props.extraCharge;
    }

    private get shoppingCart(): boolean {
        return (this.props.shoppingCart === true);
    }

    private get delta(): number {
        return (this.extraCharge.amount - this.state.initialAmount);
    }

    private get modified(): boolean {
        return (this.delta !== 0);
    }

    private get extraChargeItemId(): string {
        return `extraChargeItem_${this.extraCharge.extraChargeType!.name.replace(/\s/g,'_')}`;
    }

    // Sync-methods

    private static getInitialAmount(extraCharge: WorkOrderExtraCharge): number {
        const tag: string = "__initialAmount";
        const instance = extraCharge as any;
        return (typeof instance[tag] === "number")
            ? instance[tag]
            : (instance[tag] = extraCharge.amount);
    }

    // Async-methods

    private async onChangeDescriptionAsync(description: string): Promise<void> {
        if (this.extraCharge.description !== description) {
            this.extraCharge.description = description;
            await this.reRenderAsync();
        }
    }

    private async onChangeAmountAsync(amount: number): Promise<void> {
        if (this.extraCharge.amount !== amount) {
            this.extraCharge.amount = amount;

            if (amount <= 0) {
                this.extraCharge.description = "";
                this.state.expanded = false;
            }

            await this.reRenderAsync();

            if (this.props.onChange) {
                await this.props.onChange(this, this.extraCharge);
            }
        }
    }

    private async increaseAsync(): Promise<void> {
        if (this._inputRef.current) {
            await this._inputRef.current.increaseAsync();
        }
    }

    private async decreaseAsync(): Promise<void> {
        if (this._inputRef.current) {
            await this._inputRef.current.decreaseAsync();
        }
    }

    private async toggleAsync(): Promise<void> {
        const expanded: boolean = (!this.state.expanded);

        await this.setState({expanded});

        if (this.props.onDescriptionToggle) {
            await this.props.onDescriptionToggle(this, this.extraCharge, expanded);
        }
    }

    // Renders

    private get renderNormal(): JSX.Element {
        const type: ExtraChargeType = this.extraCharge.extraChargeType!;
        const unitName: string = EnumProvider.getExtraChargeTypeUnitText(type.unit);

        const deltaSign: string = (this.delta > 0)
            ? "+"
            : "";

        const descriptionButtonIconName: string = (this.extraCharge.description)
            ? "far comment-alt-dots"
            : "far comment-alt"

        return (
            <React.Fragment>

                <div id={"itemHeader"} className={styles.title}>

                    <div id={"itemName"} className={styles.name}>
                        <span>
                             {type.name} ({unitName})
                        </span>
                    </div>

                    <div id={"itemInitialAmount"} className={styles.initialAmount}>
                        <span>
                            {this.state.initialAmount}
                        </span>
                    </div>

                </div>

                <div id={"itemContent"} className={styles.content}>

                    <div className={styles.actions}>
                        <div>
                            <Button block
                                    id={"itemMinus"}
                                    type={ButtonType.Orange}
                                    icon={{name: "fas minus"}}
                                    onClick={() => this.decreaseAsync()}
                            />
                        </div>
                    </div>

                    <div id={"itemDataContainer"} className={styles.data}>

                        <div id={"itemAmountInputWrapper"} className={styles.input}>
                            <NumberInput hideArrows clickToEdit
                                         ref={this._inputRef}
                                         format="0.0"
                                         min={0}
                                         max={99999.99}
                                         value={this.extraCharge.amount}
                                         onChange={(_, amount) => this.onChangeAmountAsync(amount)}
                            />
                        </div>

                        <div id={"itemDescription"} className={styles.description}>
                            <span>
                                {this.extraCharge.description}
                            </span>
                        </div>

                    </div>

                    <div className={styles.actions}>

                        <div>
                            <Button block
                                    id={"itemPlus"}
                                    type={ButtonType.Orange}
                                    icon={{name: "fas plus"}}
                                    onClick={() => this.increaseAsync()}
                            />

                            {
                                (this.modified) &&
                                (
                                    <span className={styles.delta}>
                                        {deltaSign}{Utility.formatValue(this.delta, "0.0")}
                                    </span>
                                )
                            }

                        </div>

                        <div>
                            <Button block
                                    id={"itemDescriptionToggler"}
                                    type={ButtonType.Orange}
                                    disabled={(this.extraCharge.amount <= 0)}
                                    icon={{name: descriptionButtonIconName}}
                                    onClick={() => this.toggleAsync()}
                            />
                        </div>

                    </div>

                </div>

                <div className={styles.descriptionInput}>
                    {
                        (this.state.expanded) &&
                        (
                            <TextAreaInput autoFocus
                                           id={"itemDescriptionInput"}
                                           value={this.extraCharge.description || ""}
                                           onChange={(_, value) => this.onChangeDescriptionAsync(value)}
                            />
                        )
                    }
                </div>

            </React.Fragment>
        );
    }

    public render(): React.ReactNode {
        const className: string = this.css(
            styles.extraCharge,
            this.props.className,
            (this.shoppingCart) && (this.extraCharge.amount > 0) && styles.active);

        return (
            <CatalogItem id={this.extraChargeItemId}
                         className={className}>
                {
                    this.renderNormal
                }
            </CatalogItem>
        );
    }
};