import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Dropdown, DropdownAlign, DropdownOrderBy, Form, Modal, NumberInput, SelectListItem, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import {EquipmentType, ProductUnit} from "@/models/Enums";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";
import Product from "@/models/server/Product";
import EnumProvider from "@/providers/EnumProvider";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "@/localization/Localizer";

import styles from "./AddCustomEquipmentModal.module.scss";

interface IAddCustomEquipmentModalProps {
    addCustomProduct(sender: AddCustomEquipmentModal, product: WorkOrderEquipment): Promise<void>;
}

interface IAddCustomEquipmentModalState {
    equipment: WorkOrderEquipment;
}

export default class AddCustomEquipmentModal extends BaseComponent<IAddCustomEquipmentModalProps, IAddCustomEquipmentModalState> {

    state: IAddCustomEquipmentModalState = {
        equipment: new WorkOrderEquipment(EquipmentType.Custom)
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    private get equipment(): WorkOrderEquipment {
        return this.state.equipment;
    }

    private async onOpenAsync(): Promise<void> {
        const equipment = new WorkOrderEquipment(EquipmentType.Custom);
        equipment.unit = ProductUnit.Piece;

        await this.setState({ equipment });
    }

    private async onSubmitAsync(): Promise<void> {
        if (this.equipment.amount > 0) {
            await this.props.addCustomProduct(this, this.equipment);

            await this.modal.closeAsync();
        }
    }

    public async setNameAsync(value: string): Promise<void> {
        this.equipment.name = value;
        await this.reRenderAsync();
    }

    public async setUnitAsync(item: SelectListItem): Promise<void> {
        this.equipment.unit = parseInt(item.value);
        await this.reRenderAsync();
    }

    public async setAmountAsync(value: number): Promise<void> {
        this.equipment.amount = value;
        await this.reRenderAsync();
    }

    public async setPriceAsync(value: number): Promise<void> {
        this.equipment.price = value;
        await this.reRenderAsync();
    }

    public async openAsync(): Promise<void> {
        if (this.modal) {
            await this.modal.openAsync();
        }
    }

    public async closeAsync(): Promise<void> {
        if (this.modal) {
            await this.modal.closeAsync();
        }
        await this.reRenderAsync();
    }

    public get isOpen(): boolean {
        return ((this.modal != null) && (this.modal.isOpen));
    }

    public static get modalId(): string {
        return "addCustomProductModal";
    }

    public render(): React.ReactNode {
        return (
            <Modal className={styles.addCustomEquipmentModal}
                   id={AddCustomEquipmentModal.modalId}
                   ref={this._modalRef}
                   title={Localizer.rentaTasksAddEquipmentAddCustomProductModalTitleCustomEquipment}
                   subtitle={Localizer.rentaTasksAddEquipmentAddCustomProductModalSubtitleCustomEquipment}
                   onOpen={async () => await this.onOpenAsync()}
            >

                <div className="row">

                    <div className="col">

                        <Form ref={this._formRef} onSubmit={() => this.onSubmitAsync()}>

                            <TwoColumns>

                                <TextInput required autoFocus
                                           id="name"
                                           label={Localizer.genericName}
                                           value={this.equipment.name || ""}
                                           onChange={(_, value) => this.setNameAsync(value)}
                                />

                                <Dropdown required
                                          id="unit"
                                          label={Localizer.rentaTasksAddEquipmentAddCustomProductModalUnit}
                                          minWidth="105px"
                                          align={DropdownAlign.Left}
                                          orderBy={DropdownOrderBy.None}
                                          items={EnumProvider.getProductUnitItems()}
                                          selectedItem={this.equipment.unit}
                                          onChange={(_, item) => this.setUnitAsync(item!)}
                                />

                            </TwoColumns>

                            <TwoColumns>

                                <NumberInput id="amount"
                                             label={Localizer.rentaTasksAddEquipmentAddCustomProductModalAmount}
                                             step={Product.getStep(this.equipment.unit)}
                                             format="0.0"
                                             value={this.equipment.amount}
                                             min={0.01}
                                             max={99999.99}
                                             onChange={(_, value) => this.setAmountAsync(value)}
                                />

                                {
                                    (RentaTasksController.isManagerOrHigher) &&
                                    (
                                        <NumberInput id="price"
                                                     label={Localizer.rentaTasksAddEquipmentAddCustomProductModalPrice}
                                                     step={0.01}
                                                     format="0.0"
                                                     min={0}
                                                     max={99999.99}
                                                     value={this.equipment.price}
                                                     onChange={(_, value) => this.setPriceAsync(value)}
                                        />
                                    )
                                }

                            </TwoColumns>

                            <div>

                                <Button id={"cancel"}
                                        className={styles.buttons}
                                        type={ButtonType.Light}
                                        label={Localizer.genericActionCancel}
                                        onClick={async () => await this.closeAsync()}
                                />

                                <Button submit
                                        id={"save"}
                                        className={this.css(styles.buttons, "float-right")}
                                        type={ButtonType.Orange}
                                        label={Localizer.genericOk}
                                />

                            </div>

                        </Form>

                    </div>

                </div>

            </Modal>
        )
    }
}