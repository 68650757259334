import {CommunicationLanguage} from "@/models/Enums";
import LocalizationHelper from "@/helpers/LocalizationHelper";

export class SendWorkOrderExternalNotificationRequest {
    
    public workOrderId: string = "";

    public email: string = "";

    public comment: string = "";

    public userId: string = "";

    public requestDate: Date = new Date();
    
    public communicationLanguage: CommunicationLanguage = LocalizationHelper.defaultLanguage;

    public selectedMountersIds: string[] = [];
}