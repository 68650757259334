import User from "@/models/server/User";
import {NotificationStatus} from "@/models/Enums";

export default class NotificationModel {
    public id: string = "";
    
    public userId: string = "";
    
    public user: User = new User();
    
    public reusable: boolean = false;
    
    public processed: boolean = false;
    
    public processedAt: Date | null = null;
    
    public occurrence: Date | null = null;
    
    public status: NotificationStatus = NotificationStatus.Sent;
    
    public workOrderIds: string[] = [];
    
    public globalNotificationSettingId: string | null = null;
    
    public userNotificationSettingId: string | null = null;

    public isNotificationModel: boolean = true;
}