import {CellAction} from "@renta-apps/athenaeum-react-components";

/** Returns a cell action matching the name, or throws if not found. */
export function getCellAction<TItem>(actions: CellAction<TItem>[], name: string): CellAction<TItem> {
    const action: CellAction<TItem> | undefined = actions.find(x => x.action.name === name);
    if (action === undefined) {
        throw new Error(`No action found with name ${name}.`);
    }

    return action;
}