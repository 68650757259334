import {FormTimeslotType} from "@/models/Enums";
import WorkOrderType from "@/models/server/WorkOrderType";

export default class FormMapping {

    /**
     * Types of WorkOrders {@link WorkOrderType} the Form can be attached to.
     */
    public workOrderTypes: WorkOrderType[] = [];

    /**
     * When the Form can be filled and submitted in a Task.
     *
     * NOTE: the timeslot {@link FormTimeslotType.Anytime} is not supported by required Forms.
     *
     * @see require
     */
    public timeslot: FormTimeslotType = FormTimeslotType.Anytime;

    /**
     * Is the Form required to be submitted in the {@link timeslot}, otherwise blocking Task starting or completion.
     *
     * NOTE: the timeslot {@link FormTimeslotType.Anytime} is not supported by required Forms.
     *
     * @see approvable
     */
    public require: boolean = false;

    /**
     * Can multiple instances of the Form be edited at the same time.
     */
    public multiple: boolean = false;

    /**
     * Should the submitted Form be sent to be approved.
     * If {@link require} is also true, the Form needs to be approved to unblock the Task.
     */
    public approvable: boolean = false;

    /**
     * Should the submitted Form be signed by mounters to be approved.
     * If {@link require} is also true, the Form needs to be approved to unblock the Task.
     */
    public mounterSignaturesRequired: boolean = false;

    public isFormMapping: true = true;
}