import {ProductUnit} from "../Enums";
import Category from "./Category";
import {INumberFormat, NumberUtility, TFormat} from "@renta-apps/athenaeum-toolkit";
import User from "@/models/server/User";

export default class Product {
    public id: string = "";

    public externalId: string | null = null;

    public name: string = "";

    public icon: string = "";

    public unit: ProductUnit = ProductUnit.Piece;

    public price: number = 0;

    public customUnit: string | null = null;

    public category: Category = new Category();

    public categoryId: string = "";

    public favorite: boolean | null = null;
    
    public keywords: string = "";

    public deleted: boolean = false;

    public deletedAt: Date | null = null;

    public deletedById: string | null = null;

    public deletedBy: User | null = null;

    public isProduct: boolean = true;

    public static getStep(item: Product | ProductUnit | null): number {
        if (item == null) {
            return 0.01;
        }

        const unit: ProductUnit = (typeof item == "number")
            ? item
            : item.unit;

        return (unit == (ProductUnit.Meter || ProductUnit.Kilometer || ProductUnit.Meter2 || ProductUnit.Liter))
            ? 0.1
            : 1;
    }

    public static getFormat(product: Product | null): TFormat {
        const step: number = Product.getStep(product);
        const numberFormat: INumberFormat = NumberUtility.resolveFormat(step, null);
        return numberFormat.format;
    }

    public static getKeywords(item: Product): string[] {
        const instance = item as any;
        
        if (!instance.__keywords) {
            instance.__keywords = (item.keywords != null)
                ? item.keywords.split(/[,; ]/).map(item => item.toLowerCase())
                : [];
        }

        return instance.__keywords;
    }

    public static isMatch(item: Product, search: string): boolean {
        const keywords: string[] = this.getKeywords(item);
        return keywords.some(item => item.includes(search));
    }
}