import {pageData} from "@/helpers/CypressHelper";

context("(Desktop) Calendar", () => {

    beforeEach(() => {
        cy.init("Desktop");
    });

    it("Should work correctly for Manager", () => {
        cy.loginAsManager();
        test();
    });

    it("Should work correctly for Admin", () => {
        cy.loginAsAdmin();
        test();
    });

    function test(): void {
        cy.navigateToCalendarPage();

        describe("CurrentTimelineMarker is visible", () => {
            pageData().calendar.rightPanel.calendarData().get('.athenaeum-timeline-marker-timelineMarker')
                .should('be.visible');
        });
    }
});