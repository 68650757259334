
export default class SaveWorkOrderExtraChargeRequest {

    public id: string | null = null;
    
    public workOrderId: string = "";

    public extraChargeTypeId: string = "";

    public description: string | null = null;

    public amount: number = 0;

    public price: number = 0;
    
    public extraChargeDate: Date | null = null
}