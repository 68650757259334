import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {AttachmentType} from "@/models/Enums";

export default class AddWorkOrderAttachmentsRequest{

    public workOrderId: string = "";

    public public: boolean = false;

    public type: AttachmentType = AttachmentType.WorkOrderAttachment;

    public files: FileModel[] = [];

    public readonly isAddWorkOrderAttachmentsRequest: boolean = true;
}