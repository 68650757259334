import RentaTasksWizardPage from "@/pages/RentaTasks/RentaTasksWizardPage";
import {LocationPicker} from "@renta-apps/athenaeum-react-components";
import React from "react";
import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";
import styles from "../../RentaTasks.module.scss"

interface IConfirmAddressState {
}

interface IConfirmAddressProps {
}

export default class ConfirmAddress extends RentaTasksWizardPage<IConfirmAddressProps, IConfirmAddressState> {
    private get location(): GeoLocation | undefined {
        return this.wizard.owner?.location ?? undefined;
    }
    
    private async onLocationChangeAsync(location: GeoLocation): Promise<void> {
        this.wizard.owner!.location = location;
        await this.reRenderAsync();
    }

    protected get workOrderRequired(): boolean {
        return false;
    }
    
    protected get canNext(): boolean {
        return (!!this.location);
    }

    public getManual(): string {
        return ""
    }

    renderContent(): React.ReactNode {
        return (
            <div className={styles.wizardContent}>
                <p>{Localizer.rentaTasksControllerStepsActivateConstructionSiteTitleConfirmAddress}</p>
                <LocationPicker fullWidth={true}
                                location={this.location}
                                onChange={async (_, location) => await this.onLocationChangeAsync(location)}
                />
            </div>
        )
    }
}