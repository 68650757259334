import {ConstructionSiteOrWarehouseType} from "../../Enums";
import {SortDirection} from "@renta-apps/athenaeum-toolkit";

export default class GetUserSalaryHoursRequest {
    public from: Date | null = null;
    
    public to: Date | null = null;

    public source: ConstructionSiteOrWarehouseType | null = null;

    public managerUserIds: string[] = [];

    public mounterUserIds: string[] = [];

    /** Return only items where mounter has any of the given cost pools. */
    public mounterCostPoolIds: string[] = [];
    
    public pageNumber: number = 1;
    
    public pageSize: number = 100;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;
}