import {TimelineRow} from "@/pages/Calendar/CalendarData/Models/TimelineRow";
import {TimelineItem} from "@/pages/Calendar/CalendarData/Models/TimelineItem";
import {TimelineRowHeaderColumn} from "@/pages/Calendar/CalendarData/Models/TimelineRowHeaderColumn";
import {ITimelineSelectedItemIds} from "@renta-apps/athenaeum-react-components";

export default class CalendarItemData {
    /**
     * Rows to be rendered.
     */
    rows: TimelineRow[] = [];

    /**
     * Items to be rendered for the returned {@see rows}
     */
    items: readonly Readonly<TimelineItem>[] = [];

    /**
     * Header columns for the rows.
     */
    rowHeaderColumns: TimelineRowHeaderColumn[] = [];

    /**
     * Updated collection of selected {@see items}.
     */
    selectedItemsIds: ITimelineSelectedItemIds[] = [];
}