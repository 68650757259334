import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Dropdown, DropdownOrderBy, Form, IconSize, JustifyContent, SelectListItem, Spinner, TextInput, ToolbarButton, ToolbarContainer, ToolbarRow} from "@renta-apps/athenaeum-react-components";
import ToolbarModel from "./ToolbarModel";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "../../../localization/Localizer";
import FeatureFlags from "@/helpers/FeatureFlags";
import AddConstructionSiteModal from "@/pages/ConstructionSites/AddConstructionSiteModal/AddConstructionSiteModal";
import OrganizationContract from "@/models/server/OrganizationContract";
import AddConstructionSiteRequest from "@/models/server/requests/AddConstructionSiteRequest";
import AddConstructionSiteResponse from "@/models/server/responses/AddConstructionSiteResponse";
import {FeatureSwitch} from "@/components/FeatureSwitch/FeatureSwitch";

import styles from "./Toolbar.module.scss";


interface IToolbarProps  {
    model?: ToolbarModel;
    onChange?(model: ToolbarModel): Promise<void>;
    addConstructionSite(request: AddConstructionSiteRequest): Promise<AddConstructionSiteResponse>;
}

interface IToolbarState {
    model: ToolbarModel
}

export default class Toolbar extends BaseComponent<IToolbarProps, IToolbarState> {
    addConstructionSiteModalId: string = "addConstructionSiteModal" as const;

    state: IToolbarState = {
        model: this.props.model || new ToolbarModel()
    };

    private async processOnChange(invoke: boolean = false): Promise<void> {
        await this.setState({ model: this.state.model });
        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this.state.model);
        }
    }

    private async setSearchTermAsync(searchTerm: string): Promise<void> {
        this.state.model.searchTerm = searchTerm;
        await this.processOnChange();
    }

    private async setStatusAsync(item: SelectListItem | null): Promise<void> {
        this.state.model.status = (item) ? parseInt(item.value) : null;
        await this.processOnChange(true);
    }

    private async clearAsync(): Promise<void> {
        this.state.model = new ToolbarModel();
        await this.processOnChange(true);
    }

    private async fetchCustomersAsync(): Promise<OrganizationContract[]> {
        return await this.postAsync("api/constructionSiteManagement/getAllCustomers");
    }

    private renderSearchInput() {
        return (
            <TextInput id="searchTerm" inline small
                       placeholder={Localizer.constructionSitesToolBarSearchTermFilterWithProjectNumber}
                       value={this.state.model.searchTerm}
                       width="22rem"
                       onChange={async (_, value) => await this.setSearchTermAsync(value)}
            />
        )
    }

    public render(): React.ReactNode {

        return (
            <ToolbarContainer className={styles.toolbar}>

                <ToolbarRow justify={JustifyContent.SpaceBetween}>

                    <Form inline onSubmit={async () => await this.processOnChange(true)}>

                        {this.renderSearchInput()}

                        <Dropdown inline small noValidate noSubtext noWrap autoCollapse
                                  id="constructionSitesStatus"
                                  nothingSelectedText={Localizer.addConstructionsiteToolbarAnyStatus}
                                  minWidth="7rem"
                                  orderBy={DropdownOrderBy.None}
                                  items={EnumProvider.getConstructionSiteStatusItems()}
                                  selectedItem={(this.state.model.status != null) ? EnumProvider.getConstructionSiteStatusItem(this.state.model.status) : undefined}
                                  onChange={async (_, item) => await this.setStatusAsync(item)}
                        />

                        <Button small submit
                                id="filterConstructionSites"
                                label={Localizer.addConstructionsiteToolbarFilter}
                                icon={{name : "fas search"}}
                                type={ButtonType.Orange}
                        />

                        <Button small title={Localizer.addConstructionsiteToolbarClearFilter}
                                id="clearConstructionSitesFilters"
                                icon={{name: "far history", size: IconSize.Large }}
                                type={ButtonType.Info}
                                onClick={async () => await this.clearAsync()}
                        />

                    </Form>

                    <FeatureSwitch flagName={FeatureFlags.ConstructionSiteIntegration}>
                        <ToolbarButton id="addConstructionSiteButton"
                                       label={Localizer.addConstructionsiteAddSite}
                                       icon={{name: "plus", size: IconSize.Large}}
                                       type={ButtonType.Orange}
                                       dataTarget={this.addConstructionSiteModalId}
                                       toggleModal
                        />
                    </FeatureSwitch>
                </ToolbarRow>

                {
                    (this.isSpinning()) && <Spinner />
                }
                <FeatureSwitch flagName={FeatureFlags.ConstructionSiteIntegration}>
                    <AddConstructionSiteModal modalId={this.addConstructionSiteModalId}
                                              fetchCustomers={async () => this.fetchCustomersAsync()}
                                              addConstructionSite={this.props.addConstructionSite}
                    />
                </FeatureSwitch>    
            </ToolbarContainer>
        );
    }
};