import {UserRoleGroup} from "../Enums";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import OrganizationContract from "@/models/server/OrganizationContract";

export default class UserRole {

    constructor(roleName: string = "", organizationContractId: string = "", group: UserRoleGroup = UserRoleGroup.Admins) {
        this.roleName = roleName;
        this.organizationContractId = organizationContractId;
        this.group = group;
    }

    public roleName: string = "";

    public group: UserRoleGroup = UserRoleGroup.Admins;

    public organizationContractId: string = RentaTaskConstants.defaultGuid;
    
    public organizationContract: OrganizationContract | null = null;

    /**
     * Unique external id for this combination of role, contract and user.
     * E.g. a contact person has one external id for each contract they are assigned to in ERP.
     */
    public externalId: string | null = null;
    
    public isUserRole: boolean = true;

    /**
     * Set empty ids as default Guids to prevent errors when the {@link UserRole} is deserialized in FE controllers.
     */
    public static initializeEmptyGuids(userRole: UserRole): void {
        if (!userRole.organizationContractId) {
            userRole.organizationContractId = RentaTaskConstants.defaultGuid;
        }
    }
}