import React from "react";
import FormItem from "@/models/server/forms/FormItem";
import FormSummaryTableRow from "@/components/Form/FormSummaryTable/FormSummaryTableRow/FormSummaryTableRow";

import styles from "./FormSummaryTableGroup.module.scss";

export interface IFormSummaryTableGroupProps {
    groupName: string;
    groupItems: readonly Readonly<FormItem>[];
    onItemClick: (item: FormItem) => Promise<void>;
}

export default function FormSummaryTableGroup(props: Readonly<IFormSummaryTableGroupProps>): JSX.Element {
    const {groupName, groupItems, onItemClick} = props;

    const itemContainer: JSX.Element = (
        <div className={styles.formSummaryTableGroupItemContainer}>
            {
                groupItems.map((formItem) => (
                    <FormSummaryTableRow key={formItem.name}
                                         formItem={formItem}
                                         onClick={async () => await onItemClick(formItem)}
                    />
                ))
            }
        </div>
    );

    return (groupName)
        ? (
            <div className={styles.formSummaryTableGroup}>
                <h5>
                    {groupName}
                </h5>

                {itemContainer}
            </div>
        ) : itemContainer;
}